import React, { useEffect, useState } from 'react';
import { Alert, Switch, Text, View, Pressable } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import Ionicons from '@expo/vector-icons/Ionicons';
import { shallowEqual, useSelector } from 'react-redux';
import { Season } from '../../../functions/src/interfaces';
import i18n, { tokens } from '../../i18n';
import { changeDate, setSelectedClub } from '../../store/actions/actionsAuth';

import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { AuthActions } from '../../store/reducers/authReducer';
import { RootStore } from '../../store/reducers/rootReducer';
import { getCurrentAuthState, getCurrentUserWithRoles } from '../../store/selectors';
import { normalize, reservationFilters, seasonTypes, weekdays, windowWidth } from '../../utilities/constants';
import { monthString, returnActiveSeasons, standardizeDates } from '../../utilities/funcs';
import { globalStyleSheet, mainFont, mainFontBold, secondColor } from '../../utilities/globalStyles';
import { ReservationFilters } from '../../utilities/interfaces';
import BigButton from '../BigButton';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import FlexedMessage from '../FlexedMessge';
import FormEntry from '../FormEntryNew';
import FormHeading from '../FormHeading';
import Overlay from '../Overlay';
import RenderDay from './RenderDay';
import FloatingButton from './FloatingButton';
import StateComponent from '../StateComponent';

type Props = {
    selectedClub: string,
    date?: Date,
    changeMarket: boolean
}


type OverlayProps = {
    isModalVisible: boolean,
    toggleModal: () => void,
    filters: ReservationFilters,
    handler: (state) => void,
}

const RenderModal: React.FC<OverlayProps> = (props) => {
    const {isModalVisible, toggleModal, filters} = props
    const dispatch = useAppDispatch()
    const { isEmpty, user, isLoaded } = getCurrentAuthState()
    return (
      <Overlay isVisible={isModalVisible} onBackdropPress={toggleModal}>
          <View
          style={{
              //flex: 1,
              width: windowWidth*0.8,
              
          }}>
              <FormEntry 
              caption={reservationFilters.openState}
              editable={true}
              >
                  <Switch value={filters.openState} onValueChange={(value) => props.handler(prevState => {
                        return {...prevState, openState: value}
                      })}/>
              </FormEntry>
              {isEmpty?<BigButton
                buttonText={i18n.t(tokens.app.screens.myClubs.myClubsSelectClub)}
                onPress={() => dispatch(setSelectedClub(""))}
              />:null}
             
          </View>
      </Overlay>
    )
}

type ToolbarProps = {
    changeDateByIncrement: (by: number) => void,
    toggleModal: () => void,
    currDate: Date,
    setDate: (date: Date) => void,
    season: Season
}

const RenderToolbar: React.FC<ToolbarProps> = (props) => {
    const navigation = useAppNavigation()
    return (<View style={globalStyleSheet.toolbar}>
        <ToolbarButton 
        onPress={() => props.changeDateByIncrement(-1)}
        icon={"chevron-back"}
        />

        <View
        style={{flexDirection: "row", alignItems: "center"}}
        >
                <Pressable
                onPress={() => navigation.navigate('SeasonDetailScreen', {clubId: props.season.club, seasonId: props.season.firebaseId})}
                disabled={!!!props.season}
                >
                    <Text style={{fontSize: 18, fontWeight: "bold", letterSpacing: 0, fontFamily: 'AvertaStd-Semibold'}}>
                        {(props.season?.type?seasonTypes[props.season.type]:i18n.t(tokens.app.screens.reservations.noSeasonHeader)) + ' ' + weekdays[props.currDate.getDay()].substring(0,2).toLowerCase() + ' '}
                    </Text>
                </Pressable>
            <CustomDatePicker
            icon={true}
            iconId="calendar-outline"
            fontStyle={{fontSize: 18, fontWeight: "bold", letterSpacing: 0, fontFamily: 'AvertaStd-Semibold'}}
            //containerStyle={user.dateOfBirth.validity ? styles.inputOk:styles.inputKo}
            date={props.currDate} 
            setDate={props.setDate}
            /> 
             <ToolbarButton 
            onPress={props.toggleModal}
            icon={"filter-outline"}
            size={normalize(24)}
            />
        </View>
       
         
        <ToolbarButton 
        onPress={() => props.changeDateByIncrement(1)}
        icon={"chevron-forward"}
        />
        </View>)
}

function Calendar(props: Props) {
    

    const currDate = useAppSelector((state:RootStore) => new Date(JSON.parse(state.auth.currDate)), (a,b) => JSON.stringify(a) == JSON.stringify(b) ) 
    const seasons = useSelector((state:RootStore) => state.auth.seasons, (a,b) => JSON.stringify(a) == JSON.stringify(b) ) 
    const club = useSelector((state:RootStore) => state.auth.allClubs?.[props.selectedClub], (a,b) => JSON.stringify(a) == JSON.stringify(b) ) 
    const [futureSeasons, setFutureSeasons] = useState([])
    const dispatch = useAppDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)
    //const [club, setClub] = useState(props.selectedClub)
    const [filters, setFilters] = useState<ReservationFilters>({
        openState: false,
    })
    const navigation = useAppNavigation()
    
   /*  useEffect(() => {
        setClub(props.selectedClub)
    }, [props.selectedClub]) */

    
    
    
    useEffect(() => {
        
        //console.log('toto jsou sezony ', seasons, currDate)
        let futureOrActiveSeasons = []
        Object.keys(seasons?seasons:{}).forEach((key) => {
            let currSeas = {...seasons[key]}
            //console.log('testuju', currSeas)
            if (currSeas.from >= currDate) {
                futureOrActiveSeasons.push({...currSeas, key: key})
            } else if (currSeas.from <= currDate && currSeas.to >= currDate) {
                futureOrActiveSeasons.push({...currSeas, key: key})
            }
        })
        //console.log('toto setuju', futureOrActiveSeasons)
        setFutureSeasons(futureOrActiveSeasons)
    }, [currDate, seasons])
    
    
    const season = useSelector((state:RootStore) => state.auth.activeSeason, (a,b) => JSON.stringify(a) == JSON.stringify(b))


    
    
    const changeDateByIncrement = (increment: number) => {
        
        dispatch(changeDate(new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() + increment)))
    }

    
    const toggleModal = () => {
        
        setIsModalVisible((prevValue) => {
            
            return !prevValue
        })
    }
   

    return (
        <View style={{ flex: 1, width: "100%"}}>
            
            <RenderToolbar 
            changeDateByIncrement={changeDateByIncrement}
            setDate={(newDate) => {dispatch(changeDate(newDate))}}
            toggleModal={toggleModal}
            currDate={currDate}
            season={season}
            />
            <RenderModal handler={setFilters} toggleModal={toggleModal} filters={filters} isModalVisible={isModalVisible}/>
            {
                
                (
                    season && club?.activeUntil >= currDate  ? (
            <>
            <RenderDay currDate={currDate} selectedClub={props.selectedClub} reservationFilters={filters}/>
            <FloatingButton changeMarket={props.changeMarket}/>
            </>)
             : (
                <View style={{ flex: 1}}>
                    <View style={{marginBottom: 30, marginTop: 10}}>
                        <Text maxFontSizeMultiplier={1.2} style={{alignSelf: "center", fontFamily: mainFont, fontSize: normalize(18), color: "red"}}>
                            {i18n.t(tokens.app.screens.reservations.noSeasonAvailable)} 
                        </Text>
                    </View>
                    
                    
                    {
                        futureSeasons.length > 0 ?
                        <>
                        <FormHeading heading={i18n.t(tokens.app.screens.reservations.seasonsList)}/>
                        <FlatList
                        //contentContainerStyle={{flex: 1}}
                        data={futureSeasons}
                        renderItem={({item}) => (
                            <Pressable
                            onPress={() => navigation.navigate('SeasonDetailScreen', {clubId: item.club, seasonId: item.firebaseId})}
                            >
                            <FormEntry 
                            caption={seasonTypes[item.type]}
                            editable={false}
                            >
                                <View style={{alignSelf: "flex-end"}}>
                                    <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFont, fontSize: normalize(18)}}>{monthString(item.from) + ' - ' + monthString(item.to)}</Text>
                                </View>
                                
                            </FormEntry>
                            </Pressable> )}
                        />
                        </>
                        :
                        <FlexedMessage message={i18n.t(tokens.app.screens.reservations.noFutureSeasonsMessage)}/>
                        
                    }
                    
                </View>
            ))
            }
            
        </View>
    )
}

export const ToolbarButton: React.FC<{
    onPress: () => void,
    icon: any,
    size?: number
}> = (props) => {
    return (
        <Pressable onPress={props.onPress}>
            <Ionicons 
                name={props.icon}
                color="black"
                size={props.size || 35}
                
                />
            </Pressable>
    )
}




export default React.memo(Calendar, (a,b) => JSON.stringify(a) == JSON.stringify(b)) //connect(mapStateToProps, mapDispatchToProps)(Calendar)

