import React, { useEffect, useState } from 'react'
import { Dimensions, Modal, View, StyleSheet, ScrollView, Text, Pressable, ActivityIndicator } from 'react-native'
import { BlurView } from 'expo-blur';
import { useAppSelector } from '../store/hooks';
import { RootStore } from '../store/reducers/rootReducer';
import { shallowEqual } from 'react-redux';
const windowWidth = Dimensions.get('window').width - 40;
const windowHeight = Dimensions.get('window').height;

type Props = {
  onBackdropPress: () => void,
  fullScreen: boolean,
  isVisible: boolean
}


function ActionOverlay(props: Props): JSX.Element {
    
    const actionPressed = useAppSelector((state: RootStore) => state.auth.isActionPressed, shallowEqual)

    const isVisible = () => {
      if (actionPressed == true) {
        return true
      } else if (props.isVisible) {
        return props.isVisible
      } else {
        return false
      }
    }
  
    return (<Modal
        visible={isVisible()}
        //onRequestClose={() => props.onBackdropPress()}
        transparent={true}>
        <View style={styles.container}>
          <View style={styles.innerContainer}>
          <ActivityIndicator size={'large'} />
          {/* <Text>{String(props.isVisible)}</Text> */}
          </View>
        </View>
      </Modal>)
}



export default ActionOverlay;



const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      },
    innerContainer: {
      width: 120,
      height: 120,
      backgroundColor: "white",
      borderColor: "grey",
      borderRadius: 10,
      borderWidth: 0,
      justifyContent: "center",
      alignItems: "center",
    },
      modal: {
        marginLeft: 50,
        marginRight: 50,
        minHeight: "40%",
        padding: 5,
        backgroundColor: "white",
        borderRadius: 10,
        shadowColor: "black",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
      },
      modalFS: {
        flex: 1,
        backgroundColor: "white",
      },
      /* The content of the modal takes all the vertical space not used by the header. */
      modalContent: {
        width: "100%",
        borderWidth: 0,
        borderColor: "black"
      },
      modalContentFS: {
        flex: 1
      },
      modalHeader: {
        flexDirection: "row",
        borderWidth: 0,
        borderColor: "black"
      },
      /* The header takes up all the vertical space not used by the close button. */
      modalHeaderContent: {
        flexGrow: 1,
      },
      modalHeaderCloseText: {
        textAlign: "center",
        paddingLeft: 5,
        paddingRight: 5
      },
      outsideModal: {
        backgroundColor: "rgba(1, 1, 1, 0.2)",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }
});