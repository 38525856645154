import { Subscription, SubscriptionTour, SubscriptionYear, user, UserRoles } from "../../../functions/src/interfaces"
import { StateLoader } from "../../utilities/interfaces"

export enum ContactsActions {
    contactsSearchSuccess = 'SEARCH_SUCCESS',
    contactsAddContactSuccess = 'ADD_CONTACT_SUCCESS',
    contactsLoadProfileData = 'LOAD_PROFILE_FOR_EDIT',
    contactsEditProfile = 'EDIT_PROFILE',
    contactsEditRole = 'EDIT_ROLE',
    contactsUsersSubscribe = 'USER_CHANGES',
    contactsSubscriptionsChanges = 'SUBSCRIPTIONS_CHANGES',
    contactsLoadRolesData = 'LOAD_ROLES_FOR_EDIT',
    contactsLoadMembershipData = 'LOAD_SUBSCRIPTIONS_FOR_EDIT',
}


export type ContactsState = {
    contacts: user[],
    addedcontacts: {},
    editProfileData: StateLoader<user>,
    userspublic: {[uid:string]: user}
    subscriptions: {
        floating: {[subId: string]: SubscriptionYear},
        tour: {[subId: string]: SubscriptionTour},
    },
    editRolesData: StateLoader<UserRoles>,
    editSubscriptionsData: StateLoader<Subscription[]>,
}

const contactsData = [
    
]



const initState: ContactsState = {
    contacts: contactsData,
    addedcontacts:{},
    editProfileData: {
        isLoaded: false,
        data: undefined
    },
    userspublic:{},
    subscriptions: {
        floating: {},
        tour: {}
    },
    editRolesData: {
        isLoaded: false,
        data: undefined
    },
    editSubscriptionsData: {
        isLoaded: false,
        data: undefined
    },
}

const contactsReducer = (state = initState, action) => {
    switch(action.type){
        case ContactsActions.contactsSearchSuccess:
            //console.log('akce:', action)
            return {
                ...state,
                contacts: action.data
            }
        case 'SEARCH_ERROR':
            return {
                ...state,
                authError: null
            }
        case ContactsActions.contactsAddContactSuccess:
            //console.log('akce:', action)
            return {
                ...state,
                addedcontacts: action.record
            }
        case 'ADD_CONTACT_ERROR':
            return {
                ...state,
                authError: null
            }
        case ContactsActions.contactsLoadProfileData:
            return {
                ...state,
                editProfileData: action.data
            }
        case ContactsActions.contactsEditProfile:
            return {
                ...state,
                editProfileData: {
                    ...state.editProfileData,
                    data: {
                        ...state.editProfileData.data,
                        ...action.data
                    }
                }
            }
        case ContactsActions.contactsEditRole:
            return {
                ...state,
                editRolesData: {
                    ...state.editRolesData,
                    data: {
                        ...state.editRolesData.data,
                        ...action.data
                    }
                }
            }
        case ContactsActions.contactsLoadRolesData:
            return {
                ...state,
                editRolesData: action.data
            }
        case ContactsActions.contactsLoadMembershipData:
            return {
                ...state,
                editSubscriptionsData: action.data
            }
        case ContactsActions.contactsUsersSubscribe: 
            return {
                ...state,
                userspublic: {...state.userspublic, ...action.data}
            }
        case ContactsActions.contactsSubscriptionsChanges: 
            return {
                ...state,
                subscriptions: action.data
            }
        default:
            return state


    }
    return state
}

export default contactsReducer