import React, { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import QRCode from 'react-native-qrcode-svg';
import { shallowEqual } from 'react-redux';
import { Club } from '../../../functions/src/interfaces';
import { useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { normalize } from '../../utilities/constants';
//https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
type Props = {
    paymentAmt: number,
    paymentId: number,
}

function PaymentScreenQR(props:Props): JSX.Element {
    const [isLoaded, setIsLoaded] = useState(false)
    const club: Club = useAppSelector((state:RootStore) => state.auth.allClubs[state.auth?.selectedClub], shallowEqual)
    const [paymentMsg,setPaymentMsg] = useState<string>("")  

    useEffect(() => {
        if (club && club.iban != "") {
            setPaymentMsg(`SPD*1.0*ACC:${club.iban}*AM:${props.paymentAmt}.00*CC:CZK*MSG:PLATBA ZA ZBOZI*X-VS:${String(props.paymentId)}`)
            setIsLoaded(true)
        } else {
            setIsLoaded(false)
        }
    }, [club])
    return (
        <View
        style={styles.container}>
            {isLoaded?
            <QRCode
            value={paymentMsg}
            size={normalize(200)}
            />
            :<ActivityIndicator size={"small"}/>}
        </View>
    )
}


const styles = StyleSheet.create(
    {
        container: {
            justifyContent: "center",
            alignItems: "center",
        }
    }
)

export default PaymentScreenQR