import React from 'react'
import { Switch, View } from 'react-native'
import { ClubRoles } from '../../../../functions/src/interfaces'
import BigButton from '../../../components/BigButton'
import FormEntry from '../../../components/FormEntryNew'
import FormHeading from '../../../components/FormHeading'
import i18n, { tokens } from '../../../i18n'
import { useAppNavigation } from '../../../store/hooks'
import { roleTypes } from '../../../utilities/constants'
import { StateLoader } from '../../../utilities/interfaces'
import { showToast } from '../../../utilities/toastcontroller'
type Props = {
    rolesData: StateLoader<ClubRoles>,
    uid: string,
    selectedClub: string,
    dispatchActionForChanges: (callback) => void,
    editRoles: (data: ClubRoles, callback: (bool, context) => void) => void,
    isAdmin: boolean
}
function RolesData(props: Props):JSX.Element {
    const {rolesData,uid} = props
    const navigation = useAppNavigation()

    const dispatchChange = (callback: (params) => void) => {
        props.dispatchActionForChanges(
          callback
        )
    }
    return (
        rolesData.isLoaded?
            <>
            <FormHeading heading={i18n.t(tokens.app.screens.profile.profileRoles)}/>
            <FormEntry 
            caption={roleTypes.isAdmin}
            editable={props.isAdmin}
            >
                <Switch 
                style={{alignSelf: "flex-end"}}
                value={rolesData.data.isAdmin}
                onValueChange={(value) => {dispatchChange(() => { return {isAdmin: value} })}}
                disabled={!props.isAdmin}
                />
            </FormEntry>
            <FormEntry 
            caption={roleTypes.isCoach}
            editable={props.isAdmin}
            >
                <Switch 
                style={{alignSelf: "flex-end"}}
                value={rolesData.data.isCoach}
                onValueChange={(value) => {dispatchChange(() => { return {isCoach: value} })}}
                disabled={!props.isAdmin}
                />
            </FormEntry>
            <FormEntry 
            caption={roleTypes.isTester}
            editable={props.isAdmin}
            >
                <Switch 
                style={{alignSelf: "flex-end"}}
                value={rolesData.data.isTester}
                onValueChange={(value) => {dispatchChange(() => { return {isTester: value} })}}
                disabled={!props.isAdmin}
                />
            </FormEntry>
            {props.isAdmin?
            <View style={{flexDirection: "row", flex: 1}}>
                <BigButton
                buttonText={i18n.t(tokens.app.screens.profile.profileModRolesButton)}
                onPress={() => {
                    props.editRoles({
                        ...rolesData.data,
                        club: props.selectedClub,
                        uid: uid,    
                    },
                    (bool, content) => {
                        if (bool == true) {
                            navigation.goBack()
                        } else {
                            showToast(content.message)
                            console.log('Error saving roles data' + JSON.stringify(content))
                        }
                    })
                }}
                />
                
            </View>:null}
            </>
            :null
    )
}

export default RolesData