import * as XLSX from 'xlsx';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { Platform } from 'react-native';
//https://codingdeekshi.com/react-native-tutorial-to-export-or-write-excel-xlsx/

export const shareExcel = async (data: {[key: string]: any}[], sheet: string): Promise<void> => {
  var ws = XLSX.utils.json_to_sheet(data);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheet);
  
  if (['ios','android'].includes(Platform.OS)) {
    const wbout = XLSX.write(wb, {
      type: 'base64',
      bookType: "xlsx"
    });
  
    const uri = FileSystem.cacheDirectory + sheet + '.xlsx';
    console.log(`Writing to ${JSON.stringify(uri)} with text: ${wbout}`);
    await FileSystem.writeAsStringAsync(uri, wbout, {
      encoding: FileSystem.EncodingType.Base64
    });


    await Sharing.shareAsync(uri, {
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      dialogTitle: 'Reservation data',
      UTI: 'com.microsoft.excel.xlsx'
    });
  } else if (Platform.OS == 'web') {
    XLSX.writeFile(wb, 'fileName.xls');
  }
}