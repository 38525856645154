import React, { useState } from 'react';
import { Text, View } from 'react-native';
import FormHeading from '../../../components/FormHeading';
import UserAvatar from '../../../components/UserAvatar';
import { checkoutItemTypes } from '../../../utilities/constants';
import { monthString } from '../../../utilities/funcs';
import { styles } from '../styles';



function ItemDetail(props: {index: number, item: {price: number, uid?: string, type: string, extra?: string}}):JSX.Element {
    const {item, index} = props
    //console.log('Item data props', props)
    return   (
        <View style={styles.priceDetailContainer}>
            <View style={styles.priceDetailAvatarAndNumber}>
                <Text>{String(index) + '.'}</Text>
                <UserAvatar
                size={50}
                uid={item?.uid}
                />
            </View>

            <View>
                <Text style={styles.itemText}>{checkoutItemTypes[item.type] + ' ' + (props.item.extra || "")}</Text>
            </View>

            <View>
                <Text style={styles.itemPriceText}>{String(item.price.toFixed(2)) + '.-'}</Text>
            </View>
        </View>  )
}

function ReservationItem(props: {resKey: string, reservation: {price: number, date: Date, detail: {[item: string]: {
    price: number;
    uid?: string | undefined;
    type: string;

}}}}):JSX.Element {
    const { reservation, resKey } = props
    const [viewDet, setViewDet] = useState(false)
    return   (
        <View key={resKey} style={styles.reservationDetail}>
            <FormHeading heading={monthString(reservation.date)} editButtonCaption={String(reservation.price.toFixed(2)) + 'Kč'} editButton={() => setViewDet(prev => !prev)}/>
            {viewDet?
            Object.keys(reservation.detail).map((key, index) => {
                let item = reservation.detail[key]
                return (
                    <ItemDetail item={item} index={index} key={key}/>
                )
            })
            :null
            }
        </View>  )
}

export function CheckoutItems(props: {reservations: {[reservationId: string]: {price: number, date: Date, detail: {[item: string]: {
    price: number;
    uid?: string | undefined;
    type: string;

}}}}}):JSX.Element {
    const { reservations } = props
    return   (
        <View style={styles.reservationDetail}>
            {Object.keys(reservations).sort((a,b) => reservations[a]?.date > reservations[b]?.date ? 1 : -1).map((key) => {
                let reservation = reservations[key]
                return <ReservationItem key={key} resKey={key} reservation={reservation}/>
            })}
        </View>  )
}


