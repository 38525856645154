import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Alert, Platform, StyleSheet, Text, Pressable, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { Market, MarketPaymentStatuses, MarketReservation, MarketStatuses, MarketTypes, Reservation } from '../../../../functions/src/interfaces';
import BigButton from '../../../components/BigButton';
import Overlay from '../../../components/Overlay';
import { manageMarketToState } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/reservationReducer';
import { normalize } from '../../../utilities/constants';



type Props = {
  isOverlayVisible: boolean,
  setIsOverlayVisible: (state: any) => void,
  reservationData: StateLoader<Reservation>
}

function Dialog(props: Props):JSX.Element {

    //const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    
    const removeReservationAction = () => {}

    const addToCalendarAction = () => {}

    return (<Overlay isVisible={props.isOverlayVisible} onBackdropPress={() => props.setIsOverlayVisible((prev:{type: string, visible: boolean, data: any}) => {
      return {...prev, visible: !prev.visible}
    })}>
        <View
        style={styles.container}
        >
          <View style={styles.innerContainer}>
        <Text>
          Chcete editovat pouze tuto rezervaci, nebo vsechny?
        </Text>
        <View style={styles.buttonContainer}>
          <BigButton
          onPress={() => {
            
            navigation.navigate('ManageMarket', {type: "reservation", firebaseId: props.reservationData.data.reservationRequestId, club: props.reservationData.data.club})
            props.setIsOverlayVisible((prev:{type: string, visible: boolean, data: any}) => {
              return {...prev, visible: !prev.visible}
            })
          }}
          buttonText={"Pouze tuto"}
          />
          <BigButton
          onPress={() => {
            navigation.navigate('ManageMarket', {type: "longreservation", marketId: props.reservationData.data.reservationRequestId, club: props.reservationData.data.club})
            props.setIsOverlayVisible((prev:{type: string, visible: boolean, data: any}) => {
              return {...prev, visible: !prev.visible}
            })
          }}
          buttonText={"Vsechny"}
          />
        </View>
        </View>
        </View>
    </Overlay>
    )
}

const styles = StyleSheet.create({
    container: {
      width: "100%",
      flexDirection: "column",
      padding: 7,
      justifyContent:"center",
      alignItems: "center",
      
      
    },
    innerContainer: {
      width: "100%",
      flexDirection: "column",
    },
    textStyle: {
      
        textAlign: "right", 
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',
        
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        flex: 1,
        
    }
});

export default Dialog
