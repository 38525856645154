import * as Calendar from 'expo-calendar';
import { Platform } from 'react-native';

export const createEventObject = (data: {title: string, startDate: Date, endDate: Date, id: string, clubAddress?: string, text?: string}, calId: string) => {
    let event: Calendar.Event = {
        title: data.title,
        startDate: data.startDate,
        endDate: data.endDate,
        timeZone: 'Europe/Prague',
        id: data.id,
        calendarId: calId,
        location: data.clubAddress || "Unknown",
        notes: data.text && data.text.length > 0 ? data.text : "",
        alarms: [{relativeOffset: -60}, {relativeOffset: -1440}],
        recurrenceRule: {frequency: 'daily', occurrence: 1},
        allDay: false,
        availability: "BUSY",
        status: "CONFIRMED"
    }
    return event
}

export const getCalendarPermissions = async () => {
    const { status } = await Calendar.requestCalendarPermissionsAsync();
    if (status === 'granted') {
      const calendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);
      console.log('Here are all your calendars:');
      console.log({ calendars });
    }
  };

async function getDefaultCalendarSource() {
    const defaultCalendar = await Calendar.getDefaultCalendarAsync();
    return defaultCalendar.source;
}
  
export async function createCalendar() {
    const defaultCalendarSource =
      Platform.OS === 'ios'
        ? await getDefaultCalendarSource()
        : { isLocalAccount: true, name: 'Expo Calendar', id: undefined, type: "unknown" };
    const newCalendarID = await Calendar.createCalendarAsync({
      title: 'MiTennis Calendar',
      color: 'blue',
      entityType: Calendar.EntityTypes.EVENT,
      sourceId: defaultCalendarSource.id,
      source: defaultCalendarSource,
      name: 'MiTennis',
      ownerAccount: 'personal',
      accessLevel: Calendar.CalendarAccessLevel.OWNER,
    });
    console.log(`Your new calendar ID is: ${newCalendarID}`);
}

const getDefaultCalendar = () => {
    return new Promise<Calendar.Calendar>((resolve, reject) => {
        if (Platform.OS == "ios") {
            Calendar.getDefaultCalendarAsync()
            .then((calendar) => {
                resolve(calendar)
            })
            .catch((e) => {
                reject(e)
            })
        } else if (Platform.OS == "android") {
            Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT)
            .then((calendars) => {
                console.log(`Mame ${calendars.length} kalendaru` + JSON.stringify(calendars))
                var outCal: Calendar.Calendar
                for (let i = 0; i < calendars.length; i++) {
                    let calendar = calendars[i]
                    console.log(`Prochazim kalendar ${calendar.id} a je ${String(calendar.isPrimary)}`)
                    if (calendar.name == "MiTennis") {
                        outCal = calendar
                    }
                }
                if (outCal) {
                    console.log(`Vybral jsem ${outCal.id} `)
                    resolve(outCal)
                    
                } else {
                    const defaultCalendarSource = { isLocalAccount: true, name: 'MiTennis Calendar', id: "mitennis", type: undefined };
                    Calendar.createCalendarAsync({
                        title: 'MiTennis Calendar',
                        color: 'blue',
                        entityType: Calendar.EntityTypes.EVENT,
                        sourceId: defaultCalendarSource.id,
                        source: defaultCalendarSource,
                        name: 'MiTennis',
                        ownerAccount: 'personal',
                        accessLevel: Calendar.CalendarAccessLevel.OWNER,
                      })
                      .then((newcalendar) => {
                        console.log('Zalozil jsem: ' + newcalendar)
                        for (let i = 0; i < calendars.length; i++) {
                            let calendar = calendars[i]
                            console.log(`Prochazim kalendar ${calendar.id} a je ${String(calendar.isPrimary)}`)
                            if (calendar.id == newcalendar) {
                                outCal = calendar
                            }
                        }
                        resolve(outCal)
                      })
                      .catch((e) => reject(e))
                }
                //outCal = await getDefaultCalendar()
                //const calendar = calendars.find(({isPrimary})=>isPrimary);
                
                
            })
            .catch((e) => {
                reject(e)
            })
        } else {
            reject()
        }
    })
  }

 export function createEvent(data: {title: string, startDate: Date, endDate: Date, id: string, clubAddress?: string, text?: string}) {
    return new Promise((resolve, reject) => {
        getDefaultCalendar()
        .then((calendar) => {
            console.log('Mam kalendar: ' + calendar.id)
            console.log('Data pro event: ' + JSON.stringify(data))
        return Calendar.createEventAsync(
                calendar.id,
                createEventObject(data, calendar.id)
            )
            
        })
        .then((event) => {
            resolve({isSuccess: true, error: {}})
        })
        .catch((e) => {
            console.log('Nepodarilo se mi ziskat kalendar')
            reject(e)
        })
    })
  }