import React from "react";
import { TextInput, View } from "react-native";
import { Membership, MembershipTour, MembershipTypes, ProductPrice, ProductTypes, Season } from "../../../../functions/src/interfaces";
import CustomPickerSelect from "../../../components/CustomPickerSelect/CustomPickerSelect";
import FormEntry from "../../../components/FormEntryNew";
import i18n, { tokens } from "../../../i18n";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import FormHeading from "../../../components/FormHeading";
import PriceDetail from "./PriceDetail";
import BigButton from "../../../components/BigButton";
import { firestoreAutoId } from "../../../utilities/funcs";
import { roleTypes } from "../../../utilities/constants";

type Props = {
    subscription: MembershipTour,
    dispatchChange: (callback: (membership: Membership) => Membership) => void,
    seasons: {[seasonId: string]: Season}
}

function TourDetail(props: Props): JSX.Element {

    const tourItems = (seasons: {[seasonId: string]: Season}) => {
        let items = []
        let currDt = new Date
        let keys = Object.keys(seasons)
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let season  = seasons[key]
            //console.log('dsfsdfds', season)
            if (season.tour && season.to >= currDt) {
                if (!items.includes(season.tour)) items.push(season.tour)
            }
        }
        return items.map(tour => {
            return { label: String(tour), value: tour, key: tour}
        }) 
       
    }

    return (
        <View>
             <FormEntry 
            caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonTour)}
            editable={true}
            >
                <CustomPickerSelect
                onValueChange={(value) => props.dispatchChange((memb) => {
                    if (memb.type == MembershipTypes.tourSubscription) {
                        return {...memb, tour: parseInt(value)}
                    } else return memb
                })} 
                value={props.subscription.tour}
                placeholder={{label: i18n.t(tokens.app.screens.manageSeason.manageSeasonTour), value: undefined}}
                
                items={tourItems(props.seasons)
                }/>
            </FormEntry>
            
            {Object.values(props.subscription?.prices || {}).map((price) => {
                return (
                    <PriceDetail
                    key={price.firebaseId} 
                    priceId={price.firebaseId}
                    productType={ProductTypes.subscription}
                    dispatchChange={props.dispatchChange}
                    />
                )
            })}
            
            <View style={globalStyleSheet.buttonContainer}>
                <BigButton
                buttonText={i18n.t(tokens.app.components.button.buttonsAddButton)}
                onPress={() => props.dispatchChange(
                    
                    (memb) => {
                        var newMemb: Membership = {...memb}
                        let role = Object.keys(roleTypes).reduce((prev, act) => {
                            return {...prev, [act]: true}
                        }, {})
                        let newPrice: ProductPrice = {
                            firebaseId: firestoreAutoId(),
                            name: "",
                            price: 0,
                            allowedRoles: role
                        }
                        newMemb.prices = {
                            ...newMemb.prices, 
                            [newPrice.firebaseId]: newPrice
                        }
                        return newMemb
                    }
                )}
                />
            </View>
        </View>
    )
}

export default TourDetail