import React, { useEffect, useMemo, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import { AllResTypes, CourtTypes, GameTypes, ReservationStatuses, ReservationTypes, Season } from '../../../functions/src/interfaces';
import i18n, { tokens } from '../../i18n';
import { courtTypes } from '../../utilities/constants';
import { dateRangeOverlaps, extractTime, isDateEqual, isDateEqualWOTime, minutesDiff } from '../../utilities/funcs';
import { calcBlocks } from './common';
import ReservationSegment from './ReservationSegment';
import { Block } from './ReservationsFreeBlock';
import { Segment, SegmentRes, Segments } from './types';




type Props = {
    reservations: {[resId:string]: AllResTypes},
    date: Date
    courtIdText: string,
    fieldType:CourtTypes,
    courtId: string,
    season: Season,
    unlockDate: Date,
}

const FieldLane: React.FC<Props> = (props) => {
    

    const sortReservations = (a,b) => {
        if (a.dateFrom < b.dateFrom) {
            return -1
        } else if (a.dateFrom > b.dateFrom) {
            return 1
        } else if (a.dateFrom == b.dateFrom) {
            if (a.dateTo < b.dateTo) {
                return -1
            } else if (a.dateTo > b.dateTo) {
                return 1
            } else {
                return 0
            }
        }
    }

    


    const getOverlappingReservations = (reservations: AllResTypes[]) => {
        //let i = start
        //var overlapingReservations: reservation[] = [reservations[start]]
        let newres = reservations.shift()
        var response: {segmentFrom: Date, segmentTo: Date, overlappingReservations: AllResTypes[], remainingReservations: AllResTypes[]} = {
            //count: 1,
            segmentFrom: newres.dateFrom,
            segmentTo: newres.dateTo,
            overlappingReservations: [newres],
            remainingReservations: reservations,
        }
        //console.log('Blabla',response)
        let i = 0
        while (i < reservations.length) {
            //console.log('Testing', i, reservations, response)
            let res = reservations[i]
            //console.log('Debug',res[i], res[i], response.segmentFrom, response.segmentTo)
            //console.log('Testuji res', res.dateFrom, res.dateTo, response.segmentFrom, response.segmentTo, dateRangeOverlaps(res.dateFrom, res.dateTo, response.segmentFrom, response.segmentTo))
            if (dateRangeOverlaps(res.dateFrom, res.dateTo, response.segmentFrom, response.segmentTo)) {
                let removedRes = reservations.splice(i, 1)
                response.segmentTo = res.dateTo > response.segmentTo ? res.dateTo : response.segmentTo,
                //response.count += 1
                response.overlappingReservations = [...response.overlappingReservations, ...removedRes]
                response.remainingReservations = reservations
            } else {
                i++
            }
        }

        
        return response
    }
    

    const makeFreeBlocksSegment = (from: Date, to: Date, byTime: number, court: string, segKey: number, season: string): Segment => {
        var freeSegment: Segment = {
            key: segKey,
            type: "blocks",
            court: court,
            //reservations: overlaps.overlappingReservations, 
            //dateFrom: new Date(overlaps.segmentFrom),
            //dateTo: new Date(overlaps.segmentTo),
            blocks: [],
            blocksCnt: 0,
            segmentStarts: new Date(from),
            segmentEnds: new Date(to),
        }
        var blockKey = 0
        var dt = new Date(from)
        var nextDt = new Date(from)
        while (dt < to) {
            nextDt.setHours(dt.getHours(),dt.getMinutes() + byTime, 0, 0)
            var block: Block = {
                key: blockKey,
                dateFrom: new Date(dt),
                dateTo: new Date(nextDt),
                segmentStarts: from,
                segmentEnds: to,
                selected: false,
                court: court,
                season: season
            }
            freeSegment.blocksCnt += 1
            freeSegment.blocks.push(block)
            blockKey += 1
            dt = new Date(nextDt)
        }
        return freeSegment
    }

    const createSegments = (season: Season, date: Date, reservations: AllResTypes[], court: string, unlockDate: Date):Segments => {
        //console.log('to je peklo', court)
        let blocks: Segments = []
            

        let openHours = {
            from: season.openFrom,
            to: season.openTo,
        }
        var remainingReservations: AllResTypes[] = [...reservations]
        

        let dt = new Date(date)
        dt.setHours(Math.floor(openHours.from/100),openHours.from % 100,0,0)
        let end = new Date(dt)
        end.setHours(Math.floor(openHours.to/100),openHours.to % 100,0,0)
        
        let isLockedPeriod = date >= season.from && date <= season.lockedTo ? true : false
        let isLocked = unlockDate && unlockDate <= date ? false : isLockedPeriod
        //var i = 0
        var j = 0
        var s = 0

        let lockedResBlock: SegmentRes = {
            key: j,
            type: "reservations",
            //dateFrom: new Date(dt),
            //dateTo: new Date(end),
            //mainUid: "ltc",
            //type: "locked",
            //block: j,
            court: court,
            blocksCnt: calcBlocks(dt, end),
            segmentStarts: new Date(dt),
            segmentEnds: new Date(end),
            //season: season,
            //selected: false,
            reservations: [
                {
                    dateFrom: new Date(dt),
                    dateTo: new Date(end),
                    club: props.season.club,
                    court: court,
                    day: dt.getDay(),
                    
                    mainUid: "ltc",
                    
                    season: season.firebaseId,
                    timeFrom: parseInt(extractTime(new Date(dt))),
                    timeTo: parseInt(extractTime(new Date(end))),
                    type: ReservationTypes.locked,
                    status: ReservationStatuses.blocked,
            
                    updateTime: new Date,
                    text: "Zámek sezóny"

                }
            ]
        }
        //lockedRes.dateTo.setHours(lockedRes.dateTo.getHours(),lockedRes.dateTo.getMinutes() -1)
        if (isLocked) {
            blocks.push({...lockedResBlock}) 
            return blocks
        }
        //console.log(dt, end)
        
        var segmentStarts = new Date(dt)
        var segmentEnds = new Date(end)
        while (dt < end) {
            //console.log(reservations,  i < reservations.length, i)
            var nextDt: Date = new Date(end)
            if (remainingReservations.length > 0 && isDateEqual(dt,remainingReservations[0].dateFrom)) {
                //console.log('Proslo to')

                let overlaps = getOverlappingReservations(remainingReservations)
                //console.log('Overlaps', )
                remainingReservations = [...overlaps.remainingReservations]
                //console.log('Overlaps: ', overlaps)
                var resSegment: Segment = {
                    key: s,
                    type: "reservations",
                    court: court,
                    reservations: overlaps.overlappingReservations, 
                    //dateFrom: new Date(overlaps.segmentFrom),
                    //dateTo: new Date(overlaps.segmentTo),
                    blocksCnt: calcBlocks(new Date(overlaps.segmentFrom), new Date(overlaps.segmentTo)),
                    segmentStarts: new Date(overlaps.segmentFrom),
                    segmentEnds: new Date(overlaps.segmentTo),
                }
                nextDt = new Date(overlaps.segmentTo)
                
                segmentStarts = new Date(overlaps.segmentTo)
                segmentEnds = new Date(end)

                //i += overlaps.count
                s += 1
                blocks.push(resSegment)
            } else if (remainingReservations.length > 0 && dt < remainingReservations[0].dateFrom) {
                //Till next reservation
                let segment = makeFreeBlocksSegment(dt, remainingReservations[0].dateFrom, 30, court, s, season.firebaseId)
                blocks.push(segment)
                s += 1
                nextDt = new Date(remainingReservations[0].dateFrom)
            } else if (remainingReservations.length == 0 && dt < end) {
                //Till end of open time
                let segment = makeFreeBlocksSegment(dt, end, 30, court, s, season.firebaseId)
                blocks.push(segment)
                s += 1
                nextDt = new Date(end)
            } else {
                nextDt = new Date(end)
            }

            //blocks[j]['dateTo'] = nextDt
            //blocks.push({...block})
            j += 1
            dt = new Date(nextDt)
        }
        //console.log('Blocks', blocks)
        return blocks
    }
    
    const segments = useMemo(() => {
        //console.log('All reservations: ' + Object.keys(props.reservations).length)
        let selectedReservations = Object.entries(props.reservations).filter((item) => {
            //console.log(isDateEqualWOTime(props.date,item[1].dateFrom),props.date,item[1].dateFrom)
            return (
            item[1].court == props.courtId && 
            parseInt(extractTime(item[1].dateFrom)) >= props.season.openFrom && 
            parseInt(extractTime(item[1].dateTo)) <= props.season.openTo) &&
            isDateEqualWOTime(props.date,item[1].dateFrom)
            
        }).map(([key,item]) => {return {...item}}
        )
        //console.log('Selected reservations: ' + selectedReservations.length)
        let sortedReservations = selectedReservations.sort(sortReservations)
        //console.log('Sorted reservations: ', sortedReservations)
        //console.log(sortedReservations)
        let blocks = createSegments(props.season, props.date, sortedReservations, props.courtId, props.unlockDate)
        //console.log(blocks)
        return blocks
        
    },
    [props.reservations, props.date, JSON.stringify(props.season), JSON.stringify(props.unlockDate)])
    
    /* useEffect(() => {
        setActiveFields(prevState => {
            let courts = Object.keys(prevState)
            let newState = {...prevState}
            Object.entries(props.reservations).forEach(([k,o]) => {
                //console.log(o)
                if (courts.includes(o.court)) {
                    newState[o.court].reservations[k] = {...o}
                }
            })
            return newState
            
        })
       
        
    }, [props.reservations]) */
  
    return (
        <View 
            style={{
                marginLeft: 5,
                marginRight: 5
            }}>
                <Text maxFontSizeMultiplier={1.2} style={{
                    //fontWeight: "bold",
                    fontFamily: 'AvertaStd-Semibold',
                }}>
                    {i18n.t(tokens.app.screens.reservations.court)+": "+props.courtIdText.toString()+" - "+ courtTypes[props.fieldType] }
                </Text>
            <View 
            
            
            style={{
                flexDirection: "row",
                minHeight: 90,
                //height: 80
            }}
            >   
                <FlatList scrollEnabled={false} horizontal={true}
                data={segments}
                renderItem={({item}) => {
                    return (
                        <ReservationSegment 
                        segment={item}
                        />
                    )
                }}
                />
                
            </View>
            </View>
       
    )
}


export default FieldLane


