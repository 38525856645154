import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import i18n, { tokens } from "../i18n";
import FormEntry from "./FormEntryNew";
import FormHeading from "./FormHeading";
import TimeInput from "./TimeInput/TimeInput";

type Props = {
    dispatchActionForChanges: (evalChanges) => void,
    reservationTimeData: {
        dateFrom: Date,
        dateTo: Date,
        timeFrom: number,
        timeTo: number,
        court: string,
        firebaseId?: string
    },
    validations: {
        dateFrom?: {validity: boolean, error: string}
        dateTo?: {validity: boolean, error: string}
        timeFrom?: {validity: boolean, error: string}
        timeTo?: {validity: boolean, error: string}
        court?: {validity: boolean, error: string}
    },
    disabled: boolean
}
function ReservationTime(props:Props):JSX.Element {
    //console.log('Validace',props.validations, props.reservationTimeData)
    const [editable, setEditable] = useState(props.reservationTimeData.firebaseId?false:true)
    
    const dispatchChange = (callback: (params) => void) => {
        props.dispatchActionForChanges(
            callback
        )
    }

    

    return (
    <View style={styles.container}>
        <FormHeading heading={i18n.t(tokens.app.screens.manageReservation.timingHeading)} editButton={props.disabled?null:() => setEditable(prev => !prev)}/>
        <FormEntry
        caption={i18n.t(tokens.app.screens.manageReservation.reservationTimeFrom)}
        error={props.validations?.timeFrom?.error} 
        validity={props.validations?.timeFrom?.validity}
        editable={editable}
        >
            <TimeInput disabled={!editable} value={props.reservationTimeData.timeFrom} onValueChange={(value) => dispatchChange(() => {
                return {timeFrom: value}
            })}/>
        </FormEntry>
        <FormEntry
        caption={i18n.t(tokens.app.screens.manageReservation.reservationTimeTo)}
        error={props.validations?.timeTo?.error} 
        validity={props.validations?.timeTo?.validity}
        editable={editable}
        >
            <TimeInput disabled={!editable} value={props.reservationTimeData.timeTo} onValueChange={(value) => dispatchChange(() => {
                //console.log(props.reservationTimeData.dateTo, value, updateDate(props.reservationTimeData.dateTo, String(value)))
                return {timeTo: value}
            })}/>
        </FormEntry>
    </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    }
})

export default ReservationTime

/*
<FormEntry 
      caption={i18n.t(tokens.app.screens.manageReservation.reservationTimeFrom)}
      error={reservationValidations.timeFrom.error} 
      validity={reservationValidations.timeFrom.validity}>
        <View style={{alignSelf: "flex-end"}}>
        <TimeInput  
        value={newReservation.timeFrom?.toString()}
        onValueChange={(value) => {
            console.log('Proslo validaci a chic poslat ' + value)
            let dt = new Date(newReservation.dateFrom)
            //dt.setHours(0,0,0,0)
            let newDateFrom = new Date(dt)
            let [hoursFrom, minutesFrom] = splitTime(value)
            if (parseInt(value) < 2400) {
              newDateFrom.setHours(hoursFrom, minutesFrom)
            } else {
              newDateFrom = new Date(newReservation.dateFrom)
            }
            dispatch({type: "EDITED_RESERVATION", data: {timeFrom: value, dateFrom: newDateFrom}})
        }}
        />
        </View>
      </FormEntry>
 */