import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable } from 'react-native'

import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Market, MarketLongreservation, MarketOpengame, MarketReservation, UserOrClub, user } from '../../../../functions/src/interfaces';
import { useAppNavigation } from '../../../store/hooks';
import MarketEntryContent from './MarketEntryContent';



type Props = {
    item: MarketLongreservation<Market> | MarketOpengame<Market> | MarketReservation<Market>
    users: {[userId: string]: UserOrClub}
    index: number
}

const MarketEntry: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)
    const navigation = useAppNavigation()
    
    return (
        <View style={styles.entityContainer} key={props.index}>
        <Pressable style={{flex: 1}} onPress={() => {
            //console.log('Chci navigovat na: ', props.item.uid)

            navigation.navigate('ManageMarket', {type: props.item.type, uid: props.item.uid, marketId: props.item.firebaseId, club: props.item.club})
            }}>
            <MarketEntryContent item={props.item} users={props.users}/>
            </Pressable>
            
        </View>
    )
    
}


const styles = StyleSheet.create({
        
    entityContainer: {
        
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 8,
        borderColor: '#cccccc',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        paddingBottom: 0,
        backgroundColor: "white",
        borderRadius: 10,
        marginLeft: 5,
        marginRight: 5,
    },
    entityText: {
        fontSize: 16,
        color: '#333333'
    }
})


export default MarketEntry

