import { DrawerActions, Route, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState, useRef ,createRef } from 'react'
import { View, Text, Pressable, Platform, Linking, StyleSheet, useWindowDimensions } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import { shallowEqual } from 'react-redux';
import { useAppNavigation, useAppSelector } from '../store/hooks';
import { getCurrentUserWithRoles } from '../store/selectors';
import ClicableUserAvatar from './ClickableUserAvatar';
import { normalize } from '../utilities/constants'
import { mainColor, mainFontBold } from '../utilities/globalStyles';
import SmallButton from './SmallButton';
import i18n, { tokens } from '../i18n';
import { Club, CreditBalance, UserRoles } from '../../functions/src/interfaces';
import { RootStore } from '../store/reducers/rootReducer';
import { Badge } from 'react-native-elements';
import { UserOrClub } from '../store/reducers/usersReducer';
import { monthString } from '../utilities/funcs';
type Props = {
    searchAction?: (params:any) => void,
    addAction?: (params:any) => void,
    title: string,
    route?: Route<string, {basicButtons?: boolean}>
}

function RightTopBar(props:Props) {
    //console.log('Top bar props',props)
    const navigation = useAppNavigation()
    const dims = useWindowDimensions()
    const user: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    const [detail, setDetail] = useState(false)
    const userBalance:{[clubId: string]: CreditBalance} = useAppSelector((state:RootStore) => state.auth.allUserCredits?.[state.auth?.selectedClub]?.creditBalance, shallowEqual)
    const notifications = useAppSelector((state: RootStore) => {
        let reducer = state.notifications
        let notifs = Object.values(reducer.subscribedNotifications)
        var out = 0
        for (let i = 0; i < notifs.length; i++) {
            let notification = notifs[i]
            out += notification.updateDate >= reducer.notificationsLastRead.all ? 1 : 0
        }
        return out
    }, shallowEqual)
    const slectedClub: Club = useAppSelector((state: RootStore) => state.auth?.allClubs?.[state.auth?.selectedClub], (a, b) => JSON.stringify(a) == JSON.stringify(b))

    const navigateProfile = () => navigation.navigate('Profile', {uid: user?.type == 'user'? user?.uid: user.firebaseId})
    const navigateMyClubs = () => navigation.navigate('ManageMyClubsScreen', {})
    return (
        <View style={styles.wrapper}>
        <View style={styles.container}>
            {user && user?.type /* && props.route?.params?.basicButtons == true */?
            <ClicableUserAvatar 
            uid={user.type == 'user'? user?.uid: user.firebaseId} size={normalize(40)} 
            onPress={() => setDetail(prev => !prev)}
            />:null}
            <View>
            <Text maxFontSizeMultiplier={1.2} style={styles.titleText}>{props.title}</Text>
            {/* <Text maxFontSizeMultiplier={1.2} style={{color: "white", flexWrap: "wrap"}}>{String(JSON.stringify(Math.min(dims.height, dims.width)))}</Text> */}
            </View>
            <View style={styles.buttonContainer}>
            {Platform.OS == "web" && slectedClub?.homepage && slectedClub?.homepage != ""?
            <Pressable onPress={() => {
                window.location = (slectedClub.homepage) as string & Location
            }}>
                <Ionicons 
                name="home-outline"
                color="white"
                size={normalize(24)}
                //type="ionicon"
                />
            </Pressable>:null}
                
            
            {/* props.route?.params?.basicButtons ==  */true?<Pressable style={styles.button} onPress={() => navigation.dispatch(DrawerActions.openDrawer)}>
                <Ionicons 
                name="menu-outline"
                color="white"
                size={normalize(24)}
                //type="ionicon"
                />
                {notifications > 0?
                  <Badge
                    value={notifications}
                    containerStyle={{ position: 'absolute', top: 3, right: -5}}
                    badgeStyle={{backgroundColor: "red" }}
                  />
                  :null}
            </Pressable>: null}
            </View>
        </View>
        {detail?
        
            <View style={styles.detailContainer}>
                <View style={styles.detailButtonContainer}>
            <SmallButton
            onPress={navigateProfile}
            buttonText={i18n.t(tokens.app.components.rightMenu.profileRoute)}
            baseButtonHeight={30}
            baseTextSize={16}
            />
            {user?.type == "user"?
            <SmallButton
            onPress={navigateMyClubs}
            buttonText={i18n.t(tokens.app.components.rightMenu.myClubsRoute)}
            baseButtonHeight={30}
            baseTextSize={16}
            />:null}
            </View>
            
            <View
            style={styles.creditView}
            >   
                {user?.type == "user"?<Text style={styles.creditText}>{`${i18n.t(tokens.app.screens.payment.paymentFreeCredit)} ${String(userBalance || 0)}`}</Text>:null}
                {user?.type == "clubs"?<Text style={styles.creditText}>{`${i18n.t(tokens.app.screens.manageClub.manageClubActiveUntil)} ${monthString(user?.activeUntil)}`}</Text>:null}
            </View>
        </View>:null}
        </View>
    )
}
export default RightTopBar;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row", 
        backgroundColor: mainColor, 
        width:'100%', 
        //justifyContent: "center", 
        height: normalize(50), 
        alignItems:"center", 
        borderBottomRightRadius:0,
        paddingLeft: 10,
        paddingRight: 10,
        minHeight: 50,
    },
    titleText: {
        fontFamily:mainFontBold, 
        fontSize: normalize(30), 
        color:"white", 
        marginLeft:10
    },
    creditText: {
        fontFamily:mainFontBold, 
        fontSize: normalize(15), 
        color:"white", 
        marginLeft:10
    },
    creditView: {
        marginRight: 20,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: "auto", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems: "center"
    },
    buttonContainer: {
        flexDirection:"row", 
        backgroundColor: mainColor, 
        marginLeft:"auto", 
        justifyContent:"center", 
        alignItems:"center", 
        
        //height:50
    },
    detailButtonContainer: {
        flexDirection:"row", 
        flexWrap: "wrap",
        flex: 0.8
        //height:50
    },
    detailContainer: {
        flexDirection:"row", 
        backgroundColor: mainColor, 
        alignItems:"center", 
        //height:50
    },
    button: {
        width:30, 
        height: 50, 
        borderWidth:0, 
        borderColor: "black", 
        justifyContent:"center",
        marginLeft: 5,
    },
    wrapper: {
        flexDirection: "column",
        width: "100%"
    }
})