import View from '@expo/html-elements/build/primitives/View';
import { Route, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Platform, StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual } from 'react-redux';
import { AllResTypes, Club, Reservation, ReservationTypes, UserRoles } from '../../../../functions/src/interfaces';
import GamePlayers from '../../../components/GamePlayersNew';
import ResultScore from '../../../components/ResultScore';
import { editReservation, subscribeForReservation } from '../../../store/actions/actionsReservationsNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { ReservationActions } from '../../../store/reducers/reservationReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { getCurrentUserWithRoles } from '../../../store/selectors';
import ButtonComponent from '../ButtonComponent';
import Dialog from './Dialog';
import PriceComponent from '../../ManageMarketScreen/CommonMarketComponents/PriceComponent';
import ReservationDetail from './ReservationDetail';
import ReservationTime from './ReservationTime';
import { isAllowedToEdit, validations } from '../ReservationValidations';
import { StateLoader } from '../../../utilities/interfaces';
import { UserOrClub } from '../../../store/reducers/usersReducer';

type Props = {
    clubData: Club,
    reservationData: StateLoader<Reservation>  & {allValid: boolean},
    currentUser: UserOrClub<UserRoles>
}

function ReservationDetailFull(props: Props):JSX.Element {
    
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()


    const [isOverlayVisible, setIsOverlayVisible] = useState({visible: false, type: "longreservation", data: {}})
    

    const isElapsed = (dateTo: Date) => {
        let currDt = new Date
        return currDt > dateTo
    }
     
    //const [validators, setValidators] = useState({})
    
    //console.log('Update rezervace: ',reservationData)
    //const currentUser = useAppSelector(getCurrentUserWithRoles, )
    //const seasons = useAppSelector(state => state.auth.seasons, shallowEqual)

    /* useEffect(() => {
        if (seasons && reservationData.data?.season && seasons[reservationData.data.season]) {
            setValidators(validations(seasons[reservationData.data.season]))
        }
        
    }, [seasons])
 */
    

    const editButton = () => {
        if (props.reservationData.data.type == ReservationTypes.reservation) {
            navigation.navigate("ManageMarket",{marketId: props.reservationData.data.reservationRequestId, type: props.reservationData.data.type, club: props.reservationData.data.club})
        } else if (props.reservationData.data.type == ReservationTypes.longreservation) {
            
            setIsOverlayVisible({type: "longreservation", visible: true, data: {sds: ""}})
        }
    }

    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        dispatch(editReservation(changes))
    }

    return (

        <View style={styles.container}>
            <Dialog
            isOverlayVisible={isOverlayVisible.visible}
            setIsOverlayVisible={setIsOverlayVisible}
            reservationData={props.reservationData}
            />
            <ReservationDetail 
            reservationData={props.reservationData.data}
            disabled={true}
            dispatchActionForChanges={dispatchChanges}
            />
            <ReservationTime
            reservationData={props.reservationData}
            //editButton={editButton}
            />
            <GamePlayers
            visible={!(props.reservationData.data?.isOnBehalf && props.reservationData.data?.isNotRegistered) || false}
            disabled={true}
            reservationOwner={props.reservationData.data.mainUid}
            selectableType={true}
            gameType={props.reservationData.data.gameType}
            gamePlayers={props.reservationData.data.gamePlayers}
            players={props.reservationData.data.players}
            //type={reservationData.data.type}
            dispatchActionForChanges={dispatchChanges}
            //editButton={editButton}
            dragEnabled={false}//currentUser.uid == testingReservation.mainUid || currentUser.isAdmin == true || currentUser.isGod == true
            />
            <PriceComponent 
            isChanged={props.reservationData.isChanged}
            firebaseId={props.reservationData.data?.reservationRequestId}
            reservationData={{...props.reservationData.data, priceCompDataType: 'reservation'}}
            allValid={props.reservationData.allValid}
            type={'reservation'}
            />
            {isElapsed(props.reservationData.data.dateTo)?<ResultScore 
            resultId={props.reservationData.data.resultId} 
            reservation={props.reservationData.data}
            />:null}
            <ButtonComponent
            club={props.clubData}
            isChanged={props.reservationData.isChanged}
            isAllowedToEdit={isAllowedToEdit(props.currentUser, props.reservationData.data.mainUid, props.reservationData.data.status, props.reservationData.data.club)}
            reservationData={props.reservationData}
            firebaseId={props.reservationData.data.reservationRequestId}
            currentUser={props.currentUser}
            editButton={editButton}
            />
        </View>
               
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
})

export default ReservationDetailFull