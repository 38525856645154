import { StyleSheet } from "react-native";
import { normalize, windowWidth } from "./constants";
export const mainColor = '#00233f'
export const secondColor = '#93b786'
export const mainFontBold = 'AvertaStd-Semibold'
export const mainFont = 'AvertaStd-Regular'
export const padding = 5

export const gameTypesColors = {
    'opengame':'lightyellow',
    'longreservation':'lightblue',
    'training':'lightgreen',
    'blocked':'lightgrey',
    'locked':'lightgrey',
    'reservation':"#ffe0e0",
    'onBehalf':"#FFE5B4",
    'subscription':"#ffe3b7",
    'credit':"#a7f799"
}

export const styles = {
    container: {
        flex: 1,
        //alignItems: 'stretch',
        //width: windowWidth,
        //padding: 5,
    },
    button: {
        backgroundColor: secondColor,
        marginLeft: 30,
        marginRight: 30,
        marginTop: 10,
        marginBottom: 10,
        height: 40,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 100,
        //alignSelf: "stretch",
        //flex: 1,
    },
    footerView: {
        //flex: 1,
        alignItems: "center",
        marginTop: 20
    },
    footerText: {
        fontSize: normalize(16),
        color: '#2e2e2d',
        alignSelf: "center",
        fontFamily: mainFont,
    },
    footerLink: {
        color: mainColor,
        fontWeight: "bold",
        fontSize: normalize(15),
        fontFamily: mainFontBold,
    },
    buttonText: {
        color: 'white',
        fontSize: normalize(15),
        fontFamily: mainFont,
    },
    entityText: {
        fontSize: normalize(16),
        color: '#333333',
        //marginRight: 'auto',
        //width: (windowWidth - 2*padding) ,
        //fontWeight: "600",
        fontFamily: 'AvertaStd-Regular',
        //alignSelf: "flex-start",
        
    },
    inputOk: {
        fontSize: normalize(16),
        borderRadius: 5,
        overflow: 'hidden',
        backgroundColor: 'white',
        height: normalize(30),
        //marginTop: 10,
        //marginBottom: 10,
        marginLeft: 0,
        marginRight: 10,
        paddingLeft: 16,
        //borderBottomWidth: 1,
        borderBottomColor: "green",
        //alignSelf:"stretch",
    },
    inputKo: {
        fontSize: normalize(16),
        borderRadius: 5,
        overflow: 'hidden',
        backgroundColor: 'white',
        height: normalize(30),
        //marginTop: 10,
        //marginBottom: 10,
        marginLeft: 0,
        marginRight: 10,
        paddingLeft: 16,
        //borderBottomWidth: 1,
        borderBottomColor: "red",
        //alignSelf:"stretch",
    },
    toolbar: {
        width: "100%", 
        height: 50, 
        flexDirection: "row", 
        alignContent:"space-between", 
        justifyContent: "space-between",
        borderBottomWidth: 1, 
        borderColor: "grey", 
        marginBottom: 3,
        alignItems: "center",
        backgroundColor: "white",
        margin: 0,
        marginHorizontal: 0,
        marginVertical: 0,
        //paddingRight: 10,
        //paddingLeft: 10,
    },
    textInput: {
        //borderBottomWidth: 1,
        width: 70,
        borderRadius: 10,
        fontSize: 20,
        height:30,
        textAlign: "right"
    },
    messageText: {
        fontSize: normalize(20),
        fontFamily: mainFont
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
}

export const globalStyleSheet = StyleSheet.create(styles)