import { Route, useIsFocused, useNavigation } from '@react-navigation/native';
import React, { createRef, useEffect, useLayoutEffect, useState } from 'react';
import { DeviceEventEmitter, FlatList, SectionList, Text, Pressable, View } from 'react-native';
import DelayInput from 'react-native-debounce-input';
import { ButtonGroup } from 'react-native-elements';
import { shallowEqual } from 'react-redux';
import { UserRoles } from '../../../functions/src/interfaces';
import RenderContact from '../../components/Contact';
import FlexedMessage from '../../components/FlexedMessge';
import RightTopBar from '../../components/RightTopBar';
import WebWrapper from '../../components/WebWrapper';
import i18n, { tokens } from '../../i18n';
import { searchContacts } from '../../store/actions/actionsContacts';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { UserOrClub } from '../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../store/selectors';
import { normalize } from '../../utilities/constants';
import { monthString, subscriptionsToObject } from '../../utilities/funcs';
import { globalStyleSheet, mainFontBold } from '../../utilities/globalStyles';
import RenderContactWrap from './Components/RenderContactWrap';
import styles from './styles';
import BigButton from '../../components/BigButton';
import { shareExcel } from '../../utilities/exportXlsx';
import { isSearchBarAvailableForCurrentPlatform } from 'react-native-screens';



type Props = {
    route: Route<'ContactsScreen', { action?: string, alreadySelected?: string[] }>
}

function ContactsScreen(props: Props): JSX.Element {
    //console.log('Contacts screen props', props)
    const action = props.route?.params?.action
    const selectedPlayers = props.route?.params?.alreadySelected
    const navigation = useAppNavigation()
    const currentUser = useAppSelector(getCurrentUserWithRoles, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const subscriptions = useAppSelector((state: RootStore) => subscriptionsToObject({ subscriptions: state.contacts.subscriptions, activeSeason: state.auth.activeSeason, currDate: new Date(JSON.parse(state.auth.currDate)) }), (a, b) => JSON.stringify(a) == JSON.stringify(b))
    //console.log(subscriptions)
    const linkedUsers = useAppSelector((state: RootStore) => state.users.linkedUsers, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const contacts = useAppSelector((state: RootStore) => state.auth.amInContacts, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const users = useAppSelector((state: RootStore) => state.users.usersData, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const clubregistered = useAppSelector((state: RootStore) => state.users.clubmembers, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    const searchedContacts = useAppSelector((state: RootStore) => {
        let searchResults = state.contacts.contacts
        let usersData = state.users.usersData
        let myContacts = state.auth.amInContacts
        const memberships = subscriptionsToObject({ subscriptions: state.contacts.subscriptions, activeSeason: state.auth.activeSeason, currDate: new Date(JSON.parse(state.auth.currDate)) })
        var out = {
            [tokens.app.screens.contacts.contactsScreenMyContacts]: [],
            [tokens.app.screens.contacts.contactsClubmembersTitle]: [],
            [tokens.app.screens.contacts.contactsOtherTitle]: [],
        }
        let searchResUids = Object.keys(searchResults)
        for (let i = 0; i < searchResUids.length; i++) {
            let searchResUid = searchResUids[i]
            let isActive = memberships[searchResUid] && (memberships[searchResUid].isActiveFloat || memberships[searchResUid].isActiveTour) ? true : false
            if (searchResults[searchResUid].linkedFlag == true) {
                            
                searchResults[searchResUid]['linkedToUser'] = users[searchResults[searchResUid].linkedTo]
                
            }
            if (Object.keys(myContacts).includes(searchResUid)) {
                out[tokens.app.screens.contacts.contactsScreenMyContacts].push({type: "user", ...searchResults[searchResUid]})
            } else if (isActive) {
                out[tokens.app.screens.contacts.contactsClubmembersTitle].push({type: "user", ...searchResults[searchResUid]})
            } else {
                out[tokens.app.screens.contacts.contactsOtherTitle].push({type: "user", ...searchResults[searchResUid]})
            }
        }

        let result =  Object.entries(out).map(([key, value]) => {
            return { title: key, data: value }
        }).filter(item => item.data.length > 0)
        //console.log(result)
        return result

    }, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const [searchMode, setSearchMode] = useState(false)
    const inputRef = createRef();
    const [searchValue, setSearchValue] = useState("")
    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            DeviceEventEmitter.removeAllListeners("event.mapNewPlayerToGame")
        };
    }, []);

    
    //const [entityText, setEntityText] = useState('')
    //const [contacts, setContacts] = useState(props.contacts)

    //const contactsRef = firebase.firestore().collection('userspublic')
    //const userID = props.extraData.uid

    const [contactData, setContactData] = useState([])

    const [buttonGroupIndex, setButtonGroupIndex] = useState(0)


    const [buttons, setButtons] = useState(
        [
            i18n.t(tokens.app.screens.contacts.contactsMyList),
            i18n.t(tokens.app.screens.contacts.contactsAllMembList)
        ]
    )
    const isAllowed = (user: UserOrClub<UserRoles>) => {
        if (!user) {
            return false
        } else if (user.type == "user") {
            return user.isAdmin == true || user.isGod == true
        } else if (user.type == "clubs") {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (isAllowed(currentUser)) {
            setButtons(
                [
                    i18n.t(tokens.app.screens.contacts.contactsMyList),
                    i18n.t(tokens.app.screens.contacts.contactsAllMembList),
                    i18n.t(tokens.app.screens.contacts.contactsAllClubList)
                ]
            )
        }
    }, [currentUser])

    /* const renderUser = ({item, index}) => {
        return (
            <View style={styles.entityContainer}>
                <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>
                    {index}. {item.username}
                </Text>
                <Button title="Add" onPress={() => {props.addContact(item)}}/>
            </View>
        )
    } */
    const getClubRegistered = () => {
        
        let clubRegisteredLinked = Object.entries(users).reduce((prev, [uid, user]) => {
            if (uid == "09Lkg10UYskt81pD38Jc") {
                //console.log('Hej', user)
            }
            if (user.type == "user") {
                if (user.clubs?.includes(selectedClub)) {
                    return {...prev, [uid]: user}
                } else if (user.linkedFlag == true) {
                    let linkedToUser = users[user.linkedTo]
                    //console.log('linkedto', linkedToUser)
                    if (linkedToUser.type == "user") {
                        if (linkedToUser.clubs.includes(selectedClub)) {
                            return {...prev, [uid]: user}
                        } else {
                            return prev
                        }
                    } else if (linkedToUser.type == "clubs") {
                        //console.log('wtf', user, linkedToUser, selectedClub)
                        if (linkedToUser.firebaseId == selectedClub) {
                            return {...prev, [uid]: user}
                        } else {
                            return prev
                        }
                    } else {
                        return prev
                    }
                } else {
                    return prev
                }
                
            } else {
                return prev
            }
        }, {})
        //console.log('TEST',clubRegisteredLinked)
        return Object.keys(clubRegisteredLinked)
    }

    const createCore = (index: number) => {
        if (index == 0) {
            return [...Object.keys(contacts),...Object.keys(linkedUsers)]
        } else if (index == 1) {
            return Object.values(subscriptions).filter(sub => sub.isActiveFloat == true || sub.isActiveTour == true).map((sub) => sub.uid)
        } else if (index == 2) {
            return [...getClubRegistered()]
        } else {
            return []
        }
    }

    useEffect(() => {
        //console.log('State changed')
        //console.log('Showing', buttonGroupIndex)
        if (searchMode == false) {
            //const memberships = subscriptionsToObject(subscriptions)
            //console.log('memberships', memberships)
            const core = createCore(buttonGroupIndex)
            //console.log('Abcd', core)
            //delete core[props.auth.uid]
            if (core) {
                //console.log('core', core)
                //let keys = Object.keys(core)
                //for (let i = 0; i < keys.length; i++) {

                //}
                let newList = []
                core.forEach((key) => {
                    if (key) {
                        //console.log(key)
                        
                        var user = {  
                            ...users[key]
                        }
                        //console.log('User: ' + (user.type == "user" ? user.first_name + user.family_name : user.club_name))
                        if (user.type == "user" && user.linkedFlag == true) {
                            
                            user['linkedToUser'] = users[user.linkedTo]
                            
                        }

                        user['isActiveFloat'] = subscriptions[key] && subscriptions[key]['isActiveFloat'] ? subscriptions[key].isActiveFloat : false
                        user['isActiveTour'] = subscriptions[key] && subscriptions[key]['isActiveTour'] ? subscriptions[key].isActiveTour : false
                        user['membEnds'] = subscriptions[key] ? (subscriptions[key]['isActiveFloat'] ? subscriptions[key].ends : subscriptions[key]?.lastEnded) : null
                        user['tours'] = subscriptions[key] && subscriptions[key].tours ? subscriptions[key].tours : null
                        //console.log('user', user)
                        if (user['email'] ==  '******') {
                            console.log('Skip user that is deporsanalized ' + key)
                        } else if (buttonGroupIndex == 1 && (user['isActiveFloat'] == true || user['isActiveTour'] == true) && (user.type == "user")) {
                            //console.log('User ' + user.uid + ' ' + user.type)
                            newList.push(user)
                        } else if ([0, 2].includes(buttonGroupIndex)) {
                            newList.push(user)
                        }

                    }
                })
                newList = newList.sort((a, b) => a.family_name > b.family_name ? 1 : -1)
                //console.log(newList)
                setContactData(newList)
            } else {

            }
        }
    }, [clubregistered, users, buttonGroupIndex, subscriptions, searchMode])

    /* useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Pressable>
                    <Text style={{color: "white"}}>dhjfhd</Text>
                </Pressable>
                
            ),
            
        })
        navigation.setParams({basicButtons: false})
    }, []) */
    const exportContacts = (contactlist: any[]) => {
        var out = []
        for (let i = 0; i < contactlist.length; i++) {
            const currCont = contactlist[i]
            out.push(
                {
                    first_name: currCont["first_name"],
                    family_name: currCont["family_name"],
                    email: currCont["email"],
                    phone: currCont["phone"]
                }
            )
        }
        return out
    }

    const textBoxWrap = (text: string) => {
        if (text.length > 2) {
            setSearchMode(true)
            dispatch(searchContacts(text))
        } else {
            setSearchMode(false)
        }
        
    }

    console.log('Rerendering contacts')
    const isFocused = useIsFocused()
    return (


        <View style={styles.container}>
            {/* action ? null : <RightTopBar title={props.route?.name} searchAction={() => setSearchMode(prev => !prev)} /> */}
            
            <WebWrapper>
                {/* <TopBar textBoxAction={props.searchContacts} navigation={navigation}/>  */}
                {/* <CustomParallax  navigation={navigation} textBoxAction={props.searchContacts} searchbarAction={searchContacts} tabs={[]}> */}


                
                <ButtonGroup
                onPress={(val) => { setButtonGroupIndex(val) }}
                selectedIndex={buttonGroupIndex}
                buttons={buttons}
                disabled={searchMode}
                //buttonStyle={{borderColor: secondColor}}
                selectedButtonStyle={{ backgroundColor: "grey" }}
                containerStyle={[globalStyleSheet.toolbar, styles.toolbar]}
                buttonContainerStyle={{ margin: 0, padding: 0, height: "100%" }}
                textStyle={{ color: "black", fontFamily: mainFontBold, fontWeight: "bold" }}
                />

                <View style={styles.listContainer}>
                    <View
                    style={{ alignItems:"stretch", justifyContent: "center", height: 50, padding: 5}}
                    >
                    <DelayInput
                    placeholder={i18n.t(tokens.app.screens.contacts.contactsSearch)}
                    value={searchValue}
                    minLength={3}
                    placeholderTextColor="black" 
                    inputRef={inputRef}
                    onChangeText={textBoxWrap}
                    delayTimeout={500}
                    style={[globalStyleSheet.inputOk, { height: 50, width: "100%" }]}
                    />
                    </View>
                    {searchMode == false ? contactData.length > 0 ?
                        <View style={{flex: 1}}>
                            <View style={{flex: 1}}>
                            <FlatList
                            //data={ props.contactsStore && props.contactsStore.length > 0 ? (props.contactsStore):(users)}
                            data={contactData}
                            //keyboardShouldPersistTaps={'allways'}
                            renderItem={({ item, index }) => <RenderContactWrap item={item} action={action} searchMode={searchMode} isAllowed={isAllowed(currentUser)} selectedPlayers={selectedPlayers}/>
                            }
                            /* scrollEnabled={false} */
                            keyExtractor={(item) => item.uid}
                            removeClippedSubviews={true}
                        />
                        </View>
                        {(currentUser.type == "user" && (currentUser.isAdmin == true || currentUser.isGod == true)) || currentUser.type == "clubs" ? 
                        <View style={{minHeight:60}}>
                            <BigButton 
                            buttonText='Export'
                            onPress={() => shareExcel(exportContacts(contactData), "Contacts")}
                            />
                        </View>  : null}
                        </View>  :
                        <FlexedMessage message={i18n.t(tokens.app.screens.contacts.noContactsYetMessage)}/>
                        
                        : null}
                    {searchMode == true ?
                        <SectionList
                            sections={searchedContacts}
                            keyExtractor={(item) => item.uid}
                            renderItem={({ item, index }) => <RenderContactWrap item={item} action={action} searchMode={searchMode} isAllowed={isAllowed(currentUser)} selectedPlayers={selectedPlayers}/>
                            }
                            renderSectionHeader={({ section: { title } }) => (
                                <Text style={[globalStyleSheet.entityText, { fontFamily: mainFontBold, fontSize: normalize(20) }]}>{i18n.t(title)}</Text>
                            )}
                        />
                        : null}

                </View>
                {/* </CustomParallax>  */}
            </WebWrapper>
            
        </View>

    )
}


export default React.memo(ContactsScreen, (a, b) => true) 
