import authReducer, { AuthState } from './authReducer';
import { combineReducers } from 'redux';
import contactsReducer, { ContactsState } from './contactsReducer';
import reservationReducer, { ReservationsState } from  './reservationReducer'
import marketReducer, { MarketState } from  './marketReducer'
import newsReducer, { NewsState } from './newsReducer';
import usersReducer, { UsersState } from './usersReducer';
import rankingReducer from './rankingReducer';
import checkoutReducer, { CheckoutState } from './checkoutReducer';
import notificationsReducer, { NotificationsState } from './notificationsReducer';
//import { reducer as internet } from 'react-redux-internet-connection';


export type RootStore = {
    auth: AuthState,
    contacts: ContactsState,
    reservations: ReservationsState,
    market: MarketState,
    news: NewsState,
    users: UsersState,
    ranking: {[key: string]: any},
    checkout: CheckoutState,
    notifications: NotificationsState,
}

const rootReducer = combineReducers<RootStore>({
    //internet,
    auth: authReducer,
    contacts: contactsReducer,
    reservations: reservationReducer,
    market: marketReducer,
    news: newsReducer,
    users: usersReducer,
    ranking: rankingReducer,
    checkout: checkoutReducer,
    notifications: notificationsReducer,
});

export default rootReducer