import React, { useState } from "react";
import FormEntry from "../../../components/FormEntryNew";
import { TextInput, View } from "react-native";
import i18n, { tokens } from "../../../i18n";
import { StateLoader } from "../../../utilities/interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Club, UserRoles } from "../../../../functions/src/interfaces";
import { firestoreAutoId, shallowEqualJSON } from "../../../utilities/funcs";
import { RootStore } from "../../../store/reducers/rootReducer";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { AuthActions } from "../../../store/reducers/authReducer";
import { UserOrClub } from "../../../store/reducers/usersReducer";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import FormHeading from "../../../components/FormHeading";
import FormEntryNoCaption from "../../../components/FormEntryNoCaption";
import CustomPickerSelect from "../../../components/CustomPickerSelect/CustomPickerSelect";
import { courtTypes } from "../../../utilities/constants";
import SmallButton from "../../../components/SmallButton";
import BigButton from "../../../components/BigButton";


const CourtEntry = (props: {
    courtIndex: string,
    courtId: string,
}) => {
    const dispatch = useAppDispatch()
    const courtItem = useAppSelector((state: RootStore) => state.auth.managedClub.data.courts[props.courtIndex], shallowEqualJSON)
    //console.log('Rendering', courtItem )
    return (
        <FormEntryNoCaption
        editable={true}
        direction={"row"}
        ><View
        style={{justifyContent: "space-evenly", flexDirection: "row", flex: 1, alignItems: "center"}}
        >
            <TextInput maxFontSizeMultiplier={1.2}
            style={[globalStyleSheet.inputOk]}
            placeholder={i18n.t(tokens.app.screens.manageClub.manageClubCourtId)}
            placeholderTextColor="#aaaaaa"
            onChangeText={(text) => {
                dispatch({type: AuthActions.authChangeCourt, data: {index: props.courtIndex, value: {courtId: text}}})
            }}
            value={courtItem.courtId}
            underlineColorAndroid="transparent"
            autoCapitalize="words"
            //id="club_name"
            />

            <View>
                <CustomPickerSelect
                onValueChange={(value) => dispatch({type: AuthActions.authChangeCourt, data: {index: props.courtIndex, value: {type: value}}})} 
                value={courtItem.type}
                placeholder={{label: i18n.t(tokens.app.screens.manageClub.manageClubCourtType), value: undefined}}
                /* disabled={newReservation.mainUid == props.auth.uid?false:true} */
                items={Object.keys(courtTypes).map(
                    (key) => {
                        //console.log({ label: weekdays[key], value: parseInt(key) })
                        return { label: courtTypes[key], value: key }
                    }
                )
                }/>
                
                </View> 
                <SmallButton
                onPress={() => {
                    dispatch({type: AuthActions.authRemoveCourt, data: {court: props.courtId}})
                }}
                icon={'close-outline'}
                buttonText={""}
                />
            </View>
        </FormEntryNoCaption>
    )
}

type Props = {
    currentUser: UserOrClub<UserRoles>
}

function CourtsDetail(props: Props) {
    const dispatch = useAppDispatch()
    const managedClub: StateLoader<Club> = useAppSelector((state: RootStore) => state.auth.managedClub, shallowEqualJSON)
    const [editCourtsFlag,setEditCourtsFlag] = useState(false)

    return (
        <>
        <FormHeading
        heading={i18n.t(tokens.app.screens.manageClub.manageClubCourts)}
        editButton={() => setEditCourtsFlag(prev => !prev)}
        />
        {true?
        <>
        {Object.keys(managedClub.data?.courts)
        .sort((a,b) => managedClub.data?.courts[a].courtId > managedClub.data?.courts[b].courtId ? 1 : -1)
        .map((key) => {
            let item = managedClub.data.courts[key]
            return <CourtEntry courtIndex={key} key={key} courtId={item.courtId}/>
        })}
        
        
        <View style={{flexDirection: "row", flex: 1}}>
        <BigButton
        onPress={() => dispatch({type: AuthActions.authAddCourtToClub, data: {type: 'clay', courtId: firestoreAutoId()}})}
        buttonText={i18n.t(tokens.app.screens.manageClub.manageClubAddCourtButton)}
        />
        </View>
        </>
        :null}
        </>
    )
}

export default CourtsDetail