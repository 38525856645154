import React from "react";
import FormEntry from "../../../components/FormEntryNew";
import { TextInput } from "react-native";
import i18n, { tokens } from "../../../i18n";
import { StateLoader } from "../../../utilities/interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Club, UserRoles } from "../../../../functions/src/interfaces";
import { shallowEqualJSON } from "../../../utilities/funcs";
import { RootStore } from "../../../store/reducers/rootReducer";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { AuthActions } from "../../../store/reducers/authReducer";
import { UserOrClub } from "../../../store/reducers/usersReducer";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import FormHeading from "../../../components/FormHeading";
import CustomPickerSelect from "../../../components/CustomPickerSelect/CustomPickerSelect";
import { currency } from "../../../utilities/constants";

type Props = {
    currentUser: UserOrClub<UserRoles>
}

function BasicClubInfo(props: Props) {
    const dispatch = useAppDispatch()
    const managedClub: StateLoader<Club> = useAppSelector((state: RootStore) => state.auth.managedClub, shallowEqualJSON)
    const currencies = Object.keys(currency).map((key) => {return {label: currency[key], value: key}})
    return (
        <>
        <FormHeading
        heading={i18n.t(tokens.app.screens.manageClub.manageClubGeneralInfo)}
        />
        <FormEntry caption={i18n.t(tokens.app.screens.manageClub.manageClubClubName)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={i18n.t(tokens.app.screens.manageClub.manageClubClubName)}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {club_name: text}}})
                }}
                value={managedClub.data.club_name}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry
        caption={i18n.t(tokens.app.screens.manageClub.manageClubActiveUntil)}
        editable={props.currentUser.type == "user" && props.currentUser.isGod == true}
        >
            <CustomDatePicker
            //onValueChange={(value) => dispatchChanges({key: "memType", value: value})}
            date={managedClub.data.activeUntil || new Date(1900,0,1)}
            setDate={
                (date) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {activeUntil: date}}})
                }
            }
            fontStyle={globalStyleSheet.inputOk}
            disabled={!(props.currentUser.type == "user" && props.currentUser.isGod == true)}
            //textAlign="right"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.profile.profileAddress)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={i18n.t(tokens.app.screens.profile.profileAddress)}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {address: text}}})
                }}
                value={managedClub.data.address || ""}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.profile.profileCity)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={i18n.t(tokens.app.screens.profile.profileCity)}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {city: text}}})
                }}
                value={managedClub.data.city || ""}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.profile.profileEmail)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={i18n.t(tokens.app.screens.profile.profileEmail)}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {email: text}}})
                }}
                value={managedClub.data.email || ""}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.profile.profilePhone)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={i18n.t(tokens.app.screens.profile.profilePhone)}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {phone: text}}})
                }}
                value={managedClub.data.phone || ""}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.profile.profileICO)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={i18n.t(tokens.app.screens.profile.profileICO)}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {ico: text}}})
                }}
                value={managedClub.data.ico || ""}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.profile.profileDIC)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={i18n.t(tokens.app.screens.profile.profileDIC)}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {dic: text}}})
                }}
                value={managedClub.data.dic || ""}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.profile.profileClubHomepage)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={'http[s]://...'}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {homepage: text}}})
                }}
                value={managedClub.data.homepage || ""}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.profile.profileClubVOP)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={'http[s]://...'}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    dispatch({type: AuthActions.authEditClub, data: {value: {vopURL: text}}})
                }}
                value={managedClub.data.vopURL || ""}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormHeading
        heading={i18n.t(tokens.app.screens.manageClub.manageClubPaymentsHeading)}
        />
        <FormEntry
        caption={i18n.t(tokens.app.screens.manageClub.manageClubPaymentsCurr)}
        editable={true}
        >
            <CustomPickerSelect
            onValueChange={(value) => dispatch({type: AuthActions.authEditClub, data: {value: {currency: value}}})} 
            //additionalStyle={{backgroundColor: "white"}}
            value={managedClub.data.currency}
            placeholder={{label: i18n.t(tokens.app.screens.manageClub.manageClubPaymentsCurr), value: undefined}}
            /* disabled={newReservation.mainUid == props.auth.uid?false:true} */
            items={currencies}
            />
        </FormEntry>
        </>
    )
}

export default BasicClubInfo