import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import getEnvVars from '../../../environment';
import { getAuth, initializeAuth, getReactNativePersistence} from "firebase/auth/react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getStorage } from "firebase/storage";
import { getFirestore as GF } from "firebase/firestore";
const envVars = getEnvVars();
console.log('My config is: ' + JSON.stringify(envVars))

export const firebaseConfig = {
  
        apiKey: envVars.apiKey,
        authDomain: envVars.authDomain,
        projectId: envVars.projectId,
        storageBucket: envVars.storageBucket,
        messagingSenderId: envVars.messagingSenderId,
        appId: envVars.appId,
        measurementId: envVars.measurementId,
}

var app: firebase.app.App = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.apps[0]
var auth = initializeAuth(app, {
    persistence: getReactNativePersistence(AsyncStorage),
    
    });

        
        


export { firebase, auth };
export const getFileStorage = () => getStorage(app);

export const getFirestore = () => {
    return app.firestore()
}

export const getFirebase = () => {
    return app
}
