export const validateEmail = (email: string):boolean => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
}

export const validateClub = (name: string):boolean => {
  var re = /^[A-Ž][A-Ža-ž0-9\s]{2,}$/;
    return re.test(name);
}

export const validateNames = (name: string):boolean => {
  var re = /^[A-Ž][a-ž]{2,}$/;
    return re.test(name);
}

export const validatePhone = (phone: string):boolean => {
  var re = /^[0-9]{9}$/;
    return re.test(phone);
}


export const validatePassword = (pass: string):boolean => {
  
    return pass.length >= 6;
}

export const validateTextTime = (value: string):boolean => {
  const pattern = '^([\s0-2]?[0-9]|2[0-3])[0|3][0]$'
  const condition = new RegExp(pattern, 'g');
  return condition.test(value);
  
}