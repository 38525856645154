import { DocumentData, Query } from "@google-cloud/firestore";
import Constants from "expo-constants";
import { AnyMarket, CallbackFunc, FunctionResponse, GameTypes, Market, MarketCredit, MarketLongreservation, MarketOpengame, MarketPaymentStatuses, MarketReservation, MarketStatuses, MarketSubscription, MarketTypes, MembershipTypes, Message, ProductTypes, Reservation, ReservationTypes } from "../../../functions/src/interfaces";
import { extractTime, firestoreAutoId, longToSingleSwap, singleToLongSwap, splitTime, standardizeDates, subscriptionsToObject } from "../../utilities/funcs";
import { MarketFilters } from "../../utilities/interfaces";
import { showToast } from "../../utilities/toastcontroller";
import { AppThunk } from "../hooks";
import { MarketActions } from "../reducers/marketReducer";
import { RootStore } from "../reducers/rootReducer";
import firebase from 'firebase/compat/app';
import { AuthActions } from "../reducers/authReducer";

//ADD localhost address of your server
const API_URL = Constants.expoConfig.extra.functionsUrlBase


export const addLongreservation = (): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        let market: MarketLongreservation<Market> = state.market.managedMarket.data as any
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        let body: string = JSON.stringify({
            token: token,
            marketType: 'longreservation',
            data: {...market}
        })
        
        console.log('Request: '+ body)
        let response = await fetch(`${API_URL}/addOrChangeMarketRequest` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });
        //dispatch(manageMarketToState(response.json(), ))
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: false})
        if (response.status == 200) {
            const resp: FunctionResponse = await response.json()
            const data: MarketLongreservation<Market> = standardizeDates(resp.data) 
            console.log('Data returned: ' + JSON.stringify(data))
            
            
            if (data.paymentStatus == MarketPaymentStatuses.requiresPayment) {
                return {action: 'checkout', error: {}, data: {checkoutId: data.firebaseId, marketId: data.firebaseId}}
            } else {
                return {action: 'none', error: {}, data: {marketId: data.firebaseId}}
            }
        } else {
            const errResp: FunctionResponse =  await response.json()
            console.log('Error resp: ' + JSON.stringify(errResp.error))
            showToast('Error: ' + JSON.stringify(errResp.error))
            return {action: 'none', error: {code: errResp.error.code, message: errResp.error.message}, data: {}}
        }
        
    }
}


export const addSubscription = (): AppThunk<Promise<any>>  => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        let market: MarketSubscription<Market> = state.market.managedMarket.data as any
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        let body: string = JSON.stringify({
            token: token,
            marketType: 'subscription',
            data: {...market}
        })
        
        console.log('Request: '+ body)
        let response = await fetch(`${API_URL}/addOrChangeMarketRequest` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });
        //dispatch(manageMarketToState(response.json(), ))
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: false})
        if (response.status == 200) {
            const resp: FunctionResponse = await response.json()
            const data: MarketSubscription<Market> = standardizeDates(resp.data) 
            console.log('Data returned: ' + JSON.stringify(data))
            
            
            if (data.paymentStatus == MarketPaymentStatuses.requiresPayment) {
                return {action: 'checkout', error: {}, data: {checkoutId: data.firebaseId, marketId: data.firebaseId}}
            } else {
                return {action: 'none', error: {}, data: {marketId: data.firebaseId}}
            }
        } else {
            const errResp: FunctionResponse =  await response.json()
            console.log('Error resp: ' + JSON.stringify(errResp.error))
            showToast('Error: ' + JSON.stringify(errResp.error))
            return {action: 'none', error: {code: errResp.error.code, message: errResp.error.message}, data: {}}
        }
        
    }
}

export const addCredit = (): AppThunk<Promise<any>>  => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        let market: MarketCredit<Market> = state.market.managedMarket.data as any
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        let body: string = JSON.stringify({
            token: token,
            marketType: 'credit',
            data: {...market}
        })
        
        console.log('Request: '+ body)
        let response = await fetch(`${API_URL}/addOrChangeMarketRequest` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });
        //dispatch(manageMarketToState(response.json(), ))
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: false})
        if (response.status == 200) {
            const resp: FunctionResponse = await response.json()
            const data: MarketCredit<Market> = standardizeDates(resp.data) 
            console.log('Data returned: ' + JSON.stringify(data))
            
            
            if (data.paymentStatus == MarketPaymentStatuses.requiresPayment) {
                return {action: 'checkout', error: {}, data: {checkoutId: data.firebaseId, marketId: data.firebaseId}}
            } else {
                return {action: 'none', error: {}, data: {marketId: data.firebaseId}}
            }
        } else {
            const errResp: FunctionResponse =  await response.json()
            console.log('Error resp: ' + JSON.stringify(errResp.error))
            showToast('Error: ' + JSON.stringify(errResp.error))
            return {action: 'none', error: {code: errResp.error.code, message: errResp.error.message}, data: {}}
        }
        
    }
}


export const manageMarketToState = (market: {
    marketId: string,
    club: string,
    firebaseId: string,
    type: MarketTypes,
    uid: string,
    mainUid: string,
}, callback): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: MarketActions.marketPutMarketToState, data: {isLoaded: false, isChanged: false, data: {...market}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        //const firestoreuid = getState().firebase.auth.uid
        const firebase = getFirebase()
        const firestoreuid = firebase.auth()?.currentUser?.uid
        const state: RootStore = getState()
        const seasons = state.auth.seasons
        
        const clubData = state.auth.allClubs[market.club]
        
        
        //dispatch({type: ReservationActions.editedReservation, data: {data: changes, seasonsData: seasons, validations: {}}})
        //console.log('add Market to state params', market, callback, firestoreuid)
        

        if (market.marketId) {
            const rootRef = getFirestore()
            const marketRef = rootRef.collection('clubs').doc(market.club).collection('market').doc(market.marketId)
            
            var returnedData = {}
            var unsubscribe = marketRef.onSnapshot(
                (querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    let changes = {}
                    try {
                        changes = {...changes, ...standardizeDates(querySnapshot.data())}
                    }
                    catch {
                        console.log('error parsing', querySnapshot)
                    }
                    //console.log('Totoo je return response', changes)
                    dispatch({type: AuthActions.authActionPressed, data: false})
                    dispatch({type: MarketActions.marketPutMarketToState, data: {isLoaded: true, seasonsData: seasons, isChanged: false, data: changes}})
                },
                (error) => {
                    console.log("Error listening" + JSON.stringify(error))
                    dispatch({type: AuthActions.authActionPressed, data: false})
                    dispatch({type: MarketActions.marketPutMarketToState, data: {isLoaded: true, seasonsData: seasons, isChanged: false, data: {}}})
                }
            )
                
            //console.log(unsubscribe)
            callback(unsubscribe
                )  
        } else if (market.firebaseId) {
            let initState: any = {}
            if (market.type == "reservation" && 
            state.reservations.managedReservation.data.type != ReservationTypes.locked &&
            state.reservations.managedReservation.data.type != ReservationTypes.outoforder) {
                
                let newData: MarketReservation<Market> = {
                    type: MarketTypes.reservation,
                    dateFrom: state.reservations.managedReservation.data.dateFrom,
                    dateTo: state.reservations.managedReservation.data.dateTo,
                    timeFrom: parseInt(extractTime(state.reservations.managedReservation.data.dateFrom)),
                    timeTo: parseInt(extractTime(state.reservations.managedReservation.data.dateTo)),
                    court: state.reservations.managedReservation.data.court,
                    season: state.reservations.managedReservation.data.season,
                    uid: firestoreuid,
                    gamePlayers: state.reservations.managedReservation.data.gamePlayers,
                    players: state.reservations.managedReservation.data.players,
                    gameType: state.reservations.managedReservation.data.gameType,
                    club: state.reservations.managedReservation.data.club,
                    status: MarketStatuses.new,
                    day: state.reservations.managedReservation.data.dateFrom.getDay(),
                    updateTime: new Date,
                    dueDate: state.reservations.managedReservation.data.dateFrom,
                    requestDate: new Date,
                    reservationIds: [state.reservations.managedReservation.data.firebaseId],
                    firebaseId: state.reservations.managedReservation.data.reservationRequestId,
                    text: "",
                    paymentStatus: MarketPaymentStatuses.processing, 
                    mainUid: state.reservations.managedReservation.data.mainUid,
                    isOnBehalf: state.reservations.managedReservation.data.isOnBehalf,
                    isNotRegistered: state.reservations.managedReservation.data.isNotRegistered,
                    onBehalfFirstName: state.reservations.managedReservation.data.onBehalfFirstName,
                    onBehalfFamilyName: state.reservations.managedReservation.data.onBehalfFamilyName,
                    onBehalfPhone: state.reservations.managedReservation.data.onBehalfPhone,
                }
                initState = {...newData}
            } else if (market.type == "opengame" && 
            state.reservations.managedReservation.data.type != ReservationTypes.locked &&
            state.reservations.managedReservation.data.type != ReservationTypes.outoforder) {
                let newData: MarketOpengame<Market> = {
                    type: MarketTypes.opengame,
                    timeFrom: parseInt(extractTime(state.reservations.managedReservation.data.dateFrom)),
                    timeTo: parseInt(extractTime(state.reservations.managedReservation.data.dateTo)),
                    court: state.reservations.managedReservation.data.court,
                    season: state.reservations.managedReservation.data.season,
                    uid: firestoreuid,
                    gamePlayers: state.reservations.managedReservation.data.gamePlayers,
                    players: state.reservations.managedReservation.data.players,
                    gameType: state.reservations.managedReservation.data.gameType,
                    club: state.reservations.managedReservation.data.club,
                    appliedPlayers: [],
                    notifiedPlayers: [],
                    status: MarketStatuses.new,
                    updateTime: new Date,
                    dueDate: state.reservations.managedReservation.data.dateFrom,
                    requestDate: new Date,
                    reservationIds: [state.reservations.managedReservation.data.firebaseId],
                    prevFirebaseId: state.reservations.managedReservation.data.reservationRequestId,
                    text: "",
                }
                initState = {...newData}
            }
            
              
            dispatch({type: AuthActions.authActionPressed, data: false})
            dispatch({type: MarketActions.marketPutMarketToState, data: {isLoaded: true, seasonsData: seasons, data: initState}})
        } else {
            //console.log('This will be a new request', market)

            const defaultPlayers = market.uid == market.club ? [] : [market.uid]
            let initState: any = {}
            if (market.type == MarketTypes.opengame){
                initState = {
                        uid: market.uid,
                        type: MarketTypes.opengame,
                        dueDate: new Date,
                        timeFrom: 1200,
                        timeTo: 1200,
                        gameType: "group",
                        gamePlayers: {home: {A:""}, away: {A:""}},
                        players: [...defaultPlayers],
                        appliedPlayers: [],
                        club: market.club,
                        text: "",
                        messages: [],
                        //reservationId: "",
                        status: MarketStatuses.new,
                        requestDate: new Date,
                        //isLoading: false,
                        mainUid: firestoreuid,
                        reservationIds: [],
                        updateTime: new Date,
                        court: undefined,
                        notifiedPlayers: [],
                        season: undefined,
                } as MarketOpengame<Market>
            } else if (market.type == MarketTypes.longreservation) {
                initState = {
                    uid: market.uid,
                    type: MarketTypes.longreservation,
                    season: "",
                    from: null,
                    to: null,
                    day: 6,
                    timeFrom: 600,
                    timeTo: 800,
                    weekRecur: 1,
                    dueDate: new Date(2021, 8, 30),
                    court: "",
                    club: market.club,
                    text: "",
                    gameType: "group",
                    messages: [],
                    players: [...defaultPlayers],
                    gamePlayers: {home: {A:""}, away: {A:""}},
                    requestDate: new Date,
                    status: MarketStatuses.new,
                    reservationIds: [],
                    mainUid: firestoreuid,
                    updateTime: new Date,
                } as MarketLongreservation<Market>
            

            } else if (market.type == MarketTypes.reservation) {
                var reservation: MarketReservation<Market> = undefined
                if (firestoreuid) {
                    reservation = {
                        dateFrom: state.reservations.selection.dateFrom,
                        dateTo: state.reservations.selection.dateTo,
                        timeFrom: parseInt(extractTime(state.reservations.selection.dateFrom)),
                        timeTo: parseInt(extractTime(state.reservations.selection.dateTo)),
                        court: state.reservations.selection.court,
                        season: state.reservations.selection.season,
                        uid: firestoreuid,
                        gamePlayers: {home: {A:""}, away: {A:""}},
                        players: [...defaultPlayers],
                        type: MarketTypes.reservation,
                        gameType: GameTypes.group,
                        club: state.auth.selectedClub,
                        status: MarketStatuses.new,
                        day: state.reservations.selection.dateFrom.getDay(),
                        updateTime: new Date,
                        dueDate: state.reservations.selection.dateFrom,
                        requestDate: new Date,
                        reservationIds: [],
                        text: "",
                        paymentStatus: MarketPaymentStatuses.processing,
                        mainUid: firestoreuid,
                        isOnBehalf: false,
                    }
                } else {
                    reservation = {
                        dateFrom: state.reservations.selection.dateFrom,
                        dateTo: state.reservations.selection.dateTo,
                        timeFrom: parseInt(extractTime(state.reservations.selection.dateFrom)),
                        timeTo: parseInt(extractTime(state.reservations.selection.dateTo)),
                        court: state.reservations.selection.court,
                        season: state.reservations.selection.season,
                        uid: state.auth.selectedClub,
                        gamePlayers: {home: {A:""}, away: {A:""}},
                        players: [],
                        type: MarketTypes.reservation,
                        gameType: GameTypes.group,
                        club: state.auth.selectedClub,
                        status: MarketStatuses.new,
                        day: state.reservations.selection.dateFrom.getDay(),
                        updateTime: new Date,
                        dueDate: state.reservations.selection.dateFrom,
                        requestDate: new Date,
                        reservationIds: [],
                        text: "",
                        paymentStatus: MarketPaymentStatuses.processing,
                        mainUid: "",
                        isOnBehalf: true,
                        isNotRegistered: true,
                }
                
                }
                initState = {...reservation}
            } else if (market.type == MarketTypes.subscription) {
                let type = clubData?.products?.[ProductTypes.subscription].type
                let getSubs = subscriptionsToObject({ subscriptions: state.contacts.subscriptions, activeSeason: state.auth.activeSeason, currDate: new Date(JSON.parse(state.auth.currDate)) })
                let mySubs = getSubs[market.mainUid]
                console.log('My subs: ' + JSON.stringify(mySubs))
                let activeMemTo = mySubs?.ends ? mySubs.ends : new Date
                let from = new Date(activeMemTo)
                let subscription: MarketSubscription<Market> = {
                    uid: firestoreuid,
                    club: state.auth.selectedClub,
                    dueDate: activeMemTo,
                    requestDate: new Date,
                    status: MarketStatuses.new,
                    type: MarketTypes.subscription,
                    text: "",
                    updateTime: new Date,
                    memType: type,
                    quantity: 0,
                    minFrom: activeMemTo,
                    from: from,
                    to: undefined,
                    tour: clubData.products?.[ProductTypes.subscription]?.type == MembershipTypes.tourSubscription ? clubData.products?.[ProductTypes.subscription]?.tour : undefined,
                    mainUid: market.mainUid,
                    paymentStatus: MarketPaymentStatuses.requiresPayment,
                    product: {firebaseId: undefined, name: "", price: 0, allowedRoles: {}, type: type}
                }
                //console.log('Subscription data', clubData)
                initState = {...subscription}
            } else if (market.type == MarketTypes.credit) {
                var requestExpires = new Date
                requestExpires.setDate(requestExpires.getDate() + 3)
                let credit: MarketCredit<Market> = {
                    uid: firestoreuid,
                    club: state.auth.selectedClub,
                    dueDate: requestExpires,
                    requestDate: new Date,
                    status: MarketStatuses.new,
                    type: MarketTypes.credit,
                    text: "",
                    updateTime: new Date,
                    amount: 0,
                    mainUid: firestoreuid,
                    paymentStatus: MarketPaymentStatuses.requiresPayment,
                }
                initState = {...credit}
            } else {
                initState = {
                    uid: firestoreuid,
                    type: 'other',
                    //isLoading: false,
                }
            }
            //console.log('Posilam init state',initState)
            dispatch({type: AuthActions.authActionPressed, data: false})
            dispatch({type: MarketActions.marketPutMarketToState, data: {isLoaded: true, seasonsData: seasons, isChanged: true, data: initState}})
        }
        

        
        
    }
}

export const editMarket = (changes: {[key: string]: any}): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const state = getState()
        const seasons = state.auth.seasons
        
        
        
        dispatch({type: MarketActions.changeManagedMarket, data: {data: changes, seasonsData: seasons, validations: {}}})

    }
}


export const marketApprovalAction = (clubId: string, marketId: string, action: 'approve' | 'decline'): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}, validations: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        let market: AnyMarket = state.market.managedMarket.data
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        let body: string = JSON.stringify({
            token: token,
            type: market.type,
            action: action,
            marketId: marketId,
            clubId: clubId
        })
        
        console.log('Request: '+ body)
        let response = await fetch(`${API_URL}/marketApproval` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });
        if (response.ok) {
            const resp: FunctionResponse = await response.json()
            const data: MarketReservation<Market> = standardizeDates(resp.data) 
            console.log('Returned data: ' + JSON.stringify(data))
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {...data}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return true
        } else {
            const resp: FunctionResponse = await response.json()
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}, error: {...resp.error}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            showToast('Error: ' + JSON.stringify(resp.error))
            return false
        }
    }
}

export const marketDeletionAction = (clubId: string, marketId: string, type: 'all' | 'future'): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}, validations: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        let market = state.market.managedMarket.data
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        const firestore = getFirestore()
        var reservationIds: string[] = []
        if (market.type == MarketTypes.reservation || market.type == MarketTypes.longreservation) {
            //let retyped = {...market}
            if (type == 'all') {
                reservationIds = [...market.reservationIds]
            } else if (type == 'future') {
                const query = firestore.collection('clubs').doc(clubId).collection('reservations').where('reservationRequestId','==',market.firebaseId)
                let reservationsDocs = await query.get()
                let reservations: Reservation[] = reservationsDocs.docs.map((doc) => standardizeDates(doc.data()))
                let futureReservations: Reservation[] = [...reservations.filter(res => res.dateFrom >= new Date)]
                reservationIds = futureReservations.map((res) => res.firebaseId)
            }
        }
        
        
        let body: string = JSON.stringify({
            token: token,
            type: market.type,
            reservationIds: reservationIds,
            marketId: marketId,
            clubId: clubId
        })
        
        console.log('Request: '+ body)
        let response = await fetch(`${API_URL}/marketDeletion` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });
        if (response.ok) {
            const resp: FunctionResponse = await response.json()
            const data: MarketReservation<Market> = standardizeDates(resp.data) 
            console.log('Returned data: ' + JSON.stringify(data))
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {...data}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})

            if (resp.actions.includes('REQUIRES_REFUND')) {
                return {action: 'checkout', error: {}, data: {checkoutId: data.firebaseId, marketId: data.firebaseId}}
            } else {
                return {action: 'none', error: {}, data: {marketId: data.firebaseId}}
            }
        } else {
            const errResp: FunctionResponse =  await response.json()
            console.log('Error resp: ' + JSON.stringify(errResp.error))
            showToast('Error: ' + JSON.stringify(errResp.error))
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return {action: 'none', error: {code: errResp.error.code, message: errResp.error.message}, data: {}}
        }
    }
}

export const addReservation = (): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        try {
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: true})
            let state: RootStore = getState()
            let reservation: MarketReservation<Market> = state.market.managedMarket.data as any
            let user = getFirebase().auth().currentUser
            var token = undefined
            if (user) {
                token = await user.getIdToken(true)
            } else {
                
            }
            


            
            
            console.log('Request: ' + JSON.stringify(reservation))
            console.log('Url: ')
            let response = await fetch(`${API_URL}/addOrChangeMarketRequest` , {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Accept":"*/*",
                    "Connection":"keep-alive",
                    "Accept-Encoding":"gzip, deflate, br",
                },
                body: JSON.stringify({
                    token: token,
                    marketType: 'reservation',
                    data: reservation
                }),

            });
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})

            if (response.status == 200) {
                const resp: FunctionResponse = await response.json()
                const data: MarketReservation<Market> = standardizeDates(resp.data) 
                console.log('Data returned: ' + JSON.stringify(data))

                
                if (data.paymentStatus == MarketPaymentStatuses.requiresPayment) {
                    return {action: 'checkout', error: {}, data: {checkoutId: data.firebaseId, marketId: data.firebaseId}}
                } else {
                    return {action: 'none', error: {}, data: {marketId: data.firebaseId}}
                }
            } else {
                const errResp: FunctionResponse = await response.json()
                console.log('Error resp: ' + JSON.stringify(errResp.error))
                showToast('Error: ' + JSON.stringify(errResp.error))
                return {action: 'none', error: {code: errResp.error.code, message: errResp.error.message}, data: {}}
            }
        }
        catch (e: any) {
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}, error: {code: e.code, message: e.message}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            console.log('Error add/changing market: ' + e.message)
            showToast('Error: ' + JSON.stringify(e.message))
            return e
        }   
    }
}

export const addOpengame = (createReservation?: boolean): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}, validations: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        let user = getFirebase().auth().currentUser
        const token = await user.getIdToken(true)
        let market: MarketOpengame<Market> = state.market.managedMarket.data as any
        var updateMarket = {}
        if (createReservation == true) {
            let dateFrom = new Date(market.dueDate)
            let timeFrom = splitTime(String(market.timeFrom))
            dateFrom.setHours(timeFrom[0], timeFrom[1])
            let dateTo = new Date(market.dueDate)
            let timeTo = splitTime(String(market.timeTo))
            dateTo.setHours(timeTo[0], timeTo[1])
            let reservationRequest: MarketReservation<Market> = {
                uid: market.uid,
                club: market.club,
                dueDate: market.dueDate,
                requestDate: new Date,
                status: MarketStatuses.new,
                text: "",
                updateTime: new Date,
                court: market.court,
                dateFrom: dateFrom,
                dateTo: dateTo,
                day: market.dueDate.getDay(),
                season: market.season,
                timeFrom: market.timeFrom,
                timeTo: market.timeTo,
                gameType: market.gameType,
                gamePlayers: market.gamePlayers,
                players: market.players,
                paymentStatus: MarketPaymentStatuses.processing,
                mainUid: user.uid,
                reservationIds: [],
                type: MarketTypes.reservation
            }
            let bodyRes: string = JSON.stringify({
                token: token,
                marketType: 'reservation',
                data: {...reservationRequest}
            })

            console.log('Request: ' + JSON.stringify(bodyRes))

            let responseReservation = await fetch(`${API_URL}/addOrChangeMarketRequest` , {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Accept":"*/*",
                    "Connection":"keep-alive",
                    "Accept-Encoding":"gzip, deflate, br",
                },
                body: bodyRes,

            });

            if (responseReservation.status == 200) {
                const respRes: FunctionResponse = await responseReservation.json()
                const dataRes: MarketReservation<Market> = standardizeDates(respRes.data) 
                console.log('Data returned: ' + JSON.stringify(dataRes))
                //return {action: 'none', error: {}, data: {marketId: data.firebaseId}}
                updateMarket = {...updateMarket, reservationIds: dataRes.reservationIds}
                
            } else {
                const errResp: FunctionResponse = await responseReservation.json()
                
                console.log('Error resp: ' + JSON.stringify(errResp.error))
                showToast('Error: ' + JSON.stringify(errResp.error))
                return {action: 'none', error: {code: errResp.error.code, message: errResp.error.message}, data: {}}
            }
        }
        market = {...market, ...updateMarket}
        let body: string = JSON.stringify({
            token: token,
            marketType: 'opengame',
            data: {...market}
        })
        
        console.log('Request: ', market)
        let response = await fetch(`${API_URL}/addOrChangeMarketRequest` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });
        dispatch({type: MarketActions.changeManagedMarket, data: {isChanged: false, isActionPressed: false, data: {}, validations: {}}})
        dispatch({type: AuthActions.authActionPressed, data: false})

        if (response.status == 200) {
            const resp: FunctionResponse = await response.json()
            const data: MarketOpengame<Market> = standardizeDates(resp.data) 
            console.log('Data returned: ' + JSON.stringify(data))
            return {action: 'none', error: {}, data: {marketId: data.firebaseId}}
            
        } else {
            const errResp: FunctionResponse = await response.json()
            
            console.log('Error resp: ' + JSON.stringify(errResp.error))
            showToast('Error: ' + JSON.stringify(errResp.error))
            return {action: 'none', error: {code: errResp.error.code, message: errResp.error.message}, data: {}}
        }
        
    }
}



export const addEditMessage = (msg: Message, marketId: string, club: string, callback: CallbackFunc): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}, validations: {}}})
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}, validations: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        //let market: AnyMarket = state.market.managedMarket.data
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        
        let body: string = JSON.stringify({
            token: token,
            messageId: msg.messageId,
            messageText: msg.message,
            marketId: marketId,
            clubId: club
        })
        let response = await fetch(`${API_URL}/addOrChangeMessage` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });

        if (response.ok) {
            showToast('Message sent successfully')
            callback(true, msg)
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return true
        } else {
            showToast('Error sending message')
            callback(false, msg)
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return false
        }
        
       
    }
}



export const addPlayerToOpengameApplied = (player: string, firebaseId: string, club: string, callback: CallbackFunc): AppThunk<Promise<any>> => {
    //console.log(player, marketId, club, callback)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}, validations: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        //let market: AnyMarket = state.market.managedMarket.data
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        
        let body: string = JSON.stringify({
            token: token,
            type: "market",
            firebaseId: firebaseId,
            club: club
        })
        let response = await fetch(`${API_URL}/applyToAppliedPlayers` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });

        if (response.ok) {
            showToast('User added/removed successfully')
            callback(true, body)
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return true
        } else {
            showToast('Error adding/removing user')
            callback(false, body)
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return false
        }
       
    }
}

export const subscribeForMarket = (filters: MarketFilters, callback: CallbackFunc): AppThunk<any> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log("Callung subscribe for res")
        let dateFrom = new Date
        
        const state: RootStore = getState()
        //const firebase = getFirebase()
        const rootRef = getFirestore()
        //console.log('state', firestoreuid)
        const currUser = state.auth.currentUserData
        let statuses = [ 
            MarketStatuses.open,
            MarketStatuses.blocked
        ]

        var entityRef: any = null
        var clubList = []
        var subscribeList = []

        if (currUser.type == "clubs") {
            clubList = [currUser.firebaseId]
        } else {
            if (filters.allClubs == true) {
                clubList = [...currUser.clubs]
            } else {
                clubList = [state.auth.selectedClub]
            }
            
        }

        for (let i = 0; i < clubList.length; i++) {
            let currClub = clubList[i]
            entityRef = rootRef.collection('clubs').doc(currClub).collection('market')
            //Conditional based filters
            if (true) {
                entityRef = entityRef.where('status','in',statuses)
            } 
            //Order na konci
            entityRef = entityRef.where('dueDate', '>=', dateFrom).orderBy('dueDate') as Query<DocumentData>;
            //console.log("Ahoj kurva", club)
            let unsubscribe = entityRef.onSnapshot(
                (querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    const changes = querySnapshot.docChanges()
                    const js = {
                        'added':{},
                        'modified':{},
                        'removed':{}
                                
                    }
                    changes.forEach(doc => {
                        //console.log(doc)
                        //console.log(doc)
                        js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 

                        
                        
                    })
                    
                    //console.log(js)
                    dispatch({type: MarketActions.marketSubscriptionChanges, data: js})
                },
                (error) => {
                    console.log("Error listening" + JSON.stringify(error))
                }
            )

            subscribeList.push(unsubscribe)
        }
        
        return subscribeList

        //console.log(unsubscribe)

            


    }
}


export const removePlayer = (player: string, marketId: string, club: string, type: 'applied' | 'players', callback: CallbackFunc): AppThunk<Promise<any>> => {
    
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const fb = getFirebase()
        const firestoreuid = getFirebase().auth().currentUser.uid
        //console.log('state', firestoreuid)
        


        const rootRef = getFirestore()
        const opengameRef = rootRef.collection('clubs').doc(club).collection('market').doc(marketId)
        
        rootRef.runTransaction(async (T) => {
            //TODO: musí se tu řešit query na clash 
            const marketEntry = await opengameRef.get()
            
            if (marketEntry.exists) {
                let data = marketEntry.data()
                //console.log('tohle jo', data)
                if (type == 'applied') {
                    if (data.appliedPlayers.includes(player)) {
                        //console.log('test existence ', data.uid == firestoreuid, firestoreuid == player)
                        if  (data.uid == firestoreuid || firestoreuid == player) {
                            T.update(opengameRef, {
                                appliedPlayers: firebase.firestore.FieldValue.arrayRemove(player)
                            })
                        } else {
                            return Promise.reject('Unauthorised')
                        }
                    } 
                } else if (type == 'players') {
                    if (data.players.includes(player)) {
                        //console.log('test existence ', data.uid == firestoreuid, firestoreuid == player)
                        if  (data.uid == firestoreuid || firestoreuid == player) {
                            T.update(opengameRef, {
                                players: firebase.firestore.FieldValue.arrayRemove(player)
                            })
                        } else {
                            return Promise.reject('Unauthorised')
                        }
                    }    
                }
                
                
                
            }
                
        })
        .then(
            () => {
                callback(true, {})
                dispatch({type: 'OPENGAME_EDITED', data: {}})
            }
        ).catch(
            (e) => {
                
                callback(false,e)
                dispatch({type: 'OPENGAME_ERROR', e})
            }
        )
       
    }
}

export const updateManagedMarketByReservation = (reservation: Reservation): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        let changes = {
            timeFrom: reservation.timeFrom,
            timeTo: reservation.timeTo,
            dateFrom: reservation.dateFrom,
            dateTo: reservation.dateTo,
            court: reservation.court,
            season: reservation.season,
            reservationIds: [reservation.firebaseId],
            dueDate: reservation.dateFrom,
        }
        dispatch({type: MarketActions.changeManagedMarket, data: {data: changes}})
       
    }
}

export const updateManagedMarketByselection = (): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        let state: RootStore = getState()
        let selection = state.reservations.selection
        let marketUpdate = {
            dateFrom: selection.dateFrom,
            dateTo: selection.dateTo,
            timeFrom: parseInt(extractTime(selection.dateFrom)),
            timeTo: parseInt(extractTime(selection.dateTo)),
            court: selection.court,
            season: selection.season,
            day: selection.dateFrom.getDay(),
            dueDate: selection.dateFrom,
        }
        dispatch({type: MarketActions.changeManagedMarket, data: {data: marketUpdate, createReservation: true}})
       
    }
}


export const subscribeForRequest= (marketId: string, club: string, callback: CallbackFunc): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //const firebase = getFirebase()
        const firestore = getFirestore()

        const checkoutRef = firestore.collection('clubs').doc(club).collection('market').doc(marketId)
        
        return checkoutRef.onSnapshot((snapshot) => {
            if (snapshot.exists) {
                let data = standardizeDates(snapshot.data()) 
                dispatch({type: MarketActions.marketSubscribeForIndividualRequest, data: {data: {...data}, isLoaded: true, isSuccess: true}})
            }
            
        })

    }
}




export const switchLongShort = (): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const state = getState()
        const currManaged = state.market.managedMarket.data
        const seasons = state.auth.seasons
        if (currManaged.type == MarketTypes.reservation) {
            let season = seasons[currManaged.season]
            let newMarketState = singleToLongSwap(currManaged, season)
            dispatch({type: MarketActions.marketPutMarketToState, data: {isLoaded: true, seasonsData: seasons, isChanged: true, data: newMarketState}})
        } else if (currManaged.type == MarketTypes.longreservation) {
            let season = currManaged.season ? seasons[currManaged.season] : state.auth.activeSeason
            let newMarketState = longToSingleSwap(currManaged, season)
            dispatch({type: MarketActions.marketPutMarketToState, data: {isLoaded: true, seasonsData: seasons, isChanged: true, data: newMarketState}})
        } else {
            console.log('Do nothing')
        }
    }
}