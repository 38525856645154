import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable } from 'react-native'

import { StyleSheet } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { Message } from '../../functions/src/interfaces';



type Props = {
    messages: Message[]
}

const MessagesCount: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)


    const stylelocal = StyleSheet.create({
        container: {
            flexDirection: "row",
            alignItems: "center"
        }
    })

  
    return (
        <View
        style={stylelocal.container}
        >
            <Ionicons
            name={"mail-outline"}
            color="black"
            size={20}
            />
            <Text>
            {props.messages?.length || 0}
            </Text>
        </View>
       
    )
}





export default MessagesCount

