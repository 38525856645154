import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { connect, shallowEqual } from 'react-redux'
import { News } from '../../functions/src/interfaces'


import i18n, { tokens } from '../i18n'
import { useAppSelector } from '../store/hooks'
import { RootStore } from '../store/reducers/rootReducer'
import { UserOrClub } from '../store/reducers/usersReducer'
import { styles } from '../utilities/globalStyles'
import FormHeading from './FormHeading'
import PlayerListItem from './PlayerListItem'

type Props = {
    handler: (stateCallback: (state: any) => any) => void
    playerPress: () => void,
    disabled: boolean,
    isEditor: boolean,
    removeAction: (uid: string, docId: string, clubId: string, listType: string, callback: (bool, context) => void) => void,
    document: News,
    allowApprove?: boolean,
    alreadySelected: string[],
    addPlayerAction: () => void
}


const PlayerList: React.FC<Props> = (props) => {
    //const index = props.index
    //const item = props.item
    const currentUser: UserOrClub = useAppSelector((state: RootStore) => state.auth.currentUserData, shallowEqual)
    //const contactFlag = 
    //console.log('nalezeno', props)
    const [appliedPlayersList, setAppliedPlayersList] = useState([])
   
    const removeActionSelect = (uid: string) => {
        if (props.isEditor) {
            return () => props.handler(prevState => {

                return {...prevState, appliedPlayers: [...prevState.appliedPlayers.filter(iter => iter != uid)]}
            })
        } else if (currentUser.type == "user" && uid == currentUser.uid) {
            return () => props.removeAction(uid, props.document?.firebaseId, props.document?.club, 'applied', () => {})
        }
    }

    useEffect(() => {
        if (currentUser.type == "user" && !props.alreadySelected.includes(currentUser.uid)) {
            setAppliedPlayersList([...props.document?.appliedPlayers || [],null])
        } else {
            setAppliedPlayersList([...props.document?.appliedPlayers || []])
        }
    }, [props.document?.appliedPlayers, currentUser])
    
    return (
        <View>
        {appliedPlayersList.length > 0?<FormHeading heading={i18n.t(tokens.app.components.appliedPlayers.applicationsHeading)}/>:null}
        <FlatList 
        horizontal={true}
        contentContainerStyle={{paddingLeft: 10, paddingRight: 10}}
        data={appliedPlayersList}
        renderItem={({item, index}) => { 
            //console.log(item, props.users)
            const path = ['appliedPlayers', index.toString()]
            return (<PlayerListItem
                //key={key}
                //handler={props.handler}
                //setSelectionHandler={setSelection}
                disabled={props.disabled && (item != null)}
                uid={item}
                //selection={props.selection}
                //sideCaption={props.sideCaption}
                //reservation={props.reservation}
                playerPressAction={props.playerPress}
                //applyAction={props.applyAction}
                removeAction={removeActionSelect(item)}
                approveAction={props.isEditor && props.allowApprove==true ? () => {
                    props.handler(prevState => {
                        return {
                            ...prevState,
                            players: [...prevState.players, item],
                            appliedPlayers: [...prevState.appliedPlayers.filter(iter => iter != item)]
                        }
                    })
                } : null}
                path={path}/>)
        }}
        keyExtractor={(item) => item}
        
        />
        </View>
    )
}



export default PlayerList


const style = StyleSheet.create({

    entityContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 8,
        borderBottomColor: '#cccccc',
        borderBottomWidth: 1,
        paddingBottom: 8
    },
    
})