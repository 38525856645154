import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Pressable, Image } from 'react-native';
import { Camera, CameraType } from 'expo-camera';
import { connect } from 'react-redux';
import { uploadPhoto } from '../../store/actions/actionsUsers'
import * as ImageManipulator from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker';
import i18n, { tokens } from '../../i18n';
import { Route, useNavigation } from '@react-navigation/native';
import { useAppDispatch, useAppNavigation } from '../../store/hooks';

type Props = {
  route: Route<'CameraScreen', { uid: string }>

}


function CameraScreen(props: Props) {
  const navigation = useAppNavigation()
  const dispatch = useAppDispatch()
  const CameraTypes: any = Camera.Constants.Type
  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(CameraTypes.back);
  const [previewVisible, setPreviewVisible] = useState(false)
  const [capturedImage, setCapturedImage] = useState(null)
  const [capturedBlob, setCapturedBlob] = useState(null)
  let camera = null

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text maxFontSizeMultiplier={1.2}>No access to camera</Text>;
  }

  const flip = () => {
    setType(
      type === CameraTypes.back
        ? CameraTypes.front
        : CameraTypes.back
    );
  }

  const selFile = () => {
    selectFile().then(() => { }).catch((e) => console.log(JSON.stringify(e)))
  }

  const upload = () => {
    console.log('Upload')
    dispatch(uploadPhoto(capturedBlob, props.route.params.uid, (result) => {
      console.log(JSON.stringify(result))
      navigation.goBack()
    }))
  }

  const __takePicture = async () => {
    if (!camera) return
    const photo = await camera.takePictureAsync()

    let height = 300
    let width = (300 / photo.height) * photo.width

    const resizedPhoto = await ImageManipulator.manipulateAsync(
      photo.uri,
      [{ resize: { width: width, height: height } }],
      { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
    )
    const response = await fetch(resizedPhoto.uri)
    const blob = await response.blob()
    //console.log(photo, blob, resizedPhoto)
    setPreviewVisible(true)
    setCapturedImage(photo)
    setCapturedBlob(blob)
  }

  const selectFile = async () => {
    // No permissions request is necessary for launching the image library
    //Should be ImageInfo Type
    let result: any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    let height = 300
    let width = (height / result.height) * result.width

    const resizedPhoto = await ImageManipulator.manipulateAsync(
      result.uri,
      [{ resize: { width: width, height: height } }],
      { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
    )
    const response = await fetch(resizedPhoto.uri)
    const blob = await response.blob()


    setPreviewVisible(true)
    setCapturedImage(resizedPhoto)
    setCapturedBlob(blob)

  };

  return (

    <View style={styles.container}>
      {previewVisible && capturedImage ?
        <Image style={{ width: "100%", height: "100%" }} source={{ uri: capturedImage.uri }} />
        :<Camera style={styles.camera} type={type} ref={(r) => {
          camera = r
        }}/>}
      <View
        style={styles.bottomBar}
      >
        {previewVisible && capturedImage ? null :
          <Pressable
            style={styles.button}
            onPress={flip}>
            <Text maxFontSizeMultiplier={1.2} style={styles.text}> {i18n.t(tokens.app.screens.camera.cameraFlip)} </Text>
          </Pressable>}

        <Pressable
          onPress={previewVisible && capturedImage ? upload : __takePicture}

        >
          <View
            style={styles.roundButton}
          >
            {previewVisible && capturedImage ? <Text maxFontSizeMultiplier={1.2}>
              {i18n.t(tokens.app.screens.camera.cameraUpload)}
            </Text> : null}
          </View>
        </Pressable>
        {previewVisible && capturedImage ? null :
          <Pressable
            style={styles.button}
            onPress={selFile}>
            <Text maxFontSizeMultiplier={1.2} style={styles.text}> {i18n.t(tokens.app.screens.camera.cameraGallery)} </Text>
          </Pressable>
        }
      </View>
    </View>

  )
}


const styles = StyleSheet.create({
  roundButton: {
    width: 70,
    height: 70,
    bottom: 0,
    borderRadius: 50,
    backgroundColor: '#fff',
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    margin: 20,
  },
  button: {
    width: 100,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 18,
    color: 'white',
  },
  bottomBar: {
    position: 'absolute',
    bottom: 0,
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    padding: 20,
    justifyContent: 'space-evenly',
  }
});



export default CameraScreen
