//import { useEffect, useState } from 'react'
import { Image, Text, Pressable, View } from 'react-native'
import { StyleSheet } from 'react-native';
import { normalize } from '../utilities/constants';
import { mainFont, secondColor, styles } from '../utilities/globalStyles'

type Props = {
    onPress: () => any,
    disabled?: boolean,
    imageAsset?: string,
    buttonText: string
}

const BigButton: React.FC<Props> = (props) => {
    const imageUri = {
        event:{
            uri: require('../../assets/images/add-event.png')
        }
    }
  
    return (
        
        <Pressable
        style={style.button}
        onPress={() => props.onPress()}
        disabled={props.disabled}
        >
        <View style={style.inner}>
        {props.imageAsset?<Image 
        style= {style.image}
        source={imageUri[props.imageAsset].uri}
        />:null}
        <Text maxFontSizeMultiplier={1.2} style={styles.buttonText}>{props.buttonText}</Text>
        </View>
        </Pressable>
    )
}
const style = StyleSheet.create({
    image: {
        width: normalize(25),
        height: normalize(25),
        aspectRatio: 1,
        alignSelf: 'center',
    },
    inner: {
        //flex: 1,
        flexDirection: "column",
        justifyContent:"center",
        alignSelf: "center"
    },
    button: {
        backgroundColor: secondColor,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        marginBottom: "auto",
        minHeight: 40,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 100,
        flex: 1,
        flexDirection: "row",
    },
    buttonText: {
        color: 'white',
        fontSize: normalize(16),
        fontFamily: mainFont,
    }})


export default BigButton

