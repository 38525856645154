import { Season, UserRoles } from "./interfaces"
//import { createHash } from 'node:crypto'

export function standardizeDates(json) {
    if (json) {
        let keys = Object.keys(json)
        var out = {...json}
        keys.forEach(
            (value, index, array) => {
                //console.log('stad', value, typeof json[value], json[value])
                let valTp = typeof json[value]
                if (valTp == 'object') {
                    if (json[value] == null) {
                        out[value] = undefined
                    } else if (json[value].toDate) {
                        out[value] = json[value].toDate()
                    } else if (json[value] instanceof Array) {
                        for (let i = 0; i < json[value].length; i++) {
                            if (typeof json[value][i] == 'object') {
                                out[value][i] = standardizeDates(json[value][i])
                            } 
                        }
                    } else {
                        out[value] = standardizeDates(json[value])
                    }
                    
                } else if (valTp == 'string') {
                    const pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
                    const condition = new RegExp(pattern, 'g');
                    
                    if (condition.test(json[value])) {
                        out[value] = new Date(json[value])
                    }
                }
            }
        )
        return out

    } else {
        return json
    }
    
}

export const dateToTimeString = (date) => {
    let h = date.getHours().toString()
    let m = date.getMinutes().toString()
    m = m.length == 1 ? "0" + m : m 
    return (
        h + ":" + m
    )
}

export const monthString = (date) => {
    if (date) {
        let d = date.getDate()
        let m = date.getMonth() + 1
        let y = date.getFullYear()

        return d.toString() + "." +  m.toString() + "." + y.toString()

    } else {
        return "NA"
    }
    
}

export const splitTime = (strTime) => {
    var hour
    var minute
    //console.log(strTime, strTime.length, strTime.substring(0,2), strTime.substring(2,4))
    if (strTime) {
        if (strTime.length == 4) {
            hour = strTime.substring(0,2)//.parseInt()
    
            minute = strTime.substring(2,4)//.parseInt()
            
        } else if (strTime.length == 3) {
            hour = strTime.substring(0,1)//.parseInt()
            minute = strTime.substring(1,3)//.parseInt()
            
        }
        //console.log(hour, minute)
        return [parseInt(hour), parseInt(minute)]
    } else {
        return [0, 0]
    }
    

}

export const hoursMinutesToString = ([h,m]) => {
    return h.toString() + ":" + (m.toString().length == 1 ? "0" + m.toString() : m.toString())
}


export const extractTime = (date) => {
    if (date) {
        let dt = date
        //console.log('ted jdu tudy', dt)
        const hh = dt.getHours()
        //console.log('ted jdu tudy 2', hh)
        const mm = dt.getMinutes()
        //console.log('ted jdu tudy 3', mm)
        const tm = hh * 100 + mm
        //console.log('ted jdu tudy 4', tm, tm.toString())
        return tm.toString()
    } else {
        return ""
    }
}

export const dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
    if (a_start <= b_start && b_start < a_end) return true; // b starts in a
    if (a_start < b_end   && b_end  <= a_end) return true; // b ends in a
    if (b_start <  a_start && a_end   <  b_end) return true; // a in b
    return false;
}

export const findInObjectUsingPath = (path, object) => {
    let out = {...object}
    for (let i = 0; i < path.length; i++) {
        out = out[path[i]]
    }
    return out
}

export const setValueInObjectUsingPath = (path, object, newValue) => {
    let out = {...object}
    for (let i = 0; i < path.length; i++) {
        out = {...out[path[i]]}
    }
    return out
}

export const firestoreAutoId = () => {
    const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  
    let autoId = ''
  
    for (let i = 0; i < 20; i++) {
      autoId += CHARS.charAt(
        Math.floor(Math.random() * CHARS.length)
      )
    }
    return autoId
  }
export const stdSeas = (seasData, key) => {
    let outSeasData = {
        courtsList: [],
        ...seasData,
        
    }
    //console.log('Dpc tak co se kurva loguje', key, typeof(key), key != '', key != undefined)
    if (key != '' && key != undefined) {
        outSeasData.to = seasData.to.toDate()
        outSeasData.from = seasData.from.toDate()
        outSeasData.planTo = seasData.planTo.toDate()
        outSeasData.planFrom = seasData.planFrom.toDate()
        outSeasData['key'] = key
    }
    
    
    return outSeasData
}

export const getTrueRoles = (roles: {[roleId:string]: boolean}) => Object.entries(roles).reduce((prev, [key, value]) => {
    if (value == true) {
        return [...prev, key]
    } else {
        return [...prev]
    }
},[] as string[])

export const isAnyRole = (roles: string[], userRoles: UserRoles):boolean => {
    try {
        for (let i = 0; i < roles.length; i++) {
            let role = roles[i]
            if (userRoles && userRoles[role] && userRoles[role] == true) {
                return true
            }
        }
        return false
    }
    catch (e) {
        console.log('Error evaluation roles')
        return false
    }
}

export const returnActiveSeasons = (date, seasons) => {
    var outSeas: Season[] = []
    let stdSeas = standardizeDates({...seasons})
    //console.log('Test',seasons)
    Object.keys(stdSeas).forEach((seas) => {
        let seasData: Season = {...stdSeas[seas], key: seas}
        if (!(seas != '' && seas != undefined)) {
            console.log('Tfuj')
        } else if (seasData.from <= date && seasData.to >= date) {
            outSeas.push(seasData)
        } 
    })
    //console.log('Test out',outSeas)
    return outSeas
}

export function getNextDayOfWeek(date, dayOfWeek) {
    // Code to check that date and dayOfWeek are valid left as an exercise ;)

    var resultDate = new Date(date.getTime());

    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);

    return resultDate;
}


export const depersonalizeUserData = (data) => {
    let out = {}
    let keys = Object.keys(data)
    for (let i = 0; i < keys.length; i++) {
        var currItem = data[keys[i]]
        if (typeof currItem == "string" && keys[i].toLowerCase().slice(-2) != 'id' ) {
            currItem = "******"
        } else if (currItem instanceof Date) {
            currItem = new Date(1900,1,1)
        }
        out[keys[i]] = currItem
    }
    return out
}

export const evalWinner = (result) => {
    var res = {
        away: 0,
        home: 0,
    }
    
    for (let i = 0; i < result.length; i++) {
        let set = result[i]
        res['home'] += set['home'].games > set['away'].games ? 1 : 0
        res['away'] += set['home'].games > set['away'].games ? 0 : 1
    }

    if (res['away'] > res['home']) {
       return 'away' 
    } else if (res['away'] < res['home']) {
        return 'home' 
    } else {
        return 'tie'
    }
}


export function isDateEqual (dateA:Date, dateB:Date) {
    
    return (dateA.getTime() === dateB.getTime());
}

export const equals = (a: string[], b: string[]) =>
a.length === b.length &&
a.every((v, i) => v === b[i]);

/* export function md5(content:string) {  
    return createHash('md5').update(content).digest('hex')
} */

export function age(birthdate: Date, today: Date) {
    const oneOrZero = (today.getMonth() < birthdate.getMonth() || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()))
    const age = (today.getFullYear() - birthdate.getFullYear() - (oneOrZero?1:0));
    return age;
}