import React, { useEffect } from "react";
import { ActivityIndicator, FlatList, StyleSheet, Text, Pressable, View } from "react-native";
import UserAvatar from "../../../components/UserAvatar";
import Ionicons from '@expo/vector-icons/Ionicons';
import { normalize } from "../../../utilities/constants";
import FormEntry from "../../../components/FormEntryNew";
import FormHeading from "../../../components/FormHeading";
import i18n, { tokens } from "../../../i18n";
import ResultItemUser from "../../../components/ResultItemUser";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../../store/hooks";
import { getUserData, mySubscriptionsSubscribe, queryGameResults } from "../../../store/actions/actionsUsers";
import { user, UserRoles } from "../../../../functions/src/interfaces";
import ButtonComponent from "./ButtonComponent";
import { monthString } from "../../../utilities/funcs";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { SubscribtionListItem } from "../../../utilities/interfaces";
import { getMySubscriptions } from "../../../store/selectors";
import { shallowEqual } from "react-redux";
import { RootStore } from "../../../store/reducers/rootReducer";
import ClicableUserAvatar from "../../../components/ClickableUserAvatar";
import { UserOrClub } from "../../../store/reducers/usersReducer";

type Props = {
    type: 'user' | 'contact',
    uid: string,
    currentUser: UserOrClub<UserRoles>
}
function UserProfile(props: Props): JSX.Element {
    const {uid,type, currentUser} = props
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    const clubSubscriptions: SubscribtionListItem[] = useAppSelector((state: RootStore) => getMySubscriptions(state, uid), shallowEqual)
    const userGames = useAppSelector((state: RootStore) => state.users.userResults, shallowEqual)
    const usersData = useAppSelector((state: RootStore) => state.users.usersData, shallowEqual)
    const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    const contacts = useAppSelector((state:RootStore) => {
        let contOut = {}
        
        let uids = Object.keys(state.auth.contacts || {})
        for (let i = 0; i < uids.length; i++) {
            let uid = uids[i]
            if (state.auth.contacts && state.auth.contacts[uid]) {
                contOut[uid] = {...state.auth.contacts[uid]}
            }
        }
        return contOut
    }, shallowEqual)

    useEffect(() => {
        dispatch(getUserData(uid, () => {}))
        dispatch(queryGameResults(uid, (bool,context)=> {}))
        let subsUnsub = dispatch(mySubscriptionsSubscribe(uid))
        console.log('Calling subscribe')
        return () => {
            if (subsUnsub) subsUnsub()
        }
    }, [uid])

    const getProfile = (userData: user, type: "user" | "contact") => {
        if (type == "contact") {
            return [
                {key: i18n.t(tokens.app.screens.profile.profileFirstName), value: userData.first_name},
                {key: i18n.t(tokens.app.screens.profile.profileFamilyName), value: userData.family_name},
            ]
            
        } else {
            return [
                {key: i18n.t(tokens.app.screens.profile.profileFirstName), value: userData.first_name},
                {key: i18n.t(tokens.app.screens.profile.profileFamilyName), value: userData.family_name},
                {key: i18n.t(tokens.app.screens.profile.profileBirthDate), value: monthString(userData.dateOfBirth)},
                {key: i18n.t(tokens.app.screens.profile.profileAddress), value: userData.address},
                {key: i18n.t(tokens.app.screens.profile.profileCity), value: userData.city}
            ]
        } 
    }

    

    const userData = (usersData, uid) => {
        if (type == "contact") {
            if (usersData && usersData[uid]) {
                return usersData[uid]
            } else {
                return {}
            }
        } else if (type == "user") {
            return currentUser
        }
    }

    return (
        <View style={localStyles.container}>
            <ClicableUserAvatar
            disabled={type == 'contact'}
            onPress={() => navigation.navigate("Camera", {uid: uid})}
            size={normalize(170)} 
            uid={userData(usersData, props.uid).uid}
            />
            
        <Text maxFontSizeMultiplier={1.2} style={{fontSize: 20, fontWeight:"bold", alignSelf:"center"}}>{userData(usersData, props.uid)?userData(usersData, props.uid).first_name + ' ' + userData(usersData, props.uid).family_name:"Not loaded"}</Text>
        <Text maxFontSizeMultiplier={1.2} style={{fontSize: 10, alignSelf:"center"}}>
            <Ionicons 
            name="at-outline"
            color={"black"}
            size={12}
            />
        {userData(usersData, props.uid)?userData(usersData, props.uid).email:"Not loaded"}
        <Ionicons 
            name="call-outline"
            color={"black"}
            size={12}
            />
        {userData(usersData, props.uid)?userData(usersData, props.uid).phone:"Not loaded"}
        </Text>
        <ButtonComponent
        isContact={Object.keys(contacts).includes(uid)}
        isLoggedUser={type == "user"}
        disabled={!(type == "user" || (currentUser.type == "user" && currentUser.isGod == true) || (currentUser.type == "user" && currentUser.isAdmin == true) || (currentUser.type == "clubs" && selectedClub == currentUser.firebaseId))}
        uid={uid}
        />
        
        <FormHeading heading={i18n.t(tokens.app.screens.profile.profileDetail)}/>
        <View style={localStyles.detailContainer}>
        {getProfile(userData(usersData, props.uid), type).map((item) => {
            return (<FormEntry key={item.key} caption={item.key} editable={false}>

                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{item.value}</Text>
            </FormEntry>)
        })}
        </View>
        
        <FormHeading heading={i18n.t(tokens.app.screens.profile.profileMembershipTitle)}/>
        <View key="subs" style={localStyles.detailContainer}>
        {//Filter if not viewing own subs
        clubSubscriptions.filter(item => {
            if (uid == currentUser.uid) {
                return true
            } else {
                if (item.clubId == selectedClub) {
                    return true
                } else {
                    return false
                }
            }
        }).map((item) => {
                return (
                <FormEntry key={item.clubId} caption={item.club_name} editable={false}>
                    {item.active?
                    <Text>{`${i18n.t(tokens.app.screens.profile.profileMembershipTo)}: ${monthString(item.activeTill)}`}</Text>:
                    <Text>{`${i18n.t(tokens.app.screens.profile.profileSubscriptionNA)}`}</Text>}
                </FormEntry>)
            })
        }
        </View>
        <FormHeading heading={i18n.t(tokens.app.screens.profile.profileLast10games)} />
        {userGames.isLoaded == true && userGames.uid == uid?
        <View style={localStyles.detailContainer}>
        {userGames.data.results.map((item) => {
            return (<ResultItemUser key={item.firebaseId} uid={uid} gamePlayers={item.gamePlayers} result={item.result}/>)
        })}
        </View>:<ActivityIndicator size={"small"}/>}     
             
                    
                        
                    
        </View>
    )
}

export default UserProfile

const localStyles = StyleSheet.create({
    container: {
        alignItems: "center",
        width: "100%",
    },
    detailContainer: {
        width: "100%"
    }
})