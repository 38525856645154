import { Route } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import Calendar from "../../components/Calendar/Calendar";
import { globalStyleSheet } from "../../utilities/globalStyles";

type Props = {
    route: Route<'BrowseCalendar', { club: string, date: Date }>
}

function BrowseCalendar(props: Props):JSX.Element {
    return (
        <View style={globalStyleSheet.container}>
            <Calendar selectedClub={props.route.params.club} date={props.route.params.date} changeMarket={true}/>
        </View>
    )
}



export default BrowseCalendar