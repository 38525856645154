import React from "react";
import { View } from "react-native";
import FormHeading from "../../../components/FormHeading";
import FormEntry from "../../../components/FormEntryNew";
import { roleTypes } from "../../../utilities/constants";
import { TextInput } from "react-native-gesture-handler";
import { StateLoader } from "../../../utilities/interfaces";
import { Club } from "../../../../functions/src/interfaces";
import { RootStore } from "../../../store/reducers/rootReducer";
import { useAppSelector } from "../../../store/hooks";
import { shallowEqualJSON } from "../../../utilities/funcs";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import i18n, { tokens } from "../../../i18n";

type Props = {
    dispatchChange: (data: any) => void
}

function AgeRoles(props: Props) {
    
    const managedClub: StateLoader<Club> = useAppSelector((state: RootStore) => state.auth.managedClub, shallowEqualJSON)

    return (
        <View>
            <FormHeading
            heading={i18n.t(tokens.app.screens.manageClub.manageClubAgeRoles)}
            />
            <FormEntry
            caption={roleTypes.isChild + " <= "}
            editable={true}
            >
                <TextInput
                value={String(managedClub.data?.ageRelatedRoles?.isChildUntil || 0)}
                //defaultValue="0"
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                inputMode="numeric"
                onChangeText={(text) => {
                    let changes = {
                        ...managedClub.data.ageRelatedRoles,
                        isChildUntil: parseInt(text)
                    }
                    props.dispatchChange({ageRelatedRoles: changes})
                }}
                />
            </FormEntry>
            <FormEntry
            caption={roleTypes.isPensioner + " >= "}
            editable={true}
            >
                <TextInput
                value={String(managedClub.data?.ageRelatedRoles?.isPensionerFrom || 0)}
                //defaultValue="0"
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                inputMode="numeric"
                onChangeText={(text) => {
                    let changes = {
                        ...managedClub.data.ageRelatedRoles,
                        isPensionerFrom: parseInt(text)
                    }
                    props.dispatchChange({ageRelatedRoles: changes})
                }}
                />
            </FormEntry>
        </View>
    )
}


export default AgeRoles