import { Route, useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { FlatList, View, Text, Pressable, Platform } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { shallowEqual } from 'react-redux'
import { CheckoutData, MarketTypes } from '../../../functions/src/interfaces'
import ActionOverlay from '../../components/ActionOverlay'
import FormEntry from '../../components/FormEntryNew'
import WebWrapper from '../../components/WebWrapper'
import i18n, { tokens } from '../../i18n'
import { queryCheckouts } from '../../store/actions/actionsCheckout'
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks'
import { RootStore } from '../../store/reducers/rootReducer'
import { monthString, paymentStateEval } from '../../utilities/funcs'
import { StateLoader } from '../../utilities/interfaces'

type Props = {
    route: Route<'Checkout', {uid: string, clubId: string}>
}

function CheckoutListScreen(props:Props):JSX.Element {
    const {uid, clubId} = props.route.params
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()

    const queriedCheckouts: StateLoader<(CheckoutData & {marketType: MarketTypes} )[]> = useAppSelector((state: RootStore) => {
        let allData =  state.checkout.queriedCheckouts
        let checkoutData = allData?.data?.data || {}
        let returnData = {
            ...allData,
            data: Object.values(checkoutData)
        }
        return returnData
    }, shallowEqual)

    useEffect(() => {
        dispatch(queryCheckouts(uid, clubId, (bool, context) => {}, false))
    }, [uid, clubId])
    return (
        
            <WebWrapper>
            <ActionOverlay
            onBackdropPress={()=> {}}
            fullScreen={true}
            isVisible={queriedCheckouts.isActionPressed}
            />
            <FlatList 
            data={queriedCheckouts.data}
            renderItem={({item}) => {
                return (
                    <Pressable
                    onPress={() => navigation.push('ManageMarket', {type: undefined, uid: item.mainUid, marketId: item.firebaseId, club: item.club})}
                    >
                        <FormEntry
                        editable={false}
                        caption={`${i18n.t(item.marketType || tokens.app.components.picker.undefined)} ${monthString(item.updatedDate)}`}
                        >
                            <Text>{i18n.t(paymentStateEval({...item, isCalculated: false}))}</Text>
                        </FormEntry>
                    </Pressable>
                )
            }}
            keyExtractor={(item) => item.firebaseId}
            />

            </WebWrapper>
        
    )
}

export default CheckoutListScreen