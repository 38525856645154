import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Alert, Platform, StyleSheet, Text, Pressable, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { Market, MarketLongreservation, MarketReservation, MarketStatuses, Season } from '../../../../functions/src/interfaces';
import ActionOverlay from '../../../components/ActionOverlay';
import BigButton from '../../../components/BigButton';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import CustomPickerSelect from '../../../components/CustomPickerSelect/CustomPickerSelect';
import FormEntry from '../../../components/FormEntryNew';
import FormEntryNoCaption from '../../../components/FormEntryNoCaption';
import FormHeading from '../../../components/FormHeading';
import TimeInput from '../../../components/TimeInput/TimeInput';
import i18n, { tokens } from '../../../i18n';
import { marketApprovalAction } from '../../../store/actions/actionsMarketNew';
import { addReservation } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/reservationReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { courtTypes, normalize, reccurency, seasonTypes, weekdays } from '../../../utilities/constants';
import { monthString, objectToPicker } from '../../../utilities/funcs';
import { globalStyleSheet } from '../../../utilities/globalStyles';
import { showToast } from '../../../utilities/toastcontroller';


type Props = {
    managedMarketData: StateLoader<MarketLongreservation<Market>>,
    dispatchActionForChanges: (callback) => void,
    disabled: boolean
}

function PeriodComponent(props: Props): JSX.Element {


    const navigation = useAppNavigation()
    const [editPeriodFlag, setEditPeriodFlag] = useState(props.managedMarketData.data?.firebaseId ? false : true)
    const seasons: {[seasonId: string]: Season} = useAppSelector(state => state.auth.seasons, shallowEqual)
    const queriedCollisions = useAppSelector((state: RootStore) => state.market.queriedCollisions, shallowEqual)

    const fields = useAppSelector(state => {
        let selectedClub = state.auth.selectedClub
        let clubs = state.auth.allClubs
        return Object.assign({}, clubs[selectedClub].courts)
    }, shallowEqual)

    const dispatchChange = (callback: (params) => void) => {
        props.dispatchActionForChanges(
            callback
        )
    }
    const courtItems = (season) => {
        let out = []
        if (seasons[season]?.courtsList) {
            let obj: {[key: string]: string} = Object.entries(seasons[season]?.courtsList).reduce((prev, [key, court]) => {
                if (key) {
                    //let court = seasons[season]?.courtsList[key]
                    if (court.selected) {
                        return {
                            ...prev, 
                            [key]: court.courtId.toString() + " - " + courtTypes[court.type]
                        }
                    } else {
                        return {...prev}
                    }

                } else {
                    return {...prev}
                }
            }, {})
            return objectToPicker(obj)
        }
        return out
    }
    const seasonsItems = (seasonsData: {[key: string]: Season}) => {
        
        let obj: {[key: string]: string} = Object.entries(seasonsData).reduce((prev,[key, seas]) => {
            let out = {
                ...prev,
                [key]: seasonTypes[seas.type] + ' - ' + seas.from.getFullYear().toString()
            }
            return out
            
        }, {})
        return objectToPicker(obj)
    }

    return (
        <View
            style={styles.container}>
            <FormHeading
                heading={i18n.t(tokens.app.screens.longreservation.periodHeading)}
                editButton={() => setEditPeriodFlag(prev => !prev)}
            />
            {editPeriodFlag ?
                <>
                    <FormEntry
                        caption={i18n.t(tokens.app.screens.longreservation.season)}
                        error={props.managedMarketData.validations.season.error}
                        validity={props.managedMarketData.validations.season.validity}
                        editable={!props.disabled}
                    >
                        <CustomPickerSelect
                            onValueChange={(value) => {
                                if (value) {
                                    //console.log("Tohle tam chci narvat" ,value,prevValue)
                                    let today = new Date
                                    let tommorow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
                                    tommorow.setHours(0, 0, 0, 0)
                                    let twoweeks = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14)
                                    twoweeks.setHours(0, 0, 0, 0)
                                    //console.log('tady to pada', seasons, managedMarket.data)
                                    let newValue = {
                                        season: value,
                                        from: seasons[value].from > tommorow ? seasons[value].from : tommorow,
                                        to: seasons[value].to,
                                        dueDate: seasons[value].planTo > tommorow ? seasons[value].planTo : twoweeks,
                                    }

                                    dispatchChange(() => { return { ...newValue } })
                                }
                            }
                            }
                            value={props.managedMarketData.data.season}
                            placeholder={{ label: i18n.t(tokens.app.screens.longreservation.season), value: undefined }}
                            /* disabled={newReservation.mainUid == props.auth.uid?false:true} */
                            items={
                                seasonsItems(seasons)
                            } />
                    </FormEntry>
                    {props.managedMarketData.data.season ?
                        <FormEntry
                            caption={i18n.t(tokens.app.screens.longreservation.periodFrom)}
                            error={props.managedMarketData.validations.from.error}
                            validity={props.managedMarketData.validations.from.validity}
                            editable={!props.disabled}
                        >

                            <CustomDatePicker
                                containerStyle={globalStyleSheet.inputOk}
                                date={props.managedMarketData.data.from}
                                fontStyle={globalStyleSheet.entityText}
                                setDate={(newDate) => dispatchChange(() => { return { from: newDate } })}
                            />

                        </FormEntry> : null}

                    {props.managedMarketData.data.season ?
                        <FormEntry
                            caption={i18n.t(tokens.app.screens.longreservation.periodTo)}
                            error={props.managedMarketData.validations.to.error}
                            validity={props.managedMarketData.validations.to.validity}
                            editable={!props.disabled}
                        >
                            <View style={{ alignSelf: "flex-end" }}>
                                <CustomDatePicker
                                    containerStyle={globalStyleSheet.inputOk}
                                    date={props.managedMarketData.data.to}
                                    fontStyle={globalStyleSheet.entityText}
                                    setDate={(newDate) => dispatchChange(() => { return { to: newDate } })}
                                />
                            </View>
                        </FormEntry> : null}
                    <FormEntry
                        caption={i18n.t(tokens.app.screens.longreservation.weekday)}
                        editable={!props.disabled}>
                        <CustomPickerSelect
                            onValueChange={(value) => {

                                //console.log("Tohle tam chci narvat" ,value,prevValue)
                                let newValue = {

                                    day: parseInt(value)
                                }
                                dispatchChange(() => { return { ...newValue } })

                            }
                            }
                            value={String(props.managedMarketData.data.day)}
                            placeholder={{ label: i18n.t(tokens.app.screens.longreservation.weekday), value: undefined }}
                            /* disabled={newReservation.mainUid == props.auth.uid?false:true} */
                            items={objectToPicker(weekdays)} />


                    </FormEntry>
                    <FormEntry
                        caption={i18n.t(tokens.app.screens.longreservation.reccurency)}
                        editable={!props.disabled}
                    >
                        <CustomPickerSelect
                            onValueChange={(value) => {

                                //console.log("Tohle tam chci narvat" ,value,prevValue)
                                let newValue = {
                                    ...value,
                                    weekRecur: parseInt(value)
                                }
                                dispatchChange(() => { return { ...newValue } })

                            }
                            }
                            value={String(props.managedMarketData.data.weekRecur)}
                            placeholder={{ label: i18n.t(tokens.app.screens.longreservation.reccurency), value: undefined }}
                            /* disabled={newReservation.mainUid == props.auth.uid?false:true} */
                            items={objectToPicker(reccurency)}
                        />

                    </FormEntry>
                    <FormHeading heading={i18n.t(tokens.app.screens.longreservation.timingHeading)} />
                    <FormEntry
                        caption={i18n.t(tokens.app.screens.longreservation.timeStart)}
                        error={props.managedMarketData.validations.timeFrom.error}
                        validity={props.managedMarketData.validations.timeFrom.validity}
                        editable={!props.disabled}
                    >
                            <TimeInput
                                value={props.managedMarketData.data.timeFrom}
                                disabled={props.disabled}
                                onValueChange={(value) => {
                                    dispatchChange(() => { return { timeFrom: parseInt(value) } })
                                }}
                            />
 
                    </FormEntry>
                    <FormEntry
                        caption={i18n.t(tokens.app.screens.longreservation.timeEnd)}
                        error={props.managedMarketData.validations.timeTo.error}
                        validity={props.managedMarketData.validations.timeTo.validity}
                        editable={!props.disabled}
                    >

                            <TimeInput
                                value={props.managedMarketData.data.timeTo}
                                disabled={props.disabled}
                                onValueChange={(value) => {
                                    dispatchChange(() => { return { timeTo: parseInt(value) } })
                                }}
                            />

                    </FormEntry>
                    <FormEntry
                        caption={i18n.t(tokens.app.screens.longreservation.court)}
                        error={props.managedMarketData.validations.court.error}
                        validity={props.managedMarketData.validations.court.validity}
                        editable={!props.disabled}
                    >
                        <CustomPickerSelect
                            onValueChange={(value) => {

                                //console.log("Tohle tam chci narvat" ,value,prevValue)
                                let newValue = {

                                    court: value
                                }

                                dispatchChange(() => { return { ...newValue } })

                            }
                            }
                            value={props.managedMarketData.data.court}
                            placeholder={{ label: i18n.t(tokens.app.screens.reservations.court), value: undefined }}
                            /* disabled={newReservation.mainUid == props.auth.uid?false:true} */
                            items={
                                courtItems(props.managedMarketData.data.season)
                            }
                        />



                    </FormEntry>
                    <FormEntry
                    caption={i18n.t(tokens.app.screens.longreservation.collisions)}
                    editable={false}
                    >
                        <Pressable
                        onPress={() => navigation.navigate('ReservationsListScreen', {type: "collisions"})}
                        >
                            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>
                                {i18n.t(tokens.app.screens.longreservation.viewButton) + ' ' + (queriedCollisions.data?.length?queriedCollisions.data?.length:0).toString()}
                            </Text>
                        </Pressable>
                    </FormEntry>
                </> : 
                <FormEntryNoCaption
                editable={false}
                direction="column"
                ><>
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{i18n.t(tokens.app.screens.longreservation.periodMessage, {
                        season: props.managedMarketData.data.season ? seasonTypes[seasons[props.managedMarketData.data.season].type].toLowerCase().slice(0, -1) : "NA",
                        from: monthString(props.managedMarketData.data.from),
                        to: monthString(props.managedMarketData.data.to),
                        weekday: weekdays[props.managedMarketData.data.day],
                        recur: props.managedMarketData.data.weekRecur ? reccurency[props.managedMarketData.data.weekRecur].toLowerCase() : "NA",
                    })}</Text>
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{i18n.t(tokens.app.screens.longreservation.timingMessage, {
                        timeFrom: props.managedMarketData.data.timeFrom,
                        timeTo: props.managedMarketData.data.timeTo,
                        court: props.managedMarketData.data.court ? props.managedMarketData.data.court + ' - ' + courtTypes[fields[props.managedMarketData.data.court].type] : "NA",
                    })}</Text>
                    </>
                </FormEntryNoCaption>}
        </View>
    )
}

const styles = StyleSheet.create({

    textStyle: {

        textAlign: "right",
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',

    },
    container: {
        width: "100%",

    }
});

export default PeriodComponent
