import Constants from "expo-constants";
import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { PaymentData, PaymentModes } from "../../../../functions/src/interfaces";
import BigButton from "../../../components/BigButton";
import { useAppDispatch, useAppNavigation } from "../../../store/hooks";
import { StateLoader } from "../../../store/reducers/checkoutReducer";

//ADD localhost address of your server
const API_URL = Constants.expoConfig.extra.functionsUrlBase

type Props = {
    paymentData: StateLoader<PaymentData & {clientSecret?:string}>
    allowStripe: boolean,
    reinitialize?: (method: PaymentModes) => Promise<string>,
    isAdmin: boolean,
}



const StripePayment: React.FC<Props> = props => {
    //const {club, checkoutId, marketId} = props
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    //const selectedClub = useSelector(state => state.auth.selectedClub, shallowEqual)
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [mode, setMode] = useState(PaymentModes.paymentModeNone);
    const [clientSecret, setClientSecret] = useState("")
    const [loader, setLoader] = useState({ isLoaded: false, isSuccess: false })

    
    





 


    return (
        <View style={styles.container}>
            {loader.isLoaded?
            <>
            <Text>Submit card detail</Text>
            </>
            :<BigButton
            buttonText="Zaplatit"
            onPress={() => props.reinitialize(props.paymentData.data.type)}
            />}
            
            
        </View>
    );
};
export default StripePayment;

const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: "column",
        width: "75%",
        alignItems: "center",
        //justifyContent: "space-evenly",
    },
    methodSelectTitle: {
        fontSize: 14,
        letterSpacing: 1.5,
        color: 'black',
        textTransform: 'uppercase'
    },
    container: {
        flex: 1,
        justifyContent: "center",
        margin: 20,
        alignItems: "center",  
    },
    input: {
        backgroundColor: "#efefefef",

        borderRadius: 8,
        fontSize: 20,
        height: 50,
        padding: 10,
    },
    card: {
        backgroundColor: "#efefefef",
    },
    cardContainer: {
        height: 50,
        marginVertical: 30,
    },
    buyButton: {
        backgroundColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
    },
    textButton: {
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        color: '#007DFF'
    },
    selectButton: {
        borderColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        borderWidth: 2,
    },
    boldText: {
        fontSize: 17,
        fontWeight: '700'
    }
});
