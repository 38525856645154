import { Route } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { FlatList, Platform, StyleSheet, Pressable, View, Text } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { shallowEqual } from "react-redux";
import { Club, user } from "../../../functions/src/interfaces";
import BigButton from "../../components/BigButton";
import RenderContactClub from "../../components/ContactClub";
import CustomPickerSelect from "../../components/CustomPickerSelect/CustomPickerSelect";
import FormEntry from "../../components/FormEntryNew";
import FormEntryNoCaption from "../../components/FormEntryNoCaption";
import FormHeading from "../../components/FormHeading";
import RadioButton from "../../components/RadioButton";
import SmallButton from "../../components/SmallButton";
import i18n, { tokens } from "../../i18n";
import { addClubSubscription, removeClubSubscription, setSelectedClub } from "../../store/actions/actionsAuth";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks";
import { RootStore } from "../../store/reducers/rootReducer";
import { getCurrentUserWithRoles } from "../../store/selectors";
import { globalStyleSheet } from "../../utilities/globalStyles";
import ClubList from "../../components/ClubsList";
import MyClubsComponent from "./Components/MyClubsComponent";

type Props = {
    route: Route<'ManageMyClubsScreen', {}>
}

function ManageMyClubsScreen(props: Props): JSX.Element {
    const dispatch = useAppDispatch()
    
    
    const navigation = useAppNavigation()
    const currentUser: undefined | user = useAppSelector((state: RootStore) => {
        let currData = state.auth.currentUserData
        if (currData.type == "user") {
            return currData
        } else {
            undefined
        }
    }, shallowEqual)
    const [club, setClub] = useState(undefined)
    

    const changeSelection = (value) => {
        if (value) {
            setClub(value)
        }
    }

    
    
    

    const clubAddAction = (item: string) => dispatch(addClubSubscription(item, currentUser.uid))
    const clubRemoveAction = (item: string) => dispatch(removeClubSubscription(item, currentUser.uid))
    const selectClub = (item: string) => dispatch(setSelectedClub(item))

    if (currentUser) {
    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        
        >
        <View style={globalStyleSheet.container}>
            <FormEntryNoCaption 
            editable={false}
            >
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.messageText}>{i18n.t(tokens.app.screens.myClubs.myClubsMessage)}</Text> 
            </FormEntryNoCaption>
            <MyClubsComponent
            selectedClubs={currentUser.clubs || []}
            addSelectedAction={clubAddAction}
            removeSelectedAction={clubRemoveAction}
            selectClubAction={selectClub}
            />
        </View>
        </KeyboardAwareScrollView>
    )
    } else {
        return null
    }
}

const localStyles = StyleSheet.create({
    pickClubRow: {flexDirection: "row", flex: 1, justifyContent: "space-between", alignItems: "center"}
})

export default ManageMyClubsScreen