import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { View, Text, TextInput, Switch, FlatList, ActivityIndicator } from 'react-native'
import { shallowEqual } from 'react-redux'
import { Subscription, UserRoles } from '../../../../functions/src/interfaces'
import BigButton from '../../../components/BigButton'
import CustomPickerSelect from '../../../components/CustomPickerSelect/CustomPickerSelect'
import FormEntry from '../../../components/FormEntryNew'
import FormHeading from '../../../components/FormHeading'
import Overlay from '../../../components/Overlay'
import i18n, { tokens } from '../../../i18n'
import { useAppSelector } from '../../../store/hooks'
import { RootStore } from '../../../store/reducers/rootReducer'
import { membershipTypes } from '../../../utilities/constants'
import { monthString, objectToPicker } from '../../../utilities/funcs'
import { globalStyleSheet, secondColor } from '../../../utilities/globalStyles'
import { StateLoader } from '../../../utilities/interfaces'
type Props = {
    isModalVisible: boolean,
    toggleModal: () => void,
    removeUser: (user) => void
}
const RenderModal: React.FC<Props> = (props) => {

    const modalData = useAppSelector((state: RootStore) => state.auth.userDeletion, shallowEqual)

    return (
      <Overlay isVisible={props.isModalVisible} onBackdropPress={props.toggleModal}>
         
                  
              
              {modalData.confirmed ? (
                  
                    modalData.isLoaded ? (
                        <View>
                            <Text maxFontSizeMultiplier={1.2}>{modalData.success ? (
                                i18n.t(tokens.app.screens.profile.profileDeletionOK)
                            ) : (
                                i18n.t(tokens.app.screens.profile.profileDeletionKO)
                            )}</Text>
                            <Text maxFontSizeMultiplier={1.2}>{modalData.error.length > 0?JSON.stringify(modalData.error):""}</Text>
                            <BigButton
                            buttonText='OK'
                            onPress={() => {
                                props.toggleModal()
                            }}
                            />
                             
                        </View>
                        
                    ) : (
                        <ActivityIndicator  size="small" color={secondColor} />
                    )
                  
              )
              :(
                <View
                
                >
                <Text maxFontSizeMultiplier={1.2}>{i18n.t(tokens.app.screens.profile.profileDeletionConfirmationQuestion)}</Text>
                <View style={{flexDirection: "row", justifyContent: "space-evenly"}}>
                    <BigButton 
                    onPress={() => {
                        props.removeUser(modalData)
                    }}
                    buttonText={i18n.t(tokens.app.screens.profile.profileDelete)}
                    />
                    <BigButton 
                    onPress={() => {
                        props.toggleModal()
                    }}
                    buttonText={i18n.t(tokens.app.screens.profile.profileDeleteCancel)}
                    />
                
                    
                    </View>
                    </View>
              )
            }
          
      </Overlay>
    )
}

export default RenderModal