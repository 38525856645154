import { GameTypes, MarketStatuses, MarketTypes, MembershipTypes, NewsTypes, PaymentModes, RoleTypes } from '../../functions/src/interfaces';
import { AboutAppScreen, Alerts, AppliedPlayers, AppText, AvatarComponent, ButtonCaptions, CameraScreen, CheckoutItemsTypes, CheckoutListScreen, CheckoutScreen, ContactsScreen, Courts, CreditScreen, Functions, GameSetting, HomeScreen, IndexScreen, LinkedUsersScreen, LoginScreen, LongReservationScreen, ManageAllClubsScreen, ManageClubScreen, ManageLinkedUsersScreen, ManageMarketScreen, ManageMyClubsScreen, ManageReservationScreen, ManageSeasonScreen, MarketCommons, MarketScreen, Messages, NewsEntity, NewsFilters, NewsScreen, OpenGameScreen, PaymentScreen, PaymentState, ProductPaymentDescriptions, ProfileScreen, RankingEntry, RankingScreen, Reccurency, RegistrationScreen, ReservationFilters, ReservationsScreen, ReservationStatisticsScreen, ResultScore, RightMenu, Seasons, SubscriptionScreen, Tabs, UnverifiedScreen, Weekdays } from './keys';

export const tokens = {
  app: {
    intro: AppText,
    tabs: Tabs,
    screens: {
      registration: RegistrationScreen,
      login: LoginScreen,
      reservations: ReservationsScreen,
      manageReservation: ManageReservationScreen,
      unverified: UnverifiedScreen,
      contacts: ContactsScreen,
      profile: ProfileScreen,
      longreservation: LongReservationScreen,
      opengame: OpenGameScreen,
      market: MarketScreen,
      news: NewsScreen,
      home: HomeScreen,
      manageMarket: ManageMarketScreen,
      index: IndexScreen,
      camera: CameraScreen,
      ranking: RankingScreen,
      checkout: CheckoutScreen,
      payment: PaymentScreen,
      subscription: SubscriptionScreen,
      manageClub: ManageClubScreen,
      manageSeason: ManageSeasonScreen,
      myClubs: ManageMyClubsScreen,
      allClubs: ManageAllClubsScreen,
      credit: CreditScreen,
      linkeduser: LinkedUsersScreen,
      manageLinked: ManageLinkedUsersScreen,
      checkoutList: CheckoutListScreen,
      statistics: ReservationStatisticsScreen,
      about: AboutAppScreen,
    },
    constants: {
      courts: Courts,
      seasons: Seasons,
      weekdays: Weekdays,
      gametypes: GameTypes,
      reccurency: Reccurency,
      markettypes: MarketTypes,
      paymentstates: PaymentState,
      marketstates: MarketStatuses,
      checkoutItems: CheckoutItemsTypes,
      paymentModes: PaymentModes,
      roleTypes: RoleTypes,
      newsTypes: NewsTypes,
      memTypes: MembershipTypes,
      invoicePaymentTypeDesc: ProductPaymentDescriptions,
    },
    components: {
      gameSettings: GameSetting,
      newsEntity: NewsEntity,
      appliedPlayers: AppliedPlayers,
      messages: Messages,
      rightMenu: RightMenu,
      alerts: Alerts,
      result: ResultScore,
      rank: RankingEntry,
      market: MarketCommons,
      newsFilters: NewsFilters,
      reservationFilters: ReservationFilters,
      picker: Functions,
      avatar: AvatarComponent,
      button: ButtonCaptions,
      
    }
  }
}