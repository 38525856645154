import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TextInput, Button, Alert, Platform, Pressable, ActivityIndicator } from "react-native";
import Constants from "expo-constants";
import { shallowEqual, useSelector } from "react-redux";
import FormHeading from "../../../components/FormHeading";
import { Route, useNavigation } from "@react-navigation/native";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getCurrentUserWithRoles } from "../../../store/selectors";
import BigButton from "../../../components/BigButton";
import { handleCheckoutAction, subscribeForPayment } from "../../../store/actions/actionsCheckout";
import { Club, PaymentData, PaymentModes } from "../../../../functions/src/interfaces";
import { RootStore } from "../../../store/reducers/rootReducer";
import { StateLoader } from "../../../store/reducers/checkoutReducer";
import StripePayment from "./StripePayment";
import PaymentQR from "./PaymentQR";
import PaymentCredit from "./PaymentCredit";
import PaymentPayU from "./PaymentPayU";
import PaymentCash from "./PaymentCash";

//ADD localhost address of your server
const API_URL = Constants.expoConfig.extra.functionsUrlBase

type Props = {
    paymentData: StateLoader<PaymentData & {clientSecret?:string}>,
    price: number,
    //marketId: string,
    checkoutId: string,
    clubData: Club,
    mode: PaymentModes,
    allowStripe: boolean,
    reinitialize?: (method: PaymentModes) => Promise<any>,
    isAdmin: boolean,
    redirect?: string,
}


const PaymentByType: React.FC<Props> = props => {
    //const mode = props.paymentData?.data?.type || PaymentModes.paymentModeNone
    if (props.mode == PaymentModes.paymentModeCard) {
        return <StripePayment isAdmin={props.isAdmin} reinitialize={props.reinitialize} paymentData={props.paymentData} allowStripe={props.allowStripe}/>
    } else if (props.mode == PaymentModes.paymentModeBT) {
        return <PaymentQR isAdmin={props.isAdmin} reinitialize={props.reinitialize} paymentAmt={props.price} paymentId={123} paymentData={props.paymentData}/>
    } else if (props.mode == PaymentModes.paymentModeCredit) {
        return <PaymentCredit isAdmin={props.isAdmin} reinitialize={props.reinitialize} paymentAmt={props.price} paymentId={props.paymentData.data.firebaseId} paymentData={props.paymentData} checkoutId={props.checkoutId} clubId={props.clubData.firebaseId}/>
    } else if (props.mode == PaymentModes.paymentModePayU) {
        return <PaymentPayU isAdmin={props.isAdmin} reinitialize={props.reinitialize} paymentData={props.paymentData} redirect={props.redirect}/>
    } else if (props.mode == PaymentModes.paymentModeCash) {
        return <PaymentCash isAdmin={props.isAdmin} reinitialize={props.reinitialize} paymentData={props.paymentData}/>
    } else {
        return null
    }
 


    
};
export default PaymentByType;

const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: "column",
        width: "75%",
        alignItems: "center",
        //justifyContent: "space-evenly",
    },
    methodSelectTitle: {
        fontSize: 14,
        letterSpacing: 1.5,
        color: 'black',
        textTransform: 'uppercase'
    },
    container: {
        flex: 1,
        justifyContent: "center",
        //margin: 20,
        //alignItems: "center",  
    },
    input: {
        backgroundColor: "#efefefef",

        borderRadius: 8,
        fontSize: 20,
        height: 50,
        padding: 10,
    },
    card: {
        backgroundColor: "#efefefef",
    },
    cardContainer: {
        height: 50,
        marginVertical: 30,
    },
    buyButton: {
        backgroundColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
    },
    textButton: {
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        color: '#007DFF'
    },
    selectButton: {
        borderColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        borderWidth: 2,
    },
    boldText: {
        fontSize: 17,
        fontWeight: '700'
    }
});
