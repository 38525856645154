import React from 'react'
import { StyleSheet, Switch, Text, View } from 'react-native'

import { useAppDispatch } from '../store/hooks'
import { normalize } from '../utilities/constants'

type Props = {
    switchCaption: string,
    value: boolean,
    switchKey: string,
    disabled?: boolean,
    dispatchChange: (data: {[role: string]: boolean}) => void
}

const RoleSwitch: React.FC<Props> = (props) => {


    const extra = {
        borderWidth: props.disabled?0:1
    }
    

  
    return (<View style={[style.entityContainer,extra]}>
            <Text maxFontSizeMultiplier={1.2}>{props.switchCaption}</Text>
            <Switch 
            //disabled={!isAllowedToEdit()}
            style={style.switchStyle}
            disabled={props.disabled}
            value={props.value}
            onValueChange={(value) => {
                props.dispatchChange({[props.switchKey]: value})
            }}
            />
        </View>
    )
}

const style = StyleSheet.create({
        entityContainer: {
            flexDirection: 'column',
            //justifyContent: 'space-between',
            marginTop: 3,
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 5,
            borderColor: "grey",
            borderWidth: 1,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 3,
            paddingBottom: 3,
            margin: 2,
            minWidth: 100,
            
        },
        textStyle: {
            fontSize: normalize(8)
        },
        switchStyle: { transform: [{ scaleX: .5 }, { scaleY: .5 }] }
    })

export default RoleSwitch

