//import { useEffect, useState } from 'react'
import { Image, Text, Pressable, View } from 'react-native'
import { StyleSheet } from 'react-native';
import { normalize } from '../utilities/constants';
import { mainFont, mainFontBold, secondColor, styles } from '../utilities/globalStyles'
import Ionicons from '@expo/vector-icons/Ionicons';

type Props = {
    onPress: () => void,
    disabled?: boolean,
    icon?: any,
    buttonText: string,
    baseTextSize?: number,
    baseButtonHeight?: number,
}

const SmallButton: React.FC<Props> = (props) => {
    const style = StyleSheet.create({
    
        inner: {
            //flex: 1,
            flexDirection: "row",
            justifyContent:"center",
            //width: "100%",
            alignItems: "center",
        },
        button: {
            backgroundColor: "grey",
            minHeight: normalize(props.baseButtonHeight?props.baseButtonHeight:40),
            borderRadius: normalize(props.baseButtonHeight?props.baseButtonHeight/2:20),
            alignItems: "center",
            justifyContent: 'center',
            paddingLeft: 10,
            paddingRight: 10,
            minWidth: 100,
            margin: 5,
        },
        buttonText: {
            marginLeft: 5,
            color: 'white',
            fontSize: normalize(props.baseTextSize?props.baseTextSize:18),
            fontFamily: mainFont,
        }})
  
    return (
        
        <Pressable
        style={style.button}
        onPress={() => props.onPress()}
        disabled={props.disabled}
        >
        <View style={style.inner}>
        <Ionicons 
        name={props.icon}
        color={"white"}
        size={normalize(props.baseTextSize?props.baseTextSize:18)}
        />
        <Text maxFontSizeMultiplier={1.2} style={style.buttonText}>{props.buttonText}</Text>
        </View>
        </Pressable>
    )
}



export default SmallButton

