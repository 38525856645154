import { StyleSheet } from "react-native";
import { normalize } from "../../utilities/constants";
import { mainFont, mainFontBold } from "../../utilities/globalStyles";


export const styles = StyleSheet.create({
    header: {
        alignSelf: "center",
        margin: 10
    },
    headerText: {
        fontFamily: mainFontBold,
        fontSize: normalize(25)
    },
    container: {
        flex: 1
    },
    reservationDetail: {
        flexDirection: "column",
        width: "100%",
        backgroundColor: "white",
        borderRadius: 10,
        marginBottom: 5,
    },
    priceDetailContainer: {
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60,
        paddingLeft: 20,
        paddingRight: 20,
        
    },
    priceDetailAvatarAndNumber: {
        flexDirection: "row",
        alignItems: "center"
    },
    itemText: {
        fontFamily: mainFont,
        fontSize: normalize(18)
    },
    itemPriceText: {
        fontFamily: mainFontBold,
        fontSize: normalize(18)
    },
    buttonRow: {
        flexDirection: "row",
        width: "100%"
    },
    messageContainer: {
        width: "100%"
    },
    messageText: {
        fontSize: normalize(20),
        fontFamily: mainFont
    }
})