import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Modal, Pressable, StyleSheet, Text } from "react-native";
import { View } from "react-native";
import { mainColor, secondColor } from "../utilities/globalStyles";
import Ionicons from '@expo/vector-icons/Ionicons';
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withDelay, withTiming } from "react-native-reanimated";
import { BlurView } from "expo-blur";

type FloatingActionProps = {
    visible?: boolean,
    actions: any[]
    color?: string,
    overrideWithAction?: boolean,
    onPressItem: (name: any) => void,
    buttonSize: number
}

type ActionProps = {
    icon: any,
    index: number,
    totalActionsCnt: number,
    text?: string,
    isOpened: boolean,
    onPressItem: (name: string) => void,
    name: string
}

const DEFAULT_SHADOW_PROPS = {
    shadowOpacity: 0.35,
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowColor: "#000000",
    shadowRadius: 3
  };

export type Handle = {

    setActionY: (val: number) => void
}


const DURATION = 300;
const DELAY = 200;
const TRANSLATE_Y = -60;

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const ActionComponent = (props:ActionProps) => {

    const actionFlag = useSharedValue(0)
    

    const onPressHandle = () => {
        props.onPressItem(props.name)
    }

    const animStyle = useAnimatedStyle<any>(() => {
        return {
            transform: [
                {translateY: actionFlag.value*TRANSLATE_Y*(props.totalActionsCnt-(props.index)+0.5)},
                {scale: interpolate(actionFlag.value, [1, 0], [1, 0])}
            ]
        }
    })


    const setActionY = (totalActionsCnt: number) => {
        console.log(props.isOpened)
        if (!props.isOpened) {
            actionFlag.value = withDelay(DELAY*(props.index), withTiming(0, {duration: DURATION}))
        } else {
            actionFlag.value = withDelay(DELAY*(props.index), withTiming(1, {duration: DURATION}))
        }
    }

    useEffect(() => {
        setActionY(props.totalActionsCnt)
    },
    [props.isOpened])

    

    return (
        <AnimatedPressable style={[styles.actionButton, animStyle]} onPress={onPressHandle}>
            <View style={styles.actionContainer}>
                
                {props.text?(
                <View style={styles.actionTextContainer}>
                    <Text>{props.text}</Text>
                </View>
                ):null}
                <View style={styles.innerActionIconContainer}>
                    <Ionicons name={props.icon} size={36} color={"white"}/>
                </View>
            </View>
            
        </AnimatedPressable>
    )
}

const FloatingAction = (props: FloatingActionProps) => {

    const [isOpened,setIsOpened] = useState(false)
    const [visible,setVisible] = useState(true)
    
    const references = props.actions

    function handlePress() {
        if (props.overrideWithAction == true) {
            props.onPressItem(props.actions[0].name)
        } else {
            setIsOpened(val => !val)
        }
    }

    useEffect(() => {
        setVisible(props.visible || true)
    }, [props.visible])



    return visible?(
        <>
        
    
    <View style={styles.container}>
        <Pressable 
        onPress={handlePress}
        style={({pressed}) => pressed ? [styles.plusButton, { transform: [{ scale: 0.9 }]}] : [styles.plusButton]}
        >
            <Ionicons name="add" size={36} color={"white"}/>
        </Pressable>
        
            
        
    </View>
    {isOpened? (<Pressable style={styles.overlay} onPress={handlePress}>
                <BlurView  style={styles.overlay} intensity={100}>
        
            </BlurView>
        </Pressable>) : null}
        {
            references.map((item, index) => {
                return (
                    <ActionComponent 
                    isOpened={isOpened} 
                    key={index} 
                    icon={item.icon || "add"} 
                    index={index} 
                    totalActionsCnt={references.length} 
                    text={item.text}
                    onPressItem={props.onPressItem}
                    name={item.name}
                    />
                )
            })
        }
    
    </>
    ):null
}   

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 50,
        right: 30,
        zIndex: 3,
    },
    plusButton: {
        width: 60,
        height: 60,
        backgroundColor: secondColor,
        borderRadius: 30,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        ...DEFAULT_SHADOW_PROPS
    },
    actionButton: {
        right: 30,
        position: "absolute",
        bottom: 50,
        ...DEFAULT_SHADOW_PROPS
    },
    innerActionIconContainer: {
        width: 40,
        height: 40,
        backgroundColor: mainColor,
        borderRadius: 20,
        justifyContent: "center",
        alignItems: "center",
        margin: 4
    },
    actionContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    actionTextContainer: {
        backgroundColor: "white"
    },
    overlay: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        elevation: 0,
        zIndex: 0
      },
})

export default FloatingAction