import { Route } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { ActivityIndicator, Platform, StyleSheet, Text, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { shallowEqual } from 'react-redux'
import BigButton from '../../components/BigButton'
import WebWrapper from '../../components/WebWrapper'
import { handleCheckoutAll, subscribeForCheckout } from '../../store/actions/actionsCheckout'
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks'
import { CheckoutActions, StateLoader } from '../../store/reducers/checkoutReducer'
import { normalize } from '../../utilities/constants'
import { globalStyleSheet, mainFont, mainFontBold } from '../../utilities/globalStyles'
//import { checkoutData } from '../../utilities/interfaces'

import { AnyMarket, CheckoutData, Club, MarketTypes, PaymentModes, PaymentStatuses, UserRoles } from '../../../functions/src/interfaces'
import ActionOverlay from '../../components/ActionOverlay'
import FormHeading from '../../components/FormHeading'
import { subscribeForRequest } from '../../store/actions/actionsMarketNew'
import { RootStore } from '../../store/reducers/rootReducer'

import { isAnyRole } from '../../../functions/src/funcs'
import FormEntry from '../../components/FormEntryNew'
import i18n, { tokens } from '../../i18n'
import { MarketActions } from '../../store/reducers/marketReducer'
import { getCurrentUserWithRoles } from '../../store/selectors'
import { CheckoutItems } from './Components/CheckoutItemDetail'
import { RenderModeMessage } from './Components/CheckoutModeMessage'
import { styles } from './styles'
import { CheckoutModes } from './Components/types'
import { showToast } from '../../utilities/toastcontroller'
import { UserOrClub } from '../../store/reducers/usersReducer'
import CheckoutScreenComponent from './Components/CheckoutScreenComponent'



type Props = {
    route: Route<'Checkout', {checkoutId: string, clubId: string, mode?: CheckoutModes}>
    
}




function CheckoutScreen(props: Props):JSX.Element {
    const {checkoutId, mode, clubId} = props.route?.params
    return (
        <CheckoutScreenComponent
        checkoutId={checkoutId}
        mode={mode}
        clubId={clubId}
        />
    )
}



export default CheckoutScreen

