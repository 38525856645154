import React, { useEffect, useRef, useState } from 'react';
import { Text, Pressable, useWindowDimensions, View } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { dateBOD, dateEOD } from '../../utilities/funcs';
import { secondColor } from '../../utilities/globalStyles';
import Overlay from '../Overlay';

type Props = {
    date: Date,
    setDate: (state) => void,
    icon?: boolean,
    iconId?: any,
    placeholder?: any,
    containerStyle?: any,
    fontStyle: any,
    disabled?: boolean,
    maximumDate?: Date,
    minimumDate?: Date,
    timeAlignment?: "BOD" | "EOD"
}



function CustomDatePicker(props: Props) {

    
    const windowWidth = useWindowDimensions().width - 40;
    const windowHeight = useWindowDimensions().height;
    const gridDim = Math.min((windowWidth)/7, 50)
    const dateButton = useRef(null);
    const currDate = props.date ? props.date : new Date 
    const setCurDate = props.setDate


    
    //const [ animation, setAnimation ] = useState(new Animated.Value(0));
    //const animation = new Animated.Value(0)

    const [isModalVisible, setIsModalVisible] = useState(false)
    //const translation = useRef(new Animated.Value(0)).current;
    const [numOfWeeks, setNumOfWeeks] = useState(3)

    

    const toggleModal = () => {
        
        setIsModalVisible((prevValue) => {
            //Animated.spring(animation, {
            //    toValue: prevValue ? 0 : 1,
            //    useNativeDriver: false//Platform.OS == 'ios' || Platform.OS == "android" ? true : false 
            //}).start()
            //console.log(animation)
            //setAnimation(new Animated.Value(0))
            return !prevValue
        })
      }
    var numOfW = numOfWeeks
    const getNumOfWeeks = (num) => {
        numOfW = num
    }

    useEffect(() => {
        setNumOfWeeks(numOfW)
        
    }, [numOfW])
    
    
    const dateString = (date) => {
        let d = date.getDate()
        let m = date.getMonth() + 1
        let y = date.getFullYear()

        return d.toString() + "." +  m.toString() + "." + y.toString()
    }
    

    const renderModal = () => {
          return (
            <Overlay isVisible={isModalVisible} onBackdropPress={toggleModal}>
            <CalendarGrid 
                maximumDate={props.maximumDate}
                minimumDate={props.minimumDate}
                timeAlignment={props.timeAlignment}
                currDate={currDate} 
                gridDim={gridDim} 
                returnNumberOfWeeks={getNumOfWeeks}
                setSelectedDate={
                    (selectedDate) => {
                        setCurDate(selectedDate)
                    }
                }
                toggleModal={toggleModal}
            />
            </Overlay>
          )
      }

    

    return (
        <View>
        {renderModal()}
        <Pressable disabled={props.disabled} onPress={(event) => {
           //console.log(event)
           toggleModal()
           }}>
               <View ref={dateButton} style={{flexDirection: "row", ...props.containerStyle}}>
                <Text maxFontSizeMultiplier={1.2} style={props.fontStyle}>{(props.placeholder ? props.placeholder : "") + dateString(currDate)}</Text>

            {props.icon?<Ionicons 
                name={props.iconId?props.iconId:"calendar-outline"}
                color="black"
                size={props.fontStyle?.fontSize?props.fontStyle.fontSize:20}
                
                />:null}
                </View>
            </Pressable>
            
            
        </View>
           
    )
}

export default CustomDatePicker


function CalendarGrid(props) {
    const [currDate, setCurrDate] = useState(props.currDate)
    

    const makeDaysArray = (date) => {
        
        var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        //console.log('firstDayOfMonth', firstDayOfMonth.toString())
        var firstDayOfGrid = new Date(firstDayOfMonth);
        firstDayOfGrid.setDate(firstDayOfMonth.getDate() - (firstDayOfMonth.getDay() == 0 ? 6 : firstDayOfMonth.getDay() - 1))
        //console.log('firstDayOfGrid', firstDayOfGrid.toString())
        var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 0);
        //console.log('ahoj', lastDayOfMonth.toString())
        var dateJs = []


        var loopDate = new Date(firstDayOfGrid);
        //loopDate.setDate(firstDayOfGrid.getDate())
        //console.log('loopDate', loopDate.toString())
        while (loopDate <= lastDayOfMonth) {
            var row = []
            var withinRange = true
            if (props.maximumDate && props.maximumDate < loopDate) withinRange = false
            if (props.minimumDate && props.minimumDate > loopDate) withinRange = false
            for (let i = 0; i < 7; i++) {
                var dayObj = {
                    week: dateJs.length,
                    dayOfWeek: i,
                    date: new Date(loopDate),
                    dateOfMonth: loopDate.getDate(), 
                    color: loopDate >= firstDayOfMonth && loopDate <= lastDayOfMonth && withinRange ? "black" : "grey"
                }
                
                row.push(dayObj)
                loopDate.setDate(loopDate.getDate() + 1)
            }
            dateJs.push(row)
            
        } 
        props.returnNumberOfWeeks(dateJs.length)
        return dateJs
    }

    const getMonthString = (date) => {
        return date.getMonth().toString() + "/" + date.getFullYear().toString()
    }

    

    const [month, setMonth] = useState(getMonthString(new Date))
    const [monthArr, setMonthArr] = useState(makeDaysArray(new Date))
    
    useEffect(() => {
        
        if (month != getMonthString(currDate)) {
            setMonth(getMonthString(currDate))
            setMonthArr(makeDaysArray(currDate))
        }
        
    }, [currDate])

    const changeDate = (increment, type) => {
        if (type == 'year') {
            //if modal is visible increments are adding/subtracting month
            setCurrDate(new Date(currDate.getFullYear() + increment, currDate.getMonth(), currDate.getDate()))
            
        } else {
            //if modal is visible increments are adding/subtracting day
            setCurrDate(new Date(currDate.getFullYear(), currDate.getMonth() + increment, currDate.getDate()))
        }
    }

    const monthString = (date) => {
        let d = date.getDate()
        let m = date.getMonth() + 1
        let y = date.getFullYear()

        return m.toString()
    }

    const yearString = (date) => {
        let d = date.getDate()
        let m = date.getMonth() + 1
        let y = date.getFullYear()

        return y.toString()
    }

    return (
        
        <View style={{flexDirection: "column"}}>
            <View style={
                {
                    
                    height: 40, 
                    flexDirection: "row", 
                    alignContent:"space-between", 
                    justifyContent: "space-between",
                    borderBottomWidth: 0, 
                    borderColor: "grey", 
                    marginBottom: 3
                    }}>
            <Pressable onPress={() => changeDate(-1, 'year')}>
            <Ionicons 
                name="chevron-back"
                color="black"
                size={35}
                
                />
            </Pressable>
            
                
            <Text maxFontSizeMultiplier={1.2} style={{fontSize: 18, fontWeight: "bold", letterSpacing: 3}}>{yearString(currDate)}</Text>
           
            
            <Pressable onPress={() => changeDate(1, 'year')}>
            <Ionicons 
                name="chevron-forward"
                color="black"
                size={35}
                
                />
            </Pressable>
            
            </View>
            <View style={
                {
                    //width: "100%", 
                    height: 40, 
                    flexDirection: "row", 
                    alignContent:"space-between", 
                    justifyContent: "space-between",
                    borderBottomWidth: 0, 
                    borderColor: "grey", 
                    marginBottom: 3
                    }}>
            <Pressable onPress={() => changeDate(-1, 'month')}>
            <Ionicons 
                name="chevron-back"
                color="black"
                size={35}
                
                />
            </Pressable>
            
                
            <Text maxFontSizeMultiplier={1.2} style={{fontSize: 18, fontWeight: "bold", letterSpacing: 3}}>{monthString(currDate)}</Text>
           
            
            <Pressable onPress={() => changeDate(1, 'month')}>
            <Ionicons 
                name="chevron-forward"
                color="black"
                size={35}
                
                />
            </Pressable>
            
            </View>
            {
                    monthArr ? monthArr.map((row, rowIdx) => {
                        
                        return (
                            <View key={rowIdx} style={{ height: props.gridDim, flexDirection: "row"}}>
                                {row.map((col, colIdx) => {
                                    //console.log('Shit', col )
                                    return (
                                        <Pressable disabled={col.date > props.maximumDate || col.date < props.minimumDate} key={colIdx} style={
                                            {
                                                width: props.gridDim, 
                                                height: props.gridDim, 
                                                //flex:1, 
                                                justifyContent: 'center', //Centered horizontally
                                                alignItems: 'center', //Centered vertically
                                                backgroundColor: col.date.toDateString() == props.currDate.toDateString() ? secondColor: "white",
                                                borderRadius: props.gridDim/2,
                                            }}
                                            onPress={() => {
                                                //console.log("selected " + col.date.toDateString())
                                                if (props.timeAlignment && props.timeAlignment == "BOD") {
                                                    props.setSelectedDate(dateBOD(col.date))
                                                } else if (props.timeAlignment && props.timeAlignment == "EOD") {
                                                    props.setSelectedDate(dateEOD(col.date))
                                                } else {
                                                    props.setSelectedDate(col.date)
                                                }
                                                props.toggleModal()
                                            }}
                                            >
                                            <Text maxFontSizeMultiplier={1.2} style={{color: col.color}}>{col.dateOfMonth}</Text>
                                        </Pressable>
                                    )
                                })}
                            </View>
                        )
                    }) : null
                }
        </View>
        
    )
}



