import React from "react";
import { Switch, View } from "react-native";
import { Season } from "../../../../functions/src/interfaces";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import CustomPickerSelect from "../../../components/CustomPickerSelect/CustomPickerSelect";
import FormEntry from "../../../components/FormEntryNew";
import TimeInput from "../../../components/TimeInput/TimeInput";
import i18n, { tokens } from "../../../i18n";
import { useAppDispatch } from "../../../store/hooks";
import { AuthActions } from "../../../store/reducers/authReducer";
import { seasonTypes } from "../../../utilities/constants";
import styles from '../styles';

type Props = {
    season: Season,
    activeUntil?: Date,
    disabled?: boolean 
}

function RenderSeasonComponent(props:Props) {
    const tourItems = () => {
        let dt = new Date
        let currYear = dt.getFullYear()
        var out = []
        for (let i = 2000; i < currYear + 2; i++) {
            out.push({ label: String(i), value: String(i), key: i})
        }
        return out
    }
    const dispatch = useAppDispatch()
    return (<View>
        <FormEntry
        caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonTour)}
        editable={!(props.disabled || false)}
        >
            <CustomPickerSelect
            disabled={props.disabled}
            onValueChange={(value) => dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {tour: parseInt(value)}}})} 
            value={String(props.season.tour)}
            placeholder={{label: i18n.t(tokens.app.screens.manageSeason.manageSeasonTour), value: undefined}}
            
            items={tourItems()
            }/>
        </FormEntry>
        
        <FormEntry 
        caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonSeasonType)}
        editable={!(props.disabled || false)}
        >
    <CustomPickerSelect
    disabled={props.disabled}
    onValueChange={(value) => dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {type: value}}})} 
    value={props.season.type}
    placeholder={{label: i18n.t(tokens.app.screens.manageSeason.manageSeasonSeasonType), value: undefined}}
    
    items={Object.keys(seasonTypes).map(
        (key) => {
            return { label: seasonTypes[key], value: key }
        }
    )
    }/>
</FormEntry>
<FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonPlanFrom)}
editable={!(props.disabled || false)}
>
    <CustomDatePicker 
    icon={false}
    disabled={props.disabled}
    containerStyle={styles.inputOk}
    date={props.season.planFrom} 
    fontStyle={{fontSize: 15, letterSpacing: 3}}
    maximumDate={props.activeUntil}
    setDate={(newDate) => {dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {planFrom: newDate}}})}}
    timeAlignment={"BOD"}
    />
</FormEntry>
{/* <FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonPlanTo)}
editable={!(props.disabled || false)}
>
    <CustomDatePicker 
    icon={false}
    disabled={props.disabled}
    containerStyle={styles.inputOk}
    date={props.season.planTo} 
    fontStyle={{fontSize: 15, letterSpacing: 3}}
    maximumDate={props.activeUntil}
    setDate={(newDate) => {dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {planTo: newDate}}})}}
    timeAlignment={"EOD"}
    />
</FormEntry> */}
{/* <FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonLockedFrom)}
editable={true}
        >
    <CustomDatePicker 
    icon={false}
    containerStyle={styles.inputOk}
    date={props.season.lockedFrom} 
    fontStyle={{fontSize: 15, letterSpacing: 3}}
    setDate={(newDate) => {dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {lockedFrom: newDate}}})}}
    />
</FormEntry> */}

<FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonFrom)}
editable={!(props.disabled || false)}
>
    <CustomDatePicker 
    icon={false}
    disabled={props.disabled}
    containerStyle={styles.inputOk}
    date={props.season.from} 
    fontStyle={{fontSize: 15, letterSpacing: 3}}
    maximumDate={props.activeUntil}
    setDate={(newDate) => {dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {from: newDate, planTo: newDate}}})}}
    timeAlignment={"BOD"}
    />
</FormEntry>
<FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonTo)}
editable={!(props.disabled || false)}
>
    <CustomDatePicker 
    icon={false}
    disabled={props.disabled}
    containerStyle={styles.inputOk}
    date={props.season.to} 
    fontStyle={{fontSize: 15, letterSpacing: 3}}
    maximumDate={props.activeUntil}
    setDate={(newDate) => {dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {to: newDate}}})}}
    timeAlignment={"EOD"}
    />
</FormEntry>
<FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonLockedTo)}
editable={!(props.disabled || false)}
>
    <CustomDatePicker 
    icon={false}
    disabled={props.disabled}
    containerStyle={styles.inputOk}
    date={props.season.lockedTo} 
    fontStyle={{fontSize: 15, letterSpacing: 3}}
    maximumDate={props.activeUntil}
    setDate={(newDate) => {dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {lockedTo: newDate}}})}}
    timeAlignment={"EOD"}
    />
</FormEntry>
<FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonOpenFrom)}
editable={!(props.disabled || false)}
>
    <TimeInput
    disabled={props.disabled}
    value={props.season.openFrom}
    onValueChange={(value) => {
        dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {openFrom: value}}})
    }}
    />
</FormEntry>
<FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonOpenTo)}
editable={!(props.disabled || false)}
>
    <TimeInput 
    disabled={props.disabled}
    value={props.season.openTo}
    onValueChange={(value) => {
        dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {openTo: value}}})
    }}
    />
</FormEntry>
<FormEntry 
caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonPlanningFinished)}
editable={(!props.disabled ?? true) && props.season.firebaseId ? true : false}
>
    <Switch 
    disabled={props.disabled || props.season.firebaseId ? false : true}
    value={props.season.planningFinished}
    onValueChange={(value) => {
        dispatch({type: AuthActions.authEditSeasonData, data: {season: props.season.firebaseId, value: {planningFinished: value}}})
    }}
    />
</FormEntry>
</View>)
}

export default RenderSeasonComponent