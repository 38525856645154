import React from 'react';
import { Alert, Platform, Text, Pressable, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { GameTypes, OpengameStatuses, AllResTypes, ReservationTypes } from '../../../functions/src/interfaces';
import i18n, { tokens } from '../../i18n';
import { useAppNavigation, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { UserOrClub } from '../../store/reducers/usersReducer';
import { getCurrentAuthState, getCurrentUserWithRoles } from '../../store/selectors';
import { normalize } from '../../utilities/constants';
import { dateToTimeString } from '../../utilities/funcs';
import { gameTypesColors } from '../../utilities/globalStyles';
import { calcBlocks } from './common';
import { SegmentRes } from './types';




type Props = {
    segment: SegmentRes
}

const ReservationsBlock: React.FC<Props> = (props) => {
    const borderWidth = 2
    const blockDims = {
        height: 90,
        width: 50
    }
    const currentUser = useAppSelector(getCurrentUserWithRoles, (a, b) => {
        return JSON.stringify(a) == JSON.stringify(b)
    })
    const navigation = useAppNavigation()
    const users: {[uid: string]: UserOrClub} = useAppSelector((state: RootStore) => state.users.usersData, shallowEqual) 
    const { isEmpty, user, isLoaded } = getCurrentAuthState()

    const styleBasedOnType = (block:AllResTypes) => {
        if (block?.status == "open") {
            return gameTypesColors["locked"]
        } else if (block.type == ReservationTypes.locked) {
            return gameTypesColors[ReservationTypes.locked]
        } else if (block.type == ReservationTypes.outoforder) {
            return gameTypesColors[ReservationTypes.locked]
        } else if (block?.status == "blocked") {
            return gameTypesColors["locked"]
        } else if (block.opengameId && block.openGameStatus == 'open') {
            return gameTypesColors["opengame"]
        } else if (block?.isOnBehalf == true && block?.isNotRegistered != undefined && block?.isNotRegistered == false) {
            return gameTypesColors[block.type]
        } else if (block?.isOnBehalf == true) {
            return gameTypesColors["onBehalf"]
        //} else if (block.gameType == GameTypes.training) {
        //    return gameTypesColors[block.gameType]
        } else if (block.type == "longreservation") {
                return gameTypesColors[block.type]
        } else if (block.type == "reservation") {
            return gameTypesColors[block.type]
        } else {
            return null
        }
    }

    const getUserName = (uid: string) => {
        if (uid && users && Object.keys(users).includes(uid)) {
            let user = users[uid]
            //console.log('User data: ', user, uid)
            if (user.type == 'clubs') {
                return user.club_name
            } else {
                return user.first_name + " " + user.family_name
            }
        } else {
            return ""
        }
        
    }
    
    const reservationCaption = (block:AllResTypes) => {
        if (block.type == ReservationTypes.locked || block.type == ReservationTypes.outoforder) {
            return getUserName(block.mainUid) + " - " + block.text
        } else {
            if (block.isOnBehalf == true && block?.isNotRegistered != undefined && block?.isNotRegistered == false) {
                return getUserName(block.mainUid) + (block.gameType == GameTypes.training ? ` - ${i18n.t(tokens.app.constants.gametypes.training)}` : '')
            } else if (block.isOnBehalf == true) {
                return (block.onBehalfFirstName ? block.onBehalfFirstName  + ' ' : "") + (block.onBehalfFamilyName ? block.onBehalfFamilyName : "")
            } else {
                return getUserName(block.mainUid) + (block.gameType == GameTypes.training ? ` - ${i18n.t(tokens.app.constants.gametypes.training)}` : '')
            }
        }
    }

    
    //console.log(props)
    return (
        <View 
        
                key={props.segment.key}
                //onPress={() => doDetailedSelection(props.block.smallBlocks[0])}
                 >
                {props.segment.reservations.length>0?props.segment.reservations.map((res,index) => {
                    //console.log(res.firebaseId + 'Reservation calc range: ', calcBlocks(new Date(res.dateFrom), new Date(res.dateTo)))
                    return (<View
                        key={index}
                        style={{
                            flex: 1,
                            borderWidth: borderWidth,
                            borderColor: "grey",
                            borderRadius: 5,
                            backgroundColor: styleBasedOnType(res),
                            width: blockDims.width* calcBlocks(new Date(res.dateFrom), new Date(res.dateTo)),
                            marginLeft: blockDims.width* calcBlocks(props.segment.segmentStarts, res.dateFrom),
                        }}><Pressable
            style={{
                flexDirection: "column",
                flex: 1,
                backgroundColor: styleBasedOnType(res),
            }}
            onPress={
                () => {
                    if (res.type == ReservationTypes.locked) {
                        if (Platform.OS == "web") {
                            alert(i18n.t(tokens.app.screens.reservations.courtIsLocked))
                        } else {
                            Alert.alert(i18n.t(tokens.app.screens.reservations.courtIsLocked))
                        }
                    } else if (res.type == ReservationTypes.outoforder) {
                        navigation.navigate('ManageReservationScreen', {firebaseId: res.firebaseId, club: res.club})
                    } else {
                        if (currentUser && currentUser?.type == "user") {
                            if (!(currentUser.isAdmin || currentUser.isGod || res.allplayers.includes(currentUser.uid)  || res.openGameStatus == OpengameStatuses.open )) {
                                console.log('Cannot view this reservation, not allowed ' + res.firebaseId)
                            } else if (res.openGameStatus == OpengameStatuses.open && res.opengameId != "") {
                                navigation.navigate('ManageMarket', {type: "opengame", uid: res.uid, marketId: res.opengameId, club: res.club})
                            } else {
                                //props.manageReservationToState(reservation, (callbackval) => console.log(JSON.stringify(callbackval)))
                                navigation.navigate('ManageReservationScreen', {firebaseId: res.firebaseId, club: res.club})
                            }
                        } else if (currentUser && currentUser?.type  == "clubs") {
                            navigation.navigate('ManageReservationScreen', {firebaseId: res.firebaseId, club: res.club})
                        }
                        
                    }
                }
            }
            >   
                <Text maxFontSizeMultiplier={1.2} style={{fontSize: normalize(10)}}>{dateToTimeString(res.dateFrom)+"-"+dateToTimeString(res.dateTo)}</Text>
                {isEmpty?null:<Text maxFontSizeMultiplier={1.2} style={{fontSize: normalize(10)}}>{reservationCaption(res)}</Text>}
                {(currentUser?.type == "clubs" || (currentUser?.type == "user" && (currentUser?.isAdmin || currentUser?.isGod))) && res.type != ReservationTypes.locked && res.type != ReservationTypes.outoforder && res.paid == true?<View style={{alignSelf: "flex-end", marginTop: "auto"}}><Text maxFontSizeMultiplier={1.2} style={{fontSize: normalize(10)}}>{`Placeno`}</Text></View>:null}
            </Pressable></View>)}):null} 
            </View>
            
        
       
    )
}


export default ReservationsBlock


