import View from '@expo/html-elements/build/primitives/View';
import { Route, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Platform, StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual } from 'react-redux';
import { AllResTypes, BlockedTime, Club, Reservation, ReservationTypes, UserOrClub, UserRoles } from '../../../functions/src/interfaces';
import GamePlayers from '../../components/GamePlayersNew';
import ResultScore from '../../components/ResultScore';
import WebWrapper from '../../components/WebWrapper';
import { editReservation, manageReservationToState, subscribeForReservation } from '../../store/actions/actionsReservationsNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { ReservationActions } from '../../store/reducers/reservationReducer';
import { RootStore } from '../../store/reducers/rootReducer';
import { getCurrentUserWithRoles } from '../../store/selectors';
import ButtonComponent from './ButtonComponent';
import Dialog from './Components/Dialog';
import PriceComponent from '../ManageMarketScreen/CommonMarketComponents/PriceComponent';
import ReservationDetail from './Components/ReservationDetail';
import ReservationTime from './Components/ReservationTime';
import { isAllowedToEdit, validations } from './ReservationValidations';
import { StateLoader } from '../../utilities/interfaces';
import ReservationDetailFull from './Components/ReservationDetailFull';
import BlockedTimeFull from './Components/BlockedTime';

type Props = {
    route: Route<'Reservation', {firebaseId?: string, club?: string, type?: ReservationTypes}>
}


const RenderByType: React.FC<{
    currentUser: UserOrClub<UserRoles>
    clubData: Club,
    reservationData: StateLoader<AllResTypes>  & {allValid: boolean}
}> = (props) => {
    if (props.reservationData.data.type == ReservationTypes.outoforder) {
        
        return <BlockedTimeFull
        reservationData={props.reservationData  as StateLoader<BlockedTime>  & {allValid: boolean}}
        currentUser={props.currentUser}
        />
    } else if (props.reservationData.data.firebaseId) {
        
        return <ReservationDetailFull
        clubData={props.clubData}
        reservationData={props.reservationData  as StateLoader<Reservation>  & {allValid: boolean}}
        currentUser={props.currentUser}
        />
    }
}

function ManageReservationScreen(props: Props):JSX.Element {
    console.log('Rezervace: ' + props.route.params?.firebaseId)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const reservationId = props.route.params?.firebaseId
    const club = props.route.params?.club
    const type = props.route.params?.type
    const [isOverlayVisible, setIsOverlayVisible] = useState({visible: false, type: "longreservation", data: {}})
    const clubData: Club = useAppSelector((state: RootStore) => state.auth.allClubs[props.route.params.club], shallowEqual)

    const isElapsed = (dateTo: Date) => {
        let currDt = new Date
        return currDt > dateTo
    }
     
    //const [validators, setValidators] = useState({})
    const reservationData: StateLoader<AllResTypes>  & {allValid: boolean} = useAppSelector((state: RootStore) => {
        var reservation = {...state.reservations.managedReservation, allValid: true}
        const validators = reservation.seasonsData && reservation.data?.season ? validations(reservation.seasonsData[reservation.data.season]) : {}
        //console.log(validators)
        var validationsData = {}
        var allValid = true
        if (reservation.isLoaded) {
            validationsData = Object.keys(reservation.data).reduce((acc, key) => {acc[key] = {validation: true, error: ""}; return acc}, validationsData)
            let validKeys = Object.keys(validators)
            for (let i = 0; i < validKeys.length; i++) {
                let key = validKeys[i]
                let validator = validators[key]
                let validation = validator.method(reservation.data)
                validationsData[key] = {validity: validation.bool, error: validation.context}
                allValid = allValid == true ? validation.bool : allValid
            }
            //console.log('Probehly validace: ', validationsData)
            reservation['validations'] = {...validationsData}
        }
        reservation['allValid'] = allValid

        return reservation
    }, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    //console.log('Update rezervace: ',reservationData)
    const currentUser = useAppSelector(getCurrentUserWithRoles, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    //const seasons = useAppSelector(state => state.auth.seasons, shallowEqual)

    /* useEffect(() => {
        if (seasons && reservationData.data?.season && seasons[reservationData.data.season]) {
            setValidators(validations(seasons[reservationData.data.season]))
        }
        
    }, [seasons])
 */
    useEffect(()=> {
        var unsub: Promise<() => void> | null = null
        if (reservationId && club) {
            unsub = dispatch(subscribeForReservation(club, reservationId))

        } else if (club && type == ReservationTypes.outoforder && !reservationId) {
            //dispatch()
            //console.log('Davam do statu selekci')
            dispatch(manageReservationToState())
        }
        return () => {
            dispatch({type: ReservationActions.subscribedReservation, data: {}})
            if (unsub) unsub
            .then((resp) => resp())
        }
      }, [reservationId, club])

    const editButton = () => {
        if (reservationData.data.type == ReservationTypes.reservation) {
            navigation.navigate("ManageMarket",{marketId: reservationData.data.reservationRequestId, type: reservationData.data.type})
        } else if (reservationData.data.type == ReservationTypes.longreservation) {
            
            setIsOverlayVisible({type: "longreservation", visible: true, data: {sds: ""}})
        }
    }

    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        dispatch(editReservation(changes))
    }

    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}

        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >
            <WebWrapper>
                {reservationData.isLoaded == true?
                <RenderByType
                currentUser={currentUser}
                reservationData={reservationData}
                clubData={clubData}
                />
                :
                <ActivityIndicator size={"large"} />}
            </WebWrapper>
        </KeyboardAwareScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
})

export default ManageReservationScreen