import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { Market } from '../../../../functions/src/interfaces';
import FormEntry from '../../../components/FormEntryNew';
import i18n, { tokens } from '../../../i18n';
import { globalStyleSheet } from '../../../utilities/globalStyles';


type Props = {
    managedMarketData: Market,
}

function MarketStateComponent(props: Props): JSX.Element {

  

    return (
    <FormEntry
    caption={i18n.t(tokens.app.screens.manageMarket.manageMarketStatus)}
    editable={false}
    >
        <Text 
        maxFontSizeMultiplier={1.2} 
        style={globalStyleSheet.entityText}
        >
        {i18n.t(props.managedMarketData.status)}
        </Text>
    </FormEntry>
    )
}

const styles = StyleSheet.create({

   
});

export default MarketStateComponent
