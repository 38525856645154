import { Alert } from "react-native";
import { dateRangeOverlaps, firestoreAutoId, removeDicretics, standardizeDates } from "../../utilities/funcs"
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"
import { AllUserActions, UsersActions } from "../reducers/usersReducer";
import { ContactsActions } from "../reducers/contactsReducer";
import { getCurrentUserWithRoles } from "../selectors";
import { RootStore } from "../reducers/rootReducer";
import { CallbackFunc, ClubRoles, Membership, MembershipTypes, Subscription, SubscriptionYear, user } from "../../../functions/src/interfaces";
import { AppThunk } from "../hooks";
import { Message } from "../../utilities/interfaces";
import { AuthActions } from "../reducers/authReducer";
const fetch = require('node-fetch');

export const uploadPhoto = (photo: any, uid: string, callback: (url: string) => void): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore, getFileStorage }) => {
        //const rootRef = getFirebase()
        const state: RootStore = getState()
        const storage = getFileStorage()
        const type = state.auth.currentUserData.type
        console.log('Type' + type)
        const fireStore = getFirestore()
        const path = `${type}photos/${type}Photo_${uid}.jpg`
        const docRef = fireStore.collection(type == "clubs"?type:"userspublic").doc(uid)
        var userPhotoPhotoRef = ref(storage, path)
        //rootRef.uploadFile(path, photo)
        
        uploadBytes(userPhotoPhotoRef, photo)
        .then(async (snapshot) => {
            
            const url = await getDownloadURL(snapshot.ref)
            

            docRef.update(
                {photo: url}
            )
            dispatch({type: "PHOTO_UPLOADED", data: {}})
            callback(url)
        })
        .catch((e) => {
            console.log("Photo upload error"+JSON.stringify(e))
            Alert.alert("Photo upload error"+JSON.stringify(e))
        })
    }
}

export const usersPublicSubscribe = (uids, callback) => {
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if (uids) {
            var unsubscribe = []
            const rootRef = getFirebase().firestore()
            //console.log('wishsubscribe', uids)
            uids.forEach(contact => {
                let unsubele = rootRef.collection('userspublic').doc(contact).onSnapshot(
                    (doc) => {
                        var out_data = {}
                        const data = doc.data()
                        
                        out_data[contact] = data
                        //console.log(querySnapshot.docChanges(),out_data)
                        dispatch({type: ContactsActions.contactsUsersSubscribe, data: out_data})
                    },
                    (error) => {
                        
                    }
                )
                
                unsubscribe.push(unsubele)
            }
            
            );
            //console.log('tohle pushuju', unsubscribe)
            
                
            return () => {new Promise(
               (resolve,reject) => {
                   console.log('Unsubscribing all'+ JSON.stringify(unsubscribe));
                   Promise.all(unsubscribe)
                   .then((lst) => resolve(true))
                   .catch((e) => reject(e))
                }
                )
            }
             
        } 
        
    }
}
export const clubmembersSubscribe = (club: string, callback: CallbackFunc): AppThunk<() => void> => {
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        
        //dispatch({type: UsersActions.usersActionClubmembersChange, data: {type: 'user', data: js}})
        const rootRef = getFirebase().firestore()
        //console.log('wishsubscribe', uids)
        let entityRef = rootRef.collection('userspublic').where('clubs','array-contains',club)
        var unsubscribeClubregistered = entityRef.onSnapshot(
            (querySnapshot) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log(doc)
                    js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 

                    
                    
                })
                
                //console.log(js)
                dispatch({type: UsersActions.usersActionClubmembersChange, data: {type: 'user', data: js}})
            },
            (error) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )
            
        let entityLinkedRef = rootRef.collection('userspublic').where('linkedTo','==',club)
        var unsubscribeLinked = entityLinkedRef.onSnapshot(
            (querySnapshot) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log(doc)
                    js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 

                    
                    
                })
                
                //console.log(js)
                dispatch({type: UsersActions.usersActionClubmembersChange, data: {type: 'user', data: js}})
            },
            (error) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )
        //console.log('tohle pushuju', unsubscribe)
        
            
        return () => {
            unsubscribeClubregistered()
            unsubscribeLinked()
        }
             
        
        
    }
}
export const getUserData = (uid: string, callback: (data: any) => void): AppThunk<void> => {
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const state: RootStore = getState()
        if (uid && uid != "" && !state.users.usersData[uid]) {
            const rootRef = getFirestore()
            const user = rootRef.collection('userspublic').doc(uid)
            user.get()
            .then((response) => {
                if (response.exists) {
                    let data = response.data()
                    dispatch({type: UsersActions.usersActionUserDataFetch, data: {...standardizeDates(data), type: "user"}})
                    callback(data)
                } else {
                    dispatch({type: 'USER_DATA_FETCH_ERROR', data: {uid: uid, first_name: "error", family_name: "error"}})
                }
                
            })
            .catch(
            (e) => {
                console.log(e)
                dispatch({type: 'USER_DATA_FETCH_ERROR', data: {uid: uid, first_name: "error", family_name: "error"}})
            }
            )

        } else {
            dispatch({type: 'USER_DATA_FETCH_ERROR', data: {uid: uid, first_name: "error", family_name: "error"}})
        }
        


    }
}


export const loadProfileData = (uid: string, club: string): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        let currUser = getCurrentUserWithRoles(getState() as RootStore)  as any
        let wrap = {
            isLoaded: false,
            error: {},
            data: {}
        }
        dispatch({type: ContactsActions.contactsLoadProfileData, data: wrap})

        const rootRef = getFirestore()

        if (uid == currUser.uid) {
            let userpublic = await rootRef.collection('userspublic').doc(uid).get()
            let userprivate = await rootRef.collection('usersprivate').doc(uid).get()

            var userDataObj = {...standardizeDates(userpublic.data()),...standardizeDates(userprivate.data())}
            dispatch({type: ContactsActions.contactsLoadProfileData, data: {...wrap, data: userDataObj, isLoaded:true}})
        } else {
            dispatch({type: ContactsActions.contactsLoadProfileData, data: {...wrap, error: {code: 'NOT_USER', message: "Cannot edit foreign data"}}})
        }
        

        //dispatch({type: 'LOAD_PROFILE_FOR_EDIT', data: {...wrap, body: userDataObj, error: e}})
        
       
        rootRef.collection('clubs').doc(club).collection('roles').doc(uid).get()
        .then((roles) => {
            //console.log('Ted uz by to neco vracet melo', roles.data(), club, uid)
            let rolesData = standardizeDates(roles.data()?roles.data():{}) 
            
            dispatch({type: ContactsActions.contactsLoadRolesData, data: {...wrap, data: rolesData, isLoaded:true}})
        })
        .catch((e) => {
            dispatch({type: ContactsActions.contactsLoadRolesData, data: {...wrap, error: e}})
        })
    
        rootRef.collection('clubs').doc(club).collection('subscriptions').where('uid','==',uid).get()
        .then((subscriptions) => {
            let subsList = []
            subscriptions.forEach((doc) => {
                subsList.push(standardizeDates(doc.data()))
                
            })
            
            dispatch({type: ContactsActions.contactsLoadMembershipData, data: {...wrap, data: subsList, isLoaded:true}})
        })
        .catch((e) => {
            dispatch({type: ContactsActions.contactsLoadMembershipData, data: {...wrap, error: e}})
        })
       

        
    }
}

export const getLinkedProfileToState = (ownerUid: string, linkedUid: string, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const wrap = {
            isLoaded: false,
            error: {},
            data: {}
        }
        dispatch({type: UsersActions.usersActionLinkedUserToState, data: {...wrap, isLoaded:false}})
        if (ownerUid) {
            if (linkedUid) {
                const rootRef = getFirestore()
                let userpublic = await rootRef.collection('userspublic').doc(linkedUid).get()
                let userprivate = await rootRef.collection('usersprivate').doc(linkedUid).get()

                var userDataObj: user = {...standardizeDates(userpublic.data()),...standardizeDates(userprivate.data())}
    
                
                if (userDataObj.linkedFlag == true && userDataObj.linkedTo && userDataObj.linkedTo == ownerUid) {
                    dispatch({type: UsersActions.usersActionLinkedUserToState, data: {...wrap, data: userDataObj, isLoaded:true, isSuccess: true}})
                } else {
                    dispatch({type: UsersActions.usersActionLinkedUserToState, data: {...wrap, error: {code: "NOT_CORRECT_OWNER", message: "User does not belong to given user"}, isLoaded:true, isSuccess: false}})
                    callback(false, {code: "NOT_CORRECT_OWNER", message: "User does not belong to given user"})
                }
                
            } else {
                let defaultData: user = {
                    uid: firestoreAutoId(),
                    first_name: "",
                    family_name: "",
                    address: "",
                    city: "",
                    email: "",
                    phone: "",
                    clubs: [],
                    linkedFlag: true,
                    linkedTo: ownerUid,
                    dateOfBirth: new Date,
                }
                dispatch({type: UsersActions.usersActionLinkedUserToState, data: {...wrap, data: defaultData, isLoaded:true}})
            }
        } else {
            callback(false, {
                code: "NO_MASTER_UID_GIVEN",
                message: "There is no master uid for linked profiles"
            })
            dispatch({type: UsersActions.usersActionLinkedUserToState, data: {...wrap, error: {
                code: "NO_MASTER_UID_GIVEN",
                message: "There is no master uid for linked profiles"
            }, isLoaded:true, isSuccess: false}})
        }

    }
}

export const editProfile = (profile: {
    uid: string,
    phone: string,
    first_name: string,
    family_name: string,
    termsAndConditions: boolean,
    address: string,
    city: string,
    linkedFlag?: boolean,
    linkedTo?: string,
    dateOfBirth?: Date,
}, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: ContactsActions.contactsEditProfile, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        const rootRef = getFirestore()
        const uid = profile.uid

        let publicprofile = {
            uid: profile.uid,
            phone: profile.phone,
            first_name: profile.first_name,
            family_name: profile.family_name,
            termsAndConditions: profile.termsAndConditions || false,
            linkedFlag: profile.linkedFlag || false,
            linkedTo: profile.linkedTo || "",
            first_name_dia: removeDicretics(profile.first_name),
            family_name_dia: removeDicretics(profile.family_name),
            
        }
        if (profile.dateOfBirth) publicprofile['dateOfBirth'] = profile.dateOfBirth

        let privateprofile = {
            uid: profile.uid,
            address: profile.address,
            city: profile.city,
            linkedFlag: profile.linkedFlag || false,
            linkedTo: profile.linkedTo || "",
        }
        
        //console.log(publicprofile,privateprofile)

        rootRef.runTransaction(async (t) => {
            
            if (uid) {
                let userRefPublic = rootRef.collection('userspublic').doc(uid)
                
                let userRefPrivate = rootRef.collection('usersprivate').doc(uid)
                let userRefPublicDoc = await userRefPublic.get()
                let userRefPrivateDoc = await userRefPrivate.get()

                if (userRefPrivateDoc.exists && userRefPublicDoc.exists) {
                    t.update(userRefPublic, publicprofile)
                    t.update(userRefPrivate, privateprofile)
                } else {
                    if (profile.linkedFlag == true) {
                        t.set(userRefPublic, publicprofile)
                        t.set(userRefPrivate, privateprofile)
                    } else {
                        return Promise.reject({code: "USER_DOES_NOT_EXIST", message: "Provided uid does not exist"})
                    }
                }
                
            } else {
                return Promise.reject({code: "NO_UID_GIVEN", message: "No uid given"})
            }
        })
        .then(() => {
            dispatch({type: ContactsActions.contactsEditProfile, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            callback(true, null) 
        })
        .catch((e) => {
            dispatch({type: ContactsActions.contactsEditProfile, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            callback(false, e) 
        })
        
        
    }
}


export const editRoles = (roles: ClubRoles, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const rootRef = getFirestore()
        const uid = roles.uid
        const club = roles.club
        

        rootRef.runTransaction(async (t) => {
            if (uid) {
                let rolesRef = rootRef.collection('clubs').doc(club).collection('roles').doc(uid)

                t.set(rolesRef, roles)
            }
        })
        .then(() => {
            callback(true, roles) 
        })
        .catch((e) => {
            callback(false, e) 
        })
        
        
    }
}

export const addSubscription = (subscription: SubscriptionYear, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const rootRef = getFirestore()
        const uid = subscription.uid
        const club = subscription.club

        const subscriptionColl = rootRef.collection('clubs').doc(club).collection('subscriptions')

        rootRef.runTransaction(async (t) => {
            if (uid) {
                var overlap: boolean = undefined
                let subsQuery = await subscriptionColl.where('uid','==',uid).where('membershipEndDate','>',subscription.membershipStartDate).get()
                subsQuery.forEach((doc) => {
                    
                    let docStd = standardizeDates(doc.data())
                    
                    overlap = !overlap ? false : dateRangeOverlaps(
                        docStd.membershipStartDate,
                        docStd.membershipEndDate,
                        subscription.membershipStartDate,
                        subscription.membershipEndDate
                        )
                })
                if (overlap == true) {
                    return Promise.reject('Subscription overlaps')
                } else {
                    let subsRef = subscriptionColl.doc()
                    let subsId = await subsRef.id
                    t.set(subsRef, {...subscription, firebaseId: subsId})
                }
                /**/
            }
        })
        .then(() => {
            callback(true, null) 
        })
        .catch((e) => {
            callback(false, e) 
        })
        
        
    }
}

export const subscriptionsTourBasedSubscribe = (club: string, tour: number): AppThunk<() => void> => {
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        
        dispatch({type: ContactsActions.contactsSubscriptionsChanges, data: {floating: getState().contacts.subscriptions.floating, tour: {}}})
        
        const rootRef = getFirestore()
        //console.log('tour kurva do pice', tour)
        let entityFloatRef = rootRef.collection('clubs').doc(club)
        .collection('subscriptions')
        //.where('membershipType', 'in', [MembershipTypes.tourSubscription])
        .where('tour', '>=', tour)
        .orderBy('tour')
        var unsubscribe = entityFloatRef.onSnapshot(
            (querySnapshot) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                let state: RootStore = getState()

                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log(doc)
                    
                    
                    js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 
                    if (doc.type == "added" || doc.type == "modified") {
                        if (!state.users.usersData[js[doc.type][doc.doc.id].uid]) {
                            dispatch(getUserData(js[doc.type][doc.doc.id].uid, () => {}))
                        }
                    }
                    
                })
                
                let currentData = {
                    ...state.contacts.subscriptions,  
                    tour: {...state.contacts.subscriptions.tour, ...js.added, ...js.modified}
                }
                Object.keys(js.removed).forEach((key) => {
                    delete currentData.tour[key]
                })
                
                //
                
                //console.log('Test subsc', currentData)
                dispatch({type: ContactsActions.contactsSubscriptionsChanges, data: currentData})
            },
            (error) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )
            
        
            
        return unsubscribe
             
        
        
    }
}

export const subscriptionsSubscribe = (club: string, date: Date): AppThunk<() => void> => {
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        
        dispatch({type: ContactsActions.contactsSubscriptionsChanges, data: {tour: getState().contacts.subscriptions.tour, floating: {}}})
        
        const rootRef = getFirestore()
        //console.log('date', new Date(date))
        let entityFloatRef = rootRef.collection('clubs').doc(club)
        .collection('subscriptions')
        //.where('membershipType', 'in', ['year',MembershipTypes.yearSubscription])
        .where('membershipEndDate', '>=', date)
        .orderBy('membershipEndDate')
        var unsubscribe = entityFloatRef.onSnapshot(
            (querySnapshot) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                let state: RootStore = getState()
                
                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log(doc)
                    
                    
                    js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 
                    if (doc.type == "added" || doc.type == "modified") {
                        if (!state.users.usersData[js[doc.type][doc.doc.id].uid]) {
                            dispatch(getUserData(js[doc.type][doc.doc.id].uid, () => {}))
                        }
                    }
                    
                })
                //console.log('Pred', state.contacts.subscriptions)
                let currentData = {
                    ...state.contacts.subscriptions,  
                    floating: {...state.contacts.subscriptions.floating, ...js.added, ...js.modified}
                }
                Object.keys(js.removed).forEach((key) => {
                    delete currentData.floating[key]
                })
                //console.log('Test subsc', currentData)
                dispatch({type: ContactsActions.contactsSubscriptionsChanges, data: currentData})
            },
            (error) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )
            
        
            
        return unsubscribe
             
        
        
    }
}


export const mySubscriptionsSubscribe = (uid: string): AppThunk<() => void> => {
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        
        dispatch({type: UsersActions.usersActionUserSubsChange, data: {uid: uid, data: {}}})
        const rootRef = getFirestore()
        //console.log('wishsubscribe', uids)
        let entityRef = rootRef.collectionGroup('subscriptions')
        .where('uid', '==', uid)
        //.where('membershipEndDate', '>=', new Date)
        //.orderBy('membershipEndDate', 'desc')
        var unsubscribe = entityRef.onSnapshot(
            (querySnapshot) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log(doc)
                    
                    
                    js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 
                    
                })
                
                //console.log(js)
                dispatch({type: UsersActions.usersActionUserSubsChange, data: {uid: uid, data: js}} as AllUserActions)
            },
            (error) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )
            
        
        //console.log('tohle pushuju', unsubscribe)
        
            
        return unsubscribe
             
        
        
    }
}



export const queryGameResults = (uid: string, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: UsersActions.usersActionQueryUserResults, data: {uid: uid, isLoaded: false, isSuccess: true, edited: false}})
        const rootRef = getFirebase().firestore()
        //const uid = profile.uid
        //console.log(uid)
        //const resultRef = rootRef.collection('results').where(`gamePlayers.${uid}.side`,'in',['away','home']).orderBy('dateFrom','desc').limit(10)
        
        const resultRef = rootRef.collection('results').where(`players`,'array-contains',uid).orderBy('dateFrom','desc').limit(10)

        resultRef.get()
        .then((resultReturned) => {
            
            var games = []
            //console.log(resultReturned)
            resultReturned.forEach((doc) => {
                let docData = standardizeDates(doc.data()) 
                //console.log(docData)
                games.push(docData)
            })
            dispatch({type: UsersActions.usersActionQueryUserResults, data: {uid: uid, isLoaded: true, isSuccess: true, data: {results: games}}} as AllUserActions)
            callback(true, null) 
            /* let data = standardizeDates(resultReturned.data())
            dispatch({type: 'RESERVATION_RESULT_TO_STATE', data: {isLoaded: true, isSuccess: true, edited: false, ...data}})

            callback(true, null)  */
        })
        .catch((e) => {
            console.log(e)
            dispatch({type: UsersActions.usersActionQueryUserResults, data: {uid: uid, isLoaded: true, isSuccess: false, data: {results: []}, error: {message: e.message}}} as AllUserActions)
            callback(false, e) 
        })
        
        
    }
}
 