import React, { useState } from "react";
import { Image, Pressable, View } from "react-native";
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';

type Props = {
    editFlag: boolean,
    dispatchActionForChanges: (callback) => void,
    photo: string,
    setCapturedBlob: (state) => void,
    selectedBlob: Blob
}



const ImageComponent: React.FC<Props> = (props) => {

    

    const dispatchChange = (callback: (params) => void) => {
        props.dispatchActionForChanges(
            callback
        )
    }

    const selectFile = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [1, 1],
          quality: 1,
        });
    
        //console.log(result);
    
        let height = 300
        let width = (height/result.assets[0].height)*result.assets[0].width
        
        const resizedPhoto = await ImageManipulator.manipulateAsync(
            result.assets[0].uri,
            [{resize: {width: width, height: height}}],
            { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
        )
        const response = await fetch(resizedPhoto.uri)
        
        const blob = await response.blob()
        //console.log('Blob' + JSON.stringify(response.url))
        
        if (!result.canceled) {
            dispatchChange(() => { return {newsPhoto: resizedPhoto.uri} })
            
        }
        props.setCapturedBlob(blob)
    
      };


    return (
        <View style={{width: "100%", justifyContent: "center", alignItems: "center"}}>
                
        {props.editFlag == true?
        <Pressable
        //style={{: "auto"}}
        onPress={() => selectFile()}
        >
            <Image // size="large"
            style={{width: 150, height: 150, padding: 10}}
            //icon={{name: 'user', type: 'font-awesome'}}
            //onPress={() => console.log("Works!")}
            source={props.photo ? {uri: props.photo} : require("../../../../assets/images/na_image_ico.jpg")}
            //activeOpacity={0.7}
            //containerStyle={{flex: 1, marginLeft: 2, marginTop: 2, alignSelf:"center"}}
            />
        </Pressable>:
        <Image 
        style= {{width: '100%',height: undefined,
        aspectRatio: 1,
        alignSelf: 'center',}} 
        //icon={{name: 'user', type: 'font-awesome'}}
        //onPress={() => console.log("Works!")}
        source={props.photo ? {uri: props.photo} : require("../../../../assets/images/na_image_ico.jpg")}
        
        />
        }
    </View>
    )
}

export default ImageComponent