import { Route } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { FlatList, Switch, Text, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { AnyMarket, MarketTypes } from '../../../functions/src/interfaces';
import FilterTag from '../../components/FilterTag';
import FlexedMessage from '../../components/FlexedMessge';
import FormEntry from '../../components/FormEntryNew';
import IconButton from '../../components/IconButton';
import Overlay from '../../components/Overlay';
import RightTopBar from '../../components/RightTopBar';
import WebWrapper from '../../components/WebWrapper';
import i18n, { tokens } from '../../i18n';
import { subscribeForMarket } from '../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { UserOrClub } from '../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../store/selectors';
import { marketFilters, normalize, windowWidth } from '../../utilities/constants';
import { globalStyleSheet, secondColor } from '../../utilities/globalStyles';
import { MarketFilters } from '../../utilities/interfaces';
import MarketEntry from './Components/MarketEntry';
import styles from './styles';
import FloatingAction from '../../components/FloatingAction';

type Props = {
    route: Route<'Reservation', {firebaseId?: string, club?: string}>
}

type OverlayProps = {
    isModalVisible: boolean,
    toggleModal: () => void,
    filters: MarketFilters,
    handler: (state) => void,
}

const RenderModal: React.FC<OverlayProps> = (props) => {
    const {isModalVisible, toggleModal, filters} = props
    return (
      <Overlay isVisible={isModalVisible} onBackdropPress={toggleModal}>
          <View
          style={{
              //flex: 1,
              width: windowWidth*0.8,
              
          }}>
              <FormEntry 
              caption={marketFilters.opengame}
              editable={true}
              >
                  <Switch value={filters.opengame} onValueChange={(value) => props.handler(prevState => {
                        return {...prevState, opengame: value}
                      })}/>
              </FormEntry>
              <FormEntry 
              caption={marketFilters.myRequests}
              editable={true}
              >
                  <Switch value={filters.myRequests} onValueChange={(value) => props.handler(prevState => {
                        return {...prevState, myRequests: value}
                      })}/>
              </FormEntry>
              <FormEntry 
              caption={marketFilters.longreservation}
              editable={true}
              >
                  <Switch value={filters.longreservation} onValueChange={(value) => props.handler(prevState => {
                        return {...prevState, longreservation: value}
                      })}/>
              </FormEntry>
              <FormEntry 
              caption={marketFilters.allClubs}
              editable={true}
              >
                  <Switch value={filters.allClubs} onValueChange={(value) => props.handler(prevState => {
                        return {...prevState, allClubs: value}
                      })}/>
              </FormEntry>
          </View>
      </Overlay>
    )
}


function MarketScreen(props: Props) {
    const allActions = [
        {
          text: i18n.t(tokens.app.constants.markettypes.opengame),
          //icon: require("./images/ic_accessibility_white.png"),
          name: "bt_opengame",
          position: 1,
          color: secondColor,
          club: false,
          user: true,
        },
        {
            text: i18n.t(tokens.app.constants.markettypes.longreservation),
            //icon: require("./images/ic_videocam_white.png"),
            name: "bt_longreservation",
            position: 2,
            color: secondColor,
            club: true,
            user: true,
        },
        {
            text: i18n.t(tokens.app.constants.markettypes.subscription),
            //icon: require("./images/ic_videocam_white.png"),
            name: "bt_subscription",
            position: 3,
            color: secondColor,
            club: true,
            user: true,
        },
        {
            text: i18n.t(tokens.app.constants.markettypes.credit),
            //icon: require("./images/ic_videocam_white.png"),
            name: "bt_credit",
            position: 3,
            color: secondColor,
            club: true,
            user: true,
        },
        
    ];
    
    const dispatch = useAppDispatch()
    const club = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual) //props.userData.selectedClub ? props.userData.selectedClub : props.userData.club
    const currentUser: UserOrClub = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    const users = useAppSelector((state: RootStore) => state.users.usersData, shallowEqual)
    const market = useAppSelector((state: RootStore) => state.market.market, shallowEqual)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const navigation = useAppNavigation()
    //const translation = useRef(new Animated.Value(0)).current
    const [actions, setActions] = useState([])
    console.log('Rerendering market')
    useEffect(() => {
        if (currentUser.type == "clubs") {
            setActions(allActions.filter(item => item.club == true))
        } else if (currentUser.type == "user") {
            setActions(allActions.filter(item => item.user == true))
        }
        
    }, [currentUser])

    const toggleModal = () => {
        
        setIsModalVisible((prevValue) => {
            
            return !prevValue
        })
    }

    
    

    const [filters, setFilters] = useState<MarketFilters>({
        opengame: true,
        myRequests: true,
        longreservation: true,
        allClubs: false,
    })

    const [marketSubscriptions, setMarketSubscriptions] = useState(() => {
        /*  new Promise((resolve, reject) => {
 
         }) */
     })

    const [marketData,setMarketData] = useState([
        
    ]) 

    
    
    useEffect(() => {
        var unsub = dispatch(subscribeForMarket(filters, () => {}))
        return () => {
            if (unsub) {
                unsub.forEach((unsubFunc) => unsubFunc())
            }
        }
    }, [club, filters])

    const filterMarket = (marketItem: AnyMarket) => {
        //console.log(!marketItem, !marketItem.status, marketItem.status == 'approved')
        //console.log(marketItem.uid, props.auth.uid, filters.myRequests, true)
        if (!marketItem) {
            return false
        } else if (marketItem.status == 'approved') {
            return false
        } else if (marketItem.status == 'deleted') {
            return false
        } else if (currentUser.type == "user" && marketItem.type == MarketTypes.opengame && marketItem.uid != currentUser.uid && filters.myRequests == true) {
            return false
        } else if (currentUser.type == "user" && marketItem.type != MarketTypes.opengame && marketItem.mainUid != currentUser.uid && filters.myRequests == true) {
            return false
        } else if (marketItem.type == 'longreservation' && filters.longreservation == false) {
            return false
        } else if (marketItem.type == 'opengame' && filters.opengame == false) {
            return false
        } else if (marketItem.club != club && filters.allClubs == false) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        let out = []
        let keys = market ? Object.keys(market) : []
        keys.forEach(
            (key) => {
                if (filterMarket(market[key])) {
                    out.push(market[key])
                }
            }
        ) 
        out.sort((a,b) => {
            if (a.dueDate > b.dueDate) {
                return 1
            } else {
                return -1
            }
        })
        setMarketData(out)
        //console.log('sadasdas',)
    }, [market, filters, club])

    

    return (
        
        <View style={styles.container}>
            {/* <RightTopBar title={props.route?.name}/> */}
            <View style={[globalStyleSheet.toolbar,{paddingLeft: 10, paddingRight: 10}]}>
                    <View style={{padding: 3}}><Text>Filter</Text></View>
                    <FlatList
                    horizontal
                    style={{
                        flex: 0.9
                    }}
                    data={
                        Object.keys(filters).map((key) => {
                            return {key: key, value: filters[key], caption: marketFilters[key]}
                        })
                    }
                    renderItem={(item) => <FilterTag 
                        tag={item.item.caption} 
                        value={item.item.value}
                        onPress={() => setFilters(prev => {return {...prev, [item.item.key]: !item.item.value}})}
                        />}
                    />
                    <IconButton 
                    icon='filter-outline'
                    size={normalize(24)}
                    onPress={() => toggleModal() }
                    color={"black"}
                    />
                    
                </View>
            <WebWrapper>
            
            
                {marketData.length > 0?
                <FlatList 
                style={styles.listContainer}
                data={marketData}
                renderItem={({item, index}) => {return (<MarketEntry users={users} item={item} index={index}/>)
                }}
                keyExtractor={(item)=> item.firebaseId}
                />
                :<FlexedMessage message={"Pod vybranými filtry není v tuto chvíli žádná žádost"}/>}
                <FloatingAction
                    actions={actions}
                    onPressItem={name => {
                        navigation.navigate('ManageMarket', {type: name.substring(3), club: club})
                    //console.log(`selected button: ${name}`);
                    }}
                    color={secondColor}
                    buttonSize={70}
                />  
            
            </WebWrapper>
            <RenderModal handler={setFilters} toggleModal={toggleModal} filters={filters} isModalVisible={isModalVisible}/>
        </View> 
        
    )
}


export default MarketScreen
