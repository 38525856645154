import { Route } from '@react-navigation/native';
import React, { useState } from 'react';
import { Platform, Pressable } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Ionicons from '@expo/vector-icons/Ionicons';
import { shallowEqual } from 'react-redux';
import { user, UserRoles } from '../../../functions/src/interfaces';
import WebWrapper from '../../components/WebWrapper';
import { RootState, useAppNavigation, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { UserOrClub } from '../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../store/selectors';
import ClubProfile from './Components/ClubProfile';
import UserProfile from './Components/UserProfile';
//import Modal from 'modal-react-native-web';

export type ProfileNavigationParams = {uid: string}
//Modal.setAppElement('#root');
type Props = {
    route: Route<'ProfileScreen', ProfileNavigationParams>
}

function ProfileScreen(props: Props) {
    //console.log('Profile page props', props)
    const uid = props.route.params.uid
    //const mode = uid == props.auth.uid ? 'user' : 'contact'
    const [userData, setUserData] = useState<user>({uid: "XNA", first_name: "Prázdno", family_name: "", address: "", email: "", clubs: []})
    //const userData = mode == 'user' ? {...props.currentUserPublicData, ...props.currentUserPrivateData} :  props.profileUserPublicData
    
    const navigation = useAppNavigation()
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    const type: "user" | "clubs" = useAppSelector((state: RootStore) => state.users.usersData?.[uid]?.type, shallowEqual)

    
    
    /* useEffect(() => {
      if (user) {
        setUserData(user)
      } else if (uid && uid != "") {
        dispatch(getUserData(uid, () => {}))
      }
    }, [uid, user, currentUser]) */
    //console.log(userData)
    //const userPhoto = userData && userData.photo != '' ? {uri: userData.photo} : require("../../../assets/images/user-512.png")
    //const contactMe = mode == 'contact' && contactsMe ? contactsMe.find(item=>item.uid==uid) : null
    //const amTrusted = mode == 'user' || (contactMe && contactMe.trustedFlag == 1) ? 1 : 0
    
   
    
    
    
    React.useLayoutEffect(() => {
        if (uid == currentUser.uid) {
            navigation.setOptions({
          headerRight: () => (
            <Pressable 
            style={{paddingRight: 5}}
            onPress={() => navigation.navigate("Camera", {uid: uid})}>
                <Ionicons 
                    name="camera-outline"
                    color={"white"}
                    size={30}
                    />
            </Pressable>
          ),
        })
    };
      }, [navigation]);
    //console.log('Profile page mode', mode, amTrusted, contactMe, contactsMe.find(item=>item.uid==uid), uid)
    
    
   
    
    //const translation = useRef(new Animated.Value(0)).current

    

    


    //console.log(contactMe, amTrusted)
    return (
        <KeyboardAwareScrollView  
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        //contentContainerStyle={{ flexGrow: 1, padding: 10 }}
        //contentContainerStyle={{flex: 1, alignItems: "center"}}
        //style={{flex: 1}}
        
        >
            <WebWrapper>
            {type == "user"?
            <UserProfile 
            type={currentUser.uid == uid?"user":"contact"}
            currentUser={currentUser}
            uid={uid}
            />
            :null}
            {type == "clubs"?
            <ClubProfile 
            type={currentUser.type == "clubs" && currentUser.firebaseId == uid?"club":"contact"}
            currentUser={currentUser}
            clubId={uid}
            />
            :null}          
        </WebWrapper>
    </KeyboardAwareScrollView>)
}


export default ProfileScreen