import Constants from "expo-constants";
import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { shallowEqual } from "react-redux";
import { Club, PaymentData, PaymentModes, PaymentTransactionStatuses } from "../../../../functions/src/interfaces";
import BigButton from "../../../components/BigButton";
import FormEntry from "../../../components/FormEntryNew";
import i18n, { tokens } from "../../../i18n";
import { createPaymentIntent, handleCaptureActionPayU, handleCheckoutAction, handleCheckoutAll, handlePayUCheckout, handleRefundActionPayU } from "../../../store/actions/actionsCheckout";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../../store/hooks";
import { StateLoader } from "../../../store/reducers/checkoutReducer";
import { RootStore } from "../../../store/reducers/rootReducer";
import { getCurrentUserWithRoles } from "../../../store/selectors";

//ADD localhost address of your server
const API_URL = Constants.expoConfig.extra.functionsUrlBase

type Props = {
    paymentData: StateLoader<PaymentData & {clientSecret?:string}>
    reinitialize?: (method: PaymentModes) => Promise<string>,
    isAdmin: boolean,
    redirect: string,
}



const PaymentPayU: React.FC<Props> = props => {
    //const {club, checkoutId, marketId} = props
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    //const selectedClub = useSelector(state => state.auth.selectedClub, shallowEqual)


    const detailStatusesList = [PaymentTransactionStatuses.succeeded,PaymentTransactionStatuses.refunded,PaymentTransactionStatuses.cancelled,PaymentTransactionStatuses.amount_capturable_updated]

    return (
        <View style={styles.container}>
            <>
            {detailStatusesList.includes(props.paymentData?.data?.status)?
            <FormEntry
            caption={i18n.t(tokens.app.screens.payment.paymentCapturableValue)}
            editable={false}
            >
                <Text>{props.paymentData?.data?.amountCapturable/100}</Text>
            </FormEntry>:null}
            {detailStatusesList.includes(props.paymentData?.data?.status)?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.payment.paymentPaidValue)}
            editable={false}
            >
                <Text>{props.paymentData?.data?.amountCaptured/100}</Text>
            </FormEntry>:null}
            {detailStatusesList.includes(props.paymentData?.data?.status)?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.payment.paymentRefundedValue)}
            editable={false}
            >
                <Text>{props.paymentData?.data?.amountRefunded/100}</Text>
            </FormEntry>:null}
           
           

            <View style={styles.buttonView}>
            {props.isAdmin && props.paymentData.data.status == PaymentTransactionStatuses.amount_capturable_updated?
            <BigButton 
            buttonText={i18n.t(tokens.app.screens.payment.paymentChargeButton)}
            onPress={() => dispatch(handleCaptureActionPayU(props.paymentData.data.checkoutId, props.paymentData.data.club, () => {}))}
            />:null}
            {props.isAdmin && 
            (props.paymentData.data.status == PaymentTransactionStatuses.amount_capturable_updated ||
            props.paymentData.data.status == PaymentTransactionStatuses.succeeded || 
            props.paymentData.data.status == PaymentTransactionStatuses.capturing 
            )?
            <BigButton 
            buttonText={i18n.t(tokens.app.screens.payment.paymentCancelButton)}
            onPress={() => dispatch(handleRefundActionPayU(props.paymentData.data.checkoutId, props.paymentData.data.club, () => {}))}
            />:null}
            {props.redirect != "" && props.paymentData.data.status == PaymentTransactionStatuses.initiated?
            <BigButton
            buttonText={i18n.t(tokens.app.screens.payment.paymentButtonPay)}
            onPress={() => dispatch(handlePayUCheckout(props.redirect))}
            />:null}
            {props.redirect == "" && props.paymentData.data.status == PaymentTransactionStatuses.initiated?
            <BigButton
            buttonText={i18n.t(tokens.app.screens.payment.paymentReinitButton)}
            onPress={() => props.reinitialize(PaymentModes.paymentModePayU)}
            />
            :null}
            </View>
            </>
        </View>
    );
};
export default PaymentPayU;

const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: "column",
        width: "75%",
        alignItems: "center",
        //justifyContent: "space-evenly",
    },
    buttonView: {
        flexDirection: "row",
        //flex: 1,
        height: 150,
    },
    methodSelectTitle: {
        fontSize: 14,
        letterSpacing: 1.5,
        color: 'black',
        textTransform: 'uppercase'
    },
    container: {
        flex: 1,
        justifyContent: "center",
        margin: 20,
        //alignItems: "center",  
        //width: "100%",
    },
    input: {
        backgroundColor: "#efefefef",

        borderRadius: 8,
        fontSize: 20,
        height: 50,
        padding: 10,
    },
    card: {
        backgroundColor: "#efefefef",
    },
    cardContainer: {
        height: 50,
        marginVertical: 30,
    },
    buyButton: {
        backgroundColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
    },
    textButton: {
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        color: '#007DFF'
    },
    selectButton: {
        borderColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        borderWidth: 2,
    },
    boldText: {
        fontSize: 17,
        fontWeight: '700'
    }
});
