import React from "react";
import MyClubsComponent from "../../ManageMyClubsScreen/Components/MyClubsComponent";
import { View } from "react-native";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import BigButton from "../../../components/BigButton";

type Props = {
    detailType: "clubs",
    userData: {[key: string]: {value: any, validity: boolean, errorMessage: string}},
    dispatchChange: (data: any) => void,
    confirmAction: () => void
}

function RegisterDetailComponent(props: Props) {

    return (
    <>
    {props.detailType == "clubs"?
    <MyClubsComponent
    selectedClubs={props.userData[props.detailType].value}
    addSelectedAction={(club: string) => props.dispatchChange(() => {return {[props.detailType]: [...props.userData[props.detailType].value, club]}})}
    removeSelectedAction={(club: string) => props.dispatchChange(() => {return {[props.detailType]: [...props.userData[props.detailType].value.filter(item => item != club)]}})}
    />:null}

    <View
    style={globalStyleSheet.buttonContainer}
    >
        <BigButton
        buttonText="OK"
        onPress={props.confirmAction}
        />
    </View>
    </>
    )
     
    
}

export default RegisterDetailComponent