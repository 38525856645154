import View from '@expo/html-elements/build/primitives/View';
import React, { useState } from 'react';
import { StyleSheet, Text, TextInput } from 'react-native';
import { BlockedTime, UserRoles } from '../../../../functions/src/interfaces';
import { accOrChangeBlockedTime, editReservation, removeBlockedTime } from '../../../store/actions/actionsReservationsNew';
import { useAppDispatch, useAppNavigation } from '../../../store/hooks';
import { StateLoader } from '../../../utilities/interfaces';
import { isAllowedToEdit } from '../ReservationValidations';
import Dialog from './Dialog';
import ReservationDetail from './ReservationDetail';
import ReservationTime from '../../../components/ReservationTime';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import BigButton from '../../../components/BigButton';
import FormEntry from '../../../components/FormEntryNew';
import { isAdmin } from '../../../utilities/funcs';
import { globalStyleSheet } from '../../../utilities/globalStyles';
import i18n, { tokens } from '../../../i18n';

type Props = {
    //clubData: Club,
    reservationData: StateLoader<BlockedTime>,
    currentUser: UserOrClub<UserRoles>
}

function BlockedTimeFull(props: Props):JSX.Element {
    
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()


    const [isOverlayVisible, setIsOverlayVisible] = useState({visible: false, type: "longreservation", data: {}})
    

    const isElapsed = (dateTo: Date) => {
        let currDt = new Date
        return currDt > dateTo
    }
     
    //const [validators, setValidators] = useState({})
    
    //console.log('Update rezervace: ',reservationData)
    //const currentUser = useAppSelector(getCurrentUserWithRoles, )
    //const seasons = useAppSelector(state => state.auth.seasons, shallowEqual)

    /* useEffect(() => {
        if (seasons && reservationData.data?.season && seasons[reservationData.data.season]) {
            setValidators(validations(seasons[reservationData.data.season]))
        }
        
    }, [seasons])
 */
    

    

    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        dispatch(editReservation(changes))
    }

    return (

        <View style={styles.container}>
            
            <ReservationDetail 
            reservationData={props.reservationData.data}
            disabled={true}
            dispatchActionForChanges={dispatchChanges}
            />
            <ReservationTime 
            dispatchActionForChanges={dispatchChanges}
            disabled={!isAllowedToEdit(props.currentUser, props.reservationData.data.mainUid, props.reservationData.data.status, props.reservationData.data.club)}
            reservationTimeData={
                props.reservationData.data
            }
            validations={
                {
                    dateFrom: {validity: props.reservationData.data.dateFrom < props.reservationData.data.dateTo, error: "end < start"},
                    dateTo: {validity: props.reservationData.data.dateFrom < props.reservationData.data.dateTo, error: "end < start"},
                    timeFrom: {validity: props.reservationData.data.timeFrom < props.reservationData.data.timeTo, error: "end < start"},
                    timeTo: {validity: props.reservationData.data.timeFrom < props.reservationData.data.timeTo, error: "end < start"},
                    court: {validity: true, error: "Xna"},
                }
            }
            />
            <FormEntry
            caption={'Popis'}
            editable={isAdmin(props.currentUser, props.reservationData.data.club)}
            >
                {
                    isAdmin(props.currentUser, props.reservationData.data.club)?
                    <TextInput
                    style={globalStyleSheet.inputOk}
                    value={props.reservationData.data.text}
                    onChangeText={(text) => dispatch(editReservation({text: text}))}
                    />:
                    <Text
                    style={globalStyleSheet.inputOk}
                    >
                        {props.reservationData.data.text}
                    </Text>
                }
            </FormEntry>
            <View>
                <BigButton
                buttonText={i18n.t(tokens.app.screens.longreservation.addButton)}
                onPress={() => dispatch(accOrChangeBlockedTime())}
                />
                <BigButton
                buttonText={i18n.t(tokens.app.screens.longreservation.removeButton)}
                onPress={async () => {
                    let result = await dispatch(removeBlockedTime())
                    if (result == true) {
                        navigation.popToTop()
                    }
                }}
                />
            </View>
        </View>
               
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
})

export default BlockedTimeFull