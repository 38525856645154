import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Alert, Platform, StyleSheet, Text, Pressable, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { Market, MarketLongreservation, MarketReservation, MarketStatuses, UserRoles } from '../../../../functions/src/interfaces';
import ActionOverlay from '../../../components/ActionOverlay';
import BigButton from '../../../components/BigButton';
import i18n, { tokens } from '../../../i18n';
import { addLongreservation, marketApprovalAction } from '../../../store/actions/actionsMarketNew';
import { addReservation } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/reservationReducer';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import { normalize } from '../../../utilities/constants';
import { showToast } from '../../../utilities/toastcontroller';
import { CheckoutModes } from '../../CheckoutScreen/Components/types';
import Dialog from './Dialog';


type Props = {
    isChanged: boolean,
    firebaseId: string,
    reservationData: StateLoader<MarketLongreservation<Market>>,
    isAllowedToEdit: boolean,
    isAllowedToApprove: boolean,
    currentUser: UserOrClub<UserRoles>,
    setMarketId: (newVal: string) => void,
    setMarketButtonIndex: (index: number) => void,
}

function ButtonComponent(props: Props):JSX.Element {

    //const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const [isOverlayVisible, setIsOverlayVisible] = useState({visible: false, type: "longreservation", data: {}})
   
    //const [isActionPressed,setIsActionPressed] = useState(false)
    //console.log('VOalm', props)
    const addReservationAction = () => {
      dispatch(addLongreservation())
      .then((result) => {
        if (!props.reservationData.data.firebaseId && result.data.marketId && result.data.marketId != "") {
          props.setMarketId(result.data.marketId)
        }
        if (result.action == 'checkout') {
          //navigation.navigate('CheckoutScreen', {checkoutId: result.data.checkoutId, clubId: props.reservationData.data.club, mode: CheckoutModes.requiresPayment})
          props.setMarketButtonIndex(1)
        }
      })
      .catch((e) => {

      })
    }
    const removeReservationAction = () => {
      setIsOverlayVisible({visible: true, type: "longreservation", data: {}})
    }

    const addToCalendarAction = () => {}

    const marketApproval = () => {
      dispatch(marketApprovalAction(props.reservationData.data.club, props.firebaseId, 'approve'))
      .then((res) => {
        if (res) {
          showToast('Schvaleno')
        }
      })
    }

    return (<View
      style={styles.buttonContainer}>
        {/* <ActionOverlay
        onBackdropPress={()=> {}}
        fullScreen={true}
        isVisible={props.reservationData.isActionPressed}
        /> */}
        <Dialog
        isOverlayVisible={isOverlayVisible.visible}
        setIsOverlayVisible={setIsOverlayVisible}
        reservationData={props.reservationData}
        />
          {[MarketStatuses.new, MarketStatuses.open, MarketStatuses.blocked, MarketStatuses.approved].includes(props.reservationData.data.status) && (props.reservationData.isChanged == true || !props.reservationData.data.firebaseId) && (props.isAllowedToEdit == true)?
          <BigButton
          onPress={addReservationAction}
          //disabled={true}
          buttonText={i18n.t(tokens.app.screens.manageReservation.reservationSaveButton)}
          />
          :null}
          {props.reservationData.data.status == MarketStatuses.open && props.reservationData.data.firebaseId && (props.isAllowedToApprove == true)?
          <BigButton
          onPress={marketApproval}
          //disabled={true}
          buttonText={i18n.t(tokens.app.screens.manageReservation.reservationApproveButton)}
          />
          :null}
          {[MarketStatuses.open, MarketStatuses.blocked, MarketStatuses.approved].includes(props.reservationData.data.status) && (props.reservationData.data.firebaseId) && (props.isAllowedToEdit == true)? 
          <BigButton 
          onPress={removeReservationAction}
          buttonText={i18n.t(tokens.app.screens.opengame.removeButton)}
          />
          :null}
          
          </View>
    )
}

const styles = StyleSheet.create({
    
    textStyle: {
      
        textAlign: "right", 
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',
        
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
});

export default ButtonComponent
