import React from "react";
import FormEntryNoCaption from "../../../components/FormEntryNoCaption";
import { Platform, Text } from "react-native";
import { globalStyleSheet, mainFontBold } from "../../../utilities/globalStyles";
import i18n, { tokens } from "../../../i18n";
import * as Linking from 'expo-linking'
import * as Constants from 'expo-constants'
type Props = {
    url: string
}
function VOPComponent(props: Props) {

    const privacyUrl = props.url
    console.log(privacyUrl)
    const handleClick = (url:string) => {
        if(Platform.OS == 'web'){
            window.open(url, '_blank');
        } else {
            Linking.canOpenURL(url).then(supported => {
                if (supported) {
                    Linking.openURL(url);
                } else {
                    console.log("Don't know how to open URI: " + url);
                }
            })
        };
    };
    return (
        <FormEntryNoCaption
        editable={false}
        >
            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.messageText}>{i18n.t(tokens.app.screens.checkout.checkoutVOP)}<Text onPress={() => handleClick(privacyUrl)} style={{fontFamily: mainFontBold, color: "blue"}}>{' URL'}</Text></Text>
        </FormEntryNoCaption>
    )
}

export default VOPComponent