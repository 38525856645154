import { Route } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Platform, StyleSheet, Text, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { shallowEqual } from 'react-redux'
import BigButton from '../../../components/BigButton'
import WebWrapper from '../../../components/WebWrapper'
import { handleCheckoutAll, subscribeForCheckout } from '../../../store/actions/actionsCheckout'
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks'
import { CheckoutActions, StateLoader } from '../../../store/reducers/checkoutReducer'
import { normalize } from '../../../utilities/constants'
import { globalStyleSheet, mainFont, mainFontBold } from '../../../utilities/globalStyles'
//import { checkoutData } from '../../utilities/interfaces'

import { AnyMarket, CheckoutData, Club, MarketStatuses, MarketTypes, PaymentModes, PaymentStatuses, UserRoles } from '../../../../functions/src/interfaces'
import ActionOverlay from '../../../components/ActionOverlay'
import FormHeading from '../../../components/FormHeading'
import { subscribeForRequest } from '../../../store/actions/actionsMarketNew'
import { RootStore } from '../../../store/reducers/rootReducer'

import { isAnyRole } from '../../../../functions/src/funcs'
import FormEntry from '../../../components/FormEntryNew'
import i18n, { tokens } from '../../../i18n'
import { MarketActions } from '../../../store/reducers/marketReducer'
import { getCurrentUserWithRoles } from '../../../store/selectors'
import { CheckoutItems } from './CheckoutItemDetail'
import { RenderModeMessage } from './CheckoutModeMessage'
import { styles } from '../styles'
import { CheckoutModes } from './types'
import { showToast } from '../../../utilities/toastcontroller'
import { UserOrClub } from '../../../store/reducers/usersReducer'
import VOPComponent from './VOPComponent'



type Props = {
    checkoutId: string, 
    clubId: string, 
    mode?: CheckoutModes,
    marketType?: MarketTypes,
}
    





function CheckoutScreenComponent(props: Props):JSX.Element {
    const checkoutId = props.checkoutId
    const mode = props.mode
    const dispatch = useAppDispatch()
    const checkoutData: StateLoader<CheckoutData> = useAppSelector((state:RootStore) => state.checkout.subscribedCheckout, shallowEqual)
    const selectedClub: Club = useAppSelector((state: RootStore) => state.auth.allClubs?.[state.auth?.selectedClub], shallowEqual)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    const subscribedMarket: StateLoader<AnyMarket> & {isPromised: boolean, canBePaid: boolean} = useAppSelector((state: RootStore) => {
        var market = {...state.market.subscribedMarket, isPromised: false, canBePaid: false}
        if (market.data?.type == MarketTypes.longreservation || market.data?.type == MarketTypes.reservation) {
            let season = state.auth.seasons[market.data.season]
            if (season.courtsList?.[market.data.court]?.approval?.isPromised) {
                market.isPromised = true
            }
            let currDate = new Date
            if (currDate >= season.from && currDate <= season.to) {
                market.canBePaid = true
            } else if (market.data.status == MarketStatuses.approved) {
                market.canBePaid = true
            }
        } else {
            market.canBePaid = true
        }
        return market
        
    }, shallowEqual)
    const navigation = useAppNavigation()
    //const paymentPromise = useAppSelector((state: RootStore) => state.auth.seasons[subscribedMarket.data.season].courtsList[subscribedMarket.data.court].approval[])
    

    useEffect(() => {}, [subscribedMarket.data])


    const allowPayment = () => {
        if (subscribedMarket.canBePaid == true) {
            if ((!checkoutData?.data?.captureStatus || checkoutData?.data?.captureStatus != PaymentStatuses.fullyPaidBalance) && (currentUser.uid == checkoutData?.data?.mainUid)) {
                return true
            } else if (currentUser.type == "user" && (isAnyRole(['isAdmin','isGod'], currentUser)) && checkoutData.data.paymentIntentId != "") {
                return true
            } else if (currentUser.type == "clubs") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
        
    }

    const getTrueRoles = Object.entries(selectedClub.paymentPromise?.roles || {}).reduce((prev, [key, value]) => {
        if (value == true) {
            return [...prev, key]
        } else {
            return [...prev]
        }
    },[] as string[])

    const allowPaymentPromise = () => {
        
        if (subscribedMarket.canBePaid == true) {
            if (checkoutData.data.paymentPromise == true) {
                return false
            } else if (currentUser.type == "user" && 
                (currentUser.uid == checkoutData?.data?.mainUid) &&
                (checkoutData.data.captureStatus == PaymentStatuses.unpaidBalance || !checkoutData.data.captureStatus) && 
                (checkoutData.data.status == PaymentStatuses.unpaidBalance || !checkoutData.data.status) &&
                !!(subscribedMarket.isPromised == true) &&
                (selectedClub.paymentPromise?.allowed == true) &&
                (isAnyRole(getTrueRoles, currentUser))
                ) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const handlePaymentPromise = () => {
        dispatch(handleCheckoutAll(checkoutId,checkoutData.data.club,PaymentModes.paymentModePromise, (bool, context) => {
            console.log('Checkout response: ' + String(bool) + ' ' + JSON.stringify(context))
            if (bool == true ) {
                
                navigation.popToTop()
            } else {
                showToast(context.message)
            }
        }))
    }

    useEffect(() => {
        var unsubMarket = null

        console.log('Market params: ' + checkoutId + ' ' + selectedClub.firebaseId)
        if (checkoutData.data && checkoutData.data.marketId && selectedClub) {
            unsubMarket = dispatch(subscribeForRequest(checkoutData.data.marketId, selectedClub.firebaseId, (bool, context) => {}))
            
        }
        return () => {
            if (unsubMarket) {
                
                unsubMarket
                .then(() => console.log('Unsubscribe market'))
                .catch((e) => console.log('Unsubscribe market'))
            }
            dispatch({type: MarketActions.marketSubscribeForIndividualRequest, data: {}})
        }
    }, [checkoutData.data, selectedClub])


    useEffect(() => {
        var unsubCheckout = null
        
        console.log('Checkout params: ' + checkoutId + ' ' + selectedClub.firebaseId)
        if (checkoutId && selectedClub) {
            unsubCheckout = dispatch(subscribeForCheckout(checkoutId, selectedClub.firebaseId, (bool, context) => {}))
            
        }
        return () => {
            if (unsubCheckout) {
                dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isLoaded: false}})
                //console.log('Type: ', unsubCheckout)
                unsubCheckout
                .then(() => console.log('Unsubscribe checkout'))
                .catch((e) => console.log('Unsubscribe checkout'))
                
            }
            dispatch({type: CheckoutActions.checkoutSubscriptionChange, data: {}})
        }
    }, [checkoutId, selectedClub])

    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        //contentContainerStyle={{ flexGrow: 1, padding: 10 }}
        //contentContainerStyle={{flex: 1, alignItems: "center"}}
        //style={{flex: 1}}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >
            <WebWrapper>
                {checkoutData.isLoaded == true?
                <View style={styles.container}>
                    <RenderModeMessage mode={mode}/>
                    <FormHeading
                    heading={i18n.t(tokens.app.screens.manageMarket.manageMarketTitle)}
                    />
                    <FormEntry
                    caption={i18n.t(tokens.app.screens.manageReservation.reservationRequestIdLink)}
                    editable={false}
                    >
                        <Text style={globalStyleSheet.footerLink}
                        onPress={() => navigation.navigate("ManageMarket",{marketId: checkoutData.data.firebaseId})}>
                            {checkoutData.data.firebaseId}
                        </Text>
                    </FormEntry>  

                    <View style={styles.header}>
                        <Text style={styles.headerText}>{i18n.t(tokens.app.screens.checkout.checkoutSummary)}</Text>
                    </View>
                    <CheckoutItems reservations={checkoutData.data.detail} />
                    <View key={"total"} style={styles.priceDetailContainer}>
                        <View>
                            <Text style={styles.itemText}>{i18n.t(tokens.app.screens.checkout.checkoutSubtotal)}</Text>
                        </View>

                        <View>
                            <Text style={styles.itemPriceText}>{String((checkoutData.data.price).toFixed(2)) + 'Kč'}</Text>
                        </View>
                    </View>

                    <View key={"capturable"} style={styles.priceDetailContainer}>
                        <View>
                            <Text style={styles.itemText}>{i18n.t(tokens.app.screens.checkout.checkoutCapturableAmt)}</Text>
                        </View>

                        <View>
                            <Text style={styles.itemPriceText}>{String((checkoutData.data.amountCapturable || 0).toFixed(2)) + 'Kč'}</Text>
                        </View>
                    </View>

                    <View key={"paid"} style={styles.priceDetailContainer}>
                        <View>
                            <Text style={styles.itemText}>{i18n.t(tokens.app.screens.checkout.checkoutPaidAmt)}</Text>
                        </View>

                        <View>
                            <Text style={styles.itemPriceText}>{String(checkoutData.data.paidAmt.toFixed(2)) + 'Kč'}</Text>
                        </View>
                    </View>
                   {/* <Text>{`${currentUser.uid} ${checkoutData?.data?.mainUid}`}</Text> */}
                    <ActionOverlay
                    onBackdropPress={()=> {}}
                    fullScreen={true}
                    isVisible={checkoutData.isActionPressed}
                    />
                    {selectedClub.vopURL && selectedClub.vopURL != ""?
                    <VOPComponent
                    url={selectedClub.vopURL}
                    />:
                    null}
                    <View
                    style={styles.buttonRow}
                    >
                        {allowPayment()?
                        <BigButton 
                        onPress={() => {
                            navigation.navigate('PaymentScreen', {type: "checkout", club: checkoutData.data.club, blockedMethods: subscribedMarket.data.type == MarketTypes.credit ? [PaymentModes.paymentModeCredit] : []})
                        }}
                        buttonText={i18n.t(tokens.app.screens.checkout.checkoutProceedToPayment)}
                        //imageAsset={'event'}
                        />:null}
                        {allowPaymentPromise()?
                        <BigButton 
                        onPress={() => {
                            handlePaymentPromise()
                        }}
                        buttonText={i18n.t(tokens.app.screens.checkout.checkoutPromisePayLater)}
                        //imageAsset={'event'}
                        />:null}
                        <BigButton 
                        onPress={() => {
                            navigation.navigate('PaymentsListScreen', {checkoutId: checkoutId, clubId: checkoutData.data.club})
                        }}
                        buttonText={i18n.t(tokens.app.screens.checkout.checkoutPaymentsList)}
                        //imageAsset={'event'}
                        />
                    </View>
                    
                    
                    
                </View>
                :
                <ActivityIndicator size={"large"} />}
            </WebWrapper>
        </KeyboardAwareScrollView>
    )
}



export default CheckoutScreenComponent

