import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable } from 'react-native'

import { StyleSheet } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';


import { normalize, windowWidth } from '../utilities/constants'
import { mainFont, styles } from '../utilities/globalStyles'

type Props = {
    validity?: boolean,
    children?: JSX.Element,
    icon?: any,
    caption: string,
    error?: string,
    editable: boolean
}

const FormEntry: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)

    const padding = 5
    const [valid, setValid] = useState(true)
    const [edit, setEditable] = useState(true)
    
    useEffect(() => {if (typeof props.validity != 'undefined') setValid(props.validity)}, [props.validity])
    useEffect(() => {if (typeof props.editable != 'undefined') setEditable(props.editable)}, [props.editable])

    const stylelocal = StyleSheet.create({
        entityContainer: {
            flexDirection: 'column',
            //width: "100%",
            
            marginTop: 3,
            minHeight: 40,
            
        },
        entityText: {
            fontFamily: mainFont,
            fontSize: normalize(20),
            flexWrap: "wrap",
            //flexShrink: 1
        },
        entityRow: {
            flexDirection: 'row',
            //width: "100%",
            flex: 1,
            alignItems: "center",
            minHeight: 40,
            justifyContent: "space-between",
            paddingLeft: 15,
            paddingRight: 15,
            borderWidth: props.editable == true ? 1 : 0,
            borderColor: valid == true ? "grey" : "red",
            backgroundColor: "white",
            borderRadius: 5
        },
        
      
        entityCaption: {
            flexDirection: "column",
            flex: 1,
            //flexWrap: "wrap",
            //flex: 45
        },
        entityChildren: {
            //flex: 55,
            //width: "55%",
            flexDirection: "row",
            
        },
        errorStyle: {
            color: "red",
        },
        errorContainer: {
            //flex: 1,
            alignItems: "flex-end",
        },
        childrenDisplay: {
            marginLeft: "auto",
            alignItems: "flex-end"
        },
    })

  
    return (
        
        <View style={stylelocal.entityContainer}>
            <View style={stylelocal.entityRow}>
                <View style={stylelocal.entityCaption}
                >{props.icon?
                    <Ionicons 
                    name={props.icon}
                    color="black"
                    size={normalize(18)}
                    
                    />:null}
                    {props.caption?<Text numberOfLines={2} maxFontSizeMultiplier={1.2} style={[stylelocal.entityText]}
                    >{props.caption}</Text>:null}
                </View>
                    {props.children?<View style={stylelocal.entityChildren}
                    ><View style={stylelocal.childrenDisplay}>{props.children}</View></View>:null}
                </View>
                {!valid?
                <View style={stylelocal.errorContainer}
                >
                    <Text maxFontSizeMultiplier={1.2} style={stylelocal.errorStyle}>{props.error}</Text>
                </View>:null}
        </View>
    )
}





export default FormEntry

