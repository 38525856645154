import { Route } from "@react-navigation/native";
import React, { useEffect } from "react";
import { ActivityIndicator, Text, TextInput, View } from "react-native";
import ActionOverlay from "../../components/ActionOverlay";
import BigButton from "../../components/BigButton";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import FormEntry from "../../components/FormEntryNew";
import FormHeading from "../../components/FormHeading";
import i18n, { tokens } from "../../i18n";
import { editProfile, getLinkedProfileToState } from "../../store/actions/actionsUsers";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks";
import { RootStore } from "../../store/reducers/rootReducer";
import { UsersActions } from "../../store/reducers/usersReducer";
import { globalStyleSheet } from "../../utilities/globalStyles";
import { showToast } from "../../utilities/toastcontroller";

type Props = {
    route: Route<'ManageLinkedUser', {uid: string, linkedUid: string}>
}


function ManageLinkedUser(props: Props) {
    const {uid,linkedUid} = props.route.params
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    const profileData = useAppSelector((state: RootStore) => state.users.manageLinkedUser, (a,b) => JSON.stringify(a) == JSON.stringify(b))
    useEffect(() => {
        dispatch(getLinkedProfileToState(uid, linkedUid, (bool, content) => {
            if (bool == true) {
                showToast("Profile loaded")
            } else {
                showToast(content.message)
                console.log('Error loading profile data' + JSON.stringify(content))
            }
        }))
    }, [uid,linkedUid])

    const dispatchChange = (data: {[key: string]: any}) => {
        dispatch({type: UsersActions.usersActionLinkedUserEdit, data: {isChanged: true, data: {...profileData.data, ...data}}})
    }

    const editProfileAction = (profile, callback) => dispatch(editProfile(profile, callback))

    return (
        
        profileData.isLoaded?
            <View>  
            <ActionOverlay
            onBackdropPress={()=> {}}
            fullScreen={true}
            isVisible={profileData.isActionPressed}
            />
            <FormHeading heading={i18n.t(tokens.app.screens.profile.profileDetail)}/>
            <FormEntry
            caption={i18n.t(tokens.app.screens.profile.profileEmail)}
            editable={false}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.email}
                textAlign={'right'}
                placeholder={i18n.t(tokens.app.screens.profile.profileEmail)}
                onChangeText={(text) => {dispatchChange({ email: text})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profileFirstName)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.first_name}
                textAlign={'right'}
                placeholder={i18n.t(tokens.app.screens.profile.profileFirstName)}
                onChangeText={(text) => {dispatchChange({ first_name: text})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profileFamilyName)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.family_name}
                textAlign={'right'}
                placeholder={i18n.t(tokens.app.screens.profile.profileFamilyName)}
                onChangeText={(text) => {dispatchChange({family_name: text})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profilePhone)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.phone}
                textAlign={'right'}
                placeholder={i18n.t(tokens.app.screens.profile.profilePhone)}
                onChangeText={(text) => {dispatchChange({phone: text})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profileAddress)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.address}
                textAlign={'right'}
                placeholder={i18n.t(tokens.app.screens.profile.profileAddress)}
                onChangeText={(text) => {dispatchChange({address: text})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profileCity)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.city}
                textAlign={'right'}
                placeholder={i18n.t(tokens.app.screens.profile.profileCity)}
                onChangeText={(text) => {dispatchChange({city: text})}}
                />
            </FormEntry>
            <FormEntry 
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.dateOfBirth)}>
                <View style={{alignSelf: "flex-end"}}>
                <CustomDatePicker
                    /*placeholder="Date of birth: "*/ 
                    icon={false}
                    containerStyle={profileData.data.dateOfBirth ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    date={profileData.data.dateOfBirth} 
                    fontStyle={{fontSize: 15, letterSpacing: 3}}
                    setDate={(newDate) => {dispatchChange( {dateOfBirth: newDate } )}}
                    />
                </View>
            </FormEntry> 
            
            <View style={{flexDirection: "row", flex: 1}}>
                <BigButton
                buttonText={i18n.t(tokens.app.screens.profile.profileSaveButton)}
                onPress={() => {
                    editProfileAction({
                        ...profileData.data,
                         
                    },
                    (bool, content) => {
                        if (bool == true) {
                            navigation.goBack()
                        } else {
                            showToast(content.message)
                            console.log('Error saving profile data' + JSON.stringify(content))
                        }
                    })
                }}
                />
                
            </View>
            </View>:<ActivityIndicator size={"large"} />
    )
}

export default ManageLinkedUser