import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable, ScrollView } from 'react-native'

import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { shallowEqual } from 'react-redux';
import { Market, MarketLongreservation, MarketOpengame, MarketReservation, UserOrClub, user } from '../../../../../functions/src/interfaces';
import MessagesCount from '../../../../components/MessagesCount';
import UserAvatar from '../../../../components/UserAvatar';
import i18n from '../../../../i18n';
import { useAppSelector } from '../../../../store/hooks';
import { getCurrentUserWithRoles } from '../../../../store/selectors';
import { marketTypes, weekdays } from '../../../../utilities/constants';
import { monthString, timeFormatter } from '../../../../utilities/funcs';
import { gameTypesColors, mainFont, mainFontBold } from '../../../../utilities/globalStyles';
import MarketEntryButton from '../MarketEntryButton';
import MarketEntryBodyLong from './MarketEntryLongreservation';
import MarketEntryBodyOpen from './MarketEntryOpengame';
import MarketEntryBodyReservation from './MarketEntryReservation';



type Props = {
    data: MarketLongreservation<Market> | MarketOpengame<Market> | MarketReservation<Market>
    users: {[userId: string]: UserOrClub}
}



const MarketEntryBody: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)
    return <View>
        <BodyWrap {...props}/>
        <View style={styles.justifyContainer}>
            <MessagesCount messages={props.data.messages}/>
        </View>
    </View>
    
    
    
}

const BodyWrap: React.FC<Props> = (props) => {
    if (props.data.type == "opengame") {
        let data: MarketOpengame<Market> = {...props.data as any}
        return <MarketEntryBodyOpen data={data} users={props.users}/>
    } else if (props.data.type == "longreservation") {
        let data: MarketLongreservation<Market> = {...props.data as any}
        return <MarketEntryBodyLong data={data} users={props.users}/>
    } else if (props.data.type == "reservation") {
        let data: MarketReservation<Market> = {...props.data as any}
        return <MarketEntryBodyReservation data={data} users={props.users}/>
    } else {
        return (
            <View></View>
        )
    } 
}


const styles = StyleSheet.create({
    justifyContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingRight: 10,
        paddingBottom: 3,
    }
})


export default MarketEntryBody

