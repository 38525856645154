import React from 'react';

//import DelayInput from "react-native-debounce-input";
import { Platform, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import WebWrapper from '../../components/WebWrapper';
import StateComponent from '../../components/StateComponent';


function TestingScreen(props) {
    
    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        //contentContainerStyle={{ flexGrow: 1, padding: 10 }}
        //contentContainerStyle={{flex: 1, alignItems: "center"}}
        //style={{flex: 1}}
        
        >
            <WebWrapper>
            <StateComponent />
        </WebWrapper>
    </KeyboardAwareScrollView>
    )
}


export default TestingScreen



