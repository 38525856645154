import { Platform } from 'react-native';
import Toast from 'react-native-root-toast';


export const showToast = (message: string): void => {
    if (Platform.OS != 'web') {
        let toast = Toast.show(message, {
            duration: Toast.durations.LONG,
            position: Toast.positions.BOTTOM,
            shadow: true,
            animation: true,
            hideOnPress: true,
            backgroundColor: "white",
            textColor: "black",
            delay: 0,
            onShow: () => {
                // calls on toast\`s appear animation start
            },
            onShown: () => {
                // calls on toast\`s appear animation end.
            },
            onHide: () => {
                // calls on toast\`s hide animation start.
            },
            onHidden: () => {
                // calls on toast\`s hide animation end.
            }
        });
        setTimeout(function () {
            Toast.hide(toast);
        }, 5000);
    } else {
        alert(message)
    }
}
