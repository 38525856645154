import React from "react";
import { Switch, View } from "react-native";
import { Club } from "../../../../functions/src/interfaces";
import FormEntry from "../../../components/FormEntryNew";
import FormHeading from "../../../components/FormHeading";
import i18n, { tokens } from "../../../i18n";
import { roleTypes } from "../../../utilities/constants";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { StateLoader } from "../../../utilities/interfaces";

type Props = {
    club: StateLoader<Club>
    dispatchChange: (data: any) => void
}
function PaymentPromiseComponent(props: Props):JSX.Element {
    return (
        <View
        >
            <FormHeading
            heading={i18n.t(tokens.app.screens.manageClub.manageClubPaymentsPromiseAllowed)}
            />
            <FormEntry
            caption={i18n.t(tokens.app.screens.manageClub.manageClubPaymentsPromiseAllowed)}
            editable={true}
            >
                <Switch
                value={props.club?.data?.paymentPromise?.allowed || false}
                onValueChange={(value) => props.dispatchChange(
                    {
                        paymentPromise: {
                            ...props.club?.data?.paymentPromise,
                            allowed: value
                        }
                    }
                )}
                />
            </FormEntry>
            {props.club.data?.paymentPromise?.allowed?
            <View>
                {Object.entries(roleTypes).map(([key, objValue]) => {
                    return (<FormEntry
                    caption={objValue}
                    editable={true}
                    key={key}
                    >
                        <Switch
                        value={props.club?.data?.paymentPromise?.roles?.[key] || false}
                        onValueChange={(value) => props.dispatchChange(
                            {
                                paymentPromise: {
                                    ...props.club?.data?.paymentPromise,
                                    roles: {
                                        ...props.club?.data?.paymentPromise?.roles,
                                        [key]: value
                                    }
                                }
                            }
                        )}
                        />
                    </FormEntry>)
                })}
            </View>
            :null}
        </View>
    )
}

export default PaymentPromiseComponent