import React, { useEffect, useState } from 'react';
import { FlatList, Switch, Text, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { News, NewsTypes, user, UserRoles } from '../../../functions/src/interfaces';
import RightTopBar from '../../components/RightTopBar';
import WebWrapper from '../../components/WebWrapper';
import { addPlayerToNewsApplied, removePlayerToNewsApplied, subscribeForNews, subscribeForNewsAll } from '../../store/actions/actionsNews';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { getCurrentUserWithRoles } from '../../store/selectors';
import { newsFilters, normalize, windowWidth } from '../../utilities/constants';
import { globalStyleSheet, mainFontBold, secondColor } from '../../utilities/globalStyles';
import NewsEntry from './Components/NewsEntry';
import styles from './styles';
import Ionicons from '@expo/vector-icons/Ionicons';
import { Route, useNavigation } from '@react-navigation/native';
import Overlay from '../../components/Overlay';
import FormEntry from '../../components/FormEntryNew';
import FilterTag from '../../components/FilterTag';
import IconButton from '../../components/IconButton';
import { UserOrClub } from '../../store/reducers/usersReducer';
import FlexedMessage from '../../components/FlexedMessge';
import FloatingAction from '../../components/FloatingAction';



type Props = {
    route: Route<'NewsScreen', {}>
}

export type NewsFilters = {
    allClubs: boolean,
    tournaments: boolean,
    informations: boolean,
  }


type OverlayPropsNews = {
    isModalVisible: boolean,
    toggleModal: () => void,
    filters: NewsFilters,
    handler: (state) => void,
}

const RenderModal: React.FC<OverlayPropsNews> = (props) => {
    const {isModalVisible, toggleModal, filters} = props
    return (
      <Overlay isVisible={isModalVisible} onBackdropPress={toggleModal}>
          <View
          style={{
              //flex: 1,
              width: windowWidth*0.8,
              
          }}>
              <FormEntry 
              caption={newsFilters.allClubs}
              editable={true}
              >
                  <Switch value={filters.allClubs} onValueChange={(value) => props.handler(prevState => {
                        return {...prevState, allClubs: value}
                      })}/>
              </FormEntry>
              <FormEntry 
              caption={newsFilters.informations}
              editable={true}
              >
                  <Switch value={filters.informations} onValueChange={(value) => props.handler(prevState => {
                        return {...prevState, informations: value}
                      })}/>
              </FormEntry>
              <FormEntry 
              caption={newsFilters.tournaments}
              editable={true}
              >
                  <Switch value={filters.tournaments} onValueChange={(value) => props.handler(prevState => {
                        return {...prevState, tournaments: value}
                      })}/>
              </FormEntry>
          </View>
      </Overlay>
    )
}

function NewsScreen(props: Props) {

    //console.log('News screen props', props)
    const navigation = useAppNavigation()
    const [newsData, setNewsData] = useState([])
    const [mounted, setMounted] = useState(false)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    //const users: user[] = useAppSelector((state: RootStore) => state.users.usersData, shallowEqual)
    const clubs = useAppSelector((state: RootStore) => state.auth.allClubs, shallowEqual)
    const news: {[newsId: string]: News} = useAppSelector((state: RootStore) => state.news.news, shallowEqual)
    const selectedClub: string = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    const dispatch = useAppDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const [filters, setFilters] = useState<NewsFilters>({
        informations: true,
        tournaments: true,
        allClubs: true,
    })

    const toggleModal = () => {
        
        setIsModalVisible((prevValue) => {
            
            return !prevValue
        })
    }

    const isAdmin = () => {
        if (currentUser.type == "user") {
           return (currentUser.isGod == true) || (currentUser.isAdmin == true)
        } else if (currentUser.type == "clubs") {
            return true
        } 
    }

    const filterNews = (newsItem: News) => {
        //console.log(!marketItem, !marketItem.status, marketItem.status == 'approved')
        //console.log(marketItem.uid, props.auth.uid, filters.myRequests, true)
        if (!newsItem) {
            return false
        } else if (newsItem.type == NewsTypes.tournament && filters.tournaments == false) {
            return false
        } else if (newsItem.type == NewsTypes.information && filters.informations == false) {
            return false
        } else if (newsItem.club != selectedClub && filters.allClubs == false) {
            return false
        } else {
            return true
        }
    }


    useEffect(() => {
        var unsub = dispatch(subscribeForNewsAll(filters))
        setMounted(true)

        return () => {
            setMounted(false)
            if (unsub) {
                
                unsub.forEach((unsubscribe) => unsubscribe())
            }
        }
    }, [selectedClub])
    
    useEffect(() => {
        let out = []
        let keys = news ? Object.keys(news) : []
        keys.forEach(
            (key) => {
                if (filterNews(news[key])) {
                    out.push(news[key])
                }
                
            }
        ) 
        out.sort((a,b) => {
            if (a.dueDate > b.dueDate) {
                return 1
            } else {
                return -1
            }
        })
        setNewsData(out)
    }, [news, filters])

    const actions = [{
        text: "Accessibility",
        name: "bt_accessibility",
        icon: "add",
        
      }]
      console.log('Rendering news')
    return (
        
        <View style={styles.container}>
            
            {/* <RightTopBar title={props.route?.name} /> */}
            {/* <View style={
                    styles.toolbar}>
                    <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFontBold, fontSize: normalize(20), alignSelf: "center"}}>{clubs && selectedClub? clubs[selectedClub]?.club_name : ""}</Text>
            </View> */}
            <View style={[globalStyleSheet.toolbar,{paddingLeft: 10, paddingRight: 10}]}>
            <View style={{padding: 3}}><Text>Filter</Text></View>
                <FlatList
                horizontal
                style={{
                    flex: 0.9
                }}
                data={
                    Object.keys(filters).map((key) => {
                        return {key: key, value: filters[key], caption: newsFilters[key]}
                    })
                }
                renderItem={(item) => <FilterTag 
                    tag={item.item.caption} 
                    value={item.item.value}
                    onPress={() => setFilters(prev => {return {...prev, [item.item.key]: !item.item.value}})}
                    />}
                />
                <IconButton 
                icon='filter-outline'
                size={normalize(24)}
                onPress={() => toggleModal() }
                color={"black"}
                />
                
            </View>
            <WebWrapper>
            {mounted?<View style={globalStyleSheet.container}>
                {newsData.length > 0?
                <FlatList 
                style={styles.listContainer}
                data={newsData}
                renderItem={({item, index}) => {
                    return (<NewsEntry
                        item={item} 
                        index={index} 
                        addPlayer={addPlayerToNewsApplied}
                        removePlayer={removePlayerToNewsApplied}
                        currentUser={currentUser}
                        //users={users}
                        />)
                }}
                keyExtractor={(item)=> item.firebaseId}
                />:
                <FlexedMessage 
                message={"Pod současným nastavením filtrů není žádná aktualita."}
                />
                }
                </View>:null}
            
            <FloatingAction
            visible={isAdmin()}
            actions={actions}
            color={secondColor}
            overrideWithAction={true}
            onPressItem={() => navigation.navigate('ManageNewsScreen', {})}
            buttonSize={70}
            />
            <RenderModal handler={setFilters} toggleModal={toggleModal} filters={filters} isModalVisible={isModalVisible}/>
            </WebWrapper>
        </View> 
        
    )
}


export default NewsScreen
