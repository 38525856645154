import { useNetInfo } from '@react-native-community/netinfo';
import React from 'react';
import { Text, View } from 'react-native';
import NavigationScreen from '../NavigationScreen/NavigationScreen';
//import * as Location from 'expo-location'

function ConnectionScreen(props) {
    const netInfo = useNetInfo()
    /* const [con, setCon] = useState(false)
    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
            console.log('Connection type', state.type);
            console.log('Is connected?', JSON.stringify(state));
            setCon(state.isConnected)
        })
        return () => unsubscribe()
    }
    ) */
    console.log('Render testing')
    return (
        <>
           {netInfo.isConnected?<NavigationScreen/>:
           <View style={
               {
                   flex: 1,
                   justifyContent: "center",
                   alignItems: "center"
               }
            }
            >
            <Text maxFontSizeMultiplier={1.2} style={{fontSize: 20, fontWeight: "bold", color: "white"}}>{"OFFLINE"}</Text>
            {/* <Text maxFontSizeMultiplier={1.2} style={{fontSize: 10, color: "white"}}>{JSON.stringify(netInfo)}</Text> */}
           </View>}
        </>
    )
}
export default (ConnectionScreen)



