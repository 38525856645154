import { Route, useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import { FlatList, Platform, Text, Pressable, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { shallowEqual } from "react-redux";
import { Notification, PaymentData } from "../../../functions/src/interfaces";
import FormEntryNoCaption from "../../components/FormEntryNoCaption";
import WebWrapper from "../../components/WebWrapper";
import i18n from "../../i18n";
import { queryPaymentsByCheckoutId } from "../../store/actions/actionsCheckout";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks";
import { RootStore } from "../../store/reducers/rootReducer";
import { monthString } from "../../utilities/funcs";
import { globalStyleSheet } from "../../utilities/globalStyles";
import { StateLoader } from "../../utilities/interfaces";
import { _handleNotification } from "../HomeScreen/HomeComponent";

type Props = {
    route: Route<'PaymentsListScreen', {clubId: string, checkoutId: string}>
}

function PaymentsListScreen(props: Props): JSX.Element {
    const {clubId, checkoutId} = props.route.params
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()

    const paymentsData: StateLoader<PaymentData[]> = useAppSelector((state: RootStore) => state.checkout.queriedPayments, shallowEqual)
    
    useEffect(() => {
        console.log('Dispatching for: ' + clubId + ' and ' + checkoutId)
        if (clubId && checkoutId && clubId != "" && checkoutId != "") {

            dispatch(queryPaymentsByCheckoutId(checkoutId, clubId) as any)
        }
    }, [clubId, checkoutId])

    const onPress = (paymentData: PaymentData) => {
        navigation.navigate('PaymentScreen', {type: "payment", club: paymentData.club, paymentId: paymentData.firebaseId})
    }

    return (
        <View style={globalStyleSheet.container}>
        <WebWrapper>
        <KeyboardAwareScrollView  
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >
            <FlatList
            contentContainerStyle={globalStyleSheet.container}
            data={paymentsData.data.sort((a,b) => a.createdDate > b.createdDate ? 1 : -1)}
            keyExtractor={(item) => item.firebaseId}
            renderItem={({item}) => (
                <Pressable
                onPress={() => onPress(item)}
                >
                <FormEntryNoCaption
                editable={false}
                >
                    <Text
                    style={globalStyleSheet.entityText}
                    >{`${monthString(item.createdDate)} - ${String(item.amount)} - ${i18n.t(item.status)}`}
                    </Text>
                </FormEntryNoCaption>
                </Pressable>)}
            />

        </KeyboardAwareScrollView>
        </WebWrapper>
        </View>
    )
}

export default PaymentsListScreen
