import React, { useState, useRef } from 'react'
import { Dimensions, Platform, PixelRatio } from "react-native";
import { Currencies, MarketTypes, NewsTypes, RoleTypes, SeasonTypes } from '../../functions/src/interfaces';
import i18n, { tokens } from '../i18n';
import { NewsFilters, ReservationFilters } from '../i18n/keys';

export const windowWidth = Dimensions.get('window').width;
export const windowHeight = Dimensions.get('window').height;
export const pixRat = PixelRatio.get()

export const gameTypes = {
  single: i18n.t(tokens.app.constants.gametypes.single),
  double: i18n.t(tokens.app.constants.gametypes.double),
  group: i18n.t(tokens.app.constants.gametypes.group),
  training: i18n.t(tokens.app.constants.gametypes.training),
  withMember: i18n.t(tokens.app.constants.gametypes.withMember),
  withoutMember: i18n.t(tokens.app.constants.gametypes.withoutMember),
}

export const membershipTypes = {
  yearSubscription: i18n.t(tokens.app.constants.memTypes.yearSubscription),
  tourSubscription: i18n.t(tokens.app.constants.memTypes.tourSubscription),
}
    

export const seasonTypes = {
  [SeasonTypes.winter]: i18n.t(tokens.app.constants.seasons.winter),
  [SeasonTypes.summer]: i18n.t(tokens.app.constants.seasons.summer),
}

export const weekdays = {
  0: i18n.t(tokens.app.constants.weekdays.sunday),
  1: i18n.t(tokens.app.constants.weekdays.monday),
  2: i18n.t(tokens.app.constants.weekdays.tuesday),
  3: i18n.t(tokens.app.constants.weekdays.wednesday),
  4: i18n.t(tokens.app.constants.weekdays.thursday),
  5: i18n.t(tokens.app.constants.weekdays.friday),
  6: i18n.t(tokens.app.constants.weekdays.saturday),
}

export const weekdaysStartingMonday = {
  1: i18n.t(tokens.app.constants.weekdays.monday),
  2: i18n.t(tokens.app.constants.weekdays.tuesday),
  3: i18n.t(tokens.app.constants.weekdays.wednesday),
  4: i18n.t(tokens.app.constants.weekdays.thursday),
  5: i18n.t(tokens.app.constants.weekdays.friday),
  6: i18n.t(tokens.app.constants.weekdays.saturday),
  7: i18n.t(tokens.app.constants.weekdays.sunday),
}

export const reccurency = {
  1: i18n.t(tokens.app.constants.reccurency.weekly),
}

export const courtTypes = {
  clay: i18n.t(tokens.app.constants.courts.clay),
  hard_court: i18n.t(tokens.app.constants.courts.hard_court)
}

//1334 × 750 pixel
export const scale = (Platform.OS == "web"?Math.max(Math.min(windowHeight, windowWidth), 787):Math.min(windowHeight, windowWidth)) / (Platform.OS == "web" ? 787:375);

export function normalize(size) {
  const newSize = size * scale 
  //console.log('Scale: ' + String(scale))
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize))
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2
  }
}

export const marketTypes = {
  [MarketTypes.opengame]: i18n.t(tokens.app.constants.markettypes.opengame),
  [MarketTypes.longreservation]: i18n.t(tokens.app.constants.markettypes.longreservation),
  [MarketTypes.credit]: i18n.t(tokens.app.constants.markettypes.credit),
  [MarketTypes.reservation]: i18n.t(tokens.app.constants.markettypes.reservation),
  [MarketTypes.subscription]: i18n.t(tokens.app.constants.markettypes.subscription),
}

export const marketFilters = {
  ...marketTypes,
  'myRequests': i18n.t(tokens.app.screens.market.filterMyRequests),
  'allClubs': i18n.t(tokens.app.screens.market.allClubs),
}

export const reservationFilters = {
  [ReservationFilters.openState]: i18n.t(tokens.app.components.reservationFilters.openState)
}

export const newsFilters = {
  [NewsFilters.allClubs]: i18n.t(tokens.app.components.newsFilters.allClubs),
  [NewsFilters.informations]: i18n.t(tokens.app.components.newsFilters.informations),
  [NewsFilters.tournaments]: i18n.t(tokens.app.components.newsFilters.tournaments),
}

export const newsTypes = {
  [NewsTypes.information]: i18n.t(tokens.app.constants.newsTypes.information),
  [NewsTypes.tournament]: i18n.t(tokens.app.constants.newsTypes.tournament),
}


export const roleTypes = {
  [RoleTypes.all]: i18n.t(tokens.app.constants.roleTypes.all),
  [RoleTypes.isClubRegistered]: i18n.t(tokens.app.constants.roleTypes.isClubRegistered),
  [RoleTypes.isMember]: i18n.t(tokens.app.constants.roleTypes.isMember),
  [RoleTypes.isAdmin]: i18n.t(tokens.app.constants.roleTypes.isAdmin),
  [RoleTypes.isCoach]: i18n.t(tokens.app.constants.roleTypes.isCoach),
  [RoleTypes.isTester]: i18n.t(tokens.app.constants.roleTypes.isTester),
  [RoleTypes.isChild]: i18n.t(tokens.app.constants.roleTypes.isChild),
  [RoleTypes.isPensioner]: i18n.t(tokens.app.constants.roleTypes.isPensioner),
}


export  const checkoutItemTypes = {
  ...membershipTypes,
  reservationuser: i18n.t(tokens.app.constants.checkoutItems.reservationuser),
  fee: i18n.t(tokens.app.constants.checkoutItems.fee),
  credit: i18n.t(tokens.app.constants.checkoutItems.credit),
}

export const currency = {
  [Currencies.CZK]: "CZK",
  [Currencies.PLN]: "PLN",
}