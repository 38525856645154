import React, { useEffect, useState, useRef } from 'react'
import { FlatList, Text, Pressable, View, ActivityIndicator} from 'react-native'
import styles from './styles';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
//import StickyParallaxHeader from 'react-native-sticky-parallax-header';
import RightTopBar from '../../components/RightTopBar';
// You can import from local files
import i18n, { tokens } from '../../i18n';
import { participateInSeasonRanking, subscribeForRanking } from '../../store/actions/actionsRanking';
import WebWrapper from '../../components/WebWrapper';
import { mainFontBold } from '../../utilities/globalStyles';
import { ButtonGroup } from 'react-native-elements';
import RankingEntry from '../../components/RankingEntry';
import {gameTypes} from '../../utilities/constants'
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { Route, useNavigation } from '@react-navigation/native';
import { getCurrentUserWithRoles } from '../../store/selectors';
import { UserOrClub } from '../../store/reducers/usersReducer';
import FlexedMessage from '../../components/FlexedMessge';


type Props = {
    route: Route<'RankingScreen', {action?: string, alreadySelected?: string[]}>
}

function RankingScreen(props: Props) {
    const [initLoad, setInitLoad] = useState(false) 
    const ranking = useSelector((state:RootStore) => {
        let data = state.ranking.seasonalRanking.data
        //console.log('Selector trigger', data)
        return data
    })
    const currentUser: UserOrClub = useAppSelector(getCurrentUserWithRoles, shallowEqual) 
    const amParticipating = useSelector((state:RootStore) => state.ranking.amParticipating, shallowEqual)
    const season = useSelector((state:RootStore) => state.auth.activeSeason, shallowEqual)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    const clubmembers = useAppSelector((state: RootStore) => state.users.clubmembers, shallowEqual) 
    
    const newGameTypes = {single: gameTypes['single'], double: gameTypes.double}
    const newGameTypesKeys = Object.keys(newGameTypes)
    const newGameTypesValues = Object.values(newGameTypes)
    const [buttonGroupIndex, setButtonGroupIndex] = useState(0)
    const [buttons,setButtons] = useState(
        newGameTypesValues
    )
    
    useEffect(() => {
        var unsub = dispatch(subscribeForRanking(selectedClub, season?.firebaseId, newGameTypesKeys[buttonGroupIndex],() => {}))
        
        
        return () => {
            if (unsub) {
                unsub
                .then(() => {
                    console.log('Unsubscribing')
                })
            }
        }
    }, [selectedClub, season?.firebaseId, buttonGroupIndex])

    

    /* useEffect(() => {
         if (initLoad == false) { 
            
            dispatch(retrieveSeasonRanking("","UP8xRYGq2nt2vIwZB8ae","gYXij67qG2d7b5pJ6wav",20))
            setInitLoad(true)
        }
    } , [initLoad] ) */
    console.log('Rendering ranking')
  
    return (
        
        <View style={styles.container}>
           {/* <RightTopBar title={props.route?.name}/> */}
           <WebWrapper>
                <ButtonGroup
                onPress={(val) => {setButtonGroupIndex(val)} }
                selectedIndex={buttonGroupIndex}
                buttons={buttons}
                //buttonStyle={{borderColor: secondColor}}
                selectedButtonStyle={{backgroundColor: "grey"}}
                containerStyle={styles.toolbar}
                buttonContainerStyle={{margin: 0, padding: 0, height: "100%"}}
                textStyle={{color: "black", fontFamily: mainFontBold, fontWeight: "bold"}}
                />
                {season?
                <View
                style={{
                    flex: 1,
                    alignItems: "stretch",
                }}
                >{
                    
                    amParticipating.isLoaded?
                amParticipating.amParticipating == false
                && currentUser.type == "user"
                ?
                
                <Pressable
                    style={styles.button}
                    onPress={() => {
                            dispatch(participateInSeasonRanking(currentUser.uid, selectedClub, season.firebaseId, 'active'))
                    }}
                    >
                        <Text maxFontSizeMultiplier={1.2} style={styles.buttonText}>{i18n.t(tokens.app.screens.ranking.rankingAddButton)}</Text>
                    </Pressable>
                    :null
                    :<ActivityIndicator size={"small"}/>
                    }
                
                {amParticipating.isLoaded?
                <FlatList
                //contentContainerStyle={styles.entityContainer}
                // Data
                data={ranking}
                // Render Items
                renderItem={({item, index}) => {
                    //console.log('Rendering item: ', item)
                    return (
                    <RankingEntry 
                    position={item.rank} 
                    selectedClub={selectedClub} 
                    activeSeasonId={season?.firebaseId} 
                    currUid={currentUser.type == "user"? currentUser.uid: currentUser.firebaseId} 
                    gameType={newGameTypesKeys[buttonGroupIndex]} 
                    item={{...clubmembers[item.uid], ...item }}
                    />)
                }}
                // Item Key
                keyExtractor={(item, index) => {
                    //console.log('Item from key',item); 
                    return String(item.rank) + "_" + item.uid
                }}
                // Header (Title)
                //ListHeaderComponent={this.renderHeader}
                // Footer (Activity Indicator)
                //ListFooterComponent={this.renderFooter}
                // On End Reached (Takes a function)
                //onEndReached={() => dispatch(retrieveSeasonRanking("","UP8xRYGq2nt2vIwZB8ae","gYXij67qG2d7b5pJ6wav",20,ranking.lastVisible))}
                // How Close To The End Of List Until Next Data Request Is Made
                onEndReachedThreshold={0}
                // Refreshing (Set To True When End Reached)
                //refreshing={!ranking.isLoaded}
                />:null}
                </View>:<FlexedMessage 
                message={`V termínu kalendáře je není vybraná žádná sezona.`}
                />}
           </WebWrapper>
        </View> 
        
    )
}


export default RankingScreen
