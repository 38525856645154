import React from "react";
import { View } from "react-native";
import { Segment } from "./FieldLane";
import ReservationsBlock from "./ReservationsBlock";
import ReservationsFreeBlock from "./ReservationsFreeBlock";

type Props = {
    segment: Segment
}
const ReservationSegment:React.FC<Props> = (props) => {
    
    return (
        <View
        style={{
            borderWidth: props.segment.type == "blocks"?2:0,
            borderColor: "grey",
            borderRadius: 5,
            flexDirection: "row",
        }}
        >
            {props.segment.type == "blocks"?
            props.segment.blocks.map((item) => {
                return (<ReservationsFreeBlock key={item.key} block={item}/>)
            }):null}
            {props.segment.type == "reservations"?
            <ReservationsBlock key={props.segment.key} segment={props.segment}/>:null}
        </View>
    )
}

export default ReservationSegment