import React from "react";
import { DeviceEventEmitter, Text, Pressable, View } from "react-native";
import { user } from "../../../../functions/src/interfaces";
import RenderContact from "../../../components/Contact";
import i18n, { tokens } from "../../../i18n";
import { useAppNavigation, useAppSelector } from "../../../store/hooks";
import { RootStore } from "../../../store/reducers/rootReducer";
import { UserOrClub } from "../../../store/reducers/usersReducer";
import { monthString } from "../../../utilities/funcs";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import styles from '../styles';

type Props = {
    item: UserOrClub<{uid: string, isActiveFloat: boolean, isActiveTour: boolean, membEnds: Date, tours: number[], linkedToUser?: UserOrClub<{}>}>,
    action: string,
    searchMode: boolean,
    isAllowed: boolean,
    selectedPlayers: string[],
}

const RenderContactWrap: React.FC<Props> = (props) => {
    const navigation = useAppNavigation()
    const {item,action} = props
    const actionText = (action: string) => {
        if (action == 'selectPlayer') {
            return i18n.t(tokens.app.screens.contacts.selectButton)
        }
    }

    //const user = useAppSelector((state: RootStore) => state.users.usersData[item.uid], (a,b) => JSON.stringify(a) == JSON.stringify(b))

    return (item.type=="user"?<RenderContact
        key={item.uid}
        item={item}
        linkedToUser={item.linkedToUser}
    ><View>
            {action && !props.selectedPlayers.includes(item.uid) ? (
                <Pressable
                    style={globalStyleSheet.button}
                    onPress={() => {
                        DeviceEventEmitter.emit("event.mapNewPlayerToGame", item.uid);
                        navigation.goBack()
                        /* props.selectPlayerToReservation(item.uid, props.route.params.path, (res) => {
                            if (res == true) {
                                navigation.pop()
                            }
                        }) */
                    }}
                >
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.buttonText}>{actionText(action)}</Text>
                </Pressable>) : null}
            {props.searchMode == false && !action ?
                props.isAllowed ? (<>
                    {item.isActiveFloat?<Text key={'float'} style={[globalStyleSheet.entityText, { color: item.isActiveFloat ? "green" : "red" }]}>{item.isActiveFloat && item.membEnds ? monthString(item.membEnds) : null}</Text>: null}
                    {item.isActiveTour?<Text key={'tour'} style={[globalStyleSheet.entityText, { color: item.isActiveTour ? "green" : "red" }]}>{item.isActiveTour && item.tours ? String(Math.max(...item.tours)) : null}</Text>:null}
                    {item.isActiveFloat != true && item.isActiveTour != true ? <Text key={'red'} style={[globalStyleSheet.entityText, { color: "red" }]}>{i18n.t(tokens.app.screens.contacts.contactsNotMember)}</Text>: null}
                </>) : (<View
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 10,
                        backgroundColor: (item.isActiveFloat || item.isActiveTour) ? "green" : "red"
                    }}
                />) : null}
        </View>
    </RenderContact>:null)
}

export default RenderContactWrap