import React from 'react';
import { StyleSheet, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import SmallButton from '../../../components/SmallButton';
import i18n, { tokens } from '../../../i18n';
import { addContact, removeContact } from '../../../store/actions/actionsContacts';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { RootStore } from '../../../store/reducers/rootReducer';
import { normalize } from '../../../utilities/constants';



type Props = {
    isLoggedUser: boolean,
    isContact: boolean,
    uid: string,
    disabled: boolean,
}

function ButtonComponent(props: Props):JSX.Element {
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()

    const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)

    return (<View
      style={styles.buttonContainer}>
        
       
        {!props.isLoggedUser && props.isContact?
        <SmallButton 
        onPress={() => dispatch(removeContact({uid: props.uid}))}
        buttonText={i18n.t(tokens.app.screens.profile.profileRemToContactsButton)}
        //icon={"people-circle-outline"}
        />:null}
        {!props.isLoggedUser && !props.isContact?
        <SmallButton 
        onPress={() => dispatch(addContact({uid: props.uid}))}
        buttonText={i18n.t(tokens.app.screens.profile.profileAddToContactsButton)}
        //icon={"people-circle-outline"}
        />:null} 
        {!props.disabled?
        <SmallButton 
        onPress={() => navigation.navigate("EditProfileScreen", {uid: props.uid})}
        buttonText={i18n.t(tokens.app.screens.profile.profileEditButton)}
        //icon={"settings-outline"}
        />:null} 
        {!props.disabled?
        <SmallButton 
        onPress={() => navigation.navigate('QueryCheckoutsScreen', {uid: props.uid, clubId: selectedClub})}
        buttonText={i18n.t(tokens.app.screens.profile.profileCheckoutsButton)}
        //icon={"settings-outline"}
        />:null}    
        </View>
    )
}

const styles = StyleSheet.create({
    
    textStyle: {
      
        textAlign: "right", 
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',
        
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        flexWrap: "wrap",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
});

export default ButtonComponent
