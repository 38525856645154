import { firestoreAutoId, standardizeDates } from "../../utilities/funcs"
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"
import { CallbackFunc, News } from "../../../functions/src/interfaces"
import Constants from "expo-constants";
import { NewsActions } from "../reducers/newsReducer"   
import { RootStore } from "../reducers/rootReducer"
import { DocumentData, Query } from "@google-cloud/firestore"
import { showToast } from "../../utilities/toastcontroller"
import { AppThunk } from "../hooks";
import firebase from 'firebase/compat/app';
import { AuthActions } from "../reducers/authReducer";

const API_URL = Constants.expoConfig.extra.functionsUrlBase

export const addNewsEntry = (newsEntry:News, blob, callback): AppThunk<Promise<void>> => {
    //console.log('Chci zapsat novinku', newsEntry)
    return async (dispatch, getState, { getFirebase, getFirestore, getFileStorage }) => {
        
        const firebase = getFirebase()
        const firestoreuid = firebase.auth().currentUser.uid
        console.log('Uid: ' + firestoreuid)
        const storage = getFileStorage()

        console.log('state', newsEntry)
        const record = {
            ...newsEntry,
            uid: firestoreuid,
            messages: newsEntry.messages || [],
        }
        //delete record.firebaseId
        
        const rootRef = getFirebase().firestore()
        const newsRef = firebase.firestore().collection('clubs').doc(record.club).collection('news')
        
        rootRef.runTransaction(async (t) => {
            //TODO: musí se tu řešit query na clash 
            
            var entityRef = null
            if (record.firebaseId) {
                entityRef = newsRef.doc(record.firebaseId)
            } else {
                entityRef = newsRef.doc()
                const refId = entityRef.id
                record["firebaseId"] = refId
            }
            if (blob) {
                
                const path = 'newsPhoto/newsPhoto_' + record["firebaseId"] + '.jpg'
                console.log('jedu upload ' + path)
                console.log('jedu upload ', record)
                //rootRef.uploadFile(path, photo)
                var newsPhotoRef = ref(storage, path)
                let snapshot = await uploadBytes(newsPhotoRef, blob)
                console.log('snapshot',snapshot)
                const url = await getDownloadURL(snapshot.ref)
                record['newsPhoto'] = url
                
            }
            console.log('Nahrani news: ' + JSON.stringify(record))
            t.set(entityRef, record)
                
        })
        .then(
            () => {
                callback(true, record)
                dispatch({type: 'NEWS_ADDED', data: record})
            }
        ).catch(
            (e) => {
                
                callback(false,e)
                dispatch({type: 'ADD_NEWS_ERROR', e})
            }
        )
       
    }
}

export const manageNewsToState = (news:News, callback: (unsub: () => void) => void): AppThunk<void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        
        //const firebase = getFirebase()
        //const firestoreuid = firebase.auth.uid
        //console.log('add Market to state params', news, news.club, callback)
        

        if (news.firebaseId) {
            const rootRef = getFirestore()
            const marketRef = rootRef.collection('clubs').doc(news.club).collection('news').doc(news.firebaseId)
            
            var returnedData = {}
            var unsubscribe = marketRef.onSnapshot(
                (querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    const changes = standardizeDates(querySnapshot.data())
                    //console.log('Totoo je return response', changes)
                    dispatch({type: NewsActions.newsPutToState, data: changes})
                    
                },
                (error) => {
                    console.log("Error listening" + JSON.stringify(error))
                }
            )
    
            //console.log(unsubscribe)
            callback(unsubscribe
                )
            
        } else {
            const state = getState()
            let newNews = {
                ...news,
                uid: state.auth.userState.user.uid,
                club: state.auth.selectedClub
            }
            dispatch({type: NewsActions.newsPutToState, data: newNews})
        }
        

        
        
    }
}



export const subscribeForNews = (club: string, callback: (unsub: () => void) => void): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log("Callung subscribe for res")
        let dateFrom = new Date
        

        
        const firebase = getFirebase()
        const firestoreuid = firebase.auth().currentUser.uid
        //console.log('state', firestoreuid)
        


        const rootRef = getFirebase().firestore()
        const entityRef = firebase.firestore().collection('clubs').doc(club)
        .collection('news').where('dueDate', '>=', dateFrom).orderBy('dueDate');
        //console.log("Ahoj kurva", club)
        var unsubscribe = entityRef.onSnapshot(
            (querySnapshot) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log(doc)
                    js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 

                    
                    
                })
                
                //console.log(js)
                dispatch({type: NewsActions.newsSubcriptionChanges, data: js})
            },
            (error) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )

        //console.log(unsubscribe)
        callback(unsubscribe
            )


    }
}


export const subscribeForNewsAll = (filters): AppThunk<(() => void)[]> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log("Callung subscribe for res")
        let dateFrom = new Date
        
        const state: RootStore = getState()
        const firebase = getFirebase()
        const rootRef = getFirestore()
        //console.log('state', firestoreuid)
        const currUser = state.auth.currentUserData


        var entityRef: any = null
        var clubList = []
        var subscribeList = []

        if (currUser.type == "clubs") {
            clubList = [currUser.firebaseId]
        } else {
            if (filters.allClubs == true) {
                clubList = [...currUser.clubs]
            } else {
                clubList = [state.auth.selectedClub]
            }
            
        }

        for (let i = 0; i < clubList.length; i++) {
            let currClub = clubList[i]
            entityRef = rootRef.collection('clubs').doc(currClub).collection('news')
            //Conditional based filters
            if (true) {
                //entityRef = entityRef.where('status','in',statuses)
            } 
            //Order na konci
            entityRef = entityRef.where('dueDate', '>=', dateFrom).orderBy('dueDate') as Query<DocumentData>;
            //console.log("Ahoj kurva", club)
            let unsubscribe = entityRef.onSnapshot(
                (querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    const changes = querySnapshot.docChanges()
                    const js = {
                        'added':{},
                        'modified':{},
                        'removed':{}
                                
                    }
                    changes.forEach(doc => {
                        //console.log(doc)
                        //console.log(doc)
                        js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 

                        
                        
                    })
                    
                    //console.log(js)
                    dispatch({type: NewsActions.newsSubcriptionChanges, data: js})
                },
                (error: any) => {
                    console.log("Error listening" + JSON.stringify(error))
                }
            )

            subscribeList.push(unsubscribe)
        }
        
        return subscribeList

        //console.log(unsubscribe)
    }
}

export const addPlayerToNewsAppliedLegacy = (player: string, newsId: string, club: string, callback: CallbackFunc): AppThunk<Promise<void>> => {
    //console.log(player, newsId, club, callback)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const fb = getFirebase()
        const firestoreuid = fb.auth().currentUser.uid
        //console.log('state', firestoreuid)
        


        const rootRef = getFirestore()
        const newsRef = rootRef.collection('clubs').doc(club).collection('news').doc(newsId)
        
        rootRef.runTransaction(async (T) => {
            //TODO: musí se tu řešit query na clash 
            const marketEntry = await T.get(newsRef)
            
            if (marketEntry) {
                let data: News = standardizeDates(marketEntry.data()) 
                console.log('News data: ', data)
                if (data.uid == player || firestoreuid == player) {
                    T.update(newsRef, {appliedPlayers: firebase.firestore.FieldValue.arrayUnion(player)})
                    let out: News = {...data, appliedPlayers:  [...data.appliedPlayers || [], player]}
                    return out
                } else {
                    return Promise.reject('Unauthorised')
                }
                
                
            }
            //return data
                
        })
        .then(
            (marketEntry: News) => {
                callback(true, marketEntry)
                dispatch({type: 'NEWS_PLAYER_ADDED', data: marketEntry})
            }
        ).catch(
            (e) => {
                
                callback(false,e)
                dispatch({type: 'NEWS_PLAYER_ADD_ERROR', e})
            }
        )
       
    }
}

export const addPlayerToNewsApplied = (player: string, newsId: string, club: string, callback: CallbackFunc): AppThunk<Promise<any>> => {
    //console.log(player, newsId, club, callback)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: AuthActions.authActionPressed, data: true})
        let state: RootStore = getState()
        //let market: AnyMarket = state.market.managedMarket.data
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        
        let body: string = JSON.stringify({
            token: token,
            type: "news",
            firebaseId: newsId,
            club: club
        })
        let response = await fetch(`${API_URL}/applyToAppliedPlayers` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });

        if (response.ok) {
            showToast('User added successfully')
            dispatch({type: AuthActions.authActionPressed, data: false})
            callback(true, body)
            return true
        } else {
            showToast('Error adding user')
            dispatch({type: AuthActions.authActionPressed, data: false})
            callback(false, body)
            return false
        }
       
    }
}


export const removePlayerToNewsApplied = (player, newsId, club, callback): AppThunk<Promise<any>> => {
    //console.log(player, marketId, club, callback)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //const firestoreuid = getState().firebase.auth.uid
        const fb = getFirebase()
        const firestoreuid = fb.auth().currentUser.uid
        //console.log('state', firestoreuid)
        


        const rootRef = getFirestore()
        const opengameRef = rootRef.collection('clubs').doc(club).collection('news').doc(newsId)
        
        rootRef.runTransaction(async (T) => {
            //TODO: musí se tu řešit query na clash 
            const marketEntry = await opengameRef.get()
            
            if (marketEntry) {
                let data = marketEntry.data()

                if (data.uid == player || firestoreuid == player) {
                    T.update(opengameRef, {appliedPlayers: firebase.firestore.FieldValue.arrayRemove(player)})
                    return data
                } else {
                    return Promise.reject('Unauthorised')
                }
                
                
            }
                
        })
        .then(
            (marketEntry) => {
                callback(true, marketEntry)
                dispatch({type: 'NEWS_PLAYER_REMOVED', data: marketEntry})
            }
        ).catch(
            (e) => {
                
                callback(false,e)
                dispatch({type: 'NEWS_PLAYER_REMOVAL_ERROR', e})
            }
        )
       
    }
}



export const addEditMessage = (message, newsId, club, callback): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const fb = getFirebase()
        const firestoreuid = fb.auth().currentUser.uid
        //console.log('state', firestoreuid)
        


        const rootRef = getFirestore()
        const opengameRef = rootRef.collection('clubs').doc(club).collection('news').doc(newsId)
        
        rootRef.runTransaction(async (T) => {
            //TODO: musí se tu řešit query na clash 
            const marketEntry = await opengameRef.get()
            //console.log('Chci zapsat' + JSON.stringify(message))
            if (marketEntry) {
                let data = marketEntry.data()
                if (message.messageId) {
                    let index = data.messages.findIndex((item, i) => message.messageId == item.messageId)
                    data.messages[index] = message
                    T.update(opengameRef, {messages: data.messages})
                } else {
                    message = {...message, messageId: firestoreAutoId(), updateTime: new Date}
                    T.update(opengameRef, {messages: firebase.firestore.FieldValue.arrayUnion(message)})
                }
                
                
                
                
            }
                
        })
        .then(
            () => {
                callback(true, message)
                dispatch({type: 'OPENGAME_EDITED', data: message})
            }
        ).catch(
            (e) => {
                
                callback(false,e)
                dispatch({type: 'OPENGAME_ERROR', e})
            }
        )
       
    }
}

export const removeNews = (newsId: string, club: string, callback: CallbackFunc): AppThunk<Promise<void>> => {
    
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const fb = getFirebase()
        //const firestoreuid = fb.auth.uid
        console.log(`removal call of: ${newsId} ${club} `)
        


        const rootRef = getFirestore()
        const newsRef = rootRef.collection('clubs').doc(club).collection('news').doc(newsId)
        
        
        rootRef.runTransaction(async (T) => {
            //TODO: docasne smazat uplne
            
            //T.update(newsRef, {status: "deleted"})
            T.delete(newsRef)
            
            
        })
        .then( 
            () => {
                
                callback(true, {})
                dispatch({type: 'NEWS_DELETED', data: null})
            }
        ).catch(
            (e) => {
                console.log('nejaky problem', e)
                callback(false,e)
                dispatch({type: 'NEWS_DELETE_ERROR', e})
            }
        )
       
    }
}