import View from '@expo/html-elements/build/primitives/View';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ActivityIndicator, Platform, StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual } from 'react-redux';
import { Market, MarketOpengame, MarketTypes, Message, UserRoles } from '../../../../functions/src/interfaces';
import CommentsComponent from '../../../components/CommentsComponent';
import GamePlayers from '../../../components/GamePlayersNew';
import { addEditMessage, addPlayerToOpengameApplied, editMarket } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/marketReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../../store/selectors';
import MessageComponent from '../../../components/MessageComponent';
import PrevMarket from '../CommonMarketComponents/PrevMarket';
import ButtonComponent from './ButtonComponent';
import { isAllowedToEdit, validations } from './OpengameValidations';
import ReservationComponent from './ReservationComponent';


type Props = {
    firebaseId?: string,
    club: string,
    setMarketId: (newVal: string) => void,
}

function ManageOpengame(props: Props):JSX.Element {
    //console.log('Market: ' + props.firebaseId)
    const dispatch = useAppDispatch()
    

    //const [validators, setValidators] = useState({})
    
    const opengameData: StateLoader<MarketOpengame<Market>> = useAppSelector((state: RootStore) => {
        let returnData = {...state.market.managedMarket}
        let anyMarket = state.market.managedMarket.data
        if (anyMarket.type == MarketTypes.opengame) {
            let reservation =  {...anyMarket} 
            let seasons = state.auth.seasons
            const validators = seasons && reservation.season ? validations(seasons[reservation.season]) : {}
            //console.log(validators)
            var validationsData = {}
            var allValid = true
            if (state.market.managedMarket.isLoaded) {
                validationsData = Object.keys(reservation).reduce((acc, key) => {acc[key] = {validation: true, error: ""}; return acc}, validationsData)
                let validKeys = Object.keys(validators)
                
                for (let i = 0; i < validKeys.length; i++) {
                    let key = validKeys[i]
                    let validator = validators[key]
                    
                    let validation = validator.method(reservation)
                    
                    validationsData[key] = {validity: validation.bool, error: validation.context}
                    allValid = allValid == true ? validation.bool : allValid
                }
                //  console.log('Probehly validace: ', validationsData)
                returnData['validations'] = {...validationsData}
            }
            returnData['allValid'] = allValid
            
            console.log('Vysledek validace ', allValid)
                
            return returnData
        } else {
            return 
        }
    }, shallowEqual)
    //console.log('Update rezervace: ',reservationData)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    const queriedCollisions = useAppSelector(state => state.market.queriedCollisions, shallowEqual)

    const isEditor = () => {
        if (currentUser.type == "user") {
            return currentUser.uid == opengameData.data.uid || currentUser.isAdmin == true || currentUser.isGod == true
        } else if (currentUser.type == "clubs") {
            return currentUser.firebaseId == opengameData.data.club
        }
        
    }  

    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        //console.log(inter)
        dispatch(editMarket(changes))
    }

    const addMessage = (msg: Message, callback: (res: boolean, context: any) => void) => {
        dispatch(addEditMessage(msg,opengameData.data.firebaseId,opengameData.data.club, callback))
    }

    const applyToGame = () => {
        dispatch(addPlayerToOpengameApplied(currentUser.uid, opengameData.data.firebaseId, opengameData.data.club, (bool, context) => {
            console.log(bool, context)
        }))
    }

    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >

                {opengameData.isLoaded == true?
                <View style={styles.container}>
                    
                    <ReservationComponent
                    managedMarketData={opengameData}
                    dispatchActionForChanges={dispatchChanges}
                    disabled={!isEditor()}
                    />
                    <PrevMarket marketData={opengameData.data} setMarketId={props.setMarketId}/>
                    
                    <GamePlayers
                    gameType={opengameData.data.gameType}
                    gamePlayers={opengameData.data.gamePlayers}
                    players={opengameData.data.players}
                    type={opengameData.data.type}
                    appliedPlayers={opengameData.data.appliedPlayers}
                    notifiedPlayers={opengameData.data.notifiedPlayers}
                    dispatchActionForChanges={dispatchChanges}
                    dragEnabled={isEditor()}//currentUser.uid == testingReservation.mainUid || currentUser.isAdmin == true || currentUser.isGod == true
                    applyMethod={applyToGame}
                    selectableType={isEditor()}
                    reservationOwner={opengameData.data.uid}
                    visible={true}
                    />
                    <MessageComponent
                    managedMarketData={opengameData}
                    dispatchActionForChanges={dispatchChanges}
                    disabled={!isEditor()}
                    />
                    <CommentsComponent
                    comentable={opengameData.data.firebaseId?true:false}
                    messages={opengameData.data.messages}
                    addOrEditMessage={addMessage}
                    />
                    <ButtonComponent
                    isChanged={opengameData.isChanged}
                    isAllowedToEdit={isAllowedToEdit(currentUser, opengameData.data.uid, opengameData.data.status)}
                    opengameData={opengameData}
                    firebaseId={opengameData.data.firebaseId}
                    currentUser={currentUser}
                    setMarketId={props.setMarketId}
                    />
                </View>
                :
                <ActivityIndicator size={"large"} />}

        </KeyboardAwareScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
})

export default ManageOpengame