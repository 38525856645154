import React, { useState, createRef, useEffect } from 'react'
import { Image, Text, TextInput, Pressable, View, Platform, FlatList, DeviceEventEmitter } from 'react-native'
import styles from './styles';
import { connect, shallowEqual, useSelector } from 'react-redux'
import RenderContact from '../../components/Contact'
import { searchContacts } from '../../store/actions/actionsContacts';
import { queryreservationsByMarketId, queryUserReservations } from '../../store/actions/actionsReservationsNew';
import { dateToTimeString, monthString } from '../../utilities/funcs';
import { updateManagedMarketByReservation } from '../../store/actions/actionsMarketNew';
import FormEntry from '../../components/FormEntryNew';
import { weekdays } from '../../utilities/constants';
import { mainFont } from '../../utilities/globalStyles';
import { RootStore } from '../../store/reducers/rootReducer';
import { reservation } from '../../../functions/src/interfaces';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { Route, useNavigation } from '@react-navigation/native';
import i18n from '../../i18n';
import { ReservationStatuses } from '../../../functions/src/interfaces';

type Props = {
    route: Route<'ReservationsListScreen', {type?: "collisions" | "user" | "longreservation" | "freecourts", user: string, club: string, marketId: string}>
}
function ReservationsListScreen(props:Props) {
    const {user, type, club, marketId} = props.route.params
    //const type = props.route.params.type
    const navigation = useAppNavigation()
    const [reservations, setReservations] = useState([])
    //console.log('ReservationsListScreen props', props)
    const userData = useAppSelector((state: RootStore) => state.auth.currentUserData, shallowEqual)
    const queriedCollisions = useAppSelector((state: RootStore) => state.market.queriedCollisions, shallowEqual)
    const queriedUserReservations = useAppSelector((state: RootStore) => state.reservations.queriedUserReservations)
    const queriedReservationsByIds = useAppSelector((state: RootStore) => state.reservations.queriedReservationsList, shallowEqual)
    const usersData = useAppSelector((state: RootStore) => state.users.usersData, shallowEqual)

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (user) {
            dispatch(queryUserReservations(userData.club,user,()=>{}))
        } else if (type == "collisions") {


        } else if (type == "longreservation") {
            dispatch(queryreservationsByMarketId(club, marketId))
        } 
        
    }, [user, userData.club])


    /* useEffect(() => {
        setReservations(Object.values(props.queriedUserReservations?props.queriedUserReservations:{})) 
    }, [props.queriedUserReservations]) */
    const reservationsByType = (tp: string): (reservation & {collisions?: reservation[]})[] => {
        if (tp == "collisions") {
            return queriedCollisions.data.sort((a,b) => a.dateFrom > b.dateFrom ? 1 : -1)
        } else if (tp == "user") {
            return Object.values(queriedUserReservations?queriedUserReservations.data:{}).filter(item => [ReservationStatuses.approved, ReservationStatuses.blocked, ReservationStatuses.open].includes(item.status)).sort((a,b) => a.dateFrom > b.dateFrom ? 1 : -1)
        } else if (tp == "longreservation") {
            return queriedReservationsByIds.data.sort((a,b) => a.dateFrom > b.dateFrom ? 1 : -1)
        }
    }

    useEffect(() => {
        return () => {
            DeviceEventEmitter.removeAllListeners("event.mapReservationToOpenGame")
        };
    }, []);
    /* const [value, setValue] = useState("")
    const inputRef = createRef();
    const [searchData, setSearchData] = useState([]) 

    const textBoxWrap = (text) => {
        if (props.searchContacts) {
            return props.searchContacts(text)
        } else {
            console.log("No action for textbox")
        }
    }

    useEffect(() => {
        let keys = Object.keys(props.contactsStore)
        let dataArray = []
        keys.forEach((user) => {
            if (user != props.auth.uid) {
                dataArray.push(props.contactsStore[user])
            }

        })
        setSearchData(dataArray)
    }, [props.contactsStore]) */
    
    return (
        <View style={styles.container}>
            {/* <TopBar textBoxAction={() => {}} navigation={props.navigation}/> */}
            
            <View style={styles.listContainer}>
            <FlatList style={{ flexDirection: "column"}}
                data={reservationsByType(type)}
                renderItem={({item}) => { 
                    //console.log(item)
                    return (
                        <Pressable
                        onPress={
                            () => {
                                
                                if (type == "user") {
                                    dispatch(updateManagedMarketByReservation(item))
                                    /* let data = {...item}
                                    DeviceEventEmitter.emit("event.mapReservationToOpenGame", data)*/
                                    navigation.goBack() 

                                } else if (type == "longreservation") {
                                    navigation.navigate('ManageReservationScreen', {firebaseId: item.firebaseId, club: item.club})
                                }
                                
                                
                                  /* props.selectPlayerToReservation(item.uid, props.route.params.path, (res) => {
                                      if (res == true) {
                                          props.navigation.pop()
                                      }
                                  }) */
                                }
                        }
                        >
                            <FormEntry 
                            caption={monthString(item.dateFrom) + ' ' + weekdays[item.dateFrom.getDay()] }
                            editable={true}
                            >
                                <View style={{alignSelf: "flex-end"}}>
                                    <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>{dateToTimeString(item.dateFrom) + ' ' + dateToTimeString(item.dateTo) + ' ' + i18n.t(item.status)}</Text>
                                </View>
                                
                            </FormEntry>
                            {item.collisions?
                                <View style={{width: "100%", alignItems: "flex-end"}}>
                                    {item.collisions.map((col) => {
                                        return (
                                        <Pressable
                                        onPress={() => navigation.navigate('ManageReservationScreen', {firebaseId: col.firebaseId, club: col.club})}>
                                        <Text maxFontSizeMultiplier={1.2} key={col.firebaseId} style={{color: "red", fontFamily: mainFont}}>
                                            {(usersData[col.mainUid] ? usersData[col.mainUid].first_name + ' ' +  usersData[col.mainUid].family_name: "Unknown" )+ ' ' + dateToTimeString(col.dateFrom) + ' ' + dateToTimeString(col.dateTo)}
                                        </Text>
                                        </Pressable>
                                        )
                                    })}
                                </View>:null}
                        </Pressable>
                    )
                }}
                keyExtractor={(item) => item.firebaseId?item.firebaseId:monthString(item.dateFrom)}
                
                /> 
            </View>
        </View>
    )
}


export default ReservationsListScreen



