import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { FlatList, Image, Text, Pressable, TouchableWithoutFeedback, View } from 'react-native';

import { StyleSheet } from 'react-native';
import { News, user, UserRoles } from '../../../../functions/src/interfaces';
import ClicableUserAvatar from '../../../components/ClickableUserAvatar';
import FormHeading from '../../../components/FormHeading';
import MessagesCount from '../../../components/MessagesCount';
import i18n, { tokens } from '../../../i18n';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { RootStore } from '../../../store/reducers/rootReducer';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import { normalize } from '../../../utilities/constants';
import { monthString } from '../../../utilities/funcs';
import { mainColor, mainFont, mainFontBold } from '../../../utilities/globalStyles';
import { getUserData } from '../../../store/actions/actionsUsers';




type Props = {
    item: News,
    currentUser: UserOrClub<UserRoles>,
    removePlayer: (uid: string, firebaseId: string, club: string, callback: (bool: boolean, context: any) => void) => any,
    addPlayer: (uid: string, firebaseId: string, club: string, callback: (bool: boolean, context: any) => void) => any,
    index: number,
}

const CreatorName: React.FC<{user: UserOrClub}> = (props) => {
    if (props.user?.type == "user") {
        return (
            <Text maxFontSizeMultiplier={1.2} style={styles.headerText}>{props.user.first_name + ' ' + props.user.family_name}</Text>
        )
    } else if (props.user?.type == "clubs") {
        return (
            <Text maxFontSizeMultiplier={1.2} style={styles.headerText}>{props.user.club_name}</Text>
        )
    }
}

const NewsEntry: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    const creator: UserOrClub = useAppSelector((state: RootStore) => {
        let creator = state.users.usersData[props.item.uid]
        if (creator) {
            return creator
        } else {
            return null
        }
        
    }, (a,b) => JSON.stringify(a) == JSON.stringify(b))
    
    useEffect(() => {
        if (creator === null) {
            dispatch(getUserData(props.item.uid, () => {}))
        }
    }, [props.item.uid, creator])

    return (<View style={styles.container}>
        <TouchableWithoutFeedback style={styles.touchable}
        onPress={() => navigation.navigate('ManageNewsScreen', {newsId: props.item.firebaseId, clubId: props.item.club})}
        >
        <View style={styles.containerInner}>
            <View style={styles.headerContainer}>
                <CreatorName user={creator}/>
                <Text maxFontSizeMultiplier={1.2} style={styles.headerText}>{monthString(props.item.dueDate)}</Text>
            </View>
            {props.item.newsPhoto?<Image style={styles.mainImage} source={{uri: props.item.newsPhoto}} />:null}
            {props.item.text?<Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFont, fontSize: normalize(16)}}>{props.item.text}</Text>:null}
            {props.item.appliedMembers?
            <View style={{flexDirection: "row", justifyContent: "space-between"}}>
            
            <View style={styles.playersListContainer}>
            <FormHeading heading={i18n.t(tokens.app.components.newsEntity.applyHeading)} size={normalize(16)}/>
            <FlatList horizontal 
            data={props.item.appliedPlayers || []}
            renderItem={(item) => <ClicableUserAvatar size={40} uid={item.item} onPress={() => navigation.navigate('Profile', {uid: item.item})}/>}
            />
            <Pressable
            onPress={() => {
                console.log('pozice: ', (props.item?.appliedPlayers || []).find(item => item == props.currentUser.uid))
                if ((props.item?.appliedPlayers || []).find(item => item == props.currentUser.uid)) {
                    dispatch(props.removePlayer(props.currentUser.uid, props.item.firebaseId, props.item.club, (bool, context) => console.log(JSON.stringify(context))))
                } else {
                    dispatch(props.addPlayer(props.currentUser.uid, props.item.firebaseId, props.item.club, (bool, context) => console.log(JSON.stringify(context))))
                }
                
            }}
            >
                <Text maxFontSizeMultiplier={1.2} style={{color: mainColor}}>
                    {(props.item?.appliedPlayers || []).find(item => item == props.currentUser.uid)?
                    i18n.t(tokens.app.components.newsEntity.unapplyButton):
                    i18n.t(tokens.app.components.newsEntity.applyButton)}
                    </Text>
            </Pressable>
            </View>
            <View style={{
                flexDirection: "column",
                justifyContent: "flex-end"
            }}>
                <MessagesCount messages={props.item.messages}/>
            </View>
            </View>:null}
            </View>
        </TouchableWithoutFeedback></View>)
    
}


const styles = StyleSheet.create({
    container: {
        marginBottom: 5,
        paddingBottom: 5,
        marginLeft: 10,
        marginRight: 10,
    },
    touchable:{
        width: "100%", 
    },
    containerInner: {
        backgroundColor: "white",
        padding: 5, 
        borderRadius: 15,
    },
    headerContainer:{
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-between",
        borderBottomColor: "lightgrey",
        borderBottomWidth: 2
    },
    headerText:{
        fontFamily: mainFontBold, 
        fontSize: normalize(16)
    },
    mainImage: {
        width: '100%',height: undefined,
        aspectRatio: 1,
        alignSelf: "center"
    },
    playersListContainer: {
        flexDirection: "column",
        width: "50%",
    }
})


export default NewsEntry

