import React from "react";
import { StyleSheet, Text, View } from "react-native";


type Props = {
    message: string,
    child?: JSX.Element,
}

const FlexedMessage: React.FC<Props> = (props) => {
    return (
        <View style={styles.container}>
            <View style={styles.containerInner}>
                <Text maxFontSizeMultiplier={1.2}>{props.message}</Text>
                {props.child?props.child:null}
            </View>
        </View>
    )
}

const styles = StyleSheet.create(
    {
        container: {
            flex: 1,
            paddingHorizontal: 10,
            paddingVertical: 5,
            
            
        },
        containerInner: {
            flex: 1,
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5
        }

    }
)

export default FlexedMessage