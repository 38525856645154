import React, { ComponentType, useEffect, useRef, useState } from "react"
import { Alert, AppState, Platform, Text, View } from "react-native"
import { shallowEqual } from "react-redux"
import { loggedInSubscriptions, resendVerificationUser, signOut, subscribeToSeasons, updateToken } from "../../store/actions/actionsAuth"
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks"
import { RootStore } from "../../store/reducers/rootReducer"
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import Constants from 'expo-constants';
import i18n, { tokens } from "../../i18n"
import { NotificationsActions } from "../../store/reducers/notificationsReducer"
import { clubmembersSubscribe, subscriptionsSubscribe, subscriptionsTourBasedSubscribe } from "../../store/actions/actionsUsers"
import { subscribeForNotifications } from "../../store/actions/actionsNotifications"
import { showToast } from "../../utilities/toastcontroller"
import { Subscription } from "expo-modules-core"
//import StripeProv from "../../components/StripeProv"
import MyTabBar from "../../components/TabBar"
import ReservationScreen from "../ReservationScreen/ReservationScreen"
import ContactsScreen from "../ContactsScreen/ContactsScreen"
import MarketScreen from "../MarketScreen/MarketScreen"
import RankingScreen from "../RankingScreen/RankingScreen"
import NewsScreen from "../NewsScreen/NewsScreen"
import WebWrapper from "../../components/WebWrapper"
import { globalStyleSheet } from "../../utilities/globalStyles"
import LoginScreen from "../LoginScreen/LoginScreen"
import ActionOverlay from "../../components/ActionOverlay"
import { NotificationTypes, pushNotification, Season, UserRoles } from "../../../functions/src/interfaces"
import { getCurrentAuthState, getCurrentUserWithRoles } from "../../store/selectors"
import { NotificationContent } from "expo-notifications"
import RightTopBar from "../../components/RightTopBar"
import { UserOrClub } from "../../store/reducers/usersReducer"

type Props = {
    loggedIn: boolean
}

Notifications.setNotificationHandler({
    handleNotification: async () => {
      return {
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
      };
    },
  });



export const _handleNotification = (notification: NotificationContent, navigation) => {

    let data = notification.data;
    console.log('Notification catched data', notification)
    //Alert.alert(JSON.stringify(data))
    if (data.notification_type === NotificationTypes.news) {
        // navigate to MessageScreen with data.id as param
        try {
            navigation.navigate('ManageNewsScreen', { newsId: data.id, clubId: data.clubId })
        }
        catch (e) {
            Alert.alert(JSON.stringify([e, data.id, navigation]))
        }
    } else if (data.notification_type === NotificationTypes.market) {
        // navigate to MessageScreen with data.id as param
        try {
            navigation.navigate('ManageMarket', { marketId: data.id, club: data.clubId })
        }
        catch (e) {
            Alert.alert(JSON.stringify([e, data.id, navigation]))
        }
    } else if (data.notification_type === NotificationTypes.reservation) {
        // navigate to MessageScreen with data.id as param
        try {
            navigation.navigate('ManageReservationScreen', { firebaseId: data.id, club: data.clubId })
        }
        catch (e) {
            Alert.alert(JSON.stringify([e, data.id, navigation]))
        }
    } else if (data.notification_type === NotificationTypes.payments) {
        // navigate to MessageScreen with data.id as param
        try {
            navigation.navigate('PaymentScreen', { type: "payment", club: data.clubId, blockedMethods: [], paymentId: data.id })
        }
        catch (e) {
            Alert.alert(JSON.stringify([e, data.id, navigation]))
        }
    } else {
        // do something else based on the type or...
    }
};

type RouteObject = { 
    [key: string]: { 
        icon: string, 
        badge: number, 
        onPress: () => void, 
        component: ComponentType<any>, 
        online: boolean, 
        offline: boolean 
    } 
}

const Tab = createBottomTabNavigator();

async function registerForPushNotificationsAsync() {
    let token;
  
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      // Learn more about projectId:
      // https://docs.expo.dev/push-notifications/push-notifications-setup/#configure-projectid
      // EAS projectId is used here.
      try {
        const projectId =
          Constants?.expoConfig?.extra?.eas?.projectId ?? Constants?.easConfig?.projectId;
        if (!projectId) {
          throw new Error('Project ID not found');
        }
        token = (
          await Notifications.getExpoPushTokenAsync({
            projectId,
          })
        ).data;
        console.log(token);
      } catch (e) {
        token = `${e}`;
      }
    } else {
      alert('Must use physical device for Push Notifications');
    }
  
    return token;
  }

function HomeComponent(props: Props): JSX.Element {
    const selectedClub: string = useAppSelector(state => state.auth.selectedClub, shallowEqual)
    const activeSeason: Season = useAppSelector(state => state.auth.activeSeason, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const currDate: string = useAppSelector(state => state.auth.currDate, shallowEqual)
    //console.log('shfjdshfjdsfhds', currDate)
    const { isEmpty, user, isLoaded } = getCurrentAuthState()
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    //const isLoggingIn = useAppSelector((state: RootStore) => state.auth.isLoggingIn, shallowEqual)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const [expoPushToken, setExpoPushToken] = useState<any>('');
    const [notification, setNotification] = useState(null);
    const [channels, setChannels] = useState<Notifications.NotificationChannel[]>([]);

    const notificationListener = useRef<Notifications.Subscription>();
    const responseListener = useRef<Notifications.Subscription>();
    const [lastNotifIdent, setLastNotifIdent] = useState(null)

    //const user = auth.uid ? auth.uid : null
    
  

    useEffect(() => {
        //console.log('all my props', props, JSON.stringify(user))
        var subs = null
        if (isEmpty == false && user) {
            registerForPushNotificationsAsync().then(token => token && setExpoPushToken(token));

            if (Platform.OS === 'android') {
                Notifications.getNotificationChannelsAsync().then(value => setChannels(value ?? []));
            }
            Notifications.getExpoPushTokenAsync({
                    projectId: Constants?.expoConfig?.extra?.eas?.projectId,
                }).then(token => {
                    //console.log('new token' + token)
                    setExpoPushToken(token.data)
                    //console.log('User debug', user && user.uid)
                    if (user && user.uid) dispatch(updateToken({ uid: user.uid, token: token.data, locale: i18n.locale }, (result) => console.log('update tokenu' + JSON.stringify(result))))
                    // This listener is fired whenever a notification is received while the app is foregrounded
                    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                        console.log('Prisla test notif' + JSON.stringify(notification.request?.content?.data))
                        showToast(notification.request?.content?.body)
                        setNotification(notification);
    
                    });
    
                    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
                    
                    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                        console.log("Response listener" + JSON.stringify(response));
                        const data = response.notification.request.content
                        if (Platform.OS == "android") {
                            _handleNotification(data, navigation)
                        }
                    });
                    
                    
                    console.log('Notifs')
                    return () => {
                        console.log('Removing notification subscriptions')
                        Notifications.removeNotificationSubscription(notificationListener.current);
                        Notifications.removeNotificationSubscription(responseListener.current);
                    };
                })
                    .then((unsub) => subs = unsub)
                    .catch((e) => {
                        console.log("Response listener error: " + e.message)
                    });

            
            
        }

        return () => {
            if (subs) {
                subs()
            }
        }

    }, [isEmpty, user]);
    const clubs = useAppSelector(state => state.auth.allClubs, (a, b) => JSON.stringify(a) == JSON.stringify(b))

    useEffect(() => {
        //let unsub = null
        let clubmembers = null
        let notifications = null
        //console.log('Test for params: ', selectedClub, isEmpty, user, isLoaded)
        if (selectedClub && !isEmpty) {
            
            clubmembers = dispatch(clubmembersSubscribe(selectedClub, () => { }))
            notifications = dispatch(subscribeForNotifications())
        } else {
            //unsub = () => { }
            clubmembers = () => { }
            notifications = () => { }
        }

        return () => {
            console.log('Unsubscribing club subscriptions')
            //unsub()
            clubmembers()
            notifications()
        }
    }, [selectedClub, isEmpty])

    useEffect(() => {
        let unsub = null


        if (activeSeason && !isEmpty && selectedClub && activeSeason.tour) {
            //console.log('Test for params: ', activeSeason)
            unsub = dispatch(subscriptionsTourBasedSubscribe(selectedClub, activeSeason.tour))

        } else {
            unsub = () => { }

        }

        return () => {
            console.log('Unsubscribing active season subscriptions tour')
            unsub()

        }
    }, [activeSeason?.firebaseId, isEmpty])

    useEffect(() => {
        let unsub = null

        
        if (!isEmpty && selectedClub && currDate) {
            //console.log('Test for params: ', activeSeason)
            let date = new Date(JSON.parse(currDate))
            //console.log('sdfsdfdsfds', date)
            unsub = dispatch(subscriptionsSubscribe(selectedClub, date))

        } else {
            unsub = () => { }

        }

        return () => {
            console.log('Unsubscribing active season subscriptions float')
            unsub()

        }
    }, [activeSeason?.firebaseId, isEmpty, currDate])

    useEffect(() => {
        var unsub = []
        if (user?.uid) {
            unsub = dispatch(loggedInSubscriptions(user.uid))
        }
        return () => {
            console.log('Unsubscribing logged in subscriptions')
            unsub.forEach((val) => val())
        }
    }, [user])




    const [isVerified, setIsVerified] = useState(true)
    const appState = useRef(AppState.currentState);
    const [appStateVisible, setAppStateVisible] = useState(appState.current);
    //const [subscription, setSubscription] = useState(() => {})

    const [routes, setRoutes] = useState<RouteObject>({
        [i18n.t(tokens.app.tabs.reservations)]: {
            icon: "calendar-outline",
            badge: 0,//st.reservations.length, 
            onPress: () => dispatch({ type: NotificationsActions.notificationsSubscriptionLastRead, data: { reservations: new Date } }),
            component: ReservationScreen,
            online: true,
            offline: true,
        },
        [i18n.t(tokens.app.tabs.contacts)]: {
            icon: "people-outline",
            badge: 0,
            onPress: () => { },
            component: ContactsScreen,
            online: true,
            offline: false,
        },
        [i18n.t(tokens.app.tabs.market)]: {
            icon: "search-circle-outline",
            badge: 0,//st.market.length, 
            onPress: () => dispatch({ type: NotificationsActions.notificationsSubscriptionLastRead, data: { market: new Date } }),
            component: MarketScreen,
            online: true,
            offline: false,
        },
        [i18n.t(tokens.app.tabs.ranking)]: {
            icon: "trophy-outline",
            badge: 0,
            onPress: () => { },
            component: RankingScreen,
            online: true,
            offline: false,
        },
        [i18n.t(tokens.app.tabs.news)]: {
            icon: "newspaper-outline",
            badge: 0,//st.news.length, 
            onPress: () => dispatch({ type: NotificationsActions.notificationsSubscriptionLastRead, data: { news: new Date } }),
            component: NewsScreen,
            online: true,
            offline: false,
        },
        [i18n.t(tokens.app.tabs.login)]: {
            icon: "people-outline",
            badge: 0,
            onPress: () => { },
            component: LoginScreen,
            online: false,
            offline: true,
        }
    } as any)
    const badges = useAppSelector(state => state.notifications.notificationBadges, (a, b) => JSON.stringify(a) == JSON.stringify(b))

    useEffect(() => {
        //console.log('Nove notifikace', badges)
        setRoutes(prev => {
            let out = { ...prev }
            out[i18n.t(tokens.app.tabs.reservations)] = { ...out[i18n.t(tokens.app.tabs.reservations)], badge: badges.reservations.length }
            out[i18n.t(tokens.app.tabs.market)] = { ...out[i18n.t(tokens.app.tabs.market)], badge: badges.market.length }
            out[i18n.t(tokens.app.tabs.news)] = { ...out[i18n.t(tokens.app.tabs.news)], badge: badges.news.length }
            return out
        })
    }, [badges])

    const filterRoutes = (routes: RouteObject, type: "online" | "offline"): RouteObject => {
        return Object.entries(routes).reduce((prev, curr) => {
            if (type == "offline") {
                if (curr[1].offline == true) {
                    return { ...prev, [curr[0]]: curr[1] }
                } else {
                    return { ...prev }
                }
            } else if (type == "online") {
                if (curr[1].online == true) {
                    return { ...prev, [curr[0]]: curr[1] }
                } else {
                    return { ...prev }
                }
            }
        }, {})
    }

    console.log('Rendering')
    /* if (currentUser && currentUser?.type == "clubs" && (currentUser?.activeUntil <= new Date || !currentUser?.activeUntil)) {
        return (<View style={globalStyleSheet.container}>
            <RightTopBar title={"Home screen"}/>
            <WebWrapper>
                <View style={globalStyleSheet.container}>
                    <Text>Klub neaktivní kontaktujte správce na mirapolacek@gmail.com</Text>
                </View>
            </WebWrapper>
            </View>
        )
    } else  */if (isVerified == false && props.loggedIn == true) {
        return (
            <WebWrapper>
                <View style={globalStyleSheet.container}>
                    <View
                        style={{
                            width: "80%",
                            minHeight: 150,
                            alignSelf: "center",
                            backgroundColor: "white",
                            borderColor: "darkgrey",
                            borderWidth: 1,
                        }}
                    >

                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.footerText}>
                                {i18n.t(tokens.app.screens.unverified.unverifiedText, { email: user.email })}
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.footerText}>{i18n.t(tokens.app.screens.unverified.resendMessage)}
                                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.footerLink}
                                    onPress={() => {
                                        dispatch(resendVerificationUser((res) => { }))
                                    }}>{i18n.t(tokens.app.screens.unverified.resedVerifEmail)}</Text>
                            </Text>
                        </View>

                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.footerText}>{i18n.t(tokens.app.screens.unverified.logoutMessage)}
                                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.footerLink}
                                    onPress={() => {
                                        dispatch(signOut())
                                    }}>{i18n.t(tokens.app.screens.unverified.logout)}</Text>
                            </Text>
                        </View>


                    </View>
                </View>
            </WebWrapper>)
    } else {
        return (
            //<StripeProv
            //    clubData={clubs?.[selectedClub]}
            //>
                
                <Tab.Navigator id={undefined} screenOptions={
                    {
                        headerShown: true,
                        header: (props) => <RightTopBar title={props.route?.name} route={props.route}/>,
                        
                    }
                }
                    tabBar={props => <MyTabBar icons={filterRoutes(routes, isEmpty ? "offline" : "online")} state={props.state} descriptors={props.descriptors as any} navigation={props.navigation} />}>
                    {Object.entries(filterRoutes(routes as any, isEmpty ? "offline" : "online")).map(([key, value]) => {
                        return (
                            <Tab.Screen
                                key={key}
                                name={key}
                                component={value.component}
                                initialParams={{basicButtons: true}}
                                //initialParams
                            /* options={{ tabBarBadge: badges.reservations.length }} */
                            />
                        )
                    })

                    }

                </Tab.Navigator>
            //</StripeProv>
        )
    }
}


export default React.memo(HomeComponent, (a,b) => a.loggedIn == b.loggedIn)