import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable } from 'react-native'

import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Market, MarketLongreservation, MarketOpengame, MarketReservation, user } from '../../../../functions/src/interfaces';
import { addPlayerToOpengameApplied } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch } from '../../../store/hooks';
import { mainColor, mainFontBold } from '../../../utilities/globalStyles';


type Props = {
    uid: string,
    marketId: string,
    club: string,
    caption: string,
    action?: () => void
}

const MarketEntryButton: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)
    const dispatch = useAppDispatch()
    return (
        
        <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFontBold, color: mainColor}} 
        onPress={() => dispatch(addPlayerToOpengameApplied(props.uid, props.marketId, props.club, (bool, context) => {console.log(JSON.stringify(context))}))}>
            {props.caption}
        </Text>
        
    )
    
}


const styles = StyleSheet.create({
       
})


export default MarketEntryButton

