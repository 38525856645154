import * as Font from 'expo-font';
import 'react-native-reanimated';
import { useEffect, useState } from 'react';
import 'react-native-gesture-handler';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { decode, encode } from 'base-64';
import rootReducer from './src/store/reducers/rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { Alert, Platform } from 'react-native';
import { enableFreeze, enableScreens } from 'react-native-screens';
import thunk from 'redux-thunk';
import { getFileStorage, getFirebase, getFirestore, auth } from './src/store/firebase/config';
//import StripeProv from './src/components/StripeProv';
import { getCalendarPermissions } from './src/utilities/calendar';
import { Provider } from 'react-redux';
import ConnectionScreen from './src/screens/ConnectionScreen/ConnectionScreen';
import ErrorBoundary from 'react-native-error-boundary';
import ActionOverlay from './src/components/ActionOverlay';
import { StatusBar } from 'expo-status-bar';
import FallbackComponent from './src/components/ErrorFallbackComponent/FallbackComponent';
//import VersionCheck from 'react-native-version-check';
import * as Updates from 'expo-updates';

export function getEnvironment() {
  
}
//import { ReactReduxInternetConnection } from 'react-redux-internet-connection';
enableFreeze(true)
enableScreens(true)
/* 
const checkUpdateNeeded = async () => {
  if (Platform.OS == "android" || Platform.OS == "ios") {
    let updateNeeded = await VersionCheck.needUpdate();
    if (updateNeeded.isNeeded) {
        //Alert the user and direct to the app url
        Alert.alert('Need update')
    }
  } else {
    console.log('Dont check version on web')
  }
    
}
 */




export const store = configureStore({
  reducer: rootReducer, 
  middleware: [thunk.withExtraArgument({getFirestore,getFirebase,getFileStorage})]
    }
);


if (!global.btoa) {  global.btoa = encode }
if (!global.atob) { global.atob = decode }



function App() {
  
  
  const [user, setUser] = useState(null)
  const token = null
  //const navigation = useAppNavigation()

  const [loaded, setLoaded] = useState(false);

  const loadFonts = async () => {
    await Font.loadAsync({
      'AvertaStd-Semibold': require('./assets/fonts/AvertaStd-Semibold.otf'),
      'AvertaStd-Regular': require('./assets/fonts/AvertaStd-Regular.otf'),
    });
    setLoaded(true);
  };



  useEffect(() => {
    loadFonts();
    getCalendarPermissions();
    //checkUpdateNeeded();
    
  });
  
  /**
   * 
   * const MyFallbackComponent = ({ componentStack, error }) => {
    const { message, stack } = error; // <- this is the stack you want
    return <div>stack</div>
};
   */
const {
  currentlyRunning,
  isUpdateAvailable,
  isUpdatePending
} = Updates.useUpdates();

useEffect(() => {
  if (isUpdatePending) {
    // Update has successfully downloaded; apply it now
    Updates.reloadAsync();
  }
}, [isUpdatePending]);

  
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
      <Provider store={store}>
       <SafeAreaProvider style={{backgroundColor:"#00233f"}}>
         
       {loaded ? 
         <SafeAreaView style={{flex: 1}}>
           <StatusBar
           style="light"
           translucent={true}
           hidden={false}
           />
        <ActionOverlay 
         fullScreen={false}
         isVisible={false}
         onBackdropPress={null}
         />
        <ConnectionScreen/>
        
        </SafeAreaView>
        
        : <></>}
        </SafeAreaProvider>
  
      </Provider>
      </ErrorBoundary>
    )
  
  


  
}

export default App