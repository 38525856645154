import React from "react";
import { Pressable } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';

type Props = {
    onPress: () => void,
    icon: any,
    color: string,
    size: number,
}
const IconButton: React.FC<Props> = (props) => {
    return (<Pressable
        style={{alignItems: "center"}}
        onPress={() => props.onPress()}
        >
          <Ionicons 
          name={props.icon}
          color={props.color}
          size={props.size}
          
          />
        </Pressable>)
}

export default IconButton