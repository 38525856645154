import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Alert, Platform, StyleSheet, Text, Pressable, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { Market, MarketLongreservation, MarketPaymentStatuses, MarketReservation, MarketStatuses, MarketTypes, reservation } from '../../../../functions/src/interfaces';
import BigButton from '../../../components/BigButton';
import Overlay from '../../../components/Overlay';
import i18n, { tokens } from '../../../i18n';
import { manageMarketToState, marketDeletionAction } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/reservationReducer';
import { normalize } from '../../../utilities/constants';

type Props = {
  isOverlayVisible: boolean,
  setIsOverlayVisible: (state: any) => void,
  reservationData: StateLoader<MarketLongreservation<Market>>
}

function Dialog(props: Props):JSX.Element {

    //const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    
    const removeReservationAction = (type: 'all' | 'future') => {
       dispatch(marketDeletionAction(props.reservationData.data.club,props.reservationData.data.firebaseId, type))
    }

    //const addToCalendarAction = () => {}

    return (<Overlay isVisible={props.isOverlayVisible} onBackdropPress={() => props.setIsOverlayVisible((prev:{type: string, visible: boolean, data: any}) => {
      return {...prev, visible: !prev.visible}
    })}>
        <View
        style={styles.container}
        >
        <Text>
          {i18n.t(tokens.app.screens.longreservation.longResDialogRemoval)}
        </Text>
        <View style={styles.buttonContainer}>
          <BigButton
          onPress={() => removeReservationAction('future')}
          buttonText={i18n.t(tokens.app.screens.longreservation.longResFuture)}
          />
          <BigButton
          onPress={() => removeReservationAction('all')}
          buttonText={i18n.t(tokens.app.screens.longreservation.longResAll)}
          />
        </View>
        </View>
    </Overlay>
    )
}

const styles = StyleSheet.create({
    container: {
      width: "100%",
      flexDirection: "column",
      padding: 7,
      justifyContent: "center",
      alignItems: "center"
    },
    textStyle: {
      
        textAlign: "right", 
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',
        
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        flex: 1,
        
    }
});

export default Dialog
