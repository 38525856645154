import React, { useEffect } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { shallowEqual, useSelector } from 'react-redux';
import ActionOverlay from '../../components/ActionOverlay';

//mport { LoginScreen, HomeScreen, RegistrationScreen, ContactsScreen } from '../../screens'
import { getSelectedClub, subscribeToClubs } from '../../store/actions/actionsAuth';
import { useAppDispatch } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { getCurrentAuthState } from '../../store/selectors';
import { secondColor } from '../../utilities/globalStyles';
import HomeScreen from '../HomeScreen/HomeScreenNew';

type Props = {

}

function IndexScreen(props: Props) {
    const dispatch = useAppDispatch()
    //console.log('clubs: ' + JSON.stringify(props.clubs))
    /*
    http://react-redux-firebase.com/docs/recipes/auth.html#wait-for-auth-to-load
    */
    
    const { isEmpty, user, isLoaded } = getCurrentAuthState()
    const selectedClub = useSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    
    
    useEffect(() => {
        let unsubscribeClubs = dispatch(subscribeToClubs())

        return () => unsubscribeClubs()
    }, [selectedClub])

    useEffect(() => {
        if (user?.uid) {
            dispatch(getSelectedClub({isEmpty, uid: user?.uid}))
        }
        
    }, [isEmpty, user?.uid])

    console.log('Rendering index')
    /* const connection = useSelector(state => state.internet.connection)
    
    if (connection == false) {
        return <View><Text maxFontSizeMultiplier={1.2}>Offline</Text></View>
    } else*/  
    //TODO: loaded?

    return (<>
    {/* <ActionOverlay 
    isVisible={!isLoaded}
    onBackdropPress={() => {}}
    fullScreen={false}
    /> */}
    <HomeScreen loggedIn={!isEmpty}/>
    </>)
    
    
  }




export default React.memo(IndexScreen, (a,b) => true)