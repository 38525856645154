
import { standardizeDates } from "../../utilities/funcs"
import { AppDispatch, AppThunk, RootState } from "../hooks"
import { NotificationsActions } from "../reducers/notificationsReducer"

export const subscribeForNotifications = (): AppThunk<() => void> => {
    return (dispatch: AppDispatch, getState: () => RootState, { getFirebase, getFirestore }) => {
        //console.log("Callung subscribe for res")

        
        const fb = getFirebase()
        const firestoreuid = fb.auth().currentUser.uid
        const rootRef = getFirestore()
        const entityRef = rootRef.collection('usersprivate').doc(firestoreuid)
        .collection('notifications').orderBy('updateDate','desc').limit(20);
        //console.log("Ahoj kurva", club)
        var unsubscribe = entityRef.onSnapshot(
            (querySnapshot) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log('queried',doc.doc.data())
                    let data = standardizeDates(doc.doc.data()) 
                    let docType = doc.type
                    js[docType][doc.doc.id] = data

                })
                
                //console.log(js)
                dispatch({type: NotificationsActions.notificationsSubscriptionChange, data: js})
            },
            (error) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )
        return unsubscribe


    }
}