import React from "react";
import { Switch, View } from "react-native";
import { GameTypes } from "../../../../functions/src/interfaces";
import FormEntry from "../../../components/FormEntryNew";
import FormHeading from "../../../components/FormHeading";
import i18n, { tokens } from "../../../i18n";
import { gameTypes } from "../../../utilities/constants";

type Props = {
    gameTypeSetting: {[key:string]: boolean},
    dispatchChange: (data: any) => void
}

function AllowedGameTypes(props: Props) {
    return (
        <View>
            <FormHeading 
            heading={i18n.t(tokens.app.screens.manageClub.manageClubAllowedGameTypes)}
            />
            {Object.entries(gameTypes).map(([key, value]) => {
                return (
                    <FormEntry
                    caption={value}
                    editable={true}
                    key={key}
                    >
                        <Switch 
                        value={props.gameTypeSetting?.[key] || false}
                        onValueChange={(value) => props.dispatchChange(
                            {
                                allowedGameTypes: {
                                    ...props.gameTypeSetting,
                                    [key]: value
                                }
                            }
                        )}
                        />
                    </FormEntry>
                )
            })} 
        </View>
    )
}

export default AllowedGameTypes