import React from 'react';
import { StyleSheet, Pressable } from 'react-native';

import UserAvatar, { AvatarProps } from './UserAvatar';

type Props = {
  onPress: () => void,
  disabled?: boolean,
} & AvatarProps

interface User {
  uid: string;
  photo?: string;
  first_name: string;
  family_name: string;
}

const ClicableUserAvatar: React.FC<Props> = (props) => {
    //console.log('Uid na playerovi', props.uid)
    
    
    const styles = StyleSheet.create({
        
        entityContainer: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 8,
            borderBottomColor: '#cccccc',
            borderBottomWidth: 1,
            paddingBottom: 8
        },
        entityText: {
            fontSize: 16,
            color: '#333333'
        }
    })

    
    return (
      
      <Pressable
      onPress={props.onPress}
      disabled={props.disabled}
      >
        <UserAvatar {...props} size={props.size} uid={props.uid} />
      </Pressable>
      
    )
}



export default ClicableUserAvatar

