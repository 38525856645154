import React, { useEffect, useState } from 'react'
import { Platform, Text, TextInput, Pressable, View } from 'react-native'
import { normalize } from '../../utilities/constants'
import { hoursMinutesToString, splitTime } from '../../utilities/funcs'
import { validateTextTime } from '../../utilities/validators'
import { TimePickerModal } from 'react-native-paper-dates';

type Props = {
    value: number,
    disabled: boolean,
    onValueChange: (value) => void
}

function TimeInput(props: Props): JSX.Element {
    const selectionDefault = {
        start: 4,
        end: 4
    }
    const [outline, setOutline] = useState('red')
    const [selection, setSelection] = useState(selectionDefault)
    /*const [val,setVal] = useState(() => {
        let [h,m] = splitTime(props.value)
        return new Date(1900,1,1,h,m)
    })
    useEffect(() => {
        props.onValueChange(extractTime(val))
    }, [val]) */

    const [show,setShow] = useState(false)

    const validateKey = (value) => {
        const pattern = '^[0-9]$'
        const condition = new RegExp(pattern, 'g');
        return condition.test(value);
        
    }

    const editStyle = (text) => {
        let textTest = validateTextTime(text)
        //console.log(textTest)
        if (textTest) {
            setOutline('green')
        } else {
            setOutline('red')
        }
    }

    useEffect(() => {editStyle(props.value)}, [props.value])
    

    return (
        <View>
            <TimePickerModal
            visible={show}
            onDismiss={() => setShow(false)}
            onConfirm={({hours,minutes}) => {
                
                props.onValueChange(hours*100 + minutes)
                setShow(false)
                
            }}
            hours={12}
            minutes={14}
        />
        <Pressable
        onPress={() => setShow(val => !val)}
        disabled={props.disabled}
        >
        <Text
        style={{
            width: 55,
            fontSize: normalize(20),
            height:30,
            textAlign: 'right'
        }} 
        >{hoursMinutesToString(splitTime(String(props.value)))}</Text>
        </Pressable>
        </View>
        
      
    )
}

export default TimeInput;