import React from 'react'
import { StyleSheet, Switch, Text, View } from 'react-native'

import { useAppDispatch } from '../store/hooks'
import { normalize } from '../utilities/constants'
import RoleSwitch from './RoleSwitch'

type Props = {
    court: string,
    switchCaption: string,
    value: boolean,
    switchKey: string,
    disabled?: boolean
}

const ExemptionSwitch: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch()

    const extra = {
        borderWidth: props.disabled?0:1
    }
    

  
    return (
        <RoleSwitch
        switchCaption={props.switchCaption}
        value={props.value}
        switchKey={props.switchKey}
        dispatchChange={(data) => dispatch({type: "EDITED_COURT_EXEMPTION_SWITCH", data: {court: props.court, switchKey: props.switchKey, switchValue: data[props.switchKey]}})}
        />
    )
}

const style = StyleSheet.create({
        entityContainer: {
            flexDirection: 'column',
            //justifyContent: 'space-between',
            marginTop: 3,
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 5,
            borderColor: "grey",
            borderWidth: 1,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 3,
            paddingBottom: 3,
            margin: 2,
            minWidth: 100,
            
        },
        textStyle: {
            fontSize: normalize(8)
        },
        switchStyle: { transform: [{ scaleX: .5 }, { scaleY: .5 }] }
    })

export default ExemptionSwitch

