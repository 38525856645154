import React, { useState } from "react";
import { Text, TextInput, View } from "react-native";
import BigButton from "../../../components/BigButton";
import i18n, { tokens } from "../../../i18n";
import { resetPasswordEmail, signIn } from "../../../store/actions/actionsAuth";
import { useAppDispatch, useAppNavigation } from "../../../store/hooks";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import styles from '../styles';
type Props = {
    source: "market" | "entry"
}
function LoginForm(props: Props) {
    const {source} = props
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    
    const onFooterLinkPress = () => {
        navigation.navigate('Registration', {type: props.source == "market" ? "user" : undefined})
    }

    const resetEmailLink = () => {
        dispatch(resetPasswordEmail(email, (bool, context) => {
            if (bool == false) {
                if (context == 'EMAIL_INVALID') {
                    setError(i18n.t(tokens.app.screens.login.emailNotValid))
                } else {
                    setError(i18n.t(tokens.app.screens.login.resetEmailSendingError))
                }
                
            } else {
                setError(i18n.t(tokens.app.screens.login.emailSent))
            }
        }))
    }
    const onLoginPress = () => {
        dispatch(signIn({
            email: email,
            password: password
        }, (bool, context) => {
            console.log('Callback loginu', bool, context)
            if (bool == false) {
                if(typeof context == 'string') setError(context)
                else if (context.code) {
                    if (context.code == 'auth/wrong-password') {
                        setError(i18n.t(tokens.app.screens.login.wrongPassword))
                    } else if (context.code == 'auth/user-not-found') {
                        setError(i18n.t(tokens.app.screens.login.userNotFound))
                    } 
                    
                } else setError(i18n.t(tokens.app.screens.login.loginError))
            }
        }, null))
        
    }

    return (
    <View style={{width: "100%"}}>
    <TextInput maxFontSizeMultiplier={1.2}
        style={styles.input}
        placeholder={i18n.t(tokens.app.screens.login.email)}
        placeholderTextColor="#aaaaaa"
        onChangeText={(text) => setEmail(text)}
        value={email}
        underlineColorAndroid="transparent"
        autoCapitalize="none"
        inputMode="email"
    />
    <TextInput maxFontSizeMultiplier={1.2}
        style={styles.input}
        placeholderTextColor="#aaaaaa"
        secureTextEntry
        placeholder={i18n.t(tokens.app.screens.login.password)}
        onChangeText={(text) => setPassword(text)}
        value={password}
        underlineColorAndroid="transparent"
        autoCapitalize="none"
        
    />
    <View style={{flexDirection: "row", paddingLeft: 10, paddingRight: 10}}>
    <BigButton
    onPress={() => onLoginPress()}
    buttonText={i18n.t(tokens.app.screens.login.submit)}
    />
    </View>
    <View style={globalStyleSheet.footerView}>
        
        <Text maxFontSizeMultiplier={1.2} 
        style={globalStyleSheet.footerText}
        >
            {i18n.t(tokens.app.screens.login.noAccQuestion)}
            <Text maxFontSizeMultiplier={1.2} 
            onPress={onFooterLinkPress} 
            style={globalStyleSheet.footerLink}>
                {i18n.t(tokens.app.screens.login.registerButtonCaption)}
            </Text>
        </Text>
    </View>
    <View 
    style={globalStyleSheet.footerView}>
        <Text maxFontSizeMultiplier={1.2} 
        style={globalStyleSheet.footerText}
        >
            {i18n.t(tokens.app.screens.login.forgotPassQuestion)}
            <Text maxFontSizeMultiplier={1.2} 
            onPress={resetEmailLink} 
            style={globalStyleSheet.footerLink}>
                {i18n.t(tokens.app.screens.login.sendPasswordButton)}
            </Text>
        </Text>
    </View>
    <View style={globalStyleSheet.footerView}>
        <Text maxFontSizeMultiplier={1.2} style={{color: "red"}}>{error}</Text>
    </View>
    </View> )
}


export default LoginForm