//funguje vlastne jako PROVIDER dat

import { createDrawerNavigator, DrawerContentScrollView, DrawerItem, DrawerItemList } from "@react-navigation/drawer";
import React, { useEffect, useState } from 'react';
import { Platform, Text, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { signOut } from '../../store/actions/actionsAuth';

import { NavigationContainer } from '@react-navigation/native';
//import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { isAndroid } from "@freakycoder/react-native-helpers";
import { Badge } from 'react-native-elements';
import { ContactsScreen, RegistrationScreen } from '..';
import i18n, { tokens } from '../../i18n';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { getCurrentAuthState, getCurrentUserWithRoles } from '../../store/selectors';
import { mainColor } from '../../utilities/globalStyles';
import BrowseCalendar from '../BrowseCalendar/BrowseCalendar';
import CameraScreen from '../CameraScreen/CameraScreen';
import CheckoutListScreen from '../CheckoutListScreen/CheckoutListScreen';
import CheckoutScreen from '../CheckoutScreen/CheckoutScreen';
import IndexScreen from '../IndexScreen/IndexScreen';
import ManageClubScreen from '../ManageClubScreen/ManageClubScreen';
import ManageMarketScreen from '../ManageMarketScreen/ManageMarketScreen';
import ManageMyClubsScreen from '../ManageMyClubsScreen/ManageMyClubsScreen';
import ManageNewsScreen from '../ManageNewsScreen/ManageNewsScreen';
import ManageProfileScreen from '../ManageProfileScreen/ManageProfileScreen';
import ManageReservationScreen from '../ManageReservationScreen/ManageReservationScreenNew';
import ManageSeasonScreen from '../ManageSeasonScreen/ManageSeasonScreen';
import NotificationsScreen from '../NotificationsScreen/NotificationsScreen';
import PaymentScreen from '../PaymentScreen/PaymentScreen';
import PaymentScreenQR from '../PaymentScreenQR/PaymentScreenQR';
import PaymentsListScreen from '../PaymentsListScreen/PaymentsListScreen';
import ProfileScreen, { ProfileNavigationParams } from '../ProfileScreen/ProfileScreen';
import ReservationsListScreen from '../ReservationsListScreen/ReservationsListScreen';
import ReservationsStatisticsScreen from '../ReservationsStatisticsScreen/ReservationsStatisticsScreen';
import TestingScreen from '../TestingScreen/TestingScreen';
import { auth } from "../../store/firebase/config";
import { AuthActions } from "../../store/reducers/authReducer";
import { StackNavigationProp } from '@react-navigation/stack';
import ManageLinkedUser from "../ManageLinkedUser/ManageLinkedUser";
import LinkedUsersScreen from "../LinkedUsersScreen/LinkedUsersScreen";
import ManageAllClubsScreen from "../ManageAllClubsScreen/ManageAllClubsScreen";
import SeasonDetailScreen from "../SeasonDetailScreen/SeasonDetailScreen";
import { normalize } from "../../utilities/constants";
import { UserOrClub } from "../../store/reducers/usersReducer";
import { UserRoles } from "../../../functions/src/interfaces";
import AboutAppScreen from "../AboutAppScreen/AboutAppScreen";
import * as Notifications from 'expo-notifications';
import { _handleNotification } from '../HomeScreen/HomeComponent';
import { useAppNavigation } from '../../../src/store/hooks';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { TransitionPresets } from "react-navigation-stack";
import * as Linking from "expo-linking";
import DeleteUser from "../DeleteUser/DeleteUser";

const prefix = Linking.createURL("/");

const linking: any = {
  prefixes: [prefix],
  config: {
    initialRouteName: "StackHome",
    screens: {
      StackHome: "StackHome",
      DeleteUser: "DeleteUser",
    },
  },
};

const Stack = createNativeStackNavigator();

const Drawer = createDrawerNavigator();

type DrawerProps = {

}

const DrawerNavigator = (props: DrawerProps) => {
  const DrawerProps = props
  const dispatch = useAppDispatch()
  const { isEmpty, user, isLoaded } = getCurrentAuthState()
  //console.log('Drawer props', props)
  //const auth = useAppSelector((state: RootStore) => state.firebase.auth, shallowEqual)
  const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
  const roles: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, (a, b) => JSON.stringify(a) == JSON.stringify(b))
  const signOutDispatch = () => dispatch(signOut())
  const notifications = useAppSelector((state: RootStore) => {
    let reducer = state.notifications
    let notifs = Object.values(reducer.subscribedNotifications)
    var out = 0
    for (let i = 0; i < notifs.length; i++) {
        let notification = notifs[i]
        out += notification.updateDate >= reducer.notificationsLastRead.all ? 1 : 0
    }
    return out
}, (a, b) => JSON.stringify(a) == JSON.stringify(b))

const lastNotificationResponse = Notifications.useLastNotificationResponse()
    const navigation = useAppNavigation()
    useEffect(() => {
        if (lastNotificationResponse && Platform.OS == "ios" && !isEmpty) {
            const data = lastNotificationResponse.notification.request.content
            if (data && data.data) {
                //Alert.alert('Abcd' + JSON.stringify(data.data))
                _handleNotification(data, navigation)
                
            }
            
        }
    }, [lastNotificationResponse])

  return (
    <Drawer.Navigator id={undefined} screenOptions={{drawerPosition:'right',headerShown:false}} drawerContent={(props) => {
        //console.log('Drawer.Navigator', props)
        return (
          <DrawerContentScrollView >
            <DrawerItemList state={props.state} navigation={props.navigation} descriptors={props.descriptors}/>
            {!isEmpty?<DrawerItem route={undefined} label={i18n.t(tokens.app.components.rightMenu.contactsRoute)} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate(i18n.t(tokens.app.tabs.contacts))
                    }} />:null}
            {!isEmpty?<DrawerItem route={undefined} label={i18n.t(tokens.app.components.rightMenu.profileRoute)} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate('Profile', {uid: user.uid})
                    }} />:null}
            
            {!isEmpty && ((roles.type == "user") && ((roles.isGod || roles.isAdmin)) || roles.type == "clubs")?<DrawerItem route={undefined} label={i18n.t(tokens.app.components.rightMenu.editClubRoute)} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate('ManageClubScreen', {clubId: selectedClub})
                    }} />:null}
            
            {!isEmpty  && ((roles.type == "user") && ((roles.isGod || roles.isAdmin)) || roles.type == "clubs") ?<DrawerItem route={undefined} label={i18n.t(tokens.app.components.rightMenu.statisticsRoute)} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate('ReservationsStatisticsScreen', {uid: user.uid})
                    }} />:null}
            
            {!isEmpty && (roles.type == "user") ?<DrawerItem route={undefined} label={i18n.t(tokens.app.components.rightMenu.checkoutsRoute)} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate('QueryCheckoutsScreen', {uid: user.uid, clubId: selectedClub})
                    }} />:null}
            {!isEmpty && (roles.type == "user") ?<DrawerItem route={undefined} label={i18n.t(tokens.app.components.rightMenu.myClubsRoute)} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate('ManageMyClubsScreen', {})
                    }} />:null}
            {!isEmpty ?<DrawerItem route={undefined}label={(props) => {
            return (<View>
              <Text>{i18n.t(tokens.app.components.rightMenu.notificationsRoute)}</Text>
              {notifications > 0?
              <Badge
                value={notifications}
                containerStyle={{ position: 'absolute', top: 3, right: -5}}
                badgeStyle={{backgroundColor: "grey" }}
              />
              :null}</View>)}} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate('NotificationsScreen', {})
                    }} />:null}

            {!isEmpty?<DrawerItem route={undefined} label={i18n.t(tokens.app.screens.linkeduser.linkedUserTitle)} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate("LinkedUsersScreen", {uid: user.uid})
                    }} />:null}
            {!isEmpty  && (roles.type == "user" && roles.isGod) ?
            <DrawerItem  route={undefined} label={"Testing screen"} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate('TestingScreen', {uid: user.uid})
                    }} />:null}
            {!isEmpty && (roles.type == "user") && (roles.isGod) ?<DrawerItem  route={undefined} label={i18n.t(tokens.app.screens.allClubs.allClubsScreenTitle)} onPress={
                
                () => {
                    //console.log('Drawer.item', props)
                    props.navigation.closeDrawer()
                    props.navigation.navigate('ManageAllClubsScreen', {})
                    }} />:null}
            {!isEmpty?<DrawerItem route={undefined} label={i18n.t(tokens.app.components.rightMenu.logoutRoute)} onPress={() => signOutDispatch()} />:null}
            <DrawerItem route={undefined} label={i18n.t(tokens.app.screens.about.aboutScreenTitle)} onPress={() =>  props.navigation.navigate("AboutAppScreen", {uid: user?.uid})} />
          </DrawerContentScrollView>
        )
      }}>
      <Drawer.Screen name={i18n.t(tokens.app.components.rightMenu.homeRoute)} component={IndexScreen} options={{unmountOnBlur:true}}/>
      
      {/* <Drawer.Screen name="Contact" component={HomeScreen} {...props}/> */}
   
    </Drawer.Navigator>
  );
}

type NavScrProps = {
  
}

export type RootStackParamList = {
  StackHome: {} | undefined,
  Profile: ProfileNavigationParams,
  ManageReservationScreen: {} | undefined,
  Camera: {} | undefined,
  ManageMarket: {} | undefined,
  ReservationsListScreen: {} | undefined,
  ManageNewsScreen: {} | undefined,
  Registration: {} | undefined,
  ContactsScreenStack: {} | undefined,
  EditProfileScreen: {} | undefined,
  ManageClubScreen: {} | undefined,
  ManageSeasonScreen: {} | undefined,
  PaymentScreen: {} | undefined,
  ReservationsStatisticsScreen: {} | undefined,
  CheckoutScreen: {} | undefined,
  TestingScreen: {} | undefined,
  PaymentScreenQR: {} | undefined,
  QueryCheckoutsScreen: {} | undefined,
  ManageMyClubsScreen: {} | undefined,
  NotificationsScreen: {} | undefined,
  PaymentsListScreen: {} | undefined,
  BrowseCalendar: {} | undefined,
  ManageLinkedUser: {} | undefined,
  SeasonDetailScreen: {} | undefined,
};

function NavigationScreen(props: NavScrProps) {
    const dispatch = useAppDispatch()
    
    //console.log('LoggedScreenProps', props)
    

    
    useEffect(() => {
        
        
      let unsubscribeAuthState = auth.onAuthStateChanged((user) => {
          console.log('User subscription', user, user?.uid)
          if (user?.uid) {
              dispatch({type: AuthActions.authChangeLoggedUser, data: user})
          } else {
              dispatch({type: AuthActions.authChangeLoggedUser, data: null})
          }
          
      })

      return () => unsubscribeAuthState()
  }, [])
    
    console.log('Rendering')
    return (
        
    <NavigationContainer linking={linking}>
       <Stack.Navigator 
       id={undefined}
       screenOptions={{
        //gestureEnabled: true,
        //...(isAndroid && TransitionPresets.ModalPresentationIOS),
      }}
      
       >
        <Stack.Group
        screenOptions={{
          //presentation: "",
          headerStyle: {
            backgroundColor: mainColor,
            
            //height: normalize(50)
          },
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontWeight: 'bold',
            fontFamily: 'AvertaStd-Semibold',
          },
        }}>
        
        <Stack.Screen name="StackHome" component={DrawerNavigator} options={{headerShown:false, title:i18n.t(tokens.app.components.rightMenu.homeRoute)}} />
        <Stack.Screen name="DeleteUser" component={DeleteUser} options={{headerShown:false, title:i18n.t(tokens.app.components.rightMenu.homeRoute)}} />
        <Stack.Screen name="Profile" component={ProfileScreen}  options={{ title: i18n.t(tokens.app.screens.profile.profileScreenTitle) }} />
        <Stack.Screen name="ManageReservationScreen" component={ManageReservationScreen} options={{ title: i18n.t(tokens.app.screens.manageReservation.manageReservationTitle) }}/>
        
        <Stack.Screen name="ManageMarket" component={ManageMarketScreen} options={{ title: i18n.t(tokens.app.screens.manageMarket.manageMarketTitle) }} />
        <Stack.Screen name="ReservationsListScreen" component={ReservationsListScreen} options={{ title: i18n.t(tokens.app.tabs.reservations) }}/>
        <Stack.Screen name='ManageNewsScreen' component={ManageNewsScreen}  options={{ title: i18n.t(tokens.app.screens.news.newsScreenTitle) }} />
        <Stack.Screen name="Registration" component={RegistrationScreen} options={{ title:  i18n.t(tokens.app.screens.registration.registrationTitle) }} />
        <Stack.Screen name="ContactsScreenStack" component={ContactsScreen} options={{ title:  i18n.t(tokens.app.screens.contacts.contactsScreenTitle) }} />
        <Stack.Screen name="EditProfileScreen" component={ManageProfileScreen} options={{ title: i18n.t(tokens.app.screens.profile.profileScreenTitle) }} />
        <Stack.Screen name="ManageClubScreen" component={ManageClubScreen} options={{title: i18n.t(tokens.app.screens.manageClub.manageClubScreenTitle)}}/>
        <Stack.Screen name="ManageSeasonScreen" component={ManageSeasonScreen} options={{title: i18n.t(tokens.app.screens.manageSeason.manageSeasonScreenTitle)}}/>
        <Stack.Screen name="PaymentScreen" component={PaymentScreen}  options={{title: i18n.t(tokens.app.screens.payment.paymentTitle)}}/>
        <Stack.Screen name="ReservationsStatisticsScreen" component={ReservationsStatisticsScreen} options={{title: i18n.t(tokens.app.screens.statistics.reservationStatisticsScreenTitle)}}/>
        <Stack.Screen name="CheckoutScreen" component={CheckoutScreen} options={{title: i18n.t(tokens.app.screens.checkout.checkoutTitle)}}/>
        <Stack.Screen name="TestingScreen" component={TestingScreen} options={{presentation: "modal"}}/>
        <Stack.Screen name="PaymentScreenQR" component={PaymentScreenQR}/>
        <Stack.Screen name="QueryCheckoutsScreen" component={CheckoutListScreen} options={{title: i18n.t(tokens.app.screens.checkoutList.checkoutListScreenTitle)}}/>
        <Stack.Screen name="ManageMyClubsScreen" component={ManageMyClubsScreen}  options={{title: i18n.t(tokens.app.screens.myClubs.myClubsScreenTitle)}}/>
        <Stack.Screen name="ManageAllClubsScreen" component={ManageAllClubsScreen}  options={{title: i18n.t(tokens.app.screens.allClubs.allClubsScreenTitle)}}/>
        <Stack.Screen name="NotificationsScreen" component={NotificationsScreen}  options={{title: i18n.t(tokens.app.components.rightMenu.notificationsRoute)}}/>
        <Stack.Screen name="PaymentsListScreen" component={PaymentsListScreen}  options={{title: i18n.t(tokens.app.screens.payment.paymentTitle)}}/>
        <Stack.Screen name="BrowseCalendar" component={BrowseCalendar}  options={{title: i18n.t(tokens.app.screens.payment.paymentTitle)}}/>
        <Stack.Screen name="ManageLinkedUser" component={ManageLinkedUser}  options={{title: i18n.t(tokens.app.screens.manageLinked.manageLinkedUserTitle)}}/>
        <Stack.Screen name="LinkedUsersScreen" component={LinkedUsersScreen}  options={{title: i18n.t(tokens.app.screens.linkeduser.linkedUserTitle)}}/>
        <Stack.Screen name="SeasonDetailScreen" component={SeasonDetailScreen}  options={{title: i18n.t(tokens.app.screens.manageSeason.manageSeasonScreenTitle)}}/>
        </Stack.Group>
        <Stack.Group 
        screenOptions={{
          headerStyle: {
            backgroundColor: mainColor,
            
            //height: normalize(50)
          },
          headerShown: true,
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontWeight: 'bold',
            fontFamily: 'AvertaStd-Semibold',
          },
        }}>
        <Stack.Screen name="AboutAppScreen" component={AboutAppScreen}  options={{title: i18n.t(tokens.app.screens.about.aboutScreenTitle)}}/>
        <Stack.Screen name="Camera" component={CameraScreen}  options={{ title: i18n.t(tokens.app.screens.camera.cameraTitle) }}/>
        </Stack.Group>
      </Stack.Navigator>
      
     
    </NavigationContainer>
  
      )
}

export default NavigationScreen