import React, { useState } from "react";
import { Text, TextInput, View } from "react-native";
import BigButton from "../../components/BigButton";
import i18n, { tokens } from "../../i18n";
import { resetPasswordEmail, signInAndDeleteUser } from "../../store/actions/actionsAuth";
import { useAppDispatch, useAppNavigation } from "../../store/hooks";
import { globalStyleSheet } from "../../utilities/globalStyles";
import styles from './styles';
import WebWrapper from "../../components/WebWrapper";
import RenderModal from "./Components/DeleteProfileOverlay";
type Props = {
    source: "market" | "entry"
}
function LoginForm(props: Props) {
    const {source} = props
    const dispatch = useAppDispatch()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    
    const toggleModal = () => {
        setIsModalVisible((prevValue) => {
            return !prevValue
        })
    }

    const resetEmailLink = () => {
        dispatch(resetPasswordEmail(email, (bool, context) => {
            if (bool == false) {
                if (context == 'EMAIL_INVALID') {
                    setError(i18n.t(tokens.app.screens.login.emailNotValid))
                } else {
                    setError(i18n.t(tokens.app.screens.login.resetEmailSendingError))
                }
                
            } else {
                setError(i18n.t(tokens.app.screens.login.emailSent))
            }
        }))
    }
    const onLoginPress = () => {
        dispatch(signInAndDeleteUser({
            email: email,
            password: password
        }, (bool, context) => {
            console.log('Callback loginu', bool, context)
            if (bool == false) {
                if(typeof context == 'string') setError(context)
                else if (context.code) {
                    if (context.code == 'auth/wrong-password') {
                        setError(i18n.t(tokens.app.screens.login.wrongPassword))
                    } else if (context.code == 'auth/user-not-found') {
                        setError(i18n.t(tokens.app.screens.login.userNotFound))
                    } 
                    
                } else setError(i18n.t(tokens.app.screens.login.loginError))
            }
        }, null))
        
    }

    return (
        <WebWrapper>
            <View
            style={{flex:1, justifyContent: "center", alignItems: "center"}}
            >
                    
                <View style={{width: "100%"}}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={styles.input}
                    placeholder={i18n.t(tokens.app.screens.login.email)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => setEmail(text)}
                    value={email}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    inputMode="email"
                />
                <TextInput maxFontSizeMultiplier={1.2}
                    style={styles.input}
                    placeholderTextColor="#aaaaaa"
                    secureTextEntry
                    placeholder={i18n.t(tokens.app.screens.login.password)}
                    onChangeText={(text) => setPassword(text)}
                    value={password}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    
                />
                <RenderModal
                isModalVisible={isModalVisible}
                toggleModal={toggleModal}
                removeUser={() => onLoginPress()}
                />  
                <View style={{flexDirection: "row", paddingLeft: 10, paddingRight: 10}}>
                <BigButton
                onPress={() => toggleModal()}
                buttonText={i18n.t(tokens.app.screens.profile.profileDelete)}
                />
                </View>
                <View 
                style={globalStyleSheet.footerView}>
                    <Text maxFontSizeMultiplier={1.2} 
                    style={globalStyleSheet.footerText}
                    >
                        {i18n.t(tokens.app.screens.login.forgotPassQuestion)}
                        <Text maxFontSizeMultiplier={1.2} 
                        onPress={resetEmailLink} 
                        style={globalStyleSheet.footerLink}>
                            {i18n.t(tokens.app.screens.login.sendPasswordButton)}
                        </Text>
                    </Text>
                </View>
                <View style={globalStyleSheet.footerView}>
                    <Text maxFontSizeMultiplier={1.2} style={{color: "red"}}>{error}</Text>
                </View>
                </View> 
                
            </View>
                    
                    
        </WebWrapper>  )
}


export default LoginForm