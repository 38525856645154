import React, { useState, useEffect } from 'react'
import { Text, View, ActivityIndicator } from 'react-native'
import styles from './styles';
import { Route, useNavigation } from '@react-navigation/native';
//import DelayInput from "react-native-debounce-input";
import { connect, useDispatch, useSelector } from 'react-redux'
import WebWrapper from '../../components/WebWrapper';
import ManageReservation from './MarketReservation/ManageReservation';
import { globalStyleSheet, mainFontBold, secondColor } from '../../utilities/globalStyles';
import { RootStore } from '../../store/reducers/rootReducer';
import { useAppDispatch, useAppNavigation } from '../../store/hooks';
import { manageMarketToState } from '../../store/actions/actionsMarketNew';
import ManageLongreservation from './ManageLongreservation/ManageLongreservation';
import ManageOpengame from './ManageOpengame/ManageOpengame';
import { AnyMarket, MarketPaymentStatuses, MarketTypes } from '../../../functions/src/interfaces';
import ManageSubscription from './ManageSubscription/ManageSubscription';
import LoginForm from '../LoginScreen/LoginComponents/LoginForm';
import ManageCredit from './ManageCredit/ManageCredit';
import { getCurrentAuthState } from '../../store/selectors';
import { ButtonGroup } from 'react-native-elements';
import i18n, { tokens } from '../../i18n';
import CheckoutScreenComponent from '../CheckoutScreen/Components/CheckoutScreenComponent';
import { StateLoader } from '../../utilities/interfaces';
import { CheckoutModes } from '../CheckoutScreen/Components/types';

type Props = {
    route: Route<'ManageMarket', {marketId: string, type: string, club: string, firebaseId?: string, onBehalfUid?: string}>
}


const RenderManageProps = ({type, navigation, managedMarket, setMarketId, setMarketButtonIndex}) => {
    //console.log('RenderManageProps', props)
    if (type == MarketTypes.longreservation) {
        return (<ManageLongreservation firebaseId={managedMarket.data.firebaseId} club={managedMarket.data.club} setMarketId={setMarketId} setMarketButtonIndex={setMarketButtonIndex}/>)
    } else if (type == MarketTypes.opengame) {
        return (<ManageOpengame firebaseId={managedMarket.data.firebaseId} club={managedMarket.data.club} setMarketId={setMarketId}/>)
    } else if (type == MarketTypes.reservation) {
        return (<ManageReservation firebaseId={managedMarket.data.firebaseId} club={managedMarket.data.club} setMarketId={setMarketId} setMarketButtonIndex={setMarketButtonIndex}/>)
    } else if (type == MarketTypes.subscription) {
        return (<ManageSubscription firebaseId={managedMarket.data.firebaseId} club={managedMarket.data.club} setMarketId={setMarketId} setMarketButtonIndex={setMarketButtonIndex}/>)
    } else if (type == MarketTypes.credit) {
        return (<ManageCredit firebaseId={managedMarket.data.firebaseId} club={managedMarket.data.club} setMarketId={setMarketId} setMarketButtonIndex={setMarketButtonIndex}/>)
    } else {
        return (<Text>{type}</Text>)
    }
} 

type SlidesProps = {
    managedMarket: StateLoader<AnyMarket>,
    setMarketId: (state: any) => void,
    buttonGroupIndex: number,
    setButtonGroupIndex: (callback: (number | ((prev: number) => void))) => void,
}

const Slides: React.FC<SlidesProps> = ({managedMarket, setMarketId, buttonGroupIndex, setButtonGroupIndex}) => {
    const navigation = useAppNavigation()
    
    const [buttons, setButtons] = useState(
        [
            i18n.t(tokens.app.screens.manageMarket.manageMarketTitle),
            i18n.t(tokens.app.screens.checkout.checkoutTitle)
        ]
    )

    const isPayable = (anyMarket: AnyMarket) => {
        if (anyMarket.type == MarketTypes.credit) {
            return true
        } else if (anyMarket.type == MarketTypes.longreservation) {
            return true
        } else if (anyMarket.type == MarketTypes.reservation) {
            return true
        } else if (anyMarket.type == MarketTypes.subscription) {
            return true
        } else {
            return false
        }
    }

    
    return (
        <View style={globalStyleSheet.container}>
            {isPayable(managedMarket.data)?
            <ButtonGroup
            onPress={(val) => { setButtonGroupIndex(val) }}
            selectedIndex={buttonGroupIndex}
            buttons={buttons}
            disabled={!(managedMarket.data?.firebaseId && managedMarket.data?.firebaseId != "")}
            //buttonStyle={{borderColor: secondColor}}
            selectedButtonStyle={{ backgroundColor: "grey" }}
            containerStyle={[globalStyleSheet.toolbar]}
            buttonContainerStyle={{ margin: 0, padding: 0, height: "100%" }}
            textStyle={{ color: "black", fontFamily: mainFontBold, fontWeight: "bold" }}
            />:null}
            {buttonGroupIndex == 0?
                <RenderManageProps 
                type={managedMarket.data?.type} 
                navigation={navigation} 
                managedMarket={managedMarket} 
                setMarketId={setMarketId}
                setMarketButtonIndex={setButtonGroupIndex}
                />:null
            }
            {buttonGroupIndex == 1 && 
            (
                managedMarket.data.type == MarketTypes.credit || 
                managedMarket.data.type == MarketTypes.longreservation || 
                managedMarket.data.type == MarketTypes.reservation || 
                managedMarket.data.type == MarketTypes.subscription 
            )?
                <CheckoutScreenComponent 
                checkoutId={managedMarket.data.firebaseId}
                clubId={managedMarket.data.club}
                mode={managedMarket.data.paymentStatus == MarketPaymentStatuses.requiresPayment ? CheckoutModes.requiresPayment : null}
                marketType={managedMarket.data.type}
                />:null
            }
        </View>
    )
}

function ManageMarketScreen(props: Props):JSX.Element {
    //console.log('Prev props', props)
    const [type, setType] = useState(props.route.params?.type)  
    const [marketId, setMarketId] = useState(props.route.params.marketId)
    const firebaseId = props.route.params.firebaseId
    const dispatch = useAppDispatch()
    const club = props.route.params?.club
    //const club = useSelector((state: RootStore) => state.auth.selectedClub ? state.auth.selectedClub : state.auth.currentUserData?.type == "clubs"? state.auth.currentUserData.firebaseId:state.auth.currentUserData.clubs[0]) //props.userData.selectedClub ? props.userData.selectedClub : props.userData.club
    //const [manageMarketSubcsribtion, setManageMarketSubcsribtion] = useState(null)
    const isLoaded = useSelector((state: RootStore) => state.market.managedMarket.isLoaded, (prevValue, newValue) => prevValue == newValue)
    const managedMarket = useSelector((state: RootStore) => state.market.managedMarket, (oldState, newState) => oldState.data?.firebaseId === newState.data?.firebaseId && oldState.isLoaded === newState.isLoaded && oldState.data?.type == newState.data?.type)
    const [buttonGroupIndex, setButtonGroupIndex] = useState(0)
    const { isEmpty, user } = getCurrentAuthState()
    
    

    useEffect(() => {
        var sub = null
        console.log('Nahravam znovu')
        dispatch(manageMarketToState({marketId:marketId, type: type as MarketTypes, club: club, uid: user?.uid, mainUid: props.route.params.onBehalfUid || user?.uid, firebaseId: firebaseId}, (res: ()=> void) => {
            console.log('unsubscribe market function',typeof res)
            sub = res
        }))
        
        return () => { 
            dispatch({type: 'RESET_MARKET', data: {isLoading: true}})
            console.log('Running cleanup' + typeof(sub))
            if (sub) {
            
                sub()
            }
        }
    }, [marketId, type, club, user?.uid])
    

    

    return (
        <View style={globalStyleSheet.container}>
            <WebWrapper>
                {user?.uid?
            isLoaded?<Slides 
            managedMarket={managedMarket} 
            setMarketId={setMarketId} 
            buttonGroupIndex={buttonGroupIndex} 
            setButtonGroupIndex={setButtonGroupIndex}
            />:
            <ActivityIndicator  size="small" color={secondColor}/>
            :<View style={{flex:1, justifyContent: "center", alignItems: "center"}}>
                <Text>
                    Pro vytvoření žádosti je nutné se přihlásit/registrovat
                </Text>
                <LoginForm source={'market'}/>
            </View>}
            </WebWrapper>
        </View>
    )
}



export default ManageMarketScreen



