import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { shallowEqual } from "react-redux";
import { Club, UserRoles } from "../../../../functions/src/interfaces";
import ClicableUserAvatar from "../../../components/ClickableUserAvatar";
import FormEntry from "../../../components/FormEntryNew";
import SmallButton from "../../../components/SmallButton";
import i18n, { tokens } from "../../../i18n";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../../store/hooks";
import { RootStore } from "../../../store/reducers/rootReducer";
import { UserOrClub } from "../../../store/reducers/usersReducer";
import { normalize } from "../../../utilities/constants";
import { daysDiff, monthString } from "../../../utilities/funcs";
import { globalStyleSheet } from "../../../utilities/globalStyles";

type Props = {
    clubId: string,
    currentUser: UserOrClub<UserRoles>,
    type: "club" | "contact",
}
function ClubProfile(props: Props): JSX.Element {
    const {clubId, currentUser, type} = props
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    const clubsData: {
        [clubId: string]: Club;
    } = useAppSelector((state: RootStore) => state.auth.allClubs, shallowEqual)
    //console.log('Klubiky',clubsData)

    const clubData = (clubsData:{
        [clubId: string]: Club;
    }, clubId: string) => {
        return clubsData && clubsData[clubId] ? clubsData[clubId] : null
    }

    return (
        <View style={localStyles.container}>
            <ClicableUserAvatar
            disabled={type == 'contact'}
            onPress={() => navigation.navigate("Camera", {uid: clubId})}
            size={normalize(170)} 
            uid={clubData(clubsData, props.clubId).firebaseId}
            />
        <Text maxFontSizeMultiplier={1.2} style={{fontSize: 20, fontWeight:"bold", alignSelf:"center"}}>{clubData(clubsData, props.clubId)?clubData(clubsData, props.clubId).club_name:"Not loaded"}</Text>
        <Text maxFontSizeMultiplier={1.2} style={{fontSize: 10, alignSelf:"center"}}>
            <Ionicons 
            name="at-outline"
            color={"black"}
            size={12}
            />
        {clubData(clubsData, props.clubId)?clubData(clubsData, props.clubId).email:"Not loaded"}
        <Ionicons 
            name="call-outline"
            color={"black"}
            size={12}
            />
        {clubData(clubsData, props.clubId)?clubData(clubsData, props.clubId).phone:"Not loaded"}
        </Text>
                    
        <SmallButton 
        onPress={() => navigation.navigate('ManageClubScreen', {clubId: clubId})}
        buttonText={i18n.t(tokens.app.screens.profile.profileEditButton)}
        //icon={"settings-outline"}
        />
        <View style={localStyles.detailContainer}>
        <FormEntry
        caption={i18n.t(tokens.app.screens.profile.profileAddress)}
        editable={false}
        >
            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{clubData(clubsData, props.clubId).address}</Text>
        </FormEntry>
        <FormEntry
        caption={i18n.t(tokens.app.screens.profile.profileCity)}
        editable={false}
        >
            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{clubData(clubsData, props.clubId).city}</Text>
        </FormEntry>
        <FormEntry
        caption={i18n.t(tokens.app.screens.profile.profilePhone)}
        editable={false}
        >
            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{clubData(clubsData, props.clubId).phone}</Text>
        </FormEntry>
        {clubData(clubsData, props.clubId).firebaseId == currentUser.uid || (currentUser.type == "user" && (currentUser.isAdmin == true || currentUser.isGod == true))?<FormEntry
        caption={i18n.t(tokens.app.screens.manageClub.manageClubActiveUntil)}
        editable={false}
        >
            <Text maxFontSizeMultiplier={1.2} style={[globalStyleSheet.entityText, {color: daysDiff(new Date, clubData(clubsData, props.clubId).activeUntil) > 30 ? "black" : "red"}]}>{monthString(clubData(clubsData, props.clubId).activeUntil)}</Text>
        </FormEntry>:null}
        </View>
        </View>
    )
}

export default ClubProfile


const localStyles = StyleSheet.create({
    container: {
        alignItems: "center",
        width: "100%",
    },
    detailContainer: {
        width: "100%"
    }
})