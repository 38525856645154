import { Route, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { FlatList, Platform, StyleSheet, Pressable, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { shallowEqual } from "react-redux";
import { Club, user } from "../../../functions/src/interfaces";
import BigButton from "../../components/BigButton";
import RenderContactClub from "../../components/ContactClub";
import CustomPickerSelect from "../../components/CustomPickerSelect/CustomPickerSelect";
import FormEntry from "../../components/FormEntryNew";
import FormEntryNoCaption from "../../components/FormEntryNoCaption";
import FormHeading from "../../components/FormHeading";
import RadioButton from "../../components/RadioButton";
import SmallButton from "../../components/SmallButton";
import i18n, { tokens } from "../../i18n";
import { addClubSubscription, removeClubSubscription, setSelectedClub } from "../../store/actions/actionsAuth";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks";
import { RootStore } from "../../store/reducers/rootReducer";
import { getCurrentUserWithRoles } from "../../store/selectors";
import { globalStyleSheet } from "../../utilities/globalStyles";

type Props = {
    route: Route<'ManageAllClubsScreen', {}>
}

function ManageAllClubsScreen(props: Props): JSX.Element {
    const dispatch = useAppDispatch()
    const allClubs: {[clubId: string]: Club} = useAppSelector((state: RootStore) => state.auth.allClubs, shallowEqual)
    const navigation = useAppNavigation()
    //const selectedClub: string = useAppSelector((state:RootStore) => state.auth.selectedClub, shallowEqual)
    const currentUser: undefined | user = useAppSelector((state: RootStore) => {
        let currData = state.auth.currentUserData
        if (currData.type == "user") {
            return currData
        } else {
            undefined
        }
    }, shallowEqual)
    const [club, setClub] = useState(undefined)
    const [availableClubs, setAvailableClubs] = useState<Club[]>([])

    const changeSelection = (value) => {
        if (value) {
            setClub(value)
        }
    }

    
    
    useEffect(() => {
        let keys = Object.keys(allClubs)
        var options = []
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let club: Club = allClubs[key]
            options.push(club)
        }
        setAvailableClubs(options)
    }, [currentUser?.clubs, allClubs])

    
    if (currentUser) {
    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        
        >
        <View style={globalStyleSheet.container}>
            <FormHeading 
            heading={i18n.t(tokens.app.screens.myClubs.myClubsMyClubsHeading)}
            />
            
            <FormHeading 
            heading={i18n.t(tokens.app.screens.myClubs.myClubsAddClubSubscription)}
            />
            <FlatList
            data={availableClubs}
            renderItem={({item}) => (<RenderContactClub action={() => navigation.navigate('ManageClubScreen', {clubId: item.firebaseId})} item={item} />
                )}
            keyExtractor={(item) => item.firebaseId}
            />

        </View>
        </KeyboardAwareScrollView>
    )
    } else {
        return null
    }
}

const localStyles = StyleSheet.create({
    pickClubRow: {flexDirection: "row", flex: 1, justifyContent: "space-between", alignItems: "center"}
})

export default ManageAllClubsScreen