import { orderByPoints } from "../../utilities/funcs"

const initState = {
    seasonalRanking: {
        isLoaded: false,
        lastVisible: null,
        data: []
    },
    amParticipating: {
        isLoaded: false,
        amParticipating: false,
    },
    seasonalRankingDocuments: {}
}

const rankingReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOAD_RANKING_DATA_PAGE':
            //console.log('akce:', action)
            return {
                ...state,
                seasonalRanking: {
                    isLoaded: action.data.isLoaded,
                    lastVisible: action.data.lastVisible,
                    data: [...state.seasonalRanking.data, ...action.data.pageData]
                }
            }
        case 'GET_USER_PARTICIPATION_ACTION':
            //console.log('akce:', action)
            return {
                ...state,
                amParticipating: {
                    isLoaded: action.data.isLoaded,
                    isSuccess: action.data.isSuccess,
                    amParticipating: action.data.amParticipating,
                    error: action.data.error,
                }
            }
        case 'RANKING_CHANGES':
            //action.data
            if (!action.data) {
                return {
                    ...state,
                    seasonalRanking: {
                        isLoaded: true,
                        lastVisible: null,
                        data: []
                    },
                    amParticipating: {
                        isLoaded: true,
                        amParticipating: false,
                    },
                    seasonalRankingDocuments: {}
                }
            }
            var out = {
                ...state,
                seasonalRankingDocuments: {...state.seasonalRankingDocuments, ...action.data.added, ...action.data.modified}
            }
            Object.keys(action.data.removed).forEach((key) => {
                delete out.seasonalRankingDocuments[key]
            })
            let data = Object.values(out.seasonalRankingDocuments)
            console.log('Chci to seradit podle: '+action.data.gameType)
            out.seasonalRanking['data'] = [...data.sort((a,b) => {
                if (action.data.gameType == 'single') {
                    //console.log(action.data.gameType, a.pointsSingles, b.pointsSingles)
                    if (a.pointsSingles > b.pointsSingles) {
                        return -1
                    } else if (a.pointsSingles == b.pointsSingles) {
                        return 0
                    } else {
                        return 1
                    }
                } else if (action.data.gameType == 'double') {
                    //console.log(action.data.gameType, a.pointsDoubles, b.pointsDoubles)
                    if (a.pointsDoubles > b.pointsDoubles) {
                        return -1
                    } else if (a.pointsDoubles == b.pointsDoubles) {
                        return 0
                    } else {
                        return 1
                    }
                } else {
                    return 0
                }
                
            })]
            var pos = 0
            var prevPoints = null
            console.log('Srting by: ' + orderByPoints(action.data.gameType))
            for (let i = 0; i < out.seasonalRanking['data'].length; i++) {
                let currRankEntry = out.seasonalRanking['data'][i]

                if (prevPoints && prevPoints > currRankEntry[orderByPoints(action.data.gameType)]) {
                    pos += 1
                    prevPoints = currRankEntry[orderByPoints(action.data.gameType)]
                } else if (prevPoints == null) {
                    pos += 1
                    prevPoints = currRankEntry[orderByPoints(action.data.gameType)]
                } else {
                    pos += 0
                }
                out.seasonalRanking['data'][i]['rank'] = pos
                
            }
            //console.log(out)
            return out

        default:
            return state


    }
}

export default rankingReducer