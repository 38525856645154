

export enum AppText {
    Hello = 'hello',
    Introduction = 'introduction',
    Body = 'body'
  }

export enum RegistrationScreen {
  personalHeading = 'personalHeading',
  registrationTitle = 'registrationTitle',
  first_name = 'first name',
  first_name_validity = 'first name validity',
  family_name = 'family name',
  family_name_validity = 'family name validity',
  addressHeading = 'addressHeading',
  address = 'address',
  city = 'city',
  contactsHeading = 'contactsHeading',
  phone = 'telephone number',
  phone_validity = 'telephone number validity',
  email = 'email address',
  email_validity = 'email address validity',
  emailConfirm = 'emailConfirm',
  emailConfirm_validity = 'emailConfirm_validity',
  dateOfBirth = 'DOB',
  passwordsHeading = 'passwordsHeading',
  password = 'password',
  password_validity = 'password validity',
  confirmPassword = 'password confirmation',
  confirmPassword_validity = 'password confirmation validity',
  createAccountButtonCaption = 'create account button',
  termsAndConditions = 'termsAndConditions',
  termsAndConditionsError = 'termsAndConditionsError',
  registrationButtonsUser = 'registrationButtonsUser',
  registrationButtonsClub = 'registrationButtonsClub',
  registrationClubName = 'registrationClubName',
  registerClubTrialMessage = 'registerClubTrialMessage',
  registerPrivaciPolicy = 'registerPrivaciPolicy',
  registerUserClubsValidity = 'registerUserClubsValidity',
}

export enum LoginScreen {
  title = 'screen title',
  email = 'login email address',
  password = 'loginpassword',
  submit = 'submit',
  noAccQuestion = 'noAccQuestion',
  forgotPassQuestion = 'forgotPassQuestion',
  registerButtonCaption = 'register',
  sendPasswordButton = 'sendPasswordReset',
  emailNotValid = 'emailNotValid',
  resetEmailSendingError = 'resetEmailSendingError',
  loginError = 'loginError',
  emailSent = 'emailSent',
  userNotFound = 'userNotFound',
  wrongPassword = 'wrongPassword'
}


export enum Tabs {
  reservations = 'reservationsTab',
  login = 'loginTab',
  contacts = 'contactsTab',
  market = 'marketTab',
  ranking = 'rankingTab',
  news = 'newsTab',
}

export enum ProductPaymentDescriptions {
  reservation = 'paymentDescReservation',
  subscription = 'paymentDescSubscription',
  credit = 'paymentDescCredit',
}

export enum Courts {
  clay = 'clay',
  hard_court = 'hard_court',
}

export enum ReservationsScreen {
  court = 'court',
  notActiveSeasonMessage = 'notActiveSeasonMessage',
  notLoggedInMessage = 'notLoggedInMessage',
  notLoggedInWarning = 'notLoggedInWarning',
  notSelectedWarning = 'notSelectedWarning',
  alertTitle = 'alertTitle',
  noSeasonAvailable = 'noSeasonAvailable',
  noSeasonHeader = 'noSeasonHeader',
  seasonsList = 'seasonsList',
  noFutureSeasonsMessage = 'noFutureSeasonsMessage',
  courtIsLocked = 'courtIsLocked',
  reservationBlockedTime = 'reservationBlockedTime',
}

export enum Seasons {
  winter = 'winter',
  summer = 'summer'
}

export enum Weekdays {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday'
}

export enum Functions {
  undefined = "undefined",
  notSelected = "notSelected",
}


export enum ManageReservationScreen {
  manageReservationTitle = 'manageReservationTitle',
  manageReservationHeading = 'manageReservationHeading',
  reservationAuthor = 'reservationAuthor',
  reservationRequestor = 'reservationRequestor',
  reservationDate = 'reservationDate',
  reservationTimeFrom = 'reservationTimeFrom',
  reservationTimeTo = 'reservationTimeTo',
  reservationTypeAndPlayersHeading = 'reservationTypeAndPlayersHeading',
  reservationOpenGameCaption = 'reservationOpenGameCaption',
  reservationOpenGameLink = 'reservationOpenGameLink',
  reservationLongreservationCaption = 'reservationLongreservationCaption',
  reservationLongreservationLink = 'reservationLongreservationLink',
  reservationSaveButton = 'reservationSaveButton',
  reservationEditButton = 'reservationEditButton',
  reservationApproveButton = 'reservationApproveButton',
  reservationDeclineButton = 'reservationDeclineButton',
  reservationIsOpenMessage = 'reservationIsOpenMessage',
  reservationIsOpenCaption = 'reservationIsOpenCaption',
  reservationIsHistoryMessage = 'reservationIsHistoryMessage',
  reservationOnBehalf = 'reservationOnBehalf',
  reservationOnBehalfRegistered = 'reservationOnBehalfRegistered',
  timingHeading = 'timingHeading',
  reservationPriceHeading = 'reservationPriceHeading',
  reservationPriceState = 'reservationPriceState',
  reservationAddToCalendar = 'reservationAddToCalendar',
  reservationRequestIdLink = 'reservationRequestIdLink',
  reservationPaidInfo = 'reservationPaidInfo',
  reservationStatus = 'reservationStatus'
}



export enum PaymentState {
  paymentStateEstimate = 'paymentStateEstimate',
  paymentStateBilled = 'paymentStateBilled',
  paymentStatePartlyCharged = 'paymentStatePartlyCharged',
  paymentStatePartlyPaid = 'paymentStatePartlyPaid',
  paymentStateFullyCharged = 'paymentStateFullyCharged',
  paymentStateFullyPaid = 'paymentStateFullyPaid',
  paymentStateOverCharged = 'paymentStateOverCharged',
  paymentStateOverPaid = 'paymentStateOverPaid',
  paymentStateManual = 'paymentStateManual',
}

export enum ButtonCaptions {
  buttonsRemoveButton = 'buttonsRemoveButton',
  buttonsAddButton = 'buttonsAddButton',
}

export enum GameSetting {
  gameSettingGameType = 'gameSettingGameType',
  gameSettingPlayers = 'gameSettingPlayers',
  gameSettingEmpty = 'gameSettingEmpty',
  onSelectedMessage = 'onSelectedMessage',
  gameSettingRemPlayers = 'gameSettingRemPlayers',
  notifyPlayers = 'notifyPlayers',
  gameSettingsAgainst = 'gameSettingsAgainst',
  gameSettingsApplied = 'gameSettingsApplied',
  gameSettingsAddSelf = 'gameSettingsAddSelf',
  gameSettingsRemoveSelf = 'gameSettingsRemoveSelf',
}



export enum UnverifiedScreen {
  unverifiedText = 'unverifiedText',
  resedVerifEmail = 'resedVerifEmail',
  logout = 'logout',
  resendMessage = 'resendMessage',
  logoutMessage = 'logoutMessage',
}

export enum ContactsScreen {
  contactsScreenTitle = 'contactsScreenTitle',
  contactsMyList = 'contactsMyList',
  contactsAllMembList = 'contactsAllMembList',
  contactsAllClubList = 'contactsAllClubList',
  selectButton = 'selectButton',
  noContactsYetMessage = 'noContactsYetMessage',
  contactsScreenMyContacts = 'contactsScreenMyContacts',
  contactsClubmembersTitle = 'contactsClubmembersTitle',
  contactsOtherTitle = 'contactsOtherTitle',
  contactsNotMember = 'contactsNotMember',
  contactsManagedBy = 'contactsManagedBy',
  contactsSearch = 'contactsSearch'
}

export enum ProfileScreen {
  profileScreenTitle = 'profileScreenTitle',
  profileAddToContactsButton = 'profileAddToContactsButton',
  profileRemToContactsButton = 'profileRemToContactsButton',
  profileEditButton = 'profileEditButton',
  profileCheckoutsButton = 'profileCheckoutsButton',
  profileFirstName = 'profileFirstName',
  profileFamilyName = 'profileFamilyName',
  profileBirthDate = 'profileBirthDate',
  profileEmail = 'profileEmail',
  profilePhone = 'profilePhone',
  profileICO = 'profileICO',
  profileDIC = 'profileDIC',
  profileClubHomepage = 'profileClubHomepage',
  profileClubVOP = 'profileClubVOP',
  profileAddress = 'profileAddress',
  profileCity = 'profileCity',
  profileDelete = 'profileDelete',
  profileDeletionOK = 'profileDeletionOK',
  profileDeletionKO = 'profileDeletionKO',
  profileDeletionConfirmationQuestion = 'profileDeletionConfirmationQuestion',
  profileDeleteCancel = 'profileDeleteCancel',
  profileLast10games = 'profileLast10games',
  profileDetail = 'profileDetail',
  profileSaveButton = 'profileSaveButton',
  profileRoles = 'profileRoles',
  profileMembershipTitle = 'profileMembershipTitle',
  profileMembershipFrom = 'profileMembershipFrom',
  profileMembershipTo = 'profileMembershipTo',
  profileAddSubscription = 'profileAddSubscription',
  profileSubscriptionNA = 'profileSubscriptionNA',
  profileModRolesButton = 'profileModRolesButton',
}

export enum LongReservationScreen {
  periodHeading = 'periodHeading',
  season = 'season',
  periodFrom = 'periodFrom',
  periodTo = 'periodTo',
  weekday = 'weekday',
  reccurency = 'reccurency',
  collisions = 'collisions',
  childReservations = 'childReservations',
  timingHeading = 'longrestimingHeading',
  timeStart = 'timeStart',
  timeEnd = 'timeEnd',
  additionalHeading = 'additionalHeading',
  requestExpires = 'requestExpires',
  court = 'longrescourt',
  messageHeading = 'messageHeading',
  messagePlaceholder = 'messagePlaceholder',
  viewButton = 'viewButton',
  addButton = 'addButton',
  approveButton = 'approveButton',
  removeButton = 'removeButton',
  modifyButton = 'modifyButton',
  errorSeasonNTBSelected = 'errorSeasonNTBSelected',
  errorLongDateFuture = 'errorLongDateFuture',
  errorLongDateOOB = 'errorLongDateOOB',
  errorTimeNotOpenHours = 'errorTimeNotOpenHours',
  errorTimeNotDivisible = 'errorTimeNotDivisible',
  errorTimeToLTTimeFrom = 'errorTimeToLTTimeFrom',
  errorCourtNotSelected = 'errorCourtNotSelected',
  errorNotWIPlanPer = 'errorNotWIPlanPer',
  requestDate = 'requestDate',
  periodMessage = 'periodMessage',
  timingMessage = 'timingMessage',
  longResFuture = 'longResFuture',
  longResAll = 'longResAll',
  longResDialogRemoval = 'longResDialogRemoval',
}

export enum Reccurency {
  weekly = 'weekly'
}

export enum OpenGameScreen {
  dateTimeHeading = 'dateTimeHeading',
  requestExpires = 'OpengameRequestExpires',
  timeStart = 'opengameTimeStart',
  timeEnd = 'opengameTimeEnd',
  connectWithResButton = 'connectWithResButton',
  createReservationButton = 'createReservationButton',
  messageHeading = 'opengameMessageHeading',
  messagePlaceholder = 'opengameMessagePlaceholder',
  commentsHeading = 'messagesHeading',
  commentButton = 'commentButton',
  sendButton = 'sendButton',
  gameSettingsHeading = 'gameSettingsHeading',
  noReservationConnectedMessage = 'noReservationConnectedMessage',
  reservationConnectedMessage = 'reservationConnectedMessage',
  termOfGameMessage = 'termOfGameMessage',
  approveButton = 'openGameApproveButton',
  addButton = 'openGameAddButton',
  modifyButton = 'opengameModifyButton',
  removeButton = 'opengameRemoveButton',
  errorTooFarAway = 'errorTooFarAway',
  errorNeedToBeFuture = 'errorNeedToBeFuture',
  errorNotInOpeningHours = 'errorNotInOpeningHours',
  errorNoSeason = 'errorNoSeason',
  noMessageMessage = 'noMessageMessage',
  opengameDeclineButton = 'opengameDeclineButton'
}

export enum MarketScreen {
  filterMyRequests = 'filterMyRequests',
  freeSpotsMessage = 'freeSpotsMessage',
  periodHeading = 'marketPeriodHeading',
  reccurencyMessage = 'reccurencyMessage',
  addMyselfLink = 'addMyselfLink',
  allClubs = 'allClubs',
}


export enum AppliedPlayers {
  applicationsHeading = 'applicationsHeading'
}

export enum Messages {
  messagesHeading = 'messMessagesHeading',
  viewAllCaption = 'viewAllCaption',
  collapseCaption = 'collapseCaption',
}

export enum NewsEntity {
  applyHeading = 'applyHeading',
  applyButton = 'applyButton',
  unapplyButton = 'unapplyButton',
}

export enum NewsScreen {
  expirationDate = 'expirationDate',
  notifyAllClubmembers = 'notifyAllClubmembers',
  includeApplicationList = 'includeApplicationList',
  saveButton = 'saveButton',
  removeButton = 'newsRemoveButton',
  messagePlaceholder = 'newsMessagePlaceholder',
  newsScreenTitle = 'newsScreenTitle',
  newsType = 'newsType',
}

export enum HomeScreen {
  homeScreenTitle = 'homeScreenTitle'
}

export enum RightMenu {
  homeRoute = 'homeRoute',
  contactsRoute = 'contactsRoute',
  profileRoute = 'profileRoute',
  logoutRoute = 'logoutRoute',
  checkoutsRoute = 'checkoutsRoute',
  statisticsRoute = 'statisticsRoute',
  editClubRoute = 'editClubRoute',
  myClubsRoute = 'myClubsRoute',
  notificationsRoute = 'notificationsRoute',
}

export enum ManageMarketScreen {
  manageMarketTitle = 'manageMarketTitle',
  manageMarketStatus = 'manageMarketStatus',
}

export enum IndexScreen {
  clubPlaceholder = 'clubPlaceholder',
}

export enum Alerts {
  saved = 'saved',
  savedMessage = 'savedMessage',
}

export enum CameraScreen {
  cameraFlip = 'cameraFlip',
  cameraUpload = 'cameraUpload',
  cameraGallery = 'cameraGallery',
  cameraTitle = 'cameraTitle',
}

export enum ResultScore {
  resultCaption = 'resultCaption',
  rankedGame = 'rankedGame',
  addSet = 'addSet',
  removeSet = 'removeSet',
  saveResult = 'saveResult',
  resultScoreResult = 'resultScoreResult',
}

export enum RankingEntry {
  points = 'points',
  wins = 'wins',
  losses = 'losses',
}

export enum RankingScreen {
  rankingAddButton = 'rankingAddButton'
}

export enum CheckoutScreen {
  requiresPaymentText = 'requiresPaymentText',
  requiresRefundText = 'requiresRefundText',
  checkoutNotification = 'checkoutNotification',
  checkoutAcceptPayment = 'checkoutAcceptPayment',
  checkoutPaymentsList = 'checkoutPaymentsList',
  checkoutProceedToPayment = 'checkoutProceedToPayment',
  checkoutPromisePayLater = 'checkoutPromisePayLater',
  checkoutPaidAmt = 'checkoutPaidAmt',
  checkoutCapturableAmt = 'checkoutCapturableAmt',
  checkoutSubtotal = 'checkoutSubtotal',
  checkoutSummary = 'checkoutSummary',
  checkoutTitle = 'checkoutTitle',
  checkoutVOP = 'checkoutVOP'
}

export enum CheckoutItemsTypes {
  reservationuser = 'reservationuser',
  yearSubscription = 'yearSubscription',
  fee = 'fee',
  credit = 'checkoutCredit',
}

export enum MarketCommons {
  marketCommonsCheckoutID = 'marketCommonsCheckoutID',
  marketCommonsPrevMarketID = 'marketCommonsPrevMarketID',
  
}

export enum PaymentScreen {
  paymentPaymentMode = 'paymentPaymentMode',
  paymentChangePaymentMode = 'paymentChangePaymentMode',
  paymentTotalForPayment = 'paymentTotalForPayment',
  paymentTotalToCredit = 'paymentTotalToCredit',
  paymentTotalFromCredit = 'paymentTotalFromCredit',
  paymentPrevCreditValue = 'paymentPrevCreditValue',
  paymentNewCreditValue = 'paymentNewCreditValue',
  paymentPaidValue = 'paymentPaidValue',
  paymentCapturableValue = 'paymentCapturableValue',
  paymentRefundedValue = 'paymentRefundedValue',
  paymentFreeCredit = 'paymentFreeCredit',
  paymentDetail = 'paymentDetail',
  paymentTransactionHeading = 'paymentTransactionHeading',
  paymentState = 'paymentState',
  paymentID = 'paymentID',
  paymentButtonPay = 'paymentButtonPay',
  paymentReinitButton = 'paymentReinitButton',
  paymentCancelButton = 'paymentCancelButton',
  paymentChargeButton = 'paymentChargeButton',
  paymentPrintButton = 'paymentPrintButton',
  paymentFinishButton = 'paymentFinishButton',
  paymentCloseButton = 'paymentCloseButton',
  paymentTitle = 'paymentTitle',
  paymentMethodText = 'paymentMethodText',
}

export enum LinkedUsersScreen {
  linkedUserTitle = 'linkedUserTitle',
  linkedUserMessage = 'linkedUserMessage',
}

export enum ManageLinkedUsersScreen {
  manageLinkedUserTitle = 'manageLinkedUserTitle'
}

export enum SubscriptionScreen {
  subscriptionScreenTitle = 'subscrbtionScreenTitle',
  subscriptionMembership = 'subscrbtionMembership',
  subscriptionCurrEnds = 'subscriptionCurrEnds',
  subscriptionStart = 'subscriptionStart',
  subscriptionType = 'subscriptionType',
  subscriptionEnd = 'subscriptionEnd',
  subscriptionPriceVariant = 'subscriptionPriceVariant',
}

export enum CreditScreen {
  creditScreenTitle = 'creditScreenTitle',
  creditHeading = 'creditHeading',
  creditClub = 'creditClub',
  creditAmount = 'creditAmount',
  creditCurrentValue = 'creditCurrentValue',
}

export enum ManageClubScreen {
  manageClubClubName = 'manageClubClubName',
  manageClubCourts = 'manageClubCourts',
  manageClubAddCourtButton = 'manageClubAddCourtButton',
  manageClubSeasonsHeading = 'manageClubSeasonsHeading',
  manageClubAddSeasonButton = 'manageClubAddSeasonButton',
  manageClubProductsHeading = 'manageClubProductsHeading',
  manageClubSaveButton = 'manageClubSaveButton',
  manageClubCourtId = 'manageClubCourtId',
  manageClubCourtType = 'manageClubCourtType',
  manageClubScreenTitle = 'manageClubScreenTitle',
  manageClubPaymentsHeading = 'manageClubPaymentsHeading',
  manageClubPaymentsCurr = 'manageClubPaymentsCurr',
  manageClubAllowedGameTypes = 'manageClubAllowedGameTypes',
  manageClubActiveUntil = 'manageClubActiveUntil',
  manageClubPaymentsPromiseAllowed = 'manageClubPaymentsPromiseAllowed',
  manageClubOnlinePaymentsAllowed = 'manageClubOnlinePaymentsAllowed',
  manageClubAutoCapture = 'manageClubAutoCapture',
  manageClubPayUId = 'manageClubPayUId',
  manageClubTourPrice = 'manageClubTourPrice',
  manageClubAgeRoles = 'manageCluibAgeRoles',
  manageClubGeneralInfo = 'manageClubGeneralInfo',
  manageClubQRIBAN = 'manageClubQRIBAN',
  manageClubCreditPaymentsAllowed = 'manageClubCreditPaymentsAllowed',
  manageClubQRPaymentsAllowed = 'manageClubQRPaymentsAllowed',
  manageClubPaymentsSetting = 'manageClubPaymentsSetting',
}

export enum ManageSeasonScreen {
  manageSeasonSaveButton = 'manageSeasonSaveButton',
  manageSeasonSeasonType = 'manageSeasonSeasonType',
  manageSeasonTour = 'manageSeasonTour',
  manageSeasonPlanFrom = 'manageSeasonPlanFrom',
  manageSeasonPlanTo = 'manageSeasonPlanTo',
  manageSeasonPlanningFinished = 'manageSeasonPlanningFinished',
  manageSeasonLockedFrom = 'manageSeasonLockedFrom',
  manageSeasonLockedTo = 'manageSeasonLockedTo',
  manageSeasonFrom = 'manageSeasonFrom',
  manageSeasonTo = 'manageSeasonTo',
  manageSeasonOpenFrom = 'manageSeasonOpenFrom',
  manageSeasonOpenTo = 'manageSeasonOpenTo',
  manageSeasonCourtsHeading = 'manageSeasonCourtsHeading',
  manageSeasonSeasonHeading = 'manageSeasonSeasonHeading',
  manageSeasonButtonsBasic = 'manageSeasonButtonsBasic',
  manageSeasonButtonsCourts = 'manageSeasonButtonsCourts',
  manageSeasonButtonsPrices = 'manageSeasonButtonsPrices',
  manageSeasonUnlockDate = 'manageSeasonUnlockDate',
  manageSeasonPaid = 'manageSeasonPaid',
  manageSeasonPromised = 'manageSeasonPromised',
  manageSeasonScreenTitle = 'manageSeasonScreenTitle',
  manageSeasonButtonsPricesAdd = 'manageSeasonButtonsPricesAdd',
}

export enum ManageMyClubsScreen {
  myClubsScreenTitle = 'myClubsScreenTitle',
  myClubsMyClubsHeading = 'myClubsMyClubsHeading',
  myClubsRemoveButton = 'myClubsRemoveButton',
  myClubsAddClubSubscription = 'myClubsAddClubSubscription',
  myClubsAddButton = 'myClubsAddButton',
  myClubsSelectClub = 'myClubsSelectClub',
  myClubsMessage = 'myClubsMessage',
}

export enum ManageAllClubsScreen {
  allClubsScreenTitle = 'allClubsScreenTitle',
  
}


export enum NewsFilters {
  allClubs = 'allClubsFilter',
  tournaments = 'tournamentsFilter',
  informations = 'informationsFilter',
}


export enum ReservationFilters {
  openState = 'openState',
  
}


export enum AvatarComponent {
  avatarComponentUnknown = 'avatarComponentUnknown'
}

export enum CheckoutListScreen {
  checkoutListScreenTitle = 'checkoutListScreenTitle'
}

export enum ReservationStatisticsScreen {
  reservationStatisticsScreenTitle = 'reservationStatisticsScreenTitle'
}

export enum AboutAppScreen {
  aboutDeveloper = 'aboutDeveloper',
  aboutContactMail = 'aboutContactMail',
  aboutContactPhone = 'aboutContactPhone',
  aboutAppVersion = 'aboutAppVersion',
  aboutScreenTitle = 'aboutScreenTitle',
  aboutScreenUpdateId = 'aboutScreenUpdateId',
}
