import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Market, MarketOpengame, MarketStatuses, UserRoles } from '../../../../functions/src/interfaces';
import ActionOverlay from '../../../components/ActionOverlay';
import BigButton from '../../../components/BigButton';
import i18n, { tokens } from '../../../i18n';
import { addOpengame, marketApprovalAction, marketDeletionAction } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/reservationReducer';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import { normalize } from '../../../utilities/constants';
import { showToast } from '../../../utilities/toastcontroller';


type Props = {
    isChanged: boolean,
    firebaseId: string,
    opengameData: StateLoader<MarketOpengame<Market>> & {createReservation?:boolean},
    isAllowedToEdit: boolean,
    currentUser: UserOrClub<UserRoles>,
    setMarketId: (newVal: string) => void,
}

function ButtonComponent(props: Props):JSX.Element {

    //const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    const dispatch = useAppDispatch()
    //const [isActionPressed,setIsActionPressed] = useState(false)
    //console.log('VOalm', props)
    const addReservationAction = () => {
      dispatch(addOpengame(props.opengameData.createReservation))
      .then((result) => {
        if (!props.opengameData.data.firebaseId && result.data.marketId && result.data.marketId != "") {
          props.setMarketId(result.data.marketId)
        }
      })
      .catch((e) => {

      })
    }
    const removeReservationAction = () => {
        dispatch(marketDeletionAction(props.opengameData.data.club,props.opengameData.data.firebaseId, "all"))
    }

    //const addToCalendarAction = () => {}

    const marketApproval = (type: 'approve' | 'decline') => {
      dispatch(marketApprovalAction(props.opengameData.data.club, props.firebaseId, type))
      .then((res) => {
        if (res) {
          showToast('Schvaleno')
        }
      })
    }

    return (<View
      style={styles.buttonContainer}>
        <ActionOverlay
        onBackdropPress={()=> {}}
        fullScreen={true}
        isVisible={props.opengameData.isActionPressed}
        />
          {(props.opengameData.data.status == MarketStatuses.open || props.opengameData.data.status == MarketStatuses.new) && (props.opengameData.isChanged == true || !props.opengameData.data.firebaseId) && (props.isAllowedToEdit == true)?
          <BigButton
          onPress={addReservationAction}
          //disabled={true}
          buttonText={i18n.t(tokens.app.screens.opengame.modifyButton)}
          />
          :null}
          {props.opengameData.data.status == MarketStatuses.open && props.opengameData.data.firebaseId && (props.isAllowedToEdit == true) && props.opengameData.isChanged == false?
          <BigButton
          onPress={() => marketApproval("approve")}
          //disabled={true}
          buttonText={i18n.t(tokens.app.screens.opengame.approveButton)}
          />
          :null}
          {props.opengameData.data.status == MarketStatuses.open && props.opengameData.data.firebaseId && (props.isAllowedToEdit == true)?
          <BigButton
          onPress={() => marketApproval("decline")}
          //disabled={true}
          buttonText={i18n.t(tokens.app.screens.opengame.opengameDeclineButton)}
          />
          :null}
          {[MarketStatuses.declined, MarketStatuses.approved].includes(props.opengameData.data.status) && (props.opengameData.data.firebaseId) && (props.isAllowedToEdit == true)? 
          <BigButton 
          onPress={removeReservationAction}
          buttonText={i18n.t(tokens.app.screens.opengame.removeButton)}
          />
          :null}
          
          </View>
    )
}

const styles = StyleSheet.create({
    
    textStyle: {
      
        textAlign: "right", 
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',
        
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
});

export default ButtonComponent
