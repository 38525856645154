import React, { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, Text, TextInput, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import { CheckoutData, Club, Market, MarketLongreservation, MarketReservation, MarketStatuses, Reservation, UserRoles } from '../../../../functions/src/interfaces';
import FormEntry from '../../../components/FormEntryNew';
import FormHeading from '../../../components/FormHeading';
import i18n, { tokens } from '../../../i18n';
import { calcPriceApi } from '../../../store/actions/actionsReservationsNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/reservationReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { normalize } from '../../../utilities/constants';
import { isAdmin, paymentStateEval } from '../../../utilities/funcs';
import { getCurrentUserWithRoles } from '../../../store/selectors';
import { UserOrClub } from '../../../store/reducers/usersReducer';


type MarketData = {
  priceCompDataType: "market",
} & (MarketReservation<Market> | MarketLongreservation<Market>)

type ReservationData = {
  priceCompDataType: "reservation",
} & Reservation

type Props = {
    isChanged: boolean,
    firebaseId: string,
    reservationData: (MarketData | ReservationData) & {manualPrice?: number} ,
    allValid?: boolean,
    type: 'market' | 'reservation',
    dispatchChanges?: (callback: () => any) => void
}

function PriceComponent(props: Props):JSX.Element {
    //console.log('VOalm', props)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const reservationPrice: StateLoader<CheckoutData> & {isCalculated:boolean} = useAppSelector((state:RootStore) => state.reservations.managedReservationPrice, shallowEqual)
    const [mounted, setMounted] = useState(false)
    const [resData, setResData] = useState<MarketData | ReservationData | null>(null)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const selectedClub: Club = useAppSelector((state:RootStore) => state.auth.allClubs[state.auth.selectedClub], (a, b) => JSON.stringify(a) == JSON.stringify(b))

    useEffect(() => {
      let evalCols = [
        'gamePlayers',
        'gameType',
        'players',
        'from',
        'to',
        'dateFrom',
        'dateTo',
        'timeFrom',
        'timeTo',
        'court',
        'season',
      ]
      var changed = false
      if (!resData) {
        changed = true
        console.log('No res data')
      } else if (!props.reservationData) {
        dispatch({type: 'PRICE_DATA', data: {isLoaded: false, isSuccess: false, isCalculated: false, error: {}}})
        console.log('No prop data')
      } else {
        for (let i = 0; i < evalCols.length; i++) {
          let col = evalCols[i]
          //console.log('Evaluating ' + String(resData[col]) + ' ' + String(props.reservationData[col]))
          if (resData[col] != props.reservationData[col]) {
            changed = true
          }
        }
      }
      console.log('Changed ' + String(changed))
      if (changed == true) {
        setResData({...props.reservationData})
      }
      
    }, [props.reservationData])

    useEffect(() => {
        //console.log('VOalm', props, props.isChanged, props.allValid)
        //if (mounted == false) {
          if (props.reservationData) {
            if (props.firebaseId && props.reservationData.priceCompDataType == 'reservation') {
              dispatch(calcPriceApi(props.reservationData as any, 'reservation', () => {}))
            } else if (props.isChanged && props.allValid) {
              dispatch(calcPriceApi(props.reservationData as any, 'marketCalc', () => {}))
            } else if (props.firebaseId && props.type == 'market') {
              dispatch(calcPriceApi(props.reservationData as any, 'marketFetch', () => {}))
            } 
          } else {
            console.log('Not calling, no res yet')
          }
          //setMounted(true)
        //} 
        
        
        return () => {
          dispatch({type: 'PRICE_DATA', data: {isLoaded: false, isSuccess: false, isCalculated: false, error: {}}})
        }
      }, [resData, props.isChanged]) 

    

    return (reservationPrice.isLoaded && props.allValid == true?<>
        <FormHeading heading={i18n.t(tokens.app.screens.manageReservation.reservationPriceHeading)}
        //editButton={props.firebaseId?() => navigation.navigate('ManageMarket', {type: props.reservationData.type, uid: props.reservationData.uid, marketId: props.reservationData.priceCompDataType == "reservation" ? props.reservationData.reservationRequestId : props.reservationData.firebaseId}): null}
        //editButtonCaption={'Souhrn'}
        />
        <FormEntry caption={i18n.t(tokens.app.screens.manageReservation.reservationPriceHeading)}
        editable={false}>
        
        <View style={{flexDirection: "row"}}>
        {isAdmin(currentUser, selectedClub.firebaseId) && (props.reservationData.status == MarketStatuses.new || props.reservationData.status == MarketStatuses.open)? 
          <TextInput
          placeholder={String(reservationPrice.data.price)}
          placeholderTextColor={"black"}
          maxFontSizeMultiplier={1.2} style={styles.textStyle}
          value={String(props.reservationData.manualPrice || "")}
          onChangeText={(text) => props.dispatchChanges(
            () => {
              return {manualPrice: text == ""? 0 :parseInt(text)}
            }
          )}
          />: 
          <Text maxFontSizeMultiplier={1.2} style={styles.textStyle}>
            {String(reservationPrice.data.price)}
          </Text>}
          <Text maxFontSizeMultiplier={1.2} style={styles.textStyle}>
            {selectedClub.currency}
          </Text>
        </View>
        
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.manageReservation.reservationPriceState)}
        editable={false}>
        <Text maxFontSizeMultiplier={1.2} style={styles.textStyle}>
          {i18n.t(paymentStateEval({...reservationPrice.data, isCalculated: reservationPrice.isCalculated, isManual: !!props.reservationData.manualPrice}))}
        </Text>
        </FormEntry>
      </>
      :<>
      <FormHeading heading={i18n.t(tokens.app.screens.manageReservation.reservationPriceHeading)}/>
      <ActivityIndicator size={"small"} />
      </>
    )
}

const styles = StyleSheet.create({
    
    textStyle: {
      
        textAlign: "right", 
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',
        
    }
});

export default PriceComponent
