import { AppThunk } from "../hooks"
import firebase from 'firebase/compat/app';
import { ContactsActions } from "../reducers/contactsReducer";
import { removeDicretics } from "../../utilities/funcs";

export const addContact = (User): AppThunk<void> => {
    
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log(User)
        
        const fb = getFirebase()
        const firestore = getFirestore()
        const firestoreuid = fb.auth().currentUser.uid
        //console.log('state', firestoreuid)
        const record = {
            uid: firestoreuid,
            contactid: User.uid,
            trustedFlag: 0,
            friendRequestReceived: 0,
            friendRequestSent: 0,
            mutualFlag: 0,
            amTrustedFlag: 0,
            lastRead: new Date(),
            contactType: "",
            contactGroup: "",
            contactDetail: firestoreuid + '_' + User.uid,
            counterContactDetail: User.uid + '_' + firestoreuid
        }


        //const rootRef = getFirebase().firestore()
            const entityRef = firestore.collection('contacts').doc(firestoreuid)
            const userRef = firestore.collection('userspublic').doc(User.uid)
            firestore.runTransaction(async (t) => {
                t.set(entityRef, {uid: firestoreuid})
                const contactRef = entityRef.collection('contactlist').doc(User.uid)
                
                t.set(contactRef, record)
                var amInContacts = {am_in_contacts: firebase.firestore.FieldValue.arrayUnion(firestoreuid)}
                t.update(userRef, amInContacts)
                
        })
        .then(
            () => {
                dispatch({type: 'ADD_CONTACT_SUCCESS', record})
            }
        ).catch(
            (e) => {
                //console.log(e)
                dispatch({type: 'ADD_CONTACT_ERROR', e})
            }
        )
        
    }
}
export const removeContact = (User):AppThunk<void> => {
    
    
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log(User)
        const fb = getFirebase()
        const firestore = getFirestore()
        const firestoreuid = fb.auth().currentUser.uid
        //console.log('state', firestoreuid)
        

        
        const entityRef = firestore.collection('contacts').doc(firestoreuid)
        
        const contactRef = entityRef.collection('contactlist').doc(User.uid)
        const userRef = firestore.collection('userspublic').doc(User.uid)
        firestore.runTransaction(
            async (T) => {
                const entity = await entityRef.get()
                if (entity) {
                    const contact = await contactRef.get()

                    T.delete(contactRef)
                    T.update(userRef, {am_in_contacts: firebase.firestore.FieldValue.arrayRemove(firestoreuid)})
                }
            }
        )
        .then(
            () => {
                dispatch({type: 'REMOVE_CONTACT_SUCCESS', data: {}})
            }
        ).catch(
            (e) => {
                //console.log(e)
                dispatch({type: 'REMOVE_CONTACT_ERROR', e})
            }
        )
        
    }
}

export const searchContacts = (text: string): AppThunk<void> => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log(getState())
        const fb = getFirebase()
        const firestore = getFirestore()
        const firestoreuid = fb.auth().currentUser.uid
        //console.log(text, text.length)
        const searchData = (word: string, column: string) => {
            const entityRef = firestore.collection('userspublic')
            const query = entityRef.orderBy(column).startAt(word).endAt(word+'\uf8ff').limit(5)
            
            return query.get()
        }
        const capitalizeFirstLetter = (string: string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
          }
        if (text.length >= 2) {
            //let textDia = removeDicretics(text)
            //console.log(textDia)
            const splitText = text.split(' ')
            const promiseChain: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[]  = []
            splitText.forEach(word => {
                if (word.length >= 2) {
                    let searchWord = capitalizeFirstLetter(word)
                    promiseChain.push(searchData(searchWord, 'first_name'))
                    promiseChain.push(searchData(searchWord, 'family_name'))
                    promiseChain.push(searchData(searchWord, 'phone'))
                    promiseChain.push(searchData(searchWord, 'first_name_dia'))
                    promiseChain.push(searchData(searchWord, 'family_name_dia'))
                } 
            });
            const out = {}
            const data = []
            //console.log("WTF")
            Promise.all(promiseChain).then((results) => {
                //console.log(results)
                results.forEach(result => {
                    //const subset = {}
                    result.forEach(doc => {
                        //console.log(doc.id, '=>', doc.data());
                        firestoreuid != doc.id ? out[doc.id] = doc.data() : null
                    });
                    //out.extend(subset)
                    
                }
                    
                )
                /* Object.keys(out).forEach(
                    key => {
                        data.push(out[key])
                    }
                ) */
                //console.log('Search results', out)
                dispatch({type: ContactsActions.contactsSearchSuccess, data : out})
            }).catch((e) => {
                //console.log(e)
                dispatch({type: 'SEARCH_ERROR'})
                })
            
        
        
            
            
        } else {
            dispatch({type: ContactsActions.contactsSearchSuccess, data: []})
        }
    }
    
}
export const updateTrustedContact = (data: {contactid: string, trustedFlag: boolean}): AppThunk<void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const fb = getFirebase()
        const firestore = getFirestore()
        const firestoreuid = fb.auth().currentUser.uid
        const entityRef = firestore.collection('contacts').doc(firestoreuid).collection('contactlist').doc(data.contactid)
        entityRef.update({trustedFlag: data.trustedFlag})
        .then(() => {
            dispatch({type: 'TRUSTED_UPDATE_SUCCESS', data: []})
        })
        .catch((e) => {

        })
        
    }
}
/**
 * .then(
                (snapshot) => {
                    const data = []
                    snapshot.forEach(doc => {
                        //console.log(doc.id, '=>', doc.data());
                        data.push(doc.data())
                    });
                console.log('Data contacts',data)
                dispatch({type: 'SEARCH_SUCCESS', data})
                }
                
            ).catch((e) => {
                dispatch({type: 'SEARCH_ERROR'})
                })
 */

