import { useEffect, useRef, useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { store } from '../../App'
import firebase from 'firebase/compat/app';
//import { Auth } from 'firebase/auth/react-native'
import { FirebaseStorage } from 'firebase/storage'
import { auth } from './firebase/config';
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RootStackParamList } from '../screens/NavigationScreen/NavigationScreen'
//import type { RootState, AppDispatch } from '../store'

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  {getFirebase: () => firebase.app.App, getFirestore: () => firebase.firestore.Firestore, getFileStorage: () => FirebaseStorage},
  AnyAction
>

type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;

//type DispatchFunc = () => AppDispatch
export const useAppDispatch = () => useDispatch<TypedDispatch<RootState>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppNavigation = () => useNavigation<StackNavigationProp<RootStackParamList>>()
export const useWindowResize = () => {
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)

    const listener = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener("resize", listener)
        return window.removeEventListener("resize", listener)
    }, [])

    return {
        width, height
    }
}

export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
      const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      }, {});
      if (Object.keys(changedProps).length > 0) {
        //console.log('Changed props:', changedProps);
      }
      prev.current = props;
    });
  }