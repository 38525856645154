

const ENV_VARS = {
  dev: {
      name: "MiTennis (dev)",
      variant: "test",
      apiUrl: "[your.production.api.here]",
      apiKey: "AIzaSyAqSQ4yVv2dV0JPtaKNDmgpIyJqJ-QrzQw",
      authDomain: "slovan-6cf12.firebaseapp.com",
      databaseURL: "https://slovan-6cf12.firebaseio.com",
      projectId: "slovan-6cf12",
      storageBucket: "slovan-6cf12.appspot.com",
      messagingSenderId: "11596641041",
      appId: "1:11596641041:ios:875eb6572c0f3f075452b0",
      measurementId: null,

      stripeKey: "pk_test_51L3Pf6J2GdyfHw2y56DJY9hMa2thuPQxOSnhTFpTHrFYb5MnCCHqTPk6lbBJTPb0Ku41GnFZgfG1t3IfbSS2RwKc00VIxWYcMT",
      functionsUrlBase: "https://us-central1-slovan-6cf12.cloudfunctions.net",
      webUrl: "http://slovan-6cf12.web.app",
      easProjectId: "e4d1be53-f849-46e7-80bf-2e6230e59bde",
      package: "com.mira23.slovan",
      googleServicesFile: "./enviroments/test/google-services.json",
  },
  test: {
      name: "MiTennis (test)",
      variant: "test",
      apiUrl: "[your.production.api.here]",
      apiKey: "AIzaSyAqSQ4yVv2dV0JPtaKNDmgpIyJqJ-QrzQw",
      authDomain: "slovan-6cf12.firebaseapp.com",
      databaseURL: "https://slovan-6cf12.firebaseio.com",
      projectId: "slovan-6cf12",
      storageBucket: "slovan-6cf12.appspot.com",
      messagingSenderId: "11596641041",
      appId: "1:11596641041:ios:875eb6572c0f3f075452b0",
      measurementId: null,

      stripeKey: "pk_test_51L3Pf6J2GdyfHw2y56DJY9hMa2thuPQxOSnhTFpTHrFYb5MnCCHqTPk6lbBJTPb0Ku41GnFZgfG1t3IfbSS2RwKc00VIxWYcMT",
      functionsUrlBase: "https://us-central1-slovan-6cf12.cloudfunctions.net",
      webUrl: "http://slovan-6cf12.web.app",
      easProjectId: "e4d1be53-f849-46e7-80bf-2e6230e59bde",
      package: "com.mira23.slovan",
      googleServicesFile: "./enviroments/test/google-services.json",
  },
  prod: {
      name: "MiTennis",
      variant: "prod",
      apiUrl: "[your.production.api.here]",
      apiKey: "AIzaSyAuVdDoV444rXZeD3B8Xz_ucPwi4_4SpuM",
      authDomain: "mitennis-c346c.firebaseapp.com",
      projectId: "mitennis-c346c",
      storageBucket: "mitennis-c346c.appspot.com",
      messagingSenderId: "23221952132",
      appId: "1:23221952132:web:084a5afbd542bb36e8ecd1",
      measurementId: "G-Y6EWR2YC9R",
      stripeKey: "pk_live_51L3Pf6J2GdyfHw2yYrlkkKZ3Yt3SlBzkZlOsxeDpVCoXKbws16TKYlG8rWezbFqTJq2qQhtKuxDNAD7QdSh6241X00dawMqe61",
      functionsUrlBase: "https://us-central1-mitennis-c346c.cloudfunctions.net",
      webUrl: "http://mitennis-c346c.web.app",
      easProjectId: "e4d1be53-f849-46e7-80bf-2e6230e59bde",
      package: "com.mira23.slovan",
      googleServicesFile: "./enviroments/prod/google-services.json",
  }
};

module.exports = ENV_VARS