import { NavigationProp, ParamListBase, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { Button, FlatList, Keyboard, Text, TextInput, Pressable, View, AppState } from 'react-native'
import { Badge } from 'react-native-elements';
import Ionicons from '@expo/vector-icons/Ionicons';
import { NavigationRoute } from 'react-navigation';

import { normalize, windowHeight, windowWidth } from '../utilities/constants';
import { mainColor } from '../utilities/globalStyles';

type Props = {
  state: any, 
  descriptors: {
    [route: string]: {options: {tabBarLabel: any, title: any, tabBarAccessibilityLabel: any, tabBarTestID: any}}
  }, 
  navigation: any,
  icons: {
    [route: string]: {
      icon: any,
      badge: number,
      onPress: () => void
    }
  }
}

function MyTabBar(props: Props) {
  //console.log('Taba ', props)

    const { state, descriptors, navigation, icons } = props
    return (
      <View style={{ 
        flexDirection: "row",
        backgroundColor:"white",
        height:normalize(50),
        borderWidth: 1, 
        borderColor: 'lightgrey',
        borderRadius:0,
        justifyContent:"center",
        alignItems:"center" ,
        width: "100%"
        
        }}>
          <View style={{
            alignSelf:"center",
            flexDirection: "row",
            width: Math.min(windowWidth,windowHeight),
            justifyContent:"space-between",
            height: normalize(50),
          }}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          //console.log('Options', options)
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;
  
          const onPress = () => {
            props.icons[label].onPress()
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
            });
  
            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };
  
          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };
  
          return (
            <Pressable
              key={index}
              role="button"
              aria-label={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={{ flex: 1, flexDirection: "column", justifyContent: "center" }}
            >
              <View
              style={{flex:1, flexDirection: "column", alignItems: "center", justifyContent:"center"}}
              >
                <View
                style={{alignItems: "center"}}
                >
                  <Ionicons name={icons[label].icon} color={isFocused ? mainColor : 'darkgrey'} size={normalize(30)} 
                  />
                  {icons[label].badge > 0?
                  <Badge
                    value={icons[label].badge}
                    containerStyle={{ position: 'absolute', top: -4, right: -4}}
                    badgeStyle={{backgroundColor: "red" }}
                  />
                  :null}
                <Text maxFontSizeMultiplier={1.2} style={{ color: isFocused ? mainColor : 'darkgrey', textAlign:"center", fontSize: normalize(10) }}>
                  {label}
                </Text>
                </View>
              </View>
            </Pressable>
          );
        })}
        </View>
      </View>
    );
  }

  export default MyTabBar