import React, { useEffect, useState } from 'react';
import { Alert, Image, Platform, Text, TextInput, Pressable, View, Switch } from 'react-native';
import styles from './styles';
//import DelayInput from "react-native-debounce-input";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { News, NewsTypes, UserRoles } from '../../../functions/src/interfaces';
import CommentView from '../../components/CommentView';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import FormEntry from '../../components/FormEntryNew';
import FormHeading from '../../components/FormHeading';
import MessagesList from '../../components/MessagesList';
import PlayerListApplied from '../../components/PlayerListApplied';
import WebWrapper from '../../components/WebWrapper';
import i18n, { tokens } from '../../i18n';
import { addEditMessage, addNewsEntry, addPlayerToNewsApplied, manageNewsToState, removeNews, removePlayerToNewsApplied } from '../../store/actions/actionsNews';
import { RootStore } from '../../store/reducers/rootReducer';
import { newsTypes, normalize } from '../../utilities/constants';
import { monthString, objectToPicker } from '../../utilities/funcs';
import { mainFont, mainFontBold } from '../../utilities/globalStyles';
import { Route, useNavigation } from '@react-navigation/native';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { UserOrClub } from '../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../store/selectors';
import { NewsActions } from '../../store/reducers/newsReducer';
import ImageComponent from './Components/ImageComponent';
import CustomPickerSelect from '../../components/CustomPickerSelect/CustomPickerSelect';
import BigButton from '../../components/BigButton';

type Props = {
    route: Route<'ManageNewsScreen', {clubId: string, newsId: string}>
}


function ManageNewsScreen(props: Props) {
    const {clubId, newsId} = props.route.params
    
    
    const usersData = useAppSelector((state: RootStore) => state.users.usersData)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    const newsData: News = useAppSelector((state: RootStore) => state.news.managedNews?.data, shallowEqual)
    const loading  = useAppSelector((state: RootStore) => state.news.managedNews?.isLoaded, shallowEqual)
    const [capturedBlob, setCapturedBlob] = useState<Blob>(null)
    const navigation = useAppNavigation()
    const [editFlag, setEditFlag] = useState(newsId?false:true)
    const dispatch = useAppDispatch()
    
    const [message, setMessage] = useState({message: "", uid: currentUser.type == "user" ? currentUser.uid : currentUser.firebaseId})

    useEffect(() => {
        var unsubscribe = () => {}
        //setLoading(true)
        dispatch(manageNewsToState({
            firebaseId: newsId,
            uid: null,
            notifyAllMembers: false,
            dueDate: new Date,
            club: clubId,
            messages: [],
            newsPhoto: undefined,
            text: "",
            appliedMembers: false,
            appliedPlayers: [],
            type: NewsTypes.information
        },
        (unsub) => {
            unsubscribe = unsub
        }))

        return () => unsubscribe()
    }, [newsId, clubId])

    

    

    const amAllowedToEdit = (uid, itemUid) => {
        //console.log('joke ', uid, props.auth.uid, itemUid, props.auth.uid)
        if (currentUser.type == "user") {
            if (uid == currentUser.uid) {
                return true
            } else if (itemUid == currentUser.uid) {
                return true
            } else if (currentUser.isGod == true){
                return true
            } else if (currentUser.isAdmin == true) {
                return true
            } else {
                return false
            }
        } else if (currentUser.type == "clubs") {
            if (itemUid == currentUser.uid) {
                return true
            } else {
                return false
            }
        }
        
        
    }

    const userNameCaption = (uid: string, usersData) => {
        //console.log('All users data: ', usersData)
        let user = usersData[uid]
        if (!usersData[uid]) {
            return "XNA"
        } else if (user.type == "user") {
            return user.first_name + ' ' + user.family_name
        } else {
            return user.club_name
        }
    }

    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        
        dispatch({type: NewsActions.newsChangeManaged, data: changes })
    }

    
    return (
        
        <View style={styles.container}>
            <WebWrapper>
            {loading == false?
            <KeyboardAwareScrollView  
            keyboardDismissMode='on-drag'
            keyboardShouldPersistTaps="handled"
            enableResetScrollToCoords={false}
            extraHeight={Platform.select({ android: 200, ios: 200 })}
            /*contentContainerStyle={{ flexGrow: 1, padding: 10 }}*/
            >
            <FormHeading 
    
            heading={userNameCaption(newsData.uid, usersData)} 
            editButton={amAllowedToEdit(currentUser,newsData.uid)?() => setEditFlag(prev => !prev):null}
            />
            <ImageComponent
            editFlag={editFlag}
            photo={newsData.newsPhoto}
            dispatchActionForChanges={dispatchChanges}
            setCapturedBlob={setCapturedBlob}
            selectedBlob={capturedBlob}
            />
            {editFlag == true?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.news.expirationDate)}
            editable={true}
            >
                <View style={{alignSelf: 'flex-end'}}>
                <CustomDatePicker 
                date={newsData.dueDate} 
                setDate={(newDate) => dispatch({type: "CHANGE_MANAGED_NEWS", data: {dueDate: newDate} })}
                fontStyle={{fontFamily: mainFont, fontSize: normalize(14)}}
                />
                </View>
            </FormEntry>
            :
            <View style={{flexDirection: "row", flex: 1}}>
                <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFontBold, fontSize: normalize(14)}}>{monthString(newsData.dueDate)}</Text>
            </View>
            }
            <View style={{
                padding: 5,
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%"
            }}>
               {editFlag == true?
               <TextInput maxFontSizeMultiplier={1.2} multiline
               style={{
                   width: "100%",
                   height: 100,
                   borderWidth: 1,
                   borderColor: "grey",
                   borderRadius: 5,
                   backgroundColor: "white",
                   verticalAlign: "top",
                }}
                placeholder={i18n.t(tokens.app.screens.news.messagePlaceholder)}
                onChangeText={(itemValue) => dispatch({type: "CHANGE_MANAGED_NEWS", data: {text: itemValue} })
                }
                value={newsData && newsData.text ? newsData.text : ""}
               />
               :
               <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFont, fontSize: normalize(14)}}>
                   {newsData && newsData.text ? newsData.text : ""}
               </Text>
                }
            </View>
            {newsData.appliedMembers ?
            <PlayerListApplied
            isEditor={amAllowedToEdit(newsData.uid,undefined)}
            handler={(callback) => {
                let newState = callback(newsData)
                dispatch({type: "CHANGE_MANAGED_NEWS", data: newState})
            }}
            //navigation={props.navigation}
            playerPress={() =>  {
                dispatch(addPlayerToNewsApplied(currentUser.uid, newsData.firebaseId, newsData.club, (bool, context) => console.log(JSON.stringify(context))))
            }}
            addPlayerAction={() => {
                
            }}
            //applyAction={() =>  {}}
            removeAction={() =>  {
                dispatch(removePlayerToNewsApplied(currentUser.uid, newsData.firebaseId, newsData.club, (bool, context) => console.log(JSON.stringify(context))))
            }}
            //selection={selection}
            document={newsData}
            disabled={true}
            alreadySelected={newsData.appliedPlayers?newsData.appliedPlayers:[]}
            allowApprove={false}
            />
            
            :null}
            {editFlag == false && newsData.firebaseId?
            <>
            <MessagesList data={newsData.messages?newsData.messages:[]}/>
            <CommentView
            //bottomInputVisible={bottomInputVisible} 
            //setBottomInputVisible={setBottomInputVisible} 
            setMessage={(message) => setMessage((prevValue) => {return {...prevValue, message: message}})}
            message={message}
            submitMessage={
                (mes, callback) => {
                    dispatch(addEditMessage(mes,newsData.firebaseId,newsData.club,(res, context) => {
                        if (res == true) {
                            setMessage({message: "", uid: currentUser.uid})
                            callback(true)
                            //setBottomInputVisible(false)
                            console.log('Ok:' + JSON.stringify(context))
                        } else {
                            callback(false)
                            console.log('Error:' + JSON.stringify(context))
                            Alert.alert("Warning", context.message? context.message: "Some unknown error")
                        }
                    }))
                }
            }
            />
            
            </>:null}

            {editFlag == true?

            <FormEntry 
            caption={i18n.t(tokens.app.screens.news.notifyAllClubmembers)}
            editable={true}
            >

                <Switch 
                style={{alignSelf: "flex-end"}}
                value={newsData.notifyAllMembers}
                onValueChange={(value) => {
                    dispatch({type: "CHANGE_MANAGED_NEWS", data: {notifyAllMembers: value} })}}
                    //setNewsData(oldvalue => {return {...oldvalue, notifyAllMembers: value}})}}
                />
            </FormEntry>
            :null}
            {editFlag == true?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.news.includeApplicationList)}
            editable={true}
            >
                <Switch 
                style={{alignSelf: "flex-end"}}
                value={newsData.appliedMembers}
                onValueChange={(value) => {
                    dispatch({type: "CHANGE_MANAGED_NEWS", data: {appliedMembers: value} })
                    
                }}
                />
            </FormEntry>
            :null}
            {editFlag == true?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.news.newsType)}
            editable={true}
            >
                <CustomPickerSelect
                onValueChange={(value) => {
                    dispatch({type: "CHANGE_MANAGED_NEWS", data: {type: value} })
                }
                }
                value={newsData.type}
                placeholder={{ label: i18n.t(tokens.app.screens.news.newsType), value: undefined }}
                /* disabled={newReservation.mainUid == props.auth.uid?false:true} */
                items={objectToPicker(newsTypes)} />
            </FormEntry>
            :null}
            {editFlag == true?
            <View
            style={{flexDirection: "row", flex: 1}}>
                <BigButton
                onPress={() => {
                    dispatch(addNewsEntry(newsData, capturedBlob, (res, context) => {
                        if (res == true) {
                            navigation.goBack()
                        } else {
                            console.log('Callback of news addition' + JSON.stringify(context))
                        }
                        
                    }))
                }}
                buttonText={i18n.t(tokens.app.screens.news.saveButton)}
                />
        

                <BigButton
                onPress={() => {
                    dispatch(removeNews(newsData.firebaseId, newsData.club, (res, context) => {
                        if (res == true) {
                            navigation.goBack()
                        } else {
                            console.log('Callback of news removal' + JSON.stringify(context))
                        }
                        
                    }))
                }}
                buttonText={i18n.t(tokens.app.screens.news.removeButton)}
                />
            </View>
            :null}
            
            </KeyboardAwareScrollView>
            :null}
            </WebWrapper>
        </View>
        
    )
}


export default ManageNewsScreen



