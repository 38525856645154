import React from 'react'
import { Switch, Text, TextInput, View } from 'react-native'
import BigButton from '../../../components/BigButton'
import FormEntry from '../../../components/FormEntryNew'
import FormHeading from '../../../components/FormHeading'
import i18n, { tokens } from '../../../i18n'
import { useAppNavigation } from '../../../store/hooks'
import { globalStyleSheet } from '../../../utilities/globalStyles'
import { showToast } from '../../../utilities/toastcontroller'
type Props = {
    profileData: any,
    uid: string,
    dispatchActionForChanges: (callback) => void,
    editProfile: (data: {[s:string]: any}, callback: (bool, context) => void) => void
}
function ProfileData(props: Props):JSX.Element {
    const {profileData,uid} = props
    const navigation = useAppNavigation()

    const dispatchChange = (callback: (params) => void) => {
        props.dispatchActionForChanges(
          callback
        )
    }
    return (
        profileData.isLoaded?
            <View>  
            <FormHeading heading={i18n.t(tokens.app.screens.profile.profileDetail)}/>
            <FormEntry
            caption={i18n.t(tokens.app.screens.profile.profileEmail)}
            editable={false}
            >
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{profileData.data.email}</Text>
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profileFirstName)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.first_name}
                textAlign={'right'}
                onChangeText={(text) => {dispatchChange(() => {return { first_name: text}})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profileFamilyName)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.family_name}
                textAlign={'right'}
                onChangeText={(text) => {dispatchChange(() => {return {family_name: text}})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profilePhone)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.phone}
                textAlign={'right'}
                onChangeText={(text) => {dispatchChange(() => {return {phone: text}})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profileAddress)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.address}
                textAlign={'right'}
                onChangeText={(text) => {dispatchChange(() => {return {address: text}})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.profile.profileCity)}
            editable={true}
            >
                <TextInput maxFontSizeMultiplier={1.2}
                style={globalStyleSheet.inputOk}
                value={profileData.data.city}
                textAlign={'right'}
                onChangeText={(text) => {dispatchChange(() => {return {city: text}})}}
                />
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.registration.termsAndConditions)}
            editable={true}
            >
                 <Switch 
                style={{alignSelf: "flex-end"}}
                value={profileData.data.termsAndConditions}
                onValueChange={(value) => {dispatchChange(() => {return {termsAndConditions: value}})}}
                />
               
            </FormEntry>
            
            <View style={{flexDirection: "row", flex: 1}}>
                <BigButton
                buttonText={i18n.t(tokens.app.screens.profile.profileSaveButton)}
                onPress={() => {
                    props.editProfile({
                        ...profileData.data,
                        uid: uid,    
                    },
                    (bool, content) => {
                        if (bool == true) {
                            navigation.goBack()
                        } else {
                            showToast(content.message)
                            console.log('Error saving profile data' + JSON.stringify(content))
                        }
                    })
                }}
                />
                
            </View>
            </View>:null
    )
}

export default ProfileData