import { isAnyRole } from "../../../../functions/src/funcs"
import { MarketStatuses, ReservationStatuses, UserRoles } from "../../../../functions/src/interfaces"
import i18n, { tokens } from "../../../i18n"
import { UserOrClub } from "../../../store/reducers/usersReducer"
import { validateTextTime } from "../../../utilities/validators"


export const validations = (season) => {
    if (season) {
        return {
            uid: {validity: true, error: "", method: () => {return {bool: true, context: ""}}},
            type: {validity: true, error: "", method: () => {return {bool: true, context: ""}}},
            season: {validity: true, error: "", method: (newState) => {
                
                //console.log(currSeason)
                
                if (newState.season == "") {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorSeasonNTBSelected)}
                } else {
                    return {bool: true, context: ""}
                }
                    
            }},
            from: {validity: true, error: "", method: (newState) => {
                if (newState.season == "") return {bool: false, context: ""}
                let currSeason = season
                //console.log(currSeason)
                
                if (newState.from < new Date && newState.status != "approved") {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorLongDateFuture)}
                } else if (newState.from < currSeason.from) {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorLongDateOOB)}
                } else {
                    return {bool: true, context: ""}
                }
                    
            }},
            to: {validity: true, error: "", method: (newState) => {
                if (newState.season == "") return {bool: true, context: ""}
                let currSeason = season
                let to = new Date(currSeason.to)
                to.setHours(23,59,59)
                if (newState.to > to) {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorLongDateOOB)}
                } else {
                    return {bool: true, context: ""}
                }
                    
            }},
            dueDate: {validity: true, error: "", method: (newState) => {
                let nd = new Date
                let fut = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate() + 14)
                //console.log('dlouhodoba status ', newState)
                if (newState.dueDate < new Date && newState.status != "approved") {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorLongDateFuture)}
                } else {
                    return {bool: true, context: ""}
                }
                    
            }},
            timeFrom: {validity: true, error: "", method: (newState) => {
                if (newState.season == "") return {bool: true, context: ""}
                let currSeason = season
                //console.log(currSeason)
                
                if (parseInt(newState.timeFrom) < currSeason.openFrom || parseInt(newState.timeFrom) > currSeason.openTo) {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeNotOpenHours)}
                } else if (!validateTextTime(newState.timeFrom)) {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeNotDivisible)}
                } else {
                    return {bool: true, context: ""}
                }
                    
            }},
            timeTo: {validity: true, error: "", method: (newState) => {
                if (newState.season == "") return {bool: true, context: ""}
                let currSeason = season
                //console.log(currSeason)
                
                if (parseInt(newState.timeTo) < currSeason.openFrom || parseInt(newState.timeTo) > currSeason.openTo) {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeNotOpenHours)}
                } else if (parseInt(newState.timeTo) < parseInt(newState.timeFrom)) {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeToLTTimeFrom)}
                } else if (!validateTextTime(newState.timeTo)) {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeNotDivisible)}
                } else {
                    return {bool: true, context: ""}
                }
                    
            }},
            weekRecur: {validity: true, error: "", method: () => {return {bool: true, context: ""}}},
            court: {validity: true, error: "", method: (newState) => {
                if (newState.season == "") return {bool: true, context: ""}
                let currSeason = season
                //console.log(currSeason)
                
                if (!currSeason.courtsList[newState.court]?.selected) {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorCourtNotSelected)}
                } else {
                    return {bool: true, context: ""}
                }
                    
            }},
            text: {validity: true, error: "", method: () => {return {bool: true, context: ""}}},
            messages: {validity: true, error: "", method: () => {return {bool: true, context: ""}}},
            requestDate: {validity: true, error: "", method: (newState) => {
                if (newState.season == "") return {bool: true, context: ""}
                let currSeason = season
                //console.log(newState.requestDate, currSeason.planFrom.toDate(), newState.requestDate, currSeason.to.toDate())
                if (newState.requestDate >= currSeason.planFrom && newState.requestDate <= currSeason.to) {
                    return {bool: true, context: ""}
                } else {
                    return {bool: false, context: i18n.t(tokens.app.screens.longreservation.errorNotWIPlanPer)}
                }
                
            }
            },
        }

    } else {
        return {}
    }

}

export const isAllowedToEdit = (currentUser: UserOrClub<UserRoles>, reservationOwner: string, status: MarketStatuses, marketClub: string) => {
    if (!currentUser) {
        return false
    } else if (currentUser.type == "clubs") {
        if (marketClub == currentUser.firebaseId) {
            return true
        } else {
            return false
        }
    } else if (currentUser.uid == reservationOwner) {
        if (status == MarketStatuses.open) {
            return true
        } else if (status == MarketStatuses.blocked) {
            return true
        } else if (status == MarketStatuses.new) {
            return true
        } else if (status == MarketStatuses.approved) {
            return true
        } else {
            return false
        }
        return true
    } else if (currentUser.isGod == true) {
        return true
    } else if (currentUser.isAdmin == true) {
        return true
    } else {
        return false
    }
}

export const isAllowedToApprove = (currentUser: UserOrClub<UserRoles>, marketClub: string) => {
    if (currentUser.type == "user") {
        return isAnyRole(['isGod','isAdmin'], currentUser)
    } else if (currentUser.type == "clubs") {
        if (marketClub == currentUser.firebaseId) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}