import React from "react";
import FormEntry from "../../../components/FormEntryNew";
import { Text, TextInput, Pressable, View } from "react-native";
import i18n, { tokens } from "../../../i18n";
import { StateLoader } from "../../../utilities/interfaces";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../../store/hooks";
import { Club, Season, UserRoles } from "../../../../functions/src/interfaces";
import { monthString, shallowEqualJSON } from "../../../utilities/funcs";
import { RootStore } from "../../../store/reducers/rootReducer";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { AuthActions } from "../../../store/reducers/authReducer";
import { UserOrClub } from "../../../store/reducers/usersReducer";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import FormHeading from "../../../components/FormHeading";
import BigButton from "../../../components/BigButton";
import { seasonTypes } from "../../../utilities/constants";



type SeasonProps = {
    item: Season,
    seasonId: string,
}
const SeasonComponent: React.FC<SeasonProps> = (props) => {
    const navigation = useAppNavigation()

    //console.log(props.item)
    return (
        <Pressable 
        onPress={() => {
            navigation.navigate('ManageSeasonScreen', {seasonId: props.item.firebaseId})
        }}
        >
            
            <FormEntry 
            caption={seasonTypes[props.item.type] + ' ' + String(props.item.tour)}
            editable={false}
            >
                <View style={{alignSelf: "flex-end"}}>
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{monthString(props.item.from) + ' - ' + monthString(props.item.to)}</Text>
                </View>
            </FormEntry>
            
        </Pressable>
    )
}

type Props = {
    currentUser: UserOrClub<UserRoles>,
    seasons: {[seasonId: string]: Season}
}

function SeasonDetailInfo(props:Props) {
    const navigation = useAppNavigation()
    
    
    return (
        <>
        <FormHeading
        heading={i18n.t(tokens.app.screens.manageClub.manageClubSeasonsHeading)}
        />
        {Object.keys(props.seasons?props.seasons:{}).map((key) => {
            return (<SeasonComponent item={props.seasons[key]} key={key} seasonId={key}/>)
        })}
        <View style={{flexDirection: "row", width: "100%"}}>
            <BigButton
            onPress={() => {
                navigation.navigate('ManageSeasonScreen', {})
            }}
            buttonText={i18n.t(tokens.app.screens.manageClub.manageClubAddSeasonButton)}
            />
        </View>
        </>
    )
}

export default SeasonDetailInfo