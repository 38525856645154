import { User } from "firebase/auth/react-native"
import { State } from "react-native-gesture-handler"
import { AnyAction } from "redux"
import { Club, Price, RoleTypes, Season, SeasonTypes, user, UserRoles, Contact, Currencies, CreditBalance, DaysPrices} from "../../../functions/src/interfaces"
import { firestoreAutoId } from "../../utilities/funcs"
import { StateLoader } from "../../utilities/interfaces"
import contactsReducer from "./contactsReducer"
import { UserOrClub } from "./usersReducer"


export enum AuthActions {
    authSelectedClub = 'SELECTED_CLUB',
    authSelectedSeason = 'SELECTED_SEASON',
    authManageSeason = 'MANAGE_SEASON',
    authClubsChange = 'CLUB_CHANGES',
    authRegisterClub = 'ADD_USER',
    authSeasonsSubscribe = 'SEASON_SUBSCRIBE',
    authCleanCurrUser = 'CLEAN_CURR_USER',
    authCurrentUserPublicData = 'CURRENT_USER_PUBLIC',
    authCurrentUserPrivateData = 'CURRENT_USER_PRIVATE',
    authCurrentUserRoles = 'CURRENT_USER_ROLES',
    authCurrentUserCredits = 'CURRENT_USER_CREDITS',
    authCurrentUserContacts = 'CURRENT_USER_CONTACTS',
    authCurrentUserAmInContacts = 'CURRENT_USER_AM_IN_CONTACTS',
    authCurrentUserGlobalRoles = 'CURRENT_USER_GLOBAL_ROLES',
    authAddCourtToClub = 'ADD_NEW_COURT',
    authEditClub = 'EDIT_CLUB',
    authRemoveCourt = 'REMOVE_COURT',
    authChangeCourt = 'CHANGE_COURT',
    authEditSeasonData = 'EDIT_SEASON_VALUES',
    authEditSeasonCourtValues = 'EDIT_SEASON_COURT_VALUES',
    authLoginStarts = 'AUTHENTICATION_STARTED',
    authLoginEnds = 'AUTHENTICATION_ENDS',
    authChangePriceDayRange = 'CHANGE_PRICE_DAY_RANGE',
    authAddPriceDayRange = 'ADD_PRICE_DAY_RANGE',
    authEditPriceTimeRange = 'CHANGE_PRICE_TIME_RANGE',
    authEditPriceForRole = 'CHANGE_PRICE_FOR_ROLE',
    authChangeLoggedUser = 'CHANGE_LOGGED_USER',
    authEditClubProductPrice = 'CHANGE_PRODUCT_PRICE',
    authChangeCurrDate = 'CHANGE_CURR_DATE',
    authActionPressed = 'CHANGE_ACTION_PRESSED',
}







export type AuthState = {
    isLoggingIn: boolean,
    isActionPressed: boolean,
    userState: {
        isLoaded: boolean,
        isEmpty: boolean,
        user: null | {uid: string, email: string}
    }
    currDate: string,
    contacts: {[contactId: string]: Contact},
    myRoles: {[clubId: string]: UserRoles},
    amInContacts: {[uid: string]: user},
    currentUserData: UserOrClub | null,
    managedSeason: StateLoader<Season | undefined>,
    managedClub: StateLoader<Club | undefined>,
    selectedClub: string,
    isConnected: boolean,
    activeSeason: Season | null,
    userDeletion: {
        isLoaded: boolean,
        success: boolean,
        error?: {},
        confirmed: boolean,
        user: {},
        
    },
    allClubs: {
        [clubId: string]: Club
    },
    seasons: {
        [seasonId: string]: Season
    },
    allUserCredits: {
        [clubId: string]: CreditBalance
    }
}
const initState: AuthState = {
    isLoggingIn: false,
    isActionPressed: false,
    userState: {
        isEmpty: true,
        isLoaded: true,
        user: null
    },
    currDate: JSON.stringify(new Date),
    myRoles: {},
    contacts: {},
    amInContacts: {},
    currentUserData: undefined,
    selectedClub: "",
    isConnected: false,
    managedClub: {
        isLoaded: false,
        data:{
            club_name: "",
            address: "",
            city: "",
            courts: {},
            firebaseId: "",
            iban: "",
            stripePublishableKey: "",
            products: {
                subscription: {type: undefined, prices: {}}
            },
            email: "",
            phone: "",
            sports: [],
            activeUntil: undefined,
            currency: Currencies.CZK,
            allowedGameTypes: {},
            feeInclusion: false,
            homepage: undefined,
            vopURL: undefined,
        }
    },
    activeSeason: null,
    userDeletion: {
        isLoaded: false,
        success: false,
        error: {},
        confirmed: false,
        user: {}
    },
    managedSeason: {
        isLoaded: false,
        data: undefined
    },
    allClubs: {},
    seasons: {},
    allUserCredits: {}
}

/* 
const validateDatesAndTimes = (prices: DaysPrices, from: number, to: number): DaysPrices => {
    let src: DaysPrices =prices.sort((a, b) => a.daysFrom < b.daysFrom ? 1 : -1)
    var out: DaysPrices = [
    ]
    var currDay = 1
    var currIdx = 0
    let totRanges = src.length

    while (currDay < 7) {
        let currRange = totRanges > currIdx ? src[currIdx] : null
        if (currRange && currRange.daysFrom == currDay) {
            //Happy
            out.push(currRange)
            currDay = currRange.daysTo + 1
            currIdx += 1
        } else {
            //missing a day
            let change = {
                daysFrom: currDay,
                daysTo: currRange ? currRange.daysFrom - 1 : 7,
                priceRanges: [
                    {
                        from: from,
                        to: to,
                        prices: {}
                    }
                ]
            }
            out.push(
                change
            )
            currDay = change.daysTo + 1

        }
    }
    console.log('Ceny: ', out)
    return out
} */

const authReducer = (state = initState, action): AuthState => {
    //console.log(JSON.stringify(action))
    let managedSeason = {...state.managedSeason.data}
    var managedClub = {...state.managedClub.data}
    let openHours = {
        openFrom: managedSeason.openFrom,
        openTo: managedSeason.openTo,
    }
    switch(action.type){
        case AuthActions.authActionPressed:
            return {
                ...state,
                isActionPressed: action.data
            }
        case AuthActions.authChangeCurrDate: 
            return {
                ...state,
                currDate: action.data
            }
        case AuthActions.authEditClubProductPrice:
            managedClub.products[action.data.product][action.data.key].price = action.data.value
            return {
                ...state,
                managedClub: {
                    ...state.managedClub,
                    isChanged: true,
                    data: {
                        ...managedClub
                    }
                }
            }
        case AuthActions.authChangeLoggedUser:
            return {
                ...state,
                userState: {
                    user: action.data ? {uid: action.data.uid, email: action.data.email} : null,
                    isEmpty: action.data ? false : true,
                    isLoaded: state.userState.isLoaded,
                }
            }
        case AuthActions.authEditPriceForRole:
            let rolePrices = {
                ...managedSeason.newPrices[action.data.priceListId]?.priceRanges[action.data.priceIdx]?.prices[action.data.courtType],
                [action.data.roleType]: action.data.price
            }
            let courtPrices = {
                ...managedSeason.newPrices[action.data.priceListId].priceRanges[action.data.priceIdx].prices,
                [action.data.courtType]: rolePrices
            }

            managedSeason.newPrices[action.data.priceListId].priceRanges[action.data.priceIdx].prices = courtPrices
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {...managedSeason}
                }
            }
        case AuthActions.authChangePriceDayRange:
            var currentState = {
                ...state
            }
            let newprices = currentState.managedSeason.data.newPrices
            let newPricesWithChange = {
                ...newprices,
                [action.data.id]: {
                    ...newprices[action.data.id],
                    days: newprices[action.data.id].days.includes(action.data.day) ? [...newprices[action.data.id].days.filter(item => item != action.data.day)] : [...newprices[action.data.id].days, action.data.day]
                }
            }
            currentState.managedSeason.data.newPrices = {...newPricesWithChange}
            return currentState
        case AuthActions.authAddPriceDayRange:
            let newAutoId = firestoreAutoId()
            let pricesChangeState = {...state}
            pricesChangeState.managedSeason.data.newPrices = {...pricesChangeState.managedSeason.data.newPrices || {}}
            pricesChangeState.managedSeason.data.newPrices[newAutoId] = {
                days: [],
                id: newAutoId,
                priceRanges: [
                    {
                        from: state.managedSeason.data.openFrom,
                        to: state.managedSeason.data.openTo,
                        key: firestoreAutoId(),
                        prices: {}
                    }
                ]
            }
            console.log(pricesChangeState.managedSeason.data)
            return {
                ...pricesChangeState
            }
        case AuthActions.authCurrentUserCredits:
            let userCcredits = {...state.allUserCredits, ...action.data.added, ...action.data.modified}
            Object.keys(action.data.removed).forEach((key) => {
                delete userCcredits[key]
            })
            return {
                ...state,
                allUserCredits:  {...userCcredits}
            }
        case AuthActions.authLoginStarts:
            return {
                ...state,
                isLoggingIn: action.data,
                userState: {
                    ...state.userState,
                    isLoaded: !action.data
                }
            }
        case AuthActions.authCleanCurrUser:
            return {
                ...state,
                currentUserData: null,
                myRoles: {},
                amInContacts: {},
                contacts: {},
                allUserCredits: {},
            }
        case AuthActions.authCurrentUserContacts:
            let currentContactsData = {...state.contacts, ...action.data.added, ...action.data.modified}
            Object.keys(action.data.removed).forEach((key) => {
                delete currentContactsData[key]
            })
            return {
                ...state,
                contacts:  {...currentContactsData}
            }
            
        case AuthActions.authCurrentUserAmInContacts:
            let currentAmInContsData = {...state.amInContacts, ...action.data.added, ...action.data.modified}
            Object.keys(action.data.removed).forEach((key) => {
                delete currentAmInContsData[key]
            })
            return {
                ...state,
                amInContacts:  {...currentAmInContsData}
            }
        case AuthActions.authCurrentUserPublicData:
            return {
                ...state,
                currentUserData:  {...state.currentUserData, ...action.data}
            }
        case AuthActions.authCurrentUserPrivateData:
            return {
                ...state,
                currentUserData:  {...state.currentUserData, ...action.data}
            }
        case AuthActions.authCurrentUserGlobalRoles:
            
            return {
                ...state,
                currentUserData:  {...state.currentUserData, ...action.data}
            }
        case AuthActions.authCurrentUserRoles:
            let currentData = {...state.myRoles, ...action.data.added, ...action.data.modified}
            Object.keys(action.data.removed).forEach((key) => {
                delete currentData[key]
            })
            //console.log('WTF',currentData)
            return {
                ...state,
                myRoles:  {...currentData}
            }
        case AuthActions.authSeasonsSubscribe:
            
            
            return {
                ...state,
                seasons: {...action.data}
            }
        case AuthActions.authSelectedClub:
            return {
                ...state,
                selectedClub: action.data.selectedClub
            }
        case AuthActions.authSelectedSeason:
            return {
                ...state,
                activeSeason: action.data?{...action.data}:null
            }
        case AuthActions.authManageSeason:
            
            return {
                ...state,
                managedSeason: {
                    isLoading: false,
                    ...action.data,
                }
            }
        case AuthActions.authClubsChange:
            let out = {
                ...state,
                
            }

            out.allClubs = {...out.allClubs, ...action.data.added, ...action.data.modified}
            Object.keys(action.data.removed).forEach((key) => {
                delete out.allClubs[key]
            })
            return out
        case AuthActions.authEditPriceTimeRange:
            let newRoleRangesLength =  managedSeason.newPrices[action.data.id].priceRanges.length
            let oldTimeRange = {
                ...managedSeason.newPrices[action.data.id].priceRanges[action.data.index]
            }
            let newTimeRange = {
                from: action.data.from ? action.data.from : oldTimeRange.from,
                to: action.data.to ? action.data.to : oldTimeRange.to,
                prices: oldTimeRange.prices,
                key: oldTimeRange.key,
            }
            if (action.data.index == newRoleRangesLength - 1) {
                if (newTimeRange.to < openHours.openTo) {
                    let newRangeInput = {
                        from: newTimeRange.to,
                        to: openHours.openTo,
                        prices: newTimeRange.prices,
                        key: firestoreAutoId()
                    }
                    managedSeason.newPrices[action.data.id].priceRanges.push({...newRangeInput})
                }
            } else if (action.data.index == newRoleRangesLength - 2) {
                if (newTimeRange.to == openHours.openTo) {
                    console.log('Old array', [...managedSeason.newPrices[action.data.id].priceRanges])
                    let newArr = [...managedSeason.newPrices[action.data.id].priceRanges]
                    newArr.pop()
                    console.log('New array: ', [...newArr])
                    managedSeason.newPrices[action.data.id].priceRanges = [...newArr]
                }
            }

            managedSeason.newPrices[action.data.id].priceRanges[action.data.index] = {...newTimeRange}
            newRoleRangesLength = managedSeason.newPrices[action.data.id].priceRanges.length
            console.log('Pocet v arr ' + String(newRoleRangesLength))
            if (action.data.index > 0) {
                let prevVal = {...managedSeason.newPrices[action.data.id].priceRanges[action.data.index - 1]}
                managedSeason.newPrices[action.data.id].priceRanges[action.data.index - 1] = {...prevVal, to: newTimeRange.from}
            } 
            if (action.data.index < newRoleRangesLength - 1) {
                let prevVal = {...managedSeason.newPrices[action.data.id].priceRanges[action.data.index + 1]}
                managedSeason.newPrices[action.data.id].priceRanges[action.data.index + 1] = {...prevVal, from: newTimeRange.to}
            }
            console.log('Toto posilam zpet: ',managedSeason.newPrices[action.data.id].priceRanges)
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {
                        ...managedSeason
                    }
                    
                }
            }
        case 'EDIT_TIME_PRICE_RANGES':
            
            let roleRangesLength = managedSeason['prices'][action.data.courtType][action.data.roleType].length
            let oldTimeRanges = {
                ...managedSeason['prices'][action.data.courtType][action.data.roleType][action.data.index]
            }
            let newTimeRanges = {
                from: action.data.priceFrom ? action.data.priceFrom : oldTimeRanges.from,
                to: action.data.priceTo ? action.data.priceTo : oldTimeRanges.to,
                price: action.data.price ? action.data.price : oldTimeRanges.price,
            }
            //If reverse range then skip and return old state
            /* if (newTimeRanges.from >= newTimeRanges.to) {
                return {
                    ...state
                }
            } */
            if (action.data.index == roleRangesLength - 1) {
                if (newTimeRanges.to < openHours.openTo) {
                    let newRangeInput = {
                        from: newTimeRanges.to,
                        to: openHours.openTo,
                        price: newTimeRanges.price,
                    }
                    managedSeason['prices'][action.data.courtType][action.data.roleType].push({...newRangeInput})
                }
            } else if (action.data.index == roleRangesLength - 2) {
                if (newTimeRanges.to == openHours.openTo) {
                    console.log('Old array', [...managedSeason['prices'][action.data.courtType][action.data.roleType]])
                    let newArr = [...managedSeason['prices'][action.data.courtType][action.data.roleType]]
                    newArr.pop()
                    console.log('New array: ', [...newArr])
                    managedSeason['prices'][action.data.courtType][action.data.roleType] = [...newArr]
                }
            }

            managedSeason['prices'][action.data.courtType][action.data.roleType][action.data.index] = {...newTimeRanges}
            roleRangesLength = managedSeason['prices'][action.data.courtType][action.data.roleType].length
            console.log('Pocet v arr ' + String(roleRangesLength))
            if (action.data.index > 0) {
                let prevVal = {...managedSeason['prices'][action.data.courtType][action.data.roleType][action.data.index - 1]}
                managedSeason['prices'][action.data.courtType][action.data.roleType][action.data.index - 1] = {...prevVal, to: newTimeRanges.from}
            } 
            if (action.data.index < roleRangesLength - 1) {
                let prevVal = {...managedSeason['prices'][action.data.courtType][action.data.roleType][action.data.index + 1]}
                managedSeason['prices'][action.data.courtType][action.data.roleType][action.data.index + 1] = {...prevVal, from: newTimeRanges.to}
            }
            console.log('Toto posilam zpet: ',managedSeason['prices'][action.data.courtType][action.data.roleType])
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {
                        ...managedSeason
                    }
                    
                }
            }
        case 'REMOVE_ROLE_TYPE_IN_PRICES':
            let newVal = {...state.managedSeason.data.prices[action.data.courtType]}
            delete newVal[action.data.roleId]
            console.log("Chci poslat", newVal, action.data.roleId)
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data:{
                        ...state.managedSeason.data,
                        prices: {
                            ...state.managedSeason.data.prices,
                            [action.data.courtType]: {
                                ...newVal
                            }
                        }
                    }
                    
                }
            }
        case 'ADD_ROLE_TYPE_IN_PRICES':
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {
                        ...state.managedSeason.data,
                        prices: {
                            ...state.managedSeason.data.prices,
                            [action.data.courtType]: {
                                ...state.managedSeason.data.prices[action.data.courtType],
                                [action.data.type]: [
                                    {
                                        from: state.managedSeason.data.openFrom,
                                        to: state.managedSeason.data.openTo,
                                        price: 0,
                                    }
                                ]
                            }
                        }
                    }
                    
                }
            }
        case 'REMOVE_COURT_TYPE_IN_PRICES':
            let newValCourt = {...state.managedSeason.data.prices}
            delete newValCourt[action.data.courtId]
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {
                        ...state.managedSeason.data,
                        prices: {
                            ...newValCourt
                        }
                    }
                    
                }
            }
        case 'ADD_COURT_TYPE_IN_PRICES':
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {
                        ...state.managedSeason.data,
                        prices: {
                            ...state.managedSeason.data.prices,
                            [action.data.type]: {}
                        }
                    }
                    
                }
            }
        case 'PUT_MANAGED_CLUB_TO_STATE':
            return {
                ...state,
                managedClub: {
                    
                    changed: false,
                    isLoading: false,
                    ...action.data,
                    data: {
                        ...action.data.data,
                    }
                },
            }
        case AuthActions.authEditSeasonCourtValues:
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {
                        ...state.managedSeason.data,
                        courtsList: {
                            ...state.managedSeason.data.courtsList,
                            [action.data.court]: {
                                ...state.managedSeason.data.courtsList[action.data.court],
                                ...action.data.value
                            }
                        }
                    }
                    
                }
            }
        case 'EDITED_COURT_EXEMPTION_SWITCH':
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {
                        ...state.managedSeason.data,
                        courtsList: {
                            ...state.managedSeason.data.courtsList,
                            [action.data.court]: {
                                ...state.managedSeason.data.courtsList[action.data.court],
                                approval: {
                                    ...state.managedSeason.data.courtsList[action.data.court]?.approval,
                                    [action.data.switchKey]: action.data.switchValue
                                }
                            }
                        }
                    }
                    
                }
            }
        case AuthActions.authEditSeasonData: {
            return {
                ...state,
                managedSeason: {
                    ...state.managedSeason,
                    data: {
                        ...state.managedSeason.data,
                        ...action.data.value
                    }
                    
                }
            }
        }
        /* case 'ADD_SEASON':
            return {
                ...state,
                managedClub: {
                    ...state.managedClub,
                    data: {
                        ...state.managedClub.data,
                        seasons: {
                            ...state.managedClub.data.seasons,
                            ...action.data
                        }
                    }
                    
                }
            } */
        case AuthActions.authAddCourtToClub: {
            let currState = {
                ...state,
                managedClub: {
                    ...state.managedClub,
                    data: {
                        ...state.managedClub.data,
                        courts: {
                            ...state.managedClub.data.courts,
                            [action.data.courtId]: {...action.data}
                        }
                    }
                }
            }
            return currState
        }
        case 'USER_DELETION': {
            
            return {
                ...state,
                userDeletion: {
                    ...action.data
                }
            }
        }
        case 'CONFIRM_USER_DELETION': {
            
            return {
                ...state,
                userDeletion: {
                    isLoaded: true,
                    confirmed: false,
                    user: {...action.data},
                    success: false,
                    error: {}
                }
            }
        }
        case AuthActions.authEditClub: {
            let currState = {
                ...state,
                managedClub: {
                    ...state.managedClub,
                    data: {
                        ...state.managedClub.data,
                        ...action.data.value
                    }
                    
                }
            }
            
            
            return currState
        }
        case AuthActions.authRemoveCourt: {
            let currState = {
                ...state
            }
            currState.managedClub.data.courts = Object.entries(currState.managedClub.data.courts).reduce((prev, curr) => {
                if (curr[1].courtId != action.data.court) {
                    return {...prev, [curr[0]]: {...curr[1]}}
                } else {
                    return {...prev}
                }
                
            }, {}) //{...currState.managedClub.courts.filter(item => item.courtId != action.data.court)}
            return currState
        }
        case AuthActions.authChangeCourt: {
            //console.log(action)
            let currState = {
                ...state
            }
            currState.managedClub.data.courts[action.data.index] = {...currState.managedClub.data.courts[action.data.index], ...action.data.value}
            //console.log(currState.managedClub.data.courts[action.data.index])
            return currState
        }
        
        default:
            return state


    }
    return state
}

export default authReducer