import React from "react";
import { View, Text } from "react-native";
import { Market, MarketSubscription } from "../../../../../functions/src/interfaces";
import FormEntry from "../../../../components/FormEntryNew";
import i18n, { tokens } from "../../../../i18n";
import { globalStyleSheet } from "../../../../utilities/globalStyles";

type Props = {
    dispatchChanges: (callback) => void,
    subscription: MarketSubscription<Market>,
    
}

function TourDetail(props: Props): JSX.Element {

    return (
        <View>
             <FormEntry
            caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonTour)}
            editable={true}
            >
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>
                    {props.subscription.tour}
                </Text>
            </FormEntry>
            
        </View>
    )
}

export default TourDetail