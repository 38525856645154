import { AnyAction } from "redux"
import { Notification, NotificationTypes } from "../../../functions/src/interfaces"


export enum NotificationsActions {
    notificationsSubscriptionChange = 'NOTIFICATIONS_SUBSCRIPTION_CHANGE',
    notificationsSubscriptionLastRead = 'NOTIFICATIONS_LAST_READ',
}

export type StateLoader<T> = {
    isLoaded: boolean,
    isSuccess?: boolean,
    isChanged?: boolean,
    isActionPressed?: boolean,
    error?: {message: string}
    data: T,
    validations?: {[item: string]: {validity: boolean, error: string}}
}

export type NotificationsState = {
    allowStripe: boolean,
    subscribedNotifications: {
        [notificationId: string]: Notification
    },
    notificationsLastRead: NotificationsLastRead,
    notificationBadges: NotificationBadges
}

type NotificationsLastRead = {
    reservations: Date,
    market: Date,
    news: Date,
    all: Date,
}

type NotificationBadges = {
    reservations: string[],
    market: string[],
    news: string[],
    all: string[],
}

const initState: NotificationsState = {
    allowStripe: false,
    subscribedNotifications: {
    },
    notificationsLastRead: {
        reservations: new Date,
        market: new Date,
        news: new Date,
        all: new Date,
    },
    notificationBadges: {
        reservations: [],
        market: [],
        news: [],
        all: [],
    }
}

const notificationsReducer = (state = initState, action: AnyAction): NotificationsState => {
    switch(action.type){
        case NotificationsActions.notificationsSubscriptionChange:
            //action.data
            let deletedNotificationIds: string[] =  Object.keys(action.data.removed)
            let notifications: {[notificationId: string]: Notification} = {...state.subscribedNotifications, ...action.data.added, ...action.data.modified}
            let notificationIds: string[] = Object.keys(notifications)
            var outNotifications: {[notificationId: string]: Notification} = {}
            var currBadges: NotificationBadges = {
                ...state.notificationBadges
            }
            for (let i = 0; i < notificationIds.length; i++) {
                let notificationId: string = notificationIds[i]
                let notification: Notification = notifications[notificationId]
                if (deletedNotificationIds.includes(notificationId)) {
                    //do nothing notification is deleted
                } else {
                    outNotifications[notificationId] = {...notification}
                    if (notification.updateDate >= state.notificationsLastRead.all) {
                        currBadges.all.push(notification.id)
                        if (notification.notification_type == NotificationTypes.reservation && notification.updateDate >= state.notificationsLastRead.reservations && !currBadges.reservations.includes(notification.id)) {
                            currBadges.reservations.push(notification.id)
                        } else if (notification.notification_type == NotificationTypes.market && notification.updateDate >= state.notificationsLastRead.market && !currBadges.market.includes(notification.id)) {
                            currBadges.market.push(notification.id)
                        } else if (notification.notification_type == NotificationTypes.news && notification.updateDate >= state.notificationsLastRead.news && !currBadges.news.includes(notification.id)) {
                            currBadges.news.push(notification.id)
                        }
                    }

                    
                }
            }

            return {
                ...state,
                subscribedNotifications: {
                    ...outNotifications
                },
                notificationBadges: {
                    ...currBadges
                }
            }
        case NotificationsActions.notificationsSubscriptionLastRead:
            let change: {[type: string]: Date} = {...action.data}
            let keys = Object.keys(change).reduce(({},key) => {
                return {[key]: []}
            }, {})
            //console.log('Resetuju: ', keys)
            return {
                ...state,
                notificationsLastRead: {
                    ...state.notificationsLastRead,
                    ...action.data
                },
                notificationBadges: {
                    ...state.notificationBadges,
                    ...keys
                }
            }
        default:
            return state


    }

}

export default notificationsReducer

