export enum NotificationTypes {
    reservation = "reservation",
    market = "market",
    news = "news",
    payments = "payments",
}

export interface notificationData {
    notification_type: NotificationTypes;
    id: string;
    clubId: string,
}

export interface pushNotification {
    title: string;
    to: string[];
    body: string;
    data: notificationData;
}

//export type pushNotification = pushNotificationOther | pushNotificationMarket

export type NotificationMarket = Omit<pushNotification, "data"> & {
    uid: string,
    updateDate: Date,
    read: boolean,
    firebaseId: string,
    marketType: MarketTypes,
    notification_type: NotificationTypes.market,
} & Omit<notificationData, "notification_type">

export type NotificationAll = Omit<pushNotification, "data"> & {
    uid: string,
    updateDate: Date,
    read: boolean,
    firebaseId: string,
    notification_type: NotificationTypes.news | NotificationTypes.payments | NotificationTypes.reservation,
} & Omit<notificationData, "notification_type">

export type Notification = NotificationAll | NotificationMarket

export interface gameSide {
    A: string,
    B?: string,
}

export interface gamePlayers {
    home: gameSide,
    away: gameSide,
}

export type playerList = Array<string>

export enum GameTypes {
    double = 'double',
    single = 'single',
    group = 'group',
    training = 'training',
    withMember = 'withMember',
    withoutMember = 'withoutMember',
    //training = 'training',
  }

export enum ReservationTypes  {
    reservation = 'reservation',
    longreservation = 'longreservation',
    locked = 'locked',
    opengame = 'opengame',
    outoforder = 'outoforder'
}

export interface Reservation {
    club: string,
    court: string,
    dateFrom: Date,
    dateTo: Date,
    day: number,
    gamePlayers: gamePlayers,
    gameType: GameTypes ,
    mainUid: string,
    players: playerList,
    reservationRequestId?: string,
    season: string,
    timeFrom: number,
    timeTo: number,
    type: ReservationTypes.longreservation | ReservationTypes.reservation | ReservationTypes.opengame,
    firebaseId?: string,
    status: ReservationStatuses,
    allplayers: playerList,
    isOnBehalf?: boolean,
    onBehalfFamilyName?: string,
    onBehalfFirstName?: string,
    onBehalfPhone?: string,
    onBehalfEmail?: string,
    isNotRegistered?: boolean,
    uid?: string,
    updateTime: Date,
    opengameId?: string,
    openGameStatus?: OpengameStatuses,
    paid?: boolean,
    resultId?: string
}

export interface BlockedTime {
    club: string,
    court: string,
    dateFrom: Date,
    dateTo: Date,
    day: number,
    //gamePlayers: gamePlayers,
    //gameType: GameTypes ,
    mainUid: string,
    //players: playerList,
    //reservationRequestId?: string,
    season: string,
    timeFrom: number,
    timeTo: number,
    type: ReservationTypes.outoforder,
    firebaseId?: string,
    status: ReservationStatuses.blocked | ReservationStatuses.open,
    //allplayers: playerList,
    //isOnBehalf?: boolean,
    //onBehalfFamilyName?: string,
    //onBehalfFirstName?: string,
    //onBehalfPhone?: string,
    //onBehalfEmail?: string,
    //isNotRegistered?: boolean,
    uid?: string,
    updateTime: Date,
    //opengameId?: string,
    //openGameStatus?: OpengameStatuses,
    //paid?: boolean,
    //resultId?: string
    text: string,
}

export interface LockedTime {
    club: string,
    court: string,
    dateFrom: Date,
    dateTo: Date,
    day: number,
    //gamePlayers: gamePlayers,
    //gameType: GameTypes ,
    mainUid: string,
    //players: playerList,
    //reservationRequestId?: string,
    season: string,
    timeFrom: number,
    timeTo: number,
    type: ReservationTypes.locked,
    firebaseId?: string,
    status: ReservationStatuses.blocked | ReservationStatuses.open,
    //allplayers: playerList,
    //isOnBehalf?: boolean,
    //onBehalfFamilyName?: string,
    //onBehalfFirstName?: string,
    //onBehalfPhone?: string,
    //onBehalfEmail?: string,
    //isNotRegistered?: boolean,
    uid?: string,
    updateTime: Date,
    //opengameId?: string,
    //openGameStatus?: OpengameStatuses,
    //paid?: boolean,
    //resultId?: string
    text: string,
}

export type AllResTypes = Reservation | BlockedTime | LockedTime

export interface user {
    uid: string,
    first_name: string,
    family_name: string,
    address: string,
    email: string,
    photo?: string,
    dateOfBirth?: Date,
    city?: string,
    phone?: string,
    clubs: string[],
    linkedTo?: string,
    linkedFlag?: boolean
}

export interface UserPrivate {
    locale: string,
    token: string,
    uid: string,
    facebookId?: string,
    linkedInId?: string,
    address?: string,
    city?: string,
    country?: string,
}

export interface IUsers {
    [uid: string]: user
}

export interface IUsersFull {
    [uid: string]: user & UserPrivate
}

export interface Error {
    error_code: string,
    error_message: string,
    
}

export type UserOrClub<T = {}> = ({type: "user"} & user & T) | ({type: "clubs", uid: string} & Club & T)

export type IUsersOrClubs<T = {}> = {[uid:string]: UserOrClub<T>}

export interface PriceRange {
    from: number,
    to: number,
    price: number
}

export interface UserRoles {
    uid: string,
    isGod?: boolean,
    isAdmin?: boolean,
    isMember?: boolean,
    isClubRegistered?: boolean,
    isCoach?: boolean,
    isTester?: boolean,
    isChild?: boolean,
    isPensioner?: boolean,
}

export type ClubRoles  = {
    uid: string,
    club: string,
    isAdmin?: boolean,
    isCoach?: boolean,
    isTester: boolean,
}


export interface Price {
    from: number,
    to: number,
    price: number,
}

export interface RolePrices {
    [role: string]: Array<Price>
}

export interface CourtRolePrices {
    [courtType: string]: RolePrices
}


export type MarketReservation<T>  = T & {

    court: string
    dateFrom: Date
    dateTo: Date
    day: number
    season: string,
    timeFrom: number,
    timeTo: number,
    gameType: GameTypes,
    gamePlayers: gamePlayers,
    players: playerList,
    paymentStatus: MarketPaymentStatuses,
    isOnBehalf?: boolean,
    onBehalfFamilyName?: string,
    onBehalfFirstName?: string,
    onBehalfPhone?: string,
    onBehalfEmail?: string,
    isNotRegistered?: boolean,
    mainUid: string,
    reservationIds: string[],
    type: MarketTypes.reservation,
    manualPrice?: number,
}

export type MarketLongreservation<T>  = T & {
    court: string
    from: Date
    to: Date
    day: number
    season: string,
    timeFrom: number,
    timeTo: number,
    gameType: GameTypes,
    gamePlayers: gamePlayers,
    players: playerList,
    weekRecur: number,
    paymentStatus: MarketPaymentStatuses,
    isOnBehalf?: boolean,
    onBehalfFamilyName?: string,
    onBehalfFirstName?: string,
    onBehalfPhone?: string,
    onBehalfEmail?: string,
    isNotRegistered?: boolean,
    mainUid: string,
    reservationIds: string[],
    type: MarketTypes.longreservation,
    manualPrice?: number,
}


export type MarketOpengame<T>  = T & {
    gameType: GameTypes,
    timeFrom: number,
    timeTo: number,
    court: string,
    season: string,
    gamePlayers: gamePlayers,
    players: string[],
    appliedPlayers: string[],
    notifiedPlayers: string[],
    day?: number,
    reservationIds: string[],
    type: MarketTypes.opengame,
}

export type MarketSubscription<T> = T & {
    memType: MembershipTypes,
    product: ProductPrice & {type: MembershipTypes},
    quantity: number,
    from: Date,
    to: Date,
    mainUid: string,
    minFrom: Date,
    tour?: number,
    paymentStatus: MarketPaymentStatuses,
    type: MarketTypes.subscription,
}

export type MarketCredit<T> = T & {
    amount: number,
    mainUid: string,
    paymentStatus: MarketPaymentStatuses,
    type: MarketTypes.credit,
}

export enum MarketStatuses {
    open = 'open',
//    approval = 'approval',
    blocked = 'blocked',
    approved = 'approved',
    declined = 'declined',
    deleted = 'deleted',
    closed = 'closed',
    new = 'new',
}

export enum MarketPaymentStatuses {
    processing = 'processing',
    requiresPayment = 'requiresPayment',
    requiresCapture = 'requiresCapture',
    captured = 'captured',
    setteled = 'setteled',
    refunded = 'refunded',
}

export enum OpengameStatuses {
    open = 'open',
    disconnected = 'disconnected',
    approved = 'approved',
    declined = 'declined',
}

export enum ReservationStatuses {
    open = 'open',
    approved = 'approved',
    declined = 'declined',
    deleted = 'deleted',
    blocked = 'blocked',
}

export enum MarketTypes {
    reservation = 'reservation',
    longreservation = 'longreservation',
    opengame = 'opengame',
    subscription = 'subscription',
    credit = 'credit',
}

export type Message = {
    uid: string,
    message: string,
    messageId: string,
    updateTime: Date
}

export type Market = {
    firebaseId?: string,
    uid: string,
    club: string,
    dueDate: Date,
    requestDate: Date,
    status: MarketStatuses,
    messages?: Array<Message>,
    text: string,
    updateTime: Date,
    prevFirebaseId?: string,
}

export type PriceInfo = {
    detail: {[reservationId: string]: PriceItem},
    price: number,
    firebaseId?:string,
    isCalculated?: boolean,
    club: string,
}
export enum PaymentStatuses {
    overpaidBalance = 'overpaidBalance',
    fullyPaidBalance = 'fullyPaidBalance',
    unpaidBalance = 'unpaidBalance',
    none = 'none'
}
export type CheckoutData = PriceInfo & {
    firebaseId?: string,
    paidAmt: number,
    amountCapturable?: number,
    updatedDate?: Date,
    marketId?: string,
    captureStatus?: PaymentStatuses,
    status?: PaymentStatuses,
    mainUid: string,
    paymentIntentId?:string,
    paymentPromise: boolean,
    autoCapture: boolean,
    currency: Currencies,
}

export interface NewPrice {
    key: string,
    from: number,
    to: number,
    prices: NewCourtRolePrices,
}

export type DaysPrices = {
    [id: string]: {days: number[], id:string, priceRanges: Array<NewPrice>}
}

export interface NewCourtRolePrices {
    [courtType: string]: {[roleId: string]:number}
}


export type PriceInterval = {
    from: number,
    to: number,
    price: number,
}

export type PriceType = {
    [roleType: string]: PriceInterval[]
}

export type Prices = {
    [courtType: string]: PriceType
}

export type SeasonCourtApproval = {
    [roleType: string]: boolean
}

export type SeasonCourtSetting = {courtId: string, type: CourtTypes, selected: boolean, unlockDate: Date, approval: SeasonCourtApproval}

export type Season = {
    club: string,
    courtsList: {
        [id: string]: SeasonCourtSetting
    },
    firebaseId: string,
    from: Date,
    //lockedFrom: Date,
    lockedTo: Date,
    openFrom: number,
    openTo: number,
    planFrom: Date,
    planTo: Date,
    planningFinished: boolean,
    prices: Prices,
    newPrices: DaysPrices,
    to: Date,
    type: SeasonTypes,
    tour: number
}

export type PriceItem = {price: number, date: Date, itemType:string, detail: {[item: string]: {
    price: number;
    uid?: string | undefined;
    type: string;
    extra?: string;
}}}

export type FunctionResponse = {
    data: any,
    actions: string[],
    error?: {code: string, message: string}
}

export enum NewsTypes {
    tournament = 'tournament',
    information = 'information',
}

export type News = {
    club: string,
    dueDate: Date,
    firebaseId: string,
    newsPhoto: string,
    notifyAllMembers: boolean,
    text: string,
    uid: string,
    appliedMembers: boolean,
    appliedPlayers: string[],
    messages?: Message[],
    type: NewsTypes,
    resend?: boolean
}

export type AnyMarket = MarketLongreservation<Market> | MarketReservation<Market> | MarketOpengame<Market> | MarketCredit<Market> | MarketSubscription<Market>


export type CreditBalance = {
    uid: string,
    creditBalance: number,
    updateDate: Date,
    club: string,
}

export type Credit = {
    firebaseId: string,
    type: MarketTypes.credit,
    amount: number,
    club: string,
    uid: string,
    prevAmount?: number,
}

export enum CourtTypes {
    clay = "clay",
    hard_court = "hard_court",
}

export type Court = {
    courtId: string,
    type: CourtTypes,
}

export enum ProductTypes {
    reservation = 'reservation',
    subscription = 'subscription',
}

export enum MembershipTypes {
    yearSubscription = 'yearSubscription',
    tourSubscription = 'tourSubscription'
}

export type ProductPrice = {
    firebaseId: string,
    name: string,
    price: number,
    allowedRoles: {[roleId: string]: boolean}
}



export type MembershipYear = {
    type: MembershipTypes.yearSubscription,
    prices: {
        [key: string]: ProductPrice
    },
}

export type MembershipTour = {
    type: MembershipTypes.tourSubscription,
    prices: {
        [key: string]: ProductPrice
    },
    tour: number,
    fixDateStart?: string,
}

export type Membership = MembershipYear | MembershipTour

export type ClubBasic = {
    firebaseId: string, 
    club_name: string, 
    email: string, 
    phone: string, 
    emailConfirm: string, 
    password: string, 
    confirmPassword: string, 
    termsAndConditions: boolean,
    currency: Currencies,
}

export enum Currencies {
    CZK = 'CZK',
    PLN = 'PLN',
}

export type Club = {
    club_name: string,
    club_name_dia?: string,
    address: string,
    city: string,
    courts: {[courtId: string]: Court},
    firebaseId: string,
    iban?: string,
    stripePublishableKey?: string,
    payUId?: string,
    email: string,
    phone: string,
    homepage: string,
    vopURL: string,
    ico?: string,
    dic?: string,
    termsAndConditions?: boolean,
    products: {
        [ProductTypes.subscription]: Membership
    },
    feeInclusion: boolean,
    sports: string[]
    activeUntil: Date,
    currency: Currencies,
    photo?: string,
    allowedGameTypes: {
        [gameType: string]: boolean
    },
    paymentPromise?: {
        allowed: boolean,
        roles: {
            [roleId: string]: boolean
        }
    },
    onlinePayments?: {
        allowed: boolean,
        roles: {
            [roleId: string]: boolean
        }
    },
    qrPayments?: {
        allowed: boolean,
        iban: string,
        roles: {
            [roleId: string]: boolean
        }
    },
    creditPayments?: {
        allowed: boolean,
        roles: {
            [roleId: string]: boolean
        }
    },
    autoCapture?: {
        [MarketTypes.longreservation]: boolean,
        [MarketTypes.credit]: boolean,
        [MarketTypes.subscription]: boolean,
        [MarketTypes.reservation]: boolean,
    },
    ageRelatedRoles?: {
        isChildUntil: number,
        isPensionerFrom: number
    }
}

export enum PaymentModes {
    paymentModeCard = 'paymentModeCard',
    paymentModePayU = 'paymentModePayU',
    paymentModeBT = 'paymentModeBT',
    paymentModeCredit = 'paymentModeCredit',
    paymentModeNone = 'paymentModeNone',
    paymentModeCash = 'paymentModeCash',
    paymentModePromise = 'paymentModePromise',
}

export enum PaymentTransactionStatuses {
    initiated = 'initiated',
    amount_capturable_updated = 'amount_capturable_updated',
    succeeded = 'succeeded',
    cancelled = 'cancelled',
    capturing = 'capturing',
    refunded = 'refunded',
    refunding = 'refunding',
}

export type PaymentData = {
    amount: number,
    amountCapturable: number,
    amountCaptured: number
    amountRefunded: number
    charges: any
    checkoutId: string,
    createdDate: Date,
    firebaseId: string,
    paymentIntentId?: string,
    status: PaymentTransactionStatuses,
    uid: string,
    type: PaymentModes,
    variableSymbol?: number,
    club: string,
    prevCreditBalance?: number,
    newCreditBalance?: number,
}

export type SecretStore = {
    [clubId: string]: {
        [key: string]: string
    }
}

export type PayUCreateOrderAPI = {
    status: {
        statusCode: string
    },
    redirectUri: string,
    orderId: string,
    extOrderId: string
}

export enum RoleTypes {
    all = 'all',
    isClubRegistered = 'isClubRegistered',
    isMember = 'isMember',
    isAdmin = 'isAdmin',
    isCoach = 'isCoach',
    isGod = 'isGod',
    isTester = 'isTester',
    isChild = 'isChild',
    isPensioner = 'isPensioner',
}

export enum SeasonTypes {
    summer = 'summer',
    winter = 'winter',
}


export type SubscriptionYear = {
    type: MarketTypes.subscription,
    club: string,
    firebaseId: string,
    membershipEndDate: Date,
    membershipStartDate: Date,
    membershipType: MembershipTypes.yearSubscription,
    uid: string,
    priceId: string,
    price: number,
}

export type SubscriptionLegacyYear = {
    type: MarketTypes.subscription,
    club: string,
    firebaseId: string,
    membershipEndDate: Date,
    membershipStartDate: Date,
    membershipType: "year",
    uid: string,
    priceId: string,
    price: number,
}


export type SubscriptionTour = {
    type: MarketTypes.subscription,
    club: string,
    firebaseId: string,
    tour: number,
    membershipType: MembershipTypes.tourSubscription,
    uid: string,
    priceId: string,
    price: number,
}

export type SubscriptionLegacyTour = {
    type: MarketTypes.subscription,
    club: string,
    firebaseId: string,
    tour: number,
    membershipType: "tour",
    uid: string,
    priceId: string,
    price: number,
}

export type Subscription = SubscriptionYear | SubscriptionTour | SubscriptionLegacyTour | SubscriptionLegacyYear

export type Contact = {
    amTrustedFlag: number,
    contactDetail: string,
    contactGroup: string,
    contactType: string,
    contactid: string,
    counterContactDetail: string,
    friendRequestReceived: number,
    friendRequestSent: number,
    lastRead: Date,
    mutualFlag: number,
    trustedFlag: number,
    uid: string,
}

export type Ranking = {
    club:string,
    lastPlayed: Date,
    lossesDoubles: number,
    lossesSingles: number,
    pointsDoubles: number,
    pointsSingles: number,
    rank: number,
    season: string,
    status: string,
    uid: string,
    updateDate: Date,
    winsDoubles: number,
    winsSingles: number,
}

export type CallbackFunc = (bool: boolean, res: any) => void