import { AnyMarket, Market, MarketLongreservation, MarketReservation, Reservation, Season } from "../../../functions/src/interfaces"
import { dateToTimeString, extractTime, splitTime } from "../../utilities/funcs"

export enum MarketActions {
    changeManagedMarket = 'CHANGE_MANAGE_MARKET',
    marketPutMarketToState = 'PUT_MANAGED_MARKET_TO_STATE',
    marketQueryCollisions = 'QUERY_COLLISIONS',
    marketQueryFreeCourts = 'QUERY_FREE_COURTS',
    marketSubscriptionChanges = 'MARKET_CHANGES',
    marketSubscribeForIndividualRequest = 'SUBSCRIBE_FOR_REQUEST',
}

export type StateLoader<T> = {
    isLoaded: boolean,
    isSuccess?: boolean,
    isChanged?: boolean,
    isActionPressed?: boolean,
    error?: {message: string}
    data: T,
    validations?: {[item: string]: {validity: boolean, error: string}}
}

export type MarketState = {
    market: {[marketId:string]: MarketReservation<Market>|MarketLongreservation<Market>},
    managedMarket: StateLoader<AnyMarket> & {seasonsData?: {[seasonId: string]: Season}, createReservation?: boolean},
    queriedCollisions: StateLoader<(Reservation & {collisions: Reservation[]})[]>,
    subscribedMarket: StateLoader<AnyMarket>,
}

let initState: MarketState = {
    market: {},
    managedMarket: {
        isLoaded: false,
        data: undefined
    },
    queriedCollisions: {
        isLoaded: false,
        data: []
    },
    subscribedMarket: {
        isLoaded: false,
        data: undefined
    }
}

const updateDate = (date: Date, time:string) => {
    let dt = new Date(date)
        //dt.setHours(0,0,0,0)
    let [hoursFrom, minutesFrom] = splitTime(time)
    //console.log('dfd',hoursFrom, minutesFrom)
    if (!(hoursFrom >= 0 && hoursFrom < 24 && minutesFrom >= 0 && minutesFrom < 60)) return dt
    if (parseInt(time) < 2400) {
        dt.setHours(hoursFrom, minutesFrom)
    } else {
        dt = new Date(date)
    }
    return dt
}

const marketReducer = (state = initState, action) => {
    switch(action.type){
        case 'LONGRESERVATION_ADDED':
            //console.log('akce:', action)
            return {
                ...state,
                lastAddedReservation: action.data
            }
        case MarketActions.marketPutMarketToState:
            //console.log('akce:', action)
            return {
                ...state,
                queriedCollisions: {
                    isLoaded: false,
                    data: []
                },
                managedMarket: {
                    
                    ...action.data,
                    data: {...action.data.data}
                },
                
            }
        case 'RESET_MARKET':
            return {
                ...state,
                managedMarket: {
                    data: {...action.data,},
                    isChanged: false,
                },
            }
        /* case 'UPDATE_MANAGED_MARKET':
            //console.log('akce:', action)
            return {
                ...state,
                managedMarket: {
                    ...state.managedMarket,
                    ...action.data,
                    data: {
                        ...state.managedMarket.data,
                        ...action.data.data
                        
                    },
                    
                    //isChanged: true,

                }
            } */
        case MarketActions.changeManagedMarket:
            let changes = {...action.data.data}
            const isChanged = changes.isChanged == undefined ? true : false
            let changesItems = Object.keys(changes)
            if (changesItems.includes('timeFrom')) {
                //console.log('Changes')
                changes = {
                    ...changes,
                    dateFrom: updateDate(state.managedMarket.data.dueDate, String(action.data.data.timeFrom))
                }
            }
            if (changesItems.includes('timeTo')) {
                changes = {
                    ...changes,
                    dateTo: updateDate(state.managedMarket.data.dueDate, String(action.data.data.timeTo))
                }
            }
            //console.log('Changes', changes, {...state.managedMarket.data, ...changes})
            return {
                ...state,
                managedMarket: {
                    ...state.managedMarket,
                    ...action.data,
                    data: {...state.managedMarket.data, ...changes},
                    isChanged: isChanged,
                    
                }
            }
        case MarketActions.marketQueryCollisions:
            return {
                ...state,
                queriedCollisions: {
                    isLoaded: action.data.isLoaded || false,
                    data: [...action.data.data]
                }
                  
            }
        case 'OPENGAME_ADDED':
            //console.log('akce:', action)
            return {
                ...state,
                lastAddedOpengame: action.data
            }
        case MarketActions.marketSubscriptionChanges:
            action.data
            let out = {
                ...state,
                
            }
            out.market = {...out.market, ...action.data.added, ...action.data.modified}
            Object.keys(action.data.removed).forEach((key) => {
                delete out.market[key]
            })
            return out
        case MarketActions.marketSubscribeForIndividualRequest:
            return {
                ...state,
                subscribedMarket: {
                    ...action.data
                }
            }
        }
        
        return state
    }

export default marketReducer