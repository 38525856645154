import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable } from 'react-native'

import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Market, MarketLongreservation, MarketOpengame, MarketReservation, UserOrClub, user } from '../../../../functions/src/interfaces';
import { useAppNavigation } from '../../../store/hooks';
import MarketEntryBody from './MarketEntryBody/MarketEntryBody';
import MarketEntryHeader from './MarketEntryHeader';



type Props = {
    item: MarketLongreservation<Market> | MarketOpengame<Market> | MarketReservation<Market>
    users: {[userId: string]: UserOrClub}
}

const MarketEntryContent: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)
    const navigation = useAppNavigation()
    
    return (
        (<View style={styles.container}>
        <MarketEntryHeader data={props.item} users={props.users}/>
        <MarketEntryBody data={props.item} users={props.users}/>
        </View>)
    )
    
}


const styles = StyleSheet.create({
    container: {
        flex: 1
    }
})


export default MarketEntryContent

