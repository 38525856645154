import { string } from "prop-types"
import { CheckoutData, CreditBalance, MarketTypes, PaymentData } from "../../../functions/src/interfaces"


export enum CheckoutActions {
    checkoutSubscriptionChange = 'CHECKOUT_SUBSCRIPTION_CHANGE',
    checkoutPaymentSubscriptionChange = 'CHECKOUT_PAYMENT_SUBSCRIPTION_CHANGE',
    checkoutAllowStripeChange = 'CHECKOUT_ALLOW_STRIPE_CHANGE',
    checkoutDataStateChanges = 'CHECKOUT_STATE_CHANGE',
    checkoutGetUserCredit = 'CHECKOUT_GET_USER_CREDIT',
    checkoutFetchClientSecret = 'CHECKOUT_FETCH_CLIENT_SECRET',
    checkoutPutItemToTray = 'CHECKOUT_PUT_ITEM_TO_TRAY',
    checkoutActionQueryCheckouts = 'CHECKOUT_QUERY_USER_CHECKOUTS',
    checkoutQueryPayments = 'CHECKOUT_QUERY_PAYMENTS'
}

export type StateLoader<T> = {
    isLoaded: boolean,
    isSuccess?: boolean,
    isChanged?: boolean,
    isActionPressed?: boolean,
    error?: {message: string}
    data: T,
    validations?: {[item: string]: {validity: boolean, error: string}}
}

export type CheckoutState = {
    allowStripe: boolean,
    subscribedCheckout: StateLoader<CheckoutData>,
    subscribedPayment: StateLoader<PaymentData>,
    userBalance: StateLoader<CreditBalance | {}>,
    clientSecret: {paymentIntentId: string, clientSecret: string},
    tray: any[],
    queriedCheckouts: StateLoader<{uid: string, lastDoc: any, data: {[checkoutId: string]: CheckoutData & {marketType: MarketTypes}}}>,
    queriedPayments: StateLoader<PaymentData[]>,
}

const initState: CheckoutState = {
    allowStripe: false,
    subscribedCheckout: {
        isLoaded: false,
        data: undefined
    },
    userBalance: {
        isLoaded: false,
        data: {}
    },
    subscribedPayment: {
        isLoaded: false,
        data: undefined
    },
    clientSecret: {paymentIntentId: "", clientSecret: ""},
    tray: [],
    queriedCheckouts: {
        isLoaded: false,
        isActionPressed: false,
        data: {
            uid: null,
            lastDoc: undefined,
            data: {}
        }
    },
    queriedPayments: {
        isLoaded: false,
        data: []
    }
}

const checkoutReducer = (state = initState, action): CheckoutState => {
    switch(action.type){
        case CheckoutActions.checkoutQueryPayments:
            return {
                ...state,
                queriedPayments: {
                    ...state.queriedPayments,
                    ...action.data,
                    data: [...action.data.data]
                }
            }
        case CheckoutActions.checkoutSubscriptionChange:
            //console.log('akce:', action)
            return {
                ...state,
                subscribedCheckout: {
                    ...state.subscribedCheckout,
                    ...action.data,
                    data: {...action.data.data}
                }
            }
        case CheckoutActions.checkoutAllowStripeChange:
            return {
                ...state,
                allowStripe: action.data.allowStripe
            }
        case CheckoutActions.checkoutDataStateChanges:
            return {
                ...state,
                subscribedCheckout: {
                    ...state.subscribedCheckout,
                    ...action.data
                }
            }
        case CheckoutActions.checkoutGetUserCredit:
            return {
                ...state,
                userBalance: {
                    ...action.data
                }
            }
        case CheckoutActions.checkoutPaymentSubscriptionChange:
            //console.log('akce:', action)
            var newData = {}
            if (action.data.data) {
                newData = {...action.data.data}
            } else {
                newData = {...state.subscribedPayment.data}
            }



            return {
                ...state,
                subscribedPayment: {
                    ...state.subscribedPayment,
                    ...action.data,
                    data: {...newData}
                }
            }
        case CheckoutActions.checkoutFetchClientSecret: 
            return {
                ...state,
                clientSecret: {
                    ...action.data
                }
            }
        case CheckoutActions.checkoutPutItemToTray:
            return {
                ...state,
                tray: [...state.tray, action.data]
            }
        case CheckoutActions.checkoutActionQueryCheckouts:
            return {
                ...state,
                queriedCheckouts: action.data
            }
        default:
            return state


    }
    return state
}

export default checkoutReducer

