import { MarketStatuses, ReservationStatuses } from "../../../../functions/src/interfaces"
import i18n, { tokens } from "../../../i18n"
import { validateTextTime } from "../../../utilities/validators"


export const validations = (season) => {
    if (season) {
        return {
            uid: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
            type: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
            dueDate: {validity: true, error: "", method: (newState) => {
                let nd = new Date
                let fut = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate() + 14)
                
                if (newState.dueDate >= fut) {
                    return {bool: false, context: i18n.t(tokens.app.screens.opengame.errorTooFarAway)}
                } else if (newState.dueDate < new Date) {
                    return {bool: false, context: i18n.t(tokens.app.screens.opengame.errorNeedToBeFuture)}
                }else {
                    return {bool: true, context: ""}
                }
                    
            }},
            timeFrom: {validity: true, error: "", method: (newState) => {
                let currSeason = season
                
                //console.log('xdf ', parseInt(newState.timeFrom), currSeason.openFrom, parseInt(newState.timeFrom), currSeason.openTo, parseInt(newState.timeFrom) >= currSeason.openFrom && parseInt(newState.timeFrom) < currSeason.openTo)
                if (parseInt(newState.timeFrom) >= currSeason.openFrom && parseInt(newState.timeFrom) < currSeason.openTo) {
                    return {bool: true, context: ""}
                } else {
                    return {bool: false, context: i18n.t(tokens.app.screens.opengame.errorNotInOpeningHours)}
                }
                }},
            timeTo: {validity: true, error: "", method: (newState) => {
                let currSeason = season
                //console.log('xdf ', parseInt(newState.timeFrom), currSeason.openFrom, parseInt(newState.timeFrom), currSeason.openTo, parseInt(newState.timeFrom) >= currSeason.openFrom && parseInt(newState.timeFrom) < currSeason.openTo)
                if (parseInt(newState.timeTo) >= currSeason.openFrom && parseInt(newState.timeTo) < currSeason.openTo) {
                    return {bool: true, context: ""}
                } else {
                    return {bool: false, context: i18n.t(tokens.app.screens.opengame.errorNotInOpeningHours)}
                }
                }},
            gameType: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
            players: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
            appliedPlayers: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
            club: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
            text: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
            messages: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
            reservationId: {validity: true, error: "", method: (newState) => {return {bool: true, context: ""}}},
        }

    } else {
        return {}
    }

}

export const isAllowedToEdit = (currentUser, reservationOwner, status: MarketStatuses) => {
    if (!currentUser) {
        return false
    } else if (currentUser.uid == reservationOwner) {
        if (status == MarketStatuses.open) {
            return true
        } else if (status == MarketStatuses.new) {
            return true
        } else if (status == MarketStatuses.approved) {
            return true
        } else {
            return false
        }
        return true
    } else if (currentUser.isGod == true) {
        return true
    } else if (currentUser.isAdmin == true) {
        return true
    } else {
        return false
    }
}

