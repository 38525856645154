import React, { useEffect, useState } from 'react';
import { FlatList, Platform, Text, Pressable, View } from 'react-native';
import { Avatar, ButtonGroup } from 'react-native-elements';
import styles from './styles';
//import DelayInput from "react-native-debounce-input";
import { Route } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual, useDispatch } from 'react-redux';
import { Season, SeasonCourtSetting } from '../../../functions/src/interfaces';
import BigButton from '../../components/BigButton';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import CustomPickerSelect from '../../components/CustomPickerSelect/CustomPickerSelect';
import ExemptionSwitch from '../../components/ExemptionSwitch';
import FormEntry from '../../components/FormEntryNew';
import FormHeading from '../../components/FormHeading';
import TimeInput from '../../components/TimeInput/TimeInput';
import WebWrapper from '../../components/WebWrapper';
import i18n, { tokens } from '../../i18n';
import { editSeason, manageSeasonToState } from '../../store/actions/actionsAuth';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { AuthActions } from '../../store/reducers/authReducer';
import { RootStore } from '../../store/reducers/rootReducer';
import { normalize, seasonTypes } from '../../utilities/constants';
import { monthString } from '../../utilities/funcs';
import { mainFontBold } from '../../utilities/globalStyles';
import { StateLoader } from '../../utilities/interfaces';
import CourtTimeRangePrice, { imageByType } from './Components/NewCourtTimeRangePrice';
import RenderSeasonComponent from './Components/SeasonComponent';

type CourtProps = {
    item: SeasonCourtSetting,
    courtId: string,
    courtDetailVis: string,
    setCourtDetailVis: (state) => void
}



const CourtComponent = (props: CourtProps) => {
    //console.log('Court', props  )
    const dispatch = useDispatch()
    
    
    

    const switches = [
        {key: 'isClubRegistered', caption: i18n.t(tokens.app.constants.roleTypes.isClubRegistered) },
        {key: 'isCoach', caption: i18n.t(tokens.app.constants.roleTypes.isCoach) },
        {key: 'isAdmin', caption: i18n.t(tokens.app.constants.roleTypes.isAdmin) },
        {key: 'isGod', caption: i18n.t(tokens.app.constants.roleTypes.isGod) },
        {key: 'paid', caption: i18n.t(tokens.app.screens.manageSeason.manageSeasonPaid) },
        {key: 'isMember', caption: i18n.t(tokens.app.constants.roleTypes.isMember) },
        {key: 'isPromised', caption: i18n.t(tokens.app.screens.manageSeason.manageSeasonPromised) },
    ]

    const switchesToObject = (arr) => {
        var out = {}
        for (let i = 0; i < arr.length; i++) {
            out[arr[i]['key']] = false
        }
        return out
    }

    return (
        
        <View
        style={{
            flexDirection: "row",
            width: "100%",
            alignItems: "center"
        }}
        >
            <View style={{ flexDirection: "row"}}>
                <Pressable
                style={{
                    flexDirection: "column",
                    margin:5,
                    alignItems: "center",
                    
                }}
                onPress={() => {
                    dispatch({type: AuthActions.authEditSeasonCourtValues, data: {
                        //season: props.season, 
                        court: props.courtId, 
                        value: {selected: !props.item.selected},
                        approval: switchesToObject(switches),
                    }})
                }}
                >
                <Text maxFontSizeMultiplier={1.2}>{props.item.courtId}</Text>
                <Avatar rounded size={normalize(50)}
                avatarStyle={
                    {
                        borderColor: "black", 
                        borderWidth: 1, 
                        borderRadius: normalize(50), 
                        opacity: props.item.selected?1:0.2
                    }
                }
                source={imageByType(props.item.type)}
                activeOpacity={0.7}
                />
            </Pressable>
        </View>
        <View style={{
            flexDirection: "row",
            flex: 1
        }}>
        <View
        key={"court"}
        style={{
            flexDirection: "column",
            width: "100%",
            //alignItems: "center"
        }}
        >
                <FormEntry caption={i18n.t(tokens.app.screens.manageSeason.manageSeasonUnlockDate)} editable={props.item.selected}>
                    
                    <CustomDatePicker 
                    /*placeholder="Date of birth: "*/ 
                    /*placeholder={i18n.t(tokens.app.screens.manageSeason.manageSeasonUnlockDate)}*/
                    icon={false}
                    containerStyle={styles.inputOk}
                    date={props.item.unlockDate} 
                    fontStyle={{fontSize: 15}}
                    disabled={!props.item.selected}
                    setDate={(newDate) => {dispatch({type: AuthActions.authEditSeasonCourtValues, data: {court: props.item.courtId, value: {unlockDate: newDate}}})}}
                    />
                    
                
                </FormEntry>
                {/* <Pressable
                style={{padding: 10}}
                onPress={() => {
                    props.setCourtDetailVis(prev => {
                        console.log('Press court: ' + (prev == "" || prev != props.item.courtId ? props.item.courtId : ""))
                        return prev == "" || prev != props.item.courtId ? props.item.courtId : ""
                    })
                }}
                >
                    <Text maxFontSizeMultiplier={1.2} style={{fontSize: 15, fontWeight: "bold"}}>...</Text>
                </Pressable> */}
                {//props.item.courtId == props.courtDetailVis &&
                true? 
                <View
                key={"switches"}
                style={{
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minHeight:50
                }}
                >

                <FlatList 
                horizontal={true}
                data={switches}
                renderItem={({item}) => {return (<ExemptionSwitch disabled={!props.item.selected} court={props.courtId} key={item.key} switchKey={item.key} switchCaption={item.caption} value={props.item?.approval?.[item.key]}/>)}}
                />
                
                
                </View> : null}
        </View>
        
        </View>
        </View>
    )
}

type Props = {
    season: Season,
    activeUntil?: Date
}

const SeasonComponent = (props: Props) => {
    //console.log('Season', props)
    const dispatch = useDispatch()
    const [courtDetailVis, setCourtDetailVis] = useState("")
    //const [editSeasonFlag,setEditSeasonFlag] = useState(false)
    //console.log(props.item)
    //const managedSeason: StateLoader<Season> = useSelector((state: RootStore) => state.auth.managedSeason, shallowEqual)
    const [buttonGroupIndex, setButtonGroupIndex] = useState(0)
    
    
    
    const [buttons,setButtons] = useState(
            [
                i18n.t(tokens.app.screens.manageSeason.manageSeasonButtonsBasic), 
                i18n.t(tokens.app.screens.manageSeason.manageSeasonButtonsCourts),
                i18n.t(tokens.app.screens.manageSeason.manageSeasonButtonsPrices)
            ]
        )
    const renderSwitch = (param: number) => {
        switch(param) {
            case 0:
            return (
            <RenderSeasonComponent
            season={props.season}
            activeUntil={props.activeUntil}
            />);
            case 1:
                return (<View>
                    <FormHeading heading={i18n.t(tokens.app.screens.manageSeason.manageSeasonCourtsHeading)}/>
                    {Object
                    .keys(props.season.courtsList)
                    .sort((a,b) => props.season.courtsList[a].courtId > props.season.courtsList[b].courtId ? 1 : -1)
                    .map(
                        (key) => {
                            //console.log('court data ', key)
                            return (<CourtComponent key={key} item={props.season.courtsList[key]} courtId={key} courtDetailVis={courtDetailVis} setCourtDetailVis={setCourtDetailVis}/>)
                        }
                        )
                    }
                </View>)
            case 2:
                //return (<CourtTimeRangePrice prices={props.season.prices}/>)
                return (<CourtTimeRangePrice/>)
            default:
            return (<></>);
        }
    }
    return (
        <View >
            <ButtonGroup
            onPress={(val) => {setButtonGroupIndex(val)} }
            selectedIndex={buttonGroupIndex}
            buttons={buttons}
            //buttonStyle={{borderColor: secondColor}}
            selectedButtonStyle={{backgroundColor: "grey"}}
            containerStyle={styles.toolbar}
            buttonContainerStyle={{margin: 0, padding: 0, height: "100%"}}
            textStyle={{color: "black", fontFamily: mainFontBold, fontWeight: "bold"}}
            />
            <FormHeading 
            heading={i18n.t(tokens.app.screens.manageSeason.manageSeasonSeasonHeading, {id:props.season.firebaseId})} 
            />
            {renderSwitch(buttonGroupIndex)}
        </View>
    )
}
type SeasonProps = {
    route: Route<'ManageSeasonScreen', {seasonId: string}>
}

function ManageSeasonScreen(props: SeasonProps) {
    //console.log('Rendering')
    const dispatch = useAppDispatch()
    const seasonId = props.route.params.seasonId
    const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    const managedSeason: StateLoader<Season> = useAppSelector((state:RootStore) => {
        let seas = state.auth.managedSeason
        //console.log('Auth reducer pops', seas)
        return seas
    }, (a,b) => JSON.stringify(a) == JSON.stringify(b))

    const activeUntil = useAppSelector((state: RootStore) => state.auth.allClubs?.[state.auth.selectedClub]?.activeUntil, (a, b) => a.toISOString() == b.toISOString())
    const navigation = useAppNavigation()

    useEffect(() => {
        
        dispatch(manageSeasonToState({
            seasonId: seasonId,
            club: selectedClub,
        }, (bool, context) => {
            if (bool == true) {
                console.log('managedSeason' + JSON.stringify(context))
            } else {
                console.log('managedSeason error' + JSON.stringify(context))
            }
        }))
    }, [seasonId, selectedClub])

    //useEffect(() => { console.log(managedClub)}, [managedClub])
    useEffect(() => {
        console.log('Effect')
    } ,[])

    const _editSeason = () => {
        dispatch(editSeason(managedSeason.data, 
            
            (bool, context) => {
            if (bool == true) {
                console.log('Povedlo se upravit club' + JSON.stringify(context))
                navigation.goBack()
            } else {
                
                console.log('Nepovedlo se upravit club' + JSON.stringify(context))
            }
        }))
    }
    
    return (
        
        <View style={styles.container}>
            <WebWrapper>
            {managedSeason && managedSeason.isLoaded?
            <KeyboardAwareScrollView  
            keyboardDismissMode='on-drag'
            keyboardShouldPersistTaps="handled"
            enableResetScrollToCoords={false}
            contentContainerStyle={{ flexGrow: 1 }}
            extraHeight={Platform.select({ android: 200, ios: 200 })}
            >
            
            <SeasonComponent season={managedSeason.data} activeUntil={activeUntil}/>
            <View style={{flexDirection: "row"}}>
            <BigButton
            onPress={_editSeason}
            buttonText={i18n.t(tokens.app.screens.manageSeason.manageSeasonSaveButton)}
            />
            </View>
            </KeyboardAwareScrollView>
            :null}
            </WebWrapper>
        </View>
        
    )
}


export default ManageSeasonScreen



