import React, { useEffect, useState } from 'react';
import { ActivityIndicator, DeviceEventEmitter, Platform, StyleSheet, Text, Pressable, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual } from 'react-redux';
import { Club, Market, MarketStatuses, MarketSubscription, MarketTypes, Membership, MembershipTypes, ProductTypes, user, UserRoles } from '../../../../functions/src/interfaces';
import ActionOverlay from '../../../components/ActionOverlay';
import BigButton from '../../../components/BigButton';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import CustomPickerSelect from '../../../components/CustomPickerSelect/CustomPickerSelect';
import FormEntry from '../../../components/FormEntryNew';
import FormHeading from '../../../components/FormHeading';
import UserAvatar from '../../../components/UserAvatar';
import i18n, { tokens } from '../../../i18n';
import { addSubscription, editMarket, marketDeletionAction } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { RootStore } from '../../../store/reducers/rootReducer';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../../store/selectors';
import { membershipTypes, normalize } from '../../../utilities/constants';
import { monthString, objectToPicker } from '../../../utilities/funcs';
import { globalStyleSheet } from '../../../utilities/globalStyles';
import { StateLoader } from '../../../utilities/interfaces';
import { CheckoutModes } from '../../CheckoutScreen/Components/types';
import CheckoutInfo from '../CommonMarketComponents/CheckoutInfo';
import OwnerComponent from '../CommonMarketComponents/OwnerComponent';
import OwnerComponentBase from '../CommonMarketComponents/OwnerComponentBase';
import TourDetail from './Components/TourDetail';
import YearlyDetail from './Components/YearlyDetail';

type Props = {
    firebaseId?: string,
    club: string,
    setMarketId: (newVal: string) => void,
    setMarketButtonIndex: (index: number) => void,
}

function ManageSubscription(props: Props):JSX.Element {
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const orderData: StateLoader<MarketSubscription<Market>> = useAppSelector((state:RootStore) => {
      let data = state.market.managedMarket
      if (data.data.type == MarketTypes.subscription) {
        let subsdata = data.data
        return {...data, data: {...subsdata}}
      } else {
        return {...data, data: undefined}
      }
      
    }, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const users: {
      [uid: string]: UserOrClub<{}>;
    } = useAppSelector((state:RootStore) => state.users.usersData, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const currClub: Club = useAppSelector((state: RootStore) => {
      return state.auth.allClubs[state.auth.selectedClub]
    }, shallowEqual)

    const [membItems, setMembItems] = useState([])

    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        dispatch(editMarket(changes))
    }

    

    const calcEndDate = (type: MembershipTypes, from: Date):Date => {
        let newDate = new Date(from)
        if (type == MembershipTypes.yearSubscription) {
            newDate.setDate(newDate.getDate() + 365)
        }
        return newDate
    }

    const isEditor = () => {
      if (currentUser.type == "user") {
          return currentUser.uid == orderData.data.mainUid || currentUser.isAdmin == true || currentUser.isGod == true
      } else if (currentUser.type == "clubs") {
          return currentUser.firebaseId == orderData.data.club
      }
      
  } 

  useEffect(() => {
    var out = []
    let prodObj: Membership = currClub.products?.[ProductTypes.subscription]
    if (prodObj) {
      let prices = Object.keys(prodObj.prices)
    
     
        for (let j = 0; j < prices.length; j++) {
          let item = {
            label: membershipTypes[prodObj.type] + ' - ' + prodObj.prices[prices[j]].name,
            value: prodObj.prices[prices[j]].firebaseId,
            key: prodObj.prices[prices[j]].firebaseId
          }
          
          
          out.push(item)
        }
      
    }
    
    setMembItems(out)
  }, [currClub.products])

    const playerPress = () => {
        //console.log('volleeee', Object.keys(props.data).length, props.sideCaption)
    
          DeviceEventEmitter.addListener("event.mapNewPlayerToGame", (eventData) => {
            dispatchChanges(() => { return  {mainUid: eventData}})
            //props.side[key] = eventData
            console.log('catched daat', eventData)
          }
          )
      
          navigation.navigate('ContactsScreenStack', {
            action: 'selectPlayer', 
            path: ['mainUid'], 
            alreadySelected: []
          })
        
    }

    const addOrderAction = () => {
        dispatch(addSubscription())
        .then((result) => {
          if (!orderData.data.firebaseId && result.data.marketId && result.data.marketId != "") {
            props.setMarketId(result.data.marketId)
          }
          if (result.action == 'checkout') {
            props.setMarketButtonIndex(1)
          }
        })
        .catch((e) => {
  
        })
      }
    
      const removeReservationAction = () => {
        dispatch(marketDeletionAction(orderData.data.club,orderData.data.firebaseId, 'all'))
        .then((result) => {
          if (!orderData.data.firebaseId && result.data.marketId && result.data.marketId != "") {
            props.setMarketId(result.data.marketId)
          }
          if (result.action == 'checkout') {
            //navigation.navigate('CheckoutScreen', {checkoutId: result.data.checkoutId, clubId: orderData.data.club, mode: CheckoutModes.requiresRefund})
            props.setMarketButtonIndex(1)
          }
        })
        .catch((e) => {
  
        })
      }
    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >

                {orderData.isLoaded == true?
                <View style={styles.container}>
                    <ActionOverlay
                    onBackdropPress={()=> {}}
                    fullScreen={true}
                    isVisible={orderData.isActionPressed}
                    />
                    <OwnerComponentBase
                    marketData={orderData.data}
                    />
                    <FormEntry 
                    caption={i18n.t(tokens.app.screens.manageReservation.reservationAuthor)} 
                    editable={false}
                    >
                        <Pressable
                        disabled={false}
                        onPress={playerPress}
                        >
                        <UserAvatar uid={orderData.data.mainUid} size={normalize(70)}/>
                        </Pressable>
                    </FormEntry>
                    <CheckoutInfo marketData={orderData.data}/>
                    <FormHeading
                    heading={i18n.t(tokens.app.screens.subscription.subscriptionMembership)}
                    />
                    <FormEntry
                    caption={i18n.t(tokens.app.screens.subscription.subscriptionCurrEnds)}
                    editable={false}
                    >
                        <Text>{monthString(orderData.data.minFrom)}</Text>
                    </FormEntry>
                    <FormEntry
                    caption={i18n.t(tokens.app.screens.subscription.subscriptionType)}
                    editable={true}
                    >
                        <CustomPickerSelect
                        onValueChange={(value) => dispatchChanges(() => {return {memType: value, to: calcEndDate(value, orderData.data.from)}})}
                        value={orderData.data.memType}
                        placeholder={{ label: i18n.t(tokens.app.screens.subscription.subscriptionType), value: undefined }}
                        disabled={!isEditor()}
                        items={objectToPicker(Object.keys(membershipTypes).reduce((prev, curr) => {
                          if (currClub.products?.[ProductTypes.subscription]?.type == curr) {
                            return {
                              ...prev,
                              [curr]: membershipTypes[curr]
                            }
                          } else {
                            return {
                              ...prev
                            }
                          }
                        }, {}))}
                        //textAlign="right"
                        />
                    </FormEntry>
                    {
                      orderData.data.memType == MembershipTypes.tourSubscription ?
                      <TourDetail dispatchChanges={dispatchChanges} subscription={orderData.data}/> : null
                    }
                    {
                      orderData.data.memType == MembershipTypes.yearSubscription ?
                      <YearlyDetail dispatchChanges={dispatchChanges} subscription={orderData.data} calcEndDate={calcEndDate}/> : null
                    }
                    <FormEntry
                    caption={i18n.t(tokens.app.screens.subscription.subscriptionPriceVariant)}
                    editable={true}
                    >
                        <CustomPickerSelect
                        onValueChange={(value) => dispatchChanges(() => {
                          let products = currClub.products[ProductTypes.subscription]
                          let product = {...products.prices[value], type: products.type}
                          return {product: product}
                        })}
                        value={orderData.data.product.firebaseId}
                        placeholder={{ label: i18n.t(tokens.app.screens.subscription.subscriptionPriceVariant), value: undefined }}
                        disabled={!isEditor()}
                        items={membItems}
                        //textAlign="right"
                        />
                    </FormEntry>
                    <FormEntry
                    caption={i18n.t(tokens.app.screens.checkout.checkoutSubtotal)}
                    editable={false}
                    >
                        <Text style={globalStyleSheet.entityText}>
                        {String(orderData.data.product.price || 0)}
                        </Text>
                    </FormEntry>



                    {[MarketStatuses.new, MarketStatuses.open, MarketStatuses.blocked, MarketStatuses.approved].includes(orderData.data.status) && 
                    (orderData.isChanged == true || !orderData.data.firebaseId) && 
                    (isEditor() == true) && 
                    (users && users[orderData.data.mainUid]?.type != "clubs")?
                    <BigButton
                    onPress={addOrderAction}
                    //disabled={true}
                    buttonText={i18n.t(tokens.app.screens.manageReservation.reservationSaveButton)}
                    />
                    :null}
                    {[MarketStatuses.open].includes(orderData.data.status) && orderData.data.firebaseId && (isEditor() == true)?
                    <BigButton
                    
                    onPress={removeReservationAction}
                    buttonText={i18n.t(tokens.app.screens.opengame.removeButton)}
                    />
                    :null}
                </View>:
                <ActivityIndicator size={"large"}/>
                }   
 
        </KeyboardAwareScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    }
})

export default ManageSubscription