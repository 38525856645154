import React from 'react'
import { Platform, SafeAreaView, Text, Pressable, View } from 'react-native'

import styles from './styles'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export type Props = { error: Error; resetError: () => void }

const FallbackComponent = (props: Props) => (
    <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >
  <View style={styles.container}>
    <View style={styles.content}>
      <Text style={styles.title}>Oops!</Text>
      <Text style={styles.subtitle}>{"There's an error"}</Text>
      <Text style={styles.error}>{props.error.toString()}</Text>
      {props.error?.stack?<Text style={styles.error}>{JSON.stringify(props.error.stack)}</Text>:null}
      <Pressable style={styles.button} onPress={props.resetError}>
        <Text style={styles.buttonText}>Try again</Text>
      </Pressable>
    </View>
    </View>
  </KeyboardAwareScrollView>
)

export default FallbackComponent
