import React, { useEffect, useState } from 'react'
import { Dimensions, Modal, View, StyleSheet, ScrollView, Text, Pressable, Alert } from 'react-native'
import { BlurView } from 'expo-blur';
import MessagesList from './MessagesList';
import CommentView from './CommentView';
import { Message } from '../utilities/interfaces';
import { useAppDispatch } from '../store/hooks';
import { addEditMessage } from '../store/actions/actionsMarketNew';
import { AnyAction } from 'redux';
import FormEntryNoCaption from './FormEntryNoCaption';
const windowWidth = Dimensions.get('window').width - 40;
const windowHeight = Dimensions.get('window').height;

type Props = {
    comentable: boolean
    messages: Message[]
    addOrEditMessage: (msg: Message, callback: (res: boolean, context: any) => void) => void
}

function CommentsComponent(props: Props): JSX.Element {
    const [message, setMessage] = useState({ message: "" })
    const dispatch = useAppDispatch()

    const addOrEditMessageWrap = (msg: Message, callback: (res: boolean) => void) => {
        props.addOrEditMessage(msg, (res, context) => {
            if (res == true) {
                setMessage({ message: "" })
                callback(true)
                console.log('Ok:' + JSON.stringify(context))
            } else {
                callback(false)
                console.log('Error:' + JSON.stringify(context))
                Alert.alert("Warning", context.message ? context.message : "Some unknown error")
            }
        })
    }
    return (
        props.comentable ?
            <>  
                <MessagesList data={props.messages} />
                
                <CommentView
                    //bottomInputVisible={bottomInputVisible} 
                    //setBottomInputVisible={setBottomInputVisible} 
                    setMessage={(message) => setMessage((prevValue) => { return { ...prevValue, message: message } })}
                    message={message}
                    submitMessage={
                        addOrEditMessageWrap
                    }
                /></> : null)
}

export default CommentsComponent;



const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
    },
    modal: {
        marginLeft: 50,
        marginRight: 50,
        minHeight: "40%",
        padding: 5,
        backgroundColor: "white",
        shadowColor: "black",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalFS: {
        flex: 1,
        backgroundColor: "white",
    },
    /* The content of the modal takes all the vertical space not used by the header. */
    modalContent: {
        width: "100%",
        borderWidth: 0,
        borderColor: "black"
    },
    modalContentFS: {
        flex: 1
    },
    modalHeader: {
        flexDirection: "row",
        borderWidth: 0,
        borderColor: "black"
    },
    /* The header takes up all the vertical space not used by the close button. */
    modalHeaderContent: {
        flexGrow: 1,
    },
    modalHeaderCloseText: {
        textAlign: "center",
        paddingLeft: 5,
        paddingRight: 5
    },
    outsideModal: {
        backgroundColor: "rgba(1, 1, 1, 0.2)",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    }
});