import React, { useState } from "react";
import { Switch, Text, TextInput, View } from "react-native";
import { ClubBasic, Currencies } from "../../../../functions/src/interfaces";
import ActionOverlay from "../../../components/ActionOverlay";
import BigButton from "../../../components/BigButton";
import CustomPickerSelect from "../../../components/CustomPickerSelect/CustomPickerSelect";
import FormEntry from "../../../components/FormEntryNew";
import FormHeading from "../../../components/FormHeading";
import i18n, { tokens } from "../../../i18n";
import { useAppNavigation } from "../../../store/hooks";
import { currency } from "../../../utilities/constants";
import { globalStyleSheet, mainFontBold } from "../../../utilities/globalStyles";
import { validateClub, validateEmail, validatePassword, validatePhone } from "../../../utilities/validators";
import { formValid } from "./Validations";
import FormEntryNoCaption from "../../../components/FormEntryNoCaption";
import PrivacyPolicyComponent from "./PrivacyPolicyComponent";


type Props = {
    registerClub: (user: ClubBasic, callback) => void
}

const validateClubState = (state: ClubBasic) => {
    return {
        uid: {value: state.firebaseId, validity: true, errorMessage: ""},
        club_name : {value: state.club_name, validity: validateClub(state.club_name), errorMessage: i18n.t(tokens.app.screens.registration.first_name_validity)},
        email : {value: state.email, validity: validateEmail(state.email), errorMessage: i18n.t(tokens.app.screens.registration.email_validity)},
        emailConfirm : {value: state.emailConfirm, validity: validateEmail(state.emailConfirm), errorMessage: i18n.t(tokens.app.screens.registration.emailConfirm_validity)},
        phone : {value: state.phone, validity: validatePhone(state.phone), errorMessage: i18n.t(tokens.app.screens.registration.phone_validity)},
        password: {value: state.password, validity: validatePassword(state.password), errorMessage: i18n.t(tokens.app.screens.registration.password_validity)},
        confirmPassword: {value: state.confirmPassword, validity: state.password == state.confirmPassword, errorMessage: i18n.t(tokens.app.screens.registration.confirmPassword_validity)},
        termsAndConditions:  {value: state.termsAndConditions, validity: state.termsAndConditions == true, errorMessage: i18n.t(tokens.app.screens.registration.termsAndConditionsError)},
        currency:  {value: state.currency, validity: Object.keys(currency).includes(state.currency), errorMessage: i18n.t(tokens.app.screens.registration.termsAndConditionsError)},
    }
}

function ClubForm(props: Props):JSX.Element {
    const [isActionPressed, setIsActionPressed] = useState(false)
    const navigation = useAppNavigation()
    const currencies = Object.keys(currency).map((key) => {return {label: currency[key], value: key}})
    const [club, setClub] = useState<{
        [key: string]: {value: any, validity: boolean, errorMessage: string}
    }>(validateClubState({
        firebaseId: undefined,
        club_name: "",
        email: "",
        emailConfirm: "",
        phone: "",
        password: "",
        confirmPassword: "",
        termsAndConditions: false,
        currency: Currencies.CZK,
    }))

    const dispatchChange = (callback: () => {[key: string]: any}) => {
        let change = callback()
        //console.log(change)
        setClub(state => {
            let newClub = {
                ...Object.keys(state).reduce((prev, key)=> {return {...prev, [key]: state[key].value}}, {}),
                ...change
            }
            //console.log(newClub)
            return validateClubState(newClub as any)
        })
      }

    
    
    return <View>
        <ActionOverlay 
        onBackdropPress={() => {}}
        fullScreen={false}
        isVisible={isActionPressed}
        />
        <FormHeading icon="people-outline" heading={i18n.t(tokens.app.screens.manageClub.manageClubGeneralInfo)}/>
        <FormEntry caption={i18n.t(tokens.app.screens.registration.registrationClubName)} editable={true}
        error={club.club_name.errorMessage} 
        validity={!(!club.club_name.validity && club.club_name.value.length > 0)}
        >
                <TextInput maxFontSizeMultiplier={1.2}
                    style={globalStyleSheet.inputOk}
                    placeholder={i18n.t(tokens.app.screens.registration.registrationClubName)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {
                        dispatchChange(() => { return { club_name: text } })
                    }}
                    value={club.club_name.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="words"
                    //id="club_name"
                />
            </FormEntry>
            <FormHeading  icon="mail-outline" heading={i18n.t(tokens.app.screens.registration.contactsHeading)}/>       
            <FormEntry
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.phone)} 
            error={club.phone.errorMessage} 
            validity={!(!club.phone.validity && club.phone.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={club.phone.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.phone)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { phone: text } })}}
                    value={club.phone.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="phone"
                    inputMode="numeric"
                />
            </FormEntry>     
            <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.registration.email)} 
            error={club.email.errorMessage} 
            validity={!(!club.email.validity && club.email.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={club.email.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.email)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { email: text } })}}
                    value={club.email.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="email"
                    inputMode="email"
                />
            </FormEntry>     
            <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.registration.emailConfirm)} 
            error={club.emailConfirm.errorMessage} 
            validity={!(!club.emailConfirm.validity && club.emailConfirm.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={club.emailConfirm.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.email)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { emailConfirm: text } })}}
                    value={club.emailConfirm.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="email"
                    inputMode="email"
                />
            </FormEntry>        
            <FormHeading icon="ios-key-outline" heading={i18n.t(tokens.app.screens.registration.passwordsHeading)}/>        
            <FormEntry
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.password)} 
            error={club.password.errorMessage} 
            validity={!(!club.password.validity && club.password.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={club.password.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholderTextColor="#aaaaaa"
                    secureTextEntry
                    placeholder={i18n.t(tokens.app.screens.registration.password)}
                    onChangeText={(text) => {dispatchChange(() => { return { password: text } })}}
                    value={club.password.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="password"
                />
            </FormEntry>  
            <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.registration.confirmPassword)}  
            error={club.confirmPassword.errorMessage} 
            validity={!(!club.confirmPassword.validity && club.confirmPassword.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={club.confirmPassword.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholderTextColor="#aaaaaa"
                    secureTextEntry
                    placeholder={i18n.t(tokens.app.screens.registration.confirmPassword)}
                    onChangeText={(text) => {dispatchChange(() => { return { confirmPassword: text } })}}
                    value={club.confirmPassword.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="confirmPassword"
                />
            </FormEntry> 
            <PrivacyPolicyComponent/>
            <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.registration.termsAndConditions)} 
            error={club.termsAndConditions.errorMessage} 
            validity={club.termsAndConditions.validity}>
                <View
                style={{alignSelf: "flex-end"}}
                >
                    
                    <Switch 
                    value={club.termsAndConditions.value}
                    onValueChange={(value) => dispatchChange(() => { return { termsAndConditions: value } })}
                    />
                </View>
            </FormEntry>  
            <FormEntry
            caption={i18n.t(tokens.app.screens.manageClub.manageClubPaymentsCurr)}
            editable={true}
            >
                <CustomPickerSelect
                onValueChange={(value) => dispatchChange(() => { return { currency: value } })} 
                aditionalStyle={{backgroundColor: "white"}}
                value={club.currency.value}
                placeholder={{label: i18n.t(tokens.app.screens.manageClub.manageClubPaymentsCurr), value: undefined}}
                /* disabled={newReservation.mainUid == props.auth.uid?false:true} */
                items={currencies}
                />
            </FormEntry> 
            <FormEntryNoCaption editable={false}>
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.messageText}>
                    {i18n.t(tokens.app.screens.registration.registerClubTrialMessage)}
                </Text>
            </FormEntryNoCaption>  
            <BigButton
            disabled={!formValid(club)}
            onPress={() => {
                setIsActionPressed(true)
                let data: ClubBasic = Object.keys(club).reduce((prev,key) => {return {...prev, [key]: club[key].value}}, {}) as any
                props.registerClub(data, (bool, context) => {
                    setIsActionPressed(false)
                    if (bool == true) {
                        let club: ClubBasic = context
                        console.log('Club callback', club)
                        navigation.popToTop()
                        navigation.navigate('ManageClubScreen', {clubId: context.firebaseId})
                    }
            })}}
            buttonText={i18n.t(tokens.app.screens.registration.createAccountButtonCaption)}
            />
    
    </View>
}

export default ClubForm