import { CheckoutData, Club, PaymentData, PriceItem } from "functions/src/interfaces"
import i18n, { tokens } from "../../i18n";
import { monthString } from "../../utilities/funcs"
import { marketTypes } from '../../utilities/constants';


const VAT = 0.15

const composePaymentInvoice = (checkoutData: CheckoutData, paymentData: PaymentData, club: Club) => {

    
    let paymentDate: string = monthString(paymentData.createdDate)

    let currDate: Date = new Date
    let currDateString: string = monthString(currDate)

    let paymentDescrs = {
        reservation: i18n.t(tokens.app.constants.invoicePaymentTypeDesc.reservation),
        subscription: i18n.t(tokens.app.constants.invoicePaymentTypeDesc.subscription),
        credit: i18n.t(tokens.app.constants.invoicePaymentTypeDesc.credit),
    }
    
    
    let priceItems = Object.entries(checkoutData.detail).reduce(
        (prev: {[type:string]:{qty: number, priceNonVat: number, priceVat: number, vat: number}}, [key, item]) => {
        let prevValues = prev[item.itemType] || {qty: 0, priceNonVat: 0, priceVat: 0, vat: 0}
        let priceWoVAT = item.price/(1+VAT)
        let VATamt = priceWoVAT*VAT
        let newValues = {
            qty: prevValues.qty + 1,
            priceNonVat: prevValues.priceNonVat + priceWoVAT,
            vat: prevValues.vat + VATamt,
            priceVat: prevValues.priceVat + item.price
        }
        let out = {
            ...prev,
            [item.itemType]: newValues
        }
        return out
    }, {})

    let total = Object.entries(priceItems).reduce(
        (prev: {subtotalWoVAT: number, subtotalVAT: number, subtotalWithVAT: number}, [key, item]) => {
            return {
                subtotalWoVAT: prev.subtotalWoVAT + item.priceNonVat,
                subtotalVAT: prev.subtotalVAT + item.vat,
                subtotalWithVAT: prev.subtotalWithVAT + item.priceVat
            }
        }
    , {subtotalWoVAT: 0, subtotalVAT: 0, subtotalWithVAT: 0})

    const html = `
    <!DOCTYPE html>
    <html lang="en">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            <title>Invoice</title>
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
                rel="stylesheet"
            />
            <style>
                @media print {
                    @page {
                        size: A3;
                    }
            .noPrint{
            display:none;
            }
                }
                ul {
                    padding: 0;
                    margin: 0 0 1rem 0;
                    list-style: none;
                }
                body {
                    font-family: "Inter", sans-serif;
                    margin: 0;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                table,
                table th,
                table td {
                    border: 1px solid silver;
                }
                table th,
                table td {
                    text-align: right;
                    padding: 8px;
                }
                h1,
                h4,
                p {
                    margin: 0;
                }

                .container {
                    padding: 20px 0;
                    width: 1000px;
                    max-width: 90%;
                    margin: 0 auto;
                }

                .inv-title {
                    padding: 10px;
                    border: 1px solid silver;
                    text-align: center;
                    margin-bottom: 30px;
                }

                .inv-logo {
                    width: 150px;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 40px;
                }

                /* header */
                .inv-header {
                    display: flex;
                    margin-bottom: 20px;
                }
                .inv-header > :nth-child(1) {
                    flex: 2;
                }
                .inv-header > :nth-child(2) {
                    flex: 1;
                }
                .inv-header h2 {
                    font-size: 20px;
                    margin: 0 0 0.3rem 0;
                }
                .inv-header ul li {
                    font-size: 15px;
                    padding: 3px 0;
                }

                /* body */
                .inv-body table th,
                .inv-body table td {
                    text-align: left;
                }
                .inv-body {
                    margin-bottom: 30px;
                }

                /* footer */
                .inv-footer {
                    display: flex;
                    flex-direction: row;
                }
                .inv-footer > :nth-child(1) {
                    flex: 2;
                }
                .inv-footer > :nth-child(2) {
                    flex: 1;
                }
        .footer {
                    display: flex;
                    flex-direction: row;
            flex: 1;
            justify-content: center;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <div class="inv-title">
                    <h1>Příjmový doklad # ${checkoutData.firebaseId}</h1>
                </div>
                <div class="inv-header">
                    <div>
                        <h2>${club.club_name}</h2>
                        <ul>
                            <li>${club.address}</li>
                            <li>${club.city}</li>
                            <li>ICO: ${club.ico}</li>
                        </ul>
                    </div>
                    <div>
                        <table>
                            <tr>
                                <th>Datum vystavení</th>
                                <td>${currDateString}</td>
                            </tr>
                            <tr>
                                <th>Datum uhrazení</th>
                                <td>${paymentDate}</td>
                            </tr>
                            <tr>
                                <th>Forma úhrady</th>
                                <td>${i18n.t(paymentData.type)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="inv-body">
                    <table>
                        <thead>
                            <th>Produkt</th>
                            <th>Počet</th>
                            <th>Cena</th>
                        </thead>
                        <tbody>
                            ${Object.entries(priceItems).map(([type, data]) => {
                                return `
                                <tr>
                                    <td>
                                        <h4>${marketTypes[type]}</h4>
                                        <p>${paymentDescrs[type]}</p>
                                    </td>
                                    <td>${data.qty}</td>
                                    <td>${Math.round(data.priceVat)}</td>
                                </tr>
                                `
                            })}
                        </tbody>
                    </table>
                </div>
                <div class="inv-footer">
                    <div><!-- required --></div>
                    <div>
                        <table>
                            <tr>
                                <th>Částka bez DPH</th>
                                <td>${Math.round(total.subtotalWoVAT)}</td>
                            </tr>
                            <tr>
                                <th>DPH</th>
                                <td>${Math.round(total.subtotalVAT)}</td>
                            </tr>
                            <tr>
                                <th>Celkem k úhradě</th>
                                <td>${Math.round(total.subtotalWithVAT)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        <div class="footer">
        <button onclick="window.print();" class="noPrint">
        Print Me
        </button>
    </div>
        </body>
    </html>
    `;

    return html
}

export default composePaymentInvoice
