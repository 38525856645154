import React from 'react';
import { Text, Pressable, View } from 'react-native';
import { shallowEqual, useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/hooks';
import { ReservationActions, Selection } from '../../store/reducers/reservationReducer';
import { normalize } from '../../utilities/constants';
import { dateToTimeString } from '../../utilities/funcs';

export type Block = {
    key: number,
    //block: number,
    dateFrom: Date,
    //blocksCnt: number,
    dateTo: Date,
    segmentStarts: Date,
    segmentEnds: Date,
    selected: boolean,
    court: string,
    season: string,
    //reservations: reservation[]
}

type Props = {
    block: Block
}

const ReservationsFreeBlock: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch()
    const blockDims = {
        height: 90,
        width: 50
    }
    
    const isSelected = (block, select: Selection) => {
        try {
            if (select.court && block.court == select.court) {
                if (select.dateFrom && block.dateFrom.getTime() == select.dateFrom.getTime()) {
                    return true
                } else if (select.dateFrom && select.dateTo && block.dateFrom > select.dateFrom && block.dateTo <= select.dateTo) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }  

        }
        catch (e) {
            console.log('Neco: ' + JSON.stringify(e))
        }
                     
    }

    //const [prevSelection, setPrevSelection] = useState({})
    const selection = useSelector((state: any) => {
        let sel = state.reservations.selection
        return isSelected(props.block, sel)
    }, shallowEqual)

   /*  useEffect(() => {
        if (selected != isSelected(props.block, selection)) {
            setSelected(prevState => !prevState)
        } 
        
    }, [selection]) */
    
    
    const doSelectionPress = () => {
        console.log('Start press')

        dispatch({type: ReservationActions.reservationSetSelection, data: {...props.block}})
    }

    
    
    return (
        <View
            style={{
                    
                width: blockDims.width - (props.block.segmentStarts.getTime() == props.block.dateFrom.getTime() || props.block.segmentEnds.getTime() == props.block.dateTo.getTime()? 0 : 0),
                minHeight: blockDims.height,
                flex: 1,
                flexDirection: "column",
                //borderStyle: props.block.status == "open" ?  "dashed" : "solid",
                backgroundColor: selection ? "#caffc4" : "white",
                borderRightWidth: props.block.segmentEnds == props.block.dateTo?0:1,
                borderColor: "lightgrey",
                //borderRadius: 1,
                //marginBottom: 5,
            }}>
            <Pressable
            style={{
                flex: 1,
            }}
            onPress={
                () => doSelectionPress()
            }
            >
                <Text maxFontSizeMultiplier={1.2} style={{fontSize: normalize(10)}}>{dateToTimeString(props.block.dateFrom)}</Text>
            </Pressable></View>
            
        
       
    )
}


export default ReservationsFreeBlock


