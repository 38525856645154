import React from "react";
import { Switch, View } from "react-native";
import { Club, MarketTypes } from "../../../../functions/src/interfaces";
import FormEntry from "../../../components/FormEntryNew";
import FormHeading from "../../../components/FormHeading";
import i18n, { tokens } from "../../../i18n";
import { marketTypes, roleTypes } from "../../../utilities/constants";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { StateLoader } from "../../../utilities/interfaces";

type Props = {
    club: StateLoader<Club>
    dispatchChange: (data: any) => void
}
function AutoCaptureComponent(props: Props):JSX.Element {

    const payableMarketTypes = [
        MarketTypes.credit,
        MarketTypes.longreservation,
        MarketTypes.reservation,
        MarketTypes.subscription
    ]

    return (
        <View
        >
            <FormHeading
            heading={i18n.t(tokens.app.screens.manageClub.manageClubAutoCapture)}
            />
            
            {props.club.data?.payUId && props.club.data?.payUId != ""?
            <View>
                {payableMarketTypes.map((key) => {
                    return (<FormEntry
                    caption={marketTypes[key]}
                    editable={true}
                    key={key}
                    >
                        <Switch
                        value={props.club?.data?.autoCapture?.[key] != undefined ? props.club?.data?.autoCapture?.[key] : true}
                        onValueChange={(value) => props.dispatchChange(
                            {
                                autoCapture: {
                                    ...props.club?.data?.autoCapture,
                                    [key]: value
                                    
                                }
                            }
                        )}
                        />
                    </FormEntry>)
                })}
            </View>
            :null}
        </View>
    )
}

export default AutoCaptureComponent