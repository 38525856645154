import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from "expo-constants";
import * as Linking from 'expo-linking';
import firebase from 'firebase/compat/app';
import { DocumentData, Query } from 'firebase/firestore';
import { Platform } from "react-native";
import { CallbackFunc, Club, ClubBasic, CreditBalance, Membership, MembershipTypes, ProductTypes, Season, user, UserPrivate } from "../../../functions/src/interfaces";
import { UserRegistrationForm } from "../../screens/RegistrationScreen/RegistrationComponents/UserForm";
import { dateBOD, dateEOD, dateRangeOverlaps, depersonalizeUserData, removeDicretics, returnActiveSeasons, standardizeDates } from "../../utilities/funcs";
import { showToast } from "../../utilities/toastcontroller";
import { validateEmail } from "../../utilities/validators";
import { AppThunk } from "../hooks";
import { AuthActions } from "../reducers/authReducer";
import { RootStore } from "../reducers/rootReducer";
import { AllUserActions, UsersActions } from "../reducers/usersReducer";

const API_URL = Constants.expoConfig.extra.functionsUrlBase

const retrieveUserOnce = async (firestore: firebase.firestore.Firestore, uid: string):Promise<{type: 'club'} & Club | {type: 'user'} & user> => {
    const clubRef = firestore.collection('clubs').doc(uid)
    const usersPublicRef = firestore.collection('userspublic').doc(uid)
    const usersPrivateRef = firestore.collection('usersprivate').doc(uid)
    const clubDoc = await clubRef.get()
    if (clubDoc.exists) {
        const clubData = standardizeDates(clubDoc.data())
        return {type: 'club', ...clubData}
    } else {
        const usersPublicDoc = await usersPublicRef.get()
        const usersPrivateDoc = await usersPrivateRef.get()
        if (usersPrivateDoc.exists && usersPublicDoc.exists) {
            const usersPublicData = standardizeDates(usersPublicDoc.data())
            const usersPrivateData = standardizeDates(usersPrivateDoc.data())
            return {type: 'user', ...usersPublicData, ...usersPrivateData}
        } else {
            throw {code: 'USER_NOT_FOUND', message: 'Nor user nor club found'}
        }
    }
    
}

const getActiveSeason = (date: Date, allSeasons: {[seasonId: string]: Season}) => {
    
    let actSeas = [...returnActiveSeasons(date, allSeasons)]
    //console.log(actSeas)
    if (actSeas.length == 0) {
        return null
    } else {
        
        return {...actSeas[0]}
    }
}

export const changeDate = (date: Date): AppThunk<void> => {
    return (dispatch, getState, {getFirebase}) => {
        const state = getState()
        const allSeasons = {...state.auth.seasons}
        //console.log(allSeasons)
        const allClubs = {...state.auth.allClubs}
        const selectedClub = allClubs[state.auth.selectedClub]
        
        const activeSeason = true ? getActiveSeason(date, allSeasons) : null
        dispatch({type: AuthActions.authSelectedSeason, data: activeSeason})
        
        dispatch({type: AuthActions.authChangeCurrDate, data: JSON.stringify(date)})


    }
}

export const loggedInSubscriptions = (uid: string): AppThunk<any[]> => {
    return (dispatch, getState, {getFirebase}): any[] => {
        //console.log('User subscriptions ' + uid)
        dispatch({type: AuthActions.authCleanCurrUser, data: {}})
        dispatch({type: UsersActions.usersActionLinkedUsersChange, data: {}})
        const firebase = getFirebase()
        const firestore = firebase.firestore()
        const state: RootStore = getState()

        const userspublicRef = firestore.collection('userspublic').doc(uid)
        const clubsRef = firestore.collection('clubs').doc(uid)
        const usersprivateRef = firestore.collection('usersprivate').doc(uid)
        const rolesQuery = firestore.collectionGroup('roles').where('uid','==',uid)
        const contactlistQuery = firestore.collectionGroup('contactlist').where('uid','==',uid)
        const userspublicQuery = firestore.collection('userspublic').where('am_in_contacts','array-contains',uid)
        const globalRolesRef = firestore.collection('globalroles').doc(uid)
        const creditQuery = firestore.collectionGroup('creditbalance').where('uid','==',uid)
        const linkedRef = firestore.collection('userspublic').where('linkedTo','==',uid)

        const js = {
            'added':{},
            'modified':{},
            'removed':{}
                    
        }

        var promiseChain = []
        clubsRef.get()
        .then((clubDoc) => {
            if (clubDoc.exists) {
                let userspublicUnsubPromise = clubsRef.onSnapshot(
                    (querySnapshot) => {
                        //console.log("Ahoj kurva2", querySnapshot)
                        let changes = {}
                        try {
                            changes = standardizeDates(querySnapshot.data())
                        }
                        catch {
                            console.log('error parsing', querySnapshot)
                        }
                        
                        //let currData = {...state.auth.currentUserData}
                        //console.log('Totoo je return response', {...changes}, currData)
                        dispatch({type: AuthActions.authCurrentUserPublicData, data: {type: "clubs", ...changes}})
                    },
                    (error) => {
                        console.log("Error listening" + JSON.stringify(error))
                    }
                )
                promiseChain.push(userspublicUnsubPromise)
                
            } else {
                let userspublicUnsubPromise = userspublicRef.onSnapshot(
                    (querySnapshot) => {
                        //console.log("Ahoj kurva2", querySnapshot)
                        let changes = {}
                        try {
                            changes = standardizeDates(querySnapshot.data())
                        }
                        catch {
                            console.log('error parsing', querySnapshot)
                        }
                        
                        //let currData = {...state.auth.currentUserData}
                        //console.log('Totoo je return response', {...changes}, currData)
                        dispatch({type: AuthActions.authCurrentUserPublicData, data: {type: "user", ...changes}})
                    },
                    (error) => {
                        console.log("Error listening" + JSON.stringify(error))
                    }
                )
                promiseChain.push(userspublicUnsubPromise)
                let usersprivateUnsubPromise = usersprivateRef.onSnapshot(
                    (querySnapshot) => {
                        //console.log("Ahoj kurva2", querySnapshot)
                        let changes = {}
                        try {
                            changes = standardizeDates(querySnapshot.data())
                        }
                        catch {
                            console.log('error parsing', querySnapshot)
                        }
                        //let currData = {...state.auth.currentUserData}
                        //console.log('Totoo je return response', {...changes}, currData)
                        dispatch({type: AuthActions.authCurrentUserPrivateData, data: {...changes}})
                    },
                    (error) => {
                        console.log("Error listening" + JSON.stringify(error))
                    }
                )
                promiseChain.push(usersprivateUnsubPromise)
                let rolesQueryUnsubPromise = rolesQuery.onSnapshot((querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    let changes = querySnapshot.docChanges()
                    let jsLocal = {'added':{},
                    'modified':{},
                    'removed':{}}
                    changes.forEach(doc => {
                        //console.log(doc)
                        //console.log(doc)
                        let data = doc.doc.data()
                        jsLocal[doc.type][data.club] = standardizeDates(data) 
                        console.log(jsLocal[doc.type][doc.doc.id])
                        
                        
                    })
                    
                    //console.log(js)
                    
                    dispatch({type: AuthActions.authCurrentUserRoles, data: {...jsLocal}})
                    
                })
                promiseChain.push(rolesQueryUnsubPromise)
                let contactlistQueryUnsubPromise = contactlistQuery.onSnapshot((querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    let changes = querySnapshot.docChanges()
                    let jsLocal = {'added':{},
                    'modified':{},
                    'removed':{}}
                    changes.forEach(doc => {
                        //console.log(doc)
                        //console.log(doc)
                        jsLocal[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 
                        //console.log(jsLocal[doc.type][doc.doc.id])
                        
                        
                    })
                    
                    
                    dispatch({type: AuthActions.authCurrentUserContacts, data: {...jsLocal}})
                    
                })
                promiseChain.push(contactlistQueryUnsubPromise)
        
                let userspublicQueryUnsubPromise = userspublicQuery.onSnapshot((querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    let changes = querySnapshot.docChanges()
                    
                    let jsLocal = {'added':{},
                    'modified':{},
                    'removed':{}}

                    const state = getState()
                    let newState = {
                        ...state.users.usersData,
                        
                    }

                    changes.forEach(doc => {
                        //console.log(doc)
                        //console.log(doc)
                        let user = standardizeDates(doc.doc.data()) 
                        jsLocal[doc.type][doc.doc.id] = user
                        //console.log('Amincont det', jsLocal[doc.type][doc.doc.id])
                        newState[doc.doc.id] = {type: "user", ...user}
                        
                    })
                    
                    dispatch({type: UsersActions.usersActionUsersUpdate, data: newState})
                    dispatch({type: AuthActions.authCurrentUserAmInContacts, data: {...jsLocal}})
                    
                })
                promiseChain.push(userspublicQueryUnsubPromise)
        
                let globalRolesUnsubPromise = globalRolesRef.onSnapshot(
                    (querySnapshot) => {
                        //console.log("Ahoj kurva2", querySnapshot)
                        let changes = {}
                        try {
                            changes = standardizeDates(querySnapshot.data())
                        }
                        catch {
                            console.log('error parsing', querySnapshot)
                        }
                        //console.log('Totoo je return response', {...changes})
                        const currData = state.auth.currentUserData
                        dispatch({type: AuthActions.authCurrentUserGlobalRoles, data: {...currData, ...changes}})
                    },
                    (error) => {
                        console.log("Error listening" + JSON.stringify(error))
                    }
                )
                promiseChain.push(globalRolesUnsubPromise)

                let allUserCreditsUnsubPromise = creditQuery.onSnapshot(
                    (querySnapshot) => {
                       //console.log("Ahoj kurva2", querySnapshot)
                        let changes = querySnapshot.docChanges()
                        //console.log('Credit changes', changes)
                        let jsLocal = {
                            'added':{},
                            'modified':{},
                            'removed':{}
                        }
                        changes.forEach(doc => {
                            //console.log('Credit changes',doc.doc.data())
                            //console.log(doc)
                            let data: CreditBalance = standardizeDates(doc.doc.data()) 
                            jsLocal[doc.type][data.club] = data 
                            //console.log(jsLocal[doc.type][doc.doc.id])
                            
                            
                        })
                        
                        //console.log(js)
                        
                        dispatch({type: AuthActions.authCurrentUserCredits, data: {...jsLocal}})
                    },
                    (error) => {
                        console.log("Error listening" + JSON.stringify(error))
                    }
                )
                promiseChain.push(allUserCreditsUnsubPromise)

            }
            //All
            var linkedUsersUnsubPromise = linkedRef.onSnapshot(
                (querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    const changes = querySnapshot.docChanges()
                    const js = {
                        'added':{},
                        'modified':{},
                        'removed':{}
                                
                    }
                    changes.forEach(doc => {
                        //console.log(doc)
                        //console.log(doc)
                        js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 
    
                        
                        
                    })
                    const state = getState()
                    
                    
                    let linkedUsers = {...state.users.linkedUsers, ...js.added, ...js.modified}
                    //console.log('Porovnani clubu',seasons)
                    Object.keys(js.removed).forEach((key) => {
                        delete linkedUsers[key]
                    })
                    
                    //console.log('linked', linkedUsers)
                    dispatch({type: UsersActions.usersActionLinkedUsersChange, data: linkedUsers})



                    dispatch({type: UsersActions.usersActionClubmembersChange, data: {type: "user", data: js}} as AllUserActions)

                    
                },
                (error) => {
                    console.log("Error listening" + JSON.stringify(error))
                }
            )
            promiseChain.push(linkedUsersUnsubPromise)
        
        })
        dispatch({type: AuthActions.authLoginStarts, data: false})
        return promiseChain
    }
}


export const signInAndDeleteUser = (credentials: {email: string, password: string}, callback: CallbackFunc, clubId?: string):AppThunk<void> => {
    return (dispatch, getState, {getFirebase}) => {
        dispatch({type: AuthActions.authLoginStarts, data: true})
        const firebase = getFirebase()
        const firestore = firebase.firestore()
        const state: RootStore = getState()
        firebase.auth()
        .signInWithEmailAndPassword(
                credentials.email.trim(),
                credentials.password.trim()
        )
        .then((userCredential) => {
            // Signed in 
            
            
            return dispatch(removeUser())
        })
        .then(() => {
            console.log('User ' + credentials.email + ' was successfully deleted with all data')
            dispatch({type: "USER_DELETION", data: {isLoaded: true, success: true, error: {}, confirmed: true}})
        })
        .catch((err) => {
            //Alert.alert('login error' + JSON.stringify(err) )
            dispatch({type: AuthActions.authLoginStarts, data: false})
            dispatch({type: 'LOGIN_ERROR', err})
            callback(false, err)
        })
    }
}


export const signIn = (credentials: {email: string, password: string}, callback: CallbackFunc, clubId?: string):AppThunk<void> => {
    return (dispatch, getState, {getFirebase}) => {
        dispatch({type: AuthActions.authLoginStarts, data: true})
        const firebase = getFirebase()
        const firestore = firebase.firestore()
        const state: RootStore = getState()
        firebase.auth()
        .signInWithEmailAndPassword(
                credentials.email.trim(),
                credentials.password.trim()
        )
        .then((userCredential) => {
            // Signed in 
            
            const user = userCredential.user;
            if (!user.emailVerified) {
                //dispatch(signOut())
                dispatch({type: 'LOGIN_ERROR', err: {code:'', message:"Email not verified, check your emailbox"}})
                callback(false, {code:'', message:"Email not verified, check your emailbox"})
            }
            
            return retrieveUserOnce(firestore, user.uid)
        })
        .then((user) => {  
            const selectedClub = state.auth.selectedClub
            if (user.type == 'club') {
                if (user.firebaseId !== selectedClub) {
                    dispatch(setSelectedClub(user.firebaseId))
                }
                
            } else if (user.type == 'user') {
                if (clubId) {

                } else if (!user.clubs.includes(selectedClub)) {
                    dispatch(setSelectedClub(user.clubs[0]))
                }
            }
            dispatch({type: AuthActions.authLoginStarts, data: false})
            
        }

        ).catch((err) => {
            //Alert.alert('login error' + JSON.stringify(err) )
            dispatch({type: AuthActions.authLoginStarts, data: false})
            dispatch({type: 'LOGIN_ERROR', err})
            callback(false, err)
        })
    }
}

export const signOut = (): AppThunk<void> => {
    return (dispatch, getState, {getFirebase}) => {
        console.log('Oghlasuji')
        const firebase = getFirebase()
        firebase.auth().signOut().then(
            () => {
                dispatch({type: 'LOGOUT_SUCCESS'})
            }
        ).catch((err) => {
            dispatch({type: 'LOGOUT_ERROR', err})
        })
        
        
    }
}


export const authUser = (User: firebase.auth.UserMetadata):AppThunk<void> => {
    //console.log(User)
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        
    }
}
export const updateToken = (User: {uid: string, token: string, locale: string}, callback: (bool: boolean, res: any) => void):AppThunk<void> => {
    //console.log('Debug update token',User)
    
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        if (User) {
            console.log('Request', User)
            const response = await fetch(`${API_URL}/tokenUpdate`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(User),
            })
            console.log('Response', response)
        }
    }
}

export const setSelectedClub = (club: string):AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        try {
            const appOptions: any = getFirebase().auth().app.options
            const project = appOptions.projectId
            await AsyncStorage.setItem(project+'_selectedClub', club)
            dispatch({type: 'SELECTED_CLUB', data: {selectedClub: club}})
        }
        catch (e) {
            console.log('ERROR writing to Async storage' + JSON.stringify(e))
        }
    }
}


export const getSelectedClub = (auth: {isEmpty: boolean, uid: string}):AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        console.log('Call get selectedClub')
        const appOptions: any = getFirebase().auth().app.options
        const project = appOptions.projectId
        const rootRef = getFirebase().firestore()
        if (Platform.OS == "web") {
            const url = await Linking.parseInitialURLAsync()
            let club: string = typeof url.queryParams?.club == "string" ? url.queryParams?.club : ""
            console.log('Club param: ' + club)
            const clubsRef = rootRef.collection('clubs')
            if (club) {
                
                let clubDoc = await clubsRef.doc(club).get()
                console.log('Club exists: ' + JSON.stringify(clubDoc.exists))
                if (clubDoc.exists) {
                    dispatch({type: 'SELECTED_CLUB', data: {selectedClub: club}})
                    return
                }
            }

        }
        try {
            const value = await AsyncStorage.getItem(project+'_selectedClub')
            if(value !== null) {
                console.log('Reading from Async storage' + JSON.stringify(value))
                dispatch({type: 'SELECTED_CLUB', data: {selectedClub: value}})
                return
            } else {
                console.log('Reading from Async storage and nothing returned')
            }
        } catch(e) {
            console.log('ERROR reading from Async storage' + JSON.stringify(e))
        }
        console.log('Toto jsem poslal pres auth', auth)
        if (!auth.isEmpty) {
            const rootRef = getFirebase().firestore()
            const userPublic = await rootRef.collection("userspublic").doc(auth.uid).get()
            const club = await rootRef.collection("clubs").doc(auth.uid).get()
            if (userPublic.exists) {
                const userPublicData = userPublic.data()
                dispatch({type: 'SELECTED_CLUB', data: {selectedClub: userPublicData.clubs[0]}})
                return
            } else if (club.exists) {
                const club = userPublic.data()
                dispatch({type: 'SELECTED_CLUB', data: {selectedClub: club.firebaseId}})
                return
            }
            
        }
    } 
}
        

export const registerUser = (User: UserRegistrationForm, callback: CallbackFunc): AppThunk<void> => {
    //console.log(User)
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if (User.password !== User.confirmPassword) {
            alert("Passwords don't match.")
            return
        }
        const state = getState()
        //const club = state.auth.selectedClub
        const firebase = getFirebase()
        const firestore = getFirestore()
        
        firebase
            .auth()
            .createUserWithEmailAndPassword(User.email, User.password)
            .then((response) => {
                
                const uid = response.user.uid
                console.log('Data uid:' + uid)
                const userPublicData = {
                    uid: uid,
                    username: User.first_name + " " + User.family_name,
                    first_name : User.first_name,
                    family_name : User.family_name,
                    language : User.language,
                    email : User.email,
                    phone : User.phone,
                    dateOfBirth : User.dateOfBirth,
                    role : User.role,
                    photo : User.photo,
                    club : User.clubs[0],
                    clubs : User.clubs,
                    am_in_contacts: [],
                    termsAndConditions: User.termsAndConditions,
                    first_name_dia: removeDicretics(User.first_name),
                    family_name_dia: removeDicretics(User.family_name),
                };
                const userPrivateData = {
                    uid: uid,
                    facebookId : User.facebookId,
                    linkedInId : User.linkedInId,
                    token : User.token,
                    address : User.address,
                    city : User.city,
                    country : User.country,
                    am_in_contacts: [],
                    
                };
                
            
                const usersPublicRef = firebase.firestore().collection('userspublic')
                const usersPrivateRef = firebase.firestore().collection('usersprivate')

               
                
                var promiseChain = []

                promiseChain.push(
                    usersPublicRef
                    .doc(uid)
                    .set(userPublicData)
                    )
                promiseChain.push(
                    usersPrivateRef
                    .doc(uid)
                    .set(userPrivateData)
                    )
                
                for (let i = 0; i < User.clubs.length; i++) {
                    
                    let club = User.clubs[i]
                    //console.log('Delam role: ' + club)
                    const rolesData = {
                        uid: uid,
                        club: club,
                        isAdmin: false,
                        isCoach: false,
                    }
                    const rolesRef = firebase.firestore().collection('clubs').doc(club).collection('roles')
                    promiseChain.push(
                        rolesRef
                        .doc(uid)
                        .set(rolesData)
                        )
                }
                
                
                return Promise.all(promiseChain)
            })
            .then(
                () => {
                    console.log('Ahoj vole')
                    //dispatch(signOut())
                    //console.log(firebase.auth())
                    //signIn({
                    //    email: User.email, 
                    //    password: User.password
                    //})
                    //send verification email
                    dispatch(resendVerificationUser(() => {}));
                    //console.log(userPublicData)
                    dispatch({type: 'ADD_USER', User})
                    if (callback) callback(true, User)
                }
            )
            .catch((error: firebase.FirebaseError) => {
                alert(error.code == 'auth/email-already-in-use'? "Email used":error.message)
                dispatch({type: 'ADD_USER_ERR', User})
                var user = firebase.auth().currentUser;
                if (user) user.delete()
                if (callback) callback(false, error)
        });
        
    }
}

export const removeUser = (): AppThunk<Promise<void>> => {
    //console.log(User)
    
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: "USER_DELETION", data: {isLoaded: false, success: false, error: {}, confirmed: true}})
        const firebase = getFirebase()
        const firestore = getFirestore()
        var user = firebase.auth().currentUser;
        console.log('Attempting to delete user ' + user)
        let userUid = user.uid
  
        firestore.runTransaction(async (t) => {
            let userPublicRef = firestore.collection('userspublic').doc(user.uid)
            let userPublic = await userPublicRef.get()
            let userPublicData = standardizeDates(userPublic.data()) 
            let depersPublicData = depersonalizeUserData(userPublicData)
            t.update(userPublicRef, depersPublicData)

            let userPrivateRef = firestore.collection('usersprivate').doc(user.uid)
            let userPrivate = await userPrivateRef.get()
            let userPrivateData = standardizeDates(userPrivate.data()) 
            let depersPrivateData = depersonalizeUserData(userPrivateData)
            t.update(userPrivateRef, depersPrivateData)

            user.delete()
            .then(() => {
                console.log('Deletion success')
            })
            .catch((e) => {
                console.log('Error deleting ' + JSON.stringify(e))
                Promise.reject('Error deleting user')
            })

        })
        .then((res) => {
            console.log('User ' + userUid + ' was successfully deleted with all data')
            dispatch({type: "USER_DELETION", data: {isLoaded: true, success: true, error: {}, confirmed: true}})
        })
        .catch((e) => {
            console.log('Error deleting ' + JSON.stringify(e))
            dispatch({type: "USER_DELETION", data: {isLoaded: true, success: false, error: e, confirmed: true}})
        })
        
        
        
    }
}

export const resendVerificationUser = (callback: CallbackFunc): AppThunk<void> => {
    //console.log(User)
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        

        const firebase = getFirebase()
        const firestore = getFirestore()
        const user = firebase.auth().currentUser
        //    .createUserWithEmailAndPassword(User.email, User.password)
        //   .then((response) => {
        //console.log('Tak pojd do me', user, user.sendEmailVerification)
        user.sendEmailVerification()
        .then(() => {
            console.log('Poslano')
        })
        .catch((e) => console.log('Naposlano ' + JSON.stringify(e)));
        user.reload()
    }
}


export const authStateSubscribe = ( callback: CallbackFunc): AppThunk<void> => {
    //console.log(User)
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        

        const firebase = getFirebase()
        const authh = firebase.auth()
        /* let unsubscribe = auth.onIdTokenChanged(async (user) => {
            console.log('Neco se zmenilo na userovi', user)
            if (user) {
                
            }
        }); */
        //console.log('neco test 3', auth.currentUser.uid)
        if (!authh.currentUser) {
            return () => {}
        } else {
            //console.log('neco test 4', auth.currentUser)
            const onIdTokenChangedUnsubscribe = authh.onIdTokenChanged((user) => {
                if (user && user.emailVerified) {
                    console.log('Neco se zmenilo na userovi', user)
                    callback(user.emailVerified, {});
                    return onIdTokenChangedUnsubscribe(); //unsubscribe
                }
                
                /* setTimeout(() => {
                    console.log('Refreshing user data')
                    try {
                        if (AppState.currentState == 'active') {
                            auth.currentUser.reload();
                            auth.currentUser.getIdToken( true);
                        }
                    }
                    catch {
                        console.log('Error')
                    }
                    
                }, 5000);  */
                
            });
            return onIdTokenChangedUnsubscribe 
        }
         
    }
}


export const resetPasswordEmail = (email: string, callback: CallbackFunc): AppThunk<void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if (validateEmail(email)) {
            const firebase = getFirebase()
            firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                callback(true, 'EMAIL_SENT')
            })
            .catch((e) => {
                callback(false, e)
            })
        } else {
            callback(false, 'EMAIL_INVALID')
        }
        
        
    }
}


export const manageClubToState = ( club: string ): AppThunk<Promise<() => void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'PUT_MANAGED_CLUB_TO_STATE', data: {isLoaded: false, data: {
            club_name: "",
            seasons: {},
            courts: {},
        }}})
        
        const firebase = getFirebase()
        console.log('add Club to state params', club)
        

        if (club) {
            const marketRef = firebase.firestore().collection('clubs').doc(club)
            
            var returnedData = {}
            var unsubscribe = marketRef.onSnapshot(
                (querySnapshot) => {
                    //console.log("Ahoj kurva2", querySnapshot)
                    let changes = {}
                    try {
                        changes = standardizeDates(querySnapshot.data())
                    }
                    catch {
                        console.log('error parsing', querySnapshot)
                    }
                    console.log('Totoo je return response', {firebaseId: club, ...changes})
                    dispatch({type: 'PUT_MANAGED_CLUB_TO_STATE', data: {isLoaded: true, data: {firebaseId: club, ...changes}}})
                },
                (error) => {
                    console.log("Error listening" + JSON.stringify(error))
                }
            )
    
            //console.log(unsubscribe)
            return unsubscribe
            
        } else {
            let initState = {
                club_name:"",
                courts:{},
                seasons:{},
            }
            
            console.log(initState)
            dispatch({type: 'PUT_MANAGED_CLUB_TO_STATE', data: initState})
        }
        

        
        
    }
}



export const editClub = (club: Club, callback): AppThunk<void> => {
    //console.log('Chci zapsat novinku', newsEntry)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
       
        //console.log('state', club)
        const record = {
            ...club,
        }
    
        
        const rootRef = getFirebase().firestore()
        const clubRef = rootRef.collection('clubs')
        
        rootRef.runTransaction(async (t) => {
            //TODO: musí se tu řešit query na clash 
            
            var entityRef = null
            if (record.firebaseId) {
                entityRef = clubRef.doc(record.firebaseId)
            } else {
                entityRef = clubRef.doc()
                const refId = await entityRef.id
                record["firebaseId"] = refId
            }
            
            
            t.set(entityRef, record)
                
        })
        .then(
            () => {
                callback(true, record)
                showToast('OK')
                dispatch({type: 'CLUB_EDITED', data: record})
            }
        ).catch(
            (e) => {
                
                callback(false,e)
                showToast(e.message)
                dispatch({type: 'CLUB_EDIT_ERROR', e})
            }
        )
       
    }
}


export const manageSeasonToState = (season, callback) => {
    //console.log('Chci zapsat novinku', newsEntry)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const state: RootStore = getState()
        if (season.seasonId) {
            
            //console.log('season params',season)
           
            
            const rootRef = getFirebase().firestore()
            const clubRef = rootRef.collection('clubs').doc(season.club).collection('seasons').doc(season.seasonId)
            
            clubRef.get()
            .then(
                (data) => {
                    //console.log('data',data)
                    if (data.exists == true) {
                        let out = standardizeDates(data.data())
                    
                        callback(true, out)
                        dispatch({type: AuthActions.authManageSeason, data: {isLoaded: true, data: out}})
                    } else {
                        callback(false,{error: "NOT_FOUND", text: "Season not found"})
                        dispatch({type: AuthActions.authManageSeason, data: {isLoaded: false, error: {code: "SEASON_NOT_FOUND", message: "Season not found"}}})
                    }
                    
                }
            ).catch(
                (e) => {
                    
                    callback(false,e)
                    dispatch({type: AuthActions.authManageSeason, data: {isLoaded: false, error: e}})
                }
            )
        
        } else {
            let dt = new Date
            let club = state.auth.managedClub
            let seasons = state.auth.seasons
            let maxSeasonToHist = Object.values(seasons).reduce((prev, curr) => {
                if (curr.to >= prev) {
                    return new Date(curr.to)
                } else {
                    return prev
                }
            }, new Date)
            // make it start from next day morning
            let maxSeasonTo = dateBOD(new Date(maxSeasonToHist.getFullYear(), maxSeasonToHist.getMonth() , maxSeasonToHist.getDate() + 1))
            let out: Season = {
                type: undefined,
                from:  dateBOD(club.data.activeUntil < new Date ? new Date(club.data.activeUntil) : new Date(maxSeasonTo)),
                to: dateEOD(club.data.activeUntil < new Date ? new Date(club.data.activeUntil) : new Date(maxSeasonTo.getFullYear(), maxSeasonTo.getMonth() + 3, maxSeasonTo.getDate())),
                planFrom: dateBOD(club.data.activeUntil < new Date ? new Date(club.data.activeUntil) : new Date(maxSeasonTo.getFullYear(), maxSeasonTo.getMonth() - 1, maxSeasonTo.getDate())),
                planTo: dateBOD(club.data.activeUntil < new Date ? new Date(club.data.activeUntil) : new Date(maxSeasonTo)),
                //lockedFrom: new Date,
                lockedTo: dateEOD(club.data.activeUntil < new Date ? new Date(club.data.activeUntil) : new Date(maxSeasonTo)),
                openFrom: 600,
                openTo: 2200,
                club: club.data.firebaseId,
                courtsList: Object.entries(club.data.courts).reduce((a, [k,v]) => ({ ...a, [k]: v}), {}),
                prices: {
                    ...Object.values(club.data.courts).reduce((a, v) => ({ ...a, [v.type]: {all:[]}}), {}),
                },
                firebaseId: undefined,
                newPrices: {

                },
                tour: dt.getFullYear(),
                planningFinished: false
            }
            dispatch({type: AuthActions.authManageSeason, data: {isLoaded: true, data: out}})
            callback(true, out)
            
        } 

    }

}

export const editSeason = (season: Season, callback: CallbackFunc): AppThunk<void> => {
    //console.log('Chci zapsat novinku', newsEntry)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        //console.log('state', firestoreuid)
        
        const rootRef = getFirebase().firestore()
        const seasonRef = rootRef.collection('clubs').doc(season.club).collection('seasons')
        
        rootRef.runTransaction(async (t) => {
            //TODO: musí se tu řešit query na clash 

            if (season.from > season.to) {
                return Promise.reject({code: 'INCONSISTENT_DATES', message: "Season from is greater then to"})
            } else if (season.planFrom > season.from) {
                return Promise.reject({code: 'INCONSISTENT_DATES', message: "Season plan from is greater then from"})
            }

            const query = seasonRef//.where('from','>',season.to)
            const allSeasons = await query.get()
            for (let i = 0; i < allSeasons?.docs?.length; i++) {
                let doc = allSeasons.docs[i]
                let queriedSeason: Season = standardizeDates(doc.data())
                //console.log('Queried season ' + JSON.stringify(queriedSeason))
                //console.log(queriedSeason.from, queriedSeason.to, season.from, season.to)
                if (season.firebaseId == queriedSeason.firebaseId) {
                    console.log('Pass current')
                } else if (dateRangeOverlaps(queriedSeason.from, queriedSeason.to, season.from, season.to)) {
                    return Promise.reject({code: 'INCONSISTENT_DATES', message: "Season overlaps existing season"})
                }
            }

            var entityRef = null
            if (season.firebaseId) {
                entityRef = seasonRef.doc(season.firebaseId)
            } else {
                entityRef = seasonRef.doc()
                const refId = await entityRef.id
                season["firebaseId"] = refId
            }
            
            
            t.set(entityRef, season)
                
        })
        .then(
            () => {
                callback(true, season)
                dispatch({type: 'SEASON_EDITED', data: season})
            }
        ).catch(
            (e) => {
                
                callback(false,e)
                showToast(e.message)
                dispatch({type: 'SEASON_EDIT_ERROR', e})
            }
        )
       
    }
}

export const subscribeToSeasons = (clubId: string): AppThunk<() => void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log('VOLAM SUBSCRIBE NA SEZONY', clubId)
        const js = {
            'added':{},
            'modified':{},
            'removed':{}
                    
        }
        //dispatch({type: AuthActions.authClubsChange, data: {}})
        //const firestoreuid = getState().firebase.auth.uid
        const firebase = getFirebase()
        const firestore = getFirestore()
        
        return firestore.collection('clubs').doc(clubId).collection('seasons').onSnapshot((querySnapshot) => {
            //console.log("Ahoj kurva2", querySnapshot)
            const changes = querySnapshot.docChanges()
            
            changes.forEach(doc => {
                //console.log(doc)
                //console.log(doc)
                js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 
                //console.log(js[doc.type][doc.doc.id])
                
                
            })
            
            //console.log(js)
            const state = getState()
            const currDate = new Date(JSON.parse(state.auth.currDate))
            
            const selectedClub = state.auth.selectedClub
            let currSeasons = Object.entries(state.auth.seasons).reduce((prev, curr) => {
                let [key, season] = curr
                
                if (selectedClub == season.club) {
                    return {...prev, [key]: season}
                } else {
                    return {...prev}
                }
            }, {})
            let seasons = {...currSeasons, ...js.added, ...js.modified}
            //console.log('Porovnani clubu',seasons)
            Object.keys(js.removed).forEach((key) => {
                delete seasons[key]
            })
            //console.log(seasons)
            dispatch({type: AuthActions.authSeasonsSubscribe, data: seasons})
            const activeSeason = getActiveSeason(currDate, seasons)
            dispatch({type: AuthActions.authSelectedSeason, data: activeSeason})
            
        })
        

        
        
    }
}

export const subscribeToClubs = (): AppThunk<() => void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const js = {
            'added':{},
            'modified':{},
            'removed':{}
                    
        }
        //dispatch({type: AuthActions.authClubsChange, data: {}})
        //const firestoreuid = getState().firebase.auth.uid
        const firebase = getFirebase()
        const firestore = getFirestore()
        
        return firestore.collection('clubs').onSnapshot((querySnapshot) => {
            //console.log("Ahoj kurva2", querySnapshot)
            const changes = querySnapshot.docChanges()
            
            changes.forEach(doc => {
                //console.log(doc)
                //console.log(doc)
                js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 
                //console.log(js[doc.type][doc.doc.id])
                
                
            })
            
            //console.log(js)
            dispatch({type: AuthActions.authClubsChange, data: js})
            //dispatch({type: AuthActions.authClubsChange, data: js})
            dispatch({type: UsersActions.usersActionClubmembersChange, data: {type: 'clubs', data: js}})
        })
        

        
        
    }
}


export const registerClub = (Club: ClubBasic, callback: CallbackFunc): AppThunk<void> => {
    //console.log(User)
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if (Club.password !== Club.confirmPassword) {
            alert("Passwords don't match.")
            dispatch({type: AuthActions.authRegisterClub, data: {isSuccess: false, error: {code: "PASSWORD_DONT_MATCH", message: "Passwords don't match."}}})
            return
        }
        //const club = getState().auth.selectedClub
        const firebase = getFirebase()
        const firestore = getFirestore()
        firebase
            .auth()
            .createUserWithEmailAndPassword(Club.email, Club.password)
            .then((response) => {
                let dt = new Date
                //dt.ad
                const uid = response.user.uid
                const clubData: Club = {
                    firebaseId: uid,
                    club_name: Club.club_name,
                    club_name_dia: removeDicretics(Club.club_name),
                    email: Club.email,
                    phone: Club.phone,
                    address: "",
                    city: "",
                    termsAndConditions: Club.termsAndConditions,
                    feeInclusion: false,
                    products:{
                        [ProductTypes.subscription]: {type: MembershipTypes.tourSubscription, tour: dt.getFullYear(), prices: {}}
                    },
                    courts: {},
                    sports: ['tennis'],
                    activeUntil: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + 30) ,
                    currency: Club.currency,
                    allowedGameTypes: {},
                    paymentPromise: {
                        allowed: false,
                        roles: {}
                    },
                    homepage: "",
                    vopURL: "",
                };
                

                const userPrivateData: UserPrivate = {
                    uid: uid,
                    facebookId : "",
                    linkedInId : "",
                    token : "",
                    address : "",
                    city : "",
                    country : "",
                    locale: "cs-CZ"
                };
            
                const clubCollRef = firestore.collection('clubs')
                const upCollRef = firestore.collection('usersprivate')
                firestore.runTransaction(async (t) => {
                    t.set(clubCollRef.doc(clubData.firebaseId), clubData)
                    t.set(upCollRef.doc(clubData.firebaseId), userPrivateData)
                    return clubData
                })
                .then((club) => {
                    dispatch(resendVerificationUser(() => {}));
                    dispatch({type: AuthActions.authRegisterClub, data: {isSuccess: true, data: club}})
                    dispatch({type: AuthActions.authSelectedClub, data: {selectedClub: club.firebaseId}})
                    if (callback) callback(true, club)
                })
                .catch((error) => {
                    alert(error)
                    var user = firebase.auth().currentUser;
                    user.delete()
                    dispatch({type: AuthActions.authRegisterClub, data: {isSuccess: false, error: error}})
                    if (callback) callback(false, error)
                })
                
                    //.then(() => {
                    //    navigation.navigate('Home', {user: data})
                    //})
                    //.catch((error) => {
                    //    alert(error)
                    //});
            })
            .catch((error) => {
                alert(error)
                dispatch({type: AuthActions.authRegisterClub, data: {isSuccess: false, error: error}})
                if (callback) callback(false, error)
        });
        
    }
}


export const addClubSubscription = (clubId: string, user: string): AppThunk<void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firesbase = getFirebase()
        const firestore = firesbase.firestore()
        const userRef = firestore.collection('userspublic').doc(user)

        firestore.runTransaction(async (t) => {
            var clubIds = {clubs: firebase.firestore.FieldValue.arrayUnion(clubId)}
            t.update(userRef, clubIds)
            return clubId
        })
        .then((clubId) => {
            showToast("Uspech")
        })
        .catch((e) => {
            showToast("Chyba " + e.message)
        })      

    }
}


export const removeClubSubscription = (clubId: string, user: string): AppThunk<void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firesbase = getFirebase()
        const firestore = firesbase.firestore()
        const userRef = firestore.collection('userspublic').doc(user)

        firestore.runTransaction(async (t) => {
            var clubIds = {clubs: firebase.firestore.FieldValue.arrayRemove(clubId)}
            t.update(userRef, clubIds)
            return clubId
        })
        .then((clubId) => {
            showToast("Uspech")
        })
        .catch((e) => {
            showToast("Chyba")
        })      

    }
}



export const editSubscription = (subscriptionDetail: Membership): AppThunk<void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const state = getState()
        const currClubData: Club = state.auth.managedClub.data
        //const subscription = currClubData.products[ProductTypes.subscription]

        let out = {
            ...currClubData,
            products: {
                ...currClubData.products,
                [ProductTypes.subscription]: {
                    ...subscriptionDetail
                }
            }
        }
        dispatch({type: AuthActions.authEditClub, data: {value: out}})
    }
}
