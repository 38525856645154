import { Action, AnyAction } from "redux"
import { Club, Subscription, user } from "../../../functions/src/interfaces"
import PrevMarket from "../../screens/ManageMarketScreen/CommonMarketComponents/PrevMarket"
import { StateLoader } from "../../utilities/interfaces"
import { Score } from "./reservationReducer"

export enum UsersActions {
    usersActionUserSubsChange = 'MY_SUBSCRIBTION_CHANGE',
    usersActionUserDataFetch = 'USER_DATA_FETCH',
    usersActionQueryUserResults = 'QUERY_USER_RESULTS',
    usersActionClubmembersChange = 'CLUBMEMBERS_CHANGES',
    usersActionLinkedUserToState = 'LINKED_USER_TO_STATE',
    usersActionLinkedUserEdit = 'LINKED_USER_EDIT',
    usersActionLinkedUsersChange = 'LINKED_USERS_CHANGE',
    usersActionUsersUpdate = 'USERS_DATA_UPDATE',
}

export type UserOrClub<T = {}> = ({type: "user"} & user & T) | ({type: "clubs", uid: string} & Club)

export type UsersState = {
    usersData: {[uid: string]: UserOrClub},
    userResults: StateLoader<{results: Score[]}>,
    clubmembers: {[uid: string]: user},
    linkedUsers: {[uid: string]: user},
    userSubscribtions: { 
        uid: string,
        data: {[subId: string]: Subscription}
    },
    registrationUser: user,
    manageLinkedUser: StateLoader<user>
}

const initState:UsersState = {
    userResults: {
        isLoaded: false,
        data: {
            results: []
        }
    },
    usersData: {},
    clubmembers: {},
    userSubscribtions: {
        uid: undefined,
        data: {}
    },
    registrationUser: {
        uid: "",
        first_name: "",
        family_name: "",
        address: "",
        email: "",
        phone: "",
        clubs: [],
        city: "",
    },
    linkedUsers: {

    },
    manageLinkedUser: {
        isLoaded: false,
        data: undefined
    }
}

type UserFetchAction = Action<UsersActions.usersActionUserDataFetch> & {data: UserOrClub}
type QueryUsersAction = Action<UsersActions.usersActionQueryUserResults> & {data: StateLoader<{results: Score[]}>}
type ClubmembersChangeAction = Action<UsersActions.usersActionClubmembersChange> & {data: {type: "user" | "clubs", data: {modified: {}, added: {}, removed: {}}}}
type SubscriptionsChangeAction = Action<UsersActions.usersActionUserSubsChange> & {data: {uid: string, data: {modified: {}, added: {}, removed: {}}}}
type LinkedUserToStateAction = Action<UsersActions.usersActionLinkedUserToState> & {data: StateLoader<user>}
type LinkedUserEditAction = Action<UsersActions.usersActionLinkedUserEdit> & {data: StateLoader<user>}
type LinkedUserChangeAction = Action<UsersActions.usersActionLinkedUsersChange> & {data: {[uid: string]: user}}
type UsersChangeAction = Action<UsersActions.usersActionUsersUpdate> & {data: {type: "user" | "clubs", data: {modified: {}, added: {}, removed: {}}}}
export type AllUserActions = UserFetchAction | QueryUsersAction | ClubmembersChangeAction | SubscriptionsChangeAction | LinkedUserToStateAction | LinkedUserEditAction | LinkedUserChangeAction | UsersChangeAction

const usersReducer = (state = initState, action: AllUserActions) => {
    //console.log(action)
    switch(action.type){
        case UsersActions.usersActionUserDataFetch:
            return {
                ...state,
                usersData: {...state.usersData, [action.data.uid]: {...action.data}}
            }
        
        case UsersActions.usersActionQueryUserResults:
            return {
                ...state,
                userResults: {...action.data}
            }
        case UsersActions.usersActionClubmembersChange:
            let toAdd = {
                ...action.data.data.added, 
                ...action.data.data.modified
            }
            let typedToAdd = Object.keys(toAdd).reduce((prev, newVal) => {
                return {...prev, [newVal]: {...toAdd[newVal], type: action.data.type}}
            }, {})
            let out = {
                ...state,
                usersData: {...state.usersData, ...typedToAdd}
            }
            if (action.data.type == 'user') {
                out.clubmembers = {...out.clubmembers, ...action.data.data.added, ...action.data.data.modified}
                Object.keys(action.data.data.removed).forEach((key) => {
                    delete out.clubmembers[key]
                })
            }
            //console.log('users state', out)
            return out
        case UsersActions.usersActionUsersUpdate:
            
            return {
                ...state,
                usersData: {
                    ...action.data
                }
            }
        case UsersActions.usersActionUserSubsChange: 
            action.data
            let outsubs = {
                ...state,
                userSubscribtions: {
                    uid: action.data.uid,
                    data: {...action.data.uid == state.userSubscribtions.uid ? state.userSubscribtions.data : {}, ...action.data.data.added, ...action.data.data.modified}
                }
            }
            Object.keys(action.data?.data?.removed || {}).forEach((key) => {
                delete outsubs.userSubscribtions.data[key]
            })
            return outsubs
        case UsersActions.usersActionLinkedUserToState:
            return {
                ...state,
                manageLinkedUser: {
                    //...state.manageLinkedUser,
                    ...action.data,
                }
            }
        case UsersActions.usersActionLinkedUserEdit:
            return {
                ...state,
                manageLinkedUser: {
                    ...state.manageLinkedUser,
                    ...action.data
                }
            }
        case UsersActions.usersActionLinkedUsersChange:
            return {
                ...state,
                linkedUsers: {
                    ...action.data
                }
            }
        default:
            return state


    }
}

export default usersReducer