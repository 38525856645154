import React from 'react';
import { StyleSheet, View } from 'react-native';

import i18n, { tokens } from '../i18n';
import { normalize } from '../utilities/constants';
import ClicableUserAvatar from './ClickableUserAvatar';
import IconButton from './IconButton';

type Props = {
  playerPressAction: (props: any) => void,
  disabled: boolean,
  uid: string,
  path: string[],
  approveAction: () => void,
  removeAction: () => void,
}

const PlayerListItem: React.FC<Props> = (props) => {
    //console.log('Uid na playerovi', props.uid)
    //const item = props.item
    
    //const contactFlag = 
    //console.log('nalezeno', props)
    
    const styles = StyleSheet.create({
        
        entityContainer: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 8,
            borderBottomColor: '#cccccc',
            borderBottomWidth: 1,
            paddingBottom: 8
        },
        entityText: {
            fontSize: 16,
            color: '#333333'
        }
    })

    
    return (
      <View key={props.uid} 
              style={{flexDirection: "column", alignSelf: "flex-start"}}
              >
      
      <ClicableUserAvatar
      size={normalize(40)}
      onPress={() => props.playerPressAction(props)}
      uid={props.uid}
      caption={props.path[0] == 'appliedPlayers'?"Add self":i18n.t(tokens.app.components.gameSettings.gameSettingEmpty)}
      />
      
     {/* {JSON.stringify(props.selection) == JSON.stringify(props.path)? */}
     <View
      style={{
        flexDirection: "row",
        alignContent: "space-around",
        //flex: 1,
        justifyContent: "space-around"
      }}>
     {props.removeAction && props.uid?(<IconButton 
     key={"decline"}
     onPress={() => props.removeAction()}
     color={"red"}
     size={normalize(16)}
     icon={"close-outline"}
     />):null}
          {props.approveAction && props.uid ?(
            <IconButton 
            key={"approve"}
            onPress={() => props.approveAction()}
            color={"green"}
            size={normalize(16)}
            icon={"checkmark-outline"}
            />
              ):null}
            </View>{/* :null} */}
     </View>
   
    )
}



export default PlayerListItem

