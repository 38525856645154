import { Route } from "@react-navigation/native";
import React, { useEffect } from "react";
import { FlatList, Platform, Text, Pressable, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import WebWrapper from "../../components/WebWrapper";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks";
import { globalStyleSheet, secondColor } from "../../utilities/globalStyles";
import Ionicons from '@expo/vector-icons/Ionicons';
import { RootStore } from "../../store/reducers/rootReducer";
import FormEntry from "../../components/FormEntryNew";
import FormEntryNoCaption from "../../components/FormEntryNoCaption";
import FlexedMessage from "../../components/FlexedMessge";
import FormHeading from "../../components/FormHeading";
import i18n, { tokens } from "../../i18n";
import FloatingAction from "../../components/FloatingAction";

type Props = {
    route: Route<'LinkedUsersScreen', {uid: string}>
}


function  LinkedUsersScreen(props:Props): JSX.Element {
    const {uid} = props.route.params
    const navigation = useAppNavigation()
    

    const linkedUsers = useAppSelector((state: RootStore) => {
        let data = state.users.linkedUsers
        return Object.entries(data).map(([uid, detail]) => {
            return {...detail, key: uid}
        }).sort((a, b) => a.uid >= b.uid ? 1 : -1)
        
    }, (a, b) => JSON.stringify(a) == JSON.stringify(b))

    const actions = [{
        text: "Accessibility",
        name: "bt_accessibility",
        icon: (<Ionicons 
            name="add"
            color="white"
            size={35}
            
            />),
        
      }]

    
    return (
        <View style={globalStyleSheet.container}>
            <WebWrapper>
                <View style={{alignItems: "stretch"}}>
                    <FormHeading heading={i18n.t(tokens.app.screens.linkeduser.linkedUserTitle)}/>
                    <FormEntryNoCaption editable={false}>
                        <Text style={globalStyleSheet.messageText}>{i18n.t(tokens.app.screens.linkeduser.linkedUserMessage)}</Text>
                    </FormEntryNoCaption>
                </View>
                {linkedUsers.length > 0?
                <FlatList
                data={linkedUsers}
                renderItem={({item}) => {
                
                return (
                <Pressable
                onPress={() => navigation.navigate('ManageLinkedUser', {uid: uid, linkedUid: item.uid})}
                >
                <FormEntryNoCaption editable={true}>
                    <Text
                    style={globalStyleSheet.entityText}>
                        {item.first_name + ' ' + item.family_name}
                    </Text>
                </FormEntryNoCaption>
                </Pressable>)
                }}
                />:
                <FlexedMessage message={"Nemáte žádný spravovaný účet"}/>
                }
            
                <FloatingAction
                //visible={true}
                actions={actions}
                color={secondColor}
                overrideWithAction={true}
                onPressItem={() => navigation.navigate('ManageLinkedUser', {uid: uid})}
                buttonSize={70}
                />

           
        </WebWrapper>
        </View>
    )
}

export default LinkedUsersScreen