import React from "react";
import FormHeading from "./FormHeading";
import i18n, { tokens } from "../i18n";
import RenderContactClub from "./ContactClub";
import SmallButton from "./SmallButton";
import { Club } from "../../functions/src/interfaces";
import { useAppSelector } from "../store/hooks";
import { RootStore } from "../store/reducers/rootReducer";
import FormEntry from "./FormEntryNew";
import FormEntryNoCaption from "./FormEntryNoCaption";
import { Text } from "react-native";
import { globalStyleSheet } from "../utilities/globalStyles";

type Props = {
    availableClubs: string[]
    clubButtonAction?: (club: string) => void,
    heading: string,
    clubButtonCaption?: string,
    clubLongPressAction?: (club: string) => void,
    clubPressAction?: (club: string) => void,
    selectedClub?: string,
    headingEdditButtonCaption?: string,
    headingEdditButtonAction?: () => void
}

function ClubList(props: Props) {
    const allClubs: {[clubId: string]: Club} = useAppSelector((state: RootStore) => state.auth.allClubs, (a,b) => JSON.stringify(a) == JSON.stringify(b))
    return (
        <>
        <FormHeading
        heading={props.heading}
        editButton={props.headingEdditButtonAction}
        editButtonCaption={props.headingEdditButtonCaption}
        />
        {props.availableClubs.length > 0? props.availableClubs.map((item) => (
        <RenderContactClub 
        key={item} 
        item={allClubs?.[item]} 
        longPressAction={props.clubLongPressAction?() => props.clubLongPressAction(item):null}
        action={props.clubPressAction?() => props.clubPressAction(item):null}
        highlighted={item == props.selectedClub}
        >
            {props.clubButtonCaption?<SmallButton
            onPress={() => props.clubButtonAction(item)}
            buttonText={props.clubButtonCaption}
            />:null}
        
        </RenderContactClub>))
        :
        <FormEntryNoCaption
        editable={false}
        >
            <Text style={[globalStyleSheet.inputOk, {color: "red"}]}>
                {i18n.t(tokens.app.screens.registration.registerUserClubsValidity)}
            </Text>
        </FormEntryNoCaption>
        }
        </>
    )
}

export default ClubList