import React, { useState } from 'react'
import { FlatList, StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native'
import { shallowEqual } from 'react-redux'


import i18n, { tokens } from '../i18n'
import { useAppSelector } from '../store/hooks'
import { RootStore } from '../store/reducers/rootReducer'
import { UserOrClub } from '../store/reducers/usersReducer'
import { normalize } from '../utilities/constants'
import { monthString } from '../utilities/funcs'
import { Message } from '../utilities/interfaces'
import FormEntryNoCaption from './FormEntryNoCaption'
import FormHeading from './FormHeading'
import UserAvatar from './UserAvatar'

type Props = {
    data: Message[]
}


const CreatorName: React.FC<{user: UserOrClub, style: StyleProp<TextStyle>}> = (props) => {
    if (props.user.type == "user") {
        return (
            <Text maxFontSizeMultiplier={1.2} style={props.style}>{props.user.first_name + ' ' + props.user.family_name}</Text>
        )
    } else if (props.user.type == "clubs") {
        return (
            <Text maxFontSizeMultiplier={1.2} style={props.style}>{props.user.club_name}</Text>
        )
    }
}


const MessagesList = (props: Props) => {
    const [viewAll, setViewAll] = useState(false)
    const usersData = useAppSelector((state: RootStore) => state.users.usersData, shallowEqual)
    //const contactFlag = 
    //console.log('nalezeno', props)
    
    const style = StyleSheet.create({
        entityContainer: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 8,
            borderBottomColor: '#cccccc',
            borderBottomWidth: 1,
            paddingBottom: 8
        },
        
    })
   
    return (
        props.data?.length > 0?
        <View>
            <FormHeading 
            heading={i18n.t(tokens.app.components.messages.messagesHeading)}
            editButton={() => setViewAll(prev => !prev)}
            editButtonCaption={
                viewAll?i18n.t(
                    tokens.app.components.messages.collapseCaption
                    ):i18n.t(
                        tokens.app.components.messages.viewAllCaption, {number: props.data?props.data.length.toString():"0"}
                        )} 
            />
            <FormEntryNoCaption
                editable={false}>
            <FlatList
            scrollEnabled={false} 
            contentContainerStyle={{paddingLeft: 10, paddingRight: 10}}
            data={props.data?viewAll?props.data:props.data.slice(props.data.length-1):[]}
            renderItem={({item, index}) => { 
                //console.log(item, props.users)

                return (
                <View
                style={{
                    flexDirection: "row",
                    flex: 1,
                    minHeight: 30,
                    marginTop: 2
                }}
                >
                    <UserAvatar
                    uid={item.uid}
                    size={normalize(30)}
                    />
                
                 <View
        
                style={{
                    flexDirection: "column",
                    flex: 1,
                    minHeight: 30
                }}> 
                <View
        
                style={{
                    flexDirection: "row",
                    flex: 1,
                    //minHeight: 30
                }}>
                    <CreatorName style={{alignSelf:"flex-start", fontWeight: "bold"}} user={usersData[item.uid]}/>
                    <Text maxFontSizeMultiplier={1.2} style={{alignSelf:"flex-end", fontWeight: "bold", marginLeft: "auto"}}>{item.updateTime?monthString(item.updateTime):"XNA"}</Text>
                </View> 
                <View
        
                style={{
                    flexDirection: "row",
                    flex: 1,
                    //minHeight: 30
                }}>
                    
                    <Text maxFontSizeMultiplier={1.2}>{item.message}</Text>
                </View> 

                </View>  
                
                </View>)
            }}
            keyExtractor={(item) => item.messageId}
            
            />
            </FormEntryNoCaption>
        </View>
        : null
    )
}




export default MessagesList

