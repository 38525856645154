import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import styles from './styles';
//import DelayInput from "react-native-debounce-input";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual, useSelector } from 'react-redux';
import ActionOverlay from '../../components/ActionOverlay';
import BigButton from '../../components/BigButton';
import WebWrapper from '../../components/WebWrapper';
import i18n, { tokens } from '../../i18n';
import { removeUser } from '../../store/actions/actionsAuth';
import { editProfile, editRoles, loadProfileData } from '../../store/actions/actionsUsers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ContactsActions } from '../../store/reducers/contactsReducer';
import { RootStore } from '../../store/reducers/rootReducer';
import { getCurrentUserWithRoles, getMySubscriptions } from '../../store/selectors';
import { SubscribtionListItem } from '../../utilities/interfaces';
import RenderModal from './Components/DeleteProfileOverlay';
import MembershipData from './Components/MembershipData';
import ProfileData from './Components/ProfileData';
import RolesData from './Components/RolesData';
import { ClubRoles, UserRoles } from '../../../functions/src/interfaces';
import { Route } from '@react-navigation/native';
import { UserOrClub } from '../../store/reducers/usersReducer';

type Props = {
    route: Route<'ManageNewsScreen', {uid: string}>
}

function ManageProfileScreen(props: Props) {
    //console.log('manage profile state',props)
    const uid = props.route.params.uid
    const [isModalVisible, setIsModalVisible] = useState(false)
    const profileData = useAppSelector((state: RootStore) => state.contacts.editProfileData, shallowEqual)
    const dispatch = useAppDispatch()

    const rolesData = useAppSelector((state: RootStore) => state.contacts.editRolesData, shallowEqual)

    const clubSubscriptions: SubscribtionListItem[] = useSelector((state: RootStore) => getMySubscriptions(state, uid), shallowEqual)
    const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    
    const loadProfileDataAction = (uid, club) => dispatch(loadProfileData(uid, club))
    const editProfileAction = (profile, callback) => dispatch(editProfile(profile, callback))
    const editRolesAction = (roles: ClubRoles, callback) => dispatch(editRoles(roles, callback))
    

    const dispatchChangesProfile = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        dispatch({type: ContactsActions.contactsEditProfile, data: changes})
    }

    const dispatchChangesRoles = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        dispatch({type: ContactsActions.contactsEditRole, data: changes})
    }


    const toggleModal = () => {
        setIsModalVisible((prevValue) => {
            return !prevValue
        })
    }
    
    
   const isAdmin = () => {
    if (currentUser.type == "user") {
        return currentUser.isAdmin == true || currentUser.isGod == true
    } else if (currentUser.type == "clubs") {
        return currentUser.firebaseId == selectedClub
    }
   }

    
    
    useEffect(() => {
        if (selectedClub != null) {
            //console.log('co to kurva je', props)
            loadProfileDataAction(uid, selectedClub)
        }
        
    }, [uid, selectedClub])

    return (
        
            <View style={styles.container}>
            <KeyboardAwareScrollView  
            keyboardDismissMode='on-drag'
            keyboardShouldPersistTaps="handled"
            enableResetScrollToCoords={false}
            //contentContainerStyle={{ flexGrow: 1, padding: 10 }}
            extraHeight={Platform.select({ android: 200, ios: 200 })}
            //contentContainerStyle={{flex: 1, alignItems: "center"}}
            //style={{flex: 1}}
            
            ><WebWrapper>
            <ActionOverlay
            onBackdropPress={()=> {}}
            fullScreen={true}
            isVisible={profileData.isActionPressed}
            />
            <ProfileData
            profileData={profileData}
            uid={uid}
            dispatchActionForChanges={dispatchChangesProfile}
            editProfile={editProfileAction}
            />
            <RolesData
            rolesData={rolesData}
            uid={uid}
            dispatchActionForChanges={dispatchChangesRoles}
            editRoles={editRolesAction}
            selectedClub={selectedClub}
            isAdmin={isAdmin()}
            />
            <MembershipData
            membershipData={clubSubscriptions}
            uid={uid}
            />
            <RenderModal 
            isModalVisible={isModalVisible}
            toggleModal={toggleModal}
            removeUser={() => dispatch(removeUser())}
            />    
            {uid == currentUser.uid?
            <View
            style={{flexDirection: "row", marginTop: 10}}
            >
            <BigButton
            buttonText={i18n.t(tokens.app.screens.profile.profileDelete)}
            onPress={() => {
                    dispatch({type: "CONFIRM_USER_DELETION", data: profileData})
                    toggleModal()
            }}
            />
            </View>
            :null}
            
                </WebWrapper>
            </KeyboardAwareScrollView>
            </View>
        
    )
}


export default ManageProfileScreen



