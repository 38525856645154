import React from 'react';
import { Modal, Pressable, StyleSheet, Text, View } from 'react-native';


type Props = {
  onBackdropPress: () => void,
  isVisible: boolean,
  fullScreen?: boolean,
  children: JSX.Element | JSX.Element[],
  header?: boolean
}

function Overlay(props: Props):JSX.Element {
    
    //console.log('Overlay props', props)
    
    //isVisible={isModalVisible} onBackdropPress={toggleModal}
    /* return (<Modal  transparent={true} visible={props.isVisible} onRequestClose={props.onBackdropPress} animationType={"fade"}>
        
        <TouchableWithoutFeedback onPress={props.onBackdropPress}>
         <BlurView
           style={{ ...StyleSheet.absoluteFill }}
           tint='dark'
           intensity={100}
         />
       </TouchableWithoutFeedback>
       <View style={styles.container}>
         {props.children}
       </View>
        
    </Modal>
    ) */
    return (<Modal
        visible={props.isVisible}
        onRequestClose={() => props.onBackdropPress()}
        transparent={true}>
        <Pressable style={styles.outsideModal}
          onPress={(event) => { if (event.target == event.currentTarget) { 
            props.onBackdropPress() } }} >
          <View style={props.fullScreen?styles.modalFS:styles.modal}>
            {props.header?<View style={styles.modalHeader}>
              <View style={styles.modalHeaderContent}>
                 <Text maxFontSizeMultiplier={1.2}>Other header content</Text></View>
                
              <Pressable style={{marginLeft: "auto"}} onPress={() => props.onBackdropPress()}>
                <Text maxFontSizeMultiplier={1.2} style={styles.modalHeaderCloseText}>X</Text>
              </Pressable>
              </View>:null}
            
            <View style={props.fullScreen?styles.modalContentFS:styles.modalContent}>
              {props.children}
            </View>
          </View>
        </Pressable>
      </Modal>)
}

export default Overlay;



const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
      },
      modal: {
        marginLeft: 50,
        marginRight: 50,
        minHeight: "40%",
        padding: 5,
        backgroundColor: "white",
        borderRadius: 10,
        shadowColor: "black",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
      },
      modalFS: {
        flex: 1,
        backgroundColor: "white",
      },
      /* The content of the modal takes all the vertical space not used by the header. */
      modalContent: {
        width: "100%",
        borderWidth: 0,
        borderColor: "black"
      },
      modalContentFS: {
        flex: 1
      },
      modalHeader: {
        flexDirection: "row",
        borderWidth: 0,
        borderColor: "black"
      },
      /* The header takes up all the vertical space not used by the close button. */
      modalHeaderContent: {
        flexGrow: 1,
      },
      modalHeaderCloseText: {
        textAlign: "center",
        paddingLeft: 5,
        paddingRight: 5
      },
      outsideModal: {
        backgroundColor: "rgba(1, 1, 1, 0.2)",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }
});