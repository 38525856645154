import React, { Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Animated, View, StyleSheet, PanResponder, Text, Platform } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import i18n, { tokens } from "../i18n";
import { windowHeight, windowWidth } from "../utilities/constants";

export type DropProps = {
  id: string;
  size: number;
  style?: {[style: string]: any};
  placeholder?: string; 
};


export type DropHandle = {
  measure: () => Promise<{}>,
  show: (callback: any) => void,
  hide: (callback: any) => void,
  increase: (callback: any) => void,
  decrease: (callback: any) => void,
}

const DropFunc: React.ForwardRefRenderFunction<DropHandle, DropProps> = (props, ref) => {
  const fadeAnim = useRef(new Animated.Value(0)).current 
  const viewRef = useRef<null | View>(null)
  const [big, setBig] = useState(false)
  const sizeAnim = useRef<Animated.Value>(new Animated.Value(1)).current 

  const show = (callback) => {
    let config: Animated.TimingAnimationConfig = {
      toValue: 1,
      duration: 500,
      useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
    }
    Animated.timing(
      fadeAnim,
      config
    ).start((obj: Animated.EndResult) => {
      
      callback()
    }
      
      )
  }
  const hide = (callback) => {
    let config: Animated.TimingAnimationConfig = {
      toValue: 0,
      duration: 500,
      useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
    }
    Animated.timing(
      fadeAnim,
      config
    ).start((obj: Animated.EndResult) => {
      
      callback()
    }
      
      )
  }
  const increase = (callback) => {
    if (big == false) {
      console.log('Chci spustit animaci')
      setBig(true)
      let config: Animated.TimingAnimationConfig = {
        toValue: 1.3,
        duration: 500,
        useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
      }
      Animated.timing(
        sizeAnim,
        config
      ).start((obj: Animated.EndResult) => {
        
          callback()
        }
        
      )
    }
  }
  const decrease = (callback) => {
    if (big == true) {
      setBig(false)
    let config: Animated.TimingAnimationConfig = {
      toValue: 1,
      duration: 500,
      useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
    }
    Animated.timing(
      sizeAnim,
      config
    ).start((obj: Animated.EndResult) => {
      
      callback()
    }
      
      )
    }
  }
  const measure = () => {
    return new Promise((resolve, reject) => {
      try {
        if (viewRef && viewRef.current) {
          viewRef?.current?.measure((fx, fy, width, height, px, py) => {

            resolve({id: props.id, fx, fy, width, height, px, py, panx: null, pany: null})
          })
        }
      }
      catch (e) {
        reject(e)
      }
    })}
  useImperativeHandle(ref, () => ({
    measure,
    show,
    hide,
    increase,
    decrease,
  }))

  const styles = StyleSheet.create({
    animatedContainer: {
      height: props.size,
      width: props.size,
      borderRadius: props.size/2,
    },
    box: {
      height: props.size,
      width: props.size,
      borderRadius: props.size/2
    }
  });

  /* const pan = useRef<Animated.ValueXY>(new Animated.ValueXY()).current
  const [textFlag, setTextFlag] = useState(true)
  useEffect(() => {
    fadeAnim.addListener(({value}) => {
      if(value == 0) {
        setTextFlag(true)
      } else {
        setTextFlag(false)
      }
    })
    return () => fadeAnim.removeAllListeners()
  }, []) */
  
  
  return (/* textFlag?
    <Text>{props.placeholder}</Text>
    : */<Animated.View
    ref={viewRef}
    style={{
      ...props.style,
      zIndex: 8888,
      opacity: fadeAnim,
      transform: [/* { translateX: pan.x }, { translateY: pan.y }, */ { scaleX: sizeAnim }, {scaleY: sizeAnim}]
    }}>
        <View
        style={{
          borderColor: "black",
          borderWidth: 1,
          borderRadius: props.size/2,
          width: props.size,
          height: props.size,
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Ionicons 
                name={"trash-outline"}
                color="black"
                size={Math.round(props.size*2/3) }
                
                />

        </View>
      </Animated.View>)
  
}

export default React.forwardRef(DropFunc)