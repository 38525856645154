import React, { useEffect, useState } from 'react'
import { FlatList, Platform, Text, TextInput, View } from 'react-native'
import { Avatar, ListItem } from 'react-native-elements'
import { shallowEqual, useDispatch } from 'react-redux'
import { DaysPrices, NewPrice } from '../../../../functions/src/interfaces'
import BigButton from '../../../components/BigButton'
import FilterTag from '../../../components/FilterTag'
import FormEntryNoCaption from '../../../components/FormEntryNoCaption'
import IconButton from '../../../components/IconButton'
import TimeInput from '../../../components/TimeInput/TimeInput'
import i18n, { tokens } from '../../../i18n'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { AuthActions } from '../../../store/reducers/authReducer'
import { RootStore } from '../../../store/reducers/rootReducer'
import { normalize, roleTypes, weekdays, weekdaysStartingMonday } from '../../../utilities/constants'
import { globalStyleSheet, mainFontBold } from '../../../utilities/globalStyles'

export const imageByType = (tp) => {
    if (tp == 'clay') {
        return require('../../../../assets/images/clay.jpeg')
    } else if (tp == 'hard_court') {
        return require('../../../../assets/images/hala.jpeg')
    }
}

const RolePrices: React.FC<{
    item: string, 
    rolePrice:number | undefined,
    id:string,
    priceIdx: number,
    courtType: string
}> = (props) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (props.rolePrice == undefined) {
            dispatch({type: AuthActions.authEditPriceForRole, data: {
                priceListId: props.id, 
                roleType: props.item, 
                priceIdx: props.priceIdx, 
                courtType: props.courtType, 
                price: 1000
            }})
        }
    }, [props.rolePrice])
    return (
        <View
        style={
            {
                alignItems: "center",
                margin: 5,
                flexDirection: Platform.OS == "web" ? "column" : "row",
                flex: 1,
                justifyContent: "space-between"
            }
        }>
            <Text style={{fontFamily: mainFontBold}}>{roleTypes[props.item]}</Text>
            <TextInput
            style={{backgroundColor: "white", width: 80, textAlign: "center"}}
            value={String(props.rolePrice)}
            onChangeText={(text: string) => {
                
                dispatch({type: AuthActions.authEditPriceForRole, data: {
                    priceListId: props.id, 
                    roleType: props.item, 
                    priceIdx: props.priceIdx, 
                    courtType: props.courtType, 
                    price: text == "" ? 0 : parseInt(text)
                }})
            }}
            />
        </View>
    )
}


const CourtPrices: React.FC<{
    type: string, 
    courtRoles: {[roleId: string]:number} | undefined,
    id: string,
    priceIdx: number
}> = (props) => {


    return (<View
        style={{flexDirection: "row", flex: 1, margin: 4, alignItems: "center"}}
        key={props.type}
        >
            <View style={{flexDirection: "row"}}>
                {/* <Text
                style={{
                    height: 20,
                    transform: [{ rotate: '-90deg'}]
                }}
                >{i18n.t(props.type)}</Text> */}
            <Avatar rounded size={normalize(50)}
            avatarStyle={
                {
                    borderColor: "black", 
                    borderWidth: 1, 
                    borderRadius: normalize(50), 
                }
            }
            source={imageByType(props.type)}
            activeOpacity={0.7}
            />
        </View>
        <View style={{flexDirection: Platform.OS != "web" ? "column" : "row", flex: 1}}>
        {Object.keys(roleTypes).map((roleType) => {
            return <RolePrices 
            key={roleType} 
            item={roleType} 
            rolePrice={props.courtRoles ? props.courtRoles[roleType] : undefined}
            id={props.id}
            priceIdx={props.priceIdx}
            courtType={props.type}/>
        })}
        </View>
        </View>)
}


const TimeRanges: React.FC<{item: NewPrice, id: string, idx: number}> = (props) => {
    //console.log('Time ranges', props)
    const dispatch = useAppDispatch()
    const fieldTypeList = useAppSelector((state: RootStore) => Object.values(state.auth.managedSeason.data.courtsList).reduce((prev, curr) => {
        return Array.from(new Set([...prev, curr.type]))
    }, []))

    return (<View
    style={{flexDirection: "row", flex: 1}}
    ><View
        style={{flexDirection: "column", alignItems: "center", justifyContent: "space-between", flex: 1}}>
            <View style={{flexDirection: "row"}}>
                <Text>{i18n.t(tokens.app.screens.manageSeason.manageSeasonFrom)}</Text>
            <TimeInput
            value={props.item.from}
            onValueChange={(value) => dispatch(
                    {
                        type: AuthActions.authEditPriceTimeRange, 
                        data: {
                            id: props.id,
                            index: props.idx,
                            from: parseInt(value)
                        }
                    }
                    )
               
            }
            disabled={true}
            />
            </View>
            <View style={{flexDirection: "row"}}>
                <Text>{i18n.t(tokens.app.screens.manageSeason.manageSeasonTo)}</Text>
            <TimeInput
            value={props.item.to}
            onValueChange={(value) => dispatch(
                {
                    type: AuthActions.authEditPriceTimeRange, 
                    data: {
                        id: props.id,
                        index: props.idx,
                        to: parseInt(value)
                    }
                }
                )
           
        }
            disabled={false}
            />
            </View>
        </View>
        <View style={{flex: 4}}>
        {fieldTypeList.map((type) => <CourtPrices key={type} type={type} courtRoles={props.item.prices[type]} id={props.id} priceIdx={props.idx}/>)}
        </View>
        </View>)
}


const DaysRanges: React.FC<{days: Array<{day: number, used: boolean}>, item: {days: number[], id: string, priceRanges:Array<NewPrice>}}> = (props) => {
    //console.log('Price ranges prosp', props)
    
    const dispatch = useAppDispatch()
    const [detailFlag, setDetailFlag] = useState(false)
    const priceRanges = useAppSelector((state: RootStore) => {
        let newPrices = state.auth.managedSeason.data.newPrices[props.item.id].priceRanges
        //console.log('Prices state:', newPrices)
        return [...newPrices]
    })

    return (
        <View>
        <FormEntryNoCaption direction='row' editable={true}>
            <>
            <IconButton
            icon='ellipsis-vertical-sharp'
            size={normalize(24)}
            color="black"
            onPress={() => setDetailFlag(prev => !prev)}
            />
        <FlatList
        horizontal={true}
        data={props.days.map((day) => {return {day: day.day, value: props.item.days.includes(day.day), pricelistId: props.item.id, disabled: !props.item.days.includes(day.day) && day.used}})}
        renderItem={({item}) => (<FilterTag 
        tag={weekdays[item.day]}
        value={item.value}
        disabled={item.disabled }
        onPress={() => dispatch({type: AuthActions.authChangePriceDayRange, data: {id: item.pricelistId, day: item.day} })}
        />)}
        keyExtractor={(item) => String(item.day)}
        />
        </>
    </FormEntryNoCaption>
    {detailFlag?
        priceRanges.map((item,index) => {
        return (<TimeRanges 
        item={item}
        id={props.item.id}
        idx={index}
        key={item.key}
        />)})
        
    :null}
    </View>
    )
}


type Props = {
    //prices: DaysPrices
}



const CourtTimeRangePrice: React.FC<Props> = (props) => {
    //console.log('asdasd',props)
    const dispatch = useDispatch()
    //const contactFlag = 
    //console.log('nalezeno', props)
    const prices: DaysPrices = useAppSelector((state: RootStore) => state.auth.managedSeason.data.newPrices, shallowEqual)
    //const [prices, setPrices] = useState<DaysPrices>([])
    const [days, setDays] = useState(Object.keys(weekdays).map((day) => {
        return {day: parseInt(day), used: false}
    }))

    useEffect(() => {
        //console.log('Effect')
        let newPrices = prices || {}
        let usedDays = Object.values(newPrices).reduce((prev, curr) => {
            return [...prev, ...curr.days]
        }, [])
        setDays(prev => prev.map((item) => {
            return {...item, used: usedDays.includes(item.day)}
        }))
        //console.log(days)
    }, [prices])
  
  
    return (
        
        <View style={globalStyleSheet.container}>
            {
                Object
                .values(prices || {})
                .map((item) => (<DaysRanges 
                    item={item}
                    days={days}
                    key={String(item.id)}
                    />))
            }
            <BigButton 
            onPress={() => dispatch({type: AuthActions.authAddPriceDayRange, data: {} })}
            buttonText={i18n.t(tokens.app.screens.manageSeason.manageSeasonButtonsPricesAdd)}
            />
           
        </View>
    )
}



export default CourtTimeRangePrice

