import React, { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, Text, TextInput, View } from 'react-native'
import QRCode from 'react-native-qrcode-svg';
import { shallowEqual } from 'react-redux';
import { Club, PaymentData, PaymentModes, PaymentTransactionStatuses } from '../../../../functions/src/interfaces';
import BigButton from '../../../components/BigButton';
import FormEntry from '../../../components/FormEntryNew';
import i18n, { tokens } from '../../../i18n';
import { handlePayCreditAction } from '../../../store/actions/actionsCheckout';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/checkoutReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { normalize } from '../../../utilities/constants';
//https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
type Props = {
    paymentAmt: number,
    paymentId: string,
    checkoutId: string,
    clubId: string,
    paymentData: StateLoader<PaymentData & {clientSecret?:string}>,
    reinitialize?: (method: PaymentModes) => Promise<string>,
    isAdmin: boolean,
}

function PaymentCredit(props:Props): JSX.Element {
    const [isLoaded, setIsLoaded] = useState(true)
    const club: Club = useAppSelector((state:RootStore) => state.auth.allClubs?.[state.auth?.selectedClub], shallowEqual)
    const [paymentMsg,setPaymentMsg] = useState<string>("")  
    const dispatch = useAppDispatch()
    

    const handleBuyPress = (id: string) => {
        dispatch(handlePayCreditAction(id, props.checkoutId, club, (bool, context) => {}))
    }
    return (
        <View
        style={styles.container}>
            {isLoaded?<>
            {/* {[PaymentTransactionStatuses.succeeded,PaymentTransactionStatuses.refunded].includes(props.paymentData?.data?.status)?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.payment.paymentTotalFromCredit)}
            editable={false}
            >
                <Text>{props.paymentData?.data?.amountCaptured}</Text>
            </FormEntry>:null}
            {[PaymentTransactionStatuses.succeeded,PaymentTransactionStatuses.refunded].includes(props.paymentData?.data?.status)?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.payment.paymentTotalToCredit)}
            editable={false}
            >
                <Text>{props.paymentData?.data?.amountRefunded}</Text>
            </FormEntry>:null} */}
            {[PaymentTransactionStatuses.succeeded,PaymentTransactionStatuses.refunded].includes(props.paymentData?.data?.status)?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.payment.paymentPrevCreditValue)}
            editable={false}
            >
                <Text>{String(props.paymentData?.data?.prevCreditBalance)}</Text>
            </FormEntry>:null}
            {[PaymentTransactionStatuses.succeeded,PaymentTransactionStatuses.refunded].includes(props.paymentData?.data?.status)?
            <FormEntry 
            caption={i18n.t(tokens.app.screens.payment.paymentNewCreditValue)}
            editable={false}
            >
                <Text>{String(props.paymentData?.data?.newCreditBalance)}</Text>
            </FormEntry>:null}
            <View style={styles.buttonView}>
                {props.paymentData?.data?.firebaseId && props.paymentData?.data?.status == PaymentTransactionStatuses.initiated ?
            <BigButton
            buttonText={i18n.t(tokens.app.screens.payment.paymentButtonPay)}
            onPress={() => handleBuyPress(props.paymentData.data.paymentIntentId)}
            />:null}
            </View>
            
            </>:<ActivityIndicator size={"small"}/>}
        </View>
    )
}


const styles = StyleSheet.create(
    {
        container: {
            //width: "100%",
            justifyContent: "center",
            //alignItems: "center",
        },
        buttonView: {
            flexDirection: "row",
            //flex: 1,
            height: 150,
        }
    }
)

export default PaymentCredit