import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { AllResTypes, Reservation } from '../../../../functions/src/interfaces';
import FormEntry from '../../../components/FormEntryNew';
import FormHeading from '../../../components/FormHeading';
import i18n, { tokens } from '../../../i18n';
import { useAppNavigation } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/reservationReducer';
import { normalize } from '../../../utilities/constants';
import { hoursMinutesToString, splitTime } from '../../../utilities/funcs';
import { mainFont } from '../../../utilities/globalStyles';


type Props = {
  reservationData: StateLoader<AllResTypes>,
  editButton?: () => void
}

function ReservationTime(props: Props):JSX.Element {

    //const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    


    return (<>
            
            
            <FormHeading heading={i18n.t(tokens.app.screens.manageReservation.timingHeading)} editButton={
              props.editButton
          }/>
          <FormEntry caption={`${i18n.t(tokens.app.screens.manageReservation.reservationTimeFrom)} - ${i18n.t(tokens.app.screens.manageReservation.reservationTimeTo)}`} editable={false}>
              <Text style={{fontFamily: mainFont}}>{`${hoursMinutesToString(splitTime(String(props.reservationData.data.timeFrom)))} - ${hoursMinutesToString(splitTime(String(props.reservationData.data.timeTo)))}`}</Text>
          </FormEntry>
          
          </>
    )
}

const styles = StyleSheet.create({
    container: {
      width: "100%",
      flexDirection: "column"
    },
    textStyle: {
      
        textAlign: "right", 
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',
        
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
});

export default ReservationTime
