import { AllResTypes, BlockedTime, CallbackFunc, FunctionResponse, GameTypes, Market, MarketLongreservation, MarketPaymentStatuses, MarketReservation, MarketStatuses, MarketTypes, Reservation, ReservationStatuses, ReservationTypes } from "../../../functions/src/interfaces"
import Constants from "expo-constants";
import { dateRangeOverlaps, extractTime, splitTime, standardizeDates } from "../../utilities/funcs";
import { ReservationActions, Score } from "../reducers/reservationReducer";
import { RootStore } from "../reducers/rootReducer";
import { MarketActions, StateLoader } from "../reducers/marketReducer";
import { getUserData } from "./actionsUsers";
import { gameTypes } from "../../utilities/constants";
import { showToast } from "../../utilities/toastcontroller";
import { AppThunk } from "../hooks";
import { GPlayers } from "../../utilities/interfaces";
import { AuthActions } from "../reducers/authReducer";

//ADD localhost address of your server
const API_URL = Constants.expoConfig.extra.functionsUrlBase


export const createUidsArray = (reservation: Reservation): string[] => {
    let allplayers = [
        ...Object.values(reservation.gamePlayers?.home ? reservation.gamePlayers?.home : {}),
        ...Object.values(reservation.gamePlayers?.away ? reservation.gamePlayers?.away : {}),
        ...reservation?.players ? reservation?.players : [],
    ]
    return allplayers
}

export const queryUserReservations = (club: string, user: string, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        console.log('calling with ', club, user)
        dispatch({type: ReservationActions.reservationsUserQuery, data: {isLoaded: false, data: {}}})

        let dt = new Date
        dt.setHours(0,0,0,0)
        const rootRef = getFirestore()
        const entityRef = rootRef.collection('clubs').doc(club)
        .collection('reservations').where('mainUid', '==', user).where('dateFrom', '>=',dt).limit(30).orderBy('dateFrom');

        entityRef.get()
        .then((dataSnapshot) => {
            var reservations = {}
            dataSnapshot.forEach((doc) => {
                let data = standardizeDates(doc.data())
                reservations[doc.id] = data
            })
            dispatch({type: ReservationActions.reservationsUserQuery, data: {isLoaded: true, data: reservations}})
        })
        .catch((e) => {
            console.log('query error: ' + JSON.stringify(e))
            dispatch({type: ReservationActions.reservationsUserQuery, data: {isLoaded: true, data: {}, error: e}})
        })

    }
}

export const subscribeForReservation = (club: string, reservationId: string): AppThunk<Promise<() => void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log("Callung subscribe for res")

        //const firebase = getFirebase()
        //console.log('state', firestoreuid)
        const rootRef = getFirestore()
        const entityRef = rootRef.collection('clubs').doc(club)
        .collection('reservations').doc(reservationId)
        //console.log("Ahoj kurva", club)
        var unsubscribe = entityRef.onSnapshot(
            (doc) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const data:Reservation = standardizeDates(doc.data())
                
                
                //console.log(js)
                dispatch({type: ReservationActions.subscribedReservation, data: data})
            },
            (error) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )

        return unsubscribe


    }
}




export const manageReservationToState = (): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const state = getState()
        const firestoreuid = getFirebase().auth().currentUser.uid
        const selection = state.reservations.selection
        //const firestore = getFirestore()
        var data: BlockedTime = {
            dateFrom: selection.dateFrom,
            dateTo: selection.dateTo,
            timeFrom: parseInt(extractTime(selection.dateFrom)),
            timeTo: parseInt(extractTime(selection.dateTo)),
            court: selection.court,
            season: selection.season,
            mainUid: firestoreuid,
            type: ReservationTypes.outoforder,
            club: state.auth.selectedClub,
            status: ReservationStatuses.open,
            day: selection.dateFrom.getDay(),
            updateTime: new Date,
            text: "",
            uid: firestoreuid,
        }

        /* const clubs = state.auth.allClubs
        let club = clubs[data.club]
        data['price'] = await calcPriceFull(firestore,data,club) */
        dispatch({type: ReservationActions.subscribedReservation, data: data})
    }
}


export const editReservation = (changes: {[key: string]: any}): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const state = getState()
        const seasons = state.auth.seasons
        
        
        
        dispatch({type: ReservationActions.editedReservation, data: {data: changes, seasonsData: seasons, validations: {}}})

    }
}

export function calcPriceApi(data: MarketLongreservation<Market>, mode: 'reservation' | 'marketCalc' | 'marketFetch', callback: CallbackFunc): AppThunk<Promise<void>>;
export function calcPriceApi(data: MarketReservation<Market>, mode: 'reservation' | 'marketCalc' | 'marketFetch', callback: CallbackFunc): AppThunk<Promise<void>>;
export function calcPriceApi(data: any, mode: 'reservation' | 'marketCalc' | 'marketFetch', callback: CallbackFunc): AppThunk<Promise<void>> {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'PRICE_DATA', data: {isLoaded: false, isSuccess: false, isCalculated: false, error: {}}})
        //console.log('Volam cenu v modu: '+ mode)
        try {
            if (mode == 'marketCalc') {
                let response = await fetch(`${API_URL}/calcPriceHttp` , {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Accept":"*/*",
                        "Connection":"keep-alive",
                        "Accept-Encoding":"gzip, deflate, br",
                    },
                    body: JSON.stringify(data),
    
                });
                const { price } = await response.json();
                dispatch({type: 'PRICE_DATA', data: {isLoaded: true, isSuccess: true, isCalculated: true, data: {price: price, club: data.club}, error: {}}})
            } else if (mode == "marketFetch") {
                const firestore = getFirebase().firestore()
                if (data.firebaseId) {
                    const checkoutRef = firestore.collection('clubs').doc(data.club).collection('checkouts').doc(data.firebaseId)
                    const checkout = await checkoutRef.get()
                    var checkoutData 
                    console.log('Mam response z checkouts' + JSON.stringify(checkout.exists))
                    if (checkout.exists) {
                        
                        checkoutData = checkout.data()
                    } else {
                        console.log('Volam calcPriceApi protoze neni kalkulace ulozena')
                        
                        dispatch(calcPriceApi(data, "marketCalc", () => {})) 
                        return
                    }
                    
                    dispatch({type: 'PRICE_DATA', data: {isLoaded: true, isSuccess: true, isCalculated: false, error: {}, data: {...checkoutData, club: data.club}}})
                    return
                } else {
                    console.log('No checkout id provided')
                    dispatch({type: 'PRICE_DATA', data: {isLoaded: false, isSuccess: false, isCalculated: false, error: {}}})
                    return
                }
            } else if (mode == "reservation") {
                const firestore = getFirebase().firestore()
                if (data.reservationRequestId) {
                    const checkoutRef = firestore.collection('clubs').doc(data.club).collection('checkouts').doc(data.reservationRequestId)
                    const checkout = await checkoutRef.get()
                    var checkoutData 
                    //console.log('Mam response z checkouts' + JSON.stringify(checkout.exists))
                    if (checkout.exists) {
                        
                        checkoutData = checkout.data()
                    } else {
                        console.log('Volam calcPriceApi protoze neni kalkulace ulozena')
                        
                        dispatch(calcPriceApi(data, "marketCalc", () => {})) 
                        return
                    }
                    
                    dispatch({type: 'PRICE_DATA', data: {isLoaded: true, isSuccess: true, isCalculated: false, error: {}, data: {...checkoutData, club: data.club}}})
                    return
                } else {
                    console.log('No checkout id provided')
                    dispatch({type: 'PRICE_DATA', data: {isLoaded: false, isSuccess: false, isCalculated: false, error: {}}})
                    return
                }
            } 
        }
        catch (e) {
            dispatch({type: 'PRICE_DATA', data: {isLoaded: false, isSuccess: false, isCalculated: false, error: {message: e.message}, data: {...checkoutData, club: data.club}}})
            return
        }
    }
}

export const queryCollisions = (longReservation: StateLoader<MarketLongreservation<Market>>, callback: CallbackFunc): AppThunk<Promise<void>> => {
    

    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //clean collisions first if there were any
        console.log('calling reservations', longReservation)
        dispatch({type: MarketActions.marketQueryCollisions, data: {isLoaded: false, data:[]}})
        try {
        
            const firebase = getFirebase()

            const rootRef = getFirestore()
            //var collidingReservations = []
            
            //console.log('state', firestoreuid)
            let dt = new Date(longReservation.data.from)
            dt.setHours(0,0,0)
            let counter = 0
            
            let reservations: (Reservation & {collisions: AllResTypes[]})[] = []
            let promiseChain = []

        while (dt <= longReservation.data.to) {
            //console.log(dt.getDay(), longLeservation.day)
            if (dt.getDay() == longReservation.data.day) {
                
                if (counter % longReservation.data.weekRecur == 0) {
                    const [timeFromHours, timeFromMinutes] = splitTime(String(longReservation.data.timeFrom))
                    const [timeToHours, timeToMinutes] = splitTime(String(longReservation.data.timeTo))
                    let newDateFrom = new Date(dt)
                    newDateFrom.setHours(timeFromHours, timeFromMinutes)
                    let newDateTo = new Date(dt)
                    newDateTo.setHours(timeToHours, timeToMinutes)
                    let newDateStart = new Date(dt)
                    newDateStart.setHours(0,0,0)
                    let newDateEnd = new Date(dt)
                    newDateEnd.setHours(23,59,59)
                    let reservation: Reservation & {collisions: AllResTypes[]} = {
                        club: longReservation.data.club,
                        court: longReservation.data.court,
                        dateFrom: newDateFrom,
                        dateTo: newDateTo,
                        mainUid: longReservation.data.uid,
                        type: ReservationTypes.longreservation,
                        reservationRequestId: longReservation.data.firebaseId,
                        updateTime: new Date,
                        timeFrom: parseInt(extractTime(newDateFrom)),
                        timeTo: parseInt(extractTime(newDateTo)),
                        day: newDateFrom.getDay(),
                        gamePlayers: {home: {A:""}, away: {A:""}},
                        season: "",
                        gameType: gameTypes.group,
                        players: [],
                        status: ReservationStatuses.approved,
                        allplayers: [],
                        collisions: []
                    }
                    //console.log('Dpc, tohle chci nahrat', reservation)
                    const reservationRef = rootRef.collection('clubs').doc(reservation.club).collection('reservations')
                    
                    //query 
                    //console.log('query params ',newDateStart,newDateEnd)
                    let reservationQueryDFRef = reservationRef.where('court','==',reservation.court).where('dateFrom','>=',newDateStart).where('dateFrom','<',newDateEnd).get()
                    promiseChain.push(reservationQueryDFRef)
                    reservations.push(reservation)
                    }
                     
                    counter += 1
                }
                    dt.setDate(dt.getDate() + 1);
                }    
                Promise.all(promiseChain)
                .then(
                    (res) => {
                        res.forEach((response, index) => {
                            reservations[index]["collisions"] = []
                            response.forEach((doc) => {

                                let reservationLive: AllResTypes = standardizeDates(doc.data())
                                //console.log(reservationLive.longreservationId , reservations[index].longreservationId)
                                
                                if (dateRangeOverlaps(reservationLive.dateFrom,reservationLive.dateTo,reservations[index].dateFrom,reservations[index].dateTo)) {
                                    if ((reservationLive.type == ReservationTypes.longreservation || 
                                        reservationLive.type == ReservationTypes.reservation || 
                                        reservationLive.type == ReservationTypes.opengame)  && 
                                        (reservationLive.reservationRequestId ? reservationLive.reservationRequestId : "XNA") != reservations[index].reservationRequestId &&
                                         [ReservationStatuses.blocked,ReservationStatuses.approved].includes(reservationLive.status)) {
                                            reservations[index]["collisions"].push(reservationLive)
                                    } else {
                                        reservations[index]["collisions"].push(reservationLive)
                                    }
                                }
                               
                                
                            })
                            
                        })

                        let reservationsWithCollisions = reservations.filter(item => item.collisions.length > 0)
                        //console.log(reservationsWithCollisions)
                        //console.log(reservations)
                        callback(true, reservationsWithCollisions)
                        dispatch({type: MarketActions.marketQueryCollisions, data: {isLoaded: true, data: reservationsWithCollisions}})
                        
                    }
                ).catch(
                    (e) => {
                        console.log('No co je', e)
                        callback(false,e)
                        dispatch({type: MarketActions.marketQueryCollisions, data: {isLoaded: true, data: [], error: e}})
                    }
                )
            }
            catch (e) {
                console.log(e)
            }
        }
        
}

export const queryFreeSpots = (dateFrom: Date, dateTo: Date, club: string, callback: CallbackFunc): AppThunk<Promise<void>> => {
    

    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //clean collisions first if there were any
        //console.log('calling reservations', longReservation)
        dispatch({type: MarketActions.marketQueryCollisions, data: {isLoaded: false, data:[]}})
        try {
            

            const rootRef = getFirestore()
            //var collidingReservations = []
            const clubRef = rootRef.collection('clubs').doc(club)
            const clubDoc = await clubRef.get()
        
        }
        catch (e) {
            console.log(e)
        }
    }
        
}

export const queryreservationsByMarketId = (club:string, marketId: string): AppThunk<void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: ReservationActions.reqervationsListQuery, data: {isLoaded: false, isActionPressed: true, data: []}})
        
        const firestore = getFirestore()

        const clubRef = firestore.collection('clubs').doc(club)
        const reservations = clubRef.collection('reservations').where('reservationRequestId','==',marketId)

        reservations.get()
        .then((querySnapshot) => {
            let docs = querySnapshot.docs
            var outReservations = []
            for (let i = 0; i < docs.length; i++) {
                let doc = docs[i]
                let reservation: Reservation = standardizeDates(doc.data())
                outReservations.push(reservation)
            }
            dispatch({type: ReservationActions.reqervationsListQuery, data: {isLoaded: true, isActionPressed: false, data: outReservations}})
        })
        .catch((e) => {
            dispatch({type: ReservationActions.reqervationsListQuery, data: {isLoaded: true, isActionPressed: false, data: [], error: {code: 'QUERY_ERROR', message: e.message}}})
        })
    }
}

export const loadGameResult = (resultId: string, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        console.log('Queruju game result: ' + String(resultId))
        if (resultId) {
            dispatch({type: ReservationActions.reservationResultToState, data: {isLoaded: false, isSuccess: true, edited: false, data: {}}})
        } else {
            dispatch({type: ReservationActions.reservationResultToState, data: {isLoaded: true, isSuccess: true, edited: false, data: {result: []}}})
            return
        }
        const rootRef = getFirestore()
        //const uid = profile.uid
        const resultRef = rootRef.collection('results').doc(resultId)
        
        

        resultRef.get()
        .then((resultReturned) => {
            let data = standardizeDates(resultReturned.data())
            console.log('Returned: ' + data.firebaseId)
            dispatch({type: ReservationActions.reservationResultToState, data: {isLoaded: true, isSuccess: true, edited: false, data: {...data}}})
            callback(true, null) 
        })
        .catch((e) => {
            dispatch({type: ReservationActions.reservationResultToState, data: {isLoaded: true, isSuccess: false, message: e.message}})
            callback(false, e) 
        })
        
        
    }
}

const gamePlayersTransform = (gamePlayers: GPlayers) => {
    var out = {}
    let sides = Object.keys(gamePlayers)
    for (let i=0; i<sides.length; i++) {
        let side = sides[i]
        let positions = Object.keys(gamePlayers[side])
        for (let j=0; j<sides.length; j++) {
            let pos = positions[j]
            if (gamePlayers[side][pos] && gamePlayers[side][pos] != "") out[gamePlayers[side][pos]] = {pos: pos, side: side}
        }
    }
    
    return out
}


export const addGameResult = (result: Score, reservation: Reservation, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: ReservationActions.reservationResultAdditionResponse, data: {isLoaded: false}})
        const rootRef = getFirestore()
        //const uid = profile.uid
        
        
        let trnsGamePlayers = gamePlayersTransform(reservation.gamePlayers) 
        console.log('transformed', trnsGamePlayers)

        rootRef.runTransaction(async (t) => {
            var resData = {
                firebaseId: result.firebaseId,
                result: result.result,
                gamePlayers: trnsGamePlayers,
                reservationId: reservation.firebaseId,
                dateFrom: reservation.dateFrom,
                players: Object.keys(trnsGamePlayers),
                rankedGame: result.rankedGame ? result.rankedGame : false,
                gameType: reservation.gameType,
                club: reservation.club,
                season: reservation.season,
            }
            var reservationRef = rootRef.collection('clubs').doc(reservation.club).collection('reservations').doc(reservation.firebaseId)
            var entityRef: any = rootRef.collection('results')
            if (result.firebaseId) {
                entityRef = entityRef.doc(reservation.firebaseId)
                t.update(entityRef, resData)
            } else {
                entityRef = entityRef.doc(reservation.firebaseId)
                resData['firebaseId'] = await entityRef.id
                t.set(entityRef, resData)
            }
                
            
            t.update(reservationRef, {resultId: resData['firebaseId']})
            return resData
        })
        .then((resultReturned) => {
            dispatch({type: ReservationActions.reservationResultAdditionResponse, data: {isLoaded: true, isSuccess: true, message: "Result was successfully saved " + resultReturned.firebaseId}})
            dispatch({type: 'RESERVATION_RESULT_RESET', data: {edited: false}})
            callback(true, null) 
        })
        .catch((e) => {
            console.log(e)
            dispatch({type: ReservationActions.reservationResultAdditionResponse, data: {isLoaded: true, isSuccess: false, message: e.message}})
            callback(false, e) 
        })
        
        
    }
}


export const subscribeForReservations = (club: string, day: Date): AppThunk<() => void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //console.log("Callung subscribe for res")
        dispatch({type: ReservationActions.reservationsChanges, data: {isActionPressed: true, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        let dateFrom = new Date(day)
        dateFrom.setHours(0,0,0,0)
        let dateTo = new Date(dateFrom)
        dateTo.setDate(dateFrom.getDate() + 1)
        const state: RootStore = getState()
        
        //const firebase = getFirebase()
        //const firestoreuid = firebase.auth().currentUser.uid
        //console.log('state', firestoreuid)
        


        const rootRef = getFirestore()
        const entityRef = rootRef.collection('clubs').doc(club)
        .collection('reservations').where('dateFrom', '>=', dateFrom).where('dateFrom', '<=', dateTo).orderBy('dateFrom');
        //console.log("Ahoj kurva", club, dateFrom, dateTo)
        return entityRef.onSnapshot(
            (querySnapshot) => {
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log('queried',doc.doc.data())
                    let data = standardizeDates(doc.doc.data()) 
                    //console.log('queried',data)
                    let docType = ["deleted","declined"].includes(data.status) ? 'removed' : doc.type
                    js[docType][doc.doc.id] = data
                    let uids = createUidsArray(data)

                    uids.forEach((uid) => {
                        if (state.users.usersData[uid]) {

                        } else {
                            dispatch(getUserData(uid, () => {}))
                        }
                    })
                    //console.log('queried',js)
                })
                
                //console.log(js)
                dispatch({type: ReservationActions.reservationsChanges, data: {isLoaded: true, data: js}})
                dispatch({type: AuthActions.authActionPressed, data: false})
            },
            (error) => {
                console.log("Error listening", error)
                dispatch({type: ReservationActions.reservationsChanges, data: {isLoaded: true, isSuccess: false, data: {}}})
                dispatch({type: AuthActions.authActionPressed, data: false})
            }
        )

      


    }
}

export type ResQueryParms = {
    from: Date,
    to: Date,
    club: string
}

export const queryReservationsForStats = (queryParams: ResQueryParms, callback: CallbackFunc): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        //const firebase = getFirebase()
        console.log('calling with ', queryParams)
        dispatch({type: 'QUERIED_STAT_RESERVATIONS', data: {isLoaded: false, isSuccess: false}})
        //let state = getState()
        let dt = new Date
        dt.setHours(0,0,0,0)
        const rootRef = getFirestore()
        const entityRef = rootRef.collection('clubs').doc(queryParams.club)
        .collection('reservations').where('dateFrom', '>=', queryParams.from).where('dateFrom', '<=',queryParams.to).orderBy('dateFrom');

        entityRef.get()
        .then(async (dataSnapshot) => {
            var reservations = []
            var users = {}

            for (let x = 0; x < dataSnapshot.docs.length; x++) {
                let doc = dataSnapshot.docs[x]
                let data = standardizeDates(doc.data())
                let uids = [...data.allplayers ? data.allplayers: []]
                if (!uids.includes(data.mainUid)) uids.push(data.mainUid)
                for (let i = 0; i < uids.length; i++) {
                    let uid = uids[i]
                    if (users[uid]) {
                        console.log('Already got: ' + uid)
                    } else if (uid && uid != "") {
                        let userRef = rootRef.collection('userspublic').doc(uid)
                        let userDoc = await userRef.get()
                        users[uid] = standardizeDates(userDoc.data())
                    }
                } 
                let outData = {
                    dateFrom: data.dateFrom,
                    dateTo: data.dateTo,
                    timeFrom: parseInt(extractTime(data.dateFrom)),
                    timeTo: parseInt(extractTime(data.dateTo)),
                    firebaseId: data.firebaseId,
                    mainPlayer: users[data.mainUid]? `${users[data.mainUid].first_name} ${users[data.mainUid].family_name}`:'Not loaded',
                    mainUid: data.mainUid,
                    type: data.type,
                    isOnBehalf: data.isOnBehalf,
                    onBehalfFirstName: data.onBehalfFirstName,
                    onBehalfFamilyName: data.onBehalfFamilyName,
                    allPlayers: JSON.stringify(uids.map((uid) => {return users[uid]? `${users[uid].first_name} ${users[uid].family_name} (${uid})`:'Not loaded'})),
                    court: data.court,
                    status: data.status,
                    day: data.day,
                    
                }
                //console.log('res',outData)
                reservations.push(outData)
            }
           
            dispatch({type: 'QUERIED_STAT_RESERVATIONS', data: {isLoaded: true, isSuccess: true, reservations: reservations}})
        })
        .catch((e) => {
            console.log('query error: ' + JSON.stringify(e))
            dispatch({type: 'QUERIED_STAT_RESERVATIONS', data: {isLoaded: true, isSuccess: false, reservations: []}})
        })

    }
}



export const reservationDeletionAction = (clubId: string, marketId: string, type: 'all' | 'future' | 'single' ): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: AuthActions.authActionPressed, data: true})
        dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: true, data: {}, validations: {}}})
        let state: RootStore = getState()
        let reservation = state.reservations.managedReservation.data
        const token = await getFirebase().auth().currentUser.getIdToken(true)
        const firestore = getFirestore()
        var reservationIds: string[] = []
        if (reservation.type == ReservationTypes.reservation || reservation.type == ReservationTypes.longreservation) {
            //let retyped = {...market}
            const query = firestore.collection('clubs').doc(clubId).collection('reservations').where('reservationRequestId','==',reservation.reservationRequestId)
            let reservationsDocs = await query.get()
            let reservations: Reservation[] = reservationsDocs.docs.map((doc) => standardizeDates(doc.data()))
            if (type == 'all') {
                reservationIds = reservations.map((res) => res.firebaseId)
            } else if (type == 'future') {
                
                let futureReservations: Reservation[] = [...reservations.filter(res => res.dateFrom >= new Date)]
                reservationIds = futureReservations.map((res) => res.firebaseId)
            } else if (type == 'single') {
                reservationIds = [reservation.firebaseId]
            }
        }
        
        
        let body: string = JSON.stringify({
            token: token,
            type: reservation.type,
            reservationIds: reservationIds,
            marketId: marketId,
            clubId: clubId
        })
        
        console.log('Request: '+ body)
        let response = await fetch(`${API_URL}/marketDeletion` , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept":"*/*",
                "Connection":"keep-alive",
                "Accept-Encoding":"gzip, deflate, br",
            },
            body: body,

        });
        if (response.ok) {
            const resp: FunctionResponse = await response.json()
            const data: MarketReservation<Market> = standardizeDates(resp.data) 
            console.log('Returned data: ' + JSON.stringify(data))
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {...data}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            showToast("OK")
            if (resp.actions.includes('REQUIRES_REFUND')) {
                return {result: true, action: 'checkout', error: {}, data: {checkoutId: data.firebaseId, marketId: data.firebaseId}}
            } else {
                return {result: true, action: 'none', error: {}, data: {marketId: data.firebaseId}}
            }
        } else {
            const errResp: FunctionResponse =  await response.json()
            console.log('Error resp: ' + JSON.stringify(errResp.error))
            showToast('Error: ' + JSON.stringify(errResp.error))
            dispatch({type: MarketActions.changeManagedMarket, data: {isActionPressed: false, data: {}, validations: {}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return {result: false, action: 'none', error: {code: errResp.error.code, message: errResp.error.message}, data: {}}
        }
    }
}



export const accOrChangeBlockedTime = (): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: AuthActions.authActionPressed, data: true})
        const state = getState()
        const firestore = getFirestore()
        const managedBlock = state.reservations.managedReservation.data
        const resColRef = firestore.collection('clubs').doc(managedBlock.club).collection('reservations')
        var resData  = {
            ...managedBlock,
            status: ReservationStatuses.blocked,
        }
        firestore.runTransaction(async (t) => {
            var entityRef = null
            if (managedBlock.firebaseId) {
                entityRef = resColRef.doc(managedBlock.firebaseId)
                t.update(entityRef, resData)
            } else {
                entityRef = resColRef.doc()
                resData['firebaseId'] = await entityRef.id
                t.set(entityRef, resData)
            }
            return resData
        })
        .then((resData) => {
            console.log('Updated' + JSON.stringify(resData))
            showToast("OK")
            dispatch({type: AuthActions.authActionPressed, data: false})
        })
        .catch((e) => {
            console.log('Error' + JSON.stringify(resData))
            showToast("Error")
            dispatch({type: AuthActions.authActionPressed, data: false})
        })
        
    }
}


export const removeBlockedTime = (): AppThunk<Promise<boolean>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: AuthActions.authActionPressed, data: true})
        const state = getState()
        const firestore = getFirestore()
        const managedBlock = state.reservations.managedReservation.data
        const resRef = firestore.collection('clubs').doc(managedBlock.club).collection('reservations').doc(managedBlock.firebaseId)
        return resRef.delete()
        .then(() => {
            console.log('Deleted' + managedBlock.firebaseId)
            showToast("OK")
            dispatch({type: AuthActions.authActionPressed, data: false})
            return Promise.resolve(true)
        })
        .catch((e) => {
            console.log('Error' + managedBlock.firebaseId)
            showToast("Error")
            dispatch({type: AuthActions.authActionPressed, data: false})
            return Promise.reject(false)
        })
        
    }
}


