import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable } from 'react-native'

import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';


import { normalize, windowWidth } from '../utilities/constants'
import { mainFont, styles } from '../utilities/globalStyles'

type Props = {
    validity?: boolean,
    children?: JSX.Element,
    error?: string,
    editable: boolean,
    direction?: "row" | "column"
}

const FormEntryNoCaption: React.FC<Props> = ({validity = true, children, error, editable, direction }) => {
    //console.log('Forme entry props: ', props)

    //const padding = 5
    //const [valid, setValid] = useState(true)
    
    //useEffect(() => {if (typeof props.validity != 'undefined') setValid(props.validity)}, [props.validity])

    const stylelocal = StyleSheet.create({
        entityContainer: {
            flexDirection: 'column',
            width: "100%",
            marginTop: 3,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 5,
            paddingBottom: 5,
            borderWidth: editable == true ? 1 : 0,
            borderColor: validity == true ? "grey" : "red",
            backgroundColor: "white",
            borderRadius: 5
        },
        entityText: {
            fontFamily: mainFont,
            fontSize: normalize(20)
        },
        entityRow: {
            flexDirection: direction || "row",
            width: "100%",
            
        },
        
      
        entityCaption: {
            
        },
        entityChildren: {
            //flex: childrenFlex,
            width: "55%",
            marginLeft: "auto",
            alignItems: "flex-end"
        },
        errorStyle: {
            color: "red",
        },
        errorContainer: {
            //flex: 1,
            alignItems: "flex-end",
        }
    })

  
    return (
        
        <View style={stylelocal.entityContainer}>
            <View style={stylelocal.entityRow}>
                
                    {children}
                </View>
                {!validity?
                <View style={stylelocal.errorContainer}
                >
                    <Text maxFontSizeMultiplier={1.2} style={stylelocal.errorStyle}>{error}</Text>
                </View>:null}
        </View>
    )
}





export default FormEntryNoCaption

