import React, { Component } from "react";
import { Text } from "react-native";

type Props = {

}

type StateComponentState = {
    xx: number
}

export default class StateComponent extends React.Component<Props, StateComponentState> {
    constructor(props: Props) {
        super(props);
        //this.state = {color: "red"};
        this.state = {xx: 90}

        console.log("Tvl tohle je komponenta", this.state, props)
    }
    render() {
        return <Text>I am a Car!</Text>;
    }
}

