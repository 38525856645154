import React, { useEffect, useState } from 'react'

import { Platform, StyleSheet, Text, View } from 'react-native';
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { RootStore } from '../store/reducers/rootReducer';
import { UserOrClub } from '../store/reducers/usersReducer';
//import CachedImage from 'expo-cached-image'
import SHA1 from "crypto-js/sha1";
//import CustomFastImage from './CustomFastImage';
import { Image } from 'expo-image';
import i18n, { tokens } from '../i18n';

export interface AvatarProps {
  size: number;
  uid?: string;
  type?: "strict" | "inferrable"
  caption?: string,
  photo?: string
}

interface User {
  uid: string;
  photo?: string;
  first_name: string;
  family_name: string;
}

type UserDataType = {
  type: "user"
  data: {first_name: string, family_name: string, photo?: string, uid: string, isAvailable?: boolean}
}

type ClubDataType = {
  type: "club"
  data: {club_name: string, photo?: string, uid: string, isAvailable?: boolean}
}

type OtherDataType = {
  type: "other"
  data: {caption: string, photo?: string, isAvailable?: boolean}
}

type AnyDataType = UserDataType | ClubDataType | OtherDataType

type PlaceholderProps = {
  size: number,
  title: string
}

const PlaceholderContent: React.FC<PlaceholderProps> = (props) => {
  return (<View style={
    {
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      paddingLeft: 3,
      paddingRight: 3,
      backgroundColor: "lightgrey"
    }
  }>
    <Text style={{
      fontSize: props.size/5,
      textAlign: "center"
    }}>{props.title}</Text>
  </View>)
}


const UserAvatar: React.FC<AvatarProps> = (props) => {
    //console.log('Uid na playerovi', props.uid)
    const [error, setError] = useState(false)
    const [userData, setUserData] = useState<AnyDataType>({type: "other", data: {caption: i18n.t(tokens.app.components.avatar.avatarComponentUnknown), photo: null}})
    const user: UserOrClub = useAppSelector((state: RootStore) => state.users.usersData[props.uid], (a,b) => {
      if (a?.type == b?.type) {
        if (a?.type == "user" && b?.type == "user" && a?.uid == b?.uid && a?.photo == b?.photo) {
          return true
        } else if (a?.type == "clubs" && b?.type == "clubs" && a?.firebaseId == b?.firebaseId && a?.photo == b?.photo) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })
    const dispatch = useAppDispatch()
    const [fetched, setFetched] = useState([]) //To prevent infinite loop push fetched uids

    async function exists(url: string) {
      console.log('Fetch data: ', url)
      if (!url || url == "") return false
      const result = await fetch(url, { method: 'HEAD', mode: "no-cors" });
      console.log('Fetch result: ', result)
      return result.ok;
    }


    
    useEffect(() => {
      const useEffectAsync = async () => {
      //console.log('Muj user', user)
      if (props.type == "strict") {
        
        setUserData({type: "other", data: {caption: props.caption, photo: props.photo}})
      } else if (user && user.type == "user") {
        setUserData({type: "user", data: {first_name: user.first_name, family_name: user.family_name, photo: user.photo, uid: user.uid}})
      } else if (user && user.type == "clubs") {
        setUserData({type: "club", data: {club_name: user.club_name, photo: user.photo, uid: user.uid}})
      } else if (props.uid && props.uid != "" && !fetched.includes(props.uid)) {
        //dispatch(getUserData(props.uid, () => {}))
        setFetched(prev => {return [...prev, props.uid]})
      }
    }
    useEffectAsync()
  }
      //console.log('Uzivatelska fotka: ' + user?.photo)
    , [props.uid, user])
    //const contactFlag = 
    //console.log('nalezeno', props)
    
    const styles = StyleSheet.create({
        
        entityContainer: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 8,
            borderBottomColor: '#cccccc',
            borderBottomWidth: 1,
            paddingBottom: 8
        },
        entityText: {
            fontSize: 16,
            color: '#333333'
        }
    })

    const title = (data: AnyDataType) => {
      if (data.type == "user") {
        return `${data.data.first_name} ${data.data.family_name}`
      } else if (data.type == "club") {
        return data.data.club_name
      } else if (data.type == "other") {
        return data.data.caption
      } else {
        return i18n.t(tokens.app.components.avatar.avatarComponentUnknown)
      }
    }

    

    
    return (
      <View style={
        {
          width: props.size,
          height: props.size,
          borderColor: "darkgrey",
          borderWidth: 1,
          borderRadius: props.size/2,
          overflow: "hidden",
        }
      }>
        {
          
          (userData.type == "user" || userData.type == "club")&& userData.data.photo && userData.data.photo != '' && error == false ?
          <Image 
          source={{
            uri:  userData.data.photo,
          }}
          style={              // pass-through to <Image /> tag 
            {
              width: props.size,
              height: props.size,
            }
          }
          contentFit="cover"
          onError={(event) => setError(true)}
          />: <PlaceholderContent title={title(userData)} size={props.size}/>
          
      }
       
      </View>
    )
}



export default UserAvatar

