import { isAnyRole } from "../../../functions/src/funcs"
import { MarketStatuses, ReservationStatuses, UserRoles } from "../../../functions/src/interfaces"
import i18n, { tokens } from "../../i18n"
import { UserOrClub } from "../../store/reducers/usersReducer"
import { validateTextTime } from "../../utilities/validators"

export const validations = (season) => {
    if (season) {
        return {
            timeFrom: {
                validity: true, error: "", method: (newState) => {
                    //if (newState?.season == "") return {bool: true, context: ""}
                    //pokud nemam na rezervaci ulozenou sezonu, pouziji aktivni, zatim to je jedine misto
                    //let currSeason = newState?.season? seasons[newState?.season] : season
                    //console.log(currSeason, newState.season, season)

                    if (parseInt(newState.timeFrom) < season.openFrom || parseInt(newState.timeFrom) > season.openTo) {
                        return { bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeNotOpenHours) }
                    } else if (!validateTextTime(newState.timeFrom)) {
                        return { bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeNotDivisible) }
                    } else {
                        return { bool: true, context: "" }
                    }

                }
            },
            timeTo: {
                validity: true, error: "", method: (newState) => {
                    //if (newState.season == "") return {bool: true, context: ""}
                    //pokud nemam na rezervaci ulozenou sezonu, pouziji aktivni, zatim to je jedine misto
                    //let currSeason = newState?.season? seasons[newState?.season] : season
                    //console.log(currSeason)

                    if (parseInt(newState.timeTo) < season.openFrom || parseInt(newState.timeTo) > season.openTo) {
                        return { bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeNotOpenHours) }
                    } else if (parseInt(newState.timeTo) < parseInt(newState.timeFrom)) {
                        return { bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeToLTTimeFrom) }
                    } else if (!validateTextTime(newState.timeTo)) {
                        return { bool: false, context: i18n.t(tokens.app.screens.longreservation.errorTimeNotDivisible) }
                    } else {
                        return { bool: true, context: "" }
                    }

                }
            },
        }

    } else {
        return {}
    }

}

export const isAllowedToEdit = (currentUser: UserOrClub<UserRoles>, reservationOwner: string, status: ReservationStatuses | MarketStatuses, marketClub: string) => {
    //console.log('Signature ',currentUser, reservationOwner, status)
    if (!currentUser) {
        return false
    } else if (currentUser.type == "clubs") {
        if (marketClub == currentUser.firebaseId) {
            return true
        } else {
            return false
        }
    } else if (currentUser.uid == reservationOwner) {
        if (status == MarketStatuses.open) {
            return true
        } else if (status == MarketStatuses.new) {
            return true
        } else if (status == MarketStatuses.blocked) {
            return true
        } else if (status == MarketStatuses.approved) {
            return true
        } else {
            return false
        }
        return true
    } else if (currentUser.isGod == true) {
        return true
    } else if (currentUser.isAdmin == true) {
        return true
    } else {
        return false
    }
}


export const isAllowedToApprove = (currentUser: UserOrClub<UserRoles>, marketClub: string) => {
    if (currentUser.type == "user") {
        return isAnyRole(['isGod','isAdmin'], currentUser)
    } else if (currentUser.type == "clubs") {
        if (marketClub == currentUser.firebaseId) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}