import { Route, useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Platform, StyleSheet, Text, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { shallowEqual } from "react-redux";
import { CheckoutData, Club, CreditBalance, PaymentData, PaymentModes, PaymentStatuses, PaymentTransactionStatuses, UserRoles } from "../../../functions/src/interfaces";
import ActionOverlay from "../../components/ActionOverlay";
import FormEntry from "../../components/FormEntryNew";
import FormHeading from "../../components/FormHeading";
import SmallButton from "../../components/SmallButton";
import WebWrapper from "../../components/WebWrapper";
import i18n, { tokens } from "../../i18n";
import { getUserCredit, handleCheckoutAll, subscribeForPayment } from "../../store/actions/actionsCheckout";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks";
import { CheckoutActions, StateLoader } from "../../store/reducers/checkoutReducer";
import { RootStore } from "../../store/reducers/rootReducer";
import { getCurrentUserWithRoles } from "../../store/selectors";
import { showToast } from "../../utilities/toastcontroller";
import PaymentByType from "./Components/PaymentByType";
import PaymentDetail from "./Components/PaymentDetail";
import { isAnyRole } from "../../../functions/src/funcs";
import { paymentStateEval } from "../../utilities/funcs";
import { globalStyleSheet } from "../../utilities/globalStyles";
import { UserOrClub } from "../../store/reducers/usersReducer";
import BigButton from "../../components/BigButton";
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';
import composePaymentInvoice from "./PaymentInvoice";
//let result = await WebBrowser.openBrowserAsync(resData.redirectUri);
//console.log(result)
//ADD localhost address of your server
const API_URL = Constants.expoConfig.extra.functionsUrlBase

type Props = {
    route: Route<'PaymentScreen', {type: 'checkout' | 'payment',club: string, blockedMethods?: PaymentModes[], paymentId?: string}>
    
}



const PaymentScreen: React.FC<Props> = props => {
    const { type, club, blockedMethods, paymentId } = props.route.params
    //console.log('Payment screen',props)
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    //const selectedClub = useSelector(state => state.auth.selectedClub, shallowEqual)
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [mode, setMode] = useState(PaymentModes.paymentModeNone);
    const [selectedPrinter, setSelectedPrinter] = useState<Print.Printer>();
    const [redirectUrl, setRedirectUrl] = useState("")
    //const [clientSecret, setClientSecret] = useState("")
    
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    const userBalance:number = useAppSelector((state:RootStore) => state.auth.allUserCredits?.[state.auth?.selectedClub]?.creditBalance, shallowEqual)
    //const userBalance:{[clubId: string]: CreditBalance} = useAppSelector((state:RootStore) => state.auth.allUserCredits, shallowEqual)
    const checkoutData: StateLoader<CheckoutData> = useAppSelector((state:RootStore) => state.checkout.subscribedCheckout, shallowEqual)
    const paymentData: StateLoader<PaymentData & {clientSecret?: string}> = useAppSelector((state:RootStore) => state.checkout.subscribedPayment, shallowEqual)
    const clubData: Club = useAppSelector((state:RootStore) => state.auth.allClubs?.[state.auth?.selectedClub], shallowEqual)
    //const [clientSecret, setClientSecret] = useState(null)
    const checkoutStripe = useAppSelector(state => state.checkout.allowStripe, shallowEqual)

    useEffect(() => {
        var subscribe = () => {}
        if (type == "checkout") {
            const checkoutPaymentId = checkoutData?.data?.paymentIntentId
            if (checkoutPaymentId && checkoutPaymentId != "" && club && club != "") {
                dispatch(subscribeForPayment(checkoutPaymentId, club, (bool, context) => {}))
                .then((unsub) => subscribe = unsub)
            } else {
                dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {data: {}, isLoaded: true, isSuccess: false}})
                setMode(PaymentModes.paymentModeNone)
            }
        } else if (type == "payment") {
            if (paymentId && paymentId != "" && club && club != "") {
                dispatch(subscribeForPayment(paymentId, club, (bool, context) => {}))
                .then((unsub) => subscribe = unsub)
            } else {
                dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {data: {}, isLoaded: true, isSuccess: false}})
                setMode(PaymentModes.paymentModeNone)
            }
        }
        
        return () => {
            subscribe()
            dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {data: {}, isLoaded: false, isSuccess: false}})
        }
    }, [checkoutData?.data?.paymentIntentId, club])


  /*   useEffect(() => {
        (async ()
    }, [club, checkoutId]); */

    /* useEffect(() => {
        if (currentUser && currentUser.uid && club && club != "") {
            dispatch(getUserCredit(currentUser.uid, club, () => {}))
        }
    }, [club, currentUser, mode]) */

   
    const isAdmin = () => {
        if (currentUser.type == "user") {
            return currentUser.isAdmin == true || currentUser.isGod == true
        } else if (currentUser.type == "clubs") {
            return currentUser.firebaseId == club
        } else {
            return false
        }
    }
    
    const getTrueRoles = (roleObject: string) => Object.entries(clubData[roleObject]?.roles || {}).reduce((prev, [key, value]) => {
        if (value == true) {
            return [...prev, key]
        } else {
            return [...prev]
        }
    },[] as string[])

    const allowOnlinePayment = () => {
        if (currentUser.type == "user" && 
            //currentUser.isMember == true && 
            (blockedMethods && !blockedMethods.includes(PaymentModes.paymentModePayU)) && 
            (clubData.onlinePayments?.allowed == true) &&
            (isAnyRole(getTrueRoles('onlinePayments'), currentUser))
            ) {
            return true
        } else {
            return false
        }
    }

    const allowCreditPayment = () => {
        if (currentUser.type == "user" && 
            //currentUser.isMember == true && 
            (blockedMethods && !blockedMethods.includes(PaymentModes.paymentModeCredit)) && 
            (clubData.creditPayments?.allowed == true) //&&
            //(isAnyRole(getTrueRoles('creditPayments'), currentUser))
            ) {
            return true
        } else {
            return false
        }
    }

    const allowQRPayment = () => {
        if (currentUser.type == "user" && 
            //currentUser.isMember == true && 
            (blockedMethods && !blockedMethods.includes(PaymentModes.paymentModeBT)) && 
            (clubData.qrPayments?.allowed == true) &&
            (isAnyRole(getTrueRoles('qrPayments'), currentUser))
            ) {
            return true
        } else {
            return false
        }
    }

    const allowPaymentSelect = () => {
        if (checkoutData.data.status == PaymentStatuses.fullyPaidBalance) {
            return false
        } else if (checkoutData.data.status == PaymentStatuses.overpaidBalance) {
            return false
        } else {
            return true
        }
    }

    const handlePaymentMethodPress = (method: PaymentModes): Promise<any> => {
        let checkoutId = paymentData.data?.checkoutId || checkoutData.data?.firebaseId
        if (checkoutId) {
            return dispatch(handleCheckoutAll(checkoutId,clubData.firebaseId,method, (bool, context) => {
                console.log('Checkout response: ' + String(bool) + ' ' + JSON.stringify(context))
                if (bool == true && method == PaymentModes.paymentModePayU) {
                    
                    setRedirectUrl(context.data.redirectUri)
                }
            }))
        } else {
            showToast('No checkout ID')
        }
        
    }


    



    const print = async () => {
        // On iOS/android prints the given html. On web prints the HTML from the current page.
        const html = composePaymentInvoice(checkoutData.data, paymentData.data, clubData)
        await Print.printAsync({
          html,
          printerUrl: selectedPrinter?.url, // iOS only
        });
    };
    
    const printToFile = async () => {
        const html = composePaymentInvoice(checkoutData.data, paymentData.data, clubData)
        // On iOS/android prints the given html. On web prints the HTML from the current page.
        if (Platform.OS == "web") {
            const pW = window.open('', '', 'height=500, width=500')
            pW.document.write(html)
            pW.document.close()
            pW.print()
        } else {
            const { uri } = await Print.printToFileAsync({ html });
            console.log('File has been saved to:', uri);
            await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
        }
    };
    
    const selectPrinter = async () => {
        const printer = await Print.selectPrinterAsync(); // iOS only
        setSelectedPrinter(printer);
    };



    const MethodSelector:React.FC<{mode: PaymentModes}> = ({mode}) => {
        // ...
        return (
            <View style={{ marginVertical: 48, width: '100%'//, alignItems: "center" 
            }}>
                <FormHeading 
                heading={i18n.t(tokens.app.screens.payment.paymentPaymentMode)} 
                editButton={(mode == PaymentModes.paymentModeNone || !mode) && !allowPaymentSelect()?null:() => dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {data: {type: PaymentModes.paymentModeNone}}})}
                editButtonCaption={i18n.t(tokens.app.screens.payment.paymentChangePaymentMode)}/>
                {allowPaymentSelect() && (mode == PaymentModes.paymentModeNone || !mode) ?
                <>
                
                <View key={"bottom"} style={styles.buttonContainer}>
                    {!isAdmin()?<Text>
                        {i18n.t(tokens.app.screens.payment.paymentMethodText)}
                    </Text>:null}
                {allowOnlinePayment()?
                <SmallButton 
                onPress={() => handlePaymentMethodPress(PaymentModes.paymentModePayU)}
                buttonText={i18n.t(tokens.app.constants.paymentModes.paymentModePayU)}
                />:null}
                {allowCreditPayment()?
                <SmallButton 
                onPress={() => handlePaymentMethodPress(PaymentModes.paymentModeCredit)}
                buttonText={i18n.t(tokens.app.constants.paymentModes.paymentModeCredit)}
                />:null}
                {allowQRPayment()?
                <SmallButton 
                onPress={() => handlePaymentMethodPress(PaymentModes.paymentModeBT)}
                buttonText={i18n.t(tokens.app.constants.paymentModes.paymentModeBT)}
                />:null}
                {blockedMethods && !blockedMethods.includes(PaymentModes.paymentModeCash) && isAdmin()?
                <SmallButton 
                onPress={() => handlePaymentMethodPress(PaymentModes.paymentModeCash)}
                buttonText={i18n.t(tokens.app.constants.paymentModes.paymentModeCash)}
                />:null}
            </View>
            
            
            </>
                
                
            :<><FormEntry
            caption={i18n.t(tokens.app.screens.payment.paymentPaymentMode)}
            editable={true}
            >
                <Text>{i18n.t(mode)}</Text>
            </FormEntry>
            <FormEntry caption={i18n.t(tokens.app.screens.manageReservation.reservationPriceState)}
            editable={false}>
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.messageText}>
                    {i18n.t(paymentStateEval({...checkoutData.data, isCalculated: false}))}
                </Text>
            </FormEntry>
            </>}
                
            </View>
        )
    }
    

    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >
            <WebWrapper>
                <View style={styles.container}>
                <ActionOverlay
                onBackdropPress={()=> {}}
                fullScreen={true}
                isVisible={paymentData.isActionPressed}
                />
                    <FormHeading heading={i18n.t(tokens.app.screens.payment.paymentDetail)}/>
                    {checkoutData.isLoaded?
                    <FormEntry 
                    caption={i18n.t(tokens.app.screens.payment.paymentTotalForPayment)}
                    editable={false}
                    >
                        <Text>{checkoutData.data.price}</Text>
                    </FormEntry>:<ActivityIndicator size={"small"}/>}
                    
                    {[PaymentModes.paymentModeNone,PaymentModes.paymentModeCredit].includes(paymentData.data?.type || mode)?<FormEntry 
                    caption={i18n.t(tokens.app.screens.payment.paymentFreeCredit)}
                    editable={false}
                    >
                    
                        <Text>{userBalance}</Text>
                    </FormEntry>:null}
                    <MethodSelector mode={paymentData.data?.type || mode}/>

                    <PaymentDetail 
                    club={checkoutData.data.club}
                    paymentId={checkoutData.data.paymentIntentId}
                    />
                  
                    <PaymentByType 
                    isAdmin={isAdmin()}
                    clubData={clubData}
                    checkoutId={paymentData?.data?.checkoutId}
                    //marketId={marketId}
                    paymentData={paymentData}
                    mode={paymentData.data?.type || mode}
                    price={checkoutData.data.price}
                    allowStripe={checkoutStripe}
                    redirect={redirectUrl}
                    reinitialize={handlePaymentMethodPress}
                    />
                    
                    <View style={styles.buttonView}>
                    {isAdmin() && paymentData?.data?.firebaseId && paymentData?.data?.status == PaymentTransactionStatuses.succeeded?
                    <BigButton
                    buttonText={i18n.t(tokens.app.screens.payment.paymentPrintButton)}
                    onPress={() => printToFile()}
                    />:null}
                    <BigButton
                    buttonText={i18n.t(tokens.app.screens.payment.paymentCloseButton)}
                    onPress={() => {
                        navigation.goBack()
                    }}
                    />
                    </View>

                </View>
            </WebWrapper>
        </KeyboardAwareScrollView>
    );
};
export default PaymentScreen;

const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-evenly",
        marginBottom: 10,
    },
    methodSelectTitle: {
        fontSize: 14,
        letterSpacing: 1.5,
        color: 'black',
        textTransform: 'uppercase'
    },
    container: {
        flex: 1,
        //justifyContent: "center",
        margin: 10,
        //alignItems: "center",  
        //width: "100%",
    },
    input: {
        backgroundColor: "#efefefef",

        borderRadius: 8,
        fontSize: 20,
        height: 50,
        padding: 10,
    },
    card: {
        backgroundColor: "#efefefef",
    },
    cardContainer: {
        height: 50,
        marginVertical: 30,
    },
    buyButton: {
        backgroundColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
    },
    textButton: {
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        color: '#007DFF'
    },
    selectButton: {
        borderColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        borderWidth: 2,
    },
    boldText: {
        fontSize: 17,
        fontWeight: '700'
    },
    buttonView: {
        flexDirection: "row",
        flex: 1,
        height: 150,
    }
});
