import React, { useState } from 'react';
import { ActivityIndicator, Text, Pressable, View } from 'react-native';
import { shallowEqual } from 'react-redux';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import FormEntry from '../../components/FormEntryNew';
import i18n, { tokens } from '../../i18n';
import { queryReservationsForStats } from '../../store/actions/actionsReservationsNew';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { shareExcel } from '../../utilities/exportXlsx';
import { globalStyleSheet } from '../../utilities/globalStyles';
import styles from './styles';

type Props = {

}


function ReservationsStatisticsScreen(props: Props) {
    const dispatch = useAppDispatch()
    const queriedReservationsForStats = useAppSelector((state: RootStore) => state.reservations.queriedReservationsForStats, shallowEqual)
    const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    const [reservationForm, setReservationForm] = useState(() => {
        let currDate  = new Date
        return {
            from: new Date(currDate.getFullYear(), currDate.getMonth(),currDate.getDate(), 0, 0, 0),
            to: new Date(currDate.getFullYear(), currDate.getMonth(),currDate.getDate(), 23, 59, 59),
            season: auth.activeSeason.firebaseId,
            club: auth.selectedClub,
        }
    }
    )
    
    return (
        <View style={styles.container}>
            {/* <TopBar textBoxAction={() => {}} navigation={props.navigation}/> */}
            
            <View style={styles.listContainer}>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.longreservation.periodFrom)}
            editable={true}
            >
                <View style={{alignSelf: "flex-end"}}>
                
                <CustomDatePicker
                //containerStyle={styles.inputOk}
                fontStyle={globalStyleSheet.entityText}
                date={reservationForm.from} 
                setDate={(newDate) => {
                    //console.log('vybral jsem', newDate)
                    //setManageMarketData(oldvalue => {return {...oldvalue, dueDate: newDate}})
                    //dispatch({type: "CHANGE_RESERVATION_STAT_FORM", data: {dueDate: newDate}})
                    setReservationForm(prev => {return {...prev, from: newDate}})
                }}
                />
                </View>
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.longreservation.periodTo)}
            editable={true}
            >
                <View style={{alignSelf: "flex-end"}}>
                
                <CustomDatePicker
                //containerStyle={styles.inputOk}
                fontStyle={globalStyleSheet.entityText}
                date={reservationForm.to} 
                setDate={(newDate) => {
                    //console.log('vybral jsem', newDate)
                    //setManageMarketData(oldvalue => {return {...oldvalue, dueDate: newDate}})
                    //dispatch({type: "CHANGE_RESERVATION_STAT_FORM", data: {dueDate: newDate}})
                    setReservationForm(prev => {return {...prev, to: newDate}})
                }}
                />
                </View>
            </FormEntry>
            <View
                style={{
                    width: "100%",
                    alignItems: "center",
                }}
                >
                {queriedReservationsForStats.isLoaded?
                <>
                <Pressable
                    style={globalStyleSheet.button}
                    onPress={() => {
                        dispatch(queryReservationsForStats(reservationForm, () => {

                        }))
                    }}
                    >
                        <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.buttonText}>{"Query"}</Text>
                    </Pressable>

                    <Text maxFontSizeMultiplier={1.2}>{`Number of reservations: ${queriedReservationsForStats.reservations.length}`}</Text>
                    {queriedReservationsForStats.reservations.length > 0 ?
                    <Pressable
                    style={globalStyleSheet.button}
                    onPress={() => {
                        shareExcel(queriedReservationsForStats.reservations, "Reservations")
                    }}
                    >
                        <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.buttonText}>{"Export"}</Text>
                    </Pressable>
                    :null}
                    </>
                :<ActivityIndicator size={"small"}/>}
                    
                </View>
            </View>
        </View>
    )
}


export default ReservationsStatisticsScreen



