import { GameTypes, MarketStatuses, MarketTypes, MembershipTypes, NewsTypes, NotificationTypes, PaymentModes, PaymentTransactionStatuses, RoleTypes } from '../../functions/src/interfaces';
import { AppText, RegistrationScreen, LoginScreen, Tabs, Courts, Seasons, Weekdays, ReservationsScreen, ManageReservationScreen, GameSetting, UnverifiedScreen, ContactsScreen, ProfileScreen, LongReservationScreen, Reccurency, OpenGameScreen, MarketScreen, NewsScreen, Messages, NewsEntity, AppliedPlayers, RightMenu, ManageMarketScreen, IndexScreen, Alerts, CameraScreen, ResultScore, RankingEntry, RankingScreen, CheckoutScreen, PaymentState, CheckoutItemsTypes, PaymentScreen, MarketCommons, SubscriptionScreen, ManageClubScreen, ManageSeasonScreen, ManageMyClubsScreen, CreditScreen, NewsFilters, Functions, ManageLinkedUsersScreen, LinkedUsersScreen, ManageAllClubsScreen, ReservationFilters, AvatarComponent, CheckoutListScreen, ReservationStatisticsScreen, AboutAppScreen, ButtonCaptions, ProductPaymentDescriptions } from './keys';

export const britishEnglish = {
  [AppText.Hello]: 'Hello there',
  [AppText.Introduction]: 'General Kenobi. Welcome to this example app',
  [AppText.Body]: 'This serves the purpose of demonstrating localisation',
  [RegistrationScreen.personalHeading]: 'Personal info',
  [RegistrationScreen.passwordsHeading]: 'Passwords',
  [RegistrationScreen.addressHeading]: 'Address',
  [RegistrationScreen.address]: 'Street and no.',
  [RegistrationScreen.city]: 'City',
  [RegistrationScreen.confirmPassword]: 'Confirm password',
  [RegistrationScreen.confirmPassword_validity]: 'Passwords must match',
  [RegistrationScreen.createAccountButtonCaption]: 'Create account',
  [RegistrationScreen.dateOfBirth]: 'Date of birth',
  [RegistrationScreen.contactsHeading]: 'Contacts',
  [RegistrationScreen.email]: 'E-mail',
  [RegistrationScreen.email_validity]: 'E-mail format email@domain.dsn',
  [RegistrationScreen.emailConfirm]: 'E-mail again',
  [RegistrationScreen.emailConfirm_validity]: 'E-mailové adresses do not match',
  [RegistrationScreen.family_name]: 'First name',
  [RegistrationScreen.family_name_validity]: 'Minimal number of characters is 2',
  [RegistrationScreen.first_name]: 'Family name',
  [RegistrationScreen.first_name_validity]: 'Minimal number of characters is 2',
  [RegistrationScreen.password]: 'Password',
  [RegistrationScreen.password_validity]: 'Minimal number of characters is 6',
  [RegistrationScreen.phone]: 'Phone',
  [RegistrationScreen.phone_validity]: 'Must have 9 digit values',
  [RegistrationScreen.registrationTitle]: 'Registration',
  [RegistrationScreen.termsAndConditions]: 'Consent',
  [RegistrationScreen.termsAndConditionsError]: 'Must consent with privacy policy',
  [RegistrationScreen.registrationButtonsClub]: 'Club',
  [RegistrationScreen.registrationButtonsUser]: 'User',
  [RegistrationScreen.registrationClubName]: 'Club name',
  [RegistrationScreen.registerClubTrialMessage]: 'After registration 30day trial period will start. The end of trial period is visible on club profile under active until.\n\n After registration please use club settings to add courts and create season. If you need support feel free to contact administrator.\n\nPhone: 724720690\nEmail: mirapolacek@gmail.com',
  [RegistrationScreen.registerPrivaciPolicy]: 'Privacy policy',
  [RegistrationScreen.registerUserClubsValidity]: 'Please select at least one club',
  [LoginScreen.title]: 'Login',
  [LoginScreen.email]: 'E-mail',
  [LoginScreen.password]: 'Password',
  [LoginScreen.submit]: 'Submit',
  [LoginScreen.noAccQuestion]: "Don't have account? ",
  [LoginScreen.forgotPassQuestion]: 'Forgot your password? ',
  [LoginScreen.registerButtonCaption]: 'Register',
  [LoginScreen.sendPasswordButton]: 'Send',
  [LoginScreen.emailNotValid]: 'E-mail not valid ',
  [LoginScreen.resetEmailSendingError]: 'Error sending password reset email. ',
  [LoginScreen.loginError]: 'Error logging in, check email and password. ',
  [LoginScreen.emailSent]: 'E-mail sent ',
  [LoginScreen.userNotFound]: 'User not foundt ',
  [LoginScreen.wrongPassword]: 'Wrong password ',
  [Tabs.reservations]: 'Reservations',
  [Tabs.login]: 'Login',
  [Tabs.contacts]: 'Contacts',
  [Tabs.market]: 'Requests',
  [Tabs.ranking]: 'Ranking',
  [Tabs.news]: 'News',
  [Courts.clay]: 'Clay court',
  [Courts.hard_court]: 'Hard surface court',
  [Seasons.summer]: 'Summer season',
  [Seasons.winter]: 'Winter season',
  [Weekdays.monday]: 'Monday',
  [Weekdays.tuesday]: 'Tuesday',
  [Weekdays.wednesday]: 'Wednesday',
  [Weekdays.thursday]: 'Thursday',
  [Weekdays.friday]: 'Friday',
  [Weekdays.saturday]: 'Saturday',
  [Weekdays.sunday]: 'Sunday',
  [ReservationsScreen.court]: 'Court',
  [ReservationsScreen.notLoggedInWarning]: 'You are not logged in, please contact manager.',
  [ReservationsScreen.notSelectedWarning]: 'At least hour on schedule must be selected. First tap on the beginning and second select the end of requested period.',
  [ReservationsScreen.alertTitle]: 'Important',
  [ReservationsScreen.noSeasonAvailable]: 'The season is not selected or not active in this date',
  [ReservationsScreen.noSeasonHeader]: 'No season',
  [ReservationsScreen.seasonsList]: 'Future season list',
  [ReservationsScreen.noFutureSeasonsMessage]: 'After this date there is no registered season available, either your date is far in future or contact the administrator.',
  [ReservationsScreen.courtIsLocked]: 'Court is locked',
  [ManageReservationScreen.manageReservationHeading]: 'Detail',
  [ManageReservationScreen.manageReservationTitle]: 'Reservation detail',
  [ManageReservationScreen.reservationAuthor]: 'Orderer',
  [ManageReservationScreen.reservationDate]: 'Date',
  [ManageReservationScreen.reservationTimeFrom]: 'Begining',
  [ManageReservationScreen.reservationTimeTo]: 'End',
  [ManageReservationScreen.reservationTypeAndPlayersHeading]: 'Players and game setting',
  [ManageReservationScreen.reservationOpenGameCaption]: 'Missing a player?',
  [ManageReservationScreen.reservationOpenGameLink]: 'here',
  [ManageReservationScreen.reservationLongreservationCaption]: 'Show longtime reservation ',
  [ManageReservationScreen.reservationLongreservationLink]: 'here',
  [ManageReservationScreen.reservationSaveButton]: 'Save',
  [ManageReservationScreen.reservationEditButton]: 'Edit',
  [ManageReservationScreen.reservationApproveButton]: 'Approve',
  [ManageReservationScreen.reservationDeclineButton]: 'Remove',
  [ManageReservationScreen.reservationIsOpenMessage]: 'Reservation is open, to edit follow link below',
  [ManageReservationScreen.reservationIsOpenCaption]: 'Continue to your open reservation ',
  [ManageReservationScreen.reservationIsHistoryMessage]: 'Reservation hes elapsed and cannot be edited',
  [ManageReservationScreen.reservationOnBehalf]: 'On behalf of',
  [ManageReservationScreen.reservationOnBehalfRegistered]: 'Registered player?',
  [ManageReservationScreen.timingHeading]: 'Timing',
  [ManageReservationScreen.reservationPriceHeading]: 'Price',
  [ManageReservationScreen.reservationAddToCalendar]: 'Add to calendar',
  [ManageReservationScreen.reservationRequestor]: 'Requestor',
  [ManageReservationScreen.reservationRequestIdLink]: 'Request ID',
  [ManageReservationScreen.reservationPriceState]: 'Payment state',
  [ManageReservationScreen.reservationPaidInfo]: 'Paid',
  [ManageReservationScreen.reservationStatus]: 'State', 
  [GameSetting.gameSettingEmpty]: 'Add player',
  [GameSetting.gameSettingGameType]: 'Game type',
  [GameSetting.gameSettingPlayers]: 'Players',
  [GameSetting.gameSettingRemPlayers]: 'Additional players',
  [GameSetting.onSelectedMessage]: 'Tapping on other player will switch them',
  [GameSetting.notifyPlayers]: 'Send invites to players',
  [GameSetting.gameSettingsAgainst]: 'versus',
  [GameSetting.gameSettingsApplied]: 'Applied players',
  [GameSetting.gameSettingsAddSelf]: 'Apply self',
  [GameSetting.gameSettingsRemoveSelf]: 'Withdraw',
  [GameTypes.double]: 'Doubles',
  [GameTypes.single]: 'Singles',
  [GameTypes.group]: 'Unspecified',
  [GameTypes.training]: 'Practice',
  [GameTypes.withMember]: 'With member',
  [GameTypes.withoutMember]: 'With nonmember',
  [UnverifiedScreen.unverifiedText]: 'You are signed in using {{email}} but you have not verified it using link sent to your email box. Please check it and veryfy it. ',
  [UnverifiedScreen.resendMessage]: 'If you have trouble finding it please resend a new verification email ',
  [UnverifiedScreen.logoutMessage]: 'If you wish to sign to different account, logout first ',
  [UnverifiedScreen.resedVerifEmail]: 'here',
  [UnverifiedScreen.logout]: 'here',
  [ContactsScreen.selectButton]: 'Select',
  [ContactsScreen.contactsMyList]: 'My list',
  [ContactsScreen.contactsAllClubList]: 'All registered',
  [ContactsScreen.contactsAllMembList]: 'All members',
  [ContactsScreen.contactsScreenTitle]: 'Contacts',
  [ContactsScreen.noContactsYetMessage]: 'There are no contacts yet',
  [ContactsScreen.contactsScreenMyContacts]: 'My contacts',
  [ContactsScreen.contactsClubmembersTitle]: 'Members',
  [ContactsScreen.contactsOtherTitle]: 'Other contacts',
  [ContactsScreen.contactsNotMember]: 'Not member',
  [ContactsScreen.contactsManagedBy]: 'Managed by: ',
  [ContactsScreen.contactsSearch]: 'Search',
  [ProductPaymentDescriptions.credit]: 'Payment for credit in club',
  [ProductPaymentDescriptions.subscription]: 'Payment for membership in club',
  [ProductPaymentDescriptions.reservation]: 'Payment for court time in club',
  [ProfileScreen.profileAddToContactsButton]: 'Add to contacts',
  [ProfileScreen.profileRemToContactsButton]: 'Remove from contacts',
  [ProfileScreen.profileEditButton]: 'Edit profile',
  [ProfileScreen.profileFirstName]: 'First name',
  [ProfileScreen.profileFamilyName]: 'Family name',
  [ProfileScreen.profileBirthDate]: 'Date of birth',
  [ProfileScreen.profileEmail]: 'E-mail',
  [ProfileScreen.profilePhone]: 'Phone number',
  [ProfileScreen.profileAddress]: 'Address',
  [ProfileScreen.profileICO]: 'IČO',
  [ProfileScreen.profileDIC]: 'VAT ID',
  [ProfileScreen.profileCity]: 'City',
  [ProfileScreen.profileScreenTitle]: 'Profile',
  [ProfileScreen.profileDelete]: 'Delete account',
  [ProfileScreen.profileDeletionOK]: 'Account deletion successful',
  [ProfileScreen.profileDeletionKO]: 'Account deletion failed',
  [ProfileScreen.profileDeletionConfirmationQuestion]: 'Are you sure you want to delete your account? This action is irreversible abd all data will be lost. New registration will not renew this account.',
  [ProfileScreen.profileDeleteCancel]: 'Cancel',
  [ProfileScreen.profileLast10games]: 'Games - last 10',
  [ProfileScreen.profileDetail]: 'Detail',
  [ProfileScreen.profileSaveButton]: 'Save',
  [ProfileScreen.profileRoles]: 'Roles',
  [ProfileScreen.profileMembershipTitle]: 'Membership',
  [ProfileScreen.profileMembershipFrom]: 'Start',
  [ProfileScreen.profileMembershipTo]: 'End',
  [ProfileScreen.profileAddSubscription]: 'Add membership',
  [ProfileScreen.profileSubscriptionNA]: 'Not member',
  [ProfileScreen.profileCheckoutsButton]: 'Checkouts',
  [ProfileScreen.profileModRolesButton]: 'Modify roles',
  [ProfileScreen.profileClubHomepage]: 'Homepage URL',
  [ProfileScreen.profileClubVOP]: 'URL VOP',
  [LongReservationScreen.addButton]: 'Add',
  [LongReservationScreen.additionalHeading]: 'Additional info',
  [LongReservationScreen.approveButton]: 'Approve',
  [LongReservationScreen.collisions]: 'Collisions',
  [LongReservationScreen.court]: 'Court',
  [LongReservationScreen.errorCourtNotSelected]: 'Court not selected',
  [LongReservationScreen.errorLongDateFuture]: 'Date has to be in future',
  [LongReservationScreen.errorLongDateOOB]: 'Date has to be in season period.',
  [LongReservationScreen.errorSeasonNTBSelected]: 'Season has to be selected',
  [LongReservationScreen.errorTimeNotDivisible]: 'Time has to be in 30 min intervals',
  [LongReservationScreen.errorTimeNotOpenHours]: 'Time has to be within opening hours',
  [LongReservationScreen.errorTimeToLTTimeFrom]: 'Time to has to be greater then time from',
  [LongReservationScreen.errorNotWIPlanPer]: 'Not within planning or season range',
  [LongReservationScreen.messageHeading]: 'Message',
  [LongReservationScreen.messagePlaceholder]: 'Message',
  [LongReservationScreen.modifyButton]: 'Save',
  [LongReservationScreen.periodFrom]: 'From',
  [LongReservationScreen.periodTo]: 'To',
  [LongReservationScreen.periodHeading]: 'Period',
  [LongReservationScreen.reccurency]: 'Reccurency',
  [LongReservationScreen.removeButton]: 'Remove',
  [LongReservationScreen.requestExpires]: 'Request expiry',
  [LongReservationScreen.requestDate]: 'Request date',
  [LongReservationScreen.season]: 'Season',
  [LongReservationScreen.timeEnd]: 'Time from',
  [LongReservationScreen.timeStart]: 'Time to',
  [LongReservationScreen.timingHeading]: 'Timing',
  [LongReservationScreen.viewButton]: 'View',
  [LongReservationScreen.weekday]: 'Weekday',
  [LongReservationScreen.periodMessage]: "This is a request for longterm reservation for season {{season}} in range {{from}} to {{to}} every {{weekday}} with {{recur}} reccurency. ",
  [LongReservationScreen.timingMessage]: "The requeste time range is from {{timeFrom}} to {{timeTo}} on court {{court}}. ",
  [LongReservationScreen.childReservations]: "Reservations",
  [LongReservationScreen.longResFuture]: "Future",
  [LongReservationScreen.longResAll]: "All",
  [LongReservationScreen.longResDialogRemoval]: "Do you want to remove only future or all reservations?",
  [Reccurency.weekly]: 'Weekly',
  [OpenGameScreen.addButton]: 'Add',
  [OpenGameScreen.approveButton]: 'Approve',
  [OpenGameScreen.commentButton]: 'Comment',
  [OpenGameScreen.sendButton]: 'Send',
  [OpenGameScreen.commentsHeading]: 'Comments',
  [OpenGameScreen.connectWithResButton]: 'Connect with reservation',
  [OpenGameScreen.createReservationButton]: 'Create reservation',
  [OpenGameScreen.dateTimeHeading]: 'Scheduled term',
  [OpenGameScreen.errorNeedToBeFuture]: 'Date has to be in future',
  [OpenGameScreen.errorNotInOpeningHours]: 'Time needs to be within openning hours', 
  [OpenGameScreen.errorTooFarAway]: 'Can be planned in up to 14 days',
  [OpenGameScreen.errorNoSeason]: 'No active season at this period',
  [OpenGameScreen.gameSettingsHeading]: 'Game settings',
  [OpenGameScreen.messageHeading]: 'Message',
  [OpenGameScreen.messagePlaceholder]: 'Message',
  [OpenGameScreen.modifyButton]: 'Modify',
  [OpenGameScreen.noReservationConnectedMessage]: 'Opened game is not connected with any reservation, scheduled term can vary. ',
  [OpenGameScreen.removeButton]: 'Remove',
  [OpenGameScreen.requestExpires]: 'Date',
  [OpenGameScreen.reservationConnectedMessage]: 'Scheduled term is locked to reservation. ',
  [OpenGameScreen.termOfGameMessage]: 'It is scheduled to ',
  [OpenGameScreen.timeEnd]: 'To',
  [OpenGameScreen.timeStart]: 'From',
  [OpenGameScreen.noMessageMessage]: 'No message in the request',
  [OpenGameScreen.opengameDeclineButton]: 'Decline',
  [ManageMarketScreen.manageMarketTitle]: 'Request',
  [ManageMarketScreen.manageMarketStatus]: 'Request status',
  [MarketScreen.addMyselfLink]: 'Apply self',
  [MarketScreen.filterMyRequests]: 'My requests',
  [MarketScreen.freeSpotsMessage]: 'Number of free {{number}} spots',
  [MarketScreen.periodHeading]: 'Period',
  [MarketScreen.reccurencyMessage]: 'Each ',
  [MarketScreen.allClubs]: 'All clubs',
  [MarketTypes.opengame]: 'Player search',
  [MarketTypes.longreservation]: 'Longterm reservation',
  [MarketTypes.subscription]: 'Membership',
  [MarketTypes.reservation]: 'Reservation',
  [MarketTypes.credit]: 'Credit',
  [NewsScreen.expirationDate]: 'Expiry date',
  [NewsScreen.includeApplicationList]: 'Include application list',
  [NewsScreen.notifyAllClubmembers]: 'Notify all members',
  [NewsScreen.saveButton]: 'Save',
  [NewsScreen.removeButton]: 'Remove',
  [NewsScreen.messagePlaceholder]: 'Message',
  [NewsScreen.newsScreenTitle]: 'News', 
  [NewsScreen.newsType]: 'News type',
  [NewsTypes.information]: 'Informative',
  [NewsTypes.tournament]: 'Tournament',
  [Messages.collapseCaption]: 'Collapse',
  [Messages.messagesHeading]: 'Comments',
  [Messages.viewAllCaption]: 'Show {{number}} comments',
  [NewsEntity.applyButton]: 'Apply self',
  [NewsEntity.unapplyButton]: 'Unapply',
  [NewsEntity.applyHeading]: 'List',
  [AppliedPlayers.applicationsHeading]: 'List of applied',
  [RightMenu.contactsRoute]: 'Contacts',
  [RightMenu.homeRoute]: 'Home',
  [RightMenu.logoutRoute]: 'Logout',
  [RightMenu.profileRoute]: 'Profile',
  [RightMenu.checkoutsRoute]: 'My checkouts',
  [RightMenu.statisticsRoute]: 'Statistics',
  [RightMenu.editClubRoute]: 'Manage club',
  [RightMenu.myClubsRoute]: 'Manage my clubs',
  [RightMenu.notificationsRoute]: 'Notifications',
  [IndexScreen.clubPlaceholder]: 'Select club',
  [Alerts.saved]: 'Saved',
  [Alerts.savedMessage]: 'Changes were saved...',
  [CameraScreen.cameraFlip]: 'Flip',
  [CameraScreen.cameraGallery]: 'Gallery',
  [CameraScreen.cameraTitle]: 'Camera',
  [CameraScreen.cameraUpload]: 'Upload',
  [ResultScore.addSet]: 'Add set',
  [ResultScore.removeSet]: 'Remove set',
  [ResultScore.rankedGame]: 'Ranked game',
  [ResultScore.resultCaption]: 'Game result',
  [ResultScore.saveResult]: 'Save result',
  [ResultScore.resultScoreResult]: "Result score",
  [RankingEntry.losses]: 'Losses',
  [RankingEntry.points]: 'Points',
  [RankingEntry.wins]: 'Wins',
  [RankingScreen.rankingAddButton]: 'Participate',
  [CheckoutScreen.requiresPaymentText]: 'Upon approval it is necessary to authorise payment. In case of approval club will be able to charge the payment, otherwise authorised amount will be released.',
  [CheckoutScreen.requiresRefundText]: 'Prior to removal, please refund payment first',
  [CheckoutScreen.checkoutNotification]: 'Warning',
  [CheckoutScreen.checkoutAcceptPayment]: 'Accept payment',
  [CheckoutScreen.checkoutProceedToPayment]: 'To payment',
  [CheckoutScreen.checkoutPaidAmt]: 'Paid amount',
  [CheckoutScreen.checkoutCapturableAmt]: 'Amount capturable',
  [CheckoutScreen.checkoutSubtotal]: 'Total',
  [CheckoutScreen.checkoutSummary]: 'Summaty',
  [CheckoutScreen.checkoutTitle]: 'Checkout',
  [CheckoutScreen.checkoutPaymentsList]: 'Payments history',
  [CheckoutScreen.checkoutPromisePayLater]: 'Pay later',
  [CheckoutScreen.checkoutVOP]: 'Trading conditions',
  [PaymentState.paymentStateBilled]: 'Not paid',
  [PaymentState.paymentStateEstimate]: 'Estimated',
  [PaymentState.paymentStateFullyCharged]: 'Fully authorised',
  [PaymentState.paymentStateFullyPaid]: 'Fully paid',
  [PaymentState.paymentStateOverCharged]: 'Excess authorisation',
  [PaymentState.paymentStateOverPaid]: 'Overpaid',
  [PaymentState.paymentStatePartlyCharged]: 'Partly authorised',
  [PaymentState.paymentStatePartlyPaid]: 'Partly paid',
  [PaymentState.paymentStateManual]: 'Custom input',
  [MarketStatuses.approved]: 'Approved',
  [MarketStatuses.blocked]: 'In approval',
  [MarketStatuses.closed]: 'Closed',
  [MarketStatuses.declined]: 'Declined',
  [MarketStatuses.deleted]: 'Deleted',
  [MarketStatuses.open]: 'Created',
  [MarketStatuses.new]: 'New',
  [CheckoutItemsTypes.fee]: 'Processing fee',
  [CheckoutItemsTypes.reservationuser]: 'Player share',
  [CheckoutItemsTypes.yearSubscription]: 'Yearly subscrbtion',
  [CheckoutItemsTypes.credit]: 'Credit',
  [PaymentScreen.paymentChangePaymentMode]: 'Change',
  [PaymentScreen.paymentFreeCredit]: 'Free credit',
  [PaymentScreen.paymentPaymentMode]: 'Payment mode',
  [PaymentScreen.paymentTotalForPayment]: 'Total',
  [PaymentScreen.paymentTotalFromCredit]: 'From credit',
  [PaymentScreen.paymentTotalToCredit]: 'To creditu',
  [PaymentScreen.paymentPrevCreditValue]: 'Prev. credit balance',
  [PaymentScreen.paymentNewCreditValue]: 'New credit balance',
  [PaymentScreen.paymentPaidValue]: 'Paid amount',
  [PaymentScreen.paymentCapturableValue]: 'Authorized amount',
  [PaymentScreen.paymentRefundedValue]: 'Refunded amount',
  [PaymentScreen.paymentDetail]: 'Payment detail',
  [PaymentScreen.paymentTransactionHeading]: 'Transaction',
  [PaymentScreen.paymentState]: 'State',
  [PaymentScreen.paymentID]: 'ID',
  [PaymentScreen.paymentButtonPay]: 'Pay',
  [PaymentScreen.paymentCancelButton]: 'Cancel',
  [PaymentScreen.paymentCloseButton]: 'Close',
  [PaymentScreen.paymentChargeButton]: 'Charge',
  [PaymentScreen.paymentPrintButton]: 'Print',
  [PaymentScreen.paymentReinitButton]: 'Reinitialize',
  [PaymentScreen.paymentTitle]: 'Platba',
  [PaymentScreen.paymentMethodText]: "Choose one of payment methods. If you desire to pay using cash, then contact with administrator.",
  [PaymentScreen.paymentFinishButton]: "Finalize",
  [PaymentModes.paymentModeCard]: 'Online payment',
  [PaymentModes.paymentModePayU]: 'Online payment',
  [PaymentModes.paymentModeCredit]: 'Credit',
  [PaymentModes.paymentModeCash]: 'Cash',
  [PaymentModes.paymentModeNone]: 'None',
  [PaymentTransactionStatuses.amount_capturable_updated]: 'Capturable',
  [PaymentTransactionStatuses.cancelled]: 'Canceled',
  [PaymentTransactionStatuses.refunded]: 'Refunded',
  [PaymentTransactionStatuses.refunding]: 'Refunding',
  [PaymentTransactionStatuses.initiated]: 'Initiated',
  [PaymentTransactionStatuses.succeeded]: 'Completed',
  [PaymentTransactionStatuses.capturing]: 'In completion',
  [MarketCommons.marketCommonsCheckoutID]: 'ID Objednávky',
  [MarketCommons.marketCommonsPrevMarketID]: 'Previous request',
  [SubscriptionScreen.subscriptionCurrEnds]: 'Current ends',
  [SubscriptionScreen.subscriptionEnd]: 'End',
  [SubscriptionScreen.subscriptionMembership]: 'Membership',
  [SubscriptionScreen.subscriptionScreenTitle]: 'Membership',
  [SubscriptionScreen.subscriptionStart]: 'Starts',
  [SubscriptionScreen.subscriptionType]: 'Membership type',
  [SubscriptionScreen.subscriptionPriceVariant]: 'Variant',
  [CreditScreen.creditAmount]: 'Credit amount',
  [CreditScreen.creditClub]: 'Club',
  [CreditScreen.creditHeading]: 'Credit detail',
  [CreditScreen.creditCurrentValue]: 'Current amount',
  [CreditScreen.creditScreenTitle]: 'Credit request',
  [RoleTypes.isAdmin]: 'Admin',
  [RoleTypes.isGod]: 'God',
  [RoleTypes.isCoach]: 'Coach',
  [RoleTypes.isClubRegistered]: 'Registered',
  [RoleTypes.isMember]: 'Member',
  [RoleTypes.all]: 'Without role',
  [RoleTypes.isTester]: 'Tester', 
  [RoleTypes.isChild]: 'Child', 
  [RoleTypes.isPensioner]: 'Pensioner', 
  [ManageClubScreen.manageClubAddCourtButton]: "Add court",
  [ManageClubScreen.manageClubAddSeasonButton]: "Add season",
  [ManageClubScreen.manageClubClubName]: "Club name",
  [ManageClubScreen.manageClubCourtId]: "Court ID",
  [ManageClubScreen.manageClubCourtType]: "Court type",
  [ManageClubScreen.manageClubCourts]: "Fields",
  [ManageClubScreen.manageClubProductsHeading]: "Memberships",
  [ManageClubScreen.manageClubSaveButton]: "Save",
  [ManageClubScreen.manageClubSeasonsHeading]: "Seasons",
  [ManageClubScreen.manageClubScreenTitle]: "Manage club",
  [ManageClubScreen.manageClubPaymentsHeading]: "Payments",
  [ManageClubScreen.manageClubPaymentsCurr]: "Currency",
  [ManageClubScreen.manageClubAllowedGameTypes]: "Allowed game types",
  [ManageClubScreen.manageClubActiveUntil]: "Active until",
  [ManageClubScreen.manageClubPaymentsPromiseAllowed]: "Payment promise",
  [ManageClubScreen.manageClubAutoCapture]: "Automatically capture authorised funds",
  [ManageClubScreen.manageClubPayUId]: "PAYU gate ID",
  [ManageClubScreen.manageClubTourPrice]: "Membership price",
  [ManageClubScreen.manageClubAgeRoles]: "Roles by age",
  [ManageClubScreen.manageClubGeneralInfo]: "Basic information",
  [ManageClubScreen.manageClubQRIBAN]: "QR IBAN",
  [ManageClubScreen.manageClubCreditPaymentsAllowed]: "Allow payments via credit",
  [ManageClubScreen.manageClubQRPaymentsAllowed]: "Allow QR payments",
  [ManageClubScreen.manageClubPaymentsSetting]: "Payments settings",
  [ManageSeasonScreen.manageSeasonSaveButton]: 'Save',
  [ManageSeasonScreen.manageSeasonSeasonType]: 'Season type',
  [ManageSeasonScreen.manageSeasonTour]: 'Tour',
  [ManageSeasonScreen.manageSeasonPlanFrom]: 'Plan from',
  [ManageSeasonScreen.manageSeasonPlanTo]: 'Plan to',
  [ManageSeasonScreen.manageSeasonPlanningFinished]: 'Planning finished',
  [ManageSeasonScreen.manageSeasonLockedFrom]: 'Locked from',
  [ManageSeasonScreen.manageSeasonLockedTo]: 'Locked to',
  [ManageSeasonScreen.manageSeasonFrom]: 'From',
  [ManageSeasonScreen.manageSeasonTo]: 'To',
  [ManageSeasonScreen.manageSeasonOpenFrom]: 'Open from',
  [ManageSeasonScreen.manageSeasonOpenTo]: 'Open to',
  [ManageSeasonScreen.manageSeasonCourtsHeading]: 'Fields',
  [ManageSeasonScreen.manageSeasonSeasonHeading]: 'Season: {{id}}',
  [ManageSeasonScreen.manageSeasonButtonsBasic]: 'Basic',
  [ManageSeasonScreen.manageSeasonButtonsCourts]: 'Fields',
  [ManageSeasonScreen.manageSeasonButtonsPrices]: 'Prices',
  [ManageSeasonScreen.manageSeasonButtonsPricesAdd]: 'Add pricelist',
  [ManageSeasonScreen.manageSeasonUnlockDate]: 'Unlock date: ',
  [ManageSeasonScreen.manageSeasonPaid]: 'Paid',
  [ManageSeasonScreen.manageSeasonPromised]: 'Payment promised',
  [ManageSeasonScreen.manageSeasonScreenTitle]: "Manage season",
  [ManageMyClubsScreen.myClubsAddButton]: 'Add',
  [ManageMyClubsScreen.myClubsAddClubSubscription]: 'Add to subscribed clubs',
  [ManageMyClubsScreen.myClubsMyClubsHeading]: 'My clubs',
  [ManageMyClubsScreen.myClubsRemoveButton]: 'Remove',
  [ManageMyClubsScreen.myClubsScreenTitle]: 'My clubs',
  [ManageMyClubsScreen.myClubsSelectClub]: 'Select club',
  [ManageMyClubsScreen.myClubsMessage]: 'Select your favourite clubs. You can subscribe for news feed, see requests for all clubs (e.g. player search) and of course see reservations timetable. \n\nTo switch to other team long press on it. ',
  [ManageClubScreen.manageClubOnlinePaymentsAllowed]: "Allow online payments",
  [NewsFilters.allClubs]: 'All clubs',
  [NewsFilters.informations]: 'Informative',
  [NewsFilters.tournaments]: 'Tournaments',
  [NotificationTypes.market]: 'Requests',
  [NotificationTypes.news]: 'News',
  [NotificationTypes.payments]: 'Payments',
  [NotificationTypes.reservation]: 'Reservation',
  [Functions.undefined]: "Not defined",
  [Functions.notSelected]: "Not selected",
  [MembershipTypes.yearSubscription]: "Yearly (365days)",
  [MembershipTypes.tourSubscription]: "Tour (year)",
  [ManageLinkedUsersScreen.manageLinkedUserTitle]: "Linked user management",
  [LinkedUsersScreen.linkedUserTitle]: "Managed linked users",
  [LinkedUsersScreen.linkedUserMessage]: "Here you can maintain accounts that you have right to. Ideally for children etc.",
  [ManageAllClubsScreen.allClubsScreenTitle]: 'Manage all clubs',
  [ReservationFilters.openState]: 'Planned',
  [AvatarComponent.avatarComponentUnknown]: 'Unknown',
  [CheckoutListScreen.checkoutListScreenTitle]: 'Checkout list',
  [ReservationStatisticsScreen.reservationStatisticsScreenTitle]: 'Statistics',
  [AboutAppScreen.aboutAppVersion]: 'Version',
  [AboutAppScreen.aboutContactMail]: 'Email',
  [AboutAppScreen.aboutContactPhone]: 'Phone',
  [AboutAppScreen.aboutDeveloper]: 'Developer',
  [AboutAppScreen.aboutScreenTitle]: 'About app',
  [AboutAppScreen.aboutScreenUpdateId]: 'Update ID',
  [ReservationsScreen.reservationBlockedTime]: 'Out of order',
  [ButtonCaptions.buttonsAddButton]: 'Add',
  [ButtonCaptions.buttonsRemoveButton]: 'Remove',
}