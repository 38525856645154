import React from "react";
import { Switch, TextInput, View } from "react-native";
import { Club } from "../../../../functions/src/interfaces";
import FormEntry from "../../../components/FormEntryNew";
import FormHeading from "../../../components/FormHeading";
import i18n, { tokens } from "../../../i18n";
import { roleTypes } from "../../../utilities/constants";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { StateLoader } from "../../../utilities/interfaces";
import { AuthActions } from "../../../store/reducers/authReducer";

type Props = {
    club: StateLoader<Club>
    dispatchChange: (data: any) => void
}
function AllowPaymentsComponent(props: Props):JSX.Element {
    return (
        <View
        >
            <FormHeading
            heading={i18n.t(tokens.app.screens.manageClub.manageClubOnlinePaymentsAllowed)}
            />
            <FormEntry
            caption={i18n.t(tokens.app.screens.manageClub.manageClubOnlinePaymentsAllowed)}
            editable={true}
            >
                <Switch
                value={props.club?.data?.onlinePayments?.allowed || false}
                onValueChange={(value) => props.dispatchChange(
                    {
                        onlinePayments: {
                            ...props.club?.data?.onlinePayments,
                            allowed: value
                        }
                    }
                )}
                />
            </FormEntry>
            {props.club.data?.onlinePayments?.allowed?
            <View>
                <FormEntry
                caption={i18n.t(tokens.app.screens.manageClub.manageClubPayUId)}
                editable={true}
                >
                    <TextInput maxFontSizeMultiplier={1.2}
                        style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                        placeholder={i18n.t(tokens.app.screens.manageClub.manageClubPayUId)}
                        placeholderTextColor="#aaaaaa"
                        onChangeText={(text) => {
                            props.dispatchChange({payUId: text})
                        }}
                        value={props.club?.data.payUId || ""}
                        underlineColorAndroid="transparent"
                        //autoCapitalize="words"
                        inputMode="numeric"
                        //id="club_name"
                    />
                </FormEntry>
                {Object.entries(roleTypes).map(([key, objValue]) => {
                    return (<FormEntry
                    caption={objValue}
                    editable={true}
                    key={key}
                    >
                        <Switch
                        value={props.club?.data?.onlinePayments?.roles?.[key] || false}
                        onValueChange={(value) => props.dispatchChange(
                            {
                                onlinePayments: {
                                    ...props.club?.data?.onlinePayments,
                                    roles: {
                                        ...props.club?.data?.onlinePayments?.roles,
                                        [key]: value
                                    }
                                }
                            }
                        )}
                        />
                    </FormEntry>)
                })}
            </View>
            :null}
            <FormHeading
            heading={i18n.t(tokens.app.screens.manageClub.manageClubQRPaymentsAllowed)}
            />
            <FormEntry
            caption={i18n.t(tokens.app.screens.manageClub.manageClubQRPaymentsAllowed)}
            editable={true}
            >
                <Switch
                value={props.club?.data?.qrPayments?.allowed || false}
                onValueChange={(value) => props.dispatchChange(
                    {
                        qrPayments: {
                            ...props.club?.data?.qrPayments,
                            allowed: value
                        }
                    }
                )}
                />
            </FormEntry>
            {props.club.data?.qrPayments?.allowed?
            <View>
                <FormEntry
                caption={i18n.t(tokens.app.screens.manageClub.manageClubQRIBAN)}
                editable={true}
                >
                    <TextInput maxFontSizeMultiplier={1.2}
                        style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                        placeholder={i18n.t(tokens.app.screens.manageClub.manageClubQRIBAN)}
                        placeholderTextColor="#aaaaaa"
                        onChangeText={(text) => props.dispatchChange(
                            {
                                qrPayments: {
                                    ...props.club?.data?.qrPayments,
                                    iban: text
                                }
                            }
                        )}
                        value={props.club?.data.qrPayments?.iban || ""}
                        underlineColorAndroid="transparent"
                        //autoCapitalize="words"
                        inputMode="none"
                        //id="club_name"
                    />
                </FormEntry>
                {Object.entries(roleTypes).map(([key, objValue]) => {
                    return (<FormEntry
                    caption={objValue}
                    editable={true}
                    key={key}
                    >
                        <Switch
                        value={props.club?.data?.qrPayments?.roles?.[key] || false}
                        onValueChange={(value) => props.dispatchChange(
                            {
                                qrPayments: {
                                    ...props.club?.data?.qrPayments,
                                    roles: {
                                        ...props.club?.data?.qrPayments?.roles,
                                        [key]: value
                                    }
                                }
                            }
                        )}
                        />
                    </FormEntry>)
                })}
            </View>
            :null}
            <FormHeading
            heading={i18n.t(tokens.app.screens.manageClub.manageClubCreditPaymentsAllowed)}
            />
            <FormEntry
            caption={i18n.t(tokens.app.screens.manageClub.manageClubCreditPaymentsAllowed)}
            editable={true}
            >
                <Switch
                value={props.club?.data?.creditPayments?.allowed || false}
                onValueChange={(value) => props.dispatchChange(
                    {
                        creditPayments: {
                            ...props.club?.data?.creditPayments,
                            allowed: value
                        }
                    }
                )}
                />
            </FormEntry>
        </View>
    )
}

export default AllowPaymentsComponent