import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable, ScrollView } from 'react-native'

import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { shallowEqual } from 'react-redux';
import { Market, MarketLongreservation, MarketOpengame, MarketReservation, UserOrClub, user } from '../../../../../functions/src/interfaces';
import UserAvatar from '../../../../components/UserAvatar';
import i18n, { tokens } from '../../../../i18n';
import { useAppSelector } from '../../../../store/hooks';
import { getCurrentUserWithRoles } from '../../../../store/selectors';
import { marketTypes, weekdays } from '../../../../utilities/constants';
import { monthString, timeFormatter } from '../../../../utilities/funcs';
import { gameTypesColors, mainFont, mainFontBold } from '../../../../utilities/globalStyles';
import MarketEntryButton from '../MarketEntryButton';



type Props = {
    data: MarketLongreservation<Market>,
    users: {[userId: string]: UserOrClub}
}

const prepPlayers = (data: any ): [string[], string[], number] => {
    try {
        let totalSpots = data.gameType == "single" ? 2 : 4

        let playersInGame = [
            ...Object.values(data?.gamePlayers?.home?data.gamePlayers?.home:{}), 
            ...Object.values(data?.gamePlayers?.away?data.gamePlayers?.away:{}), 
            ...data?.players,
        ].filter(item => item != "")
        const alreadySelected = [
            ...playersInGame,
            ...data?.appliedPlayers? data?.appliedPlayers: [],
        ]
        let totalPlayers = playersInGame.length
        let freeSpots = Math.max(totalSpots - totalPlayers, 0)
        return [alreadySelected, playersInGame, freeSpots]
    } catch(err) {
        console.log('selhalo nacitani dat' + JSON.stringify(data) + ' s chybou ' + err.message)
        return [[],[],0]
    }
    
}

const MarketEntryBodyLong: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)
    
    const [alreadySelected, playersInGame, freeSpots] = prepPlayers(props.data)
    return (
        <View style={{
            flex:1,
            marginLeft: 10,
            marginRight: 10,
        }}>
            
            <View style={{flexDirection: "row", alignContent: "space-between"}}>
                <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFont}}>{i18n.t(tokens.app.screens.market.periodHeading)}</Text>
                <Text maxFontSizeMultiplier={1.2} style={{marginLeft: "auto"}}>{monthString(props.data.from) + ' - ' + monthString(props.data.to)}</Text>
            </View>
            
            {props.data.text?<Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFont}}>{props.data.text}</Text>:null}
            <ScrollView horizontal={true} style={{flexDirection: "row"}}>
            {
                    playersInGame ? playersInGame.map((user) => {
                        return (
                            <UserAvatar uid={user} size={20} key={user}/>
                        )
                    }) : null
                }
            </ScrollView>
        </View>
    )
} 
    



const styles = StyleSheet.create({
       
})


export default MarketEntryBodyLong

