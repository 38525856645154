import React from 'react'
import { StyleSheet, Text, Pressable, View } from 'react-native'


import { user } from '../../functions/src/interfaces'
import i18n, { tokens } from '../i18n'
import { useAppNavigation } from '../store/hooks'
import { UserOrClub } from '../store/reducers/usersReducer'
import { normalize } from '../utilities/constants'
import UserAvatar from './UserAvatar'

type Props = {
    item: user,
    children: any,
    linkedToUser?: UserOrClub<{}>
}

const RenderContact:React.FC<Props> = (props) => {

    const navigation = useAppNavigation()
    
    //const contactFlag = 
    //console.log('nalezeno', props)
    const getName = (user: UserOrClub<{}>) => {
        if (user.type == "user") {
            return user.family_name + ' ' + user.first_name
        } else if (user.type =="clubs") {
            return user.club_name
        } else {
            return "Error"
        }
        
        
    }
    
    
    return (
        <View style={styles.entityContainer}>
        <Pressable style={{flexDirection: "row", flex: 1}} onPress={() => {
            //console.log('Chci navigovat na: ', props.item.uid)
            navigation.navigate('Profile', {uid: props.item.uid})
            }}>
                <UserAvatar uid={props.item.uid} size={normalize(50)}/>
            
            <View style={{flexDirection: "column", marginLeft: 5, justifyContent: "center"}}>
            <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>
            {props.item ? props.item.family_name + ' ' + props.item.first_name : 'Error'}
            </Text>
            {props.item.linkedFlag == true?
            <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>
            {i18n.t(tokens.app.screens.contacts.contactsManagedBy) + (props.linkedToUser ? getName(props.linkedToUser) : 'Error')}
            </Text>
            :null}
            </View>
            
            <View style={{alignSelf: "center", marginLeft: "auto"}}>
                {props.children ? props.children : null}
            </View>
            </Pressable>
        </View>
    )
}



const styles = StyleSheet.create({
        
    entityContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        paddingBottom: 3,
        paddingTop: 3,
        backgroundColor: "white",
        marginLeft: 5,
        marginRight: 5,
        borderRadius: 5,
        alignItems: "center",
    
    },
    entityText: {
        fontSize: 16,
        color: '#333333'
    }
})

export default RenderContact

