import { Route } from "@react-navigation/native";
import React, { useEffect } from "react";
import { ActivityIndicator, View } from "react-native";
import { Season } from "../../../functions/src/interfaces";
import { useAppSelector } from "../../store/hooks";
import { RootStore } from "../../store/reducers/rootReducer";
import { globalStyleSheet } from "../../utilities/globalStyles";
import RenderSeasonComponent from "../ManageSeasonScreen/Components/SeasonComponent";
import WebWrapper from "../../components/WebWrapper";

type Props = {
    route: Route<'SeasonDetailScreen', {clubId: string, seasonId: string}>
}



function SeasonDetailScreen(props:Props) {

    const season: Season = useAppSelector((state: RootStore) => state.auth.seasons?.[props.route?.params?.seasonId], (a,b) => JSON.stringify(a) == JSON.stringify(b))

    return (
        <WebWrapper>
        <View style={globalStyleSheet.container}>
            {season? 
            <RenderSeasonComponent season={season} disabled={true}/>
            :<ActivityIndicator size={"large"}/>}
        </View>
        </WebWrapper>
    )
}

export default SeasonDetailScreen