import React from 'react';
import { DeviceEventEmitter, StyleSheet, Switch, TextInput, Pressable } from 'react-native';
import { shallowEqual } from 'react-redux';
import { Market, MarketLongreservation, MarketReservation } from '../../../../functions/src/interfaces';
import FormEntry from '../../../components/FormEntryNew';
import UserAvatar from '../../../components/UserAvatar';
import i18n, { tokens } from '../../../i18n';
import { useAppNavigation, useAppSelector } from '../../../store/hooks';
import { normalize } from '../../../utilities/constants';
import OwnerComponentBase from './OwnerComponentBase';


type Props = {
  isChanged: boolean,
  firebaseId: string,
  reservationData: MarketReservation<Market> | MarketLongreservation<Market>,
  disabled: boolean,
  dispatchActionForChanges: (callback) => void
}

function OwnerComponent(props: Props): JSX.Element {
  //console.log('VOalm', props)

  const clubmembers = useAppSelector(state => state.users.clubmembers, shallowEqual)
  const navigation = useAppNavigation()

  const getUserName = (uid) => {
    if (uid && clubmembers && Object.keys(clubmembers).includes(uid)) {
      return clubmembers[uid].first_name + " " + clubmembers[uid].family_name
    } else {
      return "Neuvedeno"
    }

  }
  const dispatchChange = (callback: (params) => void) => {
    props.dispatchActionForChanges(
      callback
    )
  }

  const playerPress = () => {
    //console.log('volleeee', Object.keys(props.data).length, props.sideCaption)

      DeviceEventEmitter.addListener("event.mapNewPlayerToGame", (eventData) => {
        dispatchChange(() => { return  {mainUid: eventData}})
        //props.side[key] = eventData
        console.log('catched daat', eventData)
      }
      )
  
      navigation.navigate('ContactsScreenStack', {
        action: 'selectPlayer', 
        path: ['mainUid'], 
        alreadySelected: []
      })
    
}

  return (<>
    <OwnerComponentBase marketData={props.reservationData}/>
    <FormEntry caption={i18n.t(tokens.app.screens.manageReservation.reservationOnBehalf)}
    editable={!props.disabled}>
      <Switch disabled={props.disabled} value={props.reservationData.isOnBehalf}
        onValueChange={(value) => {dispatchChange(() => { 
          if (value == true) {
              let newPlayers = [...props.reservationData.players.filter((item) => item !=  props.reservationData.uid)]
              return {isOnBehalf: value, mainUid: "", isNotRegistered: false, players: newPlayers}
          } else {
              return {isOnBehalf: value, mainUid: props.reservationData.uid, isNotRegistered: false}
          }
        })
      }}
      />
    </FormEntry>
    {props.reservationData.isOnBehalf?(
    <FormEntry caption={i18n.t(tokens.app.screens.manageReservation.reservationOnBehalfRegistered)}
    editable={!props.disabled}>
      <Switch disabled={props.disabled} value={!props.reservationData.isNotRegistered}
        onValueChange={(value) => {dispatchChange(() => { return { isNotRegistered: !value } })}}
      />
    </FormEntry>):null}

    {props.reservationData.isOnBehalf && props.reservationData.isNotRegistered == false?
      <FormEntry 
      caption={i18n.t(tokens.app.screens.manageReservation.reservationAuthor)} 
      editable={!props.disabled}
      >
        <Pressable
        disabled={props.disabled}
        onPress={playerPress}
        >
        <UserAvatar uid={props.reservationData.mainUid} size={normalize(70)}/>
        </Pressable>
    </FormEntry>
    :null}
    {props.reservationData.isOnBehalf && props.reservationData.isNotRegistered == true?
    <>
    <FormEntry caption={i18n.t(tokens.app.screens.registration.first_name)}
    editable={!props.disabled}>
      <TextInput maxFontSizeMultiplier={1.2}
        style={[styles.textStyle, styles.inputText]}
        placeholder={i18n.t(tokens.app.screens.registration.first_name)}
        placeholderTextColor="#aaaaaa"
        onChangeText={(text) => {dispatchChange(() => { return { onBehalfFirstName: text } })}}
        value={props.reservationData.onBehalfFirstName}
        underlineColorAndroid="transparent"
        autoCapitalize="words"
        />
    </FormEntry>
    <FormEntry caption={i18n.t(tokens.app.screens.registration.email)}
    editable={!props.disabled}>
      <TextInput maxFontSizeMultiplier={1.2}
        style={[styles.textStyle, styles.inputText]}
        placeholder={i18n.t(tokens.app.screens.registration.email)}
        placeholderTextColor="#aaaaaa"
        onChangeText={(text) => {dispatchChange(() => { return { onBehalfEmail: text } })}}
        value={props.reservationData.onBehalfEmail}
        underlineColorAndroid="transparent"
        autoCapitalize="words"
        inputMode='email'
        />
    </FormEntry>
    <FormEntry caption={i18n.t(tokens.app.screens.registration.phone)}
    editable={!props.disabled}>
      <TextInput maxFontSizeMultiplier={1.2}
        style={[styles.textStyle, styles.inputText]}
        placeholder={i18n.t(tokens.app.screens.registration.phone)}
        placeholderTextColor="#aaaaaa"
        onChangeText={(text) => {dispatchChange(() => { return { onBehalfPhone: text } })}}
        value={props.reservationData.onBehalfPhone}
        underlineColorAndroid="transparent"
        autoCapitalize="words"
        inputMode='numeric'
        />
    </FormEntry>
    </>:null}
  
  </>
  )
}

const styles = StyleSheet.create({

  textStyle: {

    textAlign: "right",
    fontSize: normalize(18),
    fontFamily: 'AvertaStd-Regular',
    
  },
  inputText: {
    backgroundColor: "white",
    borderColor: "grey",
    //borderWidth: 1,
    borderRadius: 5,
    width: "100%",
    height: 30

  }
});

export default OwnerComponent
