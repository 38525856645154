import View from '@expo/html-elements/build/primitives/View';
import React from 'react';
import { ActivityIndicator, Platform, StyleSheet, Switch } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual } from 'react-redux';
import { isAnyRole } from '../../../../functions/src/funcs';
import { Market, MarketReservation, MarketStatuses, MarketTypes, Season, UserRoles } from '../../../../functions/src/interfaces';
import FormEntry from '../../../components/FormEntryNew';
import GamePlayers from '../../../components/GamePlayersNew';
import ReservationTime from '../../../components/ReservationTime';
import i18n, { tokens } from '../../../i18n';
import { editMarket, switchLongShort } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/marketReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../../store/selectors';
import { isAllowedToApprove, isAllowedToEdit, validations } from '../../ManageReservationScreen/ReservationValidations';
import CheckoutInfo from '../CommonMarketComponents/CheckoutInfo';
import OwnerComponent from '../CommonMarketComponents/OwnerComponent';
import PrevMarket from '../CommonMarketComponents/PrevMarket';
import PriceComponent from '../CommonMarketComponents/PriceComponent';
import ButtonComponent from './ButtonComponent';

type Props = {
    firebaseId?: string,
    club: string,
    setMarketId: (newVal: string) => void,
    setMarketButtonIndex: (callback: number | ((prev: number) => void)) => void,
}

function ManageReservation(props: Props):JSX.Element {
    //console.log('Market: ' + props.firebaseId)
    const dispatch = useAppDispatch()
    
    const marketId = props.firebaseId
    const club = props.club

    //const [validators, setValidators] = useState({})
    
    const reservationData: StateLoader<MarketReservation<Market>> & {allValid: boolean} = useAppSelector((state: RootStore) => {
        var reservation = {...state.market.managedMarket} as StateLoader<MarketReservation<Market>> & {seasonsData?: {[seasonId: string]: Season}}
        const validators = reservation.seasonsData && reservation.data?.season ? validations(reservation.seasonsData[reservation.data.season]) : {}
        //console.log(validators)
        var validationsData = {}
        var allValid = true
        if (reservation.isLoaded) {
            validationsData = Object.keys(reservation.data).reduce((acc, key) => {acc[key] = {validation: true, error: ""}; return acc}, validationsData)
            let validKeys = Object.keys(validators)
            for (let i = 0; i < validKeys.length; i++) {
                let key = validKeys[i]
                let validator = validators[key]
                let validation = validator.method(reservation.data)
                validationsData[key] = {validity: validation.bool, error: validation.context}
                allValid = allValid == true ? validation.bool : allValid
            }
            //console.log('Probehly validace: ', validationsData)
            reservation['validations'] = {...validationsData}
        }
        reservation['allValid'] = allValid

        return reservation
    }, shallowEqual)
    //console.log('Update rezervace: ',reservationData)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    //const seasons = useAppSelector(state => state.auth.seasons, shallowEqual)

    /* useEffect(() => {
        if (seasons && reservationData.data?.season && seasons[reservationData.data.season]) {
            setValidators(validations(seasons[reservationData.data.season]))
        }
        
    }, [seasons])
 */
    /* useEffect(()=> {
        var unsub: Promise<() => void> | null = null
        if (marketId && club) {
            unsub = dispatch(subscribeForMarket(club, marketId))

        } else {
            //dispatch()
        }
        return () => {
            dispatch({type: 'EDITED_MARKET', data: {isLoaded: false}})
            if (unsub) unsub
            .then((resp) => resp())
        }
      }, [marketId, club]) */

    /* const isAdmin = () => {
        return currentUser.isAdmin == true || currentUser.isGod == true
    }

    const isEditor = () => {
        return currentUser.uid == reservationData.data.mainUid || currentUser.isAdmin == true || currentUser.isGod == true
    }    */

    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        dispatch(editMarket(changes))
    }

    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        
        >

                {reservationData.isLoaded == true?
                
                <View style={styles.container}>
                    
                    <OwnerComponent
                    dispatchActionForChanges={dispatchChanges}
                    reservationData={reservationData.data}
                    isChanged={reservationData.isChanged}
                    firebaseId={reservationData.data.firebaseId}
                    disabled={!isAllowedToApprove(currentUser, reservationData.data.club)}
                    />
                    {reservationData.data.status == MarketStatuses.new?
                    <FormEntry
                    caption={i18n.t(tokens.app.constants.markettypes.longreservation)}
                    editable={true}
                    >
                        <Switch 
                        value={false}
                        onValueChange={(value) => dispatch(switchLongShort())}
                        />
                    </FormEntry>
                    :null}
                    <CheckoutInfo marketData={reservationData.data}/>
                    <PrevMarket marketData={reservationData.data} setMarketId={props.setMarketId}/>
                    <ReservationTime 
                    dispatchActionForChanges={dispatchChanges}
                    disabled={!isAllowedToEdit(currentUser, reservationData.data.mainUid, reservationData.data.status, reservationData.data.club)}
                    reservationTimeData={
                        reservationData.data
                    }
                    validations={
                        reservationData.validations
                    }
                    />
                    <GamePlayers
                    visible={!(reservationData.data?.isOnBehalf && reservationData.data?.isNotRegistered) || false}
                    disabled={!isAllowedToEdit(currentUser, reservationData.data.mainUid, reservationData.data.status, reservationData.data.club)}
                    reservationOwner={reservationData.data.mainUid}
                    selectableType={!(false)}
                    gameType={reservationData.data.gameType}
                    gamePlayers={reservationData.data.gamePlayers}
                    players={reservationData.data.players}
                    type={reservationData.data.type}
                    dispatchActionForChanges={dispatchChanges}
                    dragEnabled={isAllowedToEdit(currentUser, reservationData.data.mainUid, reservationData.data.status, reservationData.data.club)}//currentUser.uid == testingReservation.mainUid || currentUser.isAdmin == true || currentUser.isGod == true
                    />
                    <PriceComponent 
                    isChanged={reservationData.isChanged}
                    firebaseId={reservationData.data?.firebaseId}
                    reservationData={{...reservationData.data, priceCompDataType: 'market'}}
                    allValid={reservationData.allValid} 
                    type={'market'}
                    dispatchChanges={dispatchChanges}
                    />
                    <ButtonComponent
                    isChanged={reservationData.isChanged}
                    isAllowedToEdit={isAllowedToEdit(currentUser, reservationData.data.mainUid, reservationData.data.status, reservationData.data.club)}
                    isAllowedToApprove={isAllowedToApprove(currentUser, reservationData.data.club)}
                    reservationData={reservationData}
                    firebaseId={reservationData.data.firebaseId}
                    currentUser={currentUser}
                    setMarketId={props.setMarketId}
                    setMarketButtonIndex={props.setMarketButtonIndex}
                    />
                </View>
                :
                <ActivityIndicator size={"large"} />}

        </KeyboardAwareScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
})

export default ManageReservation