import { Route } from "@react-navigation/native";
import React from "react";
import { Image, Platform, Text, View } from "react-native";
import WebWrapper from "../../components/WebWrapper";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { globalStyleSheet } from "../../utilities/globalStyles";
import FormEntry from "../../components/FormEntryNew";
import i18n, { tokens } from "../../i18n";
import * as Constants from "expo-constants";
import * as Updates from 'expo-updates';

type Props = {
    route: Route<'AboutAppScreen', {}>
}


function AboutAppScreen(props) {
    return (
        <View style={globalStyleSheet.container}>
            <WebWrapper>
            
            <KeyboardAwareScrollView 
            keyboardDismissMode='on-drag'
            keyboardShouldPersistTaps="handled"
            enableResetScrollToCoords={false}
            extraHeight={Platform.select({ android: 200, ios: 200 })}
            >
                <View style={{width: "100%", alignItems: "center", padding: 20}}>
                <Image
                source={require('../../../assets/IKONA_512x512px.png')}
                style={{width: 200, height: 200, borderRadius: 10}}
                />
                </View>
                <FormEntry
                caption={i18n.t(tokens.app.screens.about.aboutAppVersion)}
                editable={false}
                >
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{Constants.default?.expoConfig?.version}</Text>
                </FormEntry>
                <FormEntry
                caption={i18n.t(tokens.app.screens.about.aboutDeveloper)}
                editable={false}
                >
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{"Miroslav Poláček"}</Text>
                </FormEntry>
                <FormEntry
                caption={i18n.t(tokens.app.screens.about.aboutContactMail)}
                editable={false}
                >
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{"mirapolacek@gmail.com"}</Text>
                </FormEntry>
                <FormEntry
                caption={i18n.t(tokens.app.screens.about.aboutContactPhone)}
                editable={false}
                >
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{"+420 724 720 690"}</Text>
                </FormEntry>
                <FormEntry
                caption={i18n.t(tokens.app.screens.about.aboutScreenUpdateId)}
                editable={false}
                >
                    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{Updates.updateId?Updates.updateId.substring(0,7):"XNA"}</Text>
                </FormEntry>
            </KeyboardAwareScrollView>
        </WebWrapper>
        </View>
    )
}

export default AboutAppScreen