import React, { useEffect, useState } from 'react';
import { FlatList, Platform, Text, TextInput, Pressable, View } from 'react-native';
//import DelayInput from "react-native-debounce-input";
import { Route, useNavigation } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Club, CourtTypes, Membership, ProductTypes, Season, UserRoles } from '../../../functions/src/interfaces';
import BigButton from '../../components/BigButton';
import CustomPickerSelect from '../../components/CustomPickerSelect/CustomPickerSelect';
import FormEntry from '../../components/FormEntryNew';
import FormEntryNoCaption from '../../components/FormEntryNoCaption';
import FormHeading from '../../components/FormHeading';
import SmallButton from '../../components/SmallButton';
import WebWrapper from '../../components/WebWrapper';
import i18n, { tokens } from '../../i18n';
import { editClub, manageClubToState } from '../../store/actions/actionsAuth';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { AuthActions } from '../../store/reducers/authReducer';
import { RootStore } from '../../store/reducers/rootReducer';
import { getCurrentUserWithRoles } from '../../store/selectors';
import { courtTypes, currency, membershipTypes, seasonTypes } from '../../utilities/constants';
import { firestoreAutoId, monthString, shallowEqualJSON } from '../../utilities/funcs';
import { globalStyleSheet } from '../../utilities/globalStyles';
import { StateLoader } from '../../utilities/interfaces';
import MembershipDetail from './Components/MembershipDetail';
import AllowedGameTypes from './Components/GameTypesComponent';
import { UserOrClub } from '../../store/reducers/usersReducer';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import PaymentPromiseComponent from './Components/PaymentPromise';
import AutoCaptureComponent from './Components/AutoCapture';
import AllowPaymentsComponent from './Components/AllowPayments';
import AgeRoles from './Components/AgeRoles';
import { ToolbarButton } from '../../components/Calendar/Calendar';
import SeasonDetailInfo from './Components/SeasonDetail';
import BasicClubInfo from './Components/BasicForm';
import CourtsDetail from './Components/CourtsDetail';
import Ionicons from '@expo/vector-icons/Ionicons';

const DetailEntry:React.FC<{clubId: string, sectionDetail: string, caption: string}> = (props) => {
    const navigation = useAppNavigation()
    return (
        <Pressable
        onPress={() => navigation.push('ManageClubScreen', {clubId: props.clubId, sectionDetail: props.sectionDetail})}
        >
        <FormEntry
        caption={props.caption}
        editable={false}
        >
            <Ionicons 
            name={"chevron-forward"}
            color="darkgrey"
            size={35}
            
            />
        </FormEntry>
        </Pressable>
    )
}

const WrapComponentDetail:React.FC<{sectionDetail: string, clubId: string, children: any, sectionDetailScreen: string | undefined, caption: string}> = (props) => {
    if (props.sectionDetail == props.sectionDetailScreen) {
        return props.children
    } else if (!props.sectionDetailScreen) {
        return (<DetailEntry 
        caption={props.caption}
        sectionDetail={props.sectionDetail}
        clubId={props.clubId}
        />)
    } 
}




type Props = {
    route: Route<'ManageClubScreen', {clubId: string, sectionDetail?: string}>
}


function ManageClubScreen(props: Props) {
    const {clubId, sectionDetail} = props.route.params
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const [loading, setLoading] = useState(true)
    //const [editFlag, setEditFlag] = useState(newsId?false:true)
    const seasons: {[seasonId: string]: Season} = useAppSelector((state: RootStore) => state.auth.seasons, shallowEqualJSON)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    //const [message, setMessage] = useState({message: "", uid: ""})
    const managedClub: StateLoader<Club> = useSelector((state: RootStore) => state.auth.managedClub)
    

    

    useEffect(() => {
        var unsubscribe = () => {}
        if (!sectionDetail) {
            setLoading(true)
            dispatch(manageClubToState(clubId)).then((unsub) => {
                unsubscribe = unsub
            })
        }
        

        return () => unsubscribe()
    }, [clubId])

    const dispatchChange = (data: any) => {
        dispatch({type: AuthActions.authEditClub, data: {value: data}})
    }

    
    return (
        
        <View style={globalStyleSheet.container}>
            <WebWrapper>
            {managedClub && managedClub.isLoaded?
            <KeyboardAwareScrollView  
            keyboardDismissMode='on-drag'
            keyboardShouldPersistTaps="handled"
            enableResetScrollToCoords={false}
            extraHeight={Platform.select({ android: 200, ios: 200 })}
            >
                <View style={globalStyleSheet.container}>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='clubBasic'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubGeneralInfo)}
                >
                    <BasicClubInfo 
                    currentUser={currentUser}
                    />
                </WrapComponentDetail>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='courts'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubCourts)}
                >
                    <CourtsDetail 
                    currentUser={currentUser}
                    />
                </WrapComponentDetail>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='seasons'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubSeasonsHeading)}
                >
                    <SeasonDetailInfo 
                    currentUser={currentUser}
                    seasons={seasons}
                    //dispatchChange={dispatchChange}
                    //gameTypeSetting={managedClub.data.allowedGameTypes || {}}
                    />
                </WrapComponentDetail>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='products'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubProductsHeading)}
                >
                    <>
                    <FormHeading 
                    heading={i18n.t(tokens.app.screens.manageClub.manageClubProductsHeading)}
                    />
                    <View style={{flexDirection: "column"}}>
                    <MembershipDetail 
                    subscription={managedClub.data.products?.[ProductTypes.subscription]} 
                    disabled={false}
                    seasons={seasons}
                    />
                    </View>
                    </>
                </WrapComponentDetail>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='allowedGametypes'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubAllowedGameTypes)}
                >
                    <AllowedGameTypes 
                    dispatchChange={dispatchChange}
                    gameTypeSetting={managedClub.data.allowedGameTypes || {}}
                    />
                </WrapComponentDetail>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='promise'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubPaymentsPromiseAllowed)}
                >
                    <PaymentPromiseComponent
                    club={managedClub}
                    dispatchChange={(data) => dispatch({type: AuthActions.authEditClub, data: {value: data}})}
                    />
                </WrapComponentDetail>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='payment'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubPaymentsSetting)}
                >
                    <AllowPaymentsComponent
                    club={managedClub}
                    dispatchChange={(data) => dispatch({type: AuthActions.authEditClub, data: {value: data}})}
                    />
                </WrapComponentDetail>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='autocapture'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubAutoCapture)}
                >
                    <AutoCaptureComponent
                    club={managedClub}
                    dispatchChange={(data) => dispatch({type: AuthActions.authEditClub, data: {value: data}})}
                    />
                </WrapComponentDetail>
                <WrapComponentDetail
                clubId={clubId}
                sectionDetail='agerelated'
                sectionDetailScreen={sectionDetail}
                caption={i18n.t(tokens.app.screens.manageClub.manageClubAgeRoles)}
                >
                    <AgeRoles
                    dispatchChange={(data) => dispatch({type: AuthActions.authEditClub, data: {value: data}})}
                    />
                </WrapComponentDetail>

                <View style={{flexDirection: "row", width: "100%"}}>
                <BigButton
                onPress={() => {
                    dispatch(editClub(managedClub.data, 
                        
                        (bool, context) => {
                        if (bool == true) {
                            console.log('Povedlo se upravit club' + JSON.stringify(context))
                            
                        } else {
                            //[].reduce()
                            console.log('Nepovedlo se upravit club' + JSON.stringify(context))
                        }
                    }))
                }}
                buttonText={i18n.t(tokens.app.screens.manageClub.manageClubSaveButton)}
                />
                </View>
            </View>
            </KeyboardAwareScrollView>
            :null}
            </WebWrapper>
        </View>
        
    )
}

export default ManageClubScreen



