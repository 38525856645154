import { useAppNavigation } from '../store/hooks';
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Animated, View, StyleSheet, PanResponder, Text, Platform, ViewBase, Pressable, Alert, DeviceEventEmitter, LayoutChangeEvent } from "react-native";
import i18n, { tokens } from "../i18n";
import UserAvatar from "./UserAvatar";

interface User {
  uid: string,
  photo?: string,
  first_name: string,
  family_name: string,
}

type Props = {
  id: string;
  size: number;
  style?: {[style: string]: any};
  dragStart: () => void;
  moveHandler: (id: string) => void;
  releaseHandler: (id: string) => void;
  user: string;
  onPressHandler: (type: {pos: string, side: string}) => void;
  path: {pos: string, side: string},
  dragEnabled: boolean
};


export type Handle = {

    resetPositions: (coords: any) => void,
    returnHome: (x: number, y: number, callback: any) => void,
    measure: () => Promise<{id: string, fx: number, fy: number, width: number, height: number, px: number, py: number, panx: number, pany: number}>,
    increase: (callback: any) => void,
    decrease: (callback: any) => void,
    show: (callback: any) => void,
    hide: (callback: any) => void,
    origx: number,
    origy: number,
}

const Draggable = (props: Props, ref: React.ForwardedRef<Handle>) => {
  
  //console.log(props)
  const pan = useRef<Animated.ValueXY>(new Animated.ValueXY()).current;
  const viewRef = useRef<null | View>(null)
  //const [moveValue, setMoveValue] = useState({x: 0, y: 0})
  const [big, setBig] = useState(false)
  const fadeAnim = useRef(new Animated.Value(1)).current 
  const sizeAnim = useRef<any>(new Animated.Value(1)).current 
  const [fade, setFade] = useState(1)
  const navigation = useAppNavigation();
  const [origPos, setOrigPos] = useState({x: 0, y: 0})


  useEffect(() => {
    fadeAnim.addListener(({value}) => setFade(value))
    return () => fadeAnim.removeAllListeners()
  },[])

  //console.log('WTF ', sizeAnim)
  const styles = StyleSheet.create({
    animatedContainer: {
      height: props.size,
      width: props.size,
      borderRadius: props.size/2,
    },
    box: {
      height: props.size,
      width: props.size,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: props.size/2
    }
  });
  const increase = (callback) => {
    if (big == false) {
      console.log('Chci spustit animaci')
      setBig(true)
      let config: Animated.TimingAnimationConfig = {
        toValue: 1.2,
        duration: 500,
        useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
      }
      Animated.timing(
        sizeAnim,
        config
      ).start((obj: Animated.EndResult) => {
        
          callback()
        }
        
      )
    }
  }
  const decrease = (callback) => {
    if (big == true) {
      setBig(false)
    let config: Animated.TimingAnimationConfig = {
      toValue: 1,
      duration: 500,
      useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
    }
    Animated.timing(
      sizeAnim,
      config
    ).start((obj: Animated.EndResult) => {
      
      callback()
    }
      
      )
    }
  }

  const show = (callback) => {
    let config: Animated.TimingAnimationConfig = {
      toValue: 1,
      duration: 500,
      useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
    }
    Animated.timing(
      fadeAnim,
      config
    ).start((obj: Animated.EndResult) => {
      
      callback()
    }
      
      )
  }
  const hide = (callback) => {
    let config: Animated.TimingAnimationConfig = {
      toValue: 0,
      duration: 500,
      useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
    }
    Animated.timing(
      fadeAnim,
      config
    ).start((obj: Animated.EndResult) => {
      
      callback()
    }
      
      )
  }

/*.then(({fx, fy, width, height, px, py}) => {
              let dragData = {id: drag, x: fx, y: fy, width: width, height: height}
              console.log('dragdata',dragData,  evaluateHit(curr, dragData))
              resolve({item: drag, bool: evaluateHit(curr, dragData)})
            })
            .catch((e) => {
              reject(e)
            })
            console.log(res) */
  const returnHome = (x, y, callback) => {
    //pan.setOffset({x: 100, y: 0})
    //pan.setValue({x: offsetx, y: offsety})
    pan.flattenOffset();
        Animated.timing(pan, {
          toValue: { x: x, y: y },
          duration: 500,
          useNativeDriver: Platform.OS == "ios" || Platform.OS == "android" ? true : false
        }).start(() => {
          if (callback) callback()
        }
          
        );
  }
  

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponderCapture: (evt, gestureState) => {
        measure().then(({px, py}) => setOrigPos({x: px, y: py}))
        console.log("onStartShouldSetPanResponderCapture", gestureState.dx,
          gestureState.dy);
        return gestureState.dx != 0 && gestureState.dy != 0 && props.dragEnabled;
      },
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => {
        console.log("onMoveShouldSetPanResponderCapture", gestureState.dx,
          gestureState.dy);
        return gestureState.dx != 0 && gestureState.dy != 0 && props.dragEnabled;
      },
      onPanResponderGrant: (e, gesture) => {
        props.dragStart()
        //console.log(pan.x)
        //setMoveValue({x: pan.x, y: pan.y});
        //console.log(moveValue.x);
        //console.log(moveValue.y);
        /* pan.setOffset({
          x: pan.x,
          y: pan.y,
        }); */
      },
      onPanResponderMove: (e, gesture) => {
        pan.x.setValue(gesture.dx);
        pan.y.setValue(gesture.dy);
        props.moveHandler(props.id)
        
      }
    ,
      onPanResponderRelease: (e, gesture) => {
        
        //returnHome(0, 0, props.positions[props.id].offset, 0)
          props.releaseHandler(props.id)
          //returnHome(moveValue.x, moveValue.y)
        
      }
    })
  ).current;
  
  const onPressDrag = () => {
    if (!props.user || props.user == "") {
      props.onPressHandler(props.path)
    } else {
      navigation.navigate('Profile', {uid: props.user})
    }
  }

  const measure = () => {
    return new Promise<{id: string, fx: number, fy: number, width: number, height: number, px: number, py: number, panx: number, pany: number}>((resolve, reject) => {
      try {
        if (viewRef && viewRef.current) {
          viewRef?.current.measure((fx, fy, width, height, px, py) => {
            // console.log('Pozice',{id: props.id, fx, fy, width, height, px, py})
            let panx: any = pan.x
            let pany: any = pan.y
            resolve({id: props.id, fx, fy, width, height, px, py, panx: panx._value, pany: pany._value})
          })
        }
      }
      catch (e) {
        reject(e)
      }
    })}
  const resetPositions = () => {
    pan.setValue({x: 0, y: 0})
    if (fade == 0) {
        show(() => {})
    }
      
    
  }
  useImperativeHandle(ref, () => {
    let methods = {

    returnHome,
    measure,
    resetPositions,
    increase,
    decrease,
    show,
    hide,
    origx: origPos.x,
    origy: origPos.y
  }

  return methods
}
  

  )

  return (
      <Animated.View
      
      /* onLayout={event => {
        const layout = event.nativeEvent.layout;
        console.log('height:', layout.height);
        console.log('width:', layout.width);
        console.log('x:', layout.x);
        console.log('y:', layout.y);
        props.handler(props.id, {x: null, y: null, vx: null, vy: null, x0: layout.x, y0: layout.y})
      }} */
       ref={viewRef}
        style={{
          ...props.style,
          
          zIndex: 9999,
          borderRadius: props.size,
          opacity: fadeAnim,
          transform: [{ translateX: pan.x }, { translateY: pan.y }, { scaleX: sizeAnim }, {scaleY: sizeAnim}]
        }}
        {...panResponder.panHandlers}
        onLayout={(event: LayoutChangeEvent) => {
          //console.log('my pos', event.nativeEvent.layout.x, event.nativeEvent.layout.y)
        }}
      >
        <Pressable
        onPress={() => {
          onPressDrag()
        }}
        
        >
        
          {/* <Text style={{fontSize: 6}}>{Object.keys(props.user || {}).length > 0 ? `${props.user.first_name || ""} ${props.user.family_name || ""}` : "Přidat"}</Text> */}
          <UserAvatar 
          uid={props.user} 
          size={props.size} 
          type={props.user?"inferrable":"strict"} 
          caption={props.user?null:i18n.t(tokens.app.components.gameSettings.gameSettingEmpty)}
          />
        
        </Pressable>
      </Animated.View>
  );
}

export default React.forwardRef(Draggable) 