import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { registerClub, registerUser } from '../../store/actions/actionsAuth';
import styles from './styles';
//import DatePicker from 'rmc-date-picker';
//import 'rmc-picker/assets/index.css';
//import 'rmc-date-picker/assets/index.css';
import { Route } from '@react-navigation/native';
import { ButtonGroup } from 'react-native-elements';
import WebWrapper from '../../components/WebWrapper';
import i18n, { tokens } from '../../i18n';
import { useAppDispatch, useAppNavigation } from '../../store/hooks';
import { mainFontBold } from '../../utilities/globalStyles';
import ClubForm from './RegistrationComponents/ClubForm';
import UserForm from './RegistrationComponents/UserForm';

type Props = {
    route: Route<'RegistrationScreen', {type?: "club" | "user"}>
}

function RegistrationScreen(props: Props) {
    //console.log(Platform)
    const {type} = props.route?.params || {}



    const defaultDate = new Date(1990, 0, 1);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const [buttonGroupIndex, setButtonGroupIndex] = useState(0)


    
    const buttons = [
            i18n.t(tokens.app.screens.registration.registrationButtonsUser), 
            i18n.t(tokens.app.screens.registration.registrationButtonsClub), 
        ]
    
    useEffect(() => {
        if (type) {
            if (type == "user") {
                setButtonGroupIndex(0)
            } 
        }
    }, [type])

   



    return (
        
        <KeyboardAwareScrollView  
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{ flexGrow: 1 }}
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >
            <WebWrapper>
            {!type?<ButtonGroup
            onPress={(val) => {setButtonGroupIndex(val)} }
            selectedIndex={buttonGroupIndex}
            buttons={buttons}
            //buttonStyle={{borderColor: secondColor}}
            selectedButtonStyle={{backgroundColor: "grey"}}
            containerStyle={styles.toolbar}
            buttonContainerStyle={{margin: 0, padding: 0, height: "100%"}}
            textStyle={{color: "black", fontFamily: mainFontBold, fontWeight: "bold"}}
            />:null}
            {buttonGroupIndex == 0?
            <UserForm
            registerUser={(user, callback) => dispatch(registerUser(user, callback))}
            />:null}
            {buttonGroupIndex == 1?
            <ClubForm
            registerClub={(club, callback) => dispatch(registerClub(club, callback))}
            />:null}
            
                
            </WebWrapper>
        </KeyboardAwareScrollView>
        
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        registerUser: (user, callback) => dispatch(registerUser(user, callback))
    }
}

export default RegistrationScreen

/*
this.registerForm = this.formBuilder.group({
          username: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
          firstName: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
          familyName: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
          language: [''],
          address: ['', Validators.compose([Validators.required, Validators.minLength(0)])],
          city: ['', Validators.compose([Validators.required, Validators.minLength(0)])],
          country: [''],
          phone: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
          email: ['', Validators.compose([Validators.required, EmailValidator.isValid])],
          password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
          dateOfBirth: [new Date().toISOString().slice(0, 10), Validators.compose([Validators.required])],
          terms: [false, CheckedValidator.isChecked]
      });

*/