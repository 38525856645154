import React from "react";
import { View, Text } from "react-native";
import { Market, MarketSubscription, MembershipTypes } from "../../../../../functions/src/interfaces";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import FormEntry from "../../../../components/FormEntryNew";
import i18n, { tokens } from "../../../../i18n";
import { monthString } from "../../../../utilities/funcs";
import { globalStyleSheet } from "../../../../utilities/globalStyles";

type Props = {
    dispatchChanges: (callback) => void,
    subscription: MarketSubscription<Market>,
    calcEndDate: (type: MembershipTypes, from: Date) => Date
}

function YearlyDetail(props: Props): JSX.Element {

    return (
        <View>
            <FormEntry
            caption={i18n.t(tokens.app.screens.subscription.subscriptionStart)}
            editable={true}
            >   
                <CustomDatePicker
                containerStyle={globalStyleSheet.inputOk}
                date={props.subscription.from}
                fontStyle={globalStyleSheet.entityText}
                setDate={(newDate) => props.dispatchChanges(() => { 
                    if (newDate >= props.subscription.minFrom) {
                        return { 
                            from: newDate, 
                            to: props.calcEndDate(props.subscription.memType, newDate), 
                            dueDate: newDate 
                        } 
                    } else {
                        return { 
                            from: new Date(props.subscription.minFrom), 
                            to: props.calcEndDate(props.subscription.memType, newDate),
                            dueDate: new Date(props.subscription.minFrom),
                        } 
                    }
                }
                )} 
                />
            </FormEntry>
            <FormEntry
            caption={i18n.t(tokens.app.screens.subscription.subscriptionEnd)}
            editable={false}
            >
                <Text>{monthString(props.subscription.to)}</Text>
            </FormEntry>
        </View>
    )
}

export default YearlyDetail