import React, { useEffect, useRef, useState } from 'react'
import { InputAccessoryView, Platform, StyleSheet, Text, TextInput, Pressable, View } from 'react-native'
import i18n, { tokens } from '../i18n'
import { windowWidth } from '../utilities/constants'
import BigButton from './BigButton'





type Props = {
    message: {
        message: string
    },
    submitMessage: (text, callback) => void,
    setMessage: (text) => void
}  


const CommentView: React.FC<Props> = (props) => {
    const commentRef = useRef()
    //const [message, setMessage] = useState("")
    const [bottomInputVisible, setBottomInputVisible] = useState(false)




    useEffect(() => {
        if (bottomInputVisible) {
            if (commentRef.current) {
                let TI: any = commentRef.current
                TI.focus()
            }
            
        }
    },[bottomInputVisible])
    const styless = StyleSheet.create({
        button: {
            flex: 1
        },
        entityContainer: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 8,
            borderColor: '#cccccc',
            borderWidth: 1,
            paddingBottom: 0,
            backgroundColor: "white"
        },
        entityText: {
            fontSize: 16,
            color: '#333333'
        }
    })
    return (
        !bottomInputVisible?
        <View style={{flexDirection: "row", flex: 1}}>
            <BigButton
            buttonText={i18n.t(tokens.app.screens.opengame.commentButton)}
            onPress={() => {
                setBottomInputVisible(true)
             }}
            />
               
        </View>
        :
        Platform.OS == 'ios' ? bottomInputVisible?
            <InputAccessoryView 
            
            >
                <View style={{
                    flexDirection: "row",
                    flex: 1,
                    backgroundColor: "lightgrey",
                    height: 50,
                    borderTopWidth: 1,
                    borderColor: "black",
                    paddingLeft: 10,
                    paddingBottom: 3,
                    paddingTop: 3,
                    paddingRight: 10,
                }}>
                <TextInput maxFontSizeMultiplier={1.2} 
                style={{backgroundColor: "white", height: 40, flex: 1}}
                ref={commentRef}
                value={props.message.message}
                onChangeText={(text) => props.setMessage(text)}
                //onPressOut={() => {setBottomInputVisible(0)}}
                onBlur={() => {setBottomInputVisible(false)}}
                />
                <Pressable
                onPress={() => {
                    setBottomInputVisible(false)
                    props.submitMessage({...props.message}, (bool) => {/* if (bool == true) setBottomInputVisible(false)  */})
                }}
                style={{justifyContent: "center", padding: 3}}
                >
                    <Text>{i18n.t(tokens.app.screens.opengame.sendButton)}</Text>
                </Pressable>
                </View>
            </InputAccessoryView>:null
            :
            bottomInputVisible?
            <View style={{flexDirection: "row", flex: 1, height: 40, alignItems: "center", marginTop: 5, }}>
                <TextInput maxFontSizeMultiplier={1.2}
                multiline
                style={{backgroundColor: "white", height: 40, 
                width: windowWidth-80,  }}
                ref={commentRef}
                value={props.message.message}
                onChangeText={(text) => props.setMessage(text)}
                //onPressOut={() => {setBottomInputVisible(0)}}
                //onBlur={() => {props.setBottomInputVisible(false)}}
                />
                <BigButton
                buttonText={i18n.t(tokens.app.screens.opengame.sendButton)}
                onPress={() => {
                    props.submitMessage({...props.message}, (bool) => {if (bool == true) setBottomInputVisible(false) })
                }}
                />
            </View>:null

    )
    
    
    
            
    
}



export default CommentView

