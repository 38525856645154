import React from 'react'
import { FlatList, Text, View } from 'react-native'
import { MembershipTypes, Season } from '../../../../functions/src/interfaces'
import BigButton from '../../../components/BigButton'
import FormEntry from '../../../components/FormEntryNew'
import FormHeading from '../../../components/FormHeading'
import i18n, { tokens } from '../../../i18n'
import { RootState, useAppNavigation, useAppSelector } from '../../../store/hooks'
import { RootStore } from '../../../store/reducers/rootReducer'
import { monthString } from '../../../utilities/funcs'
import { SubscribtionListItem } from '../../../utilities/interfaces'

type Props = {
    membershipData: SubscribtionListItem[],
    uid: string,
}
function MembershipData(props: Props):JSX.Element {
    const {membershipData,uid} = props
    const navigation = useAppNavigation()
    const activeSeason: Season = useAppSelector((state: RootStore) => state.auth.activeSeason, (a,b) => JSON.stringify(a) == JSON.stringify(b))

    const isBetween = (from, to) => {
        let currDate = new Date
        if (from <= currDate && to >= currDate) {
            return true
        } else {
            return false
        }
    }

    return (
        membershipData?
            <View>
            <FormHeading heading={i18n.t(tokens.app.screens.profile.profileMembershipTitle)}/>
            <FlatList 
            scrollEnabled={false}
            data={membershipData}
            renderItem={({item}) => {
                //console.log('log jednotliveho itemu', item)
                return (
                    <View style={{flexDirection: "column"}}>
                    <FormEntry key={item.clubId} caption={item.club_name} editable={false}>
                        {item.active?
                        <Text>{`${i18n.t(tokens.app.screens.profile.profileMembershipTo)}: ${monthString(item.activeTill)}`}</Text>:
                        <Text>{`${i18n.t(tokens.app.screens.profile.profileSubscriptionNA)}`}</Text>}
                    </FormEntry>
                    <FlatList 
                    horizontal
                    data={item.subs}
                    keyExtractor={item => item.firebaseId}
                    renderItem={(({item}) => {
                        if (item.membershipType == MembershipTypes.yearSubscription) {
                            return (
                                <View
                                style={{
                                    flexDirection: "column",
                                    width: 200,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    borderRadius: 10,
                                    marginTop: 5,
                                    marginRight: 5
                                }}
                                >
                                    <Text>{`${i18n.t(tokens.app.screens.profile.profileMembershipFrom)}: ${monthString(item.membershipStartDate)}`}</Text>
                                    <Text>{`${i18n.t(tokens.app.screens.profile.profileMembershipTo)}: ${monthString(item.membershipEndDate)}`}</Text>
                                    <View
                                    style={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: 10,
                                        backgroundColor: isBetween(item.membershipStartDate, item.membershipEndDate) ? "green" : "red",
                                        margin: 5,
                                    }}
                                    />
                               </View>
                               )
                        } else if (item.membershipType == MembershipTypes.tourSubscription) {
                            return (
                                <View
                                style={{
                                    flexDirection: "column",
                                    width: 200,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    borderRadius: 10,
                                    marginTop: 5,
                                    marginRight: 5
                                }}
                                >
                                    <Text>{`${i18n.t(tokens.app.constants.memTypes.tourSubscription)}: ${item.tour}`}</Text>
                                    <View
                                    style={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: 10,
                                        backgroundColor: activeSeason?.tour == item.tour ? "green" : "red",
                                        margin: 5,
                                    }}
                                    />
                               </View>
                               )
                        } else {
                            return null
                        }
                        
                        
                    })}
                    />
                    </View>
                   
                )
            }}

            keyExtractor={(item) => item.clubId}
            />
            
            <View style={{flexDirection: "row", flex: 1}}>
                <BigButton
                buttonText={i18n.t(tokens.app.screens.profile.profileAddSubscription)}
                onPress={() => navigation.navigate('ManageMarket', {type: "subscription", club: activeSeason.club, onBehalfUid: uid})}
                />

            </View>
            </View>
            :null
    )
}

export default MembershipData