import React, { useEffect, useState } from 'react';
import { ScrollView } from "react-native";
import { shallowEqual, useSelector } from 'react-redux';
import { AllResTypes, Club, ReservationStatuses } from '../../../functions/src/interfaces';
import { subscribeForReservations } from '../../store/actions/actionsReservationsNew';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import { ReservationFilters, StateLoader } from '../../utilities/interfaces';
import ActionOverlay from '../ActionOverlay';
import FieldLane from './FieldLane';

type Props = {
    currDate: Date
    selectedClub: string,
    reservationFilters?: ReservationFilters
}

function RenderDay(props: Props) {
    //console.log(props.reservations)
    const dispatch = useAppDispatch()
    const reservations:StateLoader<{[resId:string]: AllResTypes}> = useAppSelector((state: RootStore) => state.reservations.reservations, (a,b ) => JSON.stringify(a) == JSON.stringify(b))
    const [filteredReservations, setFilteredReservations] = useState<{[resId:string]: AllResTypes}>({})
    const season = useSelector((state: RootStore) => state.auth.activeSeason, (a,b ) => JSON.stringify(a) == JSON.stringify(b))
    const [sortedFields, setSortedFields] = useState([])
    const fields = useSelector((state: RootStore) => {
    
        
        let out = {}
        let cl = state.auth.activeSeason?.courtsList
        let club = state.auth.allClubs[state.auth.activeSeason.club]
        //console.log(club)
        if (!cl || !club) return out
        Object.keys(cl).forEach((key) => {
            if (cl[key].selected == true) out[key] = {...cl[key], reservations: {}, courtId: club.courts[key].courtId, type: club.courts[key].type}
        })
        //console.log(out)
        return out
    }, (a,b ) => JSON.stringify(a) == JSON.stringify(b))
    
    //const [activeFields, setActiveFields] = useState({})
    
    
    useEffect(() => {
        let out = Object.keys(fields).sort((a,b) => fields[a].courtId > fields[b].courtId ? 1 : -1)
        //console.log(out)
        setSortedFields(out)
    }, [fields])

    useEffect(() => {
        var unsub = dispatch(subscribeForReservations(props.selectedClub, props.currDate))
        return () => {
            
            if (unsub) {
                console.log('Unsubscribing')
                unsub()
                
            }
        }
    }, [props.currDate, props.selectedClub])


    useEffect(() => {
        const filtered = Object.entries(reservations.data).reduce((prev, [key, curr]) => {
            if (curr.status == ReservationStatuses.open && props.reservationFilters.openState == false) {
                return {...prev}
            } else {
                return {...prev, [key]: curr}
            }
        }, {})
        setFilteredReservations(filtered) 
    }, [reservations, props.reservationFilters])

    

    return (
        
            
                <ScrollView
                horizontal={true}
                keyboardShouldPersistTaps='always'
                style={{
                    flex: 1,
                    padding: 10,
                    //width: "100%",
                    //height: "100%"
                }}
                
                >
                <ScrollView
                
                keyboardShouldPersistTaps='always'
                
                style={{
                    flex: 1,
                    flexDirection: "column",
                    //width: "100%",
                    //height: "100%"
                }}
                > 
                {/* <ActionOverlay
                onBackdropPress={()=>{}}
                fullScreen={false}
                isVisible={reservations.isActionPressed}
                /> */}
                {
                    sortedFields.map((field) => {
                        return (fields[field]?
                        <FieldLane 
                        date={props.currDate}
                        key={field} 
                        fieldType={fields[field]?.type} 
                        courtIdText={fields[field].courtId} 
                        //reservations={activeFields[field].reservations}
                        courtId={field}
                        season={season}
                        unlockDate={fields[field].unlockDate}
                        reservations={filteredReservations}
                        />:null
                        )
                    })
                }
            </ScrollView>
            </ScrollView>
        
    )

}


export default RenderDay
