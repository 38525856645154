import { GameTypes, MarketStatuses, MarketTypes, MembershipTypes, NewsTypes, NotificationTypes, PaymentModes, PaymentTransactionStatuses, RoleTypes } from '../../functions/src/interfaces';
import { AboutAppScreen, Alerts, AppliedPlayers, AppText, AvatarComponent, ButtonCaptions, CameraScreen, CheckoutItemsTypes, CheckoutListScreen, CheckoutScreen, ContactsScreen, Courts, CreditScreen, Functions, GameSetting, IndexScreen, LinkedUsersScreen, LoginScreen, LongReservationScreen, ManageAllClubsScreen, ManageClubScreen, ManageLinkedUsersScreen, ManageMarketScreen, ManageMyClubsScreen, ManageReservationScreen, ManageSeasonScreen, MarketCommons, MarketScreen, Messages, NewsEntity, NewsFilters, NewsScreen, OpenGameScreen, PaymentScreen, PaymentState, ProductPaymentDescriptions, ProfileScreen, RankingEntry, RankingScreen, Reccurency, RegistrationScreen, ReservationFilters, ReservationsScreen, ReservationStatisticsScreen, ResultScore, RightMenu, Seasons, SubscriptionScreen, Tabs, UnverifiedScreen, Weekdays } from './keys';

export const czechLang = {
  [AppText.Hello]: 'Ahoj',
  [AppText.Introduction]: 'Toto je zakladni text',
  [AppText.Body]: 'No a toto je body',
  [RegistrationScreen.personalHeading]: 'Osobní informace',
  [RegistrationScreen.addressHeading]: 'Adresa',
  [RegistrationScreen.address]: 'Ulice a číslo',
  [RegistrationScreen.city]: 'Město',
  [RegistrationScreen.passwordsHeading]: 'Hesla',
  [RegistrationScreen.confirmPassword]: 'Potvrzení hesla',
  [RegistrationScreen.confirmPassword_validity]: 'Hesla se musí shodovat',
  [RegistrationScreen.createAccountButtonCaption]: 'Založit účet',
  [RegistrationScreen.dateOfBirth]: 'Datum narození',
  [RegistrationScreen.contactsHeading]: 'Kontakty',
  [RegistrationScreen.email]: 'E-mail',
  [RegistrationScreen.email_validity]: 'E-mail musí být ve formátu email@doména.com',
  [RegistrationScreen.emailConfirm]: 'E-mail znovu',
  [RegistrationScreen.emailConfirm_validity]: 'E-mailové adresy se neshodují',
  [RegistrationScreen.family_name]: 'Příjmení',
  [RegistrationScreen.family_name_validity]: 'Musí být delší než 2 znaky',
  [RegistrationScreen.first_name]: 'Jméno',
  [RegistrationScreen.first_name_validity]: 'Musí být delší než 2 znaky',
  [RegistrationScreen.password]: 'Heslo',
  [RegistrationScreen.password_validity]: 'Heslo musí mít alespoň 6 znaků',
  [RegistrationScreen.phone]: 'Telefón',
  [RegistrationScreen.phone_validity]: 'Musí mít 9 číselných hodnot',
  [RegistrationScreen.termsAndConditions]: 'Souhlas se zpracováním údajů',
  [RegistrationScreen.registrationTitle]: 'Registrace',
  [RegistrationScreen.termsAndConditionsError]: 'Musí být udělen souhlas se zpracováním',
  [RegistrationScreen.registrationButtonsClub]: 'Klub',
  [RegistrationScreen.registrationButtonsUser]: 'Uživatel',
  [RegistrationScreen.registrationClubName]: 'Jméno klubu',
  [RegistrationScreen.registerClubTrialMessage]: 'Po registraci poběží 30ti denní lhůta na vyzkoušení. Datum konce platnosti je viditelné na profilu položkou aktivní do.\n\nPo registraci je potřeba nastavit klub a sezónu. Pokud potřebujete asistenci kontaktujte administrátora na tel: 724720690, nebo email: mirapolacek@gmail.com',
  [RegistrationScreen.registerPrivaciPolicy]: 'Zásady ochrany osobních údajů',
  [RegistrationScreen.registerUserClubsValidity]: 'Je třeba vybrat alespoň jeden klub',
  [LoginScreen.title]: 'Přihlášení',
  [LoginScreen.email]: 'E-mail',
  [LoginScreen.password]: 'Heslo',
  [LoginScreen.submit]: 'Přihlásit',
  [LoginScreen.noAccQuestion]: 'Nemáte účet? ',
  [LoginScreen.forgotPassQuestion]: 'Zapomenuté heslo k účtu? ',
  [LoginScreen.registerButtonCaption]: 'Registrovat',
  [LoginScreen.sendPasswordButton]: 'Odeslat',
  [LoginScreen.emailNotValid]: 'E-mail není platný ',
  [LoginScreen.resetEmailSendingError]: 'Chyba odeslání resetu hesla ',
  [LoginScreen.loginError]: 'Chyba přihlášení, zkontrolujte přihlašovací jméno a heslo. ',
  [LoginScreen.emailSent]: 'E-mail odeslán ',
  [LoginScreen.userNotFound]: 'Uživatel nenalezen ',
  [LoginScreen.wrongPassword]: 'Špatné heslo ',
  [Tabs.reservations]: 'Rezervace',
  [Tabs.login]: 'Přihlášení',
  [Tabs.contacts]: 'Kontakty',
  [Tabs.market]: 'Žádosti',
  [Tabs.ranking]: 'Žebříček',
  [Tabs.news]: 'Aktuality',
  [Courts.clay]: 'Antukový kurt',
  [Courts.hard_court]: 'Tvrdý povrch',
  [Seasons.summer]: 'Letní sezóna',
  [Seasons.winter]: 'Zimní sezóna',
  [Weekdays.monday]: 'Pondělí',
  [Weekdays.tuesday]: 'Úterý',
  [Weekdays.wednesday]: 'Středa',
  [Weekdays.thursday]: 'Čtvrtek',
  [Weekdays.friday]: 'Pátek',
  [Weekdays.saturday]: 'Sobota',
  [Weekdays.sunday]: 'Neděle',
  [ReservationsScreen.court]: 'Kurt',
  [ReservationsScreen.notLoggedInWarning]: 'Nejste přihlášen, rezervaci nelze dokončit, kontaktujte správce.',
  [ReservationsScreen.notSelectedWarning]: 'Není vybrána alespoň hodina hry. Nejprve jedním kliknutím vyberte začátek a druhým konec požadovaného časového intervalu.',
  [ReservationsScreen.alertTitle]: 'Upozornění',
  [ReservationsScreen.noSeasonAvailable]: 'V tento datum není žádná aktivní sezóna',
  [ReservationsScreen.noSeasonHeader]: 'Žádná sezóna',
  [ReservationsScreen.seasonsList]: 'Seznam budoucích sezón',
  [ReservationsScreen.noFutureSeasonsMessage]: 'Po tomto termínu není zaregistrováná žádná další sezona, buď je vámi nastavený datum příliš v budoucnosti, nebo kontaktujte správce.',
  [ReservationsScreen.courtIsLocked]: 'Kurt je zatím uzamčen',
  [ManageReservationScreen.manageReservationHeading]: 'Detail',
  [ManageReservationScreen.manageReservationTitle]: 'Detail rezervace',
  [ManageReservationScreen.reservationAuthor]: 'Žadatel',
  [ManageReservationScreen.reservationDate]: 'Datum',
  [ManageReservationScreen.reservationTimeFrom]: 'Začátek',
  [ManageReservationScreen.reservationTimeTo]: 'Konec',
  [ManageReservationScreen.reservationTypeAndPlayersHeading]: 'Hráči a nastavní typu hry',
  [ManageReservationScreen.reservationOpenGameCaption]: 'Chybí Vám hráč?',
  [ManageReservationScreen.reservationOpenGameLink]: 'zde',
  [ManageReservationScreen.reservationLongreservationCaption]: 'Zobrazit dlouhodobou rezervaci ',
  [ManageReservationScreen.reservationLongreservationLink]: 'zde',
  [ManageReservationScreen.reservationSaveButton]: 'Uložit',
  [ManageReservationScreen.reservationEditButton]: 'Upravit',
  [ManageReservationScreen.reservationApproveButton]: 'Schválit',
  [ManageReservationScreen.reservationDeclineButton]: 'Odstranit',
  [ManageReservationScreen.reservationIsOpenMessage]: 'Rezervace je otevřená a upravit leze na odkaze níže',
  [ManageReservationScreen.reservationIsOpenCaption]: 'Přejděte na svou otevřenou rezervaci ',
  [ManageReservationScreen.reservationIsHistoryMessage]: 'Rezervace už proběhla a nelze jí editovat',
  [ManageReservationScreen.reservationOnBehalf]: 'Zprostředkováno',
  [ManageReservationScreen.reservationOnBehalfRegistered]: 'Registovaný hráč?',
  [ManageReservationScreen.timingHeading]: 'Časování',
  [ManageReservationScreen.reservationPriceHeading]: 'Cena',
  [ManageReservationScreen.reservationAddToCalendar]: 'Přidat do kalendáře',
  [ManageReservationScreen.reservationRequestor]: 'Objednavatel',
  [ManageReservationScreen.reservationRequestIdLink]: 'ID Žádosti',
  [ManageReservationScreen.reservationPriceState]: 'Stav platby',
  [ManageReservationScreen.reservationPaidInfo]: 'Placeno',
  [ManageReservationScreen.reservationStatus]: 'Stav rezervace', 
  [GameSetting.gameSettingEmpty]: 'Přidat hráče',
  [GameSetting.gameSettingGameType]: 'Typ hry',
  [GameSetting.gameSettingPlayers]: 'Hráči',
  [GameSetting.gameSettingRemPlayers]: 'Další hráči',
  [GameSetting.onSelectedMessage]: 'Kliknutím na jiného hráče je prohodíte',
  [GameSetting.notifyPlayers]: 'Rozeslat pozvánky hráčům',
  [GameSetting.gameSettingsAgainst]: 'proti',
  [GameSetting.gameSettingsApplied]: 'Přihlášení hráči',
  [GameSetting.gameSettingsAddSelf]: 'Přihlásit se',
  [GameSetting.gameSettingsRemoveSelf]: 'Odhlásit se',
  [GameTypes.double]: 'Čtyřhra',
  [GameTypes.single]: 'Dvouhra',
  [GameTypes.group]: 'Nerozděleno',
  [GameTypes.training]: 'Trénink',
  [GameTypes.withMember]: 'Se členem',
  [GameTypes.withoutMember]: 'S nečlenem',
  [UnverifiedScreen.unverifiedText]: 'Jste přihlášen pomocí {{email}}, ale tento e-mail není zatím ověřený pomocí odkazu, který Vám byl zaslán do e-mailové schránky. Zkontrolujte svou schránku včetně nevyžádané pošty. Email byl odeslán z noreply@slovan-6cf12.firebaseapp.com. ',
  [UnverifiedScreen.resendMessage]: 'Pokud ho nenaleznete, nebo již nefunguje, zkuste si zaslat nový ověřovací e-mail ',
  [UnverifiedScreen.logoutMessage]: 'Pokud se chcete přihlásit k jinému účtu, nejdřív se ',
  [UnverifiedScreen.resedVerifEmail]: 'zde',
  [UnverifiedScreen.logout]: 'odhlašte',
  [ContactsScreen.selectButton]: 'Vybrat',
  [ContactsScreen.contactsMyList]: 'Můj seznam',
  [ContactsScreen.contactsAllClubList]: 'Všichni registrovaní',
  [ContactsScreen.contactsAllMembList]: 'Všichni členové',
  [ContactsScreen.contactsScreenTitle]: 'Kontakty',
  [ContactsScreen.noContactsYetMessage]: 'V seznamu zatím nejsou žádné kontakty',
  [ContactsScreen.contactsScreenMyContacts]: 'Mé kontakty',
  [ContactsScreen.contactsClubmembersTitle]: 'Členové',
  [ContactsScreen.contactsOtherTitle]: 'Ostatní kontakty',
  [ContactsScreen.contactsNotMember]: 'Bez členství',
  [ContactsScreen.contactsManagedBy]: 'Spravován: ',
  [ContactsScreen.contactsSearch]: 'Hledat',
  [ProductPaymentDescriptions.credit]: 'Platba za kredit v klubu',
  [ProductPaymentDescriptions.subscription]: 'Platba za členství v klubu',
  [ProductPaymentDescriptions.reservation]: 'Platba za hru na hřišti v klubu',
  [ProfileScreen.profileAddToContactsButton]: 'Přidat do kontaktů',
  [ProfileScreen.profileRemToContactsButton]: 'Odebrat z kontaktů',
  [ProfileScreen.profileEditButton]: 'Nastavení profilu',
  [ProfileScreen.profileFirstName]: 'Jméno',
  [ProfileScreen.profileFamilyName]: 'Příjmení',
  [ProfileScreen.profileBirthDate]: 'Datum narození',
  [ProfileScreen.profileEmail]: 'E-mail',
  [ProfileScreen.profilePhone]: 'Telefón',
  [ProfileScreen.profileICO]: 'IČO',
  [ProfileScreen.profileDIC]: 'DIČ',
  [ProfileScreen.profileAddress]: 'Adresa',
  [ProfileScreen.profileCity]: 'Město',
  [ProfileScreen.profileScreenTitle]: 'Profil',
  [ProfileScreen.profileDelete]: 'Zrušit účet',
  [ProfileScreen.profileDeletionOK]: 'Zrušení účtu proběhlo v pořádku',
  [ProfileScreen.profileDeletionKO]: 'Zrušení účtu se nepodařilo',
  [ProfileScreen.profileDeletionConfirmationQuestion]: 'Opravdu si přejete smazat účet? Tato akce je nevratná a včechna data na vašem účtu budou odstraněna. Opětovné založení účtu neobnoví předchozí nastavení.',
  [ProfileScreen.profileDeleteCancel]: 'Storno',
  [ProfileScreen.profileLast10games]: 'Hry - posledních 10',
  [ProfileScreen.profileDetail]: 'Detail',
  [ProfileScreen.profileSaveButton]: 'Uložit',
  [ProfileScreen.profileRoles]: 'Role',
  [ProfileScreen.profileMembershipTitle]: 'Členství',
  [ProfileScreen.profileMembershipFrom]: 'Začátek',
  [ProfileScreen.profileMembershipTo]: 'Konec',
  [ProfileScreen.profileAddSubscription]: 'Přidat členství',
  [ProfileScreen.profileSubscriptionNA]: 'Neplatné',
  [ProfileScreen.profileCheckoutsButton]: 'Objednávky',
  [ProfileScreen.profileModRolesButton]: 'Upravit role',
  [ProfileScreen.profileClubHomepage]: 'URL stránek',
  [ProfileScreen.profileClubVOP]: 'URL VOP',
  [LongReservationScreen.addButton]: 'Přidat',
  [LongReservationScreen.additionalHeading]: 'Dodatečné informace',
  [LongReservationScreen.approveButton]: 'Schválit',
  [LongReservationScreen.collisions]: 'Kolidující rezervace',
  [LongReservationScreen.court]: 'Kurt',
  [LongReservationScreen.errorCourtNotSelected]: 'Kurt nebyl vybrán.',
  [LongReservationScreen.errorLongDateFuture]: 'Datum musí být v budoucnosti.',
  [LongReservationScreen.errorLongDateOOB]: 'Datum musí být v mezích sezóny.',
  [LongReservationScreen.errorSeasonNTBSelected]: 'Sezóna musí být vybrána.',
  [LongReservationScreen.errorTimeNotDivisible]: 'Čas musí být v 30-minutových intervalech',
  [LongReservationScreen.errorTimeNotOpenHours]: 'Čas musí být v rozmezí otevírací doby',
  [LongReservationScreen.errorTimeToLTTimeFrom]: 'Čas do musí být větší než čas od',
  [LongReservationScreen.errorNotWIPlanPer]: 'Není v plánovacím nebo sezoním období.',
  [LongReservationScreen.messageHeading]: 'Zpráva žádosti',
  [LongReservationScreen.messagePlaceholder]: 'Zpráva',
  [LongReservationScreen.modifyButton]: 'Uložit',
  [LongReservationScreen.periodFrom]: 'Období od',
  [LongReservationScreen.periodTo]: 'Období do',
  [LongReservationScreen.periodHeading]: 'Období',
  [LongReservationScreen.reccurency]: 'Opakování',
  [LongReservationScreen.removeButton]: 'Odstranit',
  [LongReservationScreen.requestExpires]: 'Datum expirace',
  [LongReservationScreen.requestDate]: 'Datum vzniku',
  [LongReservationScreen.season]: 'Sezóna',
  [LongReservationScreen.timeEnd]: 'Čas do',
  [LongReservationScreen.timeStart]: 'Čas od',
  [LongReservationScreen.timingHeading]: 'Časování',
  [LongReservationScreen.viewButton]: 'Zobrazit',
  [LongReservationScreen.weekday]: 'Den v týdnu',
  [LongReservationScreen.periodMessage]: "Toto je žádost o dlouhodobou rezervaci na {{season}}u  v rozmezí {{from}} do {{to}} každé/á {{weekday}} s {{recur}}m opakováním. ",
  [LongReservationScreen.timingMessage]: "Požadované časové rozmezí je od {{timeFrom}} do {{timeTo}} na kurtě {{court}}. ",
  [LongReservationScreen.childReservations]: "Rezervace",
  [LongReservationScreen.longResFuture]: "Budoucí",
  [LongReservationScreen.longResAll]: "Všechny",
  [LongReservationScreen.longResDialogRemoval]: "Chcete odstranit pouze budoucí rezervace nebo všechny?",
  [Reccurency.weekly]: 'Týdenní',
  [OpenGameScreen.addButton]: 'Přidat',
  [OpenGameScreen.approveButton]: 'Schválit',
  [OpenGameScreen.commentButton]: 'Komentovat',
  [OpenGameScreen.sendButton]: 'Odeslat',
  [OpenGameScreen.commentsHeading]: 'Komentáře',
  [OpenGameScreen.connectWithResButton]: 'Spojit s rezervací',
  [OpenGameScreen.createReservationButton]: 'Vytvořit rezervaci',
  [OpenGameScreen.dateTimeHeading]: 'Termín',
  [OpenGameScreen.errorNeedToBeFuture]: 'Datum musí být v budoucnosti',
  [OpenGameScreen.errorNotInOpeningHours]: 'Čas musí být v rozmezí otevíracích hodin', 
  [OpenGameScreen.errorTooFarAway]: 'Plánovat lze pouze do 14ti dní',
  [OpenGameScreen.errorNoSeason]: 'V tomto termínu není žádná aktivní sezóna',
  [OpenGameScreen.gameSettingsHeading]: 'Nastavení hry',
  [OpenGameScreen.messageHeading]: 'Zpráva',
  [OpenGameScreen.messagePlaceholder]: 'Zpráva',
  [OpenGameScreen.modifyButton]: 'Uložit',
  [OpenGameScreen.noReservationConnectedMessage]: 'Otevřená hra není spojena s žádnou rezervací, termín je orientační. ',
  [OpenGameScreen.removeButton]: 'Smazat',
  [OpenGameScreen.requestExpires]: 'Datum',
  [OpenGameScreen.reservationConnectedMessage]: 'Termín hry je již zarezervovaný. ',
  [OpenGameScreen.termOfGameMessage]: 'Termín uskutečnění je ',
  [OpenGameScreen.timeEnd]: 'Do',
  [OpenGameScreen.timeStart]: 'Od',
  [OpenGameScreen.noMessageMessage]: 'Není uvedena žádná zpráva v žádosti.',
  [OpenGameScreen.opengameDeclineButton]: 'Zamítnout',
  [ManageMarketScreen.manageMarketTitle]: 'Žádost',
  [ManageMarketScreen.manageMarketStatus]: 'Stav žádosti',
  [MarketScreen.addMyselfLink]: 'Žádat účast',
  [MarketScreen.filterMyRequests]: 'Mé žádosti',
  [MarketScreen.freeSpotsMessage]: 'Zbývají {{number}} místa. ',
  [MarketScreen.periodHeading]: 'Období',
  [MarketScreen.reccurencyMessage]: 'Každou ',
  [MarketScreen.allClubs]: 'Všechny kluby',
  [MarketTypes.opengame]: 'Hledání hráče',
  [MarketTypes.longreservation]: 'Dlouhodobá rezervace',
  [MarketTypes.subscription]: 'Členství',
  [MarketTypes.reservation]: 'Rezervace',
  [MarketTypes.credit]: 'Kredit',
  [NewsScreen.expirationDate]: 'Datum expirace',
  [NewsScreen.includeApplicationList]: 'Zahrnout přihlašovací seznam',
  [NewsScreen.notifyAllClubmembers]: 'Notifikovat všechny členy',
  [NewsScreen.saveButton]: 'Uložit',
  [NewsScreen.removeButton]: 'Odstranit',
  [NewsScreen.messagePlaceholder]: 'Zpráva',
  [NewsScreen.newsScreenTitle]: 'Aktuality', 
  [NewsScreen.newsType]: 'Typ aktuality',
  [NewsTypes.information]: 'Informativní',
  [NewsTypes.tournament]: 'Turnaj',
  [Messages.collapseCaption]: 'Srolovat',
  [Messages.messagesHeading]: 'Komentáře',
  [Messages.viewAllCaption]: 'Zobrazit {{number}} zpráv',
  [NewsEntity.applyButton]: 'Přihlásit se',
  [NewsEntity.unapplyButton]: 'Odhlásit se',
  [NewsEntity.applyHeading]: 'Seznam',
  [AppliedPlayers.applicationsHeading]: 'Seznam přihlášených',
  [RightMenu.contactsRoute]: 'Kontakty',
  [RightMenu.homeRoute]: 'Domů',
  [RightMenu.logoutRoute]: 'Odhlásit',
  [RightMenu.profileRoute]: 'Profil',
  [RightMenu.checkoutsRoute]: 'Moje objednávky',
  [RightMenu.statisticsRoute]: 'Statistiky',
  [RightMenu.editClubRoute]: 'Nastavení klubu',
  [RightMenu.myClubsRoute]: 'Nastavení mých klubů',
  [RightMenu.notificationsRoute]: 'Oznámení',
  [IndexScreen.clubPlaceholder]: 'Vyberte klub',
  [Alerts.saved]: 'Uloženo',
  [Alerts.savedMessage]: 'Změny byly uloženy...',
  [CameraScreen.cameraFlip]: 'Otočit',
  [CameraScreen.cameraGallery]: 'Galerie',
  [CameraScreen.cameraTitle]: 'Kamera',
  [CameraScreen.cameraUpload]: 'Nahrát',
  [ResultScore.addSet]: 'Přidat set',
  [ResultScore.removeSet]: 'Odebrat set',
  [ResultScore.rankedGame]: 'Hra do žebříčku',
  [ResultScore.resultCaption]: 'Výsledek zápasu',
  [ResultScore.saveResult]: 'Uložit výsledek',
  [ResultScore.resultScoreResult]: "Výsledné skóre",
  [RankingEntry.losses]: 'Proher',
  [RankingEntry.points]: 'Bodů',
  [RankingEntry.wins]: 'Vítězství',
  [RankingScreen.rankingAddButton]: 'Přihlásit se',
  [CheckoutScreen.requiresPaymentText]: 'Před schválováním je potřeba autorizovat platbu. V případě následovného schválení si klub částku stáhne. V případě že ne, bude částka vrácena.',
  [CheckoutScreen.requiresRefundText]: 'Tato žádost již byla uhrazena, před jejím odstraněním je třeba nejprve stornovat/vrátit platbu.',
  [CheckoutScreen.checkoutNotification]: 'Upozornění',
  [CheckoutScreen.checkoutAcceptPayment]: 'Přijmout platbu',
  [CheckoutScreen.checkoutProceedToPayment]: 'K platbě',
  [CheckoutScreen.checkoutPaidAmt]: 'Uhrazená částka',
  [CheckoutScreen.checkoutCapturableAmt]: 'Částka rezervovaná',
  [CheckoutScreen.checkoutSubtotal]: 'Celkem',
  [CheckoutScreen.checkoutSummary]: 'Souhrn',
  [CheckoutScreen.checkoutTitle]: 'Účtenka',
  [CheckoutScreen.checkoutPaymentsList]: 'Historie plateb',
  [CheckoutScreen.checkoutPromisePayLater]: 'Uhradím později',
  [CheckoutScreen.checkoutVOP]: 'Obchodní podmínky',
  [PaymentState.paymentStateBilled]: 'Neuhrazeno',
  [PaymentState.paymentStateEstimate]: 'Odhadnuto',
  [PaymentState.paymentStateFullyCharged]: 'Plně autorizováno',
  [PaymentState.paymentStateFullyPaid]: 'Plně uhrazeno',
  [PaymentState.paymentStateOverCharged]: 'Plně autorizováno s přeplatkem',
  [PaymentState.paymentStateOverPaid]: 'Plně uhrazeno s přeplatkem',
  [PaymentState.paymentStatePartlyCharged]: 'Částečně autorizováno',
  [PaymentState.paymentStatePartlyPaid]: 'Částečně uhrazeno',
  [PaymentState.paymentStateManual]: 'Vlastní vstup',
  [MarketStatuses.approved]: 'Schváleno',
  [MarketStatuses.blocked]: 'Ve schvalování',
  [MarketStatuses.closed]: 'Ukončeno',
  [MarketStatuses.declined]: 'Zamítnuto',
  [MarketStatuses.deleted]: 'Stornováno',
  [MarketStatuses.open]: 'Založeno',
  [MarketStatuses.new]: 'Nová',
  [CheckoutItemsTypes.fee]: 'Administrativní poplatek',
  [CheckoutItemsTypes.reservationuser]: 'Podíl uživatele',
  [CheckoutItemsTypes.yearSubscription]: 'Roční členství',
  [CheckoutItemsTypes.credit]: 'Kredit',
  [PaymentScreen.paymentChangePaymentMode]: 'Změnit',
  [PaymentScreen.paymentFreeCredit]: 'Volný kredit',
  [PaymentScreen.paymentPaymentMode]: 'Způsob platby',
  [PaymentScreen.paymentTotalForPayment]: 'Celkem k platbě',
  [PaymentScreen.paymentTotalFromCredit]: 'Z kreditu',
  [PaymentScreen.paymentTotalToCredit]: 'Do kreditu',
  [PaymentScreen.paymentPrevCreditValue]: 'Pův. stav kreditu',
  [PaymentScreen.paymentNewCreditValue]: 'Nový stav kreditu',
  [PaymentScreen.paymentPaidValue]: 'Zaplaceno',
  [PaymentScreen.paymentCapturableValue]: 'Blokováno',
  [PaymentScreen.paymentRefundedValue]: 'Vráceno',
  [PaymentScreen.paymentDetail]: 'Detail platby',
  [PaymentScreen.paymentTransactionHeading]: 'Transakce',
  [PaymentScreen.paymentState]: 'Stav',
  [PaymentScreen.paymentID]: 'ID',
  [PaymentScreen.paymentButtonPay]: 'Zaplatit',
  [PaymentScreen.paymentCancelButton]: 'Zrušit',
  [PaymentScreen.paymentCloseButton]: 'Zavřít',
  [PaymentScreen.paymentChargeButton]: 'Zúčtovat',
  [PaymentScreen.paymentPrintButton]: 'Tisknout',
  [PaymentScreen.paymentReinitButton]: 'Obnovit',
  [PaymentScreen.paymentTitle]: 'Platba',
  [PaymentScreen.paymentMethodText]: "Vyberte jednu z možností platby. Pokud chcete uhradit v hotovosti, domluvte se se správcem.",
  [PaymentScreen.paymentFinishButton]: "Dokončit",
  [PaymentModes.paymentModeBT]: 'QR kód',
  [PaymentModes.paymentModeCard]: 'Platba online',
  [PaymentModes.paymentModePayU]: 'Platba online',
  [PaymentModes.paymentModeCredit]: 'Kredit',
  [PaymentModes.paymentModeCash]: 'Hotovost',
  [PaymentModes.paymentModeNone]: 'Nevybráno',
  [PaymentTransactionStatuses.amount_capturable_updated]: 'Částka rezervována',
  [PaymentTransactionStatuses.cancelled]: 'Zrušeno',
  [PaymentTransactionStatuses.refunded]: 'Vráceno',
  [PaymentTransactionStatuses.refunding]: 'Vrácení ve zpracování',
  [PaymentTransactionStatuses.initiated]: 'Zahájeno',
  [PaymentTransactionStatuses.succeeded]: 'Dokončeno',
  [PaymentTransactionStatuses.capturing]: 'Dokončuji',
  [MarketCommons.marketCommonsCheckoutID]: 'ID Účtenky',
  [MarketCommons.marketCommonsPrevMarketID]: 'Předchozí žádost',
  [SubscriptionScreen.subscriptionCurrEnds]: 'Současné končí',
  [SubscriptionScreen.subscriptionEnd]: 'Konec',
  [SubscriptionScreen.subscriptionMembership]: 'Členství',
  [SubscriptionScreen.subscriptionScreenTitle]: 'Prodloužit členství',
  [SubscriptionScreen.subscriptionStart]: 'Začátek',
  [SubscriptionScreen.subscriptionType]: 'Typ členství',
  [SubscriptionScreen.subscriptionPriceVariant]: 'Varianta',
  [CreditScreen.creditAmount]: 'Částka kreditu',
  [CreditScreen.creditClub]: 'Klub',
  [CreditScreen.creditHeading]: 'Detail kreditu',
  [CreditScreen.creditCurrentValue]: 'Aktuální kredit',
  [CreditScreen.creditScreenTitle]: 'Žádost o kredit',
  [RoleTypes.isAdmin]: 'Správce',
  [RoleTypes.isGod]: 'Supersprávce',
  [RoleTypes.isCoach]: 'Trenér',
  [RoleTypes.isClubRegistered]: 'Registrovaný',
  [RoleTypes.isMember]: 'Člen',
  [RoleTypes.all]: 'Všichni',
  [RoleTypes.isTester]: 'Tester', 
  [RoleTypes.isChild]: 'Dítě', 
  [RoleTypes.isPensioner]: 'Důchodce', 
  [ManageClubScreen.manageClubAddCourtButton]: "Přidat kurt",
  [ManageClubScreen.manageClubAddSeasonButton]: "Přidat sezónu",
  [ManageClubScreen.manageClubClubName]: "Jméno klubu",
  [ManageClubScreen.manageClubCourtId]: "ID kurtu",
  [ManageClubScreen.manageClubCourtType]: "Typ kurtu",
  [ManageClubScreen.manageClubCourts]: "Hřiště",
  [ManageClubScreen.manageClubProductsHeading]: "Členství",
  [ManageClubScreen.manageClubSaveButton]: "Uložit",
  [ManageClubScreen.manageClubSeasonsHeading]: "Sezóny",
  [ManageClubScreen.manageClubScreenTitle]: "Nastavení klubu",
  [ManageClubScreen.manageClubPaymentsHeading]: "Platby",
  [ManageClubScreen.manageClubPaymentsCurr]: "Měna",
  [ManageClubScreen.manageClubAllowedGameTypes]: "Povolené typy her",
  [ManageClubScreen.manageClubActiveUntil]: "Aktivní do",
  [ManageClubScreen.manageClubPaymentsPromiseAllowed]: "Příslib platby",
  [ManageClubScreen.manageClubAutoCapture]: "Automaticky získat blokované peníze",
  [ManageClubScreen.manageClubPayUId]: "ID brány PAYU",
  [ManageClubScreen.manageClubOnlinePaymentsAllowed]: "Povolit online platby",
  [ManageClubScreen.manageClubTourPrice]: "Cena členství",
  [ManageClubScreen.manageClubAgeRoles]: "Role podle věku",
  [ManageClubScreen.manageClubGeneralInfo]: "Základní informace",
  [ManageClubScreen.manageClubQRIBAN]: "QR IBAN",
  [ManageClubScreen.manageClubCreditPaymentsAllowed]: "Povolit platby kreditem",
  [ManageClubScreen.manageClubQRPaymentsAllowed]: "Povolit QR platby",
  [ManageClubScreen.manageClubPaymentsSetting]: "Nastavení plateb",
  [ManageSeasonScreen.manageSeasonSaveButton]: 'Uložit',
  [ManageSeasonScreen.manageSeasonSeasonType]: 'Typ sezóny',
  [ManageSeasonScreen.manageSeasonTour]: 'Turnus',
  [ManageSeasonScreen.manageSeasonPlanFrom]: 'Plánování od',
  [ManageSeasonScreen.manageSeasonPlanTo]: 'Plánování do',
  [ManageSeasonScreen.manageSeasonPlanningFinished]: 'Plánování ukončeno',
  [ManageSeasonScreen.manageSeasonLockedFrom]: 'Zamknuto od',
  [ManageSeasonScreen.manageSeasonLockedTo]: 'Zamknuto do',
  [ManageSeasonScreen.manageSeasonFrom]: 'Od',
  [ManageSeasonScreen.manageSeasonTo]: 'Do',
  [ManageSeasonScreen.manageSeasonOpenFrom]: 'Otevřeno od',
  [ManageSeasonScreen.manageSeasonOpenTo]: 'Otevřeno do',
  [ManageSeasonScreen.manageSeasonCourtsHeading]: 'Hřiště',
  [ManageSeasonScreen.manageSeasonSeasonHeading]: 'Sezóna: {{id}}',
  [ManageSeasonScreen.manageSeasonButtonsBasic]: 'Základní',
  [ManageSeasonScreen.manageSeasonButtonsCourts]: 'Hřiště',
  [ManageSeasonScreen.manageSeasonButtonsPrices]: 'Ceny',
  [ManageSeasonScreen.manageSeasonButtonsPricesAdd]: 'Přidat ceník',
  [ManageSeasonScreen.manageSeasonUnlockDate]: 'Datum odemknutí: ',
  [ManageSeasonScreen.manageSeasonPaid]: 'Placeno',
  [ManageSeasonScreen.manageSeasonPromised]: 'Platba přislíbena',
  [ManageSeasonScreen.manageSeasonScreenTitle]: 'Nastavení sezóny',
  [ManageMyClubsScreen.myClubsAddButton]: 'Přidat',
  [ManageMyClubsScreen.myClubsAddClubSubscription]: 'Přidat klub mezi vybrané',
  [ManageMyClubsScreen.myClubsMyClubsHeading]: 'Mé kluby',
  [ManageMyClubsScreen.myClubsRemoveButton]: 'Odebrat',
  [ManageMyClubsScreen.myClubsScreenTitle]: 'Moje kluby',
  [ManageMyClubsScreen.myClubsSelectClub]: 'Vybrat klub',
  [ManageMyClubsScreen.myClubsMessage]: 'Vyberte své oblíbené kluby. Můžete tímto odebírat novinky, vidět žádosti o doplnění hráčů a samozřejmě vidět rozvrh hodin. \n\nPřepněte na jiný klub dlouhým stisknutím.',
  [NewsFilters.allClubs]: 'Všechny kluby',
  [NewsFilters.informations]: 'Informativní',
  [NewsFilters.tournaments]: 'Turnaje',
  [NotificationTypes.market]: 'Žádost',
  [NotificationTypes.news]: 'Novinky',
  [NotificationTypes.payments]: 'Platby',
  [NotificationTypes.reservation]: 'Rezervace',
  [Functions.undefined]: "Neuvedeno",
  [Functions.notSelected]: "Nevybráno",
  [MembershipTypes.yearSubscription]: "Roční (365dní)",
  [MembershipTypes.tourSubscription]: "Turnus (ročník)",
  [ManageLinkedUsersScreen.manageLinkedUserTitle]: "Editace spravovaného účtu",
  [LinkedUsersScreen.linkedUserTitle]: "Spravované účty",
  [LinkedUsersScreen.linkedUserMessage]: "Vámi vytvořené účty. Zodpovídáte za osobní údaje těchto osob.",
  [ManageAllClubsScreen.allClubsScreenTitle]: 'Nastavení klubů',
  [ReservationFilters.openState]: 'Plánované',
  [AvatarComponent.avatarComponentUnknown]: 'Neznámý',
  [CheckoutListScreen.checkoutListScreenTitle]: 'Seznam objednávek',
  [ReservationStatisticsScreen.reservationStatisticsScreenTitle]: 'Statistiky',
  [AboutAppScreen.aboutAppVersion]: 'Verze',
  [AboutAppScreen.aboutContactMail]: 'Email',
  [AboutAppScreen.aboutContactPhone]: 'Telefón',
  [AboutAppScreen.aboutDeveloper]: 'Vývojář',
  [AboutAppScreen.aboutScreenTitle]: 'O aplikaci',
  [AboutAppScreen.aboutScreenUpdateId]: 'Update ID',
  [ReservationsScreen.reservationBlockedTime]: 'Mimo provoz',
  [ButtonCaptions.buttonsAddButton]: 'Přidat',
  [ButtonCaptions.buttonsRemoveButton]: 'Odebrat',
}