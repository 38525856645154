import React from 'react'
import { StyleSheet, Text, Pressable, View } from 'react-native'


import { Club, user } from '../../functions/src/interfaces'
import i18n, { tokens } from '../i18n'
import { useAppNavigation } from '../store/hooks'
import { normalize } from '../utilities/constants'
import UserAvatar from './UserAvatar'

type Props = {
    item: Club,
    children?: any,
    action?: () => void,
    longPressAction?: () => void,
    highlighted?: boolean,
}

const RenderContactClub:React.FC<Props> = (props) => {

    const navigation = useAppNavigation()
    
    //const contactFlag = 
    //console.log('nalezeno', props)
    
    const styles = StyleSheet.create({
        
        entityContainer: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 2,
            paddingBottom: 3,
            paddingTop: 3,
            backgroundColor: "white",
            marginLeft: 5,
            marginRight: 5,
            borderRadius: 5,
            borderColor: "darkgrey",
            borderWidth: props.highlighted ? 1 : 0
        },
        entityText: {
            fontSize: 16,
            color: '#333333'
        }
    })
    return (
        <View style={styles.entityContainer}>
        <Pressable style={{flexDirection: "row", flex: 1}} 
            onLongPress={props.longPressAction}
            onPress={() => {
            //console.log('Chci navigovat na: ', props.item.uid)
            if (props.action) {
                props.action()
            } else {
                navigation.navigate('Profile', {uid: props.item.firebaseId})
            }
            
            }}>
                <UserAvatar uid={props.item.firebaseId} size={normalize(50)}/>
            
            <View style={{flexDirection: "column", marginLeft: 5, justifyContent: "center"}}>
            <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>
            {props.item ? props.item.club_name : 'Error'}
            </Text>
            
            </View>
            
            <View style={{alignSelf: "center", marginLeft: "auto"}}>
                {props.children ? props.children : null}
            </View>
            </Pressable>
        </View>
    )
}





export default RenderContactClub

