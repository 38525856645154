import React, { useEffect, useState } from "react";
import i18n, { tokens } from "../../../i18n";
import ClubList from "../../../components/ClubsList";
import { Club } from "../../../../functions/src/interfaces";
import { useAppSelector } from "../../../store/hooks";
import { RootStore } from "../../../store/reducers/rootReducer";
import { shallowEqual } from "react-redux";

type Props = {
    selectedClubs: string[],
    addSelectedAction: (club: string) => void,
    selectClubAction?: (club: string) => void,
    removeSelectedAction?: (club: string) => void,
}

function MyClubsComponent(props: Props) {
    const allClubs: {[clubId: string]: Club} = useAppSelector((state: RootStore) => state.auth.allClubs, shallowEqual)
    const selectedClub: string = useAppSelector((state:RootStore) => state.auth.selectedClub, shallowEqual)
    const [availableClubs, setAvailableClubs] = useState([])
    useEffect(() => {
        let keys = Object.keys(allClubs)
        var options = []
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let club: Club = allClubs[key]
            if (!props?.selectedClubs.includes(key) && club.firebaseId) {
                
                let entry = { label: club.club_name, value: key }
                options.push(club.firebaseId)
            }
        }
        setAvailableClubs(options)
    }, [props.selectedClubs, allClubs])


    return (
        <>
        {props.selectedClubs && props.selectedClubs.length > 0?
        <ClubList 
        heading={i18n.t(tokens.app.screens.myClubs.myClubsMyClubsHeading)}
        availableClubs={props.selectedClubs} 
        clubButtonAction={props.removeSelectedAction}
        clubButtonCaption={i18n.t(tokens.app.screens.myClubs.myClubsRemoveButton)}
        clubLongPressAction={props.selectClubAction}
        selectedClub={selectedClub}
        />
        :null}
        {availableClubs.length > 0?
        <ClubList 
        heading={i18n.t(tokens.app.screens.myClubs.myClubsAddClubSubscription)}
        availableClubs={availableClubs} 
        clubButtonAction={props.addSelectedAction}
        clubButtonCaption={i18n.t(tokens.app.screens.myClubs.myClubsAddButton)}
        //clubLongPressAction={selectClub}
        selectedClub={selectedClub}
        />
        :null}
        </>
    )
}

export default MyClubsComponent