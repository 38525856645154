
import * as Updates from 'expo-updates'
import Constants from 'expo-constants';
import ENV_VARS from './settingVars';

const localhost = "localhost:8080" 


const getEnvVars = (env = Updates.channel) => {
 // What is __DEV__ ?
 // This variable is set to true when react-native is running in Dev mode.
 // __DEV__ is true when run locally, but false when published.
  console.log(ENV_VARS)
 if (env == 'test') {
    return ENV_VARS.test;  
  } else if (env == 'prod') {
    return ENV_VARS.prod;  
 } if (Constants.expoConfig.extra.variant == 'test') {
  return ENV_VARS.test;  
} else if (Constants.expoConfig.extra.variant == 'prod') {
  return ENV_VARS.prod;  
} else if (__DEV__) {
  return ENV_VARS.dev;
} else {
  return ENV_VARS.dev;
}
};


export default getEnvVars;
