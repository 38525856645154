import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { gamePlayers } from '../../functions/src/interfaces'
import { Set } from '../store/reducers/reservationReducer'
import { secondColor } from '../utilities/globalStyles'
import ClicableUserAvatar from './ClickableUserAvatar'
import FormEntryNoCaption from './FormEntryNoCaption'

type Props = {
    uid: string,
    result: Set[],
    gamePlayers: gamePlayers
}

const ResultItemUser: React.FC<Props> = (props) => {
    const uid = props.uid
    const result = props.result
    const gamePlayers = props.gamePlayers

    const side = gamePlayers[uid].side
    const makeSideArrs = (side, uid, gamePlayers) => {
        let uids = Object.keys(gamePlayers)
        var out = {me: [uid], opponent: []}
        uids.forEach((u) => {
            if (u == uid) {
                return
            } else if (gamePlayers[u].side == side) {
                out['me'].push(u)
            } else {
                out['opponent'].push(u)
            }
        })
        return out
    }

    const [sides, setSides] = useState({me:[],opponent:[]})
    useEffect(() => {
        setSides(makeSideArrs(side, uid, gamePlayers))
    },[uid,gamePlayers])
    
    const style = StyleSheet.create({
        sideContainer: {
            flexDirection: "row"
        },
        entityContainer: {
            flexDirection: 'column',
            //justifyContent: 'space-between',
            marginTop: 3,
            width: "100%",
            //borderBottomColor: '#cccccc',
            //borderBottomWidth: 0,
            //paddingBottom: 0,
            //alignSelf: "stretch",
            //width: '100%',
            //width: windowWidth - 2*padding,
            //paddingLeft: padding,
            //paddingRight: padding,
        },
        entityRow: {
            flexDirection: 'row',
            width: "100%",
            alignItems: "center",
            //backgroundColor: "white",
            minHeight: 50,
            borderColor:  "grey",
            //borderWidth: 1,
            borderRadius: 5,
            paddingLeft: 10,
            paddingRight: 10,
            justifyContent: "space-evenly"
        },
        buttonRow: {
            flexDirection: 'row',
            width: "100%",
            alignItems: "center",
            minHeight: 50,
            justifyContent: "center"
            
        },
        entityIcon: {
            //flex: iconFlex,
        },
        entityCaption: {
            //flex: captionFlex,
        },
        entityChildren: {
            //flex: childrenFlex,
            //alignSelf: "flex-end",
            marginLeft: "auto"
        },
        errorStyle: {
            color: "red",
        },
        errorContainer: {
            //flex: 1,
            alignItems: "flex-end",
        },
        buttonAdd:{
            backgroundColor: secondColor,
            borderRadius: 30,
            width: 60,
            height: 60,
            justifyContent: "center",
            alignItems: "center"
        }
    })

  
    return (
        <FormEntryNoCaption
        editable={false}>
        <View style={style.entityRow}>
            <View style={style.sideContainer}>
            {sides.me.map((item) => {
                return (
                    <ClicableUserAvatar key={item} uid={item} size={40} onPress={() => {}}/>
                )
            })}
            </View>
            <Text maxFontSizeMultiplier={1.2}>
                {
                    result.map((set,index) => {
                        let resultLen = result.length
                        return (
                            set[side].games + ':' + set[side=='home'?'away':'home'].games + (index == resultLen - 1 ? '':', ')
                        )
                    })
                }
            </Text>
            <View style={style.sideContainer}>
            {sides.opponent.map((item) => {
                return (
                    <ClicableUserAvatar key={item} uid={item} size={40} onPress={() => {}}/>
                )
            })}
            </View>
        </View>
        </FormEntryNoCaption>
    )
}



export default ResultItemUser

