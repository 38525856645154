import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TextInput, Button, Alert, Platform, Pressable, ActivityIndicator } from "react-native";
import Constants from "expo-constants";
import { shallowEqual, useSelector } from "react-redux";
import FormHeading from "../../../components/FormHeading";
import { Route, useNavigation } from "@react-navigation/native";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getCurrentUserWithRoles } from "../../../store/selectors";
import BigButton from "../../../components/BigButton";
import { handleCheckoutAction, subscribeForPayment } from "../../../store/actions/actionsCheckout";
import { Club, PaymentData, PaymentModes } from "../../../../functions/src/interfaces";
import { RootStore } from "../../../store/reducers/rootReducer";
import { StateLoader } from "../../../store/reducers/checkoutReducer";
import FormEntry from "../../../components/FormEntryNew";
import i18n, { tokens } from "../../../i18n";


//ADD localhost address of your server
const API_URL = Constants.expoConfig.extra.functionsUrlBase

type Props = {
    club: string,
    paymentId?: string,
}



const PaymentDetail: React.FC<Props> = props => {
    const {club, paymentId} = props
    const dispatch = useAppDispatch()
    //const selectedClub = useSelector(state => state.auth.selectedClub, shallowEqual)
    
    const paymentData: StateLoader<PaymentData> = useAppSelector((state:RootStore) => state.checkout.subscribedPayment, shallowEqual)
    
    



    useEffect(() => {
        if (paymentId && paymentId != "" && club && club != "") {
            dispatch(subscribeForPayment(paymentId, club, (bool, context) => {}))
        }
    }, [paymentId, club])


 


    return (
        paymentData.data?.firebaseId?
        <View style={styles.container}>
            {paymentData.isLoaded?
            <>
            <FormHeading 
            heading={i18n.t(tokens.app.screens.payment.paymentTransactionHeading)}/>
            <FormEntry
            caption={i18n.t(tokens.app.screens.payment.paymentState)}
            editable={false}
            >
                <Text>{paymentData.data.status?i18n.t(paymentData.data.status):""}</Text>
            </FormEntry>
            <FormEntry
            caption={i18n.t(tokens.app.screens.payment.paymentID)}
            editable={false}
            >
                <Text>{paymentData.data.paymentIntentId}</Text>
            </FormEntry>
            </>
            :null}
            
            
        </View>
        :null
    );
};
export default PaymentDetail;

const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: "column",
        width: "75%",
        alignItems: "center",
        //justifyContent: "space-evenly",
    },
    methodSelectTitle: {
        fontSize: 14,
        letterSpacing: 1.5,
        color: 'black',
        textTransform: 'uppercase'
    },
    container: {
        flex: 1,
        //width: "100%",
        justifyContent: "center",
        margin: 20,
        //alignItems: "center",  
    },
    input: {
        backgroundColor: "#efefefef",

        borderRadius: 8,
        fontSize: 20,
        height: 50,
        padding: 10,
    },
    card: {
        backgroundColor: "#efefefef",
    },
    cardContainer: {
        height: 50,
        marginVertical: 30,
    },
    buyButton: {
        backgroundColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
    },
    textButton: {
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        color: '#007DFF'
    },
    selectButton: {
        borderColor: '#007DFF',
        paddingHorizontal: 32,
        paddingVertical: 16,
        borderRadius: 8,
        borderWidth: 2,
    },
    boldText: {
        fontSize: 17,
        fontWeight: '700'
    }
});
