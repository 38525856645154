import React, { useState } from 'react'
import { Image, Platform, Text, TextInput, Pressable, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styles from './styles';
import { firebase } from '../../store/firebase/config'
import { connect } from 'react-redux'
import { signIn, resendVerificationUser, resetPasswordEmail } from '../../store/actions/actionsAuth'
import WebWrapper from '../../components/WebWrapper';
import i18n, { tokens } from '../../i18n';
import RightTopBar from '../../components/RightTopBar';
import { globalStyleSheet } from '../../utilities/globalStyles';
import LoginForm from './LoginComponents/LoginForm';

function LoginScreen(props) {
    //console.log(navigation)



    

    

    


    return (
        <View style={styles.container}>
            {/* <RightTopBar title={props.route?.name}/> */}
                
            
                <KeyboardAwareScrollView
                    style={{ width: '100%', height: 1000 }}
                    
                    contentContainerStyle={{flexGrow: 1}}
                    enableResetScrollToCoords={false}
                    keyboardDismissMode='on-drag'
                    keyboardShouldPersistTaps="handled"
                    //extraScrollHeight={}
                    extraHeight={Platform.select({ android: 2000, ios: 200 })}
                    >
                <WebWrapper>
                    <View
                    style={{flex:1, justifyContent: "center", alignItems: "center"}}
                    >
                    <LoginForm source={'entry'}/>  

                    </View>
                    
                    
                </WebWrapper>    
                </KeyboardAwareScrollView>
                
            </View>
       
    )
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds, callback) => dispatch(signIn(creds, callback,null)),
        resendVerificationUser: () => dispatch(resendVerificationUser(() => {})),
        resetPasswordEmail: (email, callback) => dispatch(resetPasswordEmail(email, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)