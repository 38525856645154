import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native';
import FormEntryNoCaption from '../../../components/FormEntryNoCaption';
import FormHeading from '../../../components/FormHeading';
import i18n, { tokens } from '../../../i18n';
import { styles } from '../styles';
import { CheckoutModes } from './types';

export const RenderModeMessage: React.FC<{mode: CheckoutModes | null}> = ({mode}) => {
    if (mode) {
        var message: string = ""
        switch(mode) {
            case CheckoutModes.requiresPayment:
                message = i18n.t(tokens.app.screens.checkout.requiresPaymentText)
                break;
            case CheckoutModes.requiresRefund:
                message = i18n.t(tokens.app.screens.checkout.requiresRefundText)
                break;
            default:
                break;
        }
        return (
            <View style={styles.messageContainer}>
                <FormHeading heading={i18n.t(tokens.app.screens.checkout.checkoutNotification)}/>
                <FormEntryNoCaption editable={false}>
                    <Text style={styles.messageText}>{message}</Text>
                </FormEntryNoCaption>
            </View>
        )
    } else {
        return null
    }
    
}



