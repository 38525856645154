import React, { useRef } from 'react';
import { Platform, StyleSheet, Text } from 'react-native';
import { normalize } from '../../utilities/constants';
import { globalStyleSheet, mainFont } from '../../utilities/globalStyles';
import {Picker} from '@react-native-picker/picker';
import i18n, { tokens } from '../../i18n';

type Props = {
  aditionalStyle?: any,
  alignText?: "right" | "left" | "center",
  disabled?: boolean,
  onValueChange: (value: any) => void,
  value: any,
  placeholder: any,
  items: {label: string, value: any, key?: string}[]
}

function CustomPickerSelect(props: Props) {

    const pickerRef = useRef()
    
    const pickerSelectStyles = StyleSheet.create({
        disabledText: {
          fontFamily: mainFont,
          fontSize: normalize(20),
        },
        inputIOS: {
          fontSize: normalize(20),
          paddingVertical: 3,
          paddingHorizontal: 10,
          borderWidth: 0,
          borderBottomWidth: 0,
          borderBottomColor: 'red',
          color: 'black',
          backgroundColor: "white",
          paddingRight: 3,
          //width: "40%",
          textAlign: props.alignText? props.alignText:"right",
          //backgroundColor: "white",
          borderColor: "grey",
          borderRadius: 5,
          //borderWidth: 1,
          fontFamily: mainFont,
          ...props.aditionalStyle
        },
        inputAndroid: {
          fontSize: normalize(20),
          paddingVertical: 3, // with vertical padding, chevron disappears
          paddingHorizontal: 10,
          borderWidth: 0,
          borderBottomWidth: 0,
          borderBottomColor: 'gray',
          color: 'black',
          paddingRight: 3,
          //width: "40%",
          textAlign: props.alignText? props.alignText:"right",
          //backgroundColor: "white",
          borderColor: "grey",
          borderRadius: 5,
          //borderWidth: 1,
          fontFamily: mainFont,
          ...props.aditionalStyle
        },
        inputWeb: {
          borderWidth: 0,
          textAlign: "right",
          fontFamily: 'AvertaStd-Regular',
          fontSize: normalize(18),
        },
        placeholder: {
          color: props.disabled? "grey": "black"
        }
      });
    return (
      props.disabled?<Text
      style={pickerSelectStyles.inputIOS}>
        {props.items.filter(item => item.value == props.value)[0]?.label || ""}
      </Text>:<Picker
        ref={pickerRef}
        selectedValue={props.value}
        style={{borderWidth: 0, width: 200, textAlign: "right"}}
        itemStyle={[globalStyleSheet.entityText]}
        mode={"dialog"}
        onValueChange={(itemValue, itemIndex) =>
          props.onValueChange(itemValue)
        }>
          {[{ label: i18n.t(tokens.app.components.picker.notSelected), value: undefined, key: "undefined"},...props.items].map((item) => <Picker.Item  label={item.label} value={item.value} key={item.key || item.value}/>)}
      </Picker>
    )
}

export default CustomPickerSelect

 {/* <Picker
        key={props.value}
        style={pickerSelectStyles}
        fixAndroidTouchableBug={true}
        useNativeAndroidPickerStyle={false}
        onValueChange={(value) => {
            
            props.onValueChange(value)
        }
        }
        value={props.value}
        placeholder={props.placeholder}
        
        items={
            props.items
        }
        disabled={props.disabled}
        /> */}