import React, { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, Switch, Text, TextInput, Pressable, View } from 'react-native'
import { shallowEqual } from 'react-redux'

import { globalStyleSheet, secondColor } from '../utilities/globalStyles'
import FormHeading from './FormHeading'

import { Reservation } from '../../functions/src/interfaces'
import i18n, { tokens } from '../i18n'
import { addGameResult, loadGameResult } from '../store/actions/actionsReservationsNew'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { ReservationActions, Score, Set } from '../store/reducers/reservationReducer'
import { RootStore } from '../store/reducers/rootReducer'
import { StateLoader } from '../utilities/interfaces'
import FormEntry from './FormEntryNew'
import BigButton from './BigButton'

type SetProps = {
    styles: any,
    dispatchChangeScore: (key: any, side: any, index: any) => void,
    index: number
}

const SetSetData: React.FC<SetProps> = (props) => {

    const setHomeData: number = useAppSelector((state: RootStore) => {
        let res = state.reservations.managedReservationScore.data.result[props.index].home.games
        //console.log('Result: ' + JSON.stringify(res))
        return res
    })

    const setAwayData: number = useAppSelector((state: RootStore) => {
        let res = state.reservations.managedReservationScore.data.result[props.index].away.games
        //console.log('Result: ' + JSON.stringify(res))
        return res
    })

    return (<View style={props.styles.entityRow}>
    <TextInput maxFontSizeMultiplier={1.2} style={props.styles.textInput} value={String(setHomeData)}
    maxLength={1}
    onKeyPress={({ nativeEvent: { key: keyValue } }) => props.dispatchChangeScore(keyValue, "home", props.index)}
    
    />
    <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>
        :
    </Text>
    <TextInput maxFontSizeMultiplier={1.2} style={props.styles.textInput} value={String(setAwayData)}
    maxLength={1}
    onKeyPress={({ nativeEvent: { key: keyValue } }) => props.dispatchChangeScore(keyValue, "away", props.index)}/>
</View>)
}

type Props = {
    resultId: string,
    reservation: Reservation
}

const ResultScore: React.FC<Props> = (props) => {
    const resultId = props.resultId
    const [editFlag,setEditFlag] = useState(false)
    const dispatch = useAppDispatch()
    const managedReservationScoreData: StateLoader<Score> = useAppSelector((state: RootStore) => {
        let res = state.reservations.managedReservationScore
        //console.log('Result: ' + JSON.stringify(res))
        return res
    }, (a,b) => JSON.stringify(a) === JSON.stringify(b)
    )
    
    //const resultAddition = useSelector((state: RootStore) => state.users.resultAddition, shallowEqual)
    //console.log('Rendering result')
    useEffect(() => {
        dispatch(loadGameResult(resultId, (bool, context) => {}))
    }, [resultId])

    const validateKey = (value) => {
        const pattern = '^[0-9]$'
        const condition = new RegExp(pattern, 'g');
        return condition.test(value);
        
    }

    const dispatchChangeScore = (key, side, index) => {
        if (validateKey(key)) {
            dispatch({type: ReservationActions.editSetValue, data: {index: index, side: side, value: parseInt(key)}})
        }
    }

    const style = StyleSheet.create({
        entityContainer: {
            flexDirection: editFlag?'column':"row",
            //justifyContent: 'space-between',
            marginTop: 3,
            width: "100%",
            //borderBottomColor: '#cccccc',
            //borderBottomWidth: 0,
            //paddingBottom: 0,
            //alignSelf: "stretch",
            //width: '100%',
            //width: windowWidth - 2*padding,
            //paddingLeft: padding,
            //paddingRight: padding,
        },
        entityRow: {
            flexDirection: 'row',
            justifyContent: "space-evenly",
            //width: "100%",
            flex: 1,
            alignItems: "center",
            backgroundColor: "white",
            minHeight: 50,
            borderColor:  "grey",
            borderWidth: 1,
            borderRadius: 5,
            paddingLeft: 5,
            paddingRight: 5,
            marginTop: 5,
        },
        buttonRow: {
            flexDirection: 'row',
            width: "100%",
            alignItems: "center",
            minHeight: 50,
            justifyContent: "center"
            
        },
        entityIcon: {
            //flex: iconFlex,
        },
        entityCaption: {
            //flex: captionFlex,
        },
        entityChildren: {
            //flex: childrenFlex,
            //alignSelf: "flex-end",
            marginLeft: "auto"
        },
        errorStyle: {
            color: "red",
        },
        errorContainer: {
            //flex: 1,
            alignItems: "flex-end",
        },
        buttonAdd:{
            backgroundColor: secondColor,
            borderRadius: 30,
            width: 60,
            height: 60,
            justifyContent: "center",
            alignItems: "center"
        },
        textInput: {
            borderBottomWidth: 1,
            //borderColor: outline,
            backgroundColor: "white",
            width: 55,
            borderRadius: 10,
            fontSize: 20,
            height:30,
            textAlign: 'center'
        }
    })

  
    return (
        ['double','single'].includes(props.reservation.gameType)?
        <>
        <FormHeading heading={i18n.t(tokens.app.components.result.resultCaption)} editButton={() => setEditFlag(prev => !prev)} editButtonCaption={"Zapsat výsledek"}/>
        <View style={style.entityContainer}>
            
            {
            managedReservationScoreData.isLoaded?
            <>
            {editFlag?
            <FormEntry 
            caption={i18n.t(tokens.app.components.result.rankedGame)}
            editable={true}
            >
                <Switch
                disabled={false}
                value={managedReservationScoreData.data.rankedGame}
                onValueChange={(value) => {
                dispatch({type: ReservationActions.rankedGameAction, data: {rankedGame: value}})
                }}
                />
            </FormEntry>:null}
            <View style={{flex: 1}}>{
                editFlag ? managedReservationScoreData.data.result.map(
                    (item, index) => {
                        //console.log('WTF',item, String(item.home.games) + String(item.away.games))
                        return (
                            <SetSetData 
                            dispatchChangeScore={dispatchChangeScore}
                            index={index}
                            styles={style}
                            key={String(item.home.games) + String(item.away.games)}
                            />
                            )
                        }) : null}
                {!editFlag ?
                <FormEntry
                caption={i18n.t(tokens.app.components.result.resultScoreResult)}
                editable={false}
                ><View
                style={{flexDirection: "row"}}
                >{
                    managedReservationScoreData.data.result.map(
                        (item, index) => {
                            // console.log(item)
                            return (<Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText} key={index}>
                                {item.home.games.toString() + ':' + item.away.games.toString() + (index + 1 == managedReservationScoreData.data.result.length ? "" : ", ")}
                            </Text>
                                )
                            })
                }
                </View>
                </FormEntry>
                :null}
            </View>
            {editFlag?
            <View style={style.buttonRow}>
                <BigButton
                onPress={() => {
                    dispatch({type: ReservationActions.addSetPress, data: {home: {games: 0}, away: {games: 0}}})
                }}
                buttonText={i18n.t(tokens.app.components.result.addSet)}
                />
                <BigButton
                onPress={() => {
                    dispatch({type: ReservationActions.removeSetPress, data: {}})
                }}
                buttonText={i18n.t(tokens.app.components.result.removeSet)}
                />
                {managedReservationScoreData.isChanged == true?
                <BigButton
                onPress={() => {
                    dispatch(addGameResult(managedReservationScoreData.data, props.reservation, (bool, context) => {
                        if (bool == true) {
                            setEditFlag(false)
                        } else {    

                        }
                    }))
                }}
                buttonText={i18n.t(tokens.app.components.result.saveResult)}
                />
               :null}
            </View>:null}
            </>
            :
            <ActivityIndicator size={"small"} />
            }
            
        </View>
        </>: null
    )
}



export default ResultScore

