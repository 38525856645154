import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { shallowEqual } from 'react-redux';
import { Reservation } from '../../../../functions/src/interfaces';
import FormEntry from '../../../components/FormEntryNew';
import FormHeading from '../../../components/FormHeading';
import i18n, { tokens } from '../../../i18n';
import { useAppNavigation, useAppSelector } from '../../../store/hooks';
import { normalize } from '../../../utilities/constants';
import { globalStyleSheet } from '../../../utilities/globalStyles';


type Props = {

  reservationData: Reservation,
  disabled: boolean,
  dispatchActionForChanges: (callback) => void
}

function ReservationDetail(props: Props): JSX.Element {
  //console.log('VOalm', props)

  const users = useAppSelector(state => state.users.usersData, shallowEqual)
  const navigation = useAppNavigation()

  const getUserName = (uid: string) => {
    if (uid && users && Object.keys(users).includes(uid)) {
        let user = users[uid]
        //console.log('User data: ', user, uid)
        if (user.type == 'clubs') {
            return user.club_name
        } else {
            return user.first_name + " " + user.family_name
        }
    } else {
        return ""
    }
    
}
  

  return (<>
    <FormHeading 
    heading={i18n.t(tokens.app.screens.manageReservation.manageReservationHeading)} 
    
    />
    <FormEntry 
    caption={i18n.t(tokens.app.screens.manageReservation.reservationAuthor)}
    editable={false}>
      <Text maxFontSizeMultiplier={1.2} style={styles.textStyle}>
        {getUserName(props.reservationData.uid)}
      </Text>
    </FormEntry>
    <FormEntry 
    caption={i18n.t(tokens.app.screens.manageReservation.reservationStatus)}
    editable={false}>
      <Text maxFontSizeMultiplier={1.2} style={styles.textStyle}>
        {i18n.t(props.reservationData.status)}
      </Text>
    </FormEntry>
    <FormEntry caption={i18n.t(tokens.app.screens.manageReservation.reservationRequestIdLink)} editable={false}>
        <Text style={globalStyleSheet.footerLink}
        onPress={() => navigation.navigate("ManageMarket",{marketId: props.reservationData.reservationRequestId, type: props.reservationData.type, club: props.reservationData.club})}>
            {props.reservationData.reservationRequestId}
        </Text>
    </FormEntry>
    
  </>
  )
}

const styles = StyleSheet.create({

  textStyle: {

    textAlign: "right",
    fontSize: normalize(18),
    fontFamily: 'AvertaStd-Regular',
    
  },
  inputText: {
    backgroundColor: "white",
    borderColor: "grey",
    //borderWidth: 1,
    borderRadius: 5,
    width: "100%",
    height: 30

  }
});

export default ReservationDetail
