import React, { useEffect, useState } from "react";
import { Switch, TextInput, View } from "react-native";
import { shallowEqual } from "react-redux";
import { Club, user } from "../../../../functions/src/interfaces";
import ActionOverlay from "../../../components/ActionOverlay";
import BigButton from "../../../components/BigButton";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import CustomPickerSelect from "../../../components/CustomPickerSelect/CustomPickerSelect";
import FormEntry from "../../../components/FormEntryNew";
import FormHeading from "../../../components/FormHeading";
import i18n, { tokens } from "../../../i18n";
import { setSelectedClub } from "../../../store/actions/actionsAuth";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../../store/hooks";
import { RootStore } from "../../../store/reducers/rootReducer";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { validateEmail, validateNames, validatePassword, validatePhone } from "../../../utilities/validators";
import { formValid } from "./Validations";
import PrivacyPolicyComponent from "./PrivacyPolicyComponent";
import ClubList from "../../../components/ClubsList";
import RegisterDetailComponent from "./DetailComponent";

export type UserRegistrationForm = {
    first_name: string,
    family_name: string,
    address: string,
    city: string,
    phone: string,
    email: string,
    clubs: string[],
    emailConfirm: string,
    confirmPassword: string,
    password: string,
    dateOfBirth: Date,
    termsAndConditions: boolean,
    language: string,
    photo: string,
    role: number,
    facebookId: string,
    linkedInId: string,
    iban: string,
    token: string,
    country: string,
}

type Props = {
    registerUser: (user, callback) => void
}

const validateUserState = (state: user & {emailConfirm: string, password: string, confirmPassword: string, termsAndConditions: boolean}) => {
    return {
        uid: {value: state.uid, validity: true, errorMessage: ""},
        first_name : {value: state.first_name, validity: validateNames(state.first_name), errorMessage: i18n.t(tokens.app.screens.registration.first_name_validity)},
        family_name : {value: state.family_name, validity: validateNames(state.family_name), errorMessage: i18n.t(tokens.app.screens.registration.family_name_validity)},
        language : {value: "ENG", validity: true, errorMessage: ""},
        email : {value: state.email, validity: validateEmail(state.email), errorMessage: i18n.t(tokens.app.screens.registration.email_validity)},
        emailConfirm : {value: state.emailConfirm, validity: validateEmail(state.emailConfirm), errorMessage: i18n.t(tokens.app.screens.registration.emailConfirm_validity)},
        phone : {value: state.phone, validity: validatePhone(state.phone), errorMessage: i18n.t(tokens.app.screens.registration.phone_validity)},
        dateOfBirth : {value: state.dateOfBirth, validity: true, errorMessage: ""},
        role : {value: 0, validity: true, errorMessage: ""},
        photo : {value: "", validity: true, errorMessage: ""},
        facebookId : {value: "", validity: true, errorMessage: ""},
        linkedInId : {value: "", validity: true, errorMessage: ""},
        token : {value: "", validity: true, errorMessage: ""},
        iban : {value: "", validity: true, errorMessage: ""},                                    // collector iban / settlement iban
        international_Swift :{value: "", validity: true, errorMessage: ""},
        address : {value: state.address, validity: true, errorMessage: ""},
        city : {value: state.city, validity: true, errorMessage: ""},
        country : {value: "CZE", validity: true, errorMessage: ""},
        password: {value: state.password, validity: validatePassword(state.password), errorMessage: i18n.t(tokens.app.screens.registration.password_validity)},
        confirmPassword: {value: state.confirmPassword, validity: state.password == state.confirmPassword, errorMessage: i18n.t(tokens.app.screens.registration.confirmPassword_validity)},
        termsAndConditions:  {value: state.termsAndConditions, validity: state.termsAndConditions == true, errorMessage: i18n.t(tokens.app.screens.registration.termsAndConditionsError)},
        clubs: {value: state.clubs, validity: state.clubs.length > 0, errorMessage: i18n.t(tokens.app.screens.registration.registerUserClubsValidity)},
    }
}

function UserForm(props: Props):JSX.Element {
    const [isActionPressed, setIsActionPressed] = useState(false)
    const navigation = useAppNavigation()
    const selectedClub: string = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    const [availableClubs, setAvailableClubs] = useState([])
    const allClubs: {[clubId: string]: Club} = useAppSelector((state: RootStore) => state.auth.allClubs, shallowEqual)
    const [detail, setDetail] = useState(null)

    useEffect(() => {
        let keys = Object.keys(allClubs)
        var options = []
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let club: Club = allClubs[key]
            if (club.club_name) {
                let entry = { label: club.club_name, value: key }
                options.push(entry)
            }
            
        }
        setAvailableClubs(options)
    }, [allClubs])


    const [user, setUser] = useState<{
        [key: string]: {value: any, validity: boolean, errorMessage: string}
    }>(validateUserState({
        uid: undefined,
        first_name: "",
        family_name: "",
        address: "",
        city: "",
        phone: "",
        email: "",
        clubs: selectedClub && selectedClub != ""? [selectedClub]:[],
        emailConfirm: "",
        confirmPassword: "",
        password: "",
        dateOfBirth: new Date(1990, 0, 1),
        termsAndConditions: false,
    }))

    const dispatchChange = (callback: () => {[key: string]: any}) => {
        let change = callback()
        setUser(state => {
            let newUser = {
                ...Object.keys(state).reduce((prev, key)=> {return {...prev, [key]: state[key].value}}, {}),
                ...change
            }
            return validateUserState(newUser as any)
        })
      }
    
    return <View>
        <ActionOverlay 
        onBackdropPress={() => {}}
        fullScreen={false}
        isVisible={isActionPressed}
        />
        {detail?
        <RegisterDetailComponent
        detailType={detail}
        dispatchChange={dispatchChange}
        userData={user}
        confirmAction={() => setDetail(null)}
        />
        :<>
        <FormHeading icon="people-outline" heading={i18n.t(tokens.app.screens.registration.personalHeading)}/>
            <FormEntry 
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.first_name)} 
            error={user.first_name.errorMessage} 
            validity={!(!user.first_name.validity && user.first_name.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.first_name.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.first_name)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { first_name: text } })}}
                    value={user.first_name.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="words"
                    key="first_name"
                /> 
            </FormEntry>
            <FormEntry 
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.family_name)} 
            error={user.family_name.errorMessage} 
            validity={!(!user.family_name.validity && user.family_name.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.family_name.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.family_name)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { family_name: text } })}}
                    value={user.family_name.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="words"
                    key="family_name"
                />
            </FormEntry>  
            <FormEntry 
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.dateOfBirth)} error={user.dateOfBirth.errorMessage} validity={user.dateOfBirth.validity}>
                <View style={{alignSelf: "flex-end"}}>
                <CustomDatePicker
                    /*placeholder="Date of birth: "*/ 
                    icon={false}
                    containerStyle={user.dateOfBirth.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    date={user.dateOfBirth.value} 
                    fontStyle={{fontSize: 15, letterSpacing: 3}}
                    setDate={(newDate) => {dispatchChange(() => { return { dateOfBirth: newDate } })}}
                    />
                </View>
            </FormEntry>   
            <FormHeading  icon="ios-business-outline" heading={i18n.t(tokens.app.screens.registration.addressHeading)}/>  
            <FormEntry 
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.address)} error={user.address.errorMessage} validity={user.address.validity}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.address.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.address)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { address: text } })}}
                    value={user.address.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="sentences"
                    key="address"
                />
            </FormEntry>        
            <FormEntry
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.city)} error={user.city.errorMessage} validity={user.city.validity}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.city.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.city)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { city: text } })}}
                    value={user.city.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="sentences"
                    key="city"
                />
            </FormEntry> 
            <FormHeading  icon="mail-outline" heading={i18n.t(tokens.app.screens.registration.contactsHeading)}/>       
            <FormEntry
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.phone)} 
            error={user.phone.errorMessage} 
            validity={!(!user.phone.validity && user.phone.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.phone.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.phone)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { phone: text } })}}
                    value={user.phone.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="phone"
                    inputMode="numeric"
                />
            </FormEntry>     
            <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.registration.email)} 
            error={user.email.errorMessage} 
            validity={!(!user.email.validity && user.email.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.email.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.email)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { email: text } })}}
                    value={user.email.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="email"
                    inputMode="email"
                />
            </FormEntry>     
            <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.registration.emailConfirm)} 
            error={user.emailConfirm.errorMessage} 
            validity={!(!user.emailConfirm.validity && user.emailConfirm.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.emailConfirm.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholder={i18n.t(tokens.app.screens.registration.email)}
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => {dispatchChange(() => { return { emailConfirm: text } })}}
                    value={user.emailConfirm.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="email"
                    inputMode="email"
                />
            </FormEntry>
            <ClubList
            availableClubs={user.clubs.value}
            
            heading={i18n.t(tokens.app.screens.myClubs.myClubsMyClubsHeading)}
            headingEdditButtonCaption="Upravit"
            headingEdditButtonAction={() => setDetail("clubs")}
            />


            <FormHeading icon="ios-key-outline" heading={i18n.t(tokens.app.screens.registration.passwordsHeading)}/>        
            <FormEntry
            editable={true}
            caption={i18n.t(tokens.app.screens.registration.password)} 
            error={user.password.errorMessage} 
            validity={!(!user.password.validity && user.password.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.password.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholderTextColor="#aaaaaa"
                    secureTextEntry
                    placeholder={i18n.t(tokens.app.screens.registration.password)}
                    onChangeText={(text) => {dispatchChange(() => { return { password: text } })}}
                    value={user.password.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="password"
                />
            </FormEntry>  
            <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.registration.confirmPassword)}  
            error={user.confirmPassword.errorMessage} 
            validity={!(!user.confirmPassword.validity && user.confirmPassword.value.length > 0)}>
                <TextInput maxFontSizeMultiplier={1.2}
                    style={user.confirmPassword.validity ? globalStyleSheet.inputOk:globalStyleSheet.inputKo}
                    placeholderTextColor="#aaaaaa"
                    secureTextEntry
                    placeholder={i18n.t(tokens.app.screens.registration.confirmPassword)}
                    onChangeText={(text) => {dispatchChange(() => { return { confirmPassword: text } })}}
                    value={user.confirmPassword.value}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    key="confirmPassword"
                />
            </FormEntry> 
            <PrivacyPolicyComponent/>
            <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.registration.termsAndConditions)} 
            error={user.termsAndConditions.errorMessage} 
            validity={user.termsAndConditions.validity}>
                <View
                style={{alignSelf: "flex-end"}}
                >

                    <Switch 
                    
                    value={user.termsAndConditions.value}
                    onValueChange={(value) => dispatchChange(() => { return { termsAndConditions: value } })}
                    />
                </View>
            </FormEntry>  
            {/* <FormEntry
            editable={true} 
            caption={i18n.t(tokens.app.screens.index.clubPlaceholder)} 
            error={user.clubs.errorMessage} 
            validity={user.clubs.validity}>
                <View
                style={{alignSelf: "flex-end"}}
                >

                <CustomPickerSelect
                onValueChange={(value) => dispatchChange(() => { return { clubs: [value] } })} 
                aditionalStyle={{backgroundColor: "white"}}
                value={user.clubs.value[0]}
                placeholder={{label: i18n.t(tokens.app.screens.index.clubPlaceholder), value: undefined}}
                
                items={availableClubs}
                />
                </View>
            </FormEntry>  */}  
            
            <BigButton
            disabled={!formValid(user)}
            onPress={() => {
                setIsActionPressed(true)
                let data = Object.keys(user).reduce((prev,key) => {return {...prev, [key]: user[key].value}}, {}) as any
                //data['clubs'] = [selectedClub]
                props.registerUser(data, (bool, context) => {
                    setIsActionPressed(false)
                    if (bool == true) {
                        //let club: ClubBasic = context
                        navigation.goBack()
                    }

            })}}
            buttonText={i18n.t(tokens.app.screens.registration.createAccountButtonCaption)}
            />
            </>}
    </View>
}

export default UserForm