import React, { useState, createRef, useEffect } from 'react'
import { View, Button, TextInput, Pressable, Platform, Dimensions, StyleSheet }from 'react-native'
import { styles } from '../utilities/globalStyles'

type Props = {
    children: any
}

function WebWrapper(props: Props): JSX.Element {

    

    const [dimensions, setDimensions] = useState(
        {
            window: {
                width: Dimensions.get('window').width, 
                height: Dimensions.get('window').height
            }, 
            screen:{
                width: Dimensions.get('screen').width, 
                height: Dimensions.get('screen').height
            },
        }
        ) 
    
    const iphoneRes = {width: 750, height: 1334}
    const ratio = iphoneRes.width / iphoneRes.height

    const onChange = ({ window, screen }) => {
        //alert('Change dims: '+JSON.stringify({ window, screen }))
        setDimensions({ window, screen });
    };
    
    useEffect(() => {
        let subscription = Dimensions.addEventListener("change", onChange);
        return () => subscription.remove();
    }) 

    const style = StyleSheet.create({
        //...styles,
        wrapperContainer: {
            flex:1,
            //backgroundColor: "red",
            //width: Math.min(dimensions.window.height*1.1, 700),
            width: "70%",
            alignSelf: "center",
        
            
        },
        webalt: {
            flex: 1,
            alignSelf: "stretch",
        }
    })
    
    
    return (
        dimensions.screen && dimensions.screen.width > Math.min(dimensions.screen.height, 700)
         ? (
            <View style={style.wrapperContainer}>
                {props.children}
            </View>
        ) : (
            <View style={style.webalt}>
                {props.children}
            </View>
        )
    )
}

export default WebWrapper