import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ActivityIndicator, DeviceEventEmitter, Platform, StyleSheet, Text, TextInput, Pressable, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual } from 'react-redux';
import { Club, Market, MarketCredit, MarketStatuses, MembershipTypes, user, UserRoles } from '../../../../functions/src/interfaces';
import ActionOverlay from '../../../components/ActionOverlay';
import BigButton from '../../../components/BigButton';
import FormEntry from '../../../components/FormEntryNew';
import FormHeading from '../../../components/FormHeading';
import UserAvatar from '../../../components/UserAvatar';
import i18n, { tokens } from '../../../i18n';
import { addSubscription, editMarket, marketDeletionAction } from '../../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { RootStore } from '../../../store/reducers/rootReducer';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../../store/selectors';
import { normalize } from '../../../utilities/constants';
import { globalStyleSheet } from '../../../utilities/globalStyles';
import { StateLoader } from '../../../utilities/interfaces';
import { CheckoutModes } from '../../CheckoutScreen/Components/types';
import CheckoutInfo from '../CommonMarketComponents/CheckoutInfo';
import OwnerComponentBase from '../CommonMarketComponents/OwnerComponentBase';

type Props = {
    firebaseId?: string,
    club: string,
    setMarketId: (newVal: string) => void,
    setMarketButtonIndex: (index: number) => void,
}

function ManageCredit(props: Props):JSX.Element {
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const orderData: StateLoader<MarketCredit<Market>> = useAppSelector((state:RootStore) => state.market.managedMarket, shallowEqual)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
    const allClubs: {[clubId: string]: Club} = useAppSelector((state: RootStore) => state.auth.allClubs, shallowEqual)

    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        dispatch(editMarket(changes))
    }

   


    const playerPress = () => {
        //console.log('volleeee', Object.keys(props.data).length, props.sideCaption)
    
          DeviceEventEmitter.addListener("event.mapNewPlayerToGame", (eventData) => {
            dispatchChanges(() => { return  {mainUid: eventData}})
            //props.side[key] = eventData
            console.log('catched daat', eventData)
          }
          )
      
          navigation.navigate('ContactsScreenStack', {
            action: 'selectPlayer', 
            path: ['mainUid'], 
            alreadySelected: []
          })
        
    }

    const isEditor = () => {
        if (currentUser.type == "user") {
            return currentUser.uid == orderData.data.mainUid || currentUser.isAdmin == true || currentUser.isGod == true
        } else if (currentUser.type == "clubs") {
            return currentUser.firebaseId == orderData.data.club
        }
        
    }  

    const addOrderAction = () => {
        dispatch(addSubscription())
        .then((result) => {
          if (!orderData.data.firebaseId && result.data.marketId && result.data.marketId != "") {
            props.setMarketId(result.data.marketId)
          }
          if (result.action == 'checkout') {
            
            props.setMarketButtonIndex(1)
          }
        })
        .catch((e) => {
  
        })
      }

      const removeReservationAction = () => {
        dispatch(marketDeletionAction(orderData.data.club,orderData.data.firebaseId, "all"))
    }

    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >

                {orderData.isLoaded == true?
                <View style={styles.container}>
                    <ActionOverlay
                    onBackdropPress={()=> {}}
                    fullScreen={true}
                    isVisible={orderData.isActionPressed}
                    />
                    <OwnerComponentBase
                    marketData={orderData.data}
                    />
                    <FormEntry 
                    caption={i18n.t(tokens.app.screens.manageReservation.reservationAuthor)} 
                    editable={false}
                    >
                        <Pressable
                        disabled={false}
                        onPress={playerPress}
                        >
                        <UserAvatar uid={orderData.data.mainUid} size={normalize(70)}/>
                        </Pressable>
                    </FormEntry>
                    <CheckoutInfo marketData={orderData.data}/>
                    <FormHeading
                    heading={i18n.t(tokens.app.screens.credit.creditHeading)}
                    />
                    <FormEntry
                    caption={i18n.t(tokens.app.screens.credit.creditClub)}
                    editable={false}
                    >
                        <Text>{allClubs[orderData.data.club].club_name}</Text>
                    </FormEntry>
                    <FormEntry
                    caption={i18n.t(tokens.app.screens.credit.creditAmount)}
                    editable={true}
                    >
                        <TextInput
                        value={String(orderData.data.amount)}
                        inputMode="numeric"
                        onChangeText={((text) => dispatchChanges(() => {return {amount: parseInt(text.length>0?text:"0")}}))}
                        style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                        />
                    </FormEntry>
                    
                    {[MarketStatuses.new, MarketStatuses.open, MarketStatuses.blocked, MarketStatuses.approved].includes(orderData.data.status) && (orderData.isChanged == true || !orderData.data.firebaseId) && (isEditor() == true)?
                    <BigButton
                    onPress={addOrderAction}
                    disabled={orderData.data.amount <= 0}
                    buttonText={i18n.t(tokens.app.screens.manageReservation.reservationSaveButton)}
                    />
                    :null}
                    {[MarketStatuses.open].includes(orderData.data.status) && orderData.data.firebaseId && (isEditor() == true)?
                    <BigButton
                    
                    onPress={removeReservationAction}
                    buttonText={i18n.t(tokens.app.screens.opengame.removeButton)}
                    />
                    :null}
                </View>:
                <ActivityIndicator size={"large"}/>
                }   

        </KeyboardAwareScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    }
})

export default ManageCredit