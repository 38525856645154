import React from "react";
import { FlatList, TextInput, View } from "react-native";
import FormHeading from "../../../components/FormHeading";
import i18n, { tokens } from "../../../i18n";
import FormEntry from "../../../components/FormEntryNew";
import { globalStyleSheet } from "../../../utilities/globalStyles";
import { Membership, ProductPrice, ProductTypes } from "../../../../functions/src/interfaces";
import { useAppSelector } from "../../../store/hooks";
import { RootStore } from "../../../store/reducers/rootReducer";
import { shallowEqual } from "react-redux";
import ExemptionSwitch from "../../../components/ExemptionSwitch";
import { roleTypes } from "../../../utilities/constants";
import RoleSwitch from "../../../components/RoleSwitch";
import { validateNumber } from "../../../utilities/funcs";

type Props = {
    productType: ProductTypes,
    priceId: string,
    dispatchChange: (callback: (membership: Membership) => Membership) => void
}

function PriceDetail(props: Props) {

    const price: ProductPrice = useAppSelector((state: RootStore) => state.auth.managedClub.data.products[props.productType].prices?.[props.priceId], shallowEqual)
    return (
        <View key={price.firebaseId}>
        <FormHeading
        heading={i18n.t(tokens.app.screens.manageClub.manageClubTourPrice)}
        editButton={() => {
            props.dispatchChange((memb) => {
                var newMemb: Membership = {...memb}
                delete newMemb.prices[props.priceId]
                return newMemb
            })
        }}
        editButtonCaption={i18n.t(tokens.app.components.button.buttonsRemoveButton)}
        />
        <FormEntry caption={"Jmeno"} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={"Jmeno"}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    props.dispatchChange((memb) => {
                        
                        var newMemb: Membership = {...memb}
                        newMemb.prices[props.priceId].name = text
                        return newMemb
                    })
                }}
                value={String(price.name)}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FormEntry caption={i18n.t(tokens.app.screens.manageClub.manageClubTourPrice)} editable={true}>
            <TextInput maxFontSizeMultiplier={1.2}
                style={[globalStyleSheet.inputOk, {textAlign: "right"}]}
                placeholder={i18n.t(tokens.app.screens.manageClub.manageClubTourPrice)}
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => {
                    
                    props.dispatchChange((memb) => {
                        var newMemb: Membership = {...memb}
                        newMemb.prices[props.priceId].price = validateNumber(text) ? parseInt(text) : 0
                        return newMemb
                    })
                }}
                value={String(price.price)}
                underlineColorAndroid="transparent"
                autoCapitalize="words"
                //id="club_name"
            />
        </FormEntry>
        <FlatList
        horizontal={true}
        data={Object.entries(price.allowedRoles).map(([k,v]) => { return {role: k, val: v}})}
        renderItem={({item}) => (
        <RoleSwitch
        switchCaption={roleTypes[item.role]}
        value={item.val}
        switchKey={item.role}
        dispatchChange={(data: {[role: string]: boolean}) => {
            props.dispatchChange((memb) => {
                var newMemb: Membership = {...memb}
                newMemb.prices[props.priceId].allowedRoles = {
                    ...memb.prices[props.priceId].allowedRoles,
                    ...data
                }
                return newMemb
                
            })
        }}
        />
        )}
        />
        </View>
    )
}

export default PriceDetail