import { Query } from "@google-cloud/firestore"
import { CallbackFunc } from "../../../functions/src/interfaces"
import { standardizeDates } from "../../utilities/funcs"
import { AppThunk } from "../hooks"
import { RootStore } from "../reducers/rootReducer"

export const participateInSeasonRanking = (uid: string, club: string, season: string, status: string): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'PARTICIPATION_ACTION', data: {isLoaded: false, uid: uid, isSuccess: true}})
        const rootRef = getFirestore()
        let rankingEntry = {
            uid: uid,
            pointsSingles: 0,
            pointsDoubles: 0,
            season: season,
            club: club,
            lastPlayed: new Date(1900,0,1),
            winsSingles: 0,
            lossesSingles: 0,
            winsDoubles: 0,
            lossesDoubles: 0,
            status: status,
        }
        rootRef.runTransaction(async (t) => {
            const seasonRankingRef = rootRef.collection('clubs').doc(club).collection('seasons').doc(season).collection('ranking')
            const seasonMeRef = seasonRankingRef.doc(uid)
            const seasonMe = await seasonMeRef.get()
            //console.log('Jiz existuji? : ' + JSON.stringify(seasonMe.exists))
            if (seasonMe.exists) {
                //return Promise.reject({code:'ALREADY_PARTICIPATING', message: 'User is already participating'})
                t.update(seasonMeRef, {status: status})
            } else {
                t.set(seasonMeRef, rankingEntry)
            }
        })
        .then(() => {
            console.log('Success: ')
            dispatch({type: 'PARTICIPATION_ACTION', data: {isLoaded: true, uid: uid, isSuccess: true}})
        })
        .catch((e) => {
            console.log('Error: ', e)
            dispatch({type: 'PARTICIPATION_ACTION', data: {isLoaded: true, uid: uid, isSuccess: false, error: e}})
        })

    }
}

export const amParticipatingInSeasonRanking = (uid: string, club: string, season: string): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        if (!season) {
            dispatch({type: 'GET_USER_PARTICIPATION_ACTION', data: {isLoaded: true, uid: uid, isSuccess: false, amParticipating: false }})
        }
        dispatch({type: 'GET_USER_PARTICIPATION_ACTION', data: {isLoaded: false, uid: uid, isSuccess: true}})
        const rootRef = getFirestore()
        const userRef = rootRef.collection('clubs').doc(club).collection('seasons').doc(season).collection('ranking').doc(uid)
        userRef.get()
        .then((doc) => {
            //console.log('Success: ')
            let data = doc.data()
            if (doc.exists && data.status == 'active') {
                dispatch({type: 'GET_USER_PARTICIPATION_ACTION', data: {isLoaded: true, uid: uid, isSuccess: true, amParticipating: true}})
            } else {
                dispatch({type: 'GET_USER_PARTICIPATION_ACTION', data: {isLoaded: true, uid: uid, isSuccess: true, amParticipating: false }})
            }
            
        })
        .catch((e) => {
            console.log('Error: ', e)
            dispatch({type: 'GET_USER_PARTICIPATION_ACTION', data: {isLoaded: true, uid: uid, isSuccess: false, error: e}})
        })

    }
}


export const retrieveSeasonRanking = (uid: string, club: string, season: string, limit: number, lastVisible: string): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'LOAD_RANKING_DATA_PAGE', data: {isLoaded: false, pageData: [], lastVisible: null}})
        try {
            const rootRef = getFirestore()
            console.log('Retrieving additional Data');
            // Cloud Firestore: Query (Additional Query)
            var additionalQuery = rootRef.collection('clubs').doc(club).collection('reservations')
                .where('season', '==', season)
                .orderBy('dateFrom')
            // If next page    
            if (lastVisible) {
                additionalQuery = additionalQuery.startAfter(lastVisible)
                
            }
            // Set retrieval limit
            additionalQuery = additionalQuery.limit(limit)
            // Cloud Firestore: Query Snapshot
            let documentSnapshots = await additionalQuery.get();
            // Cloud Firestore: Document Data
            let documentData = documentSnapshots.docs.map(document => document.data());
            //console.log('Doc data: ',documentData)
            // Cloud Firestore: Last Visible Document (Document ID To Start From For Proceeding Queries)
            //let newLastVisible = documentData[documentData.length - 1].firebaseId;
            let newLastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            // Dispatch State
            dispatch({type: 'LOAD_RANKING_DATA_PAGE', data: {isLoaded: true, pageData: [...documentData], lastVisible: newLastVisible}})
        }
        catch (e) {
            console.log('Query error', e)
            dispatch({type: 'LOAD_RANKING_DATA_PAGE_ERROR', data: {isLoaded: true, pageData: [], error: {code: 'NOT_USER', message: "Cannot edit foreign data"}}})
        }

       
    }
}


export const subscribeForRanking = (club: string, season: string, gameType: string, callback: CallbackFunc): AppThunk<Promise<() => void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        console.log("Calling subscribe for Ranking" + club + season)
        
        
        const gameTypeMap = {
            double: 'pointsDoubles',
            single: 'pointsSingles',
        }

        
        const firebase = getFirebase()
        const firestoreuid = firebase.auth().currentUser.uid
        //console.log('state', firestoreuid)
        
        if (!season) {
            dispatch(amParticipatingInSeasonRanking(firestoreuid, club, season))
            dispatch({type: 'RANKING_CHANGES', data: null})
        }

        const rootRef = getFirestore()
        const rankingRef = rootRef.collection('clubs').doc(club).collection('seasons').doc(season)
        .collection('ranking')
    
        //Order na konci
        var entityRef = rankingRef.orderBy(gameTypeMap[gameType],'desc').limit(200);
        //console.log("Ahoj kurva", club)
        var unsubscribe = entityRef.onSnapshot(
            (querySnapshot) => {
                dispatch(amParticipatingInSeasonRanking(firestoreuid, club, season))
                //console.log("Ahoj kurva2", querySnapshot)
                const changes = querySnapshot.docChanges()
                const js = {
                    'gameType':gameType,
                    'added':{},
                    'modified':{},
                    'removed':{}
                            
                }
                changes.forEach(doc => {
                    //console.log(doc)
                    //console.log(doc)
                    js[doc.type][doc.doc.id] = standardizeDates(doc.doc.data()) 

                    
                    
                })
                
                //console.log(js)
                dispatch({type: 'RANKING_CHANGES', data: js})
            },
            (error: any) => {
                console.log("Error listening" + JSON.stringify(error))
            }
        )

        //console.log(unsubscribe)
        return unsubscribe
            


    }
}


export const retrieveFollowingFive = (uid: string, club: string, season: string, limit: number): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'LOAD_RANKING_DATA_PAGE', data: {isLoaded: false, pageData: [], lastVisible: null}})
        try {
            const rootRef = getFirestore()
            console.log('Retrieving additional Data');
            const state: RootStore = getState()
            // Cloud Firestore: Query (Additional Query)
            var additionalQuery = rootRef.collection('clubs').doc(club).collection('seasons').doc(season).collection('ranking')
                .orderBy('pointsDoubles','desc')
                .endAt()
            // If next page    
            if (state.ranking.seasonalRanking.lastVisible) {
                additionalQuery = additionalQuery.startAfter(state.ranking.seasonalRanking.lastVisible)
                
            }
            // Set retrieval limit
            additionalQuery = additionalQuery.limit(limit)
            // Cloud Firestore: Query Snapshot
            let documentSnapshots = await additionalQuery.get();
            // Cloud Firestore: Document Data
            let documentData = documentSnapshots.docs.map(document => document.data());
            //console.log('Doc data: ',documentData)
            // Cloud Firestore: Last Visible Document (Document ID To Start From For Proceeding Queries)
            //let newLastVisible = documentData[documentData.length - 1].firebaseId;
            let newLastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            // Dispatch State
            dispatch({type: 'LOAD_RANKING_DATA_PAGE', data: {isLoaded: true, pageData: [...documentData], lastVisible: newLastVisible}})
        }
        catch (e) {
            console.log('Query error', e)
            dispatch({type: 'LOAD_RANKING_DATA_PAGE_ERROR', data: {isLoaded: true, pageData: [], error: {code: 'NOT_USER', message: "Cannot edit foreign data"}}})
        }

       
    }
}