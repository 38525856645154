import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable, ScrollView } from 'react-native'

import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { shallowEqual } from 'react-redux';
import { Market, MarketLongreservation, MarketOpengame, MarketReservation, UserOrClub, user } from '../../../../../functions/src/interfaces';
import UserAvatar from '../../../../components/UserAvatar';
import i18n, { tokens } from '../../../../i18n';
import { useAppSelector } from '../../../../store/hooks';
import { getCurrentUserWithRoles } from '../../../../store/selectors';
import { marketTypes, weekdays } from '../../../../utilities/constants';
import { monthString, timeFormatter } from '../../../../utilities/funcs';
import { gameTypesColors, mainFont, mainFontBold } from '../../../../utilities/globalStyles';
import MarketEntryButton from '../MarketEntryButton';



type Props = {
    data: MarketReservation<Market>,
    users: {[userId: string]: UserOrClub}
}



const MarketEntryBodyReservation: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)
    
    
    return (
        <View style={{
            flex:1,
            marginLeft: 10,
            marginRight: 10,
        }}>
            
            <Text maxFontSizeMultiplier={1.2}>{`Status: ${i18n.t(props.data.status)}`}</Text>
        </View>
    )
}


const styles = StyleSheet.create({
       
})


export default MarketEntryBodyReservation

