import React, { useEffect, useRef, useState } from "react";
import { DeviceEventEmitter, StyleSheet, Text, Pressable, useWindowDimensions, View } from "react-native";
import { GameTypes, MarketTypes, ReservationTypes } from "../../functions/src/interfaces";
import i18n, { tokens } from "../i18n";
import { useAppNavigation, useAppSelector } from '../store/hooks';
import { RootStore } from "../store/reducers/rootReducer";
import { getCurrentAuthState, getCurrentUserWithRoles } from "../store/selectors";
import { gameTypes } from "../utilities/constants";
import { firestoreAutoId, objectToPicker } from "../utilities/funcs";
import { GPlayers } from "../utilities/interfaces";
import CustomPickerSelect from "./CustomPickerSelect/CustomPickerSelect";
import Draggable, { Handle } from "./DraggableFunc";
import DropFunc from "./DropFunc";
import FormEntry from "./FormEntryNew";
import FormHeading from "./FormHeading";
import { HandleTrash } from "./TrashView";
import UserAvatar from "./UserAvatar";


type Drag = {
  side: string;
  pos: string;
  initx: number;
  inity: number;
  width: number;
  color: string;
  ref: React.MutableRefObject<Handle>;
  user: string;
  suffix: string;
}

interface User {
  uid: string,
  photo?: string,
  first_name: string,
  family_name: string,
}

type Props = {
  style?: { [style: string]: any };
  //path: string[];
  gamePlayers?: GPlayers,
  players: string[],
  notifiedPlayers?: string[],
  appliedPlayers?: string[],
  gameType: GameTypes,
  selectableType: boolean,
  disabled?: boolean,
  dispatchActionForChanges: (evalChanges) => void,
  reservationOwner: string,
  type?: ReservationTypes | MarketTypes,
  dragEnabled?: boolean,
  applyMethod?: () => void,
  visible?: boolean,
  editButton?: () => void,
};

const getAlreadySelected = (gamePlayers, players, appliedPlayers) => {
  let allplayers = [
      ...Object.values(gamePlayers?.home ? gamePlayers?.home : {}),
      ...Object.values(gamePlayers?.away ? gamePlayers?.away : {}),
      ...players ? players : [],
      ...appliedPlayers ? appliedPlayers : [],
  ]
  return allplayers
}

const initDragRefs = (cnt: number) => {
  var drags = {}
  for (let i = 0; i < cnt; i++) {
    drags[`drag${String(i)}`] = useRef()
  }
  return drags
}

const calcPos = (gameType: 'single' | 'double' | 'group', players: string[], appliedPlayers: string[]) => {
  let gamePlayersCnt = 0
  switch (gameType) {
    case 'double':
      gamePlayersCnt = 4
      break;
    case 'single':
      gamePlayersCnt = 2
      break;
    default:
      gamePlayersCnt = 0
      break
  }
  return gamePlayersCnt + (players.length || 0) + (appliedPlayers.length || 0)
}


const AddPlayer: React.FC<{ side: string, pos: number, onPressEmpty: (data: {side: string, pos: number}) => void, size: number }> = (props) => {
  return (<Pressable
    onPress={() => props.onPressEmpty({ side: props.side, pos: props.pos })}
  >
    <UserAvatar
      size={props.size}
    />
  </Pressable>)
}

const ListPlayers: React.FC<{ 
  positions: Array<any[]>, 
  size: number, 
  side: string[],  
  dragEnabled: boolean, 
  enableAdd?: boolean,
  onReleaseHandle?: (id: any) => void,
  onMoveHandle?: (id: any) => void,
  onStartDrag?: () => void,
  onPressEmpty?: (type: {
    side: string,
    pos: string
  }) => void
}> = (props) => {
  return (
    <View style={['home', 'away'].includes(props.side[0]) ? styles.sidePlayersContainer : styles.listContainer}>

      {props.positions.filter(item => props.side.includes(item[1].side)).map(([draggable, object]) => {
        return (<Draggable
          key={firestoreAutoId()//To rerender each time props changes to pass all functions that rely on current state (initObjsData)
          }
          size={props.size}
          ref={object.ref}
          id={draggable}
          releaseHandler={props.onReleaseHandle}
          moveHandler={props.onMoveHandle}
          dragStart={props.onStartDrag}
          onPressHandler={props.onPressEmpty}
          user={object.user}
          path={{ side: object.side, pos: object.pos }}
          dragEnabled={props.dragEnabled}
        />)
      })}
     {/*  {props.enableAdd?
      <ClicableUserAvatar
      size={props.size}
      caption={playersEditButtonCpation({ side: props.side[0], pos: props.positions.length })}
      onPress={() => onPressEmpty({ side: props.side[0], pos: props.positions.length })}
      type="strict"
      />
      
      :null} */}
    </View>)
}

const Side: React.FC<{ 
  positions: Array<any[]>, 
  size: number, 
  side: string[], 
  dragEnabled: boolean,
  onReleaseHandle?: (id: any) => void,
  onMoveHandle?: (id: any) => void,
  onStartDrag?: () => void,
  onPressEmpty?: (type: {
    side: string,
    pos: string
  }) => void
}> = (props) => {
  return (
    <View
      style={styles.sideContainer}
    >
      {/* <View style={styles.sideTitle}>
      <Text>{props.side}</Text>
    </View> */}
      <ListPlayers {...props} />
    </View>)
}

const GamePlayers: React.FC<Props> = ({
  style,
  //path: string[];
  gamePlayers = {away: {}, home: {}},
  players = [],
  notifiedPlayers = [],
  appliedPlayers = [],
  gameType,
  selectableType,
  disabled,
  dispatchActionForChanges,
  reservationOwner,
  type,
  dragEnabled,
  applyMethod,
  visible,
  editButton,
}) => {
  const { height, width } = useWindowDimensions()

  //const { isEmpty, user, isLoaded } = getCurrentAuthState()
  const user = useAppSelector(getCurrentUserWithRoles, (a, b) => {
    return JSON.stringify(a) == JSON.stringify(b)
  })
  const owner = useAppSelector((state: RootStore) => state.users.usersData[reservationOwner], (a,b) => JSON.stringify(a) == JSON.stringify(b))
  //console.log('Rendering', props.dragEnabled)
  const middle = Math.min(width, height) / 2
  const size = Math.min(middle / 3, 80)
  const drag = initDragRefs(30)

  const [showTrash, setShowTrash] = useState(false)
  const currentClub = useAppSelector((state: RootStore) => state.auth.allClubs[state.auth.selectedClub], (a, b) => JSON.stringify(a) == JSON.stringify(b))

  const navigation = useAppNavigation()

  
  const calcInitPos = (type: string) => {

    //console.log("Middle " + String(middle))
    var positions: { [key: string]: Drag } = {}

    switch (type) {
      case "single": {
        positions['drag0'] = { side: "home", pos: "A", initx: middle / 2 - size / 2, inity: 5, width: size, color: "blue", ref: drag["drag0"], user: gamePlayers?.home?.A, suffix: gamePlayers?.home?.A && gamePlayers?.home?.A != "" ? gamePlayers?.home?.A : "homeA" }
        //.log("Left pos " + String(middle / 2 - size / 2))
        positions['drag1'] = { side: "away", pos: "A", initx: middle + middle / 2 - size / 2, inity: 5, width: size, color: "red", ref: drag["drag1"], user: gamePlayers?.away?.A, suffix: gamePlayers?.away?.A && gamePlayers?.away?.A != "" ? gamePlayers?.away?.A : "awayA" }
        //console.log("Right pos " + String(middle + middle / 2 - size / 2))
        break;
      }
      case "double": {
        //console.log("Prece tohle nemuze vubec bezet", type)
        positions['drag0'] = { side: "home", pos: "A", initx: middle / 2 - (size + 5), inity: 5, width: size, color: "blue", ref: drag["drag0"], user: gamePlayers?.home?.A, suffix: gamePlayers?.home?.A && gamePlayers?.home?.A != "" ? gamePlayers?.home?.A : "homeA" }
        //console.log("Left pos " + String(middle / 2 - size / 2))
        positions['drag1'] = { side: "away", pos: "A", initx: middle + middle / 2 - (size + 5), inity: 5, width: size, color: "red", ref: drag["drag1"], user: gamePlayers?.away?.A, suffix: gamePlayers?.away?.A && gamePlayers?.away?.A != "" ? gamePlayers?.away?.A : "awayA" }
        //console.log("Right pos " + String(middle + middle / 2 - size / 2))
        positions['drag2'] = { side: "home", pos: "B", initx: middle / 2 + 5, inity: 5, width: size, color: "blue", ref: drag["drag2"], user: gamePlayers?.home?.B, suffix: gamePlayers?.home?.B && gamePlayers?.home?.B != "" ? gamePlayers?.home?.B : "homeB" }
        //console.log("Left pos " + String(middle / 2 - size / 2))
        positions['drag3'] = { side: "away", pos: "B", initx: middle + middle / 2 + 5, inity: 5, width: size, color: "red", ref: drag["drag3"], user: gamePlayers?.away?.B, suffix: gamePlayers?.away?.B && gamePlayers?.away?.B != "" ? gamePlayers?.away?.B : "awayB" }
        //console.log("Right pos " + String(middle + middle / 2 - size / 2))
        break;
      }

    }
    let assigned = Object.keys(positions)
    let allEntities = Object.keys(drag)
    var playersIndex = 0
    let entititiesIndex = assigned.length

    let playersExtra = reservationOwner == user.uid && dragEnabled && !['double','single'].includes(gameType) ? 1 : 0
    //console.log('WTF', assigned, allEntities)
    for (let i = 0; i < players.length + playersExtra; i++) {
      let user = players[i]
      positions[allEntities[entititiesIndex]] = { side: "players", pos: String(i), initx: 5 + (size + 5) * i, inity: size * 3, color: "purple", width: size, ref: drag[allEntities[entititiesIndex]], user: user, suffix: user }
      entititiesIndex += 1
    }
    let applPlayersExtra = reservationOwner != user.uid && dragEnabled && !getAlreadySelected(gamePlayers, players, appliedPlayers).includes(user.uid) ? 1 : 0
    for (let i = 0; i < appliedPlayers.length + applPlayersExtra; i++) {
      let user = appliedPlayers[i]
      positions[allEntities[entititiesIndex]] = { side: "appliedPlayers", pos: String(i), initx: 5 + (size + 5) * i, inity: size * 3, color: "purple", width: size, ref: drag[allEntities[entititiesIndex]], user: user, suffix: user }
      entititiesIndex += 1
    }
    let notifPlayersExtra = reservationOwner == user.uid && dragEnabled? 1 : 0
    for (let i = 0; i < notifiedPlayers.length + notifPlayersExtra; i++) {
      let user = notifiedPlayers[i]
      positions[allEntities[entititiesIndex]] = { side: "notifiedPlayers", pos: String(i), initx: 5 + (size + 5) * i, inity: size * 3, color: "purple", width: size, ref: drag[allEntities[entititiesIndex]], user: user, suffix: user }
      entititiesIndex += 1
    }

    return positions
  }
  const drop = { 'trash': { initx: middle - size / 2, inity: size + 10, ref: useRef<HandleTrash>() } }

  const [initObjsData, setInitObjData] = useState(calcInitPos(gameType))

  useEffect(() => {
    setInitObjData(calcInitPos(gameType))
  }, [gameType, gamePlayers, players])
  //console.log("nova data", initObjsData)

  const evaluateHitX = (obj1, obj2) => {
    //console.log(obj1, obj2)
    if (obj1.x >= obj2.x && obj1.x < obj2.x + obj2.width) {
      return true
    } else if (obj1.x + obj1.width >= obj2.x && obj1.x + obj1.width < obj2.x + obj2.width) {
      return true
    }
    return false
  }
  const evaluateHitY = (obj1, obj2) => {
    //console.log(obj1, obj2)
    if (obj1.y >= obj2.y && obj1.y < obj2.y + obj2.height) {
      return true
    } else if (obj1.y + obj1.height >= obj2.y && obj1.y + obj1.height < obj2.y + obj2.height) {
      return true
    }
    return false
  }
  const evaluateHit = (obj1, obj2) => {
    if (evaluateHitX(obj1, obj2) && evaluateHitY(obj1, obj2)) {
      return true
    } else {
      return false
    }
  }
  const iterObjs = (curr: { [key: string]: any }, all: { [key: string]: { [key: string]: any } }, type: 'move' | 'release') => {
    return new Promise<{ item: string, bool: boolean }>((resolve, reject) => {
      let hitPromises = []
      let allKeys = Object.keys(all)
      for (let i = 0; i < allKeys.length; i++) {
        let drag = allKeys[i]
        //console.log('Key dragu', drag, curr.id)
        if (curr.id != drag && all[drag].ref?.current?.measure) {
          //console.log(all[drag].ref)

          hitPromises.push(all[drag].ref.current.measure(drag))
          if (type == 'release') {
            all[drag].ref.current.decrease(() => { })
          }


        }
      }
      Promise.all(hitPromises)
        .then((responses) => {
          for (let i = 0; i < responses.length; i++) {
            let { id, fx, fy, width, height, px, py } = responses[i]

            let dragData = { id: id, x: px, y: py, width: width, height: height }
            //console.log('dragdata',dragData,  evaluateHit(curr, dragData))
            let hit = evaluateHit(curr, dragData)
            if (hit == true) {
              resolve({ item: id, bool: true })
            }

          }
          resolve({ item: null, bool: false })
        })
        .catch((e) => {
          //console.log('DPC', e)
          reject(e)
        })

    })

  }

  const onStartDrag = () => {
    //setShowTrash(true)
    drop["trash"]?.ref?.current?.show(() => { })
  }

  const onMoveHandle = (id) => {
    const allData = { ...initObjsData, ...drop }
    initObjsData[id].ref.current.measure()
      .then(({ id, fx, fy, width, height, px, py, panx, pany }) => {
        /* setDragObjData(prev => {
            return {
                ...prev,
                [id]: {...prev[id], id, fx, fy, width, height, px, py, panx, pany}
            }
        }) */
        return iterObjs({ id: id, x: px, y: py, width: width, height: height }, { ...initObjsData, ...drop }, 'move')
      })
      .then((res) => {
        //console.log(res, initObjsData)
        if (res.bool == true) {
          let hitRef = allData[res.item].ref
          //console.log('Volam show', hitRef)
          hitRef.current.increase(() => { })
        } else {
          Object.keys(allData).forEach((key) => {
            let draggable = allData[key].ref.current
            draggable.decrease(() => { })
          })
        }
      })
      .catch((e) => console.log(e.message))
  }


  const onReleaseHandle = (id) => {
    //console.log('I am dragging ' + id)
    const viewRef = initObjsData[id].ref
    viewRef.current.measure()
      .then(async ({ fx, fy, width, height, px, py, panx, pany }) => {
        //console.log(JSON.stringify({id: props.id, x: px, y: py, width: width, height: height}))
        //props.handler(props.id, {x: fx, y: fy, width: width})

        let hit = await iterObjs({ id: id, x: px, y: py, width: width, height: height }, { ...initObjsData, ...drop }, 'release')
        if (hit.bool == true) {
          //console.log("Hit")

          if (initObjsData[hit.item]) {
            moveToOtherPos(id, hit.item, () => {
              initObjsData[hit.item].ref.current.decrease(() => { })
              /* setInitObjData(prevState => {
              
              let newState = {
              ...prevState,
              [id]: {...prevState[id], side: prevState[hit.item].side, pos: prevState[hit.item].pos, initx: prevState[hit.item].initx, inity: prevState[hit.item].inity}, //, color: prevState[hit.item].color
              [hit.item]: {...prevState[hit.item], side: prevState[id].side, pos: prevState[id].pos, initx: prevState[id].initx, inity: prevState[id].inity },
              }
              return newState  
          })*/
              dispatchChange(() => {
                let prevState = { ...initObjsData }
                //console.log('Before', prevState)
                let newState = {
                  ...prevState,
                  [id]: { ...prevState[id], side: prevState[hit.item].side, pos: prevState[hit.item].pos, initx: prevState[hit.item].initx, inity: prevState[hit.item].inity }, //, color: prevState[hit.item].color
                  [hit.item]: { ...prevState[hit.item], side: prevState[id].side, pos: prevState[id].pos, initx: prevState[id].initx, inity: prevState[id].inity },
                }
                let changes = destructureToObjects(newState)
                //console.log('After', changes)
                return changes
              })
              //viewRef.current.resetPositions()
              initObjsData[hit.item].ref.current.resetPositions({})
              initObjsData[id].ref?.current?.resetPositions({})

            })

          } else if (drop[hit.item]) {
            let droppedObject = initObjsData[id].ref.current
            droppedObject.hide(() => {

              dispatchChange(() => {
                let currItem = initObjsData[id]
                let outGamePlayers = { ...gamePlayers }
                let outPlayers = [...players]
                let outAppliedPlayers = [...appliedPlayers]
                let outNotifiedPlayers = [...notifiedPlayers]
                if (currItem.side == 'players') {
                  outPlayers.splice(parseInt(currItem.pos), 1)
                } else if (['home', 'away'].includes(currItem.side)) {
                  outGamePlayers[currItem.side][currItem.pos] = ""
                } else if (currItem.side == 'appliedPlayers') {
                  outAppliedPlayers.splice(parseInt(currItem.pos), 1)
                } else if (currItem.side == 'notifiedPlayers') {
                  outNotifiedPlayers.splice(parseInt(currItem.pos), 1)
                }
                return {
                  gamePlayers: { ...outGamePlayers },
                  players: [...outPlayers],
                  appliedPlayers: [...outAppliedPlayers],
                  notifiedPlayers: [...outNotifiedPlayers]
                }
              })

              initObjsData[id].ref?.current?.resetPositions({})
            })
          }

        } else {
          viewRef.current.returnHome(0, 0, () => { })
        }
      })
    drop["trash"].ref?.current.hide(() => {
      setShowTrash(false)
    })
    //drag[id].current.returnHome(0, 0) //dragObjsData[id].initx
  }

  const moveToOtherPos = async (currId, toObj, callback) => {
    let moveToObj = initObjsData[toObj]
    let moveToRef = moveToObj.ref.current
    let currObj = initObjsData[currId]
    let currRef = currObj.ref.current
    let moveToPos = await moveToRef.measure()
    //let currPos = await currRef.measure()
    let currOrigPos = { origx: currRef.origx, origy: currRef.origy }
    let currNewPanCoords = { newpanx: moveToPos.px - currOrigPos.origx, newpany: moveToPos.py - currOrigPos.origy }
    let moveToNewPanCoords = { newpanx: currOrigPos.origx - moveToPos.px, newpany: currOrigPos.origy - moveToPos.py }
    //console.log(currNewPanCoords, moveToNewPanCoords)
    currRef.returnHome(currNewPanCoords.newpanx, currNewPanCoords.newpany, callback)
    if (moveToObj.user && moveToObj.user != "") {
      moveToRef.returnHome(moveToNewPanCoords.newpanx, moveToNewPanCoords.newpany, () => { })
    } else {
      moveToRef.hide(() => { })
    }

  }

  const destructureToObjects = (drags) => {
    //console.log('dess', drags)
    let dragKeys = Object.keys(drags)
    let gamePlayers: GPlayers = { home: {}, away: {} }
    let players: string[] = []
    let appliedPlayers: string[] = []
    let notifiedPlayers: string[] = []
    for (let i = 0; i < dragKeys.length; i++) {
      let dragKey = dragKeys[i]
      let drag = drags[dragKey]
      if (['home', 'away'].includes(drag['side']) && drag.user && drag.user != "") {
        //console.log('Novy drag', drag)
        gamePlayers[drag['side']][drag['pos']] = drag['user']
      } else if (drag['side'] == 'players' && drag.user && drag.user != "") {
        players.push(drag['user'])
      } else if (drag['side'] == 'appliedPlayers' && drag.user && drag.user != "") {
        appliedPlayers.push(drag['user'])
      } else if (drag['side'] == 'notifiedPlayers' && drag.user && drag.user != "") {
        notifiedPlayers.push(drag['user'])
      }
    }
    /*console.log('des', {
      gamePlayers: { ...gamePlayers },
      players: [...players]
    })*/
    return {
      gamePlayers: { ...gamePlayers },
      players: [...players],
      appliedPlayers: [...appliedPlayers],
      notifiedPlayers: [...notifiedPlayers],
    }
  }

  const dispatchChange = (callback) => {
    dispatchActionForChanges(
      callback
    )
  }
  const reshuffle = (positions: string[], gamePlayers: GPlayers, players: string[]): { gamePlayers: GPlayers, players: string[] } => {

    let newGamePlayers: GPlayers = {
      home: {},
      away: {},
    }
    let newPlayers: string[] = []
    let sides: string[] = Object.keys(newGamePlayers)
    let playersCounter = 0
    for (let i = 0; i < sides.length; i++) {
      let side = sides[i]
      let poses = ['A','B']//positions//Object.keys(gamePlayers[side] || {})
      for (let j = 0; j < poses.length; j++) {
        let pos = poses[j]
        if (positions.includes(pos) && gamePlayers[side][pos]) {
          newGamePlayers[side][pos] = gamePlayers[side][pos]
        } else if (positions.includes(pos) && playersCounter < players.length) {
          let player = players[playersCounter]
          newGamePlayers[side][pos] = player
          playersCounter += 1
        } else if (gamePlayers[side][pos] != "" && typeof gamePlayers[side][pos] == "string") {
          newPlayers.push(gamePlayers[side][pos])
        }
      }
    }
    
    return { gamePlayers: newGamePlayers, players: [...players.slice(playersCounter),...newPlayers] }
  }

  const reshuffleByGameType = (type: 'double' | 'single' | 'group', gamePlayers: GPlayers, players: string[]) => {
    var positions = []

    if (type == 'double') {
      positions = ['A', 'B']
      return reshuffle(positions, gamePlayers, players)
    } else if (type == 'single') {
      positions = ['A']
      return reshuffle(positions, gamePlayers, players)
    } else {
      positions = []
      return reshuffle(positions, gamePlayers, players)
    }
  }
  //const getAlreadySelected = () => {
  //  return Object.keys(initObjsData).map((key) => initObjsData[key]).filter(item => item && item.user != "" && item.side != 'notifiedPlayers').map((user) => user.user)
  //}

  


  const onPressAppliedList = (pressType: {side: string, pos: number}) => {
    let alreadySelected = getAlreadySelected(gamePlayers, players, appliedPlayers)
    if (dragEnabled) {
      onPressEmpty(pressType)
    } else if (!alreadySelected.includes(user.uid) && pressType.side == 'players' && type == "opengame") {
      applyMethod()
    } else if (alreadySelected.includes(user.uid) && pressType.side == 'players' && type == "opengame") {
      applyMethod()
    }
  }

  const playersEditButtonCpation = (pressType: {side: string, pos: number}) => {
    let alreadySelected = getAlreadySelected(gamePlayers, players, appliedPlayers)
    if (dragEnabled) {
      return i18n.t(tokens.app.components.gameSettings.gameSettingEmpty)
    } else if (!alreadySelected.includes(user.uid) && pressType.side == 'players' && type == "opengame") {
      return i18n.t(tokens.app.components.gameSettings.gameSettingsAddSelf)
    } else if (alreadySelected.includes(user.uid) && pressType.side == 'players' && type == "opengame") {
      return i18n.t(tokens.app.components.gameSettings.gameSettingsRemoveSelf)
    }
  }

  const gameTypeItems = () => {
    return objectToPicker(gameTypes).filter(item => {
      if (currentClub?.allowedGameTypes && currentClub?.allowedGameTypes[item.key] && currentClub?.allowedGameTypes[item.key] == true) {
        if (item.key == 'training' && user.type == "user" && (user.isCoach == true || user.isAdmin == true)) {
          return true
        } else if (item.key == 'training' && user.type == "clubs") {
          return true
        } else if (item.key == 'undefined') {
          return false
        } else if (item.key != 'training') {
          return true
        } else {
          return false
        }
       } else {
        return false
       }
      })
      
  }


  const onPressEmpty = (type) => {
    //console.log('On press', props.dragEnabled)
    if (dragEnabled == false) return
    let alreadySelected = getAlreadySelected(gamePlayers, players, appliedPlayers)
    DeviceEventEmitter.addListener("event.mapNewPlayerToGame", (eventData: string) => {

      dispatchChange(() => {

        let outGamePlayers: GPlayers = {
          ...gamePlayers,

        }
        let outPlayers = [...players]
        let outAppliedPlayers = [...appliedPlayers]
        let outNotifiedPlayers = [...notifiedPlayers]
        if (type.side == 'players') {
          if (outPlayers.length < parseInt(type.pos)) {
            outPlayers.splice(parseInt(type.pos), 1, eventData)
          } else {
            outPlayers.push(eventData)
          }
        } else if (type.side == 'appliedPlayers') {
          if (outAppliedPlayers.length < parseInt(type.pos)) {
            outAppliedPlayers.splice(parseInt(type.pos), 1, eventData)
          } else {
            outAppliedPlayers.push(eventData)
          }
        } else if (type.side == 'notifiedPlayers') {
          if (outAppliedPlayers.length < parseInt(type.pos)) {
            outNotifiedPlayers.splice(parseInt(type.pos), 1, eventData)
          } else {
            outNotifiedPlayers.push(eventData)
          }
        } else if (['home', 'away'].includes(type.side)) {
          outGamePlayers[type.side][type.pos] = eventData
        }
        return {
          gamePlayers: { ...outGamePlayers },
          players: [...outPlayers],
          appliedPlayers: [...outAppliedPlayers],
          notifiedPlayers: [...outNotifiedPlayers],
        }
      })
    }

    )
    //console.log('already selected', alreadySelected)
    navigation.navigate('ContactsScreenStack', {
      action: 'selectPlayer',
      path: type,
      alreadySelected: alreadySelected
    })
  }

  

  return (visible ?
    <View style={styles.container}>
      <FormHeading 
      heading={i18n.t(tokens.app.screens.manageReservation.reservationTypeAndPlayersHeading)} 
      editButton={editButton}/>
      {selectableType == false ? null :
        <FormEntry caption={i18n.t(tokens.app.components.gameSettings.gameSettingGameType)} validity={true} editable={!disabled}>
          <CustomPickerSelect
            onValueChange={(value) => {
              const newGamePlayers = reshuffleByGameType(value, gamePlayers, players)
              dispatchChange(() => {
                return { gamePlayers: { ...newGamePlayers.gamePlayers }, players: [...newGamePlayers.players], gameType: value, appliedPlayers: [...appliedPlayers], notifiedPlayers: [...notifiedPlayers] }
              })
            }
            }
            value={gameType}
            placeholder={{ label: i18n.t(tokens.app.components.gameSettings.gameSettingGameType), value: undefined }}
            disabled={disabled}
            items={gameTypeItems()}
            //textAlign="right"
          />

        </FormEntry>
      }
      {['double', 'single'].includes(gameType)?
      <View style={styles.gameRowContainer}>
        <Side 
        positions={Object.entries(initObjsData)} 
        side={["home"]} 
        size={size} 
        dragEnabled={dragEnabled}
        onMoveHandle={onMoveHandle}
        onPressEmpty={onPressEmpty}
        onReleaseHandle={onReleaseHandle}
        onStartDrag={onStartDrag}
        />
        <View style={styles.vsContainer}>
          <Text>
            {i18n.t(tokens.app.components.gameSettings.gameSettingsAgainst)}
          </Text>
        </View>
        <Side 
        positions={Object.entries(initObjsData)} 
        side={["away"]} 
        size={size}  
        dragEnabled={dragEnabled}
        onMoveHandle={onMoveHandle}
        onPressEmpty={onPressEmpty}
        onReleaseHandle={onReleaseHandle}
        onStartDrag={onStartDrag}
        />
      </View>
      :null}
      <FormHeading
        heading={['double', 'single'].includes(gameType) ? i18n.t(tokens.app.components.gameSettings.gameSettingRemPlayers) : i18n.t(tokens.app.components.gameSettings.gameSettingPlayers)}
        editButton={disabled?null:() => onPressAppliedList({ side: "players", pos: players.length })}
        editButtonCaption={playersEditButtonCpation({ side: "players", pos: players.length })}
      />

      <ListPlayers 
      positions={Object.entries(initObjsData)} 
      side={['double', 'single'].includes(gameType) ? ["players","appliedPlayers"] : ["players"]} 
      size={size}  
      dragEnabled={dragEnabled}
      enableAdd={!disabled}
      onMoveHandle={onMoveHandle}
      onPressEmpty={onPressEmpty}
      onReleaseHandle={onReleaseHandle}
      onStartDrag={onStartDrag}
      />


      {type == "opengame" && !['double', 'single'].includes(gameType)?
      <>
      <FormHeading
        heading={i18n.t(tokens.app.components.gameSettings.gameSettingsApplied)}
        //editButton={props.dragEnabled ?  () => onPressEmpty({ side: "appliedPlayers", pos: props.appliedPlayers.length }): null}
        //editButtonCaption={i18n.t(tokens.app.components.gameSettings.gameSettingEmpty)}
      />
      <ListPlayers 
      positions={Object.entries(initObjsData)} 
      side={["appliedPlayers"]} 
      size={size}  
      dragEnabled={dragEnabled}
      enableAdd={dragEnabled}
      onMoveHandle={onMoveHandle}
      onPressEmpty={onPressEmpty}
      onReleaseHandle={onReleaseHandle}
      onStartDrag={onStartDrag}
      />
      </>
      : null
      }

      {dragEnabled && type == "opengame"?
      <>
      <FormHeading
        heading={i18n.t(tokens.app.components.gameSettings.notifyPlayers)}
        //editButton={props.dragEnabled ?  () => onPressEmpty({ side: "notifiedPlayers", pos: props.notifiedPlayers.length }) : null}
        //editButtonCaption={i18n.t(tokens.app.components.gameSettings.gameSettingEmpty)}
      />
      <ListPlayers 
      positions={Object.entries(initObjsData)} 
      side={["notifiedPlayers"]} 
      size={size}  
      dragEnabled={dragEnabled}
      enableAdd={dragEnabled}
      onMoveHandle={onMoveHandle}
      onPressEmpty={onPressEmpty}
      onReleaseHandle={onReleaseHandle}
      onStartDrag={onStartDrag}
      />
      </>: null}
      <View
      style={styles.trashContainer}>
      <DropFunc
      id={"trash"}
      size={size}
      ref={drop["trash"].ref}
      />
      </View>
    </View> : null
  )


}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 5,

  },
  gameRowContainer: { width: "100%", flexDirection: "row", justifyContent: "space-evenly", paddingTop: 10, },
  sideContainer: {
    flexDirection: "column",
    width: "45%",

  },
  sideTitle: {
    alignItems: "center"
  },
  sidePlayersContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  vsContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  listContainer: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: 15,
    paddingRight: 15,
  },
  trashContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
})



export default GamePlayers

