import React, { useEffect, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import styles from './styles';
//import DatePicker from 'rmc-date-picker';
//import 'rmc-picker/assets/index.css';
//import 'rmc-date-picker/assets/index.css';
import { Route, useIsFocused } from '@react-navigation/native';
import { shallowEqual } from 'react-redux';
import { Club } from '../../../functions/src/interfaces';
import Calendar from '../../components/Calendar/Calendar';
import CustomPickerSelect from '../../components/CustomPickerSelect/CustomPickerSelect';
import RightTopBar from '../../components/RightTopBar';
import i18n, { tokens } from '../../i18n';
import { setSelectedClub } from '../../store/actions/actionsAuth';
import { useAppDispatch, useAppSelector, useTraceUpdate } from '../../store/hooks';
import { RootStore } from '../../store/reducers/rootReducer';
import RenderContactClub from '../../components/ContactClub';
import SmallButton from '../../components/SmallButton';
import RenderOnFocus from '../../components/RenderOnFocus';


type Props = {
    route: Route<'ReservationScreen', {action?: string, alreadySelected?: string[]}>
}

function ReservationScreen(props: Props) {
    //console.log('reservations screen props', props)
    const selectedClub: string = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    const [availableClubs, setAvailableClubs] = useState<Club[]>([])
    const allClubs: {[clubId: string]: Club} = useAppSelector((state: RootStore) => state.auth.allClubs, shallowEqual)
    const dispatch = useAppDispatch()
    const isFocused = useIsFocused()
    
    console.log('Rerendering reservations')

    useTraceUpdate(props);
    
    useEffect(() => {
        let keys = Object.keys(allClubs)
        var options = []
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let club: Club = allClubs[key]
            if (club.club_name) {
                //let entry = { label: club.club_name, value: key }
                options.push(club)
            }
            
        }
        setAvailableClubs(options)
    }, [allClubs])
    //generateHoursData()
    //getNumberOfFields().then((data) => {
    //    console.log('Pocet hrist', data)

    //})
    return (
        <RenderOnFocus isFocused={isFocused}>
        <View style={styles.container}>
            {/* <TopBar textBoxAction={() => {}} navigation={props.navigation}/> */}
            {/* <RightTopBar title={props.route?.name}/>  */}
            {/*  <Text maxFontSizeMultiplier={1.2}>{Constants.expoConfig.version + " " + scale + " " + windowWidth + " " + windowHeight + " " + pixRat}</Text>  */} 
            {selectedClub && selectedClub != ""?
            <Calendar selectedClub={selectedClub} changeMarket={false}/>:
            <View
            style={{alignSelf: "stretch"}}
            >
            <FlatList
           
           data={availableClubs}
           renderItem={({item}) => <RenderContactClub item={item} action={() => dispatch(setSelectedClub(item.firebaseId))}/>}
           keyExtractor={(item) => item.firebaseId}
           />
            </View>
            }
            {/* <CustomPickerSelect 
            onValueChange={(value) => dispatch(setSelectedClub(value))} 
            aditionalStyle={{backgroundColor: "white"}}
            value={selectedClub}
            placeholder={{label: i18n.t(tokens.app.screens.index.clubPlaceholder), value: undefined}}
            
            items={availableClubs}
            /> */
            }
            
        </View> 
        </RenderOnFocus>
    )
}


export default React.memo(ReservationScreen, (a, b) => JSON.stringify(a) == JSON.stringify(b))

