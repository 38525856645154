import { Route, useNavigation } from "@react-navigation/native";
import { NotificationContent } from "expo-notifications";
import React, { useEffect } from "react";
import { FlatList, Platform, Text, Pressable, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { shallowEqual } from "react-redux";
import { Notification, NotificationTypes } from "../../../functions/src/interfaces";
import FormEntryNoCaption from "../../components/FormEntryNoCaption";
import WebWrapper from "../../components/WebWrapper";
import i18n from "../../i18n";
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks";
import { NotificationsActions } from "../../store/reducers/notificationsReducer";
import { RootStore } from "../../store/reducers/rootReducer";
import { globalStyleSheet } from "../../utilities/globalStyles";
import { _handleNotification } from "../HomeScreen/HomeComponent";

type Props = {
    route: Route<'NotificationsScreen', {}>
}

function NotificationsScreen(props: Props): JSX.Element {
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const notifications: (Notification & {read: boolean})[] = useAppSelector((state: RootStore) => {
        let readState = state.notifications.notificationsLastRead
        let notifications = Object.values(state.notifications.subscribedNotifications)
        .sort((a,b)=> a.updateDate < b.updateDate ? 1 : -1)
        .map((notification) => {return {...notification, read: readState.all < notification.updateDate}})
        return notifications
    }, shallowEqual)

    const onPress = (item: Notification) => {
        let notifData: NotificationContent = {
            data: {
                notification_type: item.notification_type,
                id: item.id,
                clubId: item.clubId
            },
            title: item.title,
            subtitle: "",
            sound: null,
            body: item.body,
        }
        _handleNotification(notifData, navigation)
    }

    const title = (item: Notification) => {
        if ((Object.keys(item).includes('data'))) {
            //Legacy
            return i18n.t(item['data'].notification_type)
        } else if (item.notification_type == NotificationTypes.market) {
            return item.marketType ? i18n.t(item.marketType) : i18n.t(item.notification_type) 
        } else {
            return i18n.t(item.notification_type)
        }
    }

    useEffect(() => {
        //console.log('Neco')
        return () => {
            dispatch({type: NotificationsActions.notificationsSubscriptionLastRead, data: {all: new Date}})
        }
    }, [])

    return (
        <View style={globalStyleSheet.container}>
        <WebWrapper>
        <KeyboardAwareScrollView  
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >
            <FlatList
            contentContainerStyle={globalStyleSheet.container}
            data={notifications}
            keyExtractor={(item) => item.firebaseId}
            renderItem={(item) => (
                <Pressable
                onPress={() => onPress(item.item)}
                >
                <FormEntryNoCaption
                editable={item.item.read}
                >
                    <Text
                    style={globalStyleSheet.entityText}
                    >{`${title(item.item)} - ${item.item.body}`}
                    </Text>
                </FormEntryNoCaption>
                </Pressable>)}
            />

        </KeyboardAwareScrollView>
        </WebWrapper>
        </View>
    )
}

export default NotificationsScreen
