import React from "react";
import { View } from "react-native";
import { Membership, MembershipTypes, Season } from "../../../../functions/src/interfaces";
import CustomPickerSelect from "../../../components/CustomPickerSelect/CustomPickerSelect";
import FormEntry from "../../../components/FormEntryNew";
import i18n, { tokens } from "../../../i18n";
import { editSubscription } from "../../../store/actions/actionsAuth";
import { useAppDispatch } from "../../../store/hooks";
import { membershipTypes } from "../../../utilities/constants";
import { objectToPicker } from "../../../utilities/funcs";
import TourDetail from "./TourDetail";

type Props = {
    subscription: Membership;
    disabled: boolean,
    seasons: {[seasonId: string]: Season},
}

function MembershipDetail(props: Props): JSX.Element {

    const dispatch = useAppDispatch()

    const dispatchChanges = (callback: (membership: Membership) => Membership) => {
        //let changes = Object.keys(value)
        var data: Membership = {
            ...props.subscription
        }
        data = callback(data)
        dispatch(editSubscription(data))
    }

    return (
        <View>
            <FormEntry
            caption={i18n.t(tokens.app.screens.subscription.subscriptionType)}
            editable={true}
            >
                <CustomPickerSelect
                onValueChange={(value) => dispatchChanges((memb) => {
                    return {...memb, type: value}
                  
                })}
                value={props.subscription?.type}
                placeholder={{ label: i18n.t(tokens.app.screens.subscription.subscriptionType), value: undefined }}
                disabled={props.disabled}
                items={objectToPicker(membershipTypes)}
                //textAlign="right"
                />
            </FormEntry>
            {
                props.subscription?.type == MembershipTypes.tourSubscription ?
                <TourDetail seasons={props.seasons} subscription={props.subscription} dispatchChange={dispatchChanges}/>:null
            }
        </View>
    )
}

export default MembershipDetail