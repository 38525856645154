import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { View, Text, Button, Pressable } from 'react-native'

import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Market, MarketLongreservation, MarketOpengame, MarketReservation, MarketTypes, user } from '../../../../functions/src/interfaces';
import UserAvatar from '../../../components/UserAvatar';
import { marketTypes, weekdays } from '../../../utilities/constants';
import { monthString, timeFormatter } from '../../../utilities/funcs';
import { gameTypesColors, mainFont, mainFontBold } from '../../../utilities/globalStyles';
import { UserOrClub } from '../../../store/reducers/usersReducer';



type Props = {
    data: MarketLongreservation<Market> | MarketOpengame<Market> | MarketReservation<Market>
    users: {[userId: string]: UserOrClub}
}

const typeStyling = {
    "opengame": {
        "bgColor":gameTypesColors["opengame"]
    },
    "couple": {
        "bgColor":"#FFF7E6"
    },
    "trainee": {
        "bgColor":"#FFEBE6"
    },
    "trainer": {
        "bgColor":"#FAFFE6"
    },
    "longreservation": {
        "bgColor":gameTypesColors["longreservation"]
    },
    "reservation": {
        "bgColor":gameTypesColors["reservation"]
    },
    "subscription": {
        "bgColor":gameTypesColors["subscription"]
    },
    "credit": {
        "bgColor":gameTypesColors["credit"]
    }
}

const getUserName = (user: UserOrClub) => {
    
    if (user.type == "user") {
    return user.first_name + " " + user.family_name
    } else if (user.type == "clubs") {
    return user.club_name
    } else {
    return "Error"
    }

  }

const MarketEntryHeader: React.FC<Props> = (props) => {
    //console.log('Forme entry props: ', props)
    
    const user: UserOrClub = props.users[props.data.uid] 
    
    return (
        <View style={{
            
            backgroundColor: typeStyling[props.data.type].bgColor,
            flexDirection: "row",
            alignContent:"space-between",
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 10,
            paddingRight: 10,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            }}>
            <UserAvatar size={40} uid={props.data.uid}/>
                <View style={{flexDirection: "column", marginRight:"auto"}}>
                    <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFontBold}}>
                        {getUserName(user)}
                    </Text>
                    <Text maxFontSizeMultiplier={1.2}>
                        {marketTypes[props.data.type]}
                    </Text>
                </View>
                <View style={{
                    flex: 1,
                    //justifyContent: "flex-end",
                    alignItems: "flex-end",
                }}>
                    <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFontBold}}>{monthString(props.data.dueDate)}</Text>
                    {[MarketTypes.longreservation, MarketTypes.opengame, MarketTypes.reservation].includes(props.data.type)?
                    <Text maxFontSizeMultiplier={1.2} style={{fontFamily: mainFont}}>
                        {weekdays[props.data.day]?weekdays[props.data.day]:null}
                        {' '}
                        {timeFormatter(props.data.timeFrom.toString()) + " - " + timeFormatter(props.data.timeTo.toString())}
                    </Text>:null}
                </View>
                
        </View>
    )
    
}


const styles = StyleSheet.create({
       
})


export default MarketEntryHeader

