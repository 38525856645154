import { StyleSheet } from 'react-native';
import { normalize } from '../../utilities/constants';
import { styles } from '../../utilities/globalStyles';

export default StyleSheet.create({
    ...styles,
    inputOk: {
        ...styles.inputOk, textAlign: "right"
    },
    inputKo: {
        ...styles.inputKo, textAlign: "right"
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    title: {

    },
    logo: {
        flex: 1,
        height: 120,
        width: 90,
        alignSelf: "center",
        margin: 30
    },
    buttonTitle: {
        color: 'white',
        fontSize: 16,
        fontWeight: "bold"
    },
    footerView: {
        flex: 1,
        alignItems: "center",
        marginTop: 20
    },
    footerText: {
        fontSize: 16,
        color: '#2e2e2d'
    },
    footerLink: {
        color: "#788eec",
        fontWeight: "bold",
        fontSize: 16
    }
})