import React, { useState } from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { Market, News } from '../../functions/src/interfaces';
import FormEntryNoCaption from './FormEntryNoCaption';
import FormHeading from './FormHeading';
import i18n, { tokens } from '../i18n';
import { useAppDispatch, useAppNavigation } from '../store/hooks';
import { StateLoader } from '../store/reducers/reservationReducer';
import { normalize } from '../utilities/constants';
import { globalStyleSheet } from '../utilities/globalStyles';


type Props = {
    managedMarketData: StateLoader<Market> | StateLoader<News>,
    dispatchActionForChanges: (callback) => void,
    disabled: boolean
}

function MessageComponent(props: Props): JSX.Element {

    //const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const [editMessageFlag, setEditMessageFlag] = useState(props.managedMarketData.data?.firebaseId ? false : true)
    
    const dispatchChange = (callback: (params) => void) => {
        props.dispatchActionForChanges(
            callback
        )
    }

    return (
        <View
            style={styles.container}>
            <FormHeading 
            heading={i18n.t(tokens.app.screens.longreservation.messageHeading)} 
            editButton={props.disabled?null:() => setEditMessageFlag(prev => !prev)}
            />
            <FormEntryNoCaption
            editable={editMessageFlag}>
            {editMessageFlag?
            <TextInput maxFontSizeMultiplier={1.2} multiline 
            style={styles.textInput} 
            placeholder={i18n.t(tokens.app.screens.longreservation.messagePlaceholder)} 
            value={props.managedMarketData.data.text}
            onChangeText={(itemValue) =>
                dispatchChange(() => { return {text: itemValue}})
            }
            />:
            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{props.managedMarketData.data.text == ""?i18n.t(tokens.app.screens.longreservation.messagePlaceholder):props.managedMarketData.data.text}</Text>
            }
            </FormEntryNoCaption>
        </View>
    )
}

const styles = StyleSheet.create({

    textStyle: {

        textAlign: "right",
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',

    },
    container: {
        width: "100%",

    },
    textInput: {width: "100%", minHeight: 100, backgroundColor: "white", verticalAlign: "top"}
});

export default MessageComponent
