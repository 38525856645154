import React from 'react';
import { StyleSheet, Text, Pressable, View } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

import { normalize } from '../utilities/constants';
import { mainFont } from '../utilities/globalStyles';

type Props = {
    size?: number,
    icon?: any,
    removeButton?: () => void,
    editButton?: () => void,
    editButtonCaption?: string,
    heading: string
}

function FormHeading(props: Props): JSX.Element {
    

    const padding = 5

    

    const calcFlexes = (props) => {
        let icon = 0.1
        let caption = 0.3
        let children = 0.6
        if(!props.icon) {
            caption = caption + icon
            icon = 0
        }
        if(!props.caption) {
            children = caption + children
            caption = 0
        }
        //console.log('wtf', [icon,caption,children])
        return [icon,caption,children]
    }
    
    const [iconFlex, captionFlex, childrenFlex] = calcFlexes(props)
    //const contactFlag = 
    //console.log('nalezeno', props)
    
    const styles = StyleSheet.create({
        
        entityContainer: {
            flexDirection: 'column',
            //justifyContent: 'space-between',
            width: "100%",
            marginTop: 10,
            borderBottomColor: '#cccccc',
            borderBottomWidth: 0,
            paddingBottom: 0,
            paddingLeft: 10,
            paddingRight: 10,
            //paddingLeft: padding,
            //paddingRight: padding,
            marginBottom: 3,
        },
        entityRow: {
            flexDirection: 'row',
            flex: 1,
            alignItems: "center",
        },
        entityText: {
            fontSize: normalize(props.size?props.size:20),
            color: 'black',
            //marginRight: 'auto',
            //width: (windowWidth - 2*padding) * 0.3,
            //fontWeight: "600",
            fontFamily: 'AvertaStd-Semibold',
            //alignSelf: "flex-start",
            
        },
        entityIcon: {
            flex: iconFlex,
        },
        entityCaption: {
            flex: captionFlex,
        },
        entityChildren: {
            flex: childrenFlex,
            //alignItems: "flex-end",
        },
        errorStyle: {
            color: "red",
        },
        errorContainer: {
            flex: 1,
            alignItems: "flex-end",
        },
        actionText: {
            fontSize: normalize(16),
            fontFamily: mainFont,
        },
        actionStyle: {
            margin: 5
        }
    })

  
    return (
        
        <View style={styles.entityContainer}>
            <View style={styles.entityRow}>
            {props.icon?<View style={styles.entityIcon}
            >
                <Ionicons 
                name={props.icon}
                color="black"
                size={normalize(18)}
                
                />
            </View>:null}
            <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>{props.heading}</Text>
            <View
            style={{alignSelf: "flex-end", marginLeft:"auto",flexDirection:"row"}}
            >
            {props.removeButton?
            <Pressable
            onPress={() => props.removeButton()}
            style={styles.actionStyle}
            >
                <Text maxFontSizeMultiplier={1.2} style={styles.actionText}>{props.editButtonCaption?props.editButtonCaption:"Odebrat"}<Ionicons 
                name="close-outline"
                color={"black"}
                size={normalize(20)}
                
                /></Text>
            </Pressable>
            :null}
            {props.editButton?
            <Pressable
            onPress={() => props.editButton()}
            style={styles.actionStyle}
            >
                <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>{props.editButtonCaption?props.editButtonCaption + "  ":"  "}<Ionicons 
                name="create-outline"
                color={"black"}
                size={normalize(20)}
                
                /></Text>
            </Pressable>
            :null}
            </View>
            </View>
        </View>
    )
}



export default FormHeading

