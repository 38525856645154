import React from "react";

type Props = {
    children: React.ReactNode,
    isFocused: boolean,
    [prop: string]: any,
}

class RenderOnFocus extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

    }
    state = {};
  
    shouldComponentUpdate(nextProps: Props, nextState, nextContext) {
      return nextProps.isFocused;
    }
  
    render() {
      return <>{this.props.children}</>;
    }
}

export default RenderOnFocus