import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { shallowEqual } from 'react-redux';
import { Market } from '../../../../functions/src/interfaces';
import FormEntry from '../../../components/FormEntryNew';
import FormHeading from '../../../components/FormHeading';
import i18n, { tokens } from '../../../i18n';
import { useAppSelector } from '../../../store/hooks';
import { normalize } from '../../../utilities/constants';
import MarketStateComponent from './MarketStateComponent';


type Props = {

  marketData: Market,
  
}

function OwnerComponentBase(props: Props): JSX.Element {
  //console.log('VOalm', props)

  const users = useAppSelector(state => state.users.usersData, (a,b) => JSON.stringify(a) == JSON.stringify(b))
  

  const getUserName = (uid) => {
    if (uid && users && users[uid]) {
      let user = users[uid]
      if (user.type == "user") {
        return user.first_name + " " + user.family_name
      } else if (user.type == "clubs") {
        return user.club_name
      } else {
        return "Error"
      }
      
    } else {
      return "Neuvedeno"
    }

  }


  return (<>
    <FormHeading 
    heading={i18n.t(tokens.app.screens.manageReservation.manageReservationHeading)} 
    
    />
    <MarketStateComponent managedMarketData={props.marketData}/>
    <FormEntry 
    caption={i18n.t(tokens.app.screens.manageReservation.reservationRequestor)}
    editable={false}>
      <Text maxFontSizeMultiplier={1.2} style={styles.textStyle}>
        {getUserName(props.marketData.uid)}
      </Text>
    </FormEntry>
    
  </>
  )
}

const styles = StyleSheet.create({

  textStyle: {

    textAlign: "right",
    fontSize: normalize(18),
    fontFamily: 'AvertaStd-Regular',
    
  },
  inputText: {
    backgroundColor: "white",
    borderColor: "grey",
    //borderWidth: 1,
    borderRadius: 5,
    width: "100%",
    height: 30

  }
});

export default OwnerComponentBase
