import React, { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import QRCode from 'react-native-qrcode-svg';
import { shallowEqual } from 'react-redux';
import { Club, PaymentData, PaymentModes } from '../../../../functions/src/interfaces';
import BigButton from '../../../components/BigButton';
import i18n, { tokens } from '../../../i18n';
import { useAppSelector } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/checkoutReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { normalize } from '../../../utilities/constants';
//import { getUserName } from '../../../../functions/src/common';
//https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
type Props = {
    paymentAmt: number,
    paymentId: number,
    paymentData: StateLoader<PaymentData & {clientSecret?:string}>,
    reinitialize?: (method: PaymentModes) => Promise<string>,
    isAdmin: boolean,
}

function PaymentQR(props:Props): JSX.Element {
    const [isLoaded, setIsLoaded] = useState(false)
    const club: Club = useAppSelector((state:RootStore) => state.auth.allClubs?.[state.auth?.selectedClub], shallowEqual)
    const [paymentMsg,setPaymentMsg] = useState<string>("")  
    const payee = useAppSelector((state: RootStore) => state.users?.usersData[props.paymentData?.data?.uid], (a, b) => JSON.stringify(a) == JSON.stringify(b))


    useEffect(() => {
        if (club && club.iban != "") {
            setPaymentMsg(`SPD*1.0*ACC:${club.iban}*AM:${props.paymentAmt}.00*CC:CZK*MSG:${props.paymentData.data.firebaseId};${props.paymentData.data.checkoutId};${payee.email}*X-VS:${String(props.paymentId)}`)
            setIsLoaded(true)
        } else {
            setIsLoaded(false)
        }
    }, [club])
    return (
        <View
        style={styles.container}>
            {isLoaded?
            props.paymentData?.data?.firebaseId ?
            <View>
            <QRCode
            value={paymentMsg}
            size={normalize(200)}
            />
            
            </View>:
            <View style={styles.buttonView}>
            <BigButton
            buttonText={i18n.t(tokens.app.screens.payment.paymentButtonPay)}
            onPress={() => props.reinitialize(props.paymentData.data.type)}
            />
            </View>
            
            :<ActivityIndicator size={"small"}/>}
        </View>
    )
}


const styles = StyleSheet.create(
    {
        container: {
            justifyContent: "center",
            alignItems: "center",
        },
        buttonView: {
            flexDirection: "row",
            flex: 1,
            height: 150,
        }
    }
)

export default PaymentQR