import { useNavigation } from "@react-navigation/native"
import React, { useEffect } from "react"
import { shallowEqual } from "react-redux"
import { subscribeToSeasons } from "../../store/actions/actionsAuth"
import { useAppDispatch, useAppNavigation, useAppSelector } from "../../store/hooks"
import { RootStore } from "../../store/reducers/rootReducer"
import { getCurrentAuthState } from "../../store/selectors"
import HomeComponent from "./HomeComponent"

type Props = {
    loggedIn: boolean
}


function HomeScreen(props: Props):JSX.Element {
    
    const { isEmpty, user, isLoaded } = getCurrentAuthState()
    const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, (a,b) => a == b)
    //const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    useEffect(() => {

        let unsub = selectedClub ? dispatch(subscribeToSeasons(selectedClub)) : () => {}

        return () => unsub()
    }, [selectedClub])
    console.log('Rendering base')
    return (<HomeComponent loggedIn={isEmpty} />)
} 
export default HomeScreen