import { Caption } from "@expo/html-elements"
import { Alert } from "react-native"
import { AllResTypes, CheckoutData, GameTypes, Reservation, ReservationStatuses, ReservationTypes } from "../../../functions/src/interfaces"
import { calcPrice } from "../../utilities/funcs"
import { isDateEqual } from "../../utilities/funcs"
import { GPlayers } from "../../utilities/interfaces"

export enum ReservationActions {
    managedReservationAction = 'MANAGED_RESERVATION_ACTION',
    editedReservation = 'EDITED_RESERVATION',
    subscribedReservation = 'SUBSCRIBED_RESERVATION',
    editSetValue = 'EDIT_SET_VALUE',
    rankedGameAction = 'RANKED_GAME_ACTION',
    addSetPress = 'ADD_SET_PRESS',
    removeSetPress = 'REMOVE_SET_PRESS',
    reservationResultToState = 'RESERVATION_RESULT_TO_STATE',
    reservationResultAdditionResponse = 'RESULT_ADDITION_RESPONSE',
    reservationSetSelection = 'SET_SELECTION',
    reservationsUserQuery = 'QUERIED_USER_RESERVATIONS',
    reservationsChanges = 'RESERVATIONS_CHANGES',
    reqervationsListQuery = 'RESERVATIONS_LIST_QUERY',
}

export type StateLoader<T> = {
    isLoaded: boolean,
    isSuccess?: boolean,
    isChanged?: boolean,
    isActionPressed?: boolean,
    error?: {message: string}
    data: T,
    validations?: {[item: string]: {validity: boolean, error: string}}
}

export type Selection = {
    dateFrom?: Date,
    dateTo?: Date,
    court?: string,
    segmentEnds?: Date, 
    segmentStarts?: Date,
    season?: string,
    isSelected?: boolean,
}

export type Set = {
    home: {games: number},
    away: {games: number},
}

export type Score = {
    firebaseId?: string,
    gamePlayers: GPlayers,
    rankedGame: boolean,
    result: Set[]
}

export type ReservationsState = {
    reservations: StateLoader<{[key: string]: AllResTypes}>,
    managedReservation: StateLoader<AllResTypes> & {seasonsData?: any},
    subscribedReservation: AllResTypes,
    selection: Selection,
    managedReservationScore: StateLoader<Score>,
    queriedReservationsForStats: {
        isLoaded: boolean,
        reservations: AllResTypes[],
    },
    managedReservationPrice: StateLoader<CheckoutData>,
    testingReservation: Reservation & {appliedPlayers: string[], notifiedPlayers: string[]},
    queriedUserReservations: StateLoader<{[resId: string]: Reservation}>,
    queriedReservationsList: StateLoader<Reservation[]>,
}




let initState: ReservationsState = {
    reservations: {
        isLoaded: false,
        data: {}
    },
    queriedUserReservations: {isLoaded: false, data: {}},
    selection: {},
    managedReservationScore: {
        isLoaded: true,
        isSuccess: true,
        isChanged: false,
        data: {
            gamePlayers: {
                home: {
                    A: "bIr1qBTPjgRMSLLoQddhtHExRZp2",
                    B: "CecPibT4hqWAry1CUB1bQhfHR9t1",
                },
                away: {
                    A: "8nlzIKqHbxeCOwrFXXVb8Y2kwqi1",
                    B: "JefbJFH5n7We7e2sTjUqpKJnqUP2",
                }
            },
            rankedGame: false,
            result: [
                {
                    home: {
                        games: 6
                    },
                    away: {
                        games: 4
                    }
                },
                {
                    home: {
                        games: 3
                    },
                    away: {
                        games: 6
                    }
                },
                {
                    home: {
                        games: 7
                    },
                    away: {
                        games: 5
                    }
                }
            ]
        }
    },
    queriedReservationsForStats: {
        isLoaded: true,
        reservations: [],
    },
    managedReservationPrice: {
        isLoaded: false,
        data: {
            price: 0,
            detail: {},
            club: "",
            amountCapturable: 0,
            paidAmt: 0,
            mainUid: "",
            autoCapture: false,
            currency: undefined,
            paymentPromise: false
        }
    },
    testingReservation: {
        mainUid: 'CecPibT4hqWAry1CUB1bQhfHR9t1',
        club: "",
        season: "",
        dateFrom: new Date,
        dateTo: new Date,
        timeFrom: 100,
        timeTo: 200,
        court: '2',
        day: 6,
        reservationRequestId: "",
        status: ReservationStatuses.open,
        type: ReservationTypes.opengame,
        gameType: GameTypes.single,
        gamePlayers: { home: { A: "" }, away: { A: "" } },
        players: [],
        appliedPlayers: [],
        notifiedPlayers: [],
        allplayers: [],
        updateTime: new Date,
    },
    managedReservation: {
        isLoaded: false,
        isActionPressed: false,
        data: undefined,
        seasonsData: {}
    },
    subscribedReservation: undefined,
    queriedReservationsList: {
        isLoaded: false,
        data: []
    }
}

const reservationReducer = (state = initState, action) => {
    //console.log('WTF: ', action)
    let actionData: AllResTypes = {...action.data}
    switch(action.type){
        case ReservationActions.reqervationsListQuery:
            return {
                ...state,
                queriedReservationsList: {
                    ...state.queriedReservationsList,
                    ...action.data,
                    data: [...action.data.data]
                }
            }
        
        case ReservationActions.subscribedReservation:
            return {
                ...state,
                subscribedReservation: {...actionData},
                managedReservation: {
                    isLoaded: true,
                    data: {...action.data},
    
                }
            }
        case ReservationActions.reservationSetSelection:
            var currSelection = {...state.selection}
            console.log('Current selection: ' + JSON.stringify(currSelection))
            if (
                currSelection.court == action.data.court && 
                isDateEqual(currSelection.dateFrom, action.data.dateFrom) 
                
                ) {
                    //removing one block at start
                currSelection = {...currSelection, dateFrom: action.data.dateTo, isSelected: true}
            } else if (
                currSelection.court == action.data.court && 
                isDateEqual(currSelection.dateTo, action.data.dateTo) 
                
                ) {
                    //removing one block at end
                currSelection = {...currSelection, dateTo: action.data.dateFrom, isSelected: true}
            } else if (
                currSelection.court == action.data.court && 
                isDateEqual(currSelection.dateFrom, action.data.dateTo) &&
                !isDateEqual(action.data.segmentEnds, action.data.dateTo) 
                ) {
                    //adding one block at start
                    currSelection = {...currSelection, dateFrom: action.data.dateFrom, isSelected: true}
            } else if (
                currSelection.court == action.data.court && 
                isDateEqual(currSelection.dateTo, action.data.dateFrom) &&
                !isDateEqual(action.data.segmentStarts, action.data.dateFrom)
            ) {
                //adding one block at end
                currSelection = {...currSelection, dateTo: action.data.dateTo, isSelected: true}
            } else {
                currSelection = {
                    dateFrom: action.data.dateFrom,
                    dateTo: action.data.dateTo,
                    court: action.data.court,
                    segmentEnds: action.data.segmentEnds, 
                    segmentStarts: action.data.segmentStarts,
                    season: action.data.season,
                    isSelected: true,
                }
            }
            
            let timeDiff = currSelection.dateFrom.getTime() - currSelection.dateTo.getTime()
            currSelection['isSelected'] = Math.abs(timeDiff)/60000 >= 60 
            //console.log(currSelection)
            return {
                ...state,
                selection: {...currSelection}
            }
        case 'TESTING_GAME_PLAYERS_CHANGE':
            //console.log('changes',action.data)
            return {
                ...state,
                testingReservation: {
                    ...state.testingReservation,
                    gamePlayers: {...action.data.gamePlayers},
                    players: [...action.data.players],
                    appliedPlayers: [...action.data.appliedPlayers],
                    notifiedPlayers: [...action.data.notifiedPlayers],
                    gameType: action.data.gameType || state.testingReservation.gameType,
                }
            }
        case 'RESERVATION_ADDED':
            //console.log('akce:', action)
            return {
                ...state,
                lastAddedReservation: action.data
            }
        case 'PUT_MANAGED_RESERVATION_TO_STATE':
            console.log('akce:', {
                ...action.data,
                isLoaded: true,
                isChanged: true,
            })
            return {
                ...state,
                managedReservation: {
                    data: {...action.data},
                    isLoaded: true,
                    isChanged: true
                }
            }
        case ReservationActions.editedReservation:
            //Alert.alert("sds", JSON.stringify(action.data))
            return {
                ...state,
                managedReservation: {
                    ...state.managedReservation,
                    seasonsData: {...action.data.seasonsData},
                    data: {...state.managedReservation.data, ...action.data.data},
                    isChanged: true,
                    isActionPressed: action.data.isActionPressed == undefined ? state.managedReservation.isActionPressed : action.data.isActionPressed
                }
            }
        case 'PRICE_DATA':
            //console.log('akce:', action)
            return {
                ...state,
                managedReservationPrice: {
                    ...action.data,
                }
            }
        case 'GET_CLUB_DATA':
            //console.log('akce:', action)
            return {
                ...state,
                clubData: action.data
            }
        case ReservationActions.reservationsUserQuery:
            return {
                ...state,
                queriedUserReservations: action.data
            }
        case 'COLLIDING_RESERVATIONS_SKIPPED':
            return {
                ...state,
                collisions: action.data
            }
        case 'QUERIED_USER_RESERVATIONS_ERROR':
            return {
                ...state,
                queriedUserReservations: action.data
            }
        case 'RESERVATIONS_WITH_COLLISIONS':
            return {
                ...state,
                queriedCollisions: action.data
            }
        case ReservationActions.rankedGameAction:
            return {
                ...state,
                managedReservationScore: {
                    ...state.managedReservationScore,
                    data: {
                        ...state.managedReservationScore.data,
                        rankedGame: action.data.rankedGame
                    },
                    isChanged: true,
                }
            }
        case ReservationActions.addSetPress:
            return {
                ...state,
                managedReservationScore: {
                    ...state.managedReservationScore,
                    edited: true,
                    data: {
                        result: [
                            ...state.managedReservationScore.data.result,
                            action.data
                        ]
                    }
                }
            }
        case ReservationActions.removeSetPress:
            state.managedReservationScore.data.result.pop()
            break  
        case ReservationActions.editSetValue:
            state.managedReservationScore.data.result[action.data.index][action.data.side].games = action.data.value
            //state.managedReservationScore.isChanged = true
            //console.log(state.managedReservationScore)
            return {
                ...state,
                managedReservationScore: {
                    ...state.managedReservationScore,
                    isChanged: true
                }
            }
        case ReservationActions.reservationResultAdditionResponse:
            //console.log('akce:', action)
            return {
                ...state,
                resultAddition: {...action.data}
            }
        case ReservationActions.reservationResultToState:
            return {
                ...state,
                managedReservationScore: {...action.data}
            }
        case 'RESERVATION_RESULT_RESET':
            return {
                ...state,
                managedReservationScore: {...state.managedReservationScore, ...action.data}
            }
        case ReservationActions.reservationsChanges:

            let reservations = {...state.reservations.data, ...action.data.data?.added, ...action.data.data?.modified}
            Object.keys(action.data.data?.removed || {}).forEach((key) => {
                delete reservations[key]
            })
            let out = {
                ...state,
                reservations: {
                    ...action.data,
                    data: {...reservations}
                }
            }
            //console.log('My reservations data: ', out)
            return out
        case 'QUERIED_STAT_RESERVATIONS':
            return {
                ...state,
                queriedReservationsForStats: {
                    ...action.data
                }
            }
        }
       
        return state
    }

export default reservationReducer