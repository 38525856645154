import React from 'react';
import { StyleSheet, Text, Pressable } from 'react-native';
import { Market } from '../../../../functions/src/interfaces';
import FormEntry from '../../../components/FormEntryNew';
import i18n, { tokens } from '../../../i18n';
import { useAppNavigation } from '../../../store/hooks';
import { normalize } from '../../../utilities/constants';


type Props = {

  marketData: Market,
  
}

function CheckoutInfo(props: Props): JSX.Element {
  //console.log('VOalm', props)

  const navigation = useAppNavigation()

  return (
    props.marketData.firebaseId?<>
    <FormEntry 
    caption={i18n.t(tokens.app.components.market.marketCommonsCheckoutID)}
    editable={false}>
      <Pressable
      onPress={() => navigation.navigate('CheckoutScreen', {checkoutId: props.marketData.firebaseId, club: props.marketData.club})}
      >
        <Text>{props.marketData.firebaseId}</Text>
      </Pressable>
    </FormEntry>
    
  </>:null
  )
}

const styles = StyleSheet.create({

  textStyle: {

    textAlign: "right",
    fontSize: normalize(18),
    fontFamily: 'AvertaStd-Regular',
    
  },
  inputText: {
    backgroundColor: "white",
    borderColor: "grey",
    //borderWidth: 1,
    borderRadius: 5,
    width: "100%",
    height: 30

  }
});

export default CheckoutInfo
