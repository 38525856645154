import Constants from "expo-constants";
import * as WebBrowser from 'expo-web-browser';
import { AnyMarket, CallbackFunc, CheckoutData, Club, MarketTypes, PaymentData, PaymentModes, PaymentTransactionStatuses, PayUCreateOrderAPI } from "../../../functions/src/interfaces";
import { standardizeDates } from "../../utilities/funcs";
import { AppThunk } from "../hooks";
import { AuthActions } from "../reducers/authReducer";
import { CheckoutActions } from "../reducers/checkoutReducer";
import { RootStore } from "../reducers/rootReducer";

const API_URL = Constants.expoConfig.extra.functionsUrlBase

export const subscribeForCheckout = (checkoutId: string, club: string, callback: CallbackFunc): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        const firestore = getFirestore()

        const checkoutRef = firestore.collection('clubs').doc(club).collection('checkouts').doc(checkoutId)
        
        return checkoutRef.onSnapshot((snapshot) => {
            if (snapshot.exists) {
                let data: CheckoutData = standardizeDates(snapshot.data()) 
                dispatch({type: CheckoutActions.checkoutSubscriptionChange, data: {data: {...data}, isLoaded: true, isSuccess: true}})
            }
            
        })

    }
}

export const queryCheckouts = (uid: string, club: string, callback: CallbackFunc, startAfter: boolean, filters?: any[]): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        
        try {
            const state: RootStore = getState()
            dispatch({type: CheckoutActions.checkoutActionQueryCheckouts, data: {isActionPressed: true, data: state.checkout.queriedCheckouts.data}})
            const firestore = getFirestore()
            const clubRef = firestore.collection('clubs').doc(club)
            const checkoutsCollref = clubRef.collection('checkouts')
            var query = checkoutsCollref.where('mainUid','==',uid)
            
            if (filters) {

            }
            query = query.orderBy('updatedDate','desc').limit(20)
            if (startAfter) {
                query = query.startAfter(state.checkout.queriedCheckouts.data.lastDoc)
            }
            let documents = await query.get()
            //console.log('queried', documents.docs)
            var outData: {
                uid: string,
                lastDoc: any,
                data: {[checkoutId: string]: CheckoutData & {marketType: MarketTypes}}
            } = {uid: uid, lastDoc: null, data: {}}
            if (startAfter) outData = {...outData, data: {...state.checkout.queriedCheckouts?.data?.data}}
            //console.log(documents.size)
            for (let i = 0; i < documents.size; i++) {
                let doc = documents.docs[i]
                outData.lastDoc = doc
                //console.log(doc.data())
                let checkout: CheckoutData = standardizeDates(doc.data())
                
                const marketRef = clubRef.collection('market').doc(checkout.firebaseId)
                const marketDoc = await marketRef.get()
                const marketData: AnyMarket = standardizeDates(marketDoc.data())

                outData.data[checkout.firebaseId] = {...checkout, marketType: marketData?.type}
                
            }

            
            dispatch({type: CheckoutActions.checkoutActionQueryCheckouts, data: {isActionPressed: false, data: outData}})
            
        }
        catch (e: any) {
            console.log(JSON.stringify(e))
            dispatch({type: CheckoutActions.checkoutActionQueryCheckouts, data: {isActionPressed: false, error: e}})
        }
    }
}


export const handleCheckoutAction = (marketId: string, checkoutId: string, club: Club, callback: CallbackFunc): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: AuthActions.authActionPressed, data: true})
        dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: true}})
        let request = {
            checkoutId: checkoutId,
            club: club.firebaseId,
            marketId: marketId,
            platform: "web",
        }
        console.log('Request', request)
        const response = await fetch(`${API_URL}/createWebSession`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        })
        const resData = await response.json();
        console.log('Response',resData)
        callback(true, resData.sessionId)
        
        let result = await WebBrowser.openBrowserAsync(`${API_URL}/redirectWebSession?sessionId=${resData.sessionId}&key=${club.stripePublishableKey}`);
        console.log(result)
        dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: false}})
        //dispatch({type: 'SESSION_ENDED', data: {sessionId: resData.sessionId}})
        dispatch({type: AuthActions.authActionPressed, data: false})
    }
}


export const handleCaptureAction = (checkoutId: string, club: {firebaseId: string, stripePublishableKey: string}, callback: CallbackFunc): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        try {
            const token = await getFirebase().auth().currentUser.getIdToken(true)

            let request = {
                token: token,
                data: {
                    checkoutId: checkoutId,
                    clubId: club.firebaseId
                }
            }
            console.log('Request', request)
            const response = await fetch(`${API_URL}/capturePayment`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(request),
            })
            const resData = await response.json();
            console.log('Response',resData)
            dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            //dispatch({type: 'CAPTURE_SUCCESS', data: {sessionId: resData.sessionId}})
            return response
        }
        catch(e) {
            dispatch({type: 'CAPTURE_ERROR', data: {code: 'SOME_ERROR', message: e.message}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        }  

    }
}

export const handleRefundAction = (checkoutId: string, club: {firebaseId: string, stripePublishableKey: string}, callback: CallbackFunc): AppThunk<Promise<Response>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        try {
            const token = await getFirebase().auth().currentUser.getIdToken(true)

            let request = {
                token: token,
                data: {
                    checkoutId: checkoutId,
                    clubId: club.firebaseId
                }
            }
            console.log('Request', request)
            const response = await fetch(`${API_URL}/refundPayment`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(request),
            })
            const resData = await response.json();
            console.log('Response',resData)
            dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            //dispatch({type: 'REFUND_SUCCESS', data: {sessionId: resData.sessionId}})
            return response
        }
        catch(e) {
            dispatch({type: 'REFUND_ERROR', data: {code: 'SOME_ERROR', message: e.message}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        }  

    }
}



export const getUserCredit = (uid: string, club: string, callback: (bool: boolean, context: {[key: string]: any}) => void): AppThunk<Promise<void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutGetUserCredit, data: {isLoaded: false}})
        try {
            const firestore = getFirestore()

            firestore.runTransaction(async (t) => {
                const userBalRef = firestore.collection('clubs').doc(club).collection('creditbalance').doc(uid)
                const userBalDoc = await t.get(userBalRef)
                if (userBalDoc.exists) {
                    let userBalData = standardizeDates(userBalDoc.data()) 
                    return userBalData
                } else {
                    const newBalData = {
                        uid: uid,
                        creditBalance: 0,
                        updateDate: new Date
                    }
                    t.set(userBalRef, newBalData)
                    return newBalData
                }
            })
            .then((newBalData) => {
                dispatch({type: CheckoutActions.checkoutGetUserCredit, data: {isLoaded: true, data: {...newBalData}, error:{}}})
            })
            .catch((e) => {
                dispatch({type: CheckoutActions.checkoutGetUserCredit, data: {isLoaded: false, data: {}, error:{code: 'SOME_ERROR', message: e.message}}})
            })
        }
        catch(e) {
            dispatch({type: CheckoutActions.checkoutGetUserCredit, data: {isLoaded: false, data: {}, error:{code: 'SOME_ERROR', message: e.message}}})
        }  

    }
}


export const subscribeForPayment = (paymentId: string, club: string, callback: CallbackFunc): AppThunk<Promise<() => void>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {data: {firebaseId: paymentId}, isLoaded: false, isSuccess: false}})
        const firestore = getFirestore()

        const checkoutRef = firestore.collection('clubs').doc(club).collection('payments').doc(paymentId)
        const state: RootStore = getState()
        return checkoutRef.onSnapshot((snapshot) => {
            if (snapshot.exists) {
                let data: CheckoutData = standardizeDates(snapshot.data())
                if (state.checkout.subscribedPayment.data.firebaseId != data.firebaseId) {
                    console.log('Checkout and paymentintent dont correspond')
                } else {
                    dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {data: {...data}, isLoaded: true, isSuccess: true}})
                }
                
            }
            
        })

    }
}


export const createPaymentIntent = (
    club: Club,
    checkoutId: string,
    marketId: string,
    mode: PaymentModes,
    native?: boolean,
): AppThunk<Promise<string>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }):Promise<string> => {
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: true, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        //const firebase = getFirebase()
        const firestore = getFirestore()

        const currCheckoutRef = firestore.collection('clubs').doc(club.firebaseId).collection('checkouts').doc(checkoutId)
        const currCheckoutDoc = await currCheckoutRef.get()
        const currCheckoutData:CheckoutData = standardizeDates(currCheckoutDoc.data())

        /* if (currCheckoutData.paymentIntentId != "") {
            const currPaymentRef = firestore.collection('clubs').doc(club.firebaseId).collection('checkouts').doc(checkoutId)
            const currPayment
        } */
        var newPaymentId = undefined
        switch(mode) {
            case PaymentModes.paymentModeCard:
                if(native == true) {
                    const request = {
                        club: club.firebaseId,
                        checkoutId: checkoutId,
                        marketId: marketId
                    }
                    let reqStr = JSON.stringify(request)
                    console.log('Req: ' + reqStr)
                    const response = await fetch(`${API_URL}/createPaymentIntent`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: reqStr,
                    });
                    const { clientSecret, paymentAmt, error, paymentIntentId } = await response.json();
                    dispatch({type: CheckoutActions.checkoutFetchClientSecret, data: {clientSecret, paymentIntentId}})
                    //dispatch({type: AuthActions.authActionPressed, data: false})
                    //dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {data: {clientSecret, paymentAmt}, error: error}})
                    newPaymentId = paymentIntentId
                } else {
                    dispatch(handleCheckoutAction(marketId, checkoutId, club, (bool,context) => {}))
                }
                break;
            case PaymentModes.paymentModeBT:
                let newPaymentBT = await firestore.runTransaction(async (t) => {
                    const clubRef = firestore.collection('clubs').doc(club.firebaseId)
                    const checkoutRef = clubRef.collection('checkouts').doc(checkoutId)
                    const checkoutDoc = await t.get(checkoutRef)
                    const checkoutData:CheckoutData = standardizeDates(checkoutDoc.data())
                    const currPaymentRef = checkoutData.paymentIntentId ? clubRef.collection('payments').doc(checkoutData.paymentIntentId) : null
                    if (currPaymentRef) {
                        const currPaymentDoc = await t.get(currPaymentRef)
                        const currPaymentData: PaymentData = standardizeDates(currPaymentDoc.data())
                        if (currPaymentData.status == "initiated") {
                            return currPaymentData
                        }
                    }
                    const query = clubRef.collection('payments')
                    .where('type','==',PaymentModes.paymentModeBT)
                    .orderBy('paymentIntentId','desc')
                    .limit(1)
                    let queryDocs = await query.get()
                    let queryData = queryDocs.docs.map((doc) => standardizeDates(doc.data()))
                    let maxValue = parseInt(queryData[0]?.paymentIntentId) + 1 || 1
                    const newPaymentRef = clubRef.collection('payments').doc()
                    const newPaymentDoc = await newPaymentRef.get()
                    var newPaymentData: PaymentData = {
                        amount: checkoutData.price,
                        amountCapturable: 0,
                        amountCaptured: 0,
                        amountRefunded: 0,
                        charges: {},
                        checkoutId: checkoutId,
                        createdDate: new Date,
                        firebaseId: newPaymentDoc.id,
                        paymentIntentId: String(maxValue),
                        status: PaymentTransactionStatuses.initiated,
                        uid: checkoutData.mainUid,
                        type: PaymentModes.paymentModeBT,
                        club: club.firebaseId
                    }
                    t.set(newPaymentRef, newPaymentData)
                    t.update(checkoutRef, {paymentIntentId: newPaymentDoc.id})
                    return newPaymentData
                })
                //dispatch({type: })
                newPaymentId = newPaymentBT.firebaseId
                break;
            case PaymentModes.paymentModeCredit:
                let newPaymentCredit = await firestore.runTransaction(async (t) => {
                    const clubRef = firestore.collection('clubs').doc(club.firebaseId)
                    const checkoutRef = clubRef.collection('checkouts').doc(checkoutId)
                    const checkoutDoc = await t.get(checkoutRef)
                    const checkoutData:CheckoutData = standardizeDates(checkoutDoc.data())
                    const currPaymentRef = checkoutData.paymentIntentId ? clubRef.collection('payments').doc(checkoutData.paymentIntentId) : null
                    if (currPaymentRef) {
                        const currPaymentDoc = await t.get(currPaymentRef)
                        const currPaymentData: PaymentData = standardizeDates(currPaymentDoc.data())
                        if (currPaymentData.status == "initiated") {
                            return currPaymentData
                        }
                    }
                    
                    const newPaymentRef = clubRef.collection('payments').doc()
                    const newPaymentDoc = await newPaymentRef.get()
                    var newPaymentData: PaymentData = {
                        amount: checkoutData.price,
                        amountCapturable: 0,
                        amountCaptured: 0,
                        amountRefunded: 0,
                        charges: {},
                        checkoutId: checkoutId,
                        createdDate: new Date,
                        firebaseId: newPaymentDoc.id,
                        paymentIntentId: newPaymentDoc.id,
                        status: PaymentTransactionStatuses.initiated,
                        uid: checkoutData.mainUid,
                        type: PaymentModes.paymentModeCredit,
                        club: club.firebaseId,
                    }
                    t.set(newPaymentRef, newPaymentData)
                    t.update(checkoutRef, {paymentIntentId: newPaymentDoc.id})
                    return newPaymentData
                })
                //dispatch({type: })
                newPaymentId = newPaymentCredit.firebaseId
                break;
            case PaymentModes.paymentModePayU:
                dispatch(handleCheckoutPayUAction(marketId, checkoutId, {firebaseId: club.firebaseId, payUId: club.payUId}, (bool,context) => {}))
                break;
            case PaymentModes.paymentModeCash:
                let newPaymentCash = await firestore.runTransaction(async (t) => {
                    const clubRef = firestore.collection('clubs').doc(club.firebaseId)
                    const checkoutRef = clubRef.collection('checkouts').doc(checkoutId)
                    const checkoutDoc = await t.get(checkoutRef)
                    const checkoutData:CheckoutData = standardizeDates(checkoutDoc.data())
                    const currPaymentRef = checkoutData.paymentIntentId ? clubRef.collection('payments').doc(checkoutData.paymentIntentId) : null
                    if (currPaymentRef) {
                        const currPaymentDoc = await t.get(currPaymentRef)
                        const currPaymentData: PaymentData = standardizeDates(currPaymentDoc.data())
                        if (currPaymentData.status == "initiated") {
                            return currPaymentData
                        }
                    }
                    
                    const newPaymentRef = clubRef.collection('payments').doc()
                    const newPaymentDoc = await newPaymentRef.get()
                    var newPaymentData: PaymentData = {
                        amount: checkoutData.price,
                        amountCapturable: 0,
                        amountCaptured: 0,
                        amountRefunded: 0,
                        charges: {},
                        checkoutId: checkoutId,
                        createdDate: new Date,
                        firebaseId: newPaymentDoc.id,
                        paymentIntentId: newPaymentDoc.id,
                        status: PaymentTransactionStatuses.initiated,
                        uid: checkoutData.mainUid,
                        type: PaymentModes.paymentModeCash,
                        club: club.firebaseId,
                    }
                    t.set(newPaymentRef, newPaymentData)
                    t.update(checkoutRef, {paymentIntentId: newPaymentDoc.id})
                    return newPaymentData
                })
                //dispatch({type: })
                newPaymentId = newPaymentCash.firebaseId
                break;
        }
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: false}})
        dispatch({type: AuthActions.authActionPressed, data: false})
        return newPaymentId
    }
}




export const handlePayCreditAction = (paymentId: string, checkoutId: string, club: Club, callback: CallbackFunc): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        try {
            const token = await getFirebase().auth().currentUser.getIdToken(true)

            let request = {
                token: token,
                data: {
                    checkoutId: checkoutId,
                    clubId: club.firebaseId,
                    paymentId: paymentId,
                }
            }
            console.log('Request', request)
            const response = await fetch(`${API_URL}/payUsingCredit`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(request),
            })
            const resData = await response.json();
            console.log('Response',resData)
            dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            //dispatch({type: 'REFUND_SUCCESS', data: {sessionId: resData.sessionId}})
            return response
        }
        catch(e) {
            dispatch({type: 'REFUND_ERROR', data: {code: 'SOME_ERROR', message: e.message}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        }  

    }
}

export const handlePayCashAction = (paymentId: string, checkoutId: string, club: Club, callback: CallbackFunc): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        try {
            const token = await getFirebase().auth().currentUser.getIdToken(true)

            let request = {
                token: token,
                data: {
                    checkoutId: checkoutId,
                    clubId: club.firebaseId,
                    paymentId: paymentId,
                }
            }
            console.log('Request', request)
            const response = await fetch(`${API_URL}/payUsingCash`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(request),
            })
            const resData = await response.json();
            console.log('Response',resData)
            dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            //dispatch({type: 'REFUND_SUCCESS', data: {sessionId: resData.sessionId}})
            return response
        }
        catch(e) {
            dispatch({type: 'REFUND_ERROR', data: {code: 'SOME_ERROR', message: e.message}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        }  

    }
}


export const handleCheckoutPayUAction = (marketId: string, checkoutId: string, club: {firebaseId: string, payUId: string}, callback: CallbackFunc): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        try {
            let request = {
                checkoutId: checkoutId,
                club: club.firebaseId,
                clubPayUId: club.payUId,
                marketId: marketId,
            }
            console.log('Request', request)
            const response = await fetch(`${API_URL}/createOrder`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin":"*",
                },
                body: JSON.stringify(request),
            })
            const resData: PayUCreateOrderAPI = await response.json();
            console.log('Response',resData)
            callback(true, resData)
            
            let result = await WebBrowser.openBrowserAsync(resData.redirectUri);
            console.log(result)
            dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            //dispatch({type: 'SESSION_ENDED', data: {sessionId: resData.sessionId}})
        }
        catch (e: any) {
            console.log('Error: ' + e.message)
            dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        }

    }
}


export const handleCaptureActionPayU = (checkoutId: string, clubId: string, callback: CallbackFunc): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        try {
            const token = await getFirebase().auth().currentUser.getIdToken(true)

            let request = {
                token: token,
                data: {
                    checkoutId: checkoutId,
                    clubId: clubId
                }
            }
            console.log('Request', request)
            const response = await fetch(`${API_URL}/capturePaymentPayU`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(request),
            })
            const resData = await response.json();
            console.log('Response',resData)
            dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            //dispatch({type: 'CAPTURE_SUCCESS', data: {sessionId: resData.sessionId}})
            return response
        }
        catch(e) {
            dispatch({type: 'CAPTURE_ERROR', data: {code: 'SOME_ERROR', message: e.message}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        }  

    }
}


export const handleRefundActionPayU = (checkoutId: string, clubId: string, callback: CallbackFunc): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        try {
            const token = await getFirebase().auth().currentUser.getIdToken(true)

            let request = {
                token: token,
                data: {
                    checkoutId: checkoutId,
                    clubId: clubId
                }
            }
            console.log('Request', request)
            const response = await fetch(`${API_URL}/refundPaymentPayU`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(request),
            })
            const resData = await response.json();
            console.log('Response',resData)
            dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            //dispatch({type: 'REFUND_SUCCESS', data: {sessionId: resData.sessionId}})
            return response
        }
        catch(e) {
            dispatch({type: 'REFUND_ERROR', data: {code: 'SOME_ERROR', message: e.message}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        }  

    }
}



export const handleCheckoutAll = (checkoutId: string, clubId: string, method: PaymentModes, callback: CallbackFunc): AppThunk<Promise<any>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: AuthActions.authActionPressed, data: true})
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: true}})
        try {
            let request = {
                checkoutId: checkoutId,
                club: clubId,
                //clubPayUId: club.payUId,
                method: method,
            }
            console.log('Request', request)
            const response = await fetch(`${API_URL}/createOrderAll`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin":"*",
                },
                body: JSON.stringify(request),
            })
            const resData: PayUCreateOrderAPI = await response.json();
            console.log('Response',resData)
            callback(true, resData)
            
            
            dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return true
            //dispatch({type: 'SESSION_ENDED', data: {sessionId: resData.sessionId}})
        }
        catch (e: any) {
            console.log('Error: ' + e.message)
            dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            return false
        }

    }
}


export const handlePayUCheckout = (url: string): AppThunk<void> => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: true}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        try {
            console.log('Redirecting to: ' + url)
            let result = await WebBrowser.openBrowserAsync(url);
            dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
            //dispatch({type: 'SESSION_ENDED', data: {sessionId: resData.sessionId}})
        }
        catch (e: any) {
            console.log('Error: ' + e.message)
            dispatch({type: CheckoutActions.checkoutDataStateChanges, data: {isActionPressed: false}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        }

    }
}


export const queryPaymentsByCheckoutId = (checkoutId: string, club: string): AppThunk<void> => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: CheckoutActions.checkoutQueryPayments, data: {isLoaded: false, isActionPressed: true, data: []}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        const firestore = getFirestore()

        const clubRef = firestore.collection('clubs').doc(club)
        const reservations = clubRef.collection('payments').where('checkoutId','==',checkoutId)
        var outReservations = []
        reservations.get()
        .then((querySnapshot) => {
            let docs = querySnapshot.docs
            
            for (let i = 0; i < docs.length; i++) {
                let doc = docs[i]
                let reservation: PaymentData = standardizeDates(doc.data())
                outReservations.push(reservation)
            }
            //dispatch(})
            dispatch({type: CheckoutActions.checkoutQueryPayments, data: {isLoaded: true, isActionPressed: false, data: outReservations}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        })
        .catch((e) => {
            dispatch({type: CheckoutActions.checkoutQueryPayments, data: {isLoaded: true, isActionPressed: false, data: [], error: {code: 'QUERY_ERROR', message: e.message}}})
            dispatch({type: AuthActions.authActionPressed, data: false})
        })
    }
}


export const finalizePayment = (
    club: Club,
    paymentId: string,
): AppThunk<Promise<string>> => {
    return async (dispatch, getState, { getFirebase, getFirestore }):Promise<any> => {
        dispatch({type: CheckoutActions.checkoutPaymentSubscriptionChange, data: {isActionPressed: true, data: {}}})
        dispatch({type: AuthActions.authActionPressed, data: true})
        //const firebase = getFirebase()
        const firestore = getFirestore()

        const currPaymentRef = firestore.collection('clubs').doc(club.firebaseId).collection('payments').doc(paymentId)
        const currPaymentDoc = await currPaymentRef.get()
        const paymentData = standardizeDates(currPaymentDoc.data())

        firestore.runTransaction(async (t) => {
            
        })
    }
}