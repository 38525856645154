import { News } from "../../../functions/src/interfaces"
import { StateLoader } from "../../utilities/interfaces"

export enum NewsActions {
    newsPutToState = 'PUT_MANAGED_NEWS_TO_STATE',
    newsChangeManaged = "CHANGE_MANAGED_NEWS",
    newsSubcriptionChanges = 'NEWS_CHANGES'
}


export type NewsState = {
    managedNews: StateLoader<News>,
    news: {[newsId: string]: News}
}

let initState: NewsState = {
    managedNews: undefined,
    news: {},
}

const newsReducer = (state = initState, action) => {
    switch(action.type){
        
        case NewsActions.newsPutToState:
            //console.log('akce:', action)
            return {
                ...state,
                managedNews: {
                    isLoaded: false,
                    data: {...action.data}
                }
            }
        case NewsActions.newsChangeManaged:
            return {
                ...state,
                managedNews: {
                    ...state.managedNews,
                    data: {...state.managedNews.data, ...action.data},
                    isLoading: false,
                }
            }
        case NewsActions.newsSubcriptionChanges:
            action.data
            let out = {
                ...state,
                
            }
            out.news = {...out.news, ...action.data.added, ...action.data.modified}
            Object.keys(action.data.removed).forEach((key) => {
                delete out.news[key]
            })
            return out
        }
        return state
    }


export default newsReducer