import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Alert, Platform, StyleSheet, View } from 'react-native';
import { Club, Reservation, ReservationStatuses, UserRoles } from '../../../functions/src/interfaces';
import ActionOverlay from '../../components/ActionOverlay';
import BigButton from '../../components/BigButton';
import i18n, { tokens } from '../../i18n';
import { addReservation, marketDeletionAction } from '../../store/actions/actionsMarketNew';
import { useAppDispatch, useAppNavigation } from '../../store/hooks';
import { StateLoader } from '../../store/reducers/reservationReducer';
import { normalize } from '../../utilities/constants';
import {createEvent} from '../../utilities/calendar'
import { showToast } from '../../utilities/toastcontroller';
import { reservationDeletionAction } from '../../store/actions/actionsReservationsNew';
import { UserOrClub } from '../../store/reducers/usersReducer';

type Props = {
    isChanged: boolean,
    firebaseId: string,
    reservationData: StateLoader<Reservation>,
    isAllowedToEdit: boolean,
    currentUser: UserOrClub<UserRoles>,
    editButton: () => void,
    club: Club,
}

function ButtonComponent(props: Props):JSX.Element {
    //console.log('Buttons props', props)
    //const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    //const [isActionPressed,setIsActionPressed] = useState(false)
    //console.log('VOalm', props)
    const addReservationAction = () => {
      dispatch(addReservation())
      .then((result) => {
        if (result.action == 'checkout') {
          
        }
      })
      .catch((e) => {

      })
    }

    const openGameAction = () => {
      navigation.navigate('ManageMarket', {type: "opengame", uid: props.currentUser, firebaseId: props.reservationData.data.firebaseId, club: props.reservationData.data.club})
    }
    const editReservationAction = () => {
      props.editButton()
    }

    const removeReservationAction = () => {
      dispatch(reservationDeletionAction(props.reservationData.data.club,props.reservationData.data.reservationRequestId, 'single'))
      .then((result) => {
        console.log('Resp ', result)
        if (result.result == true) {
          navigation.popToTop()
        }
      })
      .catch((e) => {
        console.log('Error ', e)
      })
    }

    const addToCalendarAction = () => {
      createEvent({
        title: props.club.club_name,
        startDate: props.reservationData.data.dateFrom,
        endDate: props.reservationData.data.dateTo,
        clubAddress: "",
        id: props.reservationData.data.firebaseId || "",
        text: ""
      })
      .then((res) => {
        showToast("OK")
      })
      .catch((e) => showToast(JSON.stringify(e)))
     }

    return (<>
    {/* <ActionOverlay
      onBackdropPress={()=> {}}
      fullScreen={true}
      isVisible={props.reservationData.isActionPressed}
      /> */}<View
      style={styles.buttonContainer}>
        
          
          {[ReservationStatuses.open, ReservationStatuses.approved].includes(props.reservationData.data.status) && (props.reservationData.data.firebaseId) && (props.isAllowedToEdit == true)? 
          <BigButton 
          onPress={editReservationAction}
          buttonText={i18n.t(tokens.app.screens.manageReservation.reservationEditButton)}
          />
          :null}
          {[ReservationStatuses.open, ReservationStatuses.blocked, ReservationStatuses.approved].includes(props.reservationData.data.status) && (props.reservationData.data.firebaseId) && (props.isAllowedToEdit == true)? 
          <BigButton 
          onPress={removeReservationAction}
          buttonText={i18n.t(tokens.app.screens.manageReservation.reservationDeclineButton)}
          />
          :null}
          </View>
          <View
      style={styles.buttonContainer}>
        
          {[ReservationStatuses.open, ReservationStatuses.approved].includes(props.reservationData.data.status) && (props.isAllowedToEdit == true)?
          <BigButton
          onPress={openGameAction}
          //disabled={true}
          buttonText={i18n.t(tokens.app.screens.manageReservation.reservationOpenGameCaption)}
          />
          :null}
          {(props.reservationData.data.allplayers || []).includes(props.currentUser.uid) && (Platform.OS == "ios" || Platform.OS == "android") && ([ReservationStatuses.approved].includes(props.reservationData.data.status))?
          <BigButton 
          onPress={addToCalendarAction}
          buttonText={i18n.t(tokens.app.screens.manageReservation.reservationAddToCalendar)}
          imageAsset={'event'}
          />
          :null}
          
          </View>
          </>
    )
}

const styles = StyleSheet.create({
    
    textStyle: {
      
        textAlign: "right", 
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',
        
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
});

export default ButtonComponent
