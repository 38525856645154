import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Platform, StyleSheet, TextInput, View } from 'react-native'
import QRCode from 'react-native-qrcode-svg';
import { shallowEqual } from 'react-redux';
import { Club, PaymentData, PaymentModes, PaymentTransactionStatuses } from '../../../../functions/src/interfaces';
import BigButton from '../../../components/BigButton';
import FormEntry from '../../../components/FormEntryNew';
import i18n, { tokens } from '../../../i18n';
import { handlePayCashAction, handlePayCreditAction } from '../../../store/actions/actionsCheckout';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/checkoutReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { normalize } from '../../../utilities/constants';



//https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
type Props = {
    paymentData: StateLoader<PaymentData & {clientSecret?:string}>,
    reinitialize?: (method: PaymentModes) => Promise<string>,
    isAdmin: boolean,
}

function PaymentCash(props:Props): JSX.Element {
    const [isLoaded, setIsLoaded] = useState(true)
    const club: Club = useAppSelector((state:RootStore) => state.auth.allClubs?.[state.auth?.selectedClub], shallowEqual)
    const [paymentMsg,setPaymentMsg] = useState<string>("")  
    

    const dispatch = useAppDispatch()
    

    const handleBuyPress = (id: string) => {
        dispatch(handlePayCashAction(id, props.paymentData.data.checkoutId, club, (bool, context) => {}))
    }

    return (
        <View
        style={styles.container}>
            {isLoaded?
            
            
            <View style={styles.buttonView}>
            {props.isAdmin && props.paymentData?.data?.firebaseId && props.paymentData?.data?.status == PaymentTransactionStatuses.initiated?
            <BigButton
            buttonText={i18n.t(tokens.app.screens.payment.paymentChargeButton)}
            onPress={() => handleBuyPress(props.paymentData.data.paymentIntentId)}
            />:null}
            </View>
            
            :<ActivityIndicator size={"small"}/>}
        </View>
    )
}


const styles = StyleSheet.create(
    {
        container: {
            justifyContent: "center",
            //alignItems: "center",
        },
        buttonView: {
            flexDirection: "row",
            //flex: 1,
            height: 150,
        }
    }
)

export default PaymentCash