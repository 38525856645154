import { Club, MembershipTypes, Subscription, UserRoles } from "../../functions/src/interfaces"
import { subscriptionsToObject } from "../utilities/funcs"
import { SubscribtionListItem } from "../utilities/interfaces"
import { useAppSelector } from "./hooks"
import { RootStore } from "./reducers/rootReducer"
import { UserOrClub } from "./reducers/usersReducer"

export const getCurrentUserWithRoles = (state: RootStore):UserOrClub<UserRoles> => {
    let userState = state.auth.userState
    if (!userState.user?.uid) return null
    if (Object.keys(state.auth.allClubs).includes(userState.user.uid)) {
        return {type: 'clubs', uid: state.auth.allClubs[userState.user.uid].firebaseId, ...state.auth.allClubs[userState.user?.uid]}
    } else {
        
        const memberships = subscriptionsToObject({ subscriptions: state.contacts.subscriptions, activeSeason: state.auth.activeSeason, currDate: new Date(JSON.parse(state.auth.currDate)) })
        let isMember = memberships[userState.user?.uid]?.isActiveFloat || memberships[userState.user?.uid]?.isActiveTour || false
        //console.log(state.auth.selectedClub)
        let allMyClubRoles = {
            ...state.auth.myRoles?.[state.auth.selectedClub], 
            isMember: isMember, 
            all: true, 
            isClubRegistered: state.auth?.currentUserData?.type == "user" && state.auth?.currentUserData?.clubs.includes(state.auth?.selectedClub),
            isRegistered: state.auth?.currentUserData?.type == "user" && state.auth?.currentUserData?.clubs.includes(state.auth?.selectedClub)
        }
        return {
            type: "user",
            ...state.auth.currentUserData,
            ...allMyClubRoles,
        }
    }
}

export const getCurrentAuthState = () => useAppSelector((state: RootStore) => {
    //console.log('Auth data: ', state.auth.userState)
    return state.auth.userState
}, (a, b) => {
    return a.isEmpty == b.isEmpty && a.isLoaded == b.isLoaded && a.user?.uid == b.user?.uid
})

export const getMySubscriptions = (state:RootStore, uid: string) => {
    const currDate = new Date
    
    //let uid = state.users.userSubscribtions.uid
    //console.log('Subs data: ', state.users.userSubscribtions)
    let clubsData: {[clubId:string]: Club} = state.auth.allClubs
    //let subKeys = Object.keys(subs)
    let user = state.users.usersData?.[uid]
    let activeSeason = state.auth.activeSeason
    let tourSeasons = Object.values(state.auth.seasons).filter(seas => seas.tour == activeSeason?.tour)
    let maxTourEnd = tourSeasons.reduce((prev,curr) => {
        if (prev < curr.to) {
            return curr.to
        } else {
            return prev
        }
    }, new Date)
    var mySubs = []
    if (user && user.type == "user") {
        let clubs = user?.clubs || []
        //console.log( state.auth)
        let allUserSubs = Object.values(state.users.userSubscribtions.data)
        var clubMemberships = {}
        for (let i = 0; i < clubs.length; i++) {
            let clubId = clubs[i]
            let selClub = clubsData[clubId]
            if (!Object.keys(clubMemberships).includes(clubId)) {
                clubMemberships[clubId] = {clubId: clubId, club_name: selClub.club_name, active: false, activeTill: null, subs: []}
            }
        }
        for (let i = 0; i < allUserSubs.length; i++) {
            let sub: Subscription = allUserSubs[i]
            let selClub = clubsData[sub.club]
            if (!Object.keys(clubMemberships).includes(sub.club)) {
                clubMemberships[sub.club] = {clubId: sub.club, club_name: selClub.club_name, active: false, activeTill: null, subs: []}
            }
            clubMemberships[sub.club].subs.push(sub)
            if (sub.membershipType == MembershipTypes.yearSubscription || sub.membershipType == "year") {
                if (   currDate >= sub.membershipStartDate 
                    && currDate <= sub.membershipEndDate) {
                        clubMemberships[sub.club].active = true
                        clubMemberships[sub.club].activeTill = clubMemberships[sub.club].activeTill < sub.membershipEndDate || !clubMemberships[sub.club].activeTill ? sub.membershipEndDate : clubMemberships[sub.club].activeTill
                } else if (currDate <= sub.membershipEndDate) {
                    clubMemberships[sub.club].activeTill = clubMemberships[sub.club].activeTill < sub.membershipEndDate || !clubMemberships[sub.club].activeTill ? sub.membershipEndDate : clubMemberships[sub.club].activeTill
                }     
                
            } else if (sub.membershipType == MembershipTypes.tourSubscription || sub.membershipType == "tour") {
                if (activeSeason && activeSeason.tour == sub.tour ) {
                    clubMemberships[sub.club].active = true
                    clubMemberships[sub.club].activeTill = maxTourEnd
                } 
            } else {
                //out = {...out, active: false, activeTill: null}
            }
        }
        mySubs = [...Object.values(clubMemberships)]
        //console.log('Subs', mySubs)
    } else {
        console.log('No user yet')
    }
    //console.log(mySubs)
    return mySubs
}