import View from '@expo/html-elements/build/primitives/View';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { ActivityIndicator, Platform, StyleSheet, Switch, Text, Pressable } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { shallowEqual } from 'react-redux';
import { isAnyRole } from '../../../../functions/src/funcs';
import { Market, MarketLongreservation, MarketStatuses, Message, UserRoles } from '../../../../functions/src/interfaces';
import CommentsComponent from '../../../components/CommentsComponent';
import FormEntry from '../../../components/FormEntryNew';
import GamePlayers from '../../../components/GamePlayersNew';
import WebWrapper from '../../../components/WebWrapper';
import i18n, { tokens } from '../../../i18n';
import { addEditMessage, editMarket, switchLongShort } from '../../../store/actions/actionsMarketNew';
import { queryCollisions } from '../../../store/actions/actionsReservationsNew';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/marketReducer';
import { RootStore } from '../../../store/reducers/rootReducer';
import { UserOrClub } from '../../../store/reducers/usersReducer';
import { getCurrentUserWithRoles } from '../../../store/selectors';
import { globalStyleSheet } from '../../../utilities/globalStyles';
import CheckoutInfo from '../CommonMarketComponents/CheckoutInfo';
import MessageComponent from '../../../components/MessageComponent';
import OwnerComponent from '../CommonMarketComponents/OwnerComponent';
import PrevMarket from '../CommonMarketComponents/PrevMarket';
import PriceComponent from '../CommonMarketComponents/PriceComponent';
import ButtonComponent from './ButtonComponent';
import { isAllowedToApprove, isAllowedToEdit, validations } from './LongreservationValidations';
import PeriodComponent from './PeriodComponent';

type Props = {
    firebaseId?: string,
    club: string,
    setMarketId: (newVal: string) => void,
    setMarketButtonIndex: (index: number) => void,
}

function ManageLongreservation(props: Props):JSX.Element {
    //console.log('Market: ' + props.firebaseId)
    const dispatch = useAppDispatch()
    
    const marketId = props.firebaseId
    const club = props.club
    const seasons = useAppSelector(state => state.auth.seasons, shallowEqual)
    const navigation = useAppNavigation()
    //const [validators, setValidators] = useState({})
    
    const longreservationData: StateLoader<MarketLongreservation<Market>> & {allValid: boolean} = useAppSelector((state: RootStore) => {
        var reservation = {...state.market.managedMarket} as StateLoader<MarketLongreservation<Market>>
        let seasons = state.auth.seasons
        const validators = seasons && reservation.data?.season ? validations(seasons[reservation.data.season]) : {}
        //console.log(validators)
        var validationsData = {}
        var allValid = true
        if (reservation.isLoaded) {
            validationsData = Object.keys(reservation.data).reduce((acc, key) => {acc[key] = {validation: true, error: ""}; return acc}, validationsData)
            let validKeys = Object.keys(validators)
            
            for (let i = 0; i < validKeys.length; i++) {
                let key = validKeys[i]
                let validator = validators[key]
                
                let validation = validator.method(reservation.data)
                
                validationsData[key] = {validity: validation.bool, error: validation.context}
                allValid = allValid == true ? validation.bool : allValid
            }
            //  console.log('Probehly validace: ', validationsData)
            reservation['validations'] = {...validationsData}
        }
        reservation['allValid'] = allValid
        
        console.log('Vysledek validace ', allValid)
            
        return reservation
    }, shallowEqual)
    //console.log('Update rezervace: ',reservationData)
    const currentUser: UserOrClub<UserRoles> = useAppSelector(getCurrentUserWithRoles, shallowEqual)
   

   
    const dispatchChanges = (evalChange: () => {[key: string]: any}) => {
        let changes = evalChange()
        
        dispatch(editMarket(changes))
    }

    useEffect(() => {
        //let inter = Object.keys(changes).filter(value => ['timeFrom','timeTo','season','to','from','court','day'].includes(value))
        //console.log(inter)
        if (longreservationData['allValid']  && longreservationData.data) {
            console.log('Volam query na kolize ')
            dispatch(queryCollisions(longreservationData, (bool, context) => {

            }))
            
        }
    }, [longreservationData.data])

    const addMessage = (msg: Message, callback: (res: boolean, context: any) => void) => {
        dispatch(addEditMessage(msg,longreservationData.data.firebaseId,longreservationData.data.club, callback))
    }

    const isSwapable =  () => (longreservationData.data.court && 
    longreservationData.data.court != "" && 
    longreservationData.data.season && 
    longreservationData.data.season != "")

    return (
        <KeyboardAwareScrollView
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        extraHeight={Platform.select({ android: 200, ios: 200 })}
        >
            
                {longreservationData.isLoaded == true?
                <View style={styles.container}>
                    <OwnerComponent
                    dispatchActionForChanges={dispatchChanges}
                    reservationData={longreservationData.data}
                    isChanged={longreservationData.isChanged}
                    firebaseId={longreservationData.data.firebaseId}
                    disabled={!isAllowedToApprove(currentUser, longreservationData.data.club)}
                    />
                    {longreservationData.data.status == MarketStatuses.new?
                    <FormEntry
                    caption={i18n.t(tokens.app.constants.markettypes.longreservation)}
                    editable={isSwapable() ? true : false}
                    >
                        <Switch
                        value={true}
                        disabled={!isSwapable()}
                        onValueChange={(value) => dispatch(switchLongShort())}
                        />
                    </FormEntry>
                    :null}
                    <CheckoutInfo marketData={longreservationData.data}/>
                    <PrevMarket marketData={longreservationData.data} setMarketId={props.setMarketId}/>
                    <PeriodComponent
                    managedMarketData={longreservationData}
                    dispatchActionForChanges={dispatchChanges}
                    disabled={!isAllowedToEdit(currentUser, longreservationData.data.mainUid, longreservationData.data.status, longreservationData.data.club)}
                    />
                    {longreservationData.data?.reservationIds?.length > 0?<FormEntry
                    caption={i18n.t(tokens.app.screens.longreservation.childReservations)}
                    editable={false}
                    >
                        <Pressable
                        onPress={() => navigation.navigate('ReservationsListScreen', {type: "longreservation", club: longreservationData.data.club, marketId: longreservationData.data.firebaseId})}
                        >
                            <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>
                                {i18n.t(tokens.app.screens.longreservation.viewButton) + ' ' + (longreservationData.data?.reservationIds?.length?longreservationData.data?.reservationIds?.length:0).toString()}
                            </Text>
                        </Pressable>
                    </FormEntry>: null}
                    <GamePlayers
                    visible={!(longreservationData.data?.isOnBehalf && longreservationData.data?.isNotRegistered) || false}
                    disabled={!isAllowedToEdit(currentUser, longreservationData.data.mainUid, longreservationData.data.status, longreservationData.data.club)}
                    reservationOwner={longreservationData.data.mainUid}
                    selectableType={true}
                    gameType={longreservationData.data.gameType}
                    gamePlayers={longreservationData.data.gamePlayers}
                    players={longreservationData.data.players}
                    type={longreservationData.data.type}
                    dispatchActionForChanges={dispatchChanges}
                    dragEnabled={isAllowedToEdit(currentUser, longreservationData.data.mainUid, longreservationData.data.status, longreservationData.data.club)}//currentUser.uid == testingReservation.mainUid || currentUser.isAdmin == true || currentUser.isGod == true
                    />
                    <MessageComponent
                    managedMarketData={longreservationData}
                    dispatchActionForChanges={dispatchChanges}
                    disabled={!isAllowedToEdit(currentUser, longreservationData.data.mainUid, longreservationData.data.status, longreservationData.data.club)}
                    />
                    <CommentsComponent
                    comentable={longreservationData.data.firebaseId?true:false}
                    messages={longreservationData.data.messages}
                    addOrEditMessage={addMessage}
                    />
                    <PriceComponent 
                    isChanged={longreservationData.isChanged}
                    firebaseId={longreservationData.data?.firebaseId}
                    reservationData={{...longreservationData.data, priceCompDataType: 'market'}}
                    allValid={longreservationData.allValid} 
                    type={'market'}
                    dispatchChanges={dispatchChanges}
                    />
                    <ButtonComponent
                    isChanged={longreservationData.isChanged}
                    isAllowedToEdit={isAllowedToEdit(currentUser, longreservationData.data.mainUid, longreservationData.data.status, longreservationData.data.club)}
                    isAllowedToApprove={isAllowedToApprove(currentUser, longreservationData.data.club)}
                    reservationData={longreservationData}
                    firebaseId={longreservationData.data.firebaseId}
                    currentUser={currentUser}
                    setMarketId={props.setMarketId}
                    setMarketButtonIndex={props.setMarketButtonIndex}
                    />
                </View>
                :
                <ActivityIndicator size={"large"} />}
            
        </KeyboardAwareScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%"
    },
    buttonContainer: {
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-around",
        //height: 50,
        //marginBottom: "auto",
        width: "100%",
        
    }
})

export default ManageLongreservation