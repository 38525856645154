import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Market, MarketOpengame } from '../../../../functions/src/interfaces';
import BigButton from '../../../components/BigButton';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import FormEntry from '../../../components/FormEntryNew';
import FormEntryNoCaption from '../../../components/FormEntryNoCaption';
import FormHeading from '../../../components/FormHeading';
import TimeInput from '../../../components/TimeInput/TimeInput';
import i18n, { tokens } from '../../../i18n';
import { useAppDispatch, useAppNavigation } from '../../../store/hooks';
import { StateLoader } from '../../../store/reducers/reservationReducer';
import { normalize, weekdays } from '../../../utilities/constants';
import { hoursMinutesToString, monthString, splitTime } from '../../../utilities/funcs';
import { globalStyleSheet } from '../../../utilities/globalStyles';



type Props = {
    managedMarketData: StateLoader<MarketOpengame<Market>>,
    dispatchActionForChanges: (callback) => void,
    disabled: boolean
}

function ReservationComponent(props: Props): JSX.Element {

    //const auth = useAppSelector((state: RootStore) => state.auth, shallowEqual)
    //const dispatch = useAppDispatch()
    const navigation = useAppNavigation()
    const [editDatetimeFlag, setEditDatetimeFlag] = useState(props.managedMarketData.data.firebaseId || props.managedMarketData?.data?.reservationIds?.length > 0 ?false:true)
    
    const dispatchChange = (callback: (params) => void) => {
        props.dispatchActionForChanges(
            callback
        )
    }

    return (
        <View
            style={styles.container}>
             <FormHeading
            heading={i18n.t(tokens.app.screens.opengame.dateTimeHeading)}
            editButton={() => setEditDatetimeFlag(prev => !prev)}
            />
            
            
            <FormEntryNoCaption editable={false}>
                <>
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{
                    (props.managedMarketData?.data?.reservationIds?.length > 0 ? 
                        i18n.t(tokens.app.screens.opengame.reservationConnectedMessage):
                        i18n.t(tokens.app.screens.opengame.noReservationConnectedMessage) 
                    )}
                </Text>
                {props.managedMarketData?.data.dueDate && !editDatetimeFlag?
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>
                    {i18n.t(tokens.app.screens.opengame.termOfGameMessage) + 
                    weekdays[props.managedMarketData?.data.dueDate?.getDay()] + " " + 
                    monthString(props.managedMarketData?.data?.dueDate) + 
                    (props.managedMarketData?.data?.timeFrom > 0 && props.managedMarketData?.data.timeTo > 0 ? 
                    " at " + hoursMinutesToString(splitTime(String(props.managedMarketData?.data?.timeFrom))) + " - " + 
                    hoursMinutesToString(splitTime(String(props.managedMarketData?.data?.timeTo))):
                    "")}
                </Text>:null}
                </>
            </FormEntryNoCaption>
            
            
            
            {editDatetimeFlag?(
            <>
            <FormEntry
            caption={i18n.t(tokens.app.screens.opengame.requestExpires)}
            error={props.managedMarketData?.validations?.dueDate.error} 
            validity={props.managedMarketData?.validations?.dueDate.validity}
            editable={!props.disabled}
            >
                {props.managedMarketData?.data?.reservationIds?.length > 0?
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{monthString(props.managedMarketData?.data?.dueDate)}</Text>
                :<CustomDatePicker 
                containerStyle={globalStyleSheet.inputOk}
                fontStyle={globalStyleSheet.entityText}
                date={props.managedMarketData?.data?.dueDate} 
                setDate={(newDate) => {
                    //console.log('vybral jsem', newDate)
                    //setManageMarketData(oldvalue => {return {...oldvalue, dueDate: newDate}})
                    dispatchChange(() => { return {dueDate: newDate}})
                }}
                />}
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.opengame.timeStart)}
            error={props.managedMarketData?.validations?.timeFrom.error} 
            validity={props.managedMarketData?.validations?.timeFrom.validity}
            editable={!props.disabled}
            >
                {props.managedMarketData?.data?.reservationIds?.length > 0?
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{hoursMinutesToString(splitTime(String(props.managedMarketData?.data?.timeFrom)))}</Text>:
                <TimeInput 
                disabled={props.disabled}
                value={props.managedMarketData?.data?.timeFrom}
                onValueChange={(value) => {
                    dispatchChange(() => { return {timeFrom: value}})
                }}
                />
                }
            </FormEntry>
            <FormEntry 
            caption={i18n.t(tokens.app.screens.opengame.timeEnd)}
            error={props.managedMarketData?.validations?.timeTo.error} 
            validity={props.managedMarketData?.validations?.timeTo.validity}
            editable={!props.disabled}
            >
                {props.managedMarketData?.data?.reservationIds?.length > 0?
                <Text maxFontSizeMultiplier={1.2} style={globalStyleSheet.entityText}>{hoursMinutesToString(splitTime(String(props.managedMarketData?.data?.timeTo)))}</Text>
                :
                <TimeInput 
                disabled={props.disabled}
                value={props.managedMarketData?.data?.timeTo}
                onValueChange={(value) => {
                    dispatchChange(() => { return {timeTo: value}})
                }}
                />
                }
            </FormEntry>
            {!props.disabled?
            <View style={{minHeight: 50, flexDirection: "row"}}>
                <BigButton 
                buttonText={i18n.t(tokens.app.screens.opengame.connectWithResButton)}
                onPress={() => {
                    navigation.navigate('ReservationsListScreen', {user: props.managedMarketData?.data.uid, type: "user"})
                }
                }
                />
                 <BigButton 
                buttonText={i18n.t(tokens.app.screens.opengame.createReservationButton)}
                onPress={() => {
                    navigation.navigate('BrowseCalendar', {club: props.managedMarketData?.data.club, date: props.managedMarketData?.data.dueDate})
                }
                }
                />
            </View>: null
            }
            </>
            ):null}
        </View>
    )
}

const styles = StyleSheet.create({

    textStyle: {

        textAlign: "right",
        fontSize: normalize(18),
        fontFamily: 'AvertaStd-Regular',

    },
    container: {
        width: "100%",

    }
});

export default ReservationComponent
