import * as Localisation from 'expo-localization';
import i18n from 'i18n-js';
import { britishEnglish } from "./en";
import { czechLang } from './cs';
export { tokens } from './tokens';

i18n.translations = {
  en: britishEnglish,
  ['en-GB']: britishEnglish,
  ['cs-CZ']: czechLang,
}

i18n.locale = Localisation.locale;
i18n.fallbacks = true;

export default i18n;