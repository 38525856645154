import React from 'react'
import { StyleSheet, Text, Pressable, View } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import { Ranking, user } from '../../functions/src/interfaces'
import i18n, { tokens } from '../i18n'
import { participateInSeasonRanking } from '../store/actions/actionsRanking'
import { useAppDispatch, useAppNavigation } from '../store/hooks'
import { normalize } from '../utilities/constants'
import UserAvatar from './UserAvatar'

type Props = {
    position: Ranking,
    gameType: string,
    item: Ranking & user,
    selectedClub: string,
    activeSeasonId: string,
    currUid: string,
    children?: any,
}

const RankingEntry: React.FC<Props> = (props) => {
    const navigation = useAppNavigation()
    const dispatch = useAppDispatch()
    //const contactFlag = 
    //console.log('nalezeno', props)
    const statsByGameType = (data, gameType) => {
        if (gameType=='single') {
            return `${i18n.t(tokens.app.components.rank.points)}: ${String(data.pointsSingles)} ${i18n.t(tokens.app.components.rank.wins)}: ${String(data.winsSingles)} ${i18n.t(tokens.app.components.rank.losses)}: ${String(data.lossesSingles)}`
        } else if (gameType=='double') {
            return `${i18n.t(tokens.app.components.rank.points)}: ${String(data.pointsDoubles)} ${i18n.t(tokens.app.components.rank.wins)}: ${String(data.winsDoubles)} ${i18n.t(tokens.app.components.rank.losses)}: ${String(data.lossesDoubles)}`
        }
    }
    
    
    const styles = StyleSheet.create({
        entityContainer: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            //marginTop: 8,
            borderBottomColor: '#cccccc',
            paddingBottom: 8,
            backgroundColor: "white",
            marginBottom: 2,
            borderRadius: 5,
            marginLeft: 5,
            marginRight: 5
            
        },
        entityText: {
            color: props.item.status == 'active'?'#333333':"grey", 
            fontSize: normalize(14),
        },
        avatar: {
            borderColor: props.item.status == 'active'?"black":"lightgrey", 
            borderWidth: 1, 
            opacity: props.item.status == 'active'? 1 : 0.3
        },
        positionText: {
            color: props.item.status == 'active'?'#333333':"grey", 
            fontSize: normalize(30),
        }
    })
    return (
        <View style={styles.entityContainer}>
        <Pressable style={{flexDirection: "row", flex: 1}} onPress={() => {
            //console.log('Chci navigovat na: ', props.item.uid)
            navigation.navigate('Profile', {uid: props.item.uid})
            }}>
            <View style={{flexDirection: "column", marginLeft: 5, justifyContent: "center"}}>
            <Text maxFontSizeMultiplier={1.2} style={styles.positionText}>
            {String(props.position) + ". "}
            </Text>
            </View>
            <UserAvatar uid={props.item.uid} size={normalize(50)}/>
            
            <View style={{flexDirection: "column", marginLeft: 5, justifyContent: "center"}}>
            <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>
            {props.item ? props.item.first_name + ' ' + props.item.family_name : 'Default'}
            </Text>
            <Text maxFontSizeMultiplier={1.2} style={styles.entityText}>
            {props.item ? statsByGameType(props.item, props.gameType) : 'Default'}
            </Text>
            </View>
            
            <View style={{alignSelf: "center", marginLeft: "auto"}}>
                {props.children ? props.children : null}
                {props.item.uid == props.currUid && props.item.status == 'active'?<Pressable
                    //style={styles.button}
                    onPress={() => {
                            dispatch(participateInSeasonRanking(props.currUid, props.selectedClub, props.activeSeasonId, 'inactive'))
                            
                    }}
                    >
                        <Ionicons 
                        name="close-outline"
                        color="red"
                        size={normalize(20)}
                        
                        />
                    </Pressable>:null}
            </View>
            </Pressable>
        </View>
    )
}



export default RankingEntry

