import React from 'react';
import { Alert, Text, Pressable, View } from 'react-native';
import { shallowEqual, useSelector } from 'react-redux';
import { useAppDispatch, useAppNavigation, useAppSelector } from '../../store/hooks';
import { ReservationActions, Selection } from '../../store/reducers/reservationReducer';
import { normalize } from '../../utilities/constants';
import { dateToTimeString, isAdmin } from '../../utilities/funcs';
import Ionicons from '@expo/vector-icons/Ionicons';
import { secondColor } from '../../utilities/globalStyles';
import i18n, { tokens } from '../../i18n';
import { updateManagedMarketByselection } from '../../store/actions/actionsMarketNew';
import { getCurrentAuthState, getCurrentUserWithRoles } from '../../store/selectors';
import { RootStore } from '../../store/reducers/rootReducer';
import { ReservationTypes } from '../../../functions/src/interfaces';
import FloatingAction from '../FloatingAction';


type Props = {
    changeMarket: boolean
}

enum FloatActions {
    reservation = 'reservation',
    block = 'block'
}

const FloatingButton: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch()
    const blockDims = {
        height: 100,
        width: 50
    }

    const navigation = useAppNavigation()
    const { isEmpty, user, isLoaded } = getCurrentAuthState()
    const currentUser = useAppSelector(getCurrentUserWithRoles, (a, b) => JSON.stringify(a) == JSON.stringify(b))
    const selectedClub = useAppSelector((state: RootStore) => state.auth.selectedClub, shallowEqual)
    /* const isSelected = (block, select: Selection) => {
        //console.log(block, select)
        try {
            if (select.court && block.court == select.court) {
                if (select.dateFrom && block.dateFrom.getTime() == select.dateFrom.getTime()) {
                    return true
                } else if (select.dateFrom && select.dateTo && block.dateFrom > select.dateFrom && block.dateTo <= select.dateTo) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }  

        }
        catch (e) {
            console.log('Neco: ' + JSON.stringify(e))
        }
                     
    } */

    const isSelected = useSelector((state:RootStore) => state.reservations.selection?.isSelected, shallowEqual)


    
    
   

    const addButtonPress = (sel, auth, name:FloatActions = FloatActions.reservation) => {
        //console.log(sel, name, props.changeMarket, name, FloatActions.reservation, name == FloatActions.reservation)
        //console.log("mel bych plnit: " + JSON.stringify(newResBlock))
        /* if (!auth) {
            Alert.alert(i18n.t(tokens.app.screens.reservations.alertTitle),i18n.t(tokens.app.screens.reservations.notLoggedInWarning))
        } else  */
        if (!sel) {
            Alert.alert(i18n.t(tokens.app.screens.reservations.alertTitle),i18n.t(tokens.app.screens.reservations.notSelectedWarning))
        } else if (props.changeMarket == true) {
            dispatch(updateManagedMarketByselection())
            navigation.goBack()
        } else if (name == FloatActions.reservation) {
            //props.manageMarketToState({...selection}, season.club, (callbackval) => console.log(JSON.stringify(callbackval)))

            navigation.navigate('ManageMarket', {type: "reservation", club: selectedClub})
        } else if (name == FloatActions.block) {
            navigation.navigate('ManageReservationScreen', {club: selectedClub, type: ReservationTypes.outoforder})
        }
        


    }

    
    //console.log(props)
    return (
        <FloatingAction
            //visible={}
            actions={[{
                text: i18n.t(tokens.app.screens.manageReservation.manageReservationTitle),
                name: FloatActions.reservation,
                icon: "add",
                
              },
              {
                text: i18n.t(tokens.app.screens.reservations.reservationBlockedTime),
                name: FloatActions.block,
                icon: "add",
                
              }]}
            color={isSelected?secondColor:"grey"}
            overrideWithAction={!isAdmin(currentUser, selectedClub)}
            onPressItem={(name: FloatActions) => addButtonPress(isSelected, !isEmpty, name)}
            buttonSize={70}
            //onPressMain={addButtonPress}
            />
    )
}


export default FloatingButton


