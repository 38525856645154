import React from "react";
import { Text, Pressable, View } from "react-native";
import { normalize } from "../utilities/constants";
import { mainFont } from "../utilities/globalStyles";

type Props = {
    tag: string,
    value: boolean,
    onPress: () => void,
    disabled?: boolean
}

const FilterTag: React.FC<Props> = (props) => {
        
    return (
        <Pressable
        onPress={props.onPress}
        disabled={props.disabled}
        >
        <View
        style={{
            borderWidth: props.value ? 2 : 1,
            borderColor: "grey",
            borderRadius: 50,
            padding: 4,
            margin: 2
        }}>

            <Text maxFontSizeMultiplier={1.2}
            style={{
                fontSize: normalize(15),
                fontFamily: mainFont,
                color: "grey",
                fontWeight: props.value ? "bold" : "normal"
            }}>{props.tag}</Text>
        </View>
        </Pressable>
    )
}

export default FilterTag